@use '../../_common/styles/brand-colors' as *;
@use 'uswds-core' as * ;

$bullet-spacing: 1.25rem;

// Highlight TODO links to make them visually standout
.todo-link,
a[href*="/TODO/"] {
  color: color('blue-80v') !important;
  background-color: color('gold-10v') !important;
  padding: 0 units(0.5);

  &:after  {
    content: ' [link TBD]';
  }

  &:visited {
    color: color('blue-80v') !important;
  }
}
.todo-link.usa-link--external:after,
a[href*="/TODO/"].usa-link--external:after {
  background-color: inherit;
  background-image: none;
  margin-left: 0;
  padding-left: units(0.5);
  vertical-align: baseline;
  -webkit-mask-image: none;
}

// Set heading colors
h1, h2, h3, h4, h5, h6 {
  color: color('primary-darker');
}

// Set heading line-heights
h2, h3, h4, h5, h6 {
	@include u-line-height('heading', 3);
}

// Anchor link icon styling
.heading-permalink {
  opacity: 0.4;

  &:visited {
    color: color('primary');
  }

  &:focus,
  &:hover {
    opacity: 1;
  }

  .usa-icon {
    vertical-align: sub;
    max-width: units(3);
  }
}

// Header anchor link styling
.landing-header {
    a {
        text-decoration: none;
        color: inherit;

        &:hover {
            text-decoration: none;
        }
        &:active {
            text-decoration: none;
        }
        &:visited {
            text-decoration: none;
        }
    }
}

// usa-prose overrides
.usa-prose {
  blockquote {
    max-width: measure(2);
  }

  code {
    word-wrap: break-word;
  }

  h2, h3, h4, h5, h6 {
      // prevent orphans and use balance if pretty is not available
      text-wrap: balance;
      text-wrap: pretty;
  }

  h2 {
    margin-top: units(5);
    &.page-title {
      margin-top: units(0);
      &.derisking {
        padding-top: units(1);
        border-top: 1px solid #1C304A;
      }
    }
  }

  li p {
    margin-bottom: 0;
  }
}


// usa-section overrides
.hero .section__heading--white {
  color: color('white');
}

// accent button
a.usa-button--accent {
  color: color('primary-darker');
  background-color: color('accent-cool');

  &:visited {
    color: color('primary-darker');
    background-color: inherit;
  }

  &:focus,
  &:hover {
    color: color('primary-darker');
    background-color: color('accent-cool-lighter');
  }

  &:active {
    background-color: color('accent-cool-lightest');
  }
}

// Main content spacing
main {
	padding: units(6) 0 units(9);
}

// Custom utilities
.width-200 {
  width: 200px;
}

// De-risking guide
.first_page > h1 {
  @include u-font('sans', '3xl');
}

.first_page > h2 {
  @include u-font('sans', '2xl');
  border-top: 1px solid color('primary-dark');
  padding-top: units(1.5);
}

#footnotes {
  @include u-font('sans', 'md');
  border-top: 1px solid color('primary-dark');
  padding-top: units(1.5);
}

.footnotes-list {
  @include u-font('sans', 'xs');
  max-width: measure(5);
  padding-left: units(2);
}

figcaption {
  font-style: italic;
}

figcaption details {
  font-style: normal;
}

// Engineering Guide
.heading-md {
  @include u-font('sans', 'md');
}

h2.heading-md {
  margin-top: units(2);
}

/* checklists and key questions */
.guide-checklist,
.key-questions {
  @include u-font('sans','md');
  color: color('white');
  padding: units(1);
}

.guide-checklist {
  background-color: color('primary');
}

.key-questions {
  background-color: color('primary-darker');
}

.guide-checklist + ul,
.key-questions + ul {
  background-color: color('blue-5');
  margin-top:0;
  padding-top: units(1);
  padding-right: units(2);
  padding-bottom: units(1);
}

.guide-checklist + ul li,
.key-questions + ul li {
  margin-bottom: units(1);
}

.guide-checklist + ul,
.guide-checklist + ul ul,
.guide-checklist--single + ul {
  list-style: none;
  margin-left: 0;
  padding-left: units(1);
}

.guide-checklist + ul li,
.guide-checklist--single + ul li {
  padding-left: $bullet-spacing;
  text-indent: (-1 * $bullet-spacing);

  &:before {
    content: '\25A1';
    padding-right: units(1);
  }
}

/* interview templates */

.example-user-interview-script code {
  background: yellow;
}

/* Touchpoints feedback button */
#touchpoints-form {
  @include u-font('sans', '3xs');
  background-color: $brand-color-medium;
  border-radius: 0.25rem 0.25rem 0 0;
  bottom: 0;
  position: fixed;
  right: 0.75rem;
  z-index: 9999;

  span .usa-icon {
    font-size: size('body', 'sm');
    vertical-align: middle;
  }
}

/* Primary navigation icons */
.usa-nav__primary-item .usa-nav__link .download-icon {
  padding-left: 2px;

  .usa-icon {
    min-height: 1rem;
    min-width: 1rem;
    vertical-align: middle;
  }
}

/* Footer */

.footer-section-bottom {
	color: white;
	padding: units(3) 0 units(6);
}

/* Footer menu */

.usa-footer__primary-link {
	padding: 0;

}

/* Social links */

.social-link {
    align-items: center;
    display: inline-flex;

    &:before {
	content: '';
	display: inline-block;
	height: 24px;
	margin-right: units(1);
	width: 24px;
    }

    &.social-link-bsky:before {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='600' height='530'%3E%3Cpath fill='%23ffffff' d='M135.72 44.03C202.216 93.951 273.74 195.17 300 249.49c26.262-54.316 97.782-155.54 164.28-205.46C512.26 8.009 590-19.862 590 68.825c0 17.712-10.155 148.79-16.111 170.07-20.703 73.984-96.144 92.854-163.25 81.433 117.3 19.964 147.14 86.092 82.697 152.22-122.39 125.59-175.91-31.511-189.63-71.766-2.514-7.38-3.69-10.832-3.708-7.896-.017-2.936-1.193.516-3.707 7.896-13.714 40.255-67.233 197.36-189.63 71.766-64.444-66.128-34.605-132.26 82.697-152.22-67.108 11.421-142.55-7.45-163.25-81.433C20.15 217.613 9.997 86.535 9.997 68.825c0-88.687 77.742-60.816 125.72-24.795z'/%3E%3C/svg%3E");
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
    }

}


/* Media links page */

.media-links-list {
    // display: inline-block;
    // gap: units(3);
    // grid-template-columns: repeat(1, 1fr);
    list-style-type: none;
    padding: 0;

    h2, .citation {
      margin-bottom:0.5rem;
    }

    li {
      margin-bottom:2rem;
    }

}

.citation, .article-date {
    font-size: size('body', 'xs');
    color: $brand-color-dark;
}

// @include at-media("tablet-lg"){
//     .media-links-list {
//         grid-template-columns: repeat(2, 1fr);
//     }

//     li {
//         margin-bottom: units(1);
//     }
// }

// @include at-media("desktop"){
//     .media-links-list {
//         grid-template-columns: repeat(3, 1fr);
//     }

//     li {
//         margin-bottom: units(1);
//     }
// }
