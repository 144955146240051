@use 'uswds-core' as *;

header {
  text-align: center;
}

.usa-logo-img {
  border-radius: 5px;
  margin-right: 1rem;
  max-width: 120px;
  text-align: center;
}

p, li {
  line-height: 1.5;
  font-size: 1.25rem;
}

li {
  margin-bottom:0.5rem;
}

h2 {
  margin-top: 1.25rem;
  padding-top: 1.25rem;
}

.card-with-image-guides {
  box-sizing: border-box;
  border: 2px solid transparent;
  background-color: white;
  border-radius: radius-lg;
  flex-grow: 0;
  min-height: 260px;

  h2 {
    font-size: 1.58rem;
    line-height: 1.3;
    letter-spacing: 0.0125em;
  }
}

.card-content {
  flex-grow: 1;
}

.card-with-image-guides p {
  text-wrap: pretty;
  flex-grow: 1;
}

.card-with-image-guides a {
  text-wrap: pretty;
}

.usa-list--unstyled {
  li {
    font-size: 1rem;
    margin-bottom:0.3rem;
  }

}
