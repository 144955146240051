/*---------------------------------------------------------
USWDS Settings
----------------------------------------------------------*/
@forward 'uswds-theme-settings';

/*---------------------------------------------------------
USWDS Source
----------------------------------------------------------*/
@forward 'uswds';

/*---------------------------------------------------------
Custom styling
----------------------------------------------------------*/
@forward 'custom-styles.scss';

@forward 'overrides/code_highlight.scss';
@forward 'overrides/extended-header.scss';
@forward 'overrides/usa-banner.scss';
@forward '../../brand/styles/brand-styles';

@forward 'index.scss';
@use "uswds-core" as *;

.header-logo {
    display: block;
    max-width: 96px;
    border-radius: none;
}

@include at-media("desktop"){
    .header-logo {
        display: block;
        max-width: 128px;
        border-radius: none;
    }
}
