@charset "UTF-8";

/* assets/_common/styles/styles.scss */
.usa-textarea,
.usa-range,
.usa-radio__label,
.usa-input-group,
.usa-hint,
.usa-combo-box__input,
.usa-combo-box__list,
.usa-select,
.usa-checkbox__label,
.usa-fieldset,
.usa-input {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.08rem;
  line-height: 1.3;
}
.usa-textarea,
.usa-range,
.usa-input-group,
.usa-combo-box__input,
.usa-select,
.usa-input {
  border-width: 1px;
  border-color: #565c65;
  border-style: solid;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  color: #11181d;
  display: block;
  height: 2.5rem;
  margin-top: 0.5rem;
  max-width: 30rem;
  padding: 0.5rem;
  width: 100%;
}
html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
hr {
  box-sizing: content-box;
  height: 0;
  color: inherit;
}
pre {
  font-family: monospace, monospace;
  font-size: 1em;
}
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: bolder;
}
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
button,
select {
  text-transform: none;
}
button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
legend {
  padding: 0;
}
progress {
  vertical-align: baseline;
}
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}
[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
summary {
  display: list-item;
}
@font-face {
  font-family: "Roboto Mono Web";
  font-style: normal;
  font-weight: 300;
  font-display: fallback;
  src: url("../roboto-mono-v5-latin-300-UE3MSWKN.woff2") format("woff2");
}
@font-face {
  font-family: "Roboto Mono Web";
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: url("../roboto-mono-v5-latin-regular-OEH334AX.woff2") format("woff2");
}
@font-face {
  font-family: "Roboto Mono Web";
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: url("../roboto-mono-v5-latin-700-3IFUE232.woff2") format("woff2");
}
@font-face {
  font-family: "Roboto Mono Web";
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: url("../roboto-mono-v5-latin-700-3IFUE232.woff2") format("woff2");
}
@font-face {
  font-family: "Roboto Mono Web";
  font-style: italic;
  font-weight: 300;
  font-display: fallback;
  src: url("../roboto-mono-v5-latin-300italic-L54VLD6K.woff2") format("woff2");
}
@font-face {
  font-family: "Roboto Mono Web";
  font-style: italic;
  font-weight: 400;
  font-display: fallback;
  src: url("../roboto-mono-v5-latin-italic-MJSZF7UQ.woff2") format("woff2");
}
@font-face {
  font-family: "Roboto Mono Web";
  font-style: italic;
  font-weight: 700;
  font-display: fallback;
  src: url("../roboto-mono-v5-latin-700italic-DKWOQYBO.woff2") format("woff2");
}
@font-face {
  font-family: "Roboto Mono Web";
  font-style: italic;
  font-weight: 700;
  font-display: fallback;
  src: url("../roboto-mono-v5-latin-700italic-DKWOQYBO.woff2") format("woff2");
}
@font-face {
  font-family: "Merriweather Web";
  font-style: normal;
  font-weight: 300;
  font-display: fallback;
  src: url("../Latin-Merriweather-Light-KQKG6QKY.woff2") format("woff2");
}
@font-face {
  font-family: "Merriweather Web";
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: url("../Latin-Merriweather-Regular-EYXYAATC.woff2") format("woff2");
}
@font-face {
  font-family: "Merriweather Web";
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: url("../Latin-Merriweather-Bold-34AYD4JS.woff2") format("woff2");
}
@font-face {
  font-family: "Merriweather Web";
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: url("../Latin-Merriweather-Bold-34AYD4JS.woff2") format("woff2");
}
@font-face {
  font-family: "Merriweather Web";
  font-style: italic;
  font-weight: 300;
  font-display: fallback;
  src: url("../Latin-Merriweather-LightItalic-RKOHA2JR.woff2") format("woff2");
}
@font-face {
  font-family: "Merriweather Web";
  font-style: italic;
  font-weight: 400;
  font-display: fallback;
  src: url("../Latin-Merriweather-Italic-I42LDIUD.woff2") format("woff2");
}
@font-face {
  font-family: "Merriweather Web";
  font-style: italic;
  font-weight: 700;
  font-display: fallback;
  src: url("../Latin-Merriweather-BoldItalic-TYKMDGFE.woff2") format("woff2");
}
@font-face {
  font-family: "Merriweather Web";
  font-style: italic;
  font-weight: 700;
  font-display: fallback;
  src: url("../Latin-Merriweather-BoldItalic-TYKMDGFE.woff2") format("woff2");
}
body {
  color: #11181d;
  background-color: white;
  overflow-x: hidden;
}
input:not([disabled]):focus,
select:not([disabled]):focus,
textarea:not([disabled]):focus,
button:not([disabled]):focus {
  outline: 0.25rem solid #2491ff;
  outline-offset: 0rem;
}
iframe:focus,
[href]:focus,
[tabindex]:focus,
[contentEditable=true]:focus {
  outline: 0.25rem solid #2491ff;
  outline-offset: 0rem;
}
img {
  max-width: 100%;
}
.usa-media-link {
  display: inline-block;
  line-height: 0;
}
html {
  box-sizing: border-box;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
html {
  font-feature-settings: "kern" 1;
  font-kerning: normal;
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 100%;
}
a {
  color: #046B99;
  text-decoration: underline;
}
a:visited {
  color: #54278f;
}
a:hover {
  color: #0b4778;
}
a:active {
  color: #1C304A;
}
a:focus {
  outline: 0.25rem solid #2491ff;
  outline-offset: 0rem;
}
cite,
var,
address,
dfn {
  font-style: normal;
}
.usa-focus {
  outline: 0.25rem solid #2491ff;
  outline-offset: 0rem;
}
.usa-sr-only {
  position: absolute;
  left: -999em;
  right: auto;
}
.usa-content p,
.usa-content ul:not(.usa-accordion):not(.usa-accordion--bordered),
.usa-content ol:not(.usa-accordion):not(.usa-accordion--bordered) {
  max-width: 68ex;
}
.usa-dark-background {
  background-color: #3d4551;
}
.usa-dark-background p,
.usa-dark-background span {
  color: white;
}
.usa-dark-background a {
  color: #dfe1e2;
}
.usa-dark-background a:visited {
  color: #dfe1e2;
}
.usa-dark-background a:hover,
.usa-dark-background a:active {
  color: #f0f0f0;
}
.usa-display {
  margin-bottom: 0;
  margin-top: 0;
  clear: both;
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.39rem;
  line-height: 1.2;
  font-weight: 700;
  margin-bottom: 0;
}
* + .usa-display {
  margin-top: 1.5em;
}
.usa-display + * {
  margin-top: 1em;
}
@media all and (min-width: 30em) {
  .usa-display {
    margin-bottom: 0;
    margin-top: 0;
    clear: both;
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 2.54rem;
    line-height: 1.2;
    font-weight: 700;
  }
  * + .usa-display {
    margin-top: 1.5em;
  }
  .usa-display + * {
    margin-top: 1em;
  }
}
@media all and (min-width: 40em) {
  .usa-display {
    margin-bottom: 0;
    margin-top: 0;
    clear: both;
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 3.04rem;
    line-height: 1.2;
    font-weight: 700;
  }
  * + .usa-display {
    margin-top: 1.5em;
  }
  .usa-display + * {
    margin-top: 1em;
  }
}
.usa-intro {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.39rem;
  line-height: 1.7;
  font-weight: 400;
  max-width: 88ex;
}
.usa-icon {
  display: inline-block;
  fill: currentColor;
  height: 1em;
  position: relative;
  width: 1em;
}
.usa-icon--size-3 {
  height: 1.5rem;
  width: 1.5rem;
}
.usa-icon--size-4 {
  height: 2rem;
  width: 2rem;
}
.usa-icon--size-5 {
  height: 2.5rem;
  width: 2.5rem;
}
.usa-icon--size-6 {
  height: 3rem;
  width: 3rem;
}
.usa-icon--size-7 {
  height: 3.5rem;
  width: 3.5rem;
}
.usa-icon--size-8 {
  height: 4rem;
  width: 4rem;
}
.usa-icon--size-9 {
  height: 4.5rem;
  width: 4.5rem;
}
.usa-link {
  color: #046B99;
  text-decoration: underline;
}
.usa-link:visited {
  color: #54278f;
}
.usa-link:hover {
  color: #0b4778;
}
.usa-link:active {
  color: #1C304A;
}
.usa-link:focus {
  outline: 0.25rem solid #2491ff;
  outline-offset: 0rem;
}
.usa-link--external {
  display: inline;
}
.usa-link--external::before {
  position: absolute;
  left: -999em;
  right: auto;
  content: "External.";
}
.usa-link--external[target=_blank]::before {
  position: absolute;
  left: -999em;
  right: auto;
  content: "External, opens in a new tab.";
}
.usa-link--external::after {
  background-image: url("../launch-ITYHD56Q.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 1.75ex 1.75ex;
  display: inline-block;
  height: 1.75ex;
  width: 1.75ex;
  content: "";
  display: inline;
  margin-top: 0.7ex;
  margin-left: 2px;
  padding-left: 1.75ex;
  vertical-align: middle;
}
@supports (mask: url()) {
  .usa-link--external::after {
    background: none;
    background-color: currentColor;
    -webkit-mask-image: url("../launch-ITYHD56Q.svg"), linear-gradient(transparent, transparent);
    mask-image: url("../launch-ITYHD56Q.svg"), linear-gradient(transparent, transparent);
    -webkit-mask-position: center center;
    mask-position: center center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: 1.75ex 1.75ex;
    mask-size: 1.75ex 1.75ex;
  }
}
.usa-link--external.usa-link--alt {
  display: inline;
}
.usa-link--external.usa-link--alt::before {
  position: absolute;
  left: -999em;
  right: auto;
  content: "External.";
}
.usa-link--external.usa-link--alt[target=_blank]::before {
  position: absolute;
  left: -999em;
  right: auto;
  content: "External, opens in a new tab.";
}
.usa-link--external.usa-link--alt::after {
  background-image: url("../launch--white-RFNMNFON.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 1.75ex 1.75ex;
  display: inline-block;
  height: 1.75ex;
  width: 1.75ex;
  content: "";
  display: inline;
  margin-top: 0.7ex;
  margin-left: 2px;
  padding-left: 1.75ex;
  vertical-align: middle;
}
@supports (mask: url()) {
  .usa-link--external.usa-link--alt::after {
    background: none;
    background-color: currentColor;
    -webkit-mask-image: url("../launch-ITYHD56Q.svg"), linear-gradient(transparent, transparent);
    mask-image: url("../launch-ITYHD56Q.svg"), linear-gradient(transparent, transparent);
    -webkit-mask-position: center center;
    mask-position: center center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: 1.75ex 1.75ex;
    mask-size: 1.75ex 1.75ex;
  }
}
.usa-list {
  margin-bottom: 1em;
  margin-top: 1em;
  line-height: 1.6;
  padding-left: 3ch;
}
.usa-list:last-child {
  margin-bottom: 0;
}
.usa-list ul,
.usa-list ol {
  margin-top: 0.25em;
}
.usa-list li {
  margin-bottom: 0.25em;
  max-width: 68ex;
}
.usa-list li:last-child {
  margin-bottom: 0;
}
.usa-list--unstyled {
  margin-bottom: 0;
  margin-top: 0;
  list-style-type: none;
  padding-left: 0;
}
.usa-list--unstyled > li {
  margin-bottom: 0;
  max-width: unset;
}
.usa-prose .usa-list--unstyled {
  margin-bottom: 0;
  margin-top: 0;
  list-style-type: none;
  padding-left: 0;
}
.usa-prose .usa-list--unstyled > li {
  margin-bottom: 0;
  max-width: unset;
}
.usa-paragraph {
  line-height: 1.6;
  max-width: 68ex;
  margin-bottom: 0;
  margin-top: 0;
}
* + .usa-paragraph {
  margin-top: 1em;
}
.usa-paragraph + * {
  margin-top: 1em;
}
.usa-prose {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.08rem;
  line-height: 1.6;
}
.usa-prose > ul,
.usa-prose > ol {
  margin-bottom: 1em;
  margin-top: 1em;
  line-height: 1.6;
  padding-left: 3ch;
}
.usa-prose > ul:last-child,
.usa-prose > ol:last-child {
  margin-bottom: 0;
}
.usa-prose > ul ul,
.usa-prose > ul ol,
.usa-prose > ol ul,
.usa-prose > ol ol {
  margin-top: 0.25em;
}
.usa-prose > ul li,
.usa-prose > ol li {
  margin-bottom: 0.25em;
  max-width: 68ex;
}
.usa-prose > ul li:last-child,
.usa-prose > ol li:last-child {
  margin-bottom: 0;
}
.usa-prose > table {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.08rem;
  line-height: 1.6;
  border-collapse: collapse;
  border-spacing: 0;
  color: #11181d;
  margin: 1.25rem 0;
  text-align: left;
}
.usa-prose > table thead td,
.usa-prose > table tfoot td,
.usa-prose > table th {
  background-clip: padding-box;
  line-height: 1.3;
  background-color: #f0f0f0;
  color: #11181d;
}
.usa-prose > table th,
.usa-prose > table td {
  border: 1px solid #11181d;
  padding: 0.5rem 1rem;
}
.usa-prose > table td {
  background-color: white;
  font-weight: normal;
}
.usa-prose > table caption {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 0.95rem;
  font-weight: 700;
  margin-bottom: 0.75rem;
  text-align: left;
}
.usa-prose > table th[data-sortable] {
  padding-right: 2.5rem;
  position: relative;
}
.usa-prose > table th[data-sortable]::after {
  border-bottom-color: transparent;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  bottom: 0;
  content: "";
  height: 0;
  left: 0;
  position: absolute;
  width: 100%;
}
.usa-prose > table th[data-sortable]:not([aria-sort]) .usa-table__header__button,
.usa-prose > table th[data-sortable][aria-sort=none] .usa-table__header__button {
  color: #046B99;
  text-decoration: underline;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  font-weight: normal;
  justify-content: normal;
  text-align: left;
  margin: 0;
  padding: 0;
  width: auto;
  height: 2rem;
  width: 2rem;
  background-position: center center;
  background-size: 1.5rem;
  color: #71767a;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  position: absolute;
  right: 0.25rem;
  text-align: center;
  text-decoration: none;
  top: 50%;
  transform: translate(0, -50%);
}
.usa-prose > table th[data-sortable]:not([aria-sort]) .usa-table__header__button:visited,
.usa-prose > table th[data-sortable][aria-sort=none] .usa-table__header__button:visited {
  color: #54278f;
}
.usa-prose > table th[data-sortable]:not([aria-sort]) .usa-table__header__button:hover,
.usa-prose > table th[data-sortable][aria-sort=none] .usa-table__header__button:hover {
  color: #0b4778;
}
.usa-prose > table th[data-sortable]:not([aria-sort]) .usa-table__header__button:active,
.usa-prose > table th[data-sortable][aria-sort=none] .usa-table__header__button:active {
  color: #1C304A;
}
.usa-prose > table th[data-sortable]:not([aria-sort]) .usa-table__header__button:focus,
.usa-prose > table th[data-sortable][aria-sort=none] .usa-table__header__button:focus {
  outline: 0.25rem solid #2491ff;
  outline-offset: 0rem;
}
.usa-prose > table th[data-sortable]:not([aria-sort]) .usa-table__header__button:hover,
.usa-prose > table th[data-sortable]:not([aria-sort]) .usa-table__header__button.usa-button--hover,
.usa-prose > table th[data-sortable]:not([aria-sort]) .usa-table__header__button:disabled:hover,
.usa-prose > table th[data-sortable]:not([aria-sort]) .usa-table__header__button[aria-disabled=true]:hover,
.usa-prose > table th[data-sortable]:not([aria-sort]) .usa-table__header__button:disabled.usa-button--hover,
.usa-prose > table th[data-sortable]:not([aria-sort]) .usa-table__header__button[aria-disabled=true].usa-button--hover,
.usa-prose > table th[data-sortable]:not([aria-sort]) .usa-table__header__button:active,
.usa-prose > table th[data-sortable]:not([aria-sort]) .usa-table__header__button.usa-button--active,
.usa-prose > table th[data-sortable]:not([aria-sort]) .usa-table__header__button:disabled:active,
.usa-prose > table th[data-sortable]:not([aria-sort]) .usa-table__header__button[aria-disabled=true]:active,
.usa-prose > table th[data-sortable]:not([aria-sort]) .usa-table__header__button:disabled.usa-button--active,
.usa-prose > table th[data-sortable]:not([aria-sort]) .usa-table__header__button[aria-disabled=true].usa-button--active,
.usa-prose > table th[data-sortable]:not([aria-sort]) .usa-table__header__button:disabled:focus,
.usa-prose > table th[data-sortable]:not([aria-sort]) .usa-table__header__button[aria-disabled=true]:focus,
.usa-prose > table th[data-sortable]:not([aria-sort]) .usa-table__header__button:disabled.usa-focus,
.usa-prose > table th[data-sortable]:not([aria-sort]) .usa-table__header__button[aria-disabled=true].usa-focus,
.usa-prose > table th[data-sortable]:not([aria-sort]) .usa-table__header__button:disabled,
.usa-prose > table th[data-sortable]:not([aria-sort]) .usa-table__header__button[aria-disabled=true],
.usa-prose > table th[data-sortable]:not([aria-sort]) .usa-table__header__button.usa-button--disabled,
.usa-prose > table th[data-sortable][aria-sort=none] .usa-table__header__button:hover,
.usa-prose > table th[data-sortable][aria-sort=none] .usa-table__header__button.usa-button--hover,
.usa-prose > table th[data-sortable][aria-sort=none] .usa-table__header__button:disabled:hover,
.usa-prose > table th[data-sortable][aria-sort=none] .usa-table__header__button[aria-disabled=true]:hover,
.usa-prose > table th[data-sortable][aria-sort=none] .usa-table__header__button:disabled.usa-button--hover,
.usa-prose > table th[data-sortable][aria-sort=none] .usa-table__header__button[aria-disabled=true].usa-button--hover,
.usa-prose > table th[data-sortable][aria-sort=none] .usa-table__header__button:active,
.usa-prose > table th[data-sortable][aria-sort=none] .usa-table__header__button.usa-button--active,
.usa-prose > table th[data-sortable][aria-sort=none] .usa-table__header__button:disabled:active,
.usa-prose > table th[data-sortable][aria-sort=none] .usa-table__header__button[aria-disabled=true]:active,
.usa-prose > table th[data-sortable][aria-sort=none] .usa-table__header__button:disabled.usa-button--active,
.usa-prose > table th[data-sortable][aria-sort=none] .usa-table__header__button[aria-disabled=true].usa-button--active,
.usa-prose > table th[data-sortable][aria-sort=none] .usa-table__header__button:disabled:focus,
.usa-prose > table th[data-sortable][aria-sort=none] .usa-table__header__button[aria-disabled=true]:focus,
.usa-prose > table th[data-sortable][aria-sort=none] .usa-table__header__button:disabled.usa-focus,
.usa-prose > table th[data-sortable][aria-sort=none] .usa-table__header__button[aria-disabled=true].usa-focus,
.usa-prose > table th[data-sortable][aria-sort=none] .usa-table__header__button:disabled,
.usa-prose > table th[data-sortable][aria-sort=none] .usa-table__header__button[aria-disabled=true],
.usa-prose > table th[data-sortable][aria-sort=none] .usa-table__header__button.usa-button--disabled {
  background-color: transparent;
  box-shadow: none;
  text-decoration: underline;
}
.usa-prose > table th[data-sortable]:not([aria-sort]) .usa-table__header__button.usa-button--hover,
.usa-prose > table th[data-sortable][aria-sort=none] .usa-table__header__button.usa-button--hover {
  color: #0b4778;
}
.usa-prose > table th[data-sortable]:not([aria-sort]) .usa-table__header__button.usa-button--active,
.usa-prose > table th[data-sortable][aria-sort=none] .usa-table__header__button.usa-button--active {
  color: #1C304A;
}
.usa-prose > table th[data-sortable]:not([aria-sort]) .usa-table__header__button:disabled,
.usa-prose > table th[data-sortable]:not([aria-sort]) .usa-table__header__button[aria-disabled=true],
.usa-prose > table th[data-sortable]:not([aria-sort]) .usa-table__header__button:disabled:hover,
.usa-prose > table th[data-sortable]:not([aria-sort]) .usa-table__header__button[aria-disabled=true]:hover,
.usa-prose > table th[data-sortable]:not([aria-sort]) .usa-table__header__button[aria-disabled=true]:focus,
.usa-prose > table th[data-sortable][aria-sort=none] .usa-table__header__button:disabled,
.usa-prose > table th[data-sortable][aria-sort=none] .usa-table__header__button[aria-disabled=true],
.usa-prose > table th[data-sortable][aria-sort=none] .usa-table__header__button:disabled:hover,
.usa-prose > table th[data-sortable][aria-sort=none] .usa-table__header__button[aria-disabled=true]:hover,
.usa-prose > table th[data-sortable][aria-sort=none] .usa-table__header__button[aria-disabled=true]:focus {
  color: #757575;
}
@media (forced-colors: active) {
  .usa-prose > table th[data-sortable]:not([aria-sort]) .usa-table__header__button:disabled,
  .usa-prose > table th[data-sortable]:not([aria-sort]) .usa-table__header__button[aria-disabled=true],
  .usa-prose > table th[data-sortable]:not([aria-sort]) .usa-table__header__button:disabled:hover,
  .usa-prose > table th[data-sortable]:not([aria-sort]) .usa-table__header__button[aria-disabled=true]:hover,
  .usa-prose > table th[data-sortable]:not([aria-sort]) .usa-table__header__button[aria-disabled=true]:focus,
  .usa-prose > table th[data-sortable][aria-sort=none] .usa-table__header__button:disabled,
  .usa-prose > table th[data-sortable][aria-sort=none] .usa-table__header__button[aria-disabled=true],
  .usa-prose > table th[data-sortable][aria-sort=none] .usa-table__header__button:disabled:hover,
  .usa-prose > table th[data-sortable][aria-sort=none] .usa-table__header__button[aria-disabled=true]:hover,
  .usa-prose > table th[data-sortable][aria-sort=none] .usa-table__header__button[aria-disabled=true]:focus {
    color: GrayText;
  }
}
.usa-prose > table th[data-sortable]:not([aria-sort]) .usa-table__header__button .usa-icon,
.usa-prose > table th[data-sortable][aria-sort=none] .usa-table__header__button .usa-icon {
  height: 1.5rem;
  width: 1.5rem;
  vertical-align: middle;
}
.usa-prose > table th[data-sortable]:not([aria-sort]) .usa-table__header__button .usa-icon > g,
.usa-prose > table th[data-sortable][aria-sort=none] .usa-table__header__button .usa-icon > g {
  fill: transparent;
}
.usa-prose > table th[data-sortable]:not([aria-sort]) .usa-table__header__button .usa-icon > g.unsorted,
.usa-prose > table th[data-sortable][aria-sort=none] .usa-table__header__button .usa-icon > g.unsorted {
  fill: #11181d;
}
.usa-prose > table th[data-sortable]:not([aria-sort]) .usa-table__header__button:hover .usa-icon > g.unsorted,
.usa-prose > table th[data-sortable][aria-sort=none] .usa-table__header__button:hover .usa-icon > g.unsorted {
  fill: black;
}
.usa-prose > table th[data-sortable][aria-sort=descending],
.usa-prose > table th[data-sortable][aria-sort=ascending] {
  background-color: #52daf2;
}
.usa-prose > table th[data-sortable][aria-sort=descending] .usa-table__header__button {
  color: #046B99;
  text-decoration: underline;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  font-weight: normal;
  justify-content: normal;
  text-align: left;
  margin: 0;
  padding: 0;
  width: auto;
  height: 2rem;
  width: 2rem;
  background-position: center center;
  background-size: 1.5rem;
  color: #71767a;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  position: absolute;
  right: 0.25rem;
  text-align: center;
  text-decoration: none;
  top: 50%;
  transform: translate(0, -50%);
}
.usa-prose > table th[data-sortable][aria-sort=descending] .usa-table__header__button:visited {
  color: #54278f;
}
.usa-prose > table th[data-sortable][aria-sort=descending] .usa-table__header__button:hover {
  color: #0b4778;
}
.usa-prose > table th[data-sortable][aria-sort=descending] .usa-table__header__button:active {
  color: #1C304A;
}
.usa-prose > table th[data-sortable][aria-sort=descending] .usa-table__header__button:focus {
  outline: 0.25rem solid #2491ff;
  outline-offset: 0rem;
}
.usa-prose > table th[data-sortable][aria-sort=descending] .usa-table__header__button:hover,
.usa-prose > table th[data-sortable][aria-sort=descending] .usa-table__header__button.usa-button--hover,
.usa-prose > table th[data-sortable][aria-sort=descending] .usa-table__header__button:disabled:hover,
.usa-prose > table th[data-sortable][aria-sort=descending] .usa-table__header__button[aria-disabled=true]:hover,
.usa-prose > table th[data-sortable][aria-sort=descending] .usa-table__header__button:disabled.usa-button--hover,
.usa-prose > table th[data-sortable][aria-sort=descending] .usa-table__header__button[aria-disabled=true].usa-button--hover,
.usa-prose > table th[data-sortable][aria-sort=descending] .usa-table__header__button:active,
.usa-prose > table th[data-sortable][aria-sort=descending] .usa-table__header__button.usa-button--active,
.usa-prose > table th[data-sortable][aria-sort=descending] .usa-table__header__button:disabled:active,
.usa-prose > table th[data-sortable][aria-sort=descending] .usa-table__header__button[aria-disabled=true]:active,
.usa-prose > table th[data-sortable][aria-sort=descending] .usa-table__header__button:disabled.usa-button--active,
.usa-prose > table th[data-sortable][aria-sort=descending] .usa-table__header__button[aria-disabled=true].usa-button--active,
.usa-prose > table th[data-sortable][aria-sort=descending] .usa-table__header__button:disabled:focus,
.usa-prose > table th[data-sortable][aria-sort=descending] .usa-table__header__button[aria-disabled=true]:focus,
.usa-prose > table th[data-sortable][aria-sort=descending] .usa-table__header__button:disabled.usa-focus,
.usa-prose > table th[data-sortable][aria-sort=descending] .usa-table__header__button[aria-disabled=true].usa-focus,
.usa-prose > table th[data-sortable][aria-sort=descending] .usa-table__header__button:disabled,
.usa-prose > table th[data-sortable][aria-sort=descending] .usa-table__header__button[aria-disabled=true],
.usa-prose > table th[data-sortable][aria-sort=descending] .usa-table__header__button.usa-button--disabled {
  background-color: transparent;
  box-shadow: none;
  text-decoration: underline;
}
.usa-prose > table th[data-sortable][aria-sort=descending] .usa-table__header__button.usa-button--hover {
  color: #0b4778;
}
.usa-prose > table th[data-sortable][aria-sort=descending] .usa-table__header__button.usa-button--active {
  color: #1C304A;
}
.usa-prose > table th[data-sortable][aria-sort=descending] .usa-table__header__button:disabled,
.usa-prose > table th[data-sortable][aria-sort=descending] .usa-table__header__button[aria-disabled=true],
.usa-prose > table th[data-sortable][aria-sort=descending] .usa-table__header__button:disabled:hover,
.usa-prose > table th[data-sortable][aria-sort=descending] .usa-table__header__button[aria-disabled=true]:hover,
.usa-prose > table th[data-sortable][aria-sort=descending] .usa-table__header__button[aria-disabled=true]:focus {
  color: #757575;
}
@media (forced-colors: active) {
  .usa-prose > table th[data-sortable][aria-sort=descending] .usa-table__header__button:disabled,
  .usa-prose > table th[data-sortable][aria-sort=descending] .usa-table__header__button[aria-disabled=true],
  .usa-prose > table th[data-sortable][aria-sort=descending] .usa-table__header__button:disabled:hover,
  .usa-prose > table th[data-sortable][aria-sort=descending] .usa-table__header__button[aria-disabled=true]:hover,
  .usa-prose > table th[data-sortable][aria-sort=descending] .usa-table__header__button[aria-disabled=true]:focus {
    color: GrayText;
  }
}
.usa-prose > table th[data-sortable][aria-sort=descending] .usa-table__header__button .usa-icon {
  height: 1.5rem;
  width: 1.5rem;
  vertical-align: middle;
}
.usa-prose > table th[data-sortable][aria-sort=descending] .usa-table__header__button .usa-icon > g {
  fill: transparent;
}
.usa-prose > table th[data-sortable][aria-sort=descending] .usa-table__header__button .usa-icon > g.descending {
  fill: #11181d;
}
.usa-prose > table th[data-sortable][aria-sort=ascending] .usa-table__header__button {
  color: #046B99;
  text-decoration: underline;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  font-weight: normal;
  justify-content: normal;
  text-align: left;
  margin: 0;
  padding: 0;
  width: auto;
  height: 2rem;
  width: 2rem;
  background-position: center center;
  background-size: 1.5rem;
  color: #71767a;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  position: absolute;
  right: 0.25rem;
  text-align: center;
  text-decoration: none;
  top: 50%;
  transform: translate(0, -50%);
}
.usa-prose > table th[data-sortable][aria-sort=ascending] .usa-table__header__button:visited {
  color: #54278f;
}
.usa-prose > table th[data-sortable][aria-sort=ascending] .usa-table__header__button:hover {
  color: #0b4778;
}
.usa-prose > table th[data-sortable][aria-sort=ascending] .usa-table__header__button:active {
  color: #1C304A;
}
.usa-prose > table th[data-sortable][aria-sort=ascending] .usa-table__header__button:focus {
  outline: 0.25rem solid #2491ff;
  outline-offset: 0rem;
}
.usa-prose > table th[data-sortable][aria-sort=ascending] .usa-table__header__button:hover,
.usa-prose > table th[data-sortable][aria-sort=ascending] .usa-table__header__button.usa-button--hover,
.usa-prose > table th[data-sortable][aria-sort=ascending] .usa-table__header__button:disabled:hover,
.usa-prose > table th[data-sortable][aria-sort=ascending] .usa-table__header__button[aria-disabled=true]:hover,
.usa-prose > table th[data-sortable][aria-sort=ascending] .usa-table__header__button:disabled.usa-button--hover,
.usa-prose > table th[data-sortable][aria-sort=ascending] .usa-table__header__button[aria-disabled=true].usa-button--hover,
.usa-prose > table th[data-sortable][aria-sort=ascending] .usa-table__header__button:active,
.usa-prose > table th[data-sortable][aria-sort=ascending] .usa-table__header__button.usa-button--active,
.usa-prose > table th[data-sortable][aria-sort=ascending] .usa-table__header__button:disabled:active,
.usa-prose > table th[data-sortable][aria-sort=ascending] .usa-table__header__button[aria-disabled=true]:active,
.usa-prose > table th[data-sortable][aria-sort=ascending] .usa-table__header__button:disabled.usa-button--active,
.usa-prose > table th[data-sortable][aria-sort=ascending] .usa-table__header__button[aria-disabled=true].usa-button--active,
.usa-prose > table th[data-sortable][aria-sort=ascending] .usa-table__header__button:disabled:focus,
.usa-prose > table th[data-sortable][aria-sort=ascending] .usa-table__header__button[aria-disabled=true]:focus,
.usa-prose > table th[data-sortable][aria-sort=ascending] .usa-table__header__button:disabled.usa-focus,
.usa-prose > table th[data-sortable][aria-sort=ascending] .usa-table__header__button[aria-disabled=true].usa-focus,
.usa-prose > table th[data-sortable][aria-sort=ascending] .usa-table__header__button:disabled,
.usa-prose > table th[data-sortable][aria-sort=ascending] .usa-table__header__button[aria-disabled=true],
.usa-prose > table th[data-sortable][aria-sort=ascending] .usa-table__header__button.usa-button--disabled {
  background-color: transparent;
  box-shadow: none;
  text-decoration: underline;
}
.usa-prose > table th[data-sortable][aria-sort=ascending] .usa-table__header__button.usa-button--hover {
  color: #0b4778;
}
.usa-prose > table th[data-sortable][aria-sort=ascending] .usa-table__header__button.usa-button--active {
  color: #1C304A;
}
.usa-prose > table th[data-sortable][aria-sort=ascending] .usa-table__header__button:disabled,
.usa-prose > table th[data-sortable][aria-sort=ascending] .usa-table__header__button[aria-disabled=true],
.usa-prose > table th[data-sortable][aria-sort=ascending] .usa-table__header__button:disabled:hover,
.usa-prose > table th[data-sortable][aria-sort=ascending] .usa-table__header__button[aria-disabled=true]:hover,
.usa-prose > table th[data-sortable][aria-sort=ascending] .usa-table__header__button[aria-disabled=true]:focus {
  color: #757575;
}
@media (forced-colors: active) {
  .usa-prose > table th[data-sortable][aria-sort=ascending] .usa-table__header__button:disabled,
  .usa-prose > table th[data-sortable][aria-sort=ascending] .usa-table__header__button[aria-disabled=true],
  .usa-prose > table th[data-sortable][aria-sort=ascending] .usa-table__header__button:disabled:hover,
  .usa-prose > table th[data-sortable][aria-sort=ascending] .usa-table__header__button[aria-disabled=true]:hover,
  .usa-prose > table th[data-sortable][aria-sort=ascending] .usa-table__header__button[aria-disabled=true]:focus {
    color: GrayText;
  }
}
.usa-prose > table th[data-sortable][aria-sort=ascending] .usa-table__header__button .usa-icon {
  height: 1.5rem;
  width: 1.5rem;
  vertical-align: middle;
}
.usa-prose > table th[data-sortable][aria-sort=ascending] .usa-table__header__button .usa-icon > g {
  fill: transparent;
}
.usa-prose > table th[data-sortable][aria-sort=ascending] .usa-table__header__button .usa-icon > g.ascending {
  fill: #11181d;
}
.usa-prose > table thead th[aria-sort] {
  background-color: #52daf2;
  color: #11181d;
}
.usa-prose > table td[data-sort-active],
.usa-prose > table th[data-sort-active] {
  background-color: #a8f2ff;
  color: #11181d;
}
.usa-prose > .usa-table--borderless {
}
.usa-prose > .usa-table--borderless th,
.usa-prose > .usa-table--borderless thead td,
.usa-prose > .usa-table--borderless tfoot td {
  background-color: white;
}
.usa-prose > .usa-table--borderless th {
  border-top: 0;
  color: #11181d;
}
.usa-prose > .usa-table--borderless th[aria-sort] {
  color: #11181d;
}
.usa-prose > .usa-table--borderless th[data-sortable]:not([aria-sort]) .usa-table__header__button .usa-icon > g.unsorted {
  fill: #11181d;
}
.usa-prose > .usa-table--borderless th[data-sortable]:not([aria-sort]) .usa-table__header__button:hover .usa-icon > g.unsorted {
  fill: black;
}
.usa-prose > .usa-table--borderless th,
.usa-prose > .usa-table--borderless td {
  border-left: 0;
  border-right: 0;
}
.usa-prose > .usa-table--compact th,
.usa-prose > .usa-table--compact td {
  padding: 0.25rem 0.75rem;
}
.usa-prose > .usa-table--striped tbody tr:nth-child(odd) td {
  background-color: #f0f0f0;
  color: #11181d;
}
.usa-prose > .usa-table--striped tbody tr:nth-child(odd) td[data-sort-active] {
  background-color: #c3ebfa;
  color: #11181d;
}
@media all and (max-width: 29.99em) {
  .usa-prose > .usa-table--stacked thead {
    display: none;
  }
  .usa-prose > .usa-table--stacked th {
    background-color: white;
  }
  .usa-prose > .usa-table--stacked th,
  .usa-prose > .usa-table--stacked td {
    border-bottom-width: 0;
    display: block;
    width: 100%;
  }
  .usa-prose > .usa-table--stacked tr {
    border-bottom: 0.25rem solid #11181d;
    border-top-width: 0;
    width: 100%;
  }
  .usa-prose > .usa-table--stacked tr th:first-child,
  .usa-prose > .usa-table--stacked tr td:first-child {
    border-top-width: 0;
  }
  .usa-prose > .usa-table--stacked tr:nth-child(odd) td,
  .usa-prose > .usa-table--stacked tr:nth-child(odd) th {
    background-color: inherit;
  }
  .usa-prose > .usa-table--stacked tr:first-child th:first-child,
  .usa-prose > .usa-table--stacked tr:first-child td:first-child {
    border-top: 0.25rem solid #11181d;
  }
  .usa-prose > .usa-table--stacked th[data-label],
  .usa-prose > .usa-table--stacked td[data-label] {
    padding-bottom: 0.75rem;
  }
  .usa-prose > .usa-table--stacked th[data-label]:before,
  .usa-prose > .usa-table--stacked td[data-label]:before {
    content: attr(data-label);
    display: block;
    font-weight: 700;
    margin: -0.5rem -1rem 0rem;
    padding: 0.75rem 1rem 0.25rem;
  }
}
@media all and (max-width: 29.99em) {
  .usa-prose > .usa-table--stacked-header thead {
    display: none;
  }
  .usa-prose > .usa-table--stacked-header th {
    background-color: white;
  }
  .usa-prose > .usa-table--stacked-header th,
  .usa-prose > .usa-table--stacked-header td {
    border-bottom-width: 0;
    display: block;
    width: 100%;
  }
  .usa-prose > .usa-table--stacked-header tr {
    border-bottom: 0.25rem solid #11181d;
    border-top-width: 0;
    width: 100%;
  }
  .usa-prose > .usa-table--stacked-header tr th:first-child,
  .usa-prose > .usa-table--stacked-header tr td:first-child {
    border-top-width: 0;
  }
  .usa-prose > .usa-table--stacked-header tr:nth-child(odd) td,
  .usa-prose > .usa-table--stacked-header tr:nth-child(odd) th {
    background-color: inherit;
  }
  .usa-prose > .usa-table--stacked-header tr:first-child th:first-child,
  .usa-prose > .usa-table--stacked-header tr:first-child td:first-child {
    border-top: 0.25rem solid #11181d;
  }
  .usa-prose > .usa-table--stacked-header th[data-label],
  .usa-prose > .usa-table--stacked-header td[data-label] {
    padding-bottom: 0.75rem;
  }
  .usa-prose > .usa-table--stacked-header th[data-label]:before,
  .usa-prose > .usa-table--stacked-header td[data-label]:before {
    content: attr(data-label);
    display: block;
    font-weight: 700;
    margin: -0.5rem -1rem 0rem;
    padding: 0.75rem 1rem 0.25rem;
  }
  .usa-prose > .usa-table--stacked-header tr td:first-child,
  .usa-prose > .usa-table--stacked-header tr th:first-child {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 1.01rem;
    line-height: 1.2;
    background-color: #f0f0f0;
    color: #11181d;
    font-weight: 700;
    padding: 0.75rem 1rem;
  }
  .usa-prose > .usa-table--stacked-header tr td:first-child:before,
  .usa-prose > .usa-table--stacked-header tr th:first-child:before {
    display: none;
  }
}
.usa-prose > .width-mobile .usa-table--stacked thead {
  display: none;
}
.usa-prose > .width-mobile .usa-table--stacked th {
  background-color: white;
}
.usa-prose > .width-mobile .usa-table--stacked th,
.usa-prose > .width-mobile .usa-table--stacked td {
  border-bottom-width: 0;
  display: block;
  width: 100%;
}
.usa-prose > .width-mobile .usa-table--stacked tr {
  border-bottom: 0.25rem solid #11181d;
  border-top-width: 0;
  width: 100%;
}
.usa-prose > .width-mobile .usa-table--stacked tr th:first-child,
.usa-prose > .width-mobile .usa-table--stacked tr td:first-child {
  border-top-width: 0;
}
.usa-prose > .width-mobile .usa-table--stacked tr:nth-child(odd) td,
.usa-prose > .width-mobile .usa-table--stacked tr:nth-child(odd) th {
  background-color: inherit;
}
.usa-prose > .width-mobile .usa-table--stacked tr:first-child th:first-child,
.usa-prose > .width-mobile .usa-table--stacked tr:first-child td:first-child {
  border-top: 0.25rem solid #11181d;
}
.usa-prose > .width-mobile .usa-table--stacked th[data-label],
.usa-prose > .width-mobile .usa-table--stacked td[data-label] {
  padding-bottom: 0.75rem;
}
.usa-prose > .width-mobile .usa-table--stacked th[data-label]:before,
.usa-prose > .width-mobile .usa-table--stacked td[data-label]:before {
  content: attr(data-label);
  display: block;
  font-weight: 700;
  margin: -0.5rem -1rem 0rem;
  padding: 0.75rem 1rem 0.25rem;
}
.usa-prose > .width-mobile .usa-table--stacked-header thead {
  display: none;
}
.usa-prose > .width-mobile .usa-table--stacked-header th {
  background-color: white;
}
.usa-prose > .width-mobile .usa-table--stacked-header th,
.usa-prose > .width-mobile .usa-table--stacked-header td {
  border-bottom-width: 0;
  display: block;
  width: 100%;
}
.usa-prose > .width-mobile .usa-table--stacked-header tr {
  border-bottom: 0.25rem solid #11181d;
  border-top-width: 0;
  width: 100%;
}
.usa-prose > .width-mobile .usa-table--stacked-header tr th:first-child,
.usa-prose > .width-mobile .usa-table--stacked-header tr td:first-child {
  border-top-width: 0;
}
.usa-prose > .width-mobile .usa-table--stacked-header tr:nth-child(odd) td,
.usa-prose > .width-mobile .usa-table--stacked-header tr:nth-child(odd) th {
  background-color: inherit;
}
.usa-prose > .width-mobile .usa-table--stacked-header tr:first-child th:first-child,
.usa-prose > .width-mobile .usa-table--stacked-header tr:first-child td:first-child {
  border-top: 0.25rem solid #11181d;
}
.usa-prose > .width-mobile .usa-table--stacked-header th[data-label],
.usa-prose > .width-mobile .usa-table--stacked-header td[data-label] {
  padding-bottom: 0.75rem;
}
.usa-prose > .width-mobile .usa-table--stacked-header th[data-label]:before,
.usa-prose > .width-mobile .usa-table--stacked-header td[data-label]:before {
  content: attr(data-label);
  display: block;
  font-weight: 700;
  margin: -0.5rem -1rem 0rem;
  padding: 0.75rem 1rem 0.25rem;
}
.usa-prose > .width-mobile .usa-table--stacked-header tr td:first-child,
.usa-prose > .width-mobile .usa-table--stacked-header tr th:first-child {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.01rem;
  line-height: 1.2;
  background-color: #f0f0f0;
  color: #11181d;
  font-weight: 700;
  padding: 0.75rem 1rem;
}
.usa-prose > .width-mobile .usa-table--stacked-header tr td:first-child:before,
.usa-prose > .width-mobile .usa-table--stacked-header tr th:first-child:before {
  display: none;
}
.usa-prose > .usa-table-container--scrollable {
  margin: 1.25rem 0;
  overflow-y: hidden;
}
.usa-prose > .usa-table-container--scrollable .usa-table {
  margin: 0;
}
.usa-prose > .usa-table-container--scrollable td {
  white-space: nowrap;
}
.usa-prose > p {
  line-height: 1.6;
  max-width: 68ex;
}
.usa-prose > h1,
.usa-prose > h2,
.usa-prose > h3,
.usa-prose > h4,
.usa-prose > h5,
.usa-prose > h6 {
  margin-bottom: 0;
  margin-top: 0;
  clear: both;
}
.usa-prose > * + * {
  margin-top: 1em;
  margin-bottom: 0;
}
.usa-prose > * + h1,
.usa-prose > * + h2,
.usa-prose > * + h3,
.usa-prose > * + h4,
.usa-prose > * + h5,
.usa-prose > * + h6 {
  margin-top: 1.5em;
}
.usa-prose > h1 {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 2.54rem;
  line-height: 1.2;
  font-weight: 700;
}
.usa-prose > h2 {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 2.03rem;
  line-height: 1.2;
  font-weight: 700;
}
.usa-prose > h3 {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.39rem;
  line-height: 1.2;
  font-weight: 700;
}
.usa-prose > h4 {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.01rem;
  line-height: 1.2;
  font-weight: 700;
}
.usa-prose > h5 {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 0.95rem;
  line-height: 1.2;
  font-weight: 700;
}
.usa-prose > h6 {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 0.82rem;
  line-height: 1.2;
  font-weight: normal;
  letter-spacing: 0.025em;
  text-transform: uppercase;
}
.usa-accordion {
  margin-bottom: 0;
  margin-top: 0;
  list-style-type: none;
  padding-left: 0;
  color: #11181d;
  margin: 0;
  padding: 0;
  width: 100%;
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.08rem;
  line-height: 1.6;
}
.usa-accordion > li {
  margin-bottom: 0;
  max-width: unset;
}
.usa-accordion > ul li ul {
  list-style: disc;
}
.usa-accordion > ul li ul > li > ul {
  list-style: circle;
}
.usa-accordion > ul li ul > li > ul > li > ul {
  list-style: square;
}
.usa-accordion + .usa-accordion,
.usa-accordion + .usa-accordion--bordered {
  margin-top: 0.5rem;
}
.usa-accordion--bordered .usa-accordion__content {
  border-bottom: 0.25rem solid #f0f0f0;
  border-left: 0.25rem solid #f0f0f0;
  border-right: 0.25rem solid #f0f0f0;
  padding-bottom: 1rem;
}
.usa-accordion--bordered .usa-accordion__heading {
  margin-bottom: 0;
}
.usa-accordion__heading,
.usa-prose .usa-accordion__heading {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.08rem;
  line-height: 1;
  margin: 0;
}
.usa-accordion__heading:not(:first-child),
.usa-prose .usa-accordion__heading:not(:first-child) {
  margin-top: 0.5rem;
}
.usa-accordion__content {
  color: #11181d;
  background-color: white;
  margin-top: 0;
  overflow: auto;
  padding: 1rem 1.25rem calc(1rem - 0.25rem) 1.25rem;
}
.usa-accordion__content > *:first-child {
  margin-top: 0;
}
.usa-accordion__content > *:last-child {
  margin-bottom: 0;
}
.usa-accordion__button {
  color: #046B99;
  text-decoration: underline;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  font-weight: normal;
  justify-content: normal;
  text-align: left;
  margin: 0;
  padding: 0;
  width: auto;
  color: #11181d;
  background-color: #f0f0f0;
  background-image: url("../remove-6IAU2W4S.svg"), linear-gradient(transparent, transparent);
  background-repeat: no-repeat;
  background-position: right 1.25rem center;
  background-size: 1.5rem;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  margin: 0;
  padding: 1rem 3.5rem 1rem 1.25rem;
  text-decoration: none;
  width: 100%;
}
.usa-accordion__button:visited {
  color: #54278f;
}
.usa-accordion__button:hover {
  color: #0b4778;
}
.usa-accordion__button:active {
  color: #1C304A;
}
.usa-accordion__button:focus {
  outline: 0.25rem solid #2491ff;
  outline-offset: 0rem;
}
.usa-accordion__button:hover,
.usa-accordion__button.usa-button--hover,
.usa-accordion__button:disabled:hover,
.usa-accordion__button[aria-disabled=true]:hover,
.usa-accordion__button:disabled.usa-button--hover,
.usa-accordion__button[aria-disabled=true].usa-button--hover,
.usa-accordion__button:active,
.usa-accordion__button.usa-button--active,
.usa-accordion__button:disabled:active,
.usa-accordion__button[aria-disabled=true]:active,
.usa-accordion__button:disabled.usa-button--active,
.usa-accordion__button[aria-disabled=true].usa-button--active,
.usa-accordion__button:disabled:focus,
.usa-accordion__button[aria-disabled=true]:focus,
.usa-accordion__button:disabled.usa-focus,
.usa-accordion__button[aria-disabled=true].usa-focus,
.usa-accordion__button:disabled,
.usa-accordion__button[aria-disabled=true],
.usa-accordion__button.usa-button--disabled {
  background-color: transparent;
  box-shadow: none;
  text-decoration: underline;
}
.usa-accordion__button.usa-button--hover {
  color: #0b4778;
}
.usa-accordion__button.usa-button--active {
  color: #1C304A;
}
.usa-accordion__button:disabled,
.usa-accordion__button[aria-disabled=true],
.usa-accordion__button:disabled:hover,
.usa-accordion__button[aria-disabled=true]:hover,
.usa-accordion__button[aria-disabled=true]:focus {
  color: #757575;
}
@media (forced-colors: active) {
  .usa-accordion__button:disabled,
  .usa-accordion__button[aria-disabled=true],
  .usa-accordion__button:disabled:hover,
  .usa-accordion__button[aria-disabled=true]:hover,
  .usa-accordion__button[aria-disabled=true]:focus {
    color: GrayText;
  }
}
.usa-accordion__button:hover {
  color: #11181d;
  background-color: #dfe1e2;
  background-image: url("../remove-6IAU2W4S.svg"), linear-gradient(transparent, transparent);
  background-repeat: no-repeat;
  text-decoration: none;
}
@media (forced-colors: active) {
  .usa-accordion__button {
    border: 2px solid transparent;
    position: relative;
  }
  .usa-accordion__button::before {
    background-image: url("../remove-6IAU2W4S.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 1.5rem 1.5rem;
    display: inline-block;
    height: 1.5rem;
    width: 1.5rem;
    height: 100%;
    position: absolute;
    right: 1.25rem;
    top: 0;
    content: "";
  }
  @supports (mask: url()) {
    .usa-accordion__button::before {
      background: none;
      background-color: ButtonText;
      -webkit-mask-image: url("../remove-6IAU2W4S.svg"), linear-gradient(transparent, transparent);
      mask-image: url("../remove-6IAU2W4S.svg"), linear-gradient(transparent, transparent);
      -webkit-mask-position: center center;
      mask-position: center center;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-size: 1.5rem 1.5rem;
      mask-size: 1.5rem 1.5rem;
    }
  }
}
.usa-accordion__button[aria-expanded=false] {
  background-image: url("../add-XJXD7HSN.svg"), linear-gradient(transparent, transparent);
  background-repeat: no-repeat;
  background-size: 1.5rem;
}
.usa-accordion__button[aria-expanded=false]:hover {
  background-image: url("../add-XJXD7HSN.svg"), linear-gradient(transparent, transparent);
  background-repeat: no-repeat;
}
@media (forced-colors: active) {
  .usa-accordion__button[aria-expanded=false]::before {
    background-image: url("../add-XJXD7HSN.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 1.5rem 1.5rem;
    display: inline-block;
    height: 1.5rem;
    width: 1.5rem;
    height: 100%;
    position: absolute;
    right: 1.25rem;
    top: 0;
  }
  @supports (mask: url()) {
    .usa-accordion__button[aria-expanded=false]::before {
      background: none;
      background-color: ButtonText;
      -webkit-mask-image: url("../add-XJXD7HSN.svg"), linear-gradient(transparent, transparent);
      mask-image: url("../add-XJXD7HSN.svg"), linear-gradient(transparent, transparent);
      -webkit-mask-position: center center;
      mask-position: center center;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-size: 1.5rem 1.5rem;
      mask-size: 1.5rem 1.5rem;
    }
  }
}
.usa-alert {
  background-color: #f0f0f0;
  border-left: 0.5rem solid #a9aeb1;
  color: #11181d;
}
.usa-alert .usa-alert__body {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.08rem;
  line-height: 1.6;
  padding-bottom: 1rem;
  padding-top: 1rem;
  position: relative;
}
.usa-alert .usa-alert__text {
  margin-bottom: 0;
  margin-top: 0;
}
.usa-alert .usa-alert__text:only-child {
  padding-bottom: 0;
  padding-top: 0;
}
.usa-alert .usa-alert__heading {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.39rem;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 0.5rem;
}
.usa-alert > .usa-list,
.usa-alert .usa-alert__body > .usa-list {
  padding-left: 2ch;
}
.usa-alert > .usa-list:last-child,
.usa-alert .usa-alert__body > .usa-list:last-child {
  margin-bottom: 0;
}
.usa-alert .usa-alert__body {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.usa-alert .usa-alert__body::before {
  left: 1.0833333333rem;
}
* + .usa-alert {
  margin-top: 1rem;
}
.usa-alert--success {
  background-color: #ecf3ec;
  border-left-color: #00a91c;
}
.usa-alert--success .usa-alert__body {
  color: #11181d;
  background-color: #ecf3ec;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 3.3333333333rem;
}
.usa-alert--success .usa-alert__body::before {
  background-image: url("../check_circle-FKYRF36F.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 2rem 2rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
  content: "";
  display: block;
  position: absolute;
  top: 0.6666666667rem;
}
@supports (mask: url()) {
  .usa-alert--success .usa-alert__body::before {
    background: none;
    background-color: #11181d;
    -webkit-mask-image: url("../check_circle-FKYRF36F.svg"), linear-gradient(transparent, transparent);
    mask-image: url("../check_circle-FKYRF36F.svg"), linear-gradient(transparent, transparent);
    -webkit-mask-position: center center;
    mask-position: center center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: 2rem 2rem;
    mask-size: 2rem 2rem;
  }
}
.usa-alert--success .usa-alert__body > * {
  margin-left: 2.5rem;
}
.usa-alert--success .usa-alert__body .usa-link {
  color: #046B99;
}
.usa-alert--success .usa-alert__body .usa-link:visited {
  color: #54278f;
}
.usa-alert--success .usa-alert__body .usa-link:hover,
.usa-alert--success .usa-alert__body .usa-link:active {
  color: #0b4778;
}
.usa-alert--warning {
  background-color: #faf3d1;
  border-left-color: #ffbe2e;
}
.usa-alert--warning .usa-alert__body {
  color: #11181d;
  background-color: #faf3d1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 3.3333333333rem;
}
.usa-alert--warning .usa-alert__body::before {
  background-image: url("../warning-5OR6W6GJ.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 2rem 2rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
  content: "";
  display: block;
  position: absolute;
  top: 0.6666666667rem;
}
@supports (mask: url()) {
  .usa-alert--warning .usa-alert__body::before {
    background: none;
    background-color: #11181d;
    -webkit-mask-image: url("../warning-5OR6W6GJ.svg"), linear-gradient(transparent, transparent);
    mask-image: url("../warning-5OR6W6GJ.svg"), linear-gradient(transparent, transparent);
    -webkit-mask-position: center center;
    mask-position: center center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: 2rem 2rem;
    mask-size: 2rem 2rem;
  }
}
.usa-alert--warning .usa-alert__body > * {
  margin-left: 2.5rem;
}
.usa-alert--warning .usa-alert__body .usa-link {
  color: #046B99;
}
.usa-alert--warning .usa-alert__body .usa-link:visited {
  color: #54278f;
}
.usa-alert--warning .usa-alert__body .usa-link:hover,
.usa-alert--warning .usa-alert__body .usa-link:active {
  color: #0b4778;
}
.usa-alert--error {
  background-color: #fff5ee;
  border-left-color: #d54309;
}
.usa-alert--error .usa-alert__body {
  color: #11181d;
  background-color: #fff5ee;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 3.3333333333rem;
}
.usa-alert--error .usa-alert__body::before {
  background-image: url("../error-3VQTC4AF.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 2rem 2rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
  content: "";
  display: block;
  position: absolute;
  top: 0.6666666667rem;
}
@supports (mask: url()) {
  .usa-alert--error .usa-alert__body::before {
    background: none;
    background-color: #11181d;
    -webkit-mask-image: url("../error-3VQTC4AF.svg"), linear-gradient(transparent, transparent);
    mask-image: url("../error-3VQTC4AF.svg"), linear-gradient(transparent, transparent);
    -webkit-mask-position: center center;
    mask-position: center center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: 2rem 2rem;
    mask-size: 2rem 2rem;
  }
}
.usa-alert--error .usa-alert__body > * {
  margin-left: 2.5rem;
}
.usa-alert--error .usa-alert__body .usa-link {
  color: #046B99;
}
.usa-alert--error .usa-alert__body .usa-link:visited {
  color: #54278f;
}
.usa-alert--error .usa-alert__body .usa-link:hover,
.usa-alert--error .usa-alert__body .usa-link:active {
  color: #0b4778;
}
.usa-alert--info {
  background-color: #e7f6f8;
  border-left-color: #00bde3;
}
.usa-alert--info .usa-alert__body {
  color: #11181d;
  background-color: #e7f6f8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 3.3333333333rem;
}
.usa-alert--info .usa-alert__body::before {
  background-image: url("../info-TBKQSD4V.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 2rem 2rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
  content: "";
  display: block;
  position: absolute;
  top: 0.6666666667rem;
}
@supports (mask: url()) {
  .usa-alert--info .usa-alert__body::before {
    background: none;
    background-color: #11181d;
    -webkit-mask-image: url("../info-TBKQSD4V.svg"), linear-gradient(transparent, transparent);
    mask-image: url("../info-TBKQSD4V.svg"), linear-gradient(transparent, transparent);
    -webkit-mask-position: center center;
    mask-position: center center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: 2rem 2rem;
    mask-size: 2rem 2rem;
  }
}
.usa-alert--info .usa-alert__body > * {
  margin-left: 2.5rem;
}
.usa-alert--info .usa-alert__body .usa-link {
  color: #046B99;
}
.usa-alert--info .usa-alert__body .usa-link:visited {
  color: #54278f;
}
.usa-alert--info .usa-alert__body .usa-link:hover,
.usa-alert--info .usa-alert__body .usa-link:active {
  color: #0b4778;
}
.usa-alert--emergency {
  background-color: #9c3d10;
  border-left-color: #9c3d10;
}
.usa-alert--emergency .usa-alert__body {
  color: white;
  background-color: #9c3d10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 3.3333333333rem;
}
.usa-alert--emergency .usa-alert__body::before {
  background-image: url("../error--white-XMM7ATFA.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 2rem 2rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
  content: "";
  display: block;
  position: absolute;
  top: 0.6666666667rem;
}
@supports (mask: url()) {
  .usa-alert--emergency .usa-alert__body::before {
    background: none;
    background-color: white;
    -webkit-mask-image: url("../error-3VQTC4AF.svg"), linear-gradient(transparent, transparent);
    mask-image: url("../error-3VQTC4AF.svg"), linear-gradient(transparent, transparent);
    -webkit-mask-position: center center;
    mask-position: center center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: 2rem 2rem;
    mask-size: 2rem 2rem;
  }
}
.usa-alert--emergency .usa-alert__body > * {
  margin-left: 2.5rem;
}
.usa-alert--emergency .usa-alert__body .usa-link {
  color: #dfe1e2;
}
.usa-alert--emergency .usa-alert__body .usa-link:visited {
  color: #dfe1e2;
}
.usa-alert--emergency .usa-alert__body .usa-link:hover,
.usa-alert--emergency .usa-alert__body .usa-link:active {
  color: #f0f0f0;
}
.usa-alert--slim .usa-alert__body {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  min-height: 0;
}
.usa-alert--slim .usa-alert__body:before {
  height: 1.5rem;
  top: 0.5rem;
  width: 1.5rem;
}
@supports (mask: url()) {
  .usa-alert--slim .usa-alert__body:before {
    -webkit-mask-size: 1.5rem;
    mask-size: 1.5rem;
  }
}
.usa-alert--slim .usa-alert__body > * {
  margin-left: 2rem;
}
.usa-alert--slim .usa-alert__body::before {
  left: 1.125rem;
}
.usa-alert--no-icon .usa-alert__body {
  min-height: 0;
}
.usa-alert--no-icon .usa-alert__body:before {
  display: none;
}
.usa-alert--no-icon .usa-alert__body > * {
  margin-left: 0;
}
.usa-alert--validation .usa-checklist {
  margin-top: 1rem;
}
.usa-banner {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.08rem;
  line-height: 1.6;
  background-color: #f0f0f0;
}
@media all and (min-width: 40em) {
  .usa-banner {
    font-size: 0.82rem;
    padding-bottom: 0rem;
  }
}
.usa-banner .usa-accordion {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.08rem;
  line-height: 1.6;
}
.usa-banner .grid-row {
  display: flex;
  flex-wrap: wrap;
}
.usa-banner .grid-row.grid-gap-lg {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}
.usa-banner .grid-row.grid-gap-lg > * {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
@media all and (min-width: 40em) {
  .usa-banner .grid-row .tablet\:grid-col-6 {
    flex: 0 1 auto;
    width: 50%;
  }
}
.usa-banner__header,
.usa-banner__content {
  color: #11181d;
}
.usa-banner__content {
  margin-left: auto;
  margin-right: auto;
  max-width: 64rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: transparent;
  font-size: 0.95rem;
  overflow: hidden;
  padding-bottom: 1rem;
  padding-left: 0.5rem;
  padding-top: 0.25rem;
  width: 100%;
}
@media all and (min-width: 64em) {
  .usa-banner__content {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .usa-banner__content {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .usa-banner__content {
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
  }
}
.usa-banner__content p:first-child {
  margin: 0;
}
.usa-banner__guidance {
  display: flex;
  align-items: flex-start;
  max-width: 64ex;
  padding-top: 1rem;
}
@media all and (min-width: 40em) {
  .usa-banner__guidance {
    padding-top: 0rem;
  }
}
.usa-banner__lock-image {
  height: 1.5ex;
  width: 1.21875ex;
}
.usa-banner__lock-image path {
  fill: currentColor;
}
@media (forced-colors: active) {
  .usa-banner__lock-image path {
    fill: CanvasText;
  }
}
.usa-banner__inner {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 64rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-right: 0rem;
}
@media all and (min-width: 64em) {
  .usa-banner__inner {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .usa-banner__inner {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .usa-banner__inner {
    align-items: center;
  }
}
.usa-banner__header {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  font-size: 0.76rem;
  font-weight: normal;
  min-height: 3rem;
  position: relative;
}
@media all and (min-width: 40em) {
  .usa-banner__header {
    padding-bottom: 0.25rem;
    padding-top: 0.25rem;
    min-height: 0;
  }
}
.usa-banner__header-text {
  margin-bottom: 0;
  margin-top: 0;
  font-size: 0.76rem;
  line-height: 1.2;
}
.usa-banner__header-action {
  color: #046B99;
  line-height: 1.2;
  margin-bottom: 0rem;
  margin-top: 2px;
  text-decoration: underline;
}
.usa-banner__header-action::after {
  background-image: url("../expand_more-S37WEEPD.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 1rem 1rem;
  display: inline-block;
  height: 1rem;
  width: 1rem;
  content: "";
  vertical-align: middle;
  margin-left: auto;
}
@supports (mask: url()) {
  .usa-banner__header-action::after {
    background: none;
    background-color: #046B99;
    -webkit-mask-image: url("../expand_more-S37WEEPD.svg"), linear-gradient(transparent, transparent);
    mask-image: url("../expand_more-S37WEEPD.svg"), linear-gradient(transparent, transparent);
    -webkit-mask-position: center center;
    mask-position: center center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: 1rem 1rem;
    mask-size: 1rem 1rem;
  }
  .usa-banner__header-action::after:hover {
    background-color: #0b4778;
  }
}
.usa-banner__header-action:hover::after {
  content: "";
  background-color: #0b4778;
}
.usa-banner__header-action:visited {
  color: #54278f;
}
.usa-banner__header-action:hover,
.usa-banner__header-action:active {
  color: #0b4778;
}
.usa-banner__header--expanded .usa-banner__header-action {
  display: none;
}
@media all and (min-width: 40em) {
  .usa-banner__header-action {
    display: none;
  }
}
@media (forced-colors: active) {
  .usa-banner__header-action {
    color: LinkText;
  }
  .usa-banner__header-action::after {
    background-color: ButtonText;
  }
}
.usa-banner__header-flag {
  float: left;
  margin-right: 0.5rem;
  width: 1rem;
}
@media all and (min-width: 40em) {
  .usa-banner__header-flag {
    margin-right: 0.5rem;
    padding-top: 0rem;
  }
}
.usa-banner__header--expanded {
  padding-right: 3.5rem;
}
@media all and (min-width: 40em) {
  .usa-banner__header--expanded {
    background-color: transparent;
    display: block;
    font-size: 0.76rem;
    font-weight: normal;
    min-height: 0rem;
    padding-right: 0rem;
  }
}
.usa-banner__header--expanded .usa-banner__inner {
  margin-left: 0rem;
}
@media all and (min-width: 40em) {
  .usa-banner__header--expanded .usa-banner__inner {
    margin-left: auto;
  }
}
.usa-banner__header--expanded .usa-banner__header-action {
  display: none;
}
.usa-banner__button {
  color: #046B99;
  text-decoration: underline;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  font-weight: normal;
  justify-content: normal;
  text-align: left;
  margin: 0;
  padding: 0;
  width: auto;
  position: absolute;
  left: 0;
  position: absolute;
  bottom: 0;
  top: 0;
  color: #046B99;
  text-decoration: underline;
  color: #046B99;
  display: block;
  font-size: 0.76rem;
  height: auto;
  line-height: 1.2;
  padding-top: 0rem;
  padding-left: 0rem;
  text-decoration: none;
  width: auto;
}
.usa-banner__button:visited {
  color: #54278f;
}
.usa-banner__button:hover {
  color: #0b4778;
}
.usa-banner__button:active {
  color: #1C304A;
}
.usa-banner__button:focus {
  outline: 0.25rem solid #2491ff;
  outline-offset: 0rem;
}
.usa-banner__button:hover,
.usa-banner__button.usa-button--hover,
.usa-banner__button:disabled:hover,
.usa-banner__button[aria-disabled=true]:hover,
.usa-banner__button:disabled.usa-button--hover,
.usa-banner__button[aria-disabled=true].usa-button--hover,
.usa-banner__button:active,
.usa-banner__button.usa-button--active,
.usa-banner__button:disabled:active,
.usa-banner__button[aria-disabled=true]:active,
.usa-banner__button:disabled.usa-button--active,
.usa-banner__button[aria-disabled=true].usa-button--active,
.usa-banner__button:disabled:focus,
.usa-banner__button[aria-disabled=true]:focus,
.usa-banner__button:disabled.usa-focus,
.usa-banner__button[aria-disabled=true].usa-focus,
.usa-banner__button:disabled,
.usa-banner__button[aria-disabled=true],
.usa-banner__button.usa-button--disabled {
  background-color: transparent;
  box-shadow: none;
  text-decoration: underline;
}
.usa-banner__button.usa-button--hover {
  color: #0b4778;
}
.usa-banner__button.usa-button--active {
  color: #1C304A;
}
.usa-banner__button:disabled,
.usa-banner__button[aria-disabled=true],
.usa-banner__button:disabled:hover,
.usa-banner__button[aria-disabled=true]:hover,
.usa-banner__button[aria-disabled=true]:focus {
  color: #757575;
}
@media (forced-colors: active) {
  .usa-banner__button:disabled,
  .usa-banner__button[aria-disabled=true],
  .usa-banner__button:disabled:hover,
  .usa-banner__button[aria-disabled=true]:hover,
  .usa-banner__button[aria-disabled=true]:focus {
    color: GrayText;
  }
}
.usa-banner__button:visited {
  color: #54278f;
}
.usa-banner__button:hover,
.usa-banner__button:active {
  color: #0b4778;
}
@media all and (max-width: 39.99em) {
  .usa-banner__button {
    width: 100%;
  }
  .usa-banner__button:enabled:focus {
    outline-offset: -0.25rem;
  }
}
@media all and (min-width: 40em) {
  .usa-banner__button {
    color: #046B99;
    position: static;
    bottom: auto;
    left: auto;
    right: auto;
    top: auto;
    display: inline;
    margin-left: 0.5rem;
    position: relative;
  }
  .usa-banner__button::after {
    background-image: url("../expand_more-S37WEEPD.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 1rem 1rem;
    display: inline-block;
    height: 1rem;
    width: 1rem;
    content: "";
    vertical-align: middle;
    margin-left: 2px;
  }
  @supports (mask: url()) {
    .usa-banner__button::after {
      background: none;
      background-color: #046B99;
      -webkit-mask-image: url("../expand_more-S37WEEPD.svg"), linear-gradient(transparent, transparent);
      mask-image: url("../expand_more-S37WEEPD.svg"), linear-gradient(transparent, transparent);
      -webkit-mask-position: center center;
      mask-position: center center;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-size: 1rem 1rem;
      mask-size: 1rem 1rem;
    }
    .usa-banner__button::after:hover {
      background-color: #0b4778;
    }
  }
  .usa-banner__button:hover::after {
    content: "";
    background-color: #0b4778;
  }
  .usa-banner__button:visited {
    color: #54278f;
  }
  .usa-banner__button:hover,
  .usa-banner__button:active {
    color: #0b4778;
  }
  .usa-banner__button::after,
  .usa-banner__button:hover::after {
    position: absolute;
  }
}
@media (min-width: 40em) and (forced-colors: active) {
  .usa-banner__button::after,
  .usa-banner__button:hover::after {
    background-color: ButtonText;
  }
}
@media all and (min-width: 40em) {
  .usa-banner__button:hover {
    text-decoration: none;
  }
}
.usa-banner__button[aria-expanded=false],
.usa-banner__button[aria-expanded=false]:hover,
.usa-banner__button[aria-expanded=true],
.usa-banner__button[aria-expanded=true]:hover {
  background-image: none;
}
@media (forced-colors: active) {
  .usa-banner__button[aria-expanded=false]::before,
  .usa-banner__button[aria-expanded=false]:hover::before,
  .usa-banner__button[aria-expanded=true]::before,
  .usa-banner__button[aria-expanded=true]:hover::before {
    content: none;
  }
}
@media all and (max-width: 39.99em) {
  .usa-banner__button[aria-expanded=true]::after {
    background-image: url("../close-D6X4FKTZ.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 1.5rem 1.5rem;
    display: inline-block;
    height: 3rem;
    width: 3rem;
    content: "";
    vertical-align: middle;
    margin-left: 0rem;
  }
  @supports (mask: url()) {
    .usa-banner__button[aria-expanded=true]::after {
      background: none;
      background-color: #005ea2;
      -webkit-mask-image: url("../close-D6X4FKTZ.svg"), linear-gradient(transparent, transparent);
      mask-image: url("../close-D6X4FKTZ.svg"), linear-gradient(transparent, transparent);
      -webkit-mask-position: center center;
      mask-position: center center;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-size: 1.5rem 1.5rem;
      mask-size: 1.5rem 1.5rem;
    }
  }
  .usa-banner__button[aria-expanded=true]::before {
    position: absolute;
    bottom: 0;
    top: 0;
    position: absolute;
    right: 0;
    background-color: #dfe1e2;
    content: "";
    display: block;
    height: 3rem;
    width: 3rem;
  }
  .usa-banner__button[aria-expanded=true]::after {
    position: absolute;
    bottom: 0;
    top: 0;
    position: absolute;
    right: 0;
  }
}
@media all and (min-width: 40em) {
  .usa-banner__button[aria-expanded=true] {
    height: auto;
    padding: 0rem;
    position: relative;
  }
  .usa-banner__button[aria-expanded=true]::after {
    background-image: url("../expand_less-RBECZ4GA.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 1rem 1rem;
    display: inline-block;
    height: 1rem;
    width: 1rem;
    content: "";
    vertical-align: middle;
    margin-left: 2px;
  }
  @supports (mask: url()) {
    .usa-banner__button[aria-expanded=true]::after {
      background: none;
      background-color: #046B99;
      -webkit-mask-image: url("../expand_less-RBECZ4GA.svg"), linear-gradient(transparent, transparent);
      mask-image: url("../expand_less-RBECZ4GA.svg"), linear-gradient(transparent, transparent);
      -webkit-mask-position: center center;
      mask-position: center center;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-size: 1rem 1rem;
      mask-size: 1rem 1rem;
    }
    .usa-banner__button[aria-expanded=true]::after:hover {
      background-color: #0b4778;
    }
  }
  .usa-banner__button[aria-expanded=true]:hover::after {
    content: "";
    background-color: #0b4778;
  }
  .usa-banner__button[aria-expanded=true]::after,
  .usa-banner__button[aria-expanded=true]:hover::after {
    position: absolute;
  }
}
@media (min-width: 40em) and (forced-colors: active) {
  .usa-banner__button[aria-expanded=true]::after,
  .usa-banner__button[aria-expanded=true]:hover::after {
    background-color: ButtonText;
  }
}
.usa-banner__button-text {
  position: absolute;
  left: -999em;
  right: auto;
  text-decoration: underline;
}
@media all and (min-width: 40em) {
  .usa-banner__button-text {
    position: static;
    display: inline;
  }
}
@media (forced-colors: active) {
  .usa-banner__button-text {
    color: LinkText;
  }
}
.usa-banner__icon {
  width: 2.5rem;
}
.usa-js-loading .usa-banner__content {
  position: absolute;
  left: -999em;
  right: auto;
}
.usa-breadcrumb {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.01rem;
  line-height: 1.3;
  color: #11181d;
  background-color: white;
  padding-left: 0;
  padding-right: 0;
}
@media all and (min-width: 30em) {
  .usa-breadcrumb {
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
}
@media all and (min-width: 30em) {
  .usa-breadcrumb:not(.usa-breadcrumb--wrap) .usa-breadcrumb__list {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.usa-breadcrumb__list {
  margin-bottom: 0;
  margin-top: 0;
  list-style-type: none;
  padding-left: 0;
  display: block;
  padding: 0.25rem;
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}
.usa-breadcrumb__list > li {
  margin-bottom: 0;
  max-width: unset;
}
.usa-breadcrumb__list-item {
  position: absolute;
  left: -999em;
  right: auto;
  display: inline;
}
@media all and (max-width: 29.99em) {
  .usa-breadcrumb__list-item {
    white-space: normal;
  }
  .usa-breadcrumb__list-item:nth-last-child(2) {
    position: static;
  }
  .usa-breadcrumb__list-item:nth-last-child(2) .usa-breadcrumb__link {
    padding-left: calc(2ex + 0px);
    text-indent: calc((2ex + 0px) * -1);
    display: inline-block;
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
  .usa-breadcrumb__list-item:nth-last-child(2) .usa-breadcrumb__link::before {
    background-image: url("../arrow_back-NBHWBQVI.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 2ex 2ex;
    display: inline-block;
    height: 2ex;
    width: 2ex;
    content: "";
    vertical-align: baseline;
    margin-right: 0rem;
  }
  @supports (mask: url()) {
    .usa-breadcrumb__list-item:nth-last-child(2) .usa-breadcrumb__link::before {
      background: none;
      background-color: #71767a;
      -webkit-mask-image: url("../arrow_back-NBHWBQVI.svg"), linear-gradient(transparent, transparent);
      mask-image: url("../arrow_back-NBHWBQVI.svg"), linear-gradient(transparent, transparent);
      -webkit-mask-position: center center;
      mask-position: center center;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-size: 2ex 2ex;
      mask-size: 2ex 2ex;
    }
  }
  .usa-breadcrumb__list-item:nth-last-child(2) .usa-breadcrumb__link:before {
    bottom: -0.2em;
    height: 2ex;
    position: relative;
  }
  .usa-breadcrumb__list-item:nth-last-child(2) .usa-breadcrumb__link,
  .usa-breadcrumb__list-item:nth-last-child(2) .usa-breadcrumb__link:hover,
  .usa-breadcrumb__list-item:nth-last-child(2) .usa-breadcrumb__link:active {
    text-decoration: none;
  }
  .usa-breadcrumb__list-item:nth-last-child(2) .usa-breadcrumb__link span {
    text-decoration: underline;
  }
  .usa-breadcrumb__list-item:nth-last-child(2) .usa-breadcrumb__link::before {
    margin-right: 0px;
  }
}
@media all and (min-width: 30em) {
  .usa-breadcrumb__list-item {
    position: static;
  }
  .usa-breadcrumb__list-item:not(:last-child)::after {
    background-image: url("../navigate_next-EH2RHTK7.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 2ex 2ex;
    display: inline-block;
    height: 2ex;
    width: 2ex;
    content: "";
    vertical-align: baseline;
    margin-left: 0rem;
  }
  @supports (mask: url()) {
    .usa-breadcrumb__list-item:not(:last-child)::after {
      background: none;
      background-color: #71767a;
      -webkit-mask-image: url("../navigate_next-EH2RHTK7.svg"), linear-gradient(transparent, transparent);
      mask-image: url("../navigate_next-EH2RHTK7.svg"), linear-gradient(transparent, transparent);
      -webkit-mask-position: center center;
      mask-position: center center;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-size: 2ex 2ex;
      mask-size: 2ex 2ex;
    }
  }
  .usa-breadcrumb__list-item:not(:last-child)::after {
    bottom: -0.2em;
    margin-left: 0px;
    margin-right: 0px;
    height: 2ex;
    position: relative;
  }
}
.usa-breadcrumb__link {
  color: #046B99;
  display: inline;
  text-decoration: none;
}
.usa-breadcrumb__link:visited {
  color: #54278f;
}
.usa-breadcrumb__link:hover,
.usa-breadcrumb__link:active {
  color: #0b4778;
}
.usa-breadcrumb__link span {
  text-decoration: underline;
}
@media all and (min-width: 30em) {
  .usa-breadcrumb--wrap {
    line-height: 1.5;
  }
}
.usa-breadcrumb--wrap .usa-breadcrumb__list-item {
  display: inline-block;
}
.usa-button-group {
  margin-bottom: 0;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  list-style-type: none;
  margin-left: -0.25rem;
  margin-right: -0.25rem;
  padding-left: 0;
}
@media all and (min-width: 30em) {
  .usa-button-group {
    flex-wrap: nowrap;
    align-items: stretch;
    flex-direction: row;
  }
}
.usa-button-group .usa-button-group {
  height: 100%;
}
@media all and (min-width: 30em) {
  .usa-button-group .usa-button-group .usa-button-group__item {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.usa-button-group .usa-button-group--segmented .usa-button-group__item {
  margin-top: 0;
  margin-bottom: 0;
}
.usa-button-group__item {
  margin: 0.25rem;
}
@media all and (min-width: 30em) {
  .usa-button-group__item:last-child {
    margin-right: 0;
  }
}
.usa-button-group__item .usa-button {
  height: 100%;
  margin-left: 0;
  margin-right: 0;
}
.usa-button-group--segmented {
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-left: 0;
  margin-right: 0;
}
@media all and (min-width: 30em) {
  .usa-button-group--segmented {
    justify-content: flex-start;
  }
}
.usa-button-group--segmented .usa-button {
  position: relative;
  width: calc(100% + 2px);
}
@media all and (min-width: 30em) {
  .usa-button-group--segmented .usa-button {
    width: auto;
  }
}
.usa-button-group--segmented .usa-button:hover,
.usa-button-group--segmented .usa-button:active {
  z-index: 2;
}
.usa-button-group--segmented .usa-button:focus {
  z-index: 3;
}
.usa-button-group--segmented .usa-button-group__item {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}
@media all and (min-width: 30em) {
  .usa-button-group--segmented .usa-button-group__item {
    width: auto;
  }
}
.usa-button-group--segmented .usa-button-group__item:first-child > .usa-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: -1px;
}
.usa-button-group--segmented .usa-button-group__item:last-child > .usa-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-right: 0;
  margin-left: -2px;
  width: calc(100% + 2px);
}
@media all and (min-width: 30em) {
  .usa-button-group--segmented .usa-button-group__item:last-child > .usa-button {
    margin-left: -1px;
    width: auto;
  }
}
.usa-button-group--segmented .usa-button-group__item:where(:not(:first-child):not(:last-child)) > .usa-button {
  border-radius: 0;
  margin-right: -1px;
  margin-left: -1px;
}
.usa-button-group--segmented .usa-button-group__item:where(:not(:last-child)) .usa-button::before {
  border-right: 1px solid #0b4778;
  bottom: 0;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  right: 1px;
  top: 0;
  width: 1px;
  z-index: 3;
}
.usa-button-group--segmented .usa-button-group__item:where(:not(:last-child)) .usa-button--secondary::before {
  border-right-color: #b50909;
}
.usa-button-group--segmented .usa-button-group__item:where(:not(:last-child)) .usa-button--accent-cool::before {
  border-right-color: #0081a1;
}
.usa-button-group--segmented .usa-button-group__item:where(:not(:last-child)) .usa-button--base::before {
  border-right-color: #565c65;
}
.usa-button-group--segmented .usa-button-group__item:where(:not(:last-child)) [class*=usa-button]:disabled::before,
.usa-button-group--segmented .usa-button-group__item:where(:not(:last-child)) [class*=usa-button][aria-disabled=true]::before {
  border-right-color: white;
}
.usa-button-group--segmented .usa-button-group__item:where(:not(:last-child)) .usa-button:active::before,
.usa-button-group--segmented .usa-button-group__item:where(:not(:last-child)) .usa-button--outline::before {
  display: none;
}
.usa-button {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.08rem;
  line-height: 1;
  color: white;
  background-color: #046B99;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  align-items: center;
  border: 0;
  border-radius: 0.25rem;
  cursor: pointer;
  column-gap: 0.5rem;
  display: inline-flex;
  font-weight: 700;
  justify-content: center;
  margin-right: 0.5rem;
  padding: 0.75rem 1.25rem;
  text-align: center;
  text-decoration: none;
  width: 100%;
}
@media all and (min-width: 30em) {
  .usa-button {
    width: auto;
  }
}
.usa-button:visited {
  color: white;
}
.usa-button:hover,
.usa-button.usa-button--hover {
  color: white;
  background-color: #0b4778;
  border-bottom: 0;
  text-decoration: none;
}
.usa-button:active,
.usa-button.usa-button--active {
  color: white;
  background-color: #1C304A;
}
.usa-button:not([disabled]):focus,
.usa-button:not([disabled]).usa-focus {
  outline-offset: 0.25rem;
}
.usa-button:disabled,
.usa-button[aria-disabled=true] {
  color: #454545;
  background-color: #c9c9c9;
  cursor: not-allowed;
  opacity: 1;
}
.usa-button:disabled:hover,
.usa-button:disabled:active,
.usa-button:disabled:focus,
.usa-button:disabled.usa-focus,
.usa-button[aria-disabled=true]:hover,
.usa-button[aria-disabled=true]:active,
.usa-button[aria-disabled=true]:focus,
.usa-button[aria-disabled=true].usa-focus {
  color: #454545;
  background-color: #c9c9c9;
}
@media (forced-colors: active) {
  .usa-button:disabled,
  .usa-button[aria-disabled=true] {
    border: 0;
    color: GrayText;
  }
  .usa-button:disabled:hover,
  .usa-button:disabled:active,
  .usa-button:disabled:focus,
  .usa-button:disabled.usa-focus,
  .usa-button[aria-disabled=true]:hover,
  .usa-button[aria-disabled=true]:active,
  .usa-button[aria-disabled=true]:focus,
  .usa-button[aria-disabled=true].usa-focus {
    color: GrayText;
  }
}
.usa-button:disabled.usa-button--hover,
.usa-button:disabled.usa-button--active,
.usa-button[aria-disabled=true].usa-button--hover,
.usa-button[aria-disabled=true].usa-button--active {
  color: #454545;
  background-color: #c9c9c9;
  cursor: not-allowed;
  opacity: 1;
}
.usa-button:disabled.usa-button--hover:hover,
.usa-button:disabled.usa-button--hover:active,
.usa-button:disabled.usa-button--hover:focus,
.usa-button:disabled.usa-button--hover.usa-focus,
.usa-button:disabled.usa-button--active:hover,
.usa-button:disabled.usa-button--active:active,
.usa-button:disabled.usa-button--active:focus,
.usa-button:disabled.usa-button--active.usa-focus,
.usa-button[aria-disabled=true].usa-button--hover:hover,
.usa-button[aria-disabled=true].usa-button--hover:active,
.usa-button[aria-disabled=true].usa-button--hover:focus,
.usa-button[aria-disabled=true].usa-button--hover.usa-focus,
.usa-button[aria-disabled=true].usa-button--active:hover,
.usa-button[aria-disabled=true].usa-button--active:active,
.usa-button[aria-disabled=true].usa-button--active:focus,
.usa-button[aria-disabled=true].usa-button--active.usa-focus {
  color: #454545;
  background-color: #c9c9c9;
}
@media (forced-colors: active) {
  .usa-button:disabled.usa-button--hover,
  .usa-button:disabled.usa-button--active,
  .usa-button[aria-disabled=true].usa-button--hover,
  .usa-button[aria-disabled=true].usa-button--active {
    border: 0;
    color: GrayText;
  }
  .usa-button:disabled.usa-button--hover:hover,
  .usa-button:disabled.usa-button--hover:active,
  .usa-button:disabled.usa-button--hover:focus,
  .usa-button:disabled.usa-button--hover.usa-focus,
  .usa-button:disabled.usa-button--active:hover,
  .usa-button:disabled.usa-button--active:active,
  .usa-button:disabled.usa-button--active:focus,
  .usa-button:disabled.usa-button--active.usa-focus,
  .usa-button[aria-disabled=true].usa-button--hover:hover,
  .usa-button[aria-disabled=true].usa-button--hover:active,
  .usa-button[aria-disabled=true].usa-button--hover:focus,
  .usa-button[aria-disabled=true].usa-button--hover.usa-focus,
  .usa-button[aria-disabled=true].usa-button--active:hover,
  .usa-button[aria-disabled=true].usa-button--active:active,
  .usa-button[aria-disabled=true].usa-button--active:focus,
  .usa-button[aria-disabled=true].usa-button--active.usa-focus {
    color: GrayText;
  }
}
@media (forced-colors: active) {
  .usa-button:disabled:not(.usa-button--unstyled),
  .usa-button[aria-disabled=true]:not(.usa-button--unstyled) {
    border: 2px solid GrayText;
  }
}
.usa-button .usa-icon {
  flex-shrink: 0;
}
@media (forced-colors: active) {
  .usa-button:not(.usa-button--unstyled) {
    border: 2px solid transparent;
  }
}
.usa-button--accent-cool {
  color: #11181d;
  background-color: #00CFFF;
}
.usa-button--accent-cool:visited {
  color: #11181d;
  background-color: #00CFFF;
}
.usa-button--accent-cool:hover,
.usa-button--accent-cool.usa-button--hover {
  color: white;
  background-color: #0081a1;
}
.usa-button--accent-cool:active,
.usa-button--accent-cool.usa-button--active {
  color: white;
  background-color: #0e4f5c;
}
.usa-button--accent-warm {
  color: #11181d;
  background-color: #fa9441;
}
.usa-button--accent-warm:visited {
  color: #11181d;
  background-color: #fa9441;
}
.usa-button--accent-warm:hover,
.usa-button--accent-warm.usa-button--hover {
  color: white;
  background-color: #c05600;
}
.usa-button--accent-warm:active,
.usa-button--accent-warm.usa-button--active {
  color: white;
  background-color: #775540;
}
.usa-button--outline {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #046B99;
  color: #046B99;
}
.usa-button--outline:visited {
  color: #046B99;
}
.usa-button--outline:hover,
.usa-button--outline.usa-button--hover {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #0b4778;
  color: #0b4778;
}
.usa-button--outline:active,
.usa-button--outline.usa-button--active {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #1C304A;
  color: #1C304A;
}
.usa-button--outline.usa-button--inverse {
  box-shadow: inset 0 0 0 2px #dfe1e2;
  color: #dfe1e2;
}
.usa-button--outline.usa-button--inverse:visited {
  color: #dfe1e2;
}
.usa-button--outline.usa-button--inverse:hover,
.usa-button--outline.usa-button--inverse.usa-button--hover {
  box-shadow: inset 0 0 0 2px #f0f0f0;
  color: #f0f0f0;
}
.usa-button--outline.usa-button--inverse:active,
.usa-button--outline.usa-button--inverse.usa-button--active {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px white;
  color: white;
}
.usa-button--outline.usa-button--inverse.usa-button--unstyled {
  color: #046B99;
  text-decoration: underline;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  font-weight: normal;
  justify-content: normal;
  text-align: left;
  margin: 0;
  padding: 0;
  width: auto;
  color: #dfe1e2;
}
.usa-button--outline.usa-button--inverse.usa-button--unstyled:visited {
  color: #54278f;
}
.usa-button--outline.usa-button--inverse.usa-button--unstyled:hover {
  color: #0b4778;
}
.usa-button--outline.usa-button--inverse.usa-button--unstyled:active {
  color: #1C304A;
}
.usa-button--outline.usa-button--inverse.usa-button--unstyled:focus {
  outline: 0.25rem solid #2491ff;
  outline-offset: 0rem;
}
.usa-button--outline.usa-button--inverse.usa-button--unstyled:hover,
.usa-button--outline.usa-button--inverse.usa-button--unstyled.usa-button--hover,
.usa-button--outline.usa-button--inverse.usa-button--unstyled:disabled:hover,
.usa-button--outline.usa-button--inverse.usa-button--unstyled[aria-disabled=true]:hover,
.usa-button--outline.usa-button--inverse.usa-button--unstyled:disabled.usa-button--hover,
.usa-button--outline.usa-button--inverse.usa-button--unstyled[aria-disabled=true].usa-button--hover,
.usa-button--outline.usa-button--inverse.usa-button--unstyled:active,
.usa-button--outline.usa-button--inverse.usa-button--unstyled.usa-button--active,
.usa-button--outline.usa-button--inverse.usa-button--unstyled:disabled:active,
.usa-button--outline.usa-button--inverse.usa-button--unstyled[aria-disabled=true]:active,
.usa-button--outline.usa-button--inverse.usa-button--unstyled:disabled.usa-button--active,
.usa-button--outline.usa-button--inverse.usa-button--unstyled[aria-disabled=true].usa-button--active,
.usa-button--outline.usa-button--inverse.usa-button--unstyled:disabled:focus,
.usa-button--outline.usa-button--inverse.usa-button--unstyled[aria-disabled=true]:focus,
.usa-button--outline.usa-button--inverse.usa-button--unstyled:disabled.usa-focus,
.usa-button--outline.usa-button--inverse.usa-button--unstyled[aria-disabled=true].usa-focus,
.usa-button--outline.usa-button--inverse.usa-button--unstyled:disabled,
.usa-button--outline.usa-button--inverse.usa-button--unstyled[aria-disabled=true],
.usa-button--outline.usa-button--inverse.usa-button--unstyled.usa-button--disabled {
  background-color: transparent;
  box-shadow: none;
  text-decoration: underline;
}
.usa-button--outline.usa-button--inverse.usa-button--unstyled.usa-button--hover {
  color: #0b4778;
}
.usa-button--outline.usa-button--inverse.usa-button--unstyled.usa-button--active {
  color: #1C304A;
}
.usa-button--outline.usa-button--inverse.usa-button--unstyled:disabled,
.usa-button--outline.usa-button--inverse.usa-button--unstyled[aria-disabled=true],
.usa-button--outline.usa-button--inverse.usa-button--unstyled:disabled:hover,
.usa-button--outline.usa-button--inverse.usa-button--unstyled[aria-disabled=true]:hover,
.usa-button--outline.usa-button--inverse.usa-button--unstyled[aria-disabled=true]:focus {
  color: #757575;
}
@media (forced-colors: active) {
  .usa-button--outline.usa-button--inverse.usa-button--unstyled:disabled,
  .usa-button--outline.usa-button--inverse.usa-button--unstyled[aria-disabled=true],
  .usa-button--outline.usa-button--inverse.usa-button--unstyled:disabled:hover,
  .usa-button--outline.usa-button--inverse.usa-button--unstyled[aria-disabled=true]:hover,
  .usa-button--outline.usa-button--inverse.usa-button--unstyled[aria-disabled=true]:focus {
    color: GrayText;
  }
}
.usa-button--outline.usa-button--inverse.usa-button--unstyled:visited {
  color: #dfe1e2;
}
.usa-button--outline.usa-button--inverse.usa-button--unstyled:hover,
.usa-button--outline.usa-button--inverse.usa-button--unstyled.usa-button--hover {
  color: #f0f0f0;
}
.usa-button--outline.usa-button--inverse.usa-button--unstyled:active,
.usa-button--outline.usa-button--inverse.usa-button--unstyled.usa-button--active {
  color: white;
}
.usa-button--base {
  color: white;
  background-color: #71767a;
}
.usa-button--base:hover,
.usa-button--base.usa-button--hover {
  color: white;
  background-color: #565c65;
}
.usa-button--base:active,
.usa-button--base.usa-button--active {
  color: white;
  background-color: #3d4551;
}
.usa-button--secondary {
  color: white;
  background-color: #d83933;
}
.usa-button--secondary:hover,
.usa-button--secondary.usa-button--hover {
  color: white;
  background-color: #b50909;
}
.usa-button--secondary:active,
.usa-button--secondary.usa-button--active {
  color: white;
  background-color: #8b0a03;
}
.usa-button--big {
  border-radius: 0.25rem;
  font-size: 1.39rem;
  padding: 1rem 1.5rem;
}
.usa-button--outline:disabled,
.usa-button--outline:disabled:hover,
.usa-button--outline:disabled:active,
.usa-button--outline:disabled:focus,
.usa-button--outline[aria-disabled=true],
.usa-button--outline[aria-disabled=true]:hover,
.usa-button--outline[aria-disabled=true]:active,
.usa-button--outline[aria-disabled=true]:focus,
.usa-button--outline-inverse:disabled,
.usa-button--outline-inverse:disabled:hover,
.usa-button--outline-inverse:disabled:active,
.usa-button--outline-inverse:disabled:focus,
.usa-button--outline-inverse[aria-disabled=true],
.usa-button--outline-inverse[aria-disabled=true]:hover,
.usa-button--outline-inverse[aria-disabled=true]:active,
.usa-button--outline-inverse[aria-disabled=true]:focus {
  background-color: transparent;
  color: #757575;
}
.usa-button--outline:disabled,
.usa-button--outline[aria-disabled=true] {
  box-shadow: inset 0 0 0 2px #c9c9c9;
}
.usa-button--outline:disabled.usa-button--inverse,
.usa-button--outline[aria-disabled=true].usa-button--inverse {
  box-shadow: inset 0 0 0 2px #919191;
  color: #919191;
}
@media (forced-colors: active) {
  .usa-button--outline:disabled.usa-button--inverse,
  .usa-button--outline[aria-disabled=true].usa-button--inverse {
    color: GrayText;
  }
}
.usa-button--unstyled {
  color: #046B99;
  text-decoration: underline;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  font-weight: normal;
  justify-content: normal;
  text-align: left;
  margin: 0;
  padding: 0;
  width: auto;
}
.usa-button--unstyled:visited {
  color: #54278f;
}
.usa-button--unstyled:hover {
  color: #0b4778;
}
.usa-button--unstyled:active {
  color: #1C304A;
}
.usa-button--unstyled:focus {
  outline: 0.25rem solid #2491ff;
  outline-offset: 0rem;
}
.usa-button--unstyled:hover,
.usa-button--unstyled.usa-button--hover,
.usa-button--unstyled:disabled:hover,
.usa-button--unstyled[aria-disabled=true]:hover,
.usa-button--unstyled:disabled.usa-button--hover,
.usa-button--unstyled[aria-disabled=true].usa-button--hover,
.usa-button--unstyled:active,
.usa-button--unstyled.usa-button--active,
.usa-button--unstyled:disabled:active,
.usa-button--unstyled[aria-disabled=true]:active,
.usa-button--unstyled:disabled.usa-button--active,
.usa-button--unstyled[aria-disabled=true].usa-button--active,
.usa-button--unstyled:disabled:focus,
.usa-button--unstyled[aria-disabled=true]:focus,
.usa-button--unstyled:disabled.usa-focus,
.usa-button--unstyled[aria-disabled=true].usa-focus,
.usa-button--unstyled:disabled,
.usa-button--unstyled[aria-disabled=true],
.usa-button--unstyled.usa-button--disabled {
  background-color: transparent;
  box-shadow: none;
  text-decoration: underline;
}
.usa-button--unstyled.usa-button--hover {
  color: #0b4778;
}
.usa-button--unstyled.usa-button--active {
  color: #1C304A;
}
.usa-button--unstyled:disabled,
.usa-button--unstyled[aria-disabled=true],
.usa-button--unstyled:disabled:hover,
.usa-button--unstyled[aria-disabled=true]:hover,
.usa-button--unstyled[aria-disabled=true]:focus {
  color: #757575;
}
@media (forced-colors: active) {
  .usa-button--unstyled:disabled,
  .usa-button--unstyled[aria-disabled=true],
  .usa-button--unstyled:disabled:hover,
  .usa-button--unstyled[aria-disabled=true]:hover,
  .usa-button--unstyled[aria-disabled=true]:focus {
    color: GrayText;
  }
}
.usa-card-group {
  margin-bottom: 0;
  margin-top: 0;
  list-style-type: none;
  padding-left: 0;
}
.usa-prose .usa-card-group {
  margin-bottom: 0;
  margin-top: 0;
  list-style-type: none;
  padding-left: 0;
}
.usa-card-group {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: stretch;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
@media all and (min-width: 40em) {
  .usa-card-group {
    flex-direction: row;
  }
}
.usa-card {
  margin-bottom: 2.5rem;
  max-width: none;
}
.usa-card:last-child {
  margin-bottom: 2.5rem;
}
@media all and (min-width: 40em) {
  .usa-card {
    margin-bottom: 2rem;
  }
  .usa-card:last-child {
    margin-bottom: 2rem;
  }
}
.usa-prose .usa-card {
  margin-bottom: 2.5rem;
  max-width: none;
}
.usa-prose .usa-card:last-child {
  margin-bottom: 2.5rem;
}
@media all and (min-width: 40em) {
  .usa-prose .usa-card {
    margin-bottom: 2rem;
  }
  .usa-prose .usa-card:last-child {
    margin-bottom: 2rem;
  }
}
.usa-card__container {
  color: #11181d;
  background-color: white;
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.08rem;
  line-height: 1.6;
  border-width: 2px;
  border-color: #dfe1e2;
  border-style: solid;
  display: flex;
  height: 100%;
  flex-direction: column;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  position: relative;
  border-radius: 0.5rem;
}
.usa-card:not(.usa-card--flag) .usa-card__container > :only-child {
  padding: 1.5rem;
}
.usa-card .usa-card__img {
  display: block;
}
.usa-card__header {
  padding-top: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 0.5rem;
}
.usa-card__header:last-child {
  padding-bottom: 1.5rem;
}
.usa-card__heading {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.39rem;
  line-height: 1.2;
  margin: 0;
}
.usa-card__media {
  order: -1;
  min-height: 1px;
}
.usa-card__img {
  border-top-left-radius: calc(0.5rem - 2px);
  border-top-right-radius: calc(0.5rem - 2px);
  background-color: #f0f0f0;
  position: relative;
  overflow: hidden;
}
.usa-card__img img {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.usa-card__media--inset {
  padding-top: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.usa-card__media--inset .usa-card__img {
  border-radius: 0;
}
.usa-card__body {
  flex: 1 1 0%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  flex-basis: auto;
}
.usa-card__body:last-child {
  padding-bottom: 1.5rem;
}
.usa-card__body:first-child {
  padding-top: 1.5rem;
}
.usa-card__body:only-child {
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
}
.usa-card__footer {
  padding-bottom: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.5rem;
}
.usa-card__footer .usa-button:only-of-type {
  margin-right: 0;
}
.usa-card__header > :last-child,
.usa-card__body > :last-child {
  padding-bottom: 0;
  margin-bottom: 0;
}
.usa-card__header > :first-child,
.usa-card__body > :first-child {
  margin-top: 0;
  padding-top: 0;
}
.usa-card__header > :only-child,
.usa-card__body > :only-child {
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-top: 0;
}
.usa-card__header--exdent,
.usa-card__media--exdent,
.usa-card__body--exdent,
.usa-card__footer--exdent {
  margin-inline: -2px;
}
.usa-card__header--exdent > *,
.usa-card__body--exdent > *,
.usa-card__footer--exdent > * {
  padding-left: 2px;
  padding-right: 2px;
}
.usa-card__media--exdent {
  margin-top: -2px;
}
.usa-card__media--exdent .usa-card__img {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.usa-card--header-first .usa-card__header {
  border-top-left-radius: calc(0.5rem - 2px);
  border-top-right-radius: calc(0.5rem - 2px);
  padding-bottom: 1rem;
}
.usa-card--header-first .usa-card__header--exdent {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  margin-top: -2px;
}
.usa-card--header-first .usa-card__media--inset {
  padding-top: 0;
}
.usa-card--header-first .usa-card__media {
  order: 0;
}
.usa-card--header-first .usa-card__media--exdent {
  margin-top: 0;
}
.usa-card--header-first .usa-card__img {
  border-radius: 0;
}
.usa-card--header-first .usa-card__body {
  padding-top: 1rem;
}
@media all and (min-width: 40em) {
  .usa-card--flag .usa-card__media {
    display: flex;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    top: 0;
    position: absolute;
    left: 0;
    position: absolute;
    width: 15rem;
  }
  .usa-card--flag .usa-card__img {
    border-radius: 0;
    border-top-left-radius: calc(0.5rem - 2px);
    border-bottom-left-radius: calc(0.5rem - 2px);
  }
  .usa-card--flag .usa-card__header,
  .usa-card--flag .usa-card__body,
  .usa-card--flag .usa-card__footer {
    margin-left: 15rem;
  }
  .usa-card--flag .usa-card__media--exdent {
    margin: -2px;
  }
  .usa-card--flag .usa-card__media--exdent .usa-card__img {
    border-bottom-left-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
  }
  .usa-card--flag .usa-card__media--inset {
    padding-right: 0;
    padding-bottom: 1.5rem;
  }
  .usa-card--flag .usa-card__media--inset .usa-card__img {
    border-radius: 0;
  }
  .usa-card--flag.usa-card--header-first .usa-card__header {
    padding-bottom: 0.5rem;
  }
  .usa-card--flag.usa-card--header-first .usa-card__body {
    padding-top: 0.5rem;
  }
  .usa-card--flag.usa-card--header-first .usa-card__media--inset {
    padding-top: 1.5rem;
  }
  .usa-card--flag.usa-card--media-right .usa-card__media {
    left: auto;
    right: 0;
  }
  .usa-card--flag.usa-card--media-right .usa-card__media--inset {
    padding-left: 0;
    padding-right: 1.5rem;
  }
  .usa-card--flag.usa-card--media-right .usa-card__media--inset .usa-card__img {
    border-radius: 0;
  }
  .usa-card--flag.usa-card--media-right .usa-card__img {
    border-radius: 0;
    border-top-right-radius: calc(0.5rem - 2px);
    border-bottom-right-radius: calc(0.5rem - 2px);
  }
  .usa-card--flag.usa-card--media-right .usa-card__header,
  .usa-card--flag.usa-card--media-right .usa-card__body,
  .usa-card--flag.usa-card--media-right .usa-card__footer {
    margin-left: 0;
    margin-right: 15rem;
  }
  .usa-card--flag.usa-card--media-right .usa-card__img {
    border-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
}
.usa-card:not(.usa-card--flag).usa-card__media--set-aspect {
  box-sizing: border-box;
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  position: relative;
}
@supports (aspect-ratio: 1) {
  .usa-card:not(.usa-card--flag).usa-card__media--set-aspect {
    height: inherit;
    padding: inherit;
    aspect-ratio: 1.7777777778;
    max-width: 100%;
  }
  .usa-card:not(.usa-card--flag).usa-card__media--set-aspect > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  img.usa-card:not(.usa-card--flag).usa-card__media--set-aspect,
  .usa-card:not(.usa-card--flag).usa-card__media--set-aspect > img {
    object-fit: cover;
  }
}
.usa-card:not(.usa-card--flag).usa-card__media--set-aspect .usa-card__img {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}
.usa-checklist {
  margin-bottom: 0;
  margin-top: 0;
  list-style-type: none;
  padding-left: 0;
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.08rem;
  line-height: 1.6;
}
.usa-checklist__item {
  text-indent: -2.5rem;
  margin-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-top: 0.5rem;
}
.usa-checklist__item::before {
  content: " ";
  display: inline-block;
  height: 1rem;
  margin-left: -0.25rem;
  margin-right: 0.75rem;
  width: 2rem;
}
.usa-checklist__item.usa-checklist__item--checked::before {
  background-image: url("../check--blue-60v-PCQB53AU.svg"), linear-gradient(transparent, transparent);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1.5rem;
}
.usa-collection {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.01rem;
  line-height: 1.3;
  margin-bottom: 1em;
  margin-top: 1em;
  line-height: 1.6;
  padding-left: 3ch;
  padding-left: 0;
}
.usa-collection:last-child {
  margin-bottom: 0;
}
.usa-collection ul,
.usa-collection ol {
  margin-top: 0.25em;
}
.usa-collection__item {
  margin-bottom: 0.25em;
  max-width: 68ex;
  margin-bottom: 0;
  margin-top: 0;
  list-style-type: none;
  padding-left: 0;
  align-items: flex-start;
  border-top-width: 1px;
  border-top-style: solid;
  display: flex;
  margin-bottom: 1rem;
  margin-top: 1rem;
  padding-top: 1rem;
}
.usa-collection__item:last-child {
  margin-bottom: 0;
}
.usa-collection__item > li {
  margin-bottom: 0;
  max-width: unset;
}
.usa-prose .usa-collection {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.01rem;
  line-height: 1.3;
  margin-bottom: 1em;
  margin-top: 1em;
  line-height: 1.6;
  padding-left: 3ch;
  padding-left: 0;
}
.usa-prose .usa-collection:last-child {
  margin-bottom: 0;
}
.usa-prose .usa-collection ul,
.usa-prose .usa-collection ol {
  margin-top: 0.25em;
}
.usa-prose .usa-collection__item {
  margin-bottom: 0.25em;
  max-width: 68ex;
  margin-bottom: 0;
  margin-top: 0;
  list-style-type: none;
  padding-left: 0;
  align-items: flex-start;
  border-top-width: 1px;
  border-top-style: solid;
  display: flex;
  margin-bottom: 1rem;
  margin-top: 1rem;
  padding-top: 1rem;
}
.usa-prose .usa-collection__item:last-child {
  margin-bottom: 0;
}
.usa-prose .usa-collection__item > li {
  margin-bottom: 0;
  max-width: unset;
}
.usa-collection__img,
.usa-collection__calendar-date {
  flex-shrink: 0;
  margin-right: 0.5rem;
  width: 4rem;
  margin-right: 1rem;
}
@media all and (min-width: 40em) {
  .usa-collection__img,
  .usa-collection__calendar-date {
    width: 5rem;
  }
}
.usa-collection__body {
  flex: 1 1 0%;
}
.usa-collection__heading {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.08rem;
  line-height: 1.3;
  margin-bottom: 0;
  margin-top: 0;
}
.usa-collection__description {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}
.usa-collection__meta {
  margin-bottom: 0;
  margin-top: 0;
  list-style-type: none;
  padding-left: 0;
  margin-top: 0.25rem;
}
.usa-collection__meta > li {
  margin-bottom: 0;
  max-width: unset;
}
.usa-collection__meta-item {
  margin-top: 0.25rem;
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 0.89rem;
  line-height: 1.3;
  display: block;
  margin-right: 0.5rem;
}
.usa-collection__meta-item.usa-tag {
  background-color: #dfe1e2;
  line-height: 1;
  padding-bottom: 0.25rem;
  padding-top: 0.25rem;
  color: #11181d;
  display: inline-block;
  margin-top: 0.25rem;
}
.usa-collection__meta-item.usa-tag--new {
  background-color: #c05600;
  color: white;
}
.usa-collection__calendar-date {
  text-decoration: none;
}
.usa-collection__calendar-date-month,
.usa-collection__calendar-date-day {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.08rem;
  display: block;
  font-weight: 700;
  padding: 0.25rem;
  text-align: center;
}
@media all and (min-width: 40em) {
  .usa-collection__calendar-date-month,
  .usa-collection__calendar-date-day {
    padding: 0.5rem;
  }
}
.usa-collection__calendar-date-month {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  background-color: #046B99;
  color: white;
}
.usa-collection__calendar-date-day {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border: 1px solid #046B99;
  color: #046B99;
}
.usa-collection--condensed .usa-collection__item {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
}
.usa-embed-container iframe,
.usa-embed-container object,
.usa-embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.usa-embed-container {
  box-sizing: border-box;
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  max-width: 100%;
}
@supports (aspect-ratio: 1) {
  .usa-embed-container {
    height: inherit;
    padding: inherit;
    aspect-ratio: 1.7777777778;
    max-width: 100%;
  }
  .usa-embed-container > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  img.usa-embed-container,
  .usa-embed-container > img {
    object-fit: cover;
  }
}
.usa-footer {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.08rem;
  line-height: 1.6;
}
.usa-footer > .grid-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 64rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .usa-footer > .grid-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.usa-footer__return-to-top {
  padding-bottom: 1.25rem;
  padding-top: 1.25rem;
  line-height: 1;
}
.usa-footer__return-to-top a {
  color: #046B99;
  text-decoration: underline;
}
.usa-footer__return-to-top a:visited {
  color: #54278f;
}
.usa-footer__return-to-top a:hover {
  color: #0b4778;
}
.usa-footer__return-to-top a:active {
  color: #1C304A;
}
.usa-footer__return-to-top a:focus {
  outline: 0.25rem solid #2491ff;
  outline-offset: 0rem;
}
.usa-footer__nav {
  margin-left: auto;
  margin-right: auto;
  max-width: 64rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-left: 0;
  padding-right: 0;
  border-bottom: 1px solid #a9aeb1;
}
@media all and (min-width: 64em) {
  .usa-footer__nav {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 30em) {
  .usa-footer__nav {
    padding-left: 1rem;
    padding-right: 1rem;
    border-bottom: none;
  }
}
@media all and (min-width: 30em) and (min-width: 64em) {
  .usa-footer__nav {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.usa-footer__nav > ul {
  margin-bottom: 0;
  margin-top: 0;
  list-style-type: none;
  padding-left: 0;
}
.usa-footer__primary-section {
  background-color: #f0f0f0;
}
.usa-footer__primary-section > .grid-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 64rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .usa-footer__primary-section > .grid-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.usa-footer__primary-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 64rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-left: 0;
  padding-right: 0;
}
@media all and (min-width: 64em) {
  .usa-footer__primary-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .usa-footer__primary-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.usa-footer__primary-content {
  line-height: 1.2;
}
.usa-footer__primary-link {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
  color: #11181d;
  font-weight: 700;
  border: 0;
  border-top: 1px solid #a9aeb1;
  display: block;
}
@media all and (min-width: 30em) {
  .usa-footer__primary-link {
    padding-left: 0;
    padding-right: 0;
    border: none;
  }
}
.usa-footer__primary-link--button {
  width: 100%;
  cursor: pointer;
}
.usa-footer__primary-link--button::before {
  background-image: url("../expand_more-S37WEEPD.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 1.25rem 1.25rem;
  display: inline-block;
  height: 1.25rem;
  width: 1.25rem;
  content: "";
  vertical-align: middle;
  margin-right: 0.25rem;
}
@supports (mask: url()) {
  .usa-footer__primary-link--button::before {
    background: none;
    background-color: currentColor;
    -webkit-mask-image: url("../expand_more-S37WEEPD.svg"), linear-gradient(transparent, transparent);
    mask-image: url("../expand_more-S37WEEPD.svg"), linear-gradient(transparent, transparent);
    -webkit-mask-position: center center;
    mask-position: center center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: 1.25rem 1.25rem;
    mask-size: 1.25rem 1.25rem;
  }
}
.usa-footer__primary-link--button:not([disabled]):focus {
  outline: 0.25rem solid #2491ff;
  outline-offset: -0.25rem;
}
.usa-footer__primary-link--button::before {
  height: 1.25rem;
  width: 1.25rem;
  align-items: center;
  background-size: contain;
  content: "";
  display: inline-flex;
  justify-content: center;
  margin-right: 0.25rem;
  margin-left: -0.25rem;
}
@media (forced-colors: active) {
  .usa-footer__primary-link--button::before {
    background-color: buttonText !important;
  }
}
.usa-footer__primary-link--button + .usa-list--unstyled {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.usa-footer__primary-link--button[aria-expanded=false]::before {
  background-image: url("../navigate_next-EH2RHTK7.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 1.25rem 1.25rem;
  display: inline-block;
  height: 1.25rem;
  width: 1.25rem;
  content: "";
  vertical-align: middle;
  margin-right: 0.25rem;
}
@supports (mask: url()) {
  .usa-footer__primary-link--button[aria-expanded=false]::before {
    background: none;
    background-color: currentColor;
    -webkit-mask-image: url("../navigate_next-EH2RHTK7.svg"), linear-gradient(transparent, transparent);
    mask-image: url("../navigate_next-EH2RHTK7.svg"), linear-gradient(transparent, transparent);
    -webkit-mask-position: center center;
    mask-position: center center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: 1.25rem 1.25rem;
    mask-size: 1.25rem 1.25rem;
  }
}
.usa-footer__primary-link--button[aria-expanded=false] + .usa-list--unstyled {
  display: none;
}
.usa-footer__secondary-link {
  line-height: 1.2;
  margin-left: 1rem;
  padding: 0;
}
.usa-footer__secondary-link a {
  color: #046B99;
  text-decoration: underline;
}
.usa-footer__secondary-link a:visited {
  color: #54278f;
}
.usa-footer__secondary-link a:hover {
  color: #0b4778;
}
.usa-footer__secondary-link a:active {
  color: #1C304A;
}
.usa-footer__secondary-link a:focus {
  outline: 0.25rem solid #2491ff;
  outline-offset: 0rem;
}
.usa-footer__secondary-link + .usa-footer__secondary-link {
  padding-top: 1rem;
}
@media all and (min-width: 30em) {
  .usa-footer__secondary-link {
    margin-left: 0;
  }
}
.usa-footer__contact-info {
  line-height: 1.2;
}
.usa-footer__contact-info a {
  color: #11181d;
}
@media all and (min-width: 30em) {
  .usa-footer__contact-info {
    justify-content: flex-end;
    margin-top: 0.5rem;
  }
}
.usa-sign-up {
  padding-bottom: 2rem;
  padding-top: 1.5rem;
}
.usa-sign-up .usa-label,
.usa-sign-up .usa-button {
  margin-top: 0.75rem;
}
.usa-sign-up__heading {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.39rem;
  line-height: 1.2;
  font-weight: 700;
  margin: 0;
}
.usa-footer__secondary-section {
  padding-bottom: 1.25rem;
  padding-top: 1.25rem;
  color: #11181d;
  background-color: #dfe1e2;
}
.usa-footer__secondary-section > .grid-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 64rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .usa-footer__secondary-section > .grid-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.usa-footer__secondary-section > .grid-container > .grid-row {
  justify-content: space-between;
}
.usa-footer__secondary-section a {
  color: #11181d;
}
.usa-footer__logo {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}
@media all and (min-width: 30em) {
  .usa-footer__logo {
    margin-bottom: 0;
    margin-top: 0;
    align-items: center;
  }
}
.usa-footer__logo-img {
  max-width: 5rem;
}
.usa-footer__logo-heading {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.39rem;
  line-height: 1;
  font-weight: 700;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}
.usa-footer__contact-links {
  margin-top: 1.5rem;
}
@media all and (min-width: 30em) {
  .usa-footer__contact-links {
    margin-top: 0;
    text-align: right;
  }
}
.usa-footer__contact-heading {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.39rem;
  line-height: 1.2;
  font-weight: 700;
  margin-top: 0;
}
@media all and (min-width: 30em) {
  .usa-footer__contact-heading {
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
  }
}
.usa-footer__social-links {
  line-height: 1;
  padding-bottom: 0.5rem;
}
.usa-footer__social-links a {
  text-decoration: none;
}
@media all and (min-width: 30em) {
  .usa-footer__social-links {
    justify-content: flex-end;
  }
}
.usa-social-link {
  height: 3rem;
  width: 3rem;
  background-color: rgba(0, 0, 0, 0.1);
  display: inline-block;
  padding: 0.25rem;
}
.usa-social-link:hover {
  background-color: white;
}
@media (forced-colors: active) {
  .usa-social-link {
    background-color: lightgrey;
    forced-color-adjust: none;
  }
}
.usa-social-link__icon {
  display: block;
  height: auto;
  width: 100%;
}
@media all and (min-width: 30em) {
  .usa-footer__address {
    justify-content: flex-end;
  }
}
@media all and (min-width: 64em) {
  .usa-footer--slim .usa-footer__nav {
    padding-left: 0;
    padding-right: 0;
  }
}
.usa-footer--slim .usa-footer__address {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
}
@media all and (min-width: 30em) {
  .usa-footer--slim .usa-footer__address {
    padding: 0;
  }
}
.usa-footer--slim .usa-footer__logo {
  align-items: center;
}
.usa-footer--slim .usa-footer__logo-img {
  max-width: 3rem;
}
.usa-footer--slim .usa-footer__contact-info {
  display: inline-block;
}
@media all and (min-width: 30em) {
  .usa-footer--slim .usa-footer__contact-info {
    padding-bottom: 1rem;
    padding-top: 1rem;
    margin-top: 0;
  }
}
.usa-footer--big .usa-footer__nav {
  margin-left: -1rem;
  margin-right: -1rem;
}
@media all and (min-width: 30em) {
  .usa-footer--big .usa-footer__nav {
    border-bottom: 1px solid #a9aeb1;
    padding-top: 2rem;
  }
}
@media all and (min-width: 40em) {
  .usa-footer--big .usa-footer__nav {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    border-bottom: none;
  }
}
.usa-footer--big .usa-footer__primary-link {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.01rem;
  line-height: 1.2;
  font-weight: 700;
  line-height: 1.2;
  margin: 0;
}
@media all and (min-width: 30em) {
  .usa-footer--big .usa-footer__primary-link {
    padding-bottom: 0;
    padding-top: 0;
    margin-bottom: 0.5rem;
  }
  .usa-footer--big .usa-footer__primary-link:hover {
    cursor: auto;
    text-decoration: none;
  }
}
.usa-footer--big .usa-footer__primary-content--collapsible .usa-footer__primary-link {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}
.usa-footer--big .usa-footer__primary-content--collapsible .usa-list--unstyled {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1.25rem;
}
@media all and (min-width: 30em) {
  .usa-footer--big .usa-footer__primary-content--collapsible .usa-list--unstyled {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 2rem;
    padding-top: 0.75rem;
  }
}
.usa-form {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.08rem;
  line-height: 1.3;
}
@media all and (min-width: 30em) {
  .usa-form {
    max-width: 20rem;
  }
}
.usa-form abbr[title=required] {
  text-decoration: none;
}
.usa-form :where(.usa-input, .usa-textarea, .usa-select, .usa-range) {
  max-width: none;
}
.usa-form .usa-button {
  margin-top: 0.5rem;
}
@media all and (min-width: 30em) {
  .usa-form .usa-button {
    margin-top: 1.5rem;
  }
}
.usa-form a:where(:not(.usa-button)) {
  color: #046B99;
  text-decoration: underline;
}
.usa-form a:where(:not(.usa-button)):visited {
  color: #54278f;
}
.usa-form a:where(:not(.usa-button)):hover {
  color: #0b4778;
}
.usa-form a:where(:not(.usa-button)):active {
  color: #1C304A;
}
.usa-form a:where(:not(.usa-button)):focus {
  outline: 0.25rem solid #2491ff;
  outline-offset: 0rem;
}
@media all and (min-width: 30em) {
  .usa-form--large {
    max-width: 30rem;
  }
}
.usa-show-password {
  color: #046B99;
  text-decoration: underline;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  font-weight: normal;
  justify-content: normal;
  text-align: left;
  margin: 0;
  padding: 0;
  width: auto;
  cursor: pointer;
}
.usa-show-password:visited {
  color: #54278f;
}
.usa-show-password:hover {
  color: #0b4778;
}
.usa-show-password:active {
  color: #1C304A;
}
.usa-show-password:focus {
  outline: 0.25rem solid #2491ff;
  outline-offset: 0rem;
}
.usa-show-password:hover,
.usa-show-password.usa-button--hover,
.usa-show-password:disabled:hover,
.usa-show-password[aria-disabled=true]:hover,
.usa-show-password:disabled.usa-button--hover,
.usa-show-password[aria-disabled=true].usa-button--hover,
.usa-show-password:active,
.usa-show-password.usa-button--active,
.usa-show-password:disabled:active,
.usa-show-password[aria-disabled=true]:active,
.usa-show-password:disabled.usa-button--active,
.usa-show-password[aria-disabled=true].usa-button--active,
.usa-show-password:disabled:focus,
.usa-show-password[aria-disabled=true]:focus,
.usa-show-password:disabled.usa-focus,
.usa-show-password[aria-disabled=true].usa-focus,
.usa-show-password:disabled,
.usa-show-password[aria-disabled=true],
.usa-show-password.usa-button--disabled {
  background-color: transparent;
  box-shadow: none;
  text-decoration: underline;
}
.usa-show-password.usa-button--hover {
  color: #0b4778;
}
.usa-show-password.usa-button--active {
  color: #1C304A;
}
.usa-show-password:disabled,
.usa-show-password[aria-disabled=true],
.usa-show-password:disabled:hover,
.usa-show-password[aria-disabled=true]:hover,
.usa-show-password[aria-disabled=true]:focus {
  color: #757575;
}
@media (forced-colors: active) {
  .usa-show-password:disabled,
  .usa-show-password[aria-disabled=true],
  .usa-show-password:disabled:hover,
  .usa-show-password[aria-disabled=true]:hover,
  .usa-show-password[aria-disabled=true]:focus {
    color: GrayText;
  }
}
.usa-form__note,
.usa-show-password {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 0.89rem;
  line-height: 1.3;
  float: right;
  margin: 0.25rem 0 1rem;
}
.usa-graphic-list {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.08rem;
  line-height: 1.6;
}
.usa-graphic-list .usa-graphic-list__row .usa-media-block {
  margin-bottom: 2rem;
}
@media all and (min-width: 40em) {
  .usa-graphic-list .usa-graphic-list__row .usa-media-block {
    margin-bottom: 4rem;
  }
}
@media all and (min-width: 40em) {
  .usa-graphic-list .usa-graphic-list__row:last-child .usa-media-block {
    margin-bottom: 0;
  }
}
.usa-graphic-list .usa-graphic-list__row:last-child .usa-media-block:last-child {
  margin-bottom: 0;
}
.usa-graphic-list .usa-media-block__img {
  margin-right: 1.5rem;
}
.usa-graphic-list .usa-media-block__body > :first-child {
  margin-top: 0;
}
.usa-graphic-list__heading {
  margin-bottom: 0;
  margin-top: 0;
  clear: both;
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.39rem;
  line-height: 1.2;
  font-weight: 700;
}
* + .usa-graphic-list__heading {
  margin-top: 1.5em;
}
.usa-graphic-list__heading + * {
  margin-top: 1em;
}
.usa-header {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.08rem;
  line-height: 1.6;
}
.usa-header::after {
  clear: both;
  content: "";
  display: block;
}
.usa-header a {
  border-bottom: none;
}
.usa-header .usa-search {
  margin-top: 0.5rem;
}
@media all and (min-width: 64em) {
  .usa-header .usa-search {
    float: right;
  }
}
@media all and (min-width: 64em) {
  .usa-header [role=search] {
    float: right;
    max-width: calc(27ch + 3rem);
    width: 100%;
  }
}
.usa-header [type=search] {
  min-width: 0;
}
@media all and (min-width: 64em) {
  .usa-header + .usa-hero {
    border-top: 1px solid white;
  }
}
@media all and (min-width: 64em) {
  .usa-header + .usa-section,
  .usa-header + main {
    border-top: 1px solid #dfe1e2;
  }
}
@media all and (max-width: 63.99em) {
  .usa-logo {
    flex: 1 1 0%;
    font-size: 0.89rem;
    line-height: 1;
    margin-left: 1rem;
  }
}
@media all and (min-width: 64em) {
  .usa-logo {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 1.39rem;
    line-height: 1.2;
  }
}
.usa-logo a {
  color: #11181d;
  text-decoration: none;
}
.usa-logo__text {
  display: block;
  font-style: normal;
  font-weight: 700;
  margin: 0;
}
.usa-menu-btn {
  color: #046B99;
  text-decoration: underline;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  font-weight: normal;
  justify-content: normal;
  text-align: left;
  margin: 0;
  padding: 0;
  width: auto;
  flex: 0 1 auto;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  background-color: #046B99;
  color: white;
  font-size: 0.82rem;
  height: 3rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}
.usa-menu-btn:visited {
  color: #54278f;
}
.usa-menu-btn:hover {
  color: #0b4778;
}
.usa-menu-btn:active {
  color: #1C304A;
}
.usa-menu-btn:focus {
  outline: 0.25rem solid #2491ff;
  outline-offset: 0rem;
}
.usa-menu-btn:hover,
.usa-menu-btn.usa-button--hover,
.usa-menu-btn:disabled:hover,
.usa-menu-btn[aria-disabled=true]:hover,
.usa-menu-btn:disabled.usa-button--hover,
.usa-menu-btn[aria-disabled=true].usa-button--hover,
.usa-menu-btn:active,
.usa-menu-btn.usa-button--active,
.usa-menu-btn:disabled:active,
.usa-menu-btn[aria-disabled=true]:active,
.usa-menu-btn:disabled.usa-button--active,
.usa-menu-btn[aria-disabled=true].usa-button--active,
.usa-menu-btn:disabled:focus,
.usa-menu-btn[aria-disabled=true]:focus,
.usa-menu-btn:disabled.usa-focus,
.usa-menu-btn[aria-disabled=true].usa-focus,
.usa-menu-btn:disabled,
.usa-menu-btn[aria-disabled=true],
.usa-menu-btn.usa-button--disabled {
  background-color: transparent;
  box-shadow: none;
  text-decoration: underline;
}
.usa-menu-btn.usa-button--hover {
  color: #0b4778;
}
.usa-menu-btn.usa-button--active {
  color: #1C304A;
}
.usa-menu-btn:disabled,
.usa-menu-btn[aria-disabled=true],
.usa-menu-btn:disabled:hover,
.usa-menu-btn[aria-disabled=true]:hover,
.usa-menu-btn[aria-disabled=true]:focus {
  color: #757575;
}
@media (forced-colors: active) {
  .usa-menu-btn:disabled,
  .usa-menu-btn[aria-disabled=true],
  .usa-menu-btn:disabled:hover,
  .usa-menu-btn[aria-disabled=true]:hover,
  .usa-menu-btn[aria-disabled=true]:focus {
    color: GrayText;
  }
}
@media all and (min-width: 64em) {
  .usa-menu-btn {
    display: none;
  }
}
.usa-menu-btn:hover {
  background-color: #0b4778;
  color: white;
  text-decoration: none;
}
.usa-menu-btn:active {
  color: white;
}
.usa-menu-btn:visited {
  color: white;
}
.usa-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
  visibility: hidden;
  z-index: 400;
}
.usa-overlay.is-visible {
  opacity: 1;
  visibility: visible;
}
@media all and (min-width: 64em) {
  .usa-header--basic .usa-navbar {
    position: relative;
    width: 70%;
  }
  .usa-header--basic .usa-nav {
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    display: flex;
    padding: 0 0 0.5rem 0.5rem;
    width: 100%;
  }
  .usa-header--basic .usa-nav-container {
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
  }
  .usa-header--basic .usa-nav__primary-item > .usa-current,
  .usa-header--basic .usa-nav__link:hover {
    position: relative;
  }
  .usa-header--basic .usa-nav__primary-item > .usa-current::after,
  .usa-header--basic .usa-nav__link:hover::after {
    background-color: #046B99;
    border-radius: 0;
    content: "";
    display: block;
    position: absolute;
    height: 0.25rem;
    left: 1rem;
    right: 1rem;
    bottom: -0.25rem;
  }
}
@media (min-width: 64em) and (forced-colors: active) {
  .usa-header--basic .usa-nav__primary-item > .usa-current::after,
  .usa-header--basic .usa-nav__link:hover::after {
    background-color: ButtonText;
  }
}
@media all and (min-width: 64em) {
  .usa-header--basic .usa-nav__link[aria-expanded=true]::after,
  .usa-header--basic .usa-nav__link[aria-expanded=true]:hover::after {
    display: none;
  }
  .usa-header--basic .usa-nav__primary {
    width: auto;
  }
  .usa-header--basic .usa-nav__primary-item:last-of-type {
    position: relative;
  }
  .usa-header--basic .usa-nav__primary-item:last-of-type .usa-nav__submenu {
    position: absolute;
    right: 0;
  }
  .usa-header--basic .usa-search {
    top: 0;
  }
}
.usa-header--basic.usa-header--megamenu .usa-nav__inner {
  display: flex;
  flex-direction: column;
}
@media all and (min-width: 64em) {
  .usa-header--basic.usa-header--megamenu .usa-nav__inner {
    display: block;
    float: right;
    margin-top: -2.5rem;
  }
}
@media all and (min-width: 64em) {
  .usa-header--basic.usa-header--megamenu .usa-nav__primary-item:last-of-type {
    position: static;
  }
}
@media all and (min-width: 64em) {
  .usa-header--extended {
    padding-top: 0;
  }
  .usa-header--extended .usa-nav__primary-item > .usa-current,
  .usa-header--extended .usa-nav__primary-item > .usa-nav__link:hover {
    position: relative;
  }
  .usa-header--extended .usa-nav__primary-item > .usa-current::after,
  .usa-header--extended .usa-nav__primary-item > .usa-nav__link:hover::after {
    background-color: #046B99;
    border-radius: 0;
    content: "";
    display: block;
    position: absolute;
    height: 0.25rem;
    left: 1rem;
    right: 1rem;
    bottom: 0rem;
  }
}
@media (min-width: 64em) and (forced-colors: active) {
  .usa-header--extended .usa-nav__primary-item > .usa-current::after,
  .usa-header--extended .usa-nav__primary-item > .usa-nav__link:hover::after {
    background-color: ButtonText;
  }
}
@media all and (min-width: 64em) {
  .usa-header--extended .usa-nav__link[aria-expanded=true]::after,
  .usa-header--extended .usa-nav__link[aria-expanded=true]:hover::after {
    display: none;
  }
}
@media all and (min-width: 64em) {
  .usa-header--extended .usa-logo {
    font-size: 2.03rem;
    margin: 2rem 0 1.5rem;
    max-width: 70%;
  }
}
@media all and (min-width: 64em) {
  .usa-header--extended .usa-navbar {
    margin-left: auto;
    margin-right: auto;
    max-width: 64rem;
    padding-left: 1rem;
    padding-right: 1rem;
    display: block;
    height: auto;
    overflow: auto;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .usa-header--extended .usa-navbar {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .usa-header--extended .usa-nav {
    border-top: 1px solid #dfe1e2;
    padding: 0;
    width: 100%;
  }
}
@media all and (min-width: 64em) {
  .usa-header--extended .usa-nav__inner {
    margin-left: auto;
    margin-right: auto;
    max-width: 64rem;
    padding-left: 1rem;
    padding-right: 1rem;
    position: relative;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .usa-header--extended .usa-nav__inner {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .usa-header--extended .usa-nav__primary {
    margin-left: -1rem;
  }
  .usa-header--extended .usa-nav__primary::after {
    clear: both;
    content: "";
    display: block;
  }
}
@media all and (min-width: 64em) {
  .usa-header--extended .usa-nav__link {
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
}
@media all and (min-width: 64em) {
  .usa-header--extended .usa-nav__submenu .usa-grid-full {
    padding-left: 0.75rem;
  }
}
@media all and (min-width: 64em) {
  .usa-header--extended .usa-nav__submenu.usa-megamenu {
    left: 0;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
html.usa-js-loading .usa-nav__submenu,
html.usa-js-loading .usa-nav__submenu.usa-megamenu {
  position: absolute;
  left: -999em;
  right: auto;
}
.usa-megamenu .usa-col {
  flex: 1 1 auto;
}
@media all and (min-width: 64em) {
  .usa-megamenu .usa-col {
    flex: 4 1 0%;
  }
  .usa-megamenu .usa-col .usa-nav__submenu-item a {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .usa-megamenu .usa-col:first-child .usa-nav__submenu-item a {
    padding-left: 0;
  }
  .usa-megamenu .usa-col:last-child .usa-nav__submenu-item a {
    padding-right: 0;
  }
}
@media all and (min-width: 64em) {
  .usa-megamenu.usa-nav__submenu {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 2rem;
    padding-top: 2rem;
    left: -70%;
    right: 0;
    width: auto;
  }
}
@media all and (min-width: 64em) {
  .usa-megamenu.usa-nav__submenu::before {
    position: absolute;
    bottom: 0;
    top: 0;
    background-color: #1C304A;
    content: "";
    display: block;
    position: absolute;
    width: calc(50vw - 32rem + 2rem);
    right: 100%;
  }
}
@media all and (min-width: 64em) {
  .usa-megamenu.usa-nav__submenu::after {
    position: absolute;
    bottom: 0;
    top: 0;
    background-color: #1C304A;
    content: "";
    display: block;
    position: absolute;
    width: calc(50vw - 32rem + 2rem);
    left: 100%;
  }
}
@media all and (min-width: 64em) {
  .usa-header--extended .usa-megamenu.usa-nav__submenu::before {
    position: absolute;
    bottom: 0;
    top: 0;
    background-color: #1C304A;
    content: "";
    display: block;
    position: absolute;
    width: calc(50vw - 32rem);
  }
}
@media all and (min-width: 64em) {
  .usa-header--extended .usa-megamenu.usa-nav__submenu::after {
    position: absolute;
    bottom: 0;
    top: 0;
    background-color: #1C304A;
    content: "";
    display: block;
    position: absolute;
    width: calc(50vw - 32rem);
  }
}
@media all and (min-width: 64em) {
  .usa-nav-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 64rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .usa-nav-container::after {
    clear: both;
    content: "";
    display: block;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .usa-nav-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.usa-navbar {
  height: 3rem;
}
@media all and (max-width: 63.99em) {
  .usa-navbar {
    align-items: center;
    border-bottom: 1px solid #dfe1e2;
    display: flex;
  }
}
@media all and (min-width: 64em) {
  .usa-navbar {
    border-bottom: none;
    display: inline-block;
    height: auto;
  }
}
.usa-hero {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.08rem;
  line-height: 1.6;
  background-image: url("../hero-OXUBFI7L.jpg");
  background-position: center;
  background-size: cover;
  color: white;
}
.usa-hero > .grid-container {
  position: relative;
  padding-bottom: 2rem;
  padding-top: 2rem;
}
.usa-hero__callout {
  background-color: #1C304A;
  padding: 2rem;
}
@media all and (min-width: 40em) {
  .usa-hero__callout {
    max-width: 20rem;
  }
}
.usa-hero__heading {
  margin-bottom: 0;
  margin-top: 0;
  clear: both;
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 2.03rem;
  line-height: 1.2;
  font-weight: 700;
  color: #00CFFF;
  line-height: 1.2;
}
* + .usa-hero__heading {
  margin-top: 1.5em;
}
.usa-hero__heading + * {
  margin-top: 1em;
}
.usa-hero__heading--alt {
  color: white;
  display: block;
}
.usa-icon-list {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.08rem;
  line-height: 1.6;
  margin-bottom: 0;
  margin-top: 0;
  list-style-type: none;
  padding-left: 0;
  max-width: 72ex;
}
.usa-icon-list > li {
  margin-bottom: 0;
  max-width: unset;
}
.usa-icon-list__item {
  display: flex;
  position: relative;
}
.usa-icon-list__item + .usa-icon-list__item {
  padding-top: 0.75rem;
}
.usa-prose .usa-icon-list {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.08rem;
  line-height: 1.6;
  margin-bottom: 0;
  margin-top: 0;
  list-style-type: none;
  padding-left: 0;
  max-width: 72ex;
}
.usa-prose .usa-icon-list > li {
  margin-bottom: 0;
  max-width: unset;
}
.usa-prose .usa-icon-list__item {
  display: flex;
  position: relative;
}
.usa-prose .usa-icon-list__item + .usa-icon-list__item {
  padding-top: 0.75rem;
}
.usa-icon-list--base-lightest .usa-icon-list__icon {
  color: #f0f0f0;
}
.usa-icon-list--base-lighter .usa-icon-list__icon {
  color: #dfe1e2;
}
.usa-icon-list--base-light .usa-icon-list__icon {
  color: #a9aeb1;
}
.usa-icon-list--base .usa-icon-list__icon {
  color: #71767a;
}
.usa-icon-list--base-dark .usa-icon-list__icon {
  color: #565c65;
}
.usa-icon-list--base-darker .usa-icon-list__icon {
  color: #3d4551;
}
.usa-icon-list--base-darkest .usa-icon-list__icon {
  color: #1b1b1b;
}
.usa-icon-list--primary-lightest .usa-icon-list__icon {
  color: #B3EFFF;
}
.usa-icon-list--primary-lighter .usa-icon-list__icon {
  color: #97d4ea;
}
.usa-icon-list--primary-light .usa-icon-list__icon {
  color: #28a0cb;
}
.usa-icon-list--primary .usa-icon-list__icon {
  color: #046B99;
}
.usa-icon-list--primary-vivid .usa-icon-list__icon {
  color: #0050d8;
}
.usa-icon-list--primary-dark .usa-icon-list__icon {
  color: #0b4778;
}
.usa-icon-list--primary-darker .usa-icon-list__icon {
  color: #1C304A;
}
.usa-icon-list--primary-darkest .usa-icon-list__icon {
  color: #11181d;
}
.usa-icon-list--secondary-lighter .usa-icon-list__icon {
  color: #f3e1e4;
}
.usa-icon-list--secondary-light .usa-icon-list__icon {
  color: #f2938c;
}
.usa-icon-list--secondary .usa-icon-list__icon {
  color: #d83933;
}
.usa-icon-list--secondary-vivid .usa-icon-list__icon {
  color: #e41d3d;
}
.usa-icon-list--secondary-dark .usa-icon-list__icon {
  color: #b50909;
}
.usa-icon-list--secondary-darker .usa-icon-list__icon {
  color: #8b0a03;
}
.usa-icon-list--accent-warm-lighter .usa-icon-list__icon {
  color: #f2e4d4;
}
.usa-icon-list--accent-warm-light .usa-icon-list__icon {
  color: #ffbc78;
}
.usa-icon-list--accent-warm .usa-icon-list__icon {
  color: #fa9441;
}
.usa-icon-list--accent-warm-dark .usa-icon-list__icon {
  color: #c05600;
}
.usa-icon-list--accent-warm-darker .usa-icon-list__icon {
  color: #775540;
}
.usa-icon-list--accent-cool-lightest .usa-icon-list__icon {
  color: #eff6fb;
}
.usa-icon-list--accent-cool-lighter .usa-icon-list__icon {
  color: #a8f2ff;
}
.usa-icon-list--accent-cool-light .usa-icon-list__icon {
  color: #52daf2;
}
.usa-icon-list--accent-cool .usa-icon-list__icon {
  color: #00CFFF;
}
.usa-icon-list--accent-cool-dark .usa-icon-list__icon {
  color: #0081a1;
}
.usa-icon-list--accent-cool-darker .usa-icon-list__icon {
  color: #0e4f5c;
}
.usa-icon-list--accent-cool-darkest .usa-icon-list__icon {
  color: #093b44;
}
.usa-icon-list--error-lighter .usa-icon-list__icon {
  color: #fff5ee;
}
.usa-icon-list--error-light .usa-icon-list__icon {
  color: #f39268;
}
.usa-icon-list--error .usa-icon-list__icon {
  color: #d54309;
}
.usa-icon-list--error-dark .usa-icon-list__icon {
  color: #b50909;
}
.usa-icon-list--error-darker .usa-icon-list__icon {
  color: #6f3331;
}
.usa-icon-list--warning-lighter .usa-icon-list__icon {
  color: #faf3d1;
}
.usa-icon-list--warning-light .usa-icon-list__icon {
  color: #fee685;
}
.usa-icon-list--warning .usa-icon-list__icon {
  color: #ffbe2e;
}
.usa-icon-list--warning-dark .usa-icon-list__icon {
  color: #e5a000;
}
.usa-icon-list--warning-darker .usa-icon-list__icon {
  color: #936f38;
}
.usa-icon-list--success-lighter .usa-icon-list__icon {
  color: #ecf3ec;
}
.usa-icon-list--success-light .usa-icon-list__icon {
  color: #70e17b;
}
.usa-icon-list--success .usa-icon-list__icon {
  color: #00a91c;
}
.usa-icon-list--success-dark .usa-icon-list__icon {
  color: #008817;
}
.usa-icon-list--success-darker .usa-icon-list__icon {
  color: #216e1f;
}
.usa-icon-list--info-lighter .usa-icon-list__icon {
  color: #e7f6f8;
}
.usa-icon-list--info-light .usa-icon-list__icon {
  color: #99deea;
}
.usa-icon-list--info .usa-icon-list__icon {
  color: #00bde3;
}
.usa-icon-list--info-dark .usa-icon-list__icon {
  color: #009ec1;
}
.usa-icon-list--info-darker .usa-icon-list__icon {
  color: #2e6276;
}
.usa-icon-list--disabled-lighter .usa-icon-list__icon {
  color: #c9c9c9;
}
.usa-icon-list--disabled-light .usa-icon-list__icon {
  color: #919191;
}
.usa-icon-list--disabled .usa-icon-list__icon {
  color: #757575;
}
.usa-icon-list--disabled-dark .usa-icon-list__icon {
  color: #454545;
}
.usa-icon-list--disabled-darker .usa-icon-list__icon {
  color: #1b1b1b;
}
.usa-icon-list--emergency .usa-icon-list__icon {
  color: #9c3d10;
}
.usa-icon-list--emergency-dark .usa-icon-list__icon {
  color: #332d29;
}
.usa-icon-list__icon .usa-icon {
  display: block;
  height: 1.59rem;
  position: relative;
  width: 1.59rem;
}
.usa-icon-list__content > p,
.usa-icon-list__content > ul,
.usa-icon-list__content > ol {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.08rem;
  max-width: 72ex;
}
.usa-icon-list[class*=usa-icon-list--size-] {
  max-width: none;
}
.usa-icon-list--size-micro .usa-icon-list__icon .usa-icon {
  height: 0.945rem;
  width: 0.945rem;
}
.usa-icon-list--size-micro .usa-icon-list__content {
  max-width: 72ex;
  font-size: 0.63rem;
  padding-left: 0.252rem;
}
.usa-icon-list--size-micro .usa-icon-list__content .usa-icon-list__title {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 0.63rem;
}
.usa-icon-list--size-1 .usa-icon-list__icon .usa-icon {
  height: 1.14rem;
  width: 1.14rem;
}
.usa-icon-list--size-1 .usa-icon-list__content {
  max-width: 72ex;
  font-size: 0.76rem;
  padding-left: 0.304rem;
}
.usa-icon-list--size-1 .usa-icon-list__content .usa-icon-list__title {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 0.76rem;
}
.usa-icon-list--size-2 .usa-icon-list__icon .usa-icon {
  height: 1.23rem;
  width: 1.23rem;
}
.usa-icon-list--size-2 .usa-icon-list__content {
  max-width: 72ex;
  font-size: 0.82rem;
  padding-left: 0.328rem;
}
.usa-icon-list--size-2 .usa-icon-list__content .usa-icon-list__title {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 0.82rem;
}
.usa-icon-list--size-3 .usa-icon-list__icon .usa-icon {
  height: 1.335rem;
  width: 1.335rem;
}
.usa-icon-list--size-3 .usa-icon-list__content {
  max-width: 72ex;
  font-size: 0.89rem;
  padding-left: 0.356rem;
}
.usa-icon-list--size-3 .usa-icon-list__content .usa-icon-list__title {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 0.89rem;
}
.usa-icon-list--size-4 .usa-icon-list__icon .usa-icon {
  height: 1.425rem;
  width: 1.425rem;
}
.usa-icon-list--size-4 .usa-icon-list__content {
  max-width: 72ex;
  font-size: 0.95rem;
  padding-left: 0.38rem;
}
.usa-icon-list--size-4 .usa-icon-list__content .usa-icon-list__title {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 0.95rem;
}
.usa-icon-list--size-5 .usa-icon-list__icon .usa-icon {
  height: 1.515rem;
  width: 1.515rem;
}
.usa-icon-list--size-5 .usa-icon-list__content {
  max-width: 72ex;
  font-size: 1.01rem;
  padding-left: 0.404rem;
}
.usa-icon-list--size-5 .usa-icon-list__content .usa-icon-list__title {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.01rem;
}
.usa-icon-list--size-6 .usa-icon-list__icon .usa-icon {
  height: 1.62rem;
  width: 1.62rem;
}
.usa-icon-list--size-6 .usa-icon-list__content {
  max-width: 72ex;
  font-size: 1.08rem;
  padding-left: 0.432rem;
}
.usa-icon-list--size-6 .usa-icon-list__content .usa-icon-list__title {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.08rem;
}
.usa-icon-list--size-7 .usa-icon-list__icon .usa-icon {
  height: 1.71rem;
  width: 1.71rem;
}
.usa-icon-list--size-7 .usa-icon-list__content {
  max-width: 72ex;
  font-size: 1.14rem;
  padding-left: 0.456rem;
}
.usa-icon-list--size-7 .usa-icon-list__content .usa-icon-list__title {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.14rem;
}
.usa-icon-list--size-8 .usa-icon-list__icon .usa-icon {
  height: 1.905rem;
  width: 1.905rem;
}
.usa-icon-list--size-8 .usa-icon-list__content {
  max-width: 72ex;
  font-size: 1.27rem;
  padding-left: 0.508rem;
}
.usa-icon-list--size-8 .usa-icon-list__content .usa-icon-list__title {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.27rem;
}
.usa-icon-list--size-9 .usa-icon-list__icon .usa-icon {
  height: 2.085rem;
  width: 2.085rem;
}
.usa-icon-list--size-9 .usa-icon-list__content {
  max-width: 72ex;
  font-size: 1.39rem;
  padding-left: 0.556rem;
}
.usa-icon-list--size-9 .usa-icon-list__content .usa-icon-list__title {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.39rem;
}
.usa-icon-list--size-10 .usa-icon-list__icon .usa-icon {
  height: 2.28rem;
  width: 2.28rem;
}
.usa-icon-list--size-10 .usa-icon-list__content {
  max-width: 72ex;
  font-size: 1.52rem;
  padding-left: 0.608rem;
}
.usa-icon-list--size-10 .usa-icon-list__content .usa-icon-list__title {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.52rem;
}
.usa-icon-list--size-11 .usa-icon-list__icon .usa-icon {
  height: 2.655rem;
  width: 2.655rem;
}
.usa-icon-list--size-11 .usa-icon-list__content {
  max-width: 72ex;
  font-size: 1.77rem;
  padding-left: 0.708rem;
}
.usa-icon-list--size-11 .usa-icon-list__content .usa-icon-list__title {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.77rem;
}
.usa-icon-list--size-12 .usa-icon-list__icon .usa-icon {
  height: 3.045rem;
  width: 3.045rem;
}
.usa-icon-list--size-12 .usa-icon-list__content {
  max-width: 72ex;
  font-size: 2.03rem;
  padding-left: 0.812rem;
}
.usa-icon-list--size-12 .usa-icon-list__content .usa-icon-list__title {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 2.03rem;
}
.usa-icon-list--size-13 .usa-icon-list__icon .usa-icon {
  height: 3.42rem;
  width: 3.42rem;
}
.usa-icon-list--size-13 .usa-icon-list__content {
  max-width: 72ex;
  font-size: 2.28rem;
  padding-left: 0.912rem;
}
.usa-icon-list--size-13 .usa-icon-list__content .usa-icon-list__title {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 2.28rem;
}
.usa-icon-list--size-14 .usa-icon-list__icon .usa-icon {
  height: 3.81rem;
  width: 3.81rem;
}
.usa-icon-list--size-14 .usa-icon-list__content {
  max-width: 72ex;
  font-size: 2.54rem;
  padding-left: 1.016rem;
}
.usa-icon-list--size-14 .usa-icon-list__content .usa-icon-list__title {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 2.54rem;
}
.usa-icon-list--size-15 .usa-icon-list__icon .usa-icon {
  height: 4.56rem;
  width: 4.56rem;
}
.usa-icon-list--size-15 .usa-icon-list__content {
  max-width: 72ex;
  font-size: 3.04rem;
  padding-left: 1.216rem;
}
.usa-icon-list--size-15 .usa-icon-list__content .usa-icon-list__title {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 3.04rem;
}
.usa-icon-list--size-16 .usa-icon-list__icon .usa-icon {
  height: 5.325rem;
  width: 5.325rem;
}
.usa-icon-list--size-16 .usa-icon-list__content {
  max-width: 72ex;
  font-size: 3.55rem;
  padding-left: 1.42rem;
}
.usa-icon-list--size-16 .usa-icon-list__content .usa-icon-list__title {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 3.55rem;
}
.usa-icon-list--size-17 .usa-icon-list__icon .usa-icon {
  height: 6.09rem;
  width: 6.09rem;
}
.usa-icon-list--size-17 .usa-icon-list__content {
  max-width: 72ex;
  font-size: 4.06rem;
  padding-left: 1.624rem;
}
.usa-icon-list--size-17 .usa-icon-list__content .usa-icon-list__title {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 4.06rem;
}
.usa-icon-list--size-18 .usa-icon-list__icon .usa-icon {
  height: 7.605rem;
  width: 7.605rem;
}
.usa-icon-list--size-18 .usa-icon-list__content {
  max-width: 72ex;
  font-size: 5.07rem;
  padding-left: 2.028rem;
}
.usa-icon-list--size-18 .usa-icon-list__content .usa-icon-list__title {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 5.07rem;
}
.usa-icon-list--size-19 .usa-icon-list__icon .usa-icon {
  height: 11.415rem;
  width: 11.415rem;
}
.usa-icon-list--size-19 .usa-icon-list__content {
  max-width: 72ex;
  font-size: 7.61rem;
  padding-left: 3.044rem;
}
.usa-icon-list--size-19 .usa-icon-list__content .usa-icon-list__title {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 7.61rem;
}
.usa-icon-list--size-20 .usa-icon-list__icon .usa-icon {
  height: 13.305rem;
  width: 13.305rem;
}
.usa-icon-list--size-20 .usa-icon-list__content {
  max-width: 72ex;
  font-size: 8.87rem;
  padding-left: 3.548rem;
}
.usa-icon-list--size-20 .usa-icon-list__content .usa-icon-list__title {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 8.87rem;
}
.usa-icon-list--size-3xs .usa-icon-list__icon .usa-icon {
  height: 1.23rem;
  width: 1.23rem;
}
.usa-icon-list--size-3xs .usa-icon-list__content {
  max-width: 72ex;
  font-size: 0.82rem;
  padding-left: 0.328rem;
}
.usa-icon-list--size-3xs .usa-icon-list__content .usa-icon-list__title {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 0.82rem;
}
.usa-icon-list--size-2xs .usa-icon-list__icon .usa-icon {
  height: 1.335rem;
  width: 1.335rem;
}
.usa-icon-list--size-2xs .usa-icon-list__content {
  max-width: 72ex;
  font-size: 0.89rem;
  padding-left: 0.356rem;
}
.usa-icon-list--size-2xs .usa-icon-list__content .usa-icon-list__title {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 0.89rem;
}
.usa-icon-list--size-xs .usa-icon-list__icon .usa-icon {
  height: 1.425rem;
  width: 1.425rem;
}
.usa-icon-list--size-xs .usa-icon-list__content {
  max-width: 72ex;
  font-size: 0.95rem;
  padding-left: 0.38rem;
}
.usa-icon-list--size-xs .usa-icon-list__content .usa-icon-list__title {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 0.95rem;
}
.usa-icon-list--size-sm .usa-icon-list__icon .usa-icon {
  height: 1.515rem;
  width: 1.515rem;
}
.usa-icon-list--size-sm .usa-icon-list__content {
  max-width: 72ex;
  font-size: 1.01rem;
  padding-left: 0.404rem;
}
.usa-icon-list--size-sm .usa-icon-list__content .usa-icon-list__title {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.01rem;
}
.usa-icon-list--size-md .usa-icon-list__icon .usa-icon {
  height: 1.62rem;
  width: 1.62rem;
}
.usa-icon-list--size-md .usa-icon-list__content {
  max-width: 72ex;
  font-size: 1.08rem;
  padding-left: 0.432rem;
}
.usa-icon-list--size-md .usa-icon-list__content .usa-icon-list__title {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.08rem;
}
.usa-icon-list--size-lg .usa-icon-list__icon .usa-icon {
  height: 2.085rem;
  width: 2.085rem;
}
.usa-icon-list--size-lg .usa-icon-list__content {
  max-width: 72ex;
  font-size: 1.39rem;
  padding-left: 0.556rem;
}
.usa-icon-list--size-lg .usa-icon-list__content .usa-icon-list__title {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.39rem;
}
.usa-icon-list--size-xl .usa-icon-list__icon .usa-icon {
  height: 3.045rem;
  width: 3.045rem;
}
.usa-icon-list--size-xl .usa-icon-list__content {
  max-width: 72ex;
  font-size: 2.03rem;
  padding-left: 0.812rem;
}
.usa-icon-list--size-xl .usa-icon-list__content .usa-icon-list__title {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 2.03rem;
}
.usa-icon-list--size-2xl .usa-icon-list__icon .usa-icon {
  height: 3.81rem;
  width: 3.81rem;
}
.usa-icon-list--size-2xl .usa-icon-list__content {
  max-width: 72ex;
  font-size: 2.54rem;
  padding-left: 1.016rem;
}
.usa-icon-list--size-2xl .usa-icon-list__content .usa-icon-list__title {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 2.54rem;
}
.usa-icon-list--size-3xl .usa-icon-list__icon .usa-icon {
  height: 4.56rem;
  width: 4.56rem;
}
.usa-icon-list--size-3xl .usa-icon-list__content {
  max-width: 72ex;
  font-size: 3.04rem;
  padding-left: 1.216rem;
}
.usa-icon-list--size-3xl .usa-icon-list__content .usa-icon-list__title {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 3.04rem;
}
@media all and (min-width: 30em) {
  .mobile-lg\:usa-icon-list--size-micro .usa-icon-list__icon .usa-icon {
    height: 0.945rem;
    width: 0.945rem;
  }
  .mobile-lg\:usa-icon-list--size-micro .usa-icon-list__content {
    max-width: 72ex;
    font-size: 0.63rem;
    padding-left: 0.252rem;
  }
  .mobile-lg\:usa-icon-list--size-micro .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 0.63rem;
  }
  .mobile-lg\:usa-icon-list--size-1 .usa-icon-list__icon .usa-icon {
    height: 1.14rem;
    width: 1.14rem;
  }
  .mobile-lg\:usa-icon-list--size-1 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 0.76rem;
    padding-left: 0.304rem;
  }
  .mobile-lg\:usa-icon-list--size-1 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 0.76rem;
  }
  .mobile-lg\:usa-icon-list--size-2 .usa-icon-list__icon .usa-icon {
    height: 1.23rem;
    width: 1.23rem;
  }
  .mobile-lg\:usa-icon-list--size-2 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 0.82rem;
    padding-left: 0.328rem;
  }
  .mobile-lg\:usa-icon-list--size-2 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 0.82rem;
  }
  .mobile-lg\:usa-icon-list--size-3 .usa-icon-list__icon .usa-icon {
    height: 1.335rem;
    width: 1.335rem;
  }
  .mobile-lg\:usa-icon-list--size-3 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 0.89rem;
    padding-left: 0.356rem;
  }
  .mobile-lg\:usa-icon-list--size-3 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 0.89rem;
  }
  .mobile-lg\:usa-icon-list--size-4 .usa-icon-list__icon .usa-icon {
    height: 1.425rem;
    width: 1.425rem;
  }
  .mobile-lg\:usa-icon-list--size-4 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 0.95rem;
    padding-left: 0.38rem;
  }
  .mobile-lg\:usa-icon-list--size-4 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 0.95rem;
  }
  .mobile-lg\:usa-icon-list--size-5 .usa-icon-list__icon .usa-icon {
    height: 1.515rem;
    width: 1.515rem;
  }
  .mobile-lg\:usa-icon-list--size-5 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 1.01rem;
    padding-left: 0.404rem;
  }
  .mobile-lg\:usa-icon-list--size-5 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 1.01rem;
  }
  .mobile-lg\:usa-icon-list--size-6 .usa-icon-list__icon .usa-icon {
    height: 1.62rem;
    width: 1.62rem;
  }
  .mobile-lg\:usa-icon-list--size-6 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 1.08rem;
    padding-left: 0.432rem;
  }
  .mobile-lg\:usa-icon-list--size-6 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 1.08rem;
  }
  .mobile-lg\:usa-icon-list--size-7 .usa-icon-list__icon .usa-icon {
    height: 1.71rem;
    width: 1.71rem;
  }
  .mobile-lg\:usa-icon-list--size-7 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 1.14rem;
    padding-left: 0.456rem;
  }
  .mobile-lg\:usa-icon-list--size-7 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 1.14rem;
  }
  .mobile-lg\:usa-icon-list--size-8 .usa-icon-list__icon .usa-icon {
    height: 1.905rem;
    width: 1.905rem;
  }
  .mobile-lg\:usa-icon-list--size-8 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 1.27rem;
    padding-left: 0.508rem;
  }
  .mobile-lg\:usa-icon-list--size-8 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 1.27rem;
  }
  .mobile-lg\:usa-icon-list--size-9 .usa-icon-list__icon .usa-icon {
    height: 2.085rem;
    width: 2.085rem;
  }
  .mobile-lg\:usa-icon-list--size-9 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 1.39rem;
    padding-left: 0.556rem;
  }
  .mobile-lg\:usa-icon-list--size-9 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 1.39rem;
  }
  .mobile-lg\:usa-icon-list--size-10 .usa-icon-list__icon .usa-icon {
    height: 2.28rem;
    width: 2.28rem;
  }
  .mobile-lg\:usa-icon-list--size-10 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 1.52rem;
    padding-left: 0.608rem;
  }
  .mobile-lg\:usa-icon-list--size-10 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 1.52rem;
  }
  .mobile-lg\:usa-icon-list--size-11 .usa-icon-list__icon .usa-icon {
    height: 2.655rem;
    width: 2.655rem;
  }
  .mobile-lg\:usa-icon-list--size-11 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 1.77rem;
    padding-left: 0.708rem;
  }
  .mobile-lg\:usa-icon-list--size-11 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 1.77rem;
  }
  .mobile-lg\:usa-icon-list--size-12 .usa-icon-list__icon .usa-icon {
    height: 3.045rem;
    width: 3.045rem;
  }
  .mobile-lg\:usa-icon-list--size-12 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 2.03rem;
    padding-left: 0.812rem;
  }
  .mobile-lg\:usa-icon-list--size-12 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 2.03rem;
  }
  .mobile-lg\:usa-icon-list--size-13 .usa-icon-list__icon .usa-icon {
    height: 3.42rem;
    width: 3.42rem;
  }
  .mobile-lg\:usa-icon-list--size-13 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 2.28rem;
    padding-left: 0.912rem;
  }
  .mobile-lg\:usa-icon-list--size-13 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 2.28rem;
  }
  .mobile-lg\:usa-icon-list--size-14 .usa-icon-list__icon .usa-icon {
    height: 3.81rem;
    width: 3.81rem;
  }
  .mobile-lg\:usa-icon-list--size-14 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 2.54rem;
    padding-left: 1.016rem;
  }
  .mobile-lg\:usa-icon-list--size-14 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 2.54rem;
  }
  .mobile-lg\:usa-icon-list--size-15 .usa-icon-list__icon .usa-icon {
    height: 4.56rem;
    width: 4.56rem;
  }
  .mobile-lg\:usa-icon-list--size-15 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 3.04rem;
    padding-left: 1.216rem;
  }
  .mobile-lg\:usa-icon-list--size-15 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 3.04rem;
  }
  .mobile-lg\:usa-icon-list--size-16 .usa-icon-list__icon .usa-icon {
    height: 5.325rem;
    width: 5.325rem;
  }
  .mobile-lg\:usa-icon-list--size-16 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 3.55rem;
    padding-left: 1.42rem;
  }
  .mobile-lg\:usa-icon-list--size-16 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 3.55rem;
  }
  .mobile-lg\:usa-icon-list--size-17 .usa-icon-list__icon .usa-icon {
    height: 6.09rem;
    width: 6.09rem;
  }
  .mobile-lg\:usa-icon-list--size-17 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 4.06rem;
    padding-left: 1.624rem;
  }
  .mobile-lg\:usa-icon-list--size-17 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 4.06rem;
  }
  .mobile-lg\:usa-icon-list--size-18 .usa-icon-list__icon .usa-icon {
    height: 7.605rem;
    width: 7.605rem;
  }
  .mobile-lg\:usa-icon-list--size-18 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 5.07rem;
    padding-left: 2.028rem;
  }
  .mobile-lg\:usa-icon-list--size-18 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 5.07rem;
  }
  .mobile-lg\:usa-icon-list--size-19 .usa-icon-list__icon .usa-icon {
    height: 11.415rem;
    width: 11.415rem;
  }
  .mobile-lg\:usa-icon-list--size-19 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 7.61rem;
    padding-left: 3.044rem;
  }
  .mobile-lg\:usa-icon-list--size-19 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 7.61rem;
  }
  .mobile-lg\:usa-icon-list--size-20 .usa-icon-list__icon .usa-icon {
    height: 13.305rem;
    width: 13.305rem;
  }
  .mobile-lg\:usa-icon-list--size-20 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 8.87rem;
    padding-left: 3.548rem;
  }
  .mobile-lg\:usa-icon-list--size-20 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 8.87rem;
  }
  .mobile-lg\:usa-icon-list--size-3xs .usa-icon-list__icon .usa-icon {
    height: 1.23rem;
    width: 1.23rem;
  }
  .mobile-lg\:usa-icon-list--size-3xs .usa-icon-list__content {
    max-width: 72ex;
    font-size: 0.82rem;
    padding-left: 0.328rem;
  }
  .mobile-lg\:usa-icon-list--size-3xs .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 0.82rem;
  }
  .mobile-lg\:usa-icon-list--size-2xs .usa-icon-list__icon .usa-icon {
    height: 1.335rem;
    width: 1.335rem;
  }
  .mobile-lg\:usa-icon-list--size-2xs .usa-icon-list__content {
    max-width: 72ex;
    font-size: 0.89rem;
    padding-left: 0.356rem;
  }
  .mobile-lg\:usa-icon-list--size-2xs .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 0.89rem;
  }
  .mobile-lg\:usa-icon-list--size-xs .usa-icon-list__icon .usa-icon {
    height: 1.425rem;
    width: 1.425rem;
  }
  .mobile-lg\:usa-icon-list--size-xs .usa-icon-list__content {
    max-width: 72ex;
    font-size: 0.95rem;
    padding-left: 0.38rem;
  }
  .mobile-lg\:usa-icon-list--size-xs .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 0.95rem;
  }
  .mobile-lg\:usa-icon-list--size-sm .usa-icon-list__icon .usa-icon {
    height: 1.515rem;
    width: 1.515rem;
  }
  .mobile-lg\:usa-icon-list--size-sm .usa-icon-list__content {
    max-width: 72ex;
    font-size: 1.01rem;
    padding-left: 0.404rem;
  }
  .mobile-lg\:usa-icon-list--size-sm .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 1.01rem;
  }
  .mobile-lg\:usa-icon-list--size-md .usa-icon-list__icon .usa-icon {
    height: 1.62rem;
    width: 1.62rem;
  }
  .mobile-lg\:usa-icon-list--size-md .usa-icon-list__content {
    max-width: 72ex;
    font-size: 1.08rem;
    padding-left: 0.432rem;
  }
  .mobile-lg\:usa-icon-list--size-md .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 1.08rem;
  }
  .mobile-lg\:usa-icon-list--size-lg .usa-icon-list__icon .usa-icon {
    height: 2.085rem;
    width: 2.085rem;
  }
  .mobile-lg\:usa-icon-list--size-lg .usa-icon-list__content {
    max-width: 72ex;
    font-size: 1.39rem;
    padding-left: 0.556rem;
  }
  .mobile-lg\:usa-icon-list--size-lg .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 1.39rem;
  }
  .mobile-lg\:usa-icon-list--size-xl .usa-icon-list__icon .usa-icon {
    height: 3.045rem;
    width: 3.045rem;
  }
  .mobile-lg\:usa-icon-list--size-xl .usa-icon-list__content {
    max-width: 72ex;
    font-size: 2.03rem;
    padding-left: 0.812rem;
  }
  .mobile-lg\:usa-icon-list--size-xl .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 2.03rem;
  }
  .mobile-lg\:usa-icon-list--size-2xl .usa-icon-list__icon .usa-icon {
    height: 3.81rem;
    width: 3.81rem;
  }
  .mobile-lg\:usa-icon-list--size-2xl .usa-icon-list__content {
    max-width: 72ex;
    font-size: 2.54rem;
    padding-left: 1.016rem;
  }
  .mobile-lg\:usa-icon-list--size-2xl .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 2.54rem;
  }
  .mobile-lg\:usa-icon-list--size-3xl .usa-icon-list__icon .usa-icon {
    height: 4.56rem;
    width: 4.56rem;
  }
  .mobile-lg\:usa-icon-list--size-3xl .usa-icon-list__content {
    max-width: 72ex;
    font-size: 3.04rem;
    padding-left: 1.216rem;
  }
  .mobile-lg\:usa-icon-list--size-3xl .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 3.04rem;
  }
}
@media all and (min-width: 40em) {
  .tablet\:usa-icon-list--size-micro .usa-icon-list__icon .usa-icon {
    height: 0.945rem;
    width: 0.945rem;
  }
  .tablet\:usa-icon-list--size-micro .usa-icon-list__content {
    max-width: 72ex;
    font-size: 0.63rem;
    padding-left: 0.252rem;
  }
  .tablet\:usa-icon-list--size-micro .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 0.63rem;
  }
  .tablet\:usa-icon-list--size-1 .usa-icon-list__icon .usa-icon {
    height: 1.14rem;
    width: 1.14rem;
  }
  .tablet\:usa-icon-list--size-1 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 0.76rem;
    padding-left: 0.304rem;
  }
  .tablet\:usa-icon-list--size-1 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 0.76rem;
  }
  .tablet\:usa-icon-list--size-2 .usa-icon-list__icon .usa-icon {
    height: 1.23rem;
    width: 1.23rem;
  }
  .tablet\:usa-icon-list--size-2 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 0.82rem;
    padding-left: 0.328rem;
  }
  .tablet\:usa-icon-list--size-2 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 0.82rem;
  }
  .tablet\:usa-icon-list--size-3 .usa-icon-list__icon .usa-icon {
    height: 1.335rem;
    width: 1.335rem;
  }
  .tablet\:usa-icon-list--size-3 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 0.89rem;
    padding-left: 0.356rem;
  }
  .tablet\:usa-icon-list--size-3 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 0.89rem;
  }
  .tablet\:usa-icon-list--size-4 .usa-icon-list__icon .usa-icon {
    height: 1.425rem;
    width: 1.425rem;
  }
  .tablet\:usa-icon-list--size-4 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 0.95rem;
    padding-left: 0.38rem;
  }
  .tablet\:usa-icon-list--size-4 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 0.95rem;
  }
  .tablet\:usa-icon-list--size-5 .usa-icon-list__icon .usa-icon {
    height: 1.515rem;
    width: 1.515rem;
  }
  .tablet\:usa-icon-list--size-5 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 1.01rem;
    padding-left: 0.404rem;
  }
  .tablet\:usa-icon-list--size-5 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 1.01rem;
  }
  .tablet\:usa-icon-list--size-6 .usa-icon-list__icon .usa-icon {
    height: 1.62rem;
    width: 1.62rem;
  }
  .tablet\:usa-icon-list--size-6 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 1.08rem;
    padding-left: 0.432rem;
  }
  .tablet\:usa-icon-list--size-6 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 1.08rem;
  }
  .tablet\:usa-icon-list--size-7 .usa-icon-list__icon .usa-icon {
    height: 1.71rem;
    width: 1.71rem;
  }
  .tablet\:usa-icon-list--size-7 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 1.14rem;
    padding-left: 0.456rem;
  }
  .tablet\:usa-icon-list--size-7 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 1.14rem;
  }
  .tablet\:usa-icon-list--size-8 .usa-icon-list__icon .usa-icon {
    height: 1.905rem;
    width: 1.905rem;
  }
  .tablet\:usa-icon-list--size-8 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 1.27rem;
    padding-left: 0.508rem;
  }
  .tablet\:usa-icon-list--size-8 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 1.27rem;
  }
  .tablet\:usa-icon-list--size-9 .usa-icon-list__icon .usa-icon {
    height: 2.085rem;
    width: 2.085rem;
  }
  .tablet\:usa-icon-list--size-9 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 1.39rem;
    padding-left: 0.556rem;
  }
  .tablet\:usa-icon-list--size-9 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 1.39rem;
  }
  .tablet\:usa-icon-list--size-10 .usa-icon-list__icon .usa-icon {
    height: 2.28rem;
    width: 2.28rem;
  }
  .tablet\:usa-icon-list--size-10 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 1.52rem;
    padding-left: 0.608rem;
  }
  .tablet\:usa-icon-list--size-10 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 1.52rem;
  }
  .tablet\:usa-icon-list--size-11 .usa-icon-list__icon .usa-icon {
    height: 2.655rem;
    width: 2.655rem;
  }
  .tablet\:usa-icon-list--size-11 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 1.77rem;
    padding-left: 0.708rem;
  }
  .tablet\:usa-icon-list--size-11 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 1.77rem;
  }
  .tablet\:usa-icon-list--size-12 .usa-icon-list__icon .usa-icon {
    height: 3.045rem;
    width: 3.045rem;
  }
  .tablet\:usa-icon-list--size-12 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 2.03rem;
    padding-left: 0.812rem;
  }
  .tablet\:usa-icon-list--size-12 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 2.03rem;
  }
  .tablet\:usa-icon-list--size-13 .usa-icon-list__icon .usa-icon {
    height: 3.42rem;
    width: 3.42rem;
  }
  .tablet\:usa-icon-list--size-13 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 2.28rem;
    padding-left: 0.912rem;
  }
  .tablet\:usa-icon-list--size-13 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 2.28rem;
  }
  .tablet\:usa-icon-list--size-14 .usa-icon-list__icon .usa-icon {
    height: 3.81rem;
    width: 3.81rem;
  }
  .tablet\:usa-icon-list--size-14 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 2.54rem;
    padding-left: 1.016rem;
  }
  .tablet\:usa-icon-list--size-14 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 2.54rem;
  }
  .tablet\:usa-icon-list--size-15 .usa-icon-list__icon .usa-icon {
    height: 4.56rem;
    width: 4.56rem;
  }
  .tablet\:usa-icon-list--size-15 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 3.04rem;
    padding-left: 1.216rem;
  }
  .tablet\:usa-icon-list--size-15 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 3.04rem;
  }
  .tablet\:usa-icon-list--size-16 .usa-icon-list__icon .usa-icon {
    height: 5.325rem;
    width: 5.325rem;
  }
  .tablet\:usa-icon-list--size-16 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 3.55rem;
    padding-left: 1.42rem;
  }
  .tablet\:usa-icon-list--size-16 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 3.55rem;
  }
  .tablet\:usa-icon-list--size-17 .usa-icon-list__icon .usa-icon {
    height: 6.09rem;
    width: 6.09rem;
  }
  .tablet\:usa-icon-list--size-17 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 4.06rem;
    padding-left: 1.624rem;
  }
  .tablet\:usa-icon-list--size-17 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 4.06rem;
  }
  .tablet\:usa-icon-list--size-18 .usa-icon-list__icon .usa-icon {
    height: 7.605rem;
    width: 7.605rem;
  }
  .tablet\:usa-icon-list--size-18 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 5.07rem;
    padding-left: 2.028rem;
  }
  .tablet\:usa-icon-list--size-18 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 5.07rem;
  }
  .tablet\:usa-icon-list--size-19 .usa-icon-list__icon .usa-icon {
    height: 11.415rem;
    width: 11.415rem;
  }
  .tablet\:usa-icon-list--size-19 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 7.61rem;
    padding-left: 3.044rem;
  }
  .tablet\:usa-icon-list--size-19 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 7.61rem;
  }
  .tablet\:usa-icon-list--size-20 .usa-icon-list__icon .usa-icon {
    height: 13.305rem;
    width: 13.305rem;
  }
  .tablet\:usa-icon-list--size-20 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 8.87rem;
    padding-left: 3.548rem;
  }
  .tablet\:usa-icon-list--size-20 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 8.87rem;
  }
  .tablet\:usa-icon-list--size-3xs .usa-icon-list__icon .usa-icon {
    height: 1.23rem;
    width: 1.23rem;
  }
  .tablet\:usa-icon-list--size-3xs .usa-icon-list__content {
    max-width: 72ex;
    font-size: 0.82rem;
    padding-left: 0.328rem;
  }
  .tablet\:usa-icon-list--size-3xs .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 0.82rem;
  }
  .tablet\:usa-icon-list--size-2xs .usa-icon-list__icon .usa-icon {
    height: 1.335rem;
    width: 1.335rem;
  }
  .tablet\:usa-icon-list--size-2xs .usa-icon-list__content {
    max-width: 72ex;
    font-size: 0.89rem;
    padding-left: 0.356rem;
  }
  .tablet\:usa-icon-list--size-2xs .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 0.89rem;
  }
  .tablet\:usa-icon-list--size-xs .usa-icon-list__icon .usa-icon {
    height: 1.425rem;
    width: 1.425rem;
  }
  .tablet\:usa-icon-list--size-xs .usa-icon-list__content {
    max-width: 72ex;
    font-size: 0.95rem;
    padding-left: 0.38rem;
  }
  .tablet\:usa-icon-list--size-xs .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 0.95rem;
  }
  .tablet\:usa-icon-list--size-sm .usa-icon-list__icon .usa-icon {
    height: 1.515rem;
    width: 1.515rem;
  }
  .tablet\:usa-icon-list--size-sm .usa-icon-list__content {
    max-width: 72ex;
    font-size: 1.01rem;
    padding-left: 0.404rem;
  }
  .tablet\:usa-icon-list--size-sm .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 1.01rem;
  }
  .tablet\:usa-icon-list--size-md .usa-icon-list__icon .usa-icon {
    height: 1.62rem;
    width: 1.62rem;
  }
  .tablet\:usa-icon-list--size-md .usa-icon-list__content {
    max-width: 72ex;
    font-size: 1.08rem;
    padding-left: 0.432rem;
  }
  .tablet\:usa-icon-list--size-md .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 1.08rem;
  }
  .tablet\:usa-icon-list--size-lg .usa-icon-list__icon .usa-icon {
    height: 2.085rem;
    width: 2.085rem;
  }
  .tablet\:usa-icon-list--size-lg .usa-icon-list__content {
    max-width: 72ex;
    font-size: 1.39rem;
    padding-left: 0.556rem;
  }
  .tablet\:usa-icon-list--size-lg .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 1.39rem;
  }
  .tablet\:usa-icon-list--size-xl .usa-icon-list__icon .usa-icon {
    height: 3.045rem;
    width: 3.045rem;
  }
  .tablet\:usa-icon-list--size-xl .usa-icon-list__content {
    max-width: 72ex;
    font-size: 2.03rem;
    padding-left: 0.812rem;
  }
  .tablet\:usa-icon-list--size-xl .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 2.03rem;
  }
  .tablet\:usa-icon-list--size-2xl .usa-icon-list__icon .usa-icon {
    height: 3.81rem;
    width: 3.81rem;
  }
  .tablet\:usa-icon-list--size-2xl .usa-icon-list__content {
    max-width: 72ex;
    font-size: 2.54rem;
    padding-left: 1.016rem;
  }
  .tablet\:usa-icon-list--size-2xl .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 2.54rem;
  }
  .tablet\:usa-icon-list--size-3xl .usa-icon-list__icon .usa-icon {
    height: 4.56rem;
    width: 4.56rem;
  }
  .tablet\:usa-icon-list--size-3xl .usa-icon-list__content {
    max-width: 72ex;
    font-size: 3.04rem;
    padding-left: 1.216rem;
  }
  .tablet\:usa-icon-list--size-3xl .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 3.04rem;
  }
}
@media all and (min-width: 55em) {
  .tablet-lg\:usa-icon-list--size-micro .usa-icon-list__icon .usa-icon {
    height: 0.945rem;
    width: 0.945rem;
  }
  .tablet-lg\:usa-icon-list--size-micro .usa-icon-list__content {
    max-width: 72ex;
    font-size: 0.63rem;
    padding-left: 0.252rem;
  }
  .tablet-lg\:usa-icon-list--size-micro .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 0.63rem;
  }
  .tablet-lg\:usa-icon-list--size-1 .usa-icon-list__icon .usa-icon {
    height: 1.14rem;
    width: 1.14rem;
  }
  .tablet-lg\:usa-icon-list--size-1 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 0.76rem;
    padding-left: 0.304rem;
  }
  .tablet-lg\:usa-icon-list--size-1 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 0.76rem;
  }
  .tablet-lg\:usa-icon-list--size-2 .usa-icon-list__icon .usa-icon {
    height: 1.23rem;
    width: 1.23rem;
  }
  .tablet-lg\:usa-icon-list--size-2 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 0.82rem;
    padding-left: 0.328rem;
  }
  .tablet-lg\:usa-icon-list--size-2 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 0.82rem;
  }
  .tablet-lg\:usa-icon-list--size-3 .usa-icon-list__icon .usa-icon {
    height: 1.335rem;
    width: 1.335rem;
  }
  .tablet-lg\:usa-icon-list--size-3 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 0.89rem;
    padding-left: 0.356rem;
  }
  .tablet-lg\:usa-icon-list--size-3 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 0.89rem;
  }
  .tablet-lg\:usa-icon-list--size-4 .usa-icon-list__icon .usa-icon {
    height: 1.425rem;
    width: 1.425rem;
  }
  .tablet-lg\:usa-icon-list--size-4 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 0.95rem;
    padding-left: 0.38rem;
  }
  .tablet-lg\:usa-icon-list--size-4 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 0.95rem;
  }
  .tablet-lg\:usa-icon-list--size-5 .usa-icon-list__icon .usa-icon {
    height: 1.515rem;
    width: 1.515rem;
  }
  .tablet-lg\:usa-icon-list--size-5 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 1.01rem;
    padding-left: 0.404rem;
  }
  .tablet-lg\:usa-icon-list--size-5 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 1.01rem;
  }
  .tablet-lg\:usa-icon-list--size-6 .usa-icon-list__icon .usa-icon {
    height: 1.62rem;
    width: 1.62rem;
  }
  .tablet-lg\:usa-icon-list--size-6 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 1.08rem;
    padding-left: 0.432rem;
  }
  .tablet-lg\:usa-icon-list--size-6 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 1.08rem;
  }
  .tablet-lg\:usa-icon-list--size-7 .usa-icon-list__icon .usa-icon {
    height: 1.71rem;
    width: 1.71rem;
  }
  .tablet-lg\:usa-icon-list--size-7 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 1.14rem;
    padding-left: 0.456rem;
  }
  .tablet-lg\:usa-icon-list--size-7 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 1.14rem;
  }
  .tablet-lg\:usa-icon-list--size-8 .usa-icon-list__icon .usa-icon {
    height: 1.905rem;
    width: 1.905rem;
  }
  .tablet-lg\:usa-icon-list--size-8 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 1.27rem;
    padding-left: 0.508rem;
  }
  .tablet-lg\:usa-icon-list--size-8 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 1.27rem;
  }
  .tablet-lg\:usa-icon-list--size-9 .usa-icon-list__icon .usa-icon {
    height: 2.085rem;
    width: 2.085rem;
  }
  .tablet-lg\:usa-icon-list--size-9 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 1.39rem;
    padding-left: 0.556rem;
  }
  .tablet-lg\:usa-icon-list--size-9 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 1.39rem;
  }
  .tablet-lg\:usa-icon-list--size-10 .usa-icon-list__icon .usa-icon {
    height: 2.28rem;
    width: 2.28rem;
  }
  .tablet-lg\:usa-icon-list--size-10 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 1.52rem;
    padding-left: 0.608rem;
  }
  .tablet-lg\:usa-icon-list--size-10 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 1.52rem;
  }
  .tablet-lg\:usa-icon-list--size-11 .usa-icon-list__icon .usa-icon {
    height: 2.655rem;
    width: 2.655rem;
  }
  .tablet-lg\:usa-icon-list--size-11 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 1.77rem;
    padding-left: 0.708rem;
  }
  .tablet-lg\:usa-icon-list--size-11 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 1.77rem;
  }
  .tablet-lg\:usa-icon-list--size-12 .usa-icon-list__icon .usa-icon {
    height: 3.045rem;
    width: 3.045rem;
  }
  .tablet-lg\:usa-icon-list--size-12 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 2.03rem;
    padding-left: 0.812rem;
  }
  .tablet-lg\:usa-icon-list--size-12 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 2.03rem;
  }
  .tablet-lg\:usa-icon-list--size-13 .usa-icon-list__icon .usa-icon {
    height: 3.42rem;
    width: 3.42rem;
  }
  .tablet-lg\:usa-icon-list--size-13 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 2.28rem;
    padding-left: 0.912rem;
  }
  .tablet-lg\:usa-icon-list--size-13 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 2.28rem;
  }
  .tablet-lg\:usa-icon-list--size-14 .usa-icon-list__icon .usa-icon {
    height: 3.81rem;
    width: 3.81rem;
  }
  .tablet-lg\:usa-icon-list--size-14 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 2.54rem;
    padding-left: 1.016rem;
  }
  .tablet-lg\:usa-icon-list--size-14 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 2.54rem;
  }
  .tablet-lg\:usa-icon-list--size-15 .usa-icon-list__icon .usa-icon {
    height: 4.56rem;
    width: 4.56rem;
  }
  .tablet-lg\:usa-icon-list--size-15 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 3.04rem;
    padding-left: 1.216rem;
  }
  .tablet-lg\:usa-icon-list--size-15 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 3.04rem;
  }
  .tablet-lg\:usa-icon-list--size-16 .usa-icon-list__icon .usa-icon {
    height: 5.325rem;
    width: 5.325rem;
  }
  .tablet-lg\:usa-icon-list--size-16 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 3.55rem;
    padding-left: 1.42rem;
  }
  .tablet-lg\:usa-icon-list--size-16 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 3.55rem;
  }
  .tablet-lg\:usa-icon-list--size-17 .usa-icon-list__icon .usa-icon {
    height: 6.09rem;
    width: 6.09rem;
  }
  .tablet-lg\:usa-icon-list--size-17 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 4.06rem;
    padding-left: 1.624rem;
  }
  .tablet-lg\:usa-icon-list--size-17 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 4.06rem;
  }
  .tablet-lg\:usa-icon-list--size-18 .usa-icon-list__icon .usa-icon {
    height: 7.605rem;
    width: 7.605rem;
  }
  .tablet-lg\:usa-icon-list--size-18 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 5.07rem;
    padding-left: 2.028rem;
  }
  .tablet-lg\:usa-icon-list--size-18 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 5.07rem;
  }
  .tablet-lg\:usa-icon-list--size-19 .usa-icon-list__icon .usa-icon {
    height: 11.415rem;
    width: 11.415rem;
  }
  .tablet-lg\:usa-icon-list--size-19 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 7.61rem;
    padding-left: 3.044rem;
  }
  .tablet-lg\:usa-icon-list--size-19 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 7.61rem;
  }
  .tablet-lg\:usa-icon-list--size-20 .usa-icon-list__icon .usa-icon {
    height: 13.305rem;
    width: 13.305rem;
  }
  .tablet-lg\:usa-icon-list--size-20 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 8.87rem;
    padding-left: 3.548rem;
  }
  .tablet-lg\:usa-icon-list--size-20 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 8.87rem;
  }
  .tablet-lg\:usa-icon-list--size-3xs .usa-icon-list__icon .usa-icon {
    height: 1.23rem;
    width: 1.23rem;
  }
  .tablet-lg\:usa-icon-list--size-3xs .usa-icon-list__content {
    max-width: 72ex;
    font-size: 0.82rem;
    padding-left: 0.328rem;
  }
  .tablet-lg\:usa-icon-list--size-3xs .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 0.82rem;
  }
  .tablet-lg\:usa-icon-list--size-2xs .usa-icon-list__icon .usa-icon {
    height: 1.335rem;
    width: 1.335rem;
  }
  .tablet-lg\:usa-icon-list--size-2xs .usa-icon-list__content {
    max-width: 72ex;
    font-size: 0.89rem;
    padding-left: 0.356rem;
  }
  .tablet-lg\:usa-icon-list--size-2xs .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 0.89rem;
  }
  .tablet-lg\:usa-icon-list--size-xs .usa-icon-list__icon .usa-icon {
    height: 1.425rem;
    width: 1.425rem;
  }
  .tablet-lg\:usa-icon-list--size-xs .usa-icon-list__content {
    max-width: 72ex;
    font-size: 0.95rem;
    padding-left: 0.38rem;
  }
  .tablet-lg\:usa-icon-list--size-xs .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 0.95rem;
  }
  .tablet-lg\:usa-icon-list--size-sm .usa-icon-list__icon .usa-icon {
    height: 1.515rem;
    width: 1.515rem;
  }
  .tablet-lg\:usa-icon-list--size-sm .usa-icon-list__content {
    max-width: 72ex;
    font-size: 1.01rem;
    padding-left: 0.404rem;
  }
  .tablet-lg\:usa-icon-list--size-sm .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 1.01rem;
  }
  .tablet-lg\:usa-icon-list--size-md .usa-icon-list__icon .usa-icon {
    height: 1.62rem;
    width: 1.62rem;
  }
  .tablet-lg\:usa-icon-list--size-md .usa-icon-list__content {
    max-width: 72ex;
    font-size: 1.08rem;
    padding-left: 0.432rem;
  }
  .tablet-lg\:usa-icon-list--size-md .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 1.08rem;
  }
  .tablet-lg\:usa-icon-list--size-lg .usa-icon-list__icon .usa-icon {
    height: 2.085rem;
    width: 2.085rem;
  }
  .tablet-lg\:usa-icon-list--size-lg .usa-icon-list__content {
    max-width: 72ex;
    font-size: 1.39rem;
    padding-left: 0.556rem;
  }
  .tablet-lg\:usa-icon-list--size-lg .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 1.39rem;
  }
  .tablet-lg\:usa-icon-list--size-xl .usa-icon-list__icon .usa-icon {
    height: 3.045rem;
    width: 3.045rem;
  }
  .tablet-lg\:usa-icon-list--size-xl .usa-icon-list__content {
    max-width: 72ex;
    font-size: 2.03rem;
    padding-left: 0.812rem;
  }
  .tablet-lg\:usa-icon-list--size-xl .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 2.03rem;
  }
  .tablet-lg\:usa-icon-list--size-2xl .usa-icon-list__icon .usa-icon {
    height: 3.81rem;
    width: 3.81rem;
  }
  .tablet-lg\:usa-icon-list--size-2xl .usa-icon-list__content {
    max-width: 72ex;
    font-size: 2.54rem;
    padding-left: 1.016rem;
  }
  .tablet-lg\:usa-icon-list--size-2xl .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 2.54rem;
  }
  .tablet-lg\:usa-icon-list--size-3xl .usa-icon-list__icon .usa-icon {
    height: 4.56rem;
    width: 4.56rem;
  }
  .tablet-lg\:usa-icon-list--size-3xl .usa-icon-list__content {
    max-width: 72ex;
    font-size: 3.04rem;
    padding-left: 1.216rem;
  }
  .tablet-lg\:usa-icon-list--size-3xl .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 3.04rem;
  }
}
@media all and (min-width: 64em) {
  .desktop\:usa-icon-list--size-micro .usa-icon-list__icon .usa-icon {
    height: 0.945rem;
    width: 0.945rem;
  }
  .desktop\:usa-icon-list--size-micro .usa-icon-list__content {
    max-width: 72ex;
    font-size: 0.63rem;
    padding-left: 0.252rem;
  }
  .desktop\:usa-icon-list--size-micro .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 0.63rem;
  }
  .desktop\:usa-icon-list--size-1 .usa-icon-list__icon .usa-icon {
    height: 1.14rem;
    width: 1.14rem;
  }
  .desktop\:usa-icon-list--size-1 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 0.76rem;
    padding-left: 0.304rem;
  }
  .desktop\:usa-icon-list--size-1 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 0.76rem;
  }
  .desktop\:usa-icon-list--size-2 .usa-icon-list__icon .usa-icon {
    height: 1.23rem;
    width: 1.23rem;
  }
  .desktop\:usa-icon-list--size-2 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 0.82rem;
    padding-left: 0.328rem;
  }
  .desktop\:usa-icon-list--size-2 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 0.82rem;
  }
  .desktop\:usa-icon-list--size-3 .usa-icon-list__icon .usa-icon {
    height: 1.335rem;
    width: 1.335rem;
  }
  .desktop\:usa-icon-list--size-3 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 0.89rem;
    padding-left: 0.356rem;
  }
  .desktop\:usa-icon-list--size-3 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 0.89rem;
  }
  .desktop\:usa-icon-list--size-4 .usa-icon-list__icon .usa-icon {
    height: 1.425rem;
    width: 1.425rem;
  }
  .desktop\:usa-icon-list--size-4 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 0.95rem;
    padding-left: 0.38rem;
  }
  .desktop\:usa-icon-list--size-4 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 0.95rem;
  }
  .desktop\:usa-icon-list--size-5 .usa-icon-list__icon .usa-icon {
    height: 1.515rem;
    width: 1.515rem;
  }
  .desktop\:usa-icon-list--size-5 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 1.01rem;
    padding-left: 0.404rem;
  }
  .desktop\:usa-icon-list--size-5 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 1.01rem;
  }
  .desktop\:usa-icon-list--size-6 .usa-icon-list__icon .usa-icon {
    height: 1.62rem;
    width: 1.62rem;
  }
  .desktop\:usa-icon-list--size-6 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 1.08rem;
    padding-left: 0.432rem;
  }
  .desktop\:usa-icon-list--size-6 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 1.08rem;
  }
  .desktop\:usa-icon-list--size-7 .usa-icon-list__icon .usa-icon {
    height: 1.71rem;
    width: 1.71rem;
  }
  .desktop\:usa-icon-list--size-7 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 1.14rem;
    padding-left: 0.456rem;
  }
  .desktop\:usa-icon-list--size-7 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 1.14rem;
  }
  .desktop\:usa-icon-list--size-8 .usa-icon-list__icon .usa-icon {
    height: 1.905rem;
    width: 1.905rem;
  }
  .desktop\:usa-icon-list--size-8 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 1.27rem;
    padding-left: 0.508rem;
  }
  .desktop\:usa-icon-list--size-8 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 1.27rem;
  }
  .desktop\:usa-icon-list--size-9 .usa-icon-list__icon .usa-icon {
    height: 2.085rem;
    width: 2.085rem;
  }
  .desktop\:usa-icon-list--size-9 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 1.39rem;
    padding-left: 0.556rem;
  }
  .desktop\:usa-icon-list--size-9 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 1.39rem;
  }
  .desktop\:usa-icon-list--size-10 .usa-icon-list__icon .usa-icon {
    height: 2.28rem;
    width: 2.28rem;
  }
  .desktop\:usa-icon-list--size-10 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 1.52rem;
    padding-left: 0.608rem;
  }
  .desktop\:usa-icon-list--size-10 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 1.52rem;
  }
  .desktop\:usa-icon-list--size-11 .usa-icon-list__icon .usa-icon {
    height: 2.655rem;
    width: 2.655rem;
  }
  .desktop\:usa-icon-list--size-11 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 1.77rem;
    padding-left: 0.708rem;
  }
  .desktop\:usa-icon-list--size-11 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 1.77rem;
  }
  .desktop\:usa-icon-list--size-12 .usa-icon-list__icon .usa-icon {
    height: 3.045rem;
    width: 3.045rem;
  }
  .desktop\:usa-icon-list--size-12 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 2.03rem;
    padding-left: 0.812rem;
  }
  .desktop\:usa-icon-list--size-12 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 2.03rem;
  }
  .desktop\:usa-icon-list--size-13 .usa-icon-list__icon .usa-icon {
    height: 3.42rem;
    width: 3.42rem;
  }
  .desktop\:usa-icon-list--size-13 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 2.28rem;
    padding-left: 0.912rem;
  }
  .desktop\:usa-icon-list--size-13 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 2.28rem;
  }
  .desktop\:usa-icon-list--size-14 .usa-icon-list__icon .usa-icon {
    height: 3.81rem;
    width: 3.81rem;
  }
  .desktop\:usa-icon-list--size-14 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 2.54rem;
    padding-left: 1.016rem;
  }
  .desktop\:usa-icon-list--size-14 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 2.54rem;
  }
  .desktop\:usa-icon-list--size-15 .usa-icon-list__icon .usa-icon {
    height: 4.56rem;
    width: 4.56rem;
  }
  .desktop\:usa-icon-list--size-15 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 3.04rem;
    padding-left: 1.216rem;
  }
  .desktop\:usa-icon-list--size-15 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 3.04rem;
  }
  .desktop\:usa-icon-list--size-16 .usa-icon-list__icon .usa-icon {
    height: 5.325rem;
    width: 5.325rem;
  }
  .desktop\:usa-icon-list--size-16 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 3.55rem;
    padding-left: 1.42rem;
  }
  .desktop\:usa-icon-list--size-16 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 3.55rem;
  }
  .desktop\:usa-icon-list--size-17 .usa-icon-list__icon .usa-icon {
    height: 6.09rem;
    width: 6.09rem;
  }
  .desktop\:usa-icon-list--size-17 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 4.06rem;
    padding-left: 1.624rem;
  }
  .desktop\:usa-icon-list--size-17 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 4.06rem;
  }
  .desktop\:usa-icon-list--size-18 .usa-icon-list__icon .usa-icon {
    height: 7.605rem;
    width: 7.605rem;
  }
  .desktop\:usa-icon-list--size-18 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 5.07rem;
    padding-left: 2.028rem;
  }
  .desktop\:usa-icon-list--size-18 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 5.07rem;
  }
  .desktop\:usa-icon-list--size-19 .usa-icon-list__icon .usa-icon {
    height: 11.415rem;
    width: 11.415rem;
  }
  .desktop\:usa-icon-list--size-19 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 7.61rem;
    padding-left: 3.044rem;
  }
  .desktop\:usa-icon-list--size-19 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 7.61rem;
  }
  .desktop\:usa-icon-list--size-20 .usa-icon-list__icon .usa-icon {
    height: 13.305rem;
    width: 13.305rem;
  }
  .desktop\:usa-icon-list--size-20 .usa-icon-list__content {
    max-width: 72ex;
    font-size: 8.87rem;
    padding-left: 3.548rem;
  }
  .desktop\:usa-icon-list--size-20 .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 8.87rem;
  }
  .desktop\:usa-icon-list--size-3xs .usa-icon-list__icon .usa-icon {
    height: 1.23rem;
    width: 1.23rem;
  }
  .desktop\:usa-icon-list--size-3xs .usa-icon-list__content {
    max-width: 72ex;
    font-size: 0.82rem;
    padding-left: 0.328rem;
  }
  .desktop\:usa-icon-list--size-3xs .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 0.82rem;
  }
  .desktop\:usa-icon-list--size-2xs .usa-icon-list__icon .usa-icon {
    height: 1.335rem;
    width: 1.335rem;
  }
  .desktop\:usa-icon-list--size-2xs .usa-icon-list__content {
    max-width: 72ex;
    font-size: 0.89rem;
    padding-left: 0.356rem;
  }
  .desktop\:usa-icon-list--size-2xs .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 0.89rem;
  }
  .desktop\:usa-icon-list--size-xs .usa-icon-list__icon .usa-icon {
    height: 1.425rem;
    width: 1.425rem;
  }
  .desktop\:usa-icon-list--size-xs .usa-icon-list__content {
    max-width: 72ex;
    font-size: 0.95rem;
    padding-left: 0.38rem;
  }
  .desktop\:usa-icon-list--size-xs .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 0.95rem;
  }
  .desktop\:usa-icon-list--size-sm .usa-icon-list__icon .usa-icon {
    height: 1.515rem;
    width: 1.515rem;
  }
  .desktop\:usa-icon-list--size-sm .usa-icon-list__content {
    max-width: 72ex;
    font-size: 1.01rem;
    padding-left: 0.404rem;
  }
  .desktop\:usa-icon-list--size-sm .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 1.01rem;
  }
  .desktop\:usa-icon-list--size-md .usa-icon-list__icon .usa-icon {
    height: 1.62rem;
    width: 1.62rem;
  }
  .desktop\:usa-icon-list--size-md .usa-icon-list__content {
    max-width: 72ex;
    font-size: 1.08rem;
    padding-left: 0.432rem;
  }
  .desktop\:usa-icon-list--size-md .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 1.08rem;
  }
  .desktop\:usa-icon-list--size-lg .usa-icon-list__icon .usa-icon {
    height: 2.085rem;
    width: 2.085rem;
  }
  .desktop\:usa-icon-list--size-lg .usa-icon-list__content {
    max-width: 72ex;
    font-size: 1.39rem;
    padding-left: 0.556rem;
  }
  .desktop\:usa-icon-list--size-lg .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 1.39rem;
  }
  .desktop\:usa-icon-list--size-xl .usa-icon-list__icon .usa-icon {
    height: 3.045rem;
    width: 3.045rem;
  }
  .desktop\:usa-icon-list--size-xl .usa-icon-list__content {
    max-width: 72ex;
    font-size: 2.03rem;
    padding-left: 0.812rem;
  }
  .desktop\:usa-icon-list--size-xl .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 2.03rem;
  }
  .desktop\:usa-icon-list--size-2xl .usa-icon-list__icon .usa-icon {
    height: 3.81rem;
    width: 3.81rem;
  }
  .desktop\:usa-icon-list--size-2xl .usa-icon-list__content {
    max-width: 72ex;
    font-size: 2.54rem;
    padding-left: 1.016rem;
  }
  .desktop\:usa-icon-list--size-2xl .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 2.54rem;
  }
  .desktop\:usa-icon-list--size-3xl .usa-icon-list__icon .usa-icon {
    height: 4.56rem;
    width: 4.56rem;
  }
  .desktop\:usa-icon-list--size-3xl .usa-icon-list__content {
    max-width: 72ex;
    font-size: 3.04rem;
    padding-left: 1.216rem;
  }
  .desktop\:usa-icon-list--size-3xl .usa-icon-list__content .usa-icon-list__title {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 3.04rem;
  }
}
.usa-icon-list__title {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.08rem;
  line-height: 1.2;
  margin-bottom: 0rem;
  padding-top: 0.425ex;
}
.usa-icon-list__title + * {
  margin-top: 0.5rem;
}
.usa-icon-list__content {
  font-size: 1.08rem;
  padding-left: 0.432rem;
}
.usa-icon-list__content > *:first-child {
  margin-top: 0;
}
.usa-icon-list__content > *:last-child {
  margin-bottom: 0;
}
.usa-icon-list__content ul li {
  list-style-type: disc;
}
.usa-identifier {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.01rem;
  line-height: 1.3;
  color: white;
  background-color: #1b1b1b;
  padding-bottom: 1rem;
}
.usa-identifier__container {
  margin-left: auto;
  margin-right: auto;
  max-width: 64rem;
  padding-left: 1rem;
  padding-right: 1rem;
  max-width: 64rem;
}
@media all and (min-width: 64em) {
  .usa-identifier__container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.usa-identifier__section {
  padding-bottom: 1rem;
  padding-top: 1rem;
}
@media all and (min-width: 40em) {
  .usa-identifier__section--masthead .usa-identifier__container {
    align-items: center;
    display: flex;
  }
}
.usa-identifier__logos {
  display: flex;
  margin-right: 1rem;
}
.usa-identifier__logo {
  text-decoration: none;
  height: 3rem;
  display: block;
}
.usa-identifier__logo + .usa-identifier__logo {
  margin-left: 0.5rem;
}
.usa-identifier__logo-img {
  height: 100%;
  width: auto;
}
.usa-identifier__identity {
  flex: 1 1 0%;
  margin-top: 1rem;
}
@media all and (min-width: 40em) {
  .usa-identifier__identity {
    margin-top: 0rem;
  }
}
.usa-identifier__identity-domain,
.usa-identifier__identity-disclaimer {
  margin: 0;
  padding: 0;
}
.usa-identifier__identity-domain {
  color: #a9aeb1;
}
.usa-identifier__identity-disclaimer {
  font-weight: 700;
}
.usa-identifier__identity-disclaimer a {
  color: #dfe1e2;
}
.usa-identifier__identity-disclaimer a:visited {
  color: #dfe1e2;
}
.usa-identifier__identity-disclaimer a:hover,
.usa-identifier__identity-disclaimer a:active {
  color: #f0f0f0;
}
@media all and (min-width: 40em) {
  .usa-identifier__section--required-links {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
  }
}
@media all and (min-width: 64em) {
  .usa-identifier__section--required-links {
    font-size: 0.95rem;
  }
}
.usa-identifier__required-links-list {
  margin-bottom: 0;
  margin-top: 0;
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 0;
}
@media all and (min-width: 40em) {
  .usa-identifier__required-links-list {
    column-count: 2;
    column-gap: 2rem;
    column-fill: balance;
  }
}
@media all and (min-width: 64em) {
  .usa-identifier__required-links-list {
    column-count: 4;
  }
}
.usa-identifier__required-links-item {
  break-inside: avoid;
  margin-bottom: 0.75rem;
}
.usa-identifier__required-links-item:last-child {
  margin-bottom: 0rem;
}
@media all and (min-width: 64em) {
  .usa-identifier__required-links-item {
    margin-bottom: 0.5rem;
  }
}
.usa-identifier__required-link,
.usa-identifier__required-link.usa-link {
  color: #a9aeb1;
  display: inline-block;
}
.usa-identifier__required-link:visited,
.usa-identifier__required-link.usa-link:visited {
  color: #a9aeb1;
}
.usa-identifier__required-link:hover,
.usa-identifier__required-link:active,
.usa-identifier__required-link.usa-link:hover,
.usa-identifier__required-link.usa-link:active {
  color: #dfe1e2;
}
.usa-identifier__section--usagov a {
  color: #dfe1e2;
  font-weight: 700;
  display: inline-block;
  margin-top: 0.5rem;
}
.usa-identifier__section--usagov a:visited {
  color: #dfe1e2;
}
.usa-identifier__section--usagov a:hover,
.usa-identifier__section--usagov a:active {
  color: #f0f0f0;
}
@media all and (min-width: 40em) {
  .usa-identifier__section--usagov a {
    margin-top: 0;
  }
}
@media all and (min-width: 40em) {
  .usa-identifier__usagov-description {
    display: inline-flex;
  }
}
.usa-in-page-nav-container {
  align-items: flex-start;
  display: flex;
}
.usa-in-page-nav-container main {
  max-width: 64rem;
  width: 100%;
}
.usa-in-page-nav {
  color: #11181d;
  display: none;
  margin-left: 2rem;
  margin-top: 1.25rem;
  max-width: 15rem;
  order: 2;
  position: -webkit-sticky;
  position: sticky;
  top: 2rem;
  width: 100%;
}
@media all and (min-width: 40em) {
  .usa-in-page-nav {
    display: block;
  }
}
.usa-in-page-nav:empty {
  display: none;
}
.usa-in-page-nav__nav {
  color: #11181d;
  background-color: white;
  border-radius: 0.5rem;
  padding: 1rem;
}
.usa-in-page-nav__heading {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 0.89rem;
  line-height: 1.3;
  font-weight: 700;
  margin-bottom: 1rem;
  margin-top: 0;
  padding: 0;
}
.usa-in-page-nav__list {
  margin-bottom: 0;
  margin-top: 0;
  list-style-type: none;
  padding-left: 0;
  border-left: 1px solid rgba(17, 24, 29, 0.25);
  padding: 0;
}
.usa-in-page-nav__list > li {
  margin-bottom: 0;
  max-width: unset;
}
.usa-in-page-nav__list__item {
  border-top: 1px solid #dfe1e2;
}
.usa-in-page-nav__list a:not(.usa-button) {
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
}
.usa-in-page-nav__list a:not(.usa-button):hover {
  background-color: #f0f0f0;
  text-decoration: none;
}
.usa-in-page-nav__list a:not(.usa-button):not(.usa-current) {
  color: #565c65;
}
.usa-in-page-nav__list a:not(.usa-button):not(.usa-current):hover {
  color: #046B99;
}
.usa-in-page-nav__list a:not(.usa-button):not(.usa-current):focus {
  outline-offset: 0;
}
.usa-in-page-nav__list .usa-current {
  position: relative;
  color: #046B99;
  font-weight: 700;
}
.usa-in-page-nav__list .usa-current::after {
  background-color: #046B99;
  border-radius: 99rem;
  content: "";
  display: block;
  position: absolute;
  bottom: 0.25rem;
  top: 0.25rem;
  width: 0.25rem;
  left: 0.25rem;
}
@media all and (min-width: 40em) {
  .usa-in-page-nav__list .usa-current {
    position: relative;
  }
  .usa-in-page-nav__list .usa-current::after {
    background-color: #046B99;
    border-radius: 99rem;
    content: "";
    display: block;
    position: absolute;
    bottom: 0.25rem;
    top: 0.25rem;
    width: 0.25rem;
    left: 0rem;
  }
}
.usa-in-page-nav__list a:not(.usa-button):not(.usa-current) {
  color: #046B99;
}
.usa-in-page-nav__list a:not(.usa-button):not(.usa-current):visited {
  color: #54278f;
}
.usa-in-page-nav__list a:not(.usa-button):not(.usa-current):hover,
.usa-in-page-nav__list a:not(.usa-button):not(.usa-current):active {
  color: #0b4778;
}
.usa-in-page-nav__list a:not(.usa-button):not(.usa-current):visited {
  color: #046B99;
}
.usa-in-page-nav__list a:not(.usa-button).usa-current {
  color: #11181d;
  font-weight: inherit;
}
.usa-in-page-nav__list a:not(.usa-button):hover {
  text-decoration: underline;
  background-color: none;
}
.usa-in-page-nav__list a:not(.usa-button).usa-current {
  position: static;
}
.usa-in-page-nav__list a:not(.usa-button).usa-current::after {
  background-color: #11181d;
  border-radius: 0;
  bottom: -1px;
  left: 0;
  top: 0;
  width: 0.25rem;
  z-index: 100;
}
.usa-in-page-nav__item {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 0.89rem;
  line-height: 1.2;
  border: none;
  position: relative;
}
.usa-in-page-nav__item--primary {
  font-weight: 700;
}
.usa-language {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  padding: 0 0 0.25rem 0.5rem;
  width: 100%;
}
.usa-language--small .usa-button {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-bottom: 0;
  padding-top: 0;
  font-size: 0.82rem;
  height: 2rem;
  margin-top: 0.3rem;
  min-width: 3rem;
}
.usa-language-container {
  align-items: center;
  justify-content: flex-end;
  display: flex;
}
.usa-nav-container .usa-language-container {
  align-self: center;
  margin-left: 0rem;
  position: absolute;
  right: 5rem;
  top: 1rem;
}
@media all and (min-width: 64em) {
  .usa-nav-container .usa-language-container {
    align-self: flex-end;
    margin-left: 0.75rem;
    margin-bottom: 0.5rem;
    position: relative;
    right: inherit;
    top: -2px;
  }
}
.usa-language__link {
  margin-right: 0rem;
}
.usa-language__primary {
  width: auto;
}
.usa-language__primary-item:last-of-type {
  position: relative;
}
.usa-language__primary-item:last-of-type .usa-language__submenu {
  position: absolute;
  right: 0;
}
.usa-language__submenu {
  margin-bottom: 0;
  margin-top: 0;
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 0;
  list-style-type: none;
  padding-left: 0;
  margin: 0;
  background-color: #1C304A;
  position: absolute;
  width: 15rem;
  z-index: 400;
}
.usa-language__submenu > li {
  margin-bottom: 0;
  max-width: unset;
}
.usa-language__submenu-item {
  border-top: 1px solid #dfe1e2;
  font-size: 0.89rem;
}
.usa-language__submenu .usa-current::after {
  display: none;
}
@media all and (min-width: 40em) {
  .usa-language__submenu .usa-current::after {
    display: none;
  }
}
.usa-language__submenu a:not(.usa-button) {
  padding-left: 2rem;
}
.usa-language__submenu .usa-language__submenu a:not(.usa-button) {
  padding-left: 3rem;
}
.usa-language__submenu .usa-language__submenu .usa-language__submenu a:not(.usa-button) {
  padding-left: 4rem;
}
.usa-language__submenu[aria-hidden=true] {
  display: none;
}
.usa-language__submenu .usa-language__submenu-item a {
  color: white;
  display: block;
  line-height: 1.3;
  padding: 0;
  padding: 0.5rem;
  text-decoration: none;
}
.usa-language__submenu .usa-language__submenu-item a:focus {
  outline-offset: -0.25rem;
}
.usa-language__submenu .usa-language__submenu-item a:hover {
  color: white;
  text-decoration: underline;
}
.usa-language__submenu-list {
  margin-bottom: 0;
  margin-top: 0;
  list-style-type: none;
  padding-left: 0;
}
.usa-language__submenu-list > li {
  margin-bottom: 0;
  max-width: unset;
}
.usa-language__submenu-list .usa-language__submenu-list-item {
  margin: 0;
  font-size: 0.89rem;
}
.usa-language__submenu-list .usa-language__submenu-list-item a {
  line-height: 1.3;
}
.usa-layout-docs__sidenav {
  padding-top: 2rem;
}
@media all and (min-width: 64em) {
  .usa-layout-docs__sidenav {
    padding-top: 0;
  }
}
.grid-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 64rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .grid-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.grid-container-card {
  margin-left: auto;
  margin-right: auto;
  max-width: 10rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .grid-container-card {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.grid-container-card-lg {
  margin-left: auto;
  margin-right: auto;
  max-width: 15rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .grid-container-card-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.grid-container-mobile {
  margin-left: auto;
  margin-right: auto;
  max-width: 20rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .grid-container-mobile {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.grid-container-mobile-lg {
  margin-left: auto;
  margin-right: auto;
  max-width: 30rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .grid-container-mobile-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.grid-container-tablet {
  margin-left: auto;
  margin-right: auto;
  max-width: 40rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .grid-container-tablet {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.grid-container-tablet-lg {
  margin-left: auto;
  margin-right: auto;
  max-width: 55rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .grid-container-tablet-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.grid-container-desktop {
  margin-left: auto;
  margin-right: auto;
  max-width: 64rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .grid-container-desktop {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.grid-container-desktop-lg {
  margin-left: auto;
  margin-right: auto;
  max-width: 75rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .grid-container-desktop-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.grid-container-widescreen {
  margin-left: auto;
  margin-right: auto;
  max-width: 87.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .grid-container-widescreen {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 64rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 30em) and (min-width: 64em) {
  .mobile-lg\:grid-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-container-card {
    margin-left: auto;
    margin-right: auto;
    max-width: 10rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 30em) and (min-width: 64em) {
  .mobile-lg\:grid-container-card {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-container-card-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 15rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 30em) and (min-width: 64em) {
  .mobile-lg\:grid-container-card-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-container-mobile {
    margin-left: auto;
    margin-right: auto;
    max-width: 20rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 30em) and (min-width: 64em) {
  .mobile-lg\:grid-container-mobile {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-container-mobile-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 30rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 30em) and (min-width: 64em) {
  .mobile-lg\:grid-container-mobile-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-container-tablet {
    margin-left: auto;
    margin-right: auto;
    max-width: 40rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 30em) and (min-width: 64em) {
  .mobile-lg\:grid-container-tablet {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-container-tablet-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 55rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 30em) and (min-width: 64em) {
  .mobile-lg\:grid-container-tablet-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-container-desktop {
    margin-left: auto;
    margin-right: auto;
    max-width: 64rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 30em) and (min-width: 64em) {
  .mobile-lg\:grid-container-desktop {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-container-desktop-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 75rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 30em) and (min-width: 64em) {
  .mobile-lg\:grid-container-desktop-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-container-widescreen {
    margin-left: auto;
    margin-right: auto;
    max-width: 87.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 30em) and (min-width: 64em) {
  .mobile-lg\:grid-container-widescreen {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 64rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 40em) and (min-width: 64em) {
  .tablet\:grid-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-container-card {
    margin-left: auto;
    margin-right: auto;
    max-width: 10rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 40em) and (min-width: 64em) {
  .tablet\:grid-container-card {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-container-card-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 15rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 40em) and (min-width: 64em) {
  .tablet\:grid-container-card-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-container-mobile {
    margin-left: auto;
    margin-right: auto;
    max-width: 20rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 40em) and (min-width: 64em) {
  .tablet\:grid-container-mobile {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-container-mobile-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 30rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 40em) and (min-width: 64em) {
  .tablet\:grid-container-mobile-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-container-tablet {
    margin-left: auto;
    margin-right: auto;
    max-width: 40rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 40em) and (min-width: 64em) {
  .tablet\:grid-container-tablet {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-container-tablet-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 55rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 40em) and (min-width: 64em) {
  .tablet\:grid-container-tablet-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-container-desktop {
    margin-left: auto;
    margin-right: auto;
    max-width: 64rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 40em) and (min-width: 64em) {
  .tablet\:grid-container-desktop {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-container-desktop-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 75rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 40em) and (min-width: 64em) {
  .tablet\:grid-container-desktop-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-container-widescreen {
    margin-left: auto;
    margin-right: auto;
    max-width: 87.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 40em) and (min-width: 64em) {
  .tablet\:grid-container-widescreen {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 55em) {
  .tablet-lg\:grid-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 64rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 55em) and (min-width: 64em) {
  .tablet-lg\:grid-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 55em) {
  .tablet-lg\:grid-container-card {
    margin-left: auto;
    margin-right: auto;
    max-width: 10rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 55em) and (min-width: 64em) {
  .tablet-lg\:grid-container-card {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 55em) {
  .tablet-lg\:grid-container-card-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 15rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 55em) and (min-width: 64em) {
  .tablet-lg\:grid-container-card-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 55em) {
  .tablet-lg\:grid-container-mobile {
    margin-left: auto;
    margin-right: auto;
    max-width: 20rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 55em) and (min-width: 64em) {
  .tablet-lg\:grid-container-mobile {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 55em) {
  .tablet-lg\:grid-container-mobile-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 30rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 55em) and (min-width: 64em) {
  .tablet-lg\:grid-container-mobile-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 55em) {
  .tablet-lg\:grid-container-tablet {
    margin-left: auto;
    margin-right: auto;
    max-width: 40rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 55em) and (min-width: 64em) {
  .tablet-lg\:grid-container-tablet {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 55em) {
  .tablet-lg\:grid-container-tablet-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 55rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 55em) and (min-width: 64em) {
  .tablet-lg\:grid-container-tablet-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 55em) {
  .tablet-lg\:grid-container-desktop {
    margin-left: auto;
    margin-right: auto;
    max-width: 64rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 55em) and (min-width: 64em) {
  .tablet-lg\:grid-container-desktop {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 55em) {
  .tablet-lg\:grid-container-desktop-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 75rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 55em) and (min-width: 64em) {
  .tablet-lg\:grid-container-desktop-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 55em) {
  .tablet-lg\:grid-container-widescreen {
    margin-left: auto;
    margin-right: auto;
    max-width: 87.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 55em) and (min-width: 64em) {
  .tablet-lg\:grid-container-widescreen {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 64rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .desktop\:grid-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-container-card {
    margin-left: auto;
    margin-right: auto;
    max-width: 10rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .desktop\:grid-container-card {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-container-card-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 15rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .desktop\:grid-container-card-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-container-mobile {
    margin-left: auto;
    margin-right: auto;
    max-width: 20rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .desktop\:grid-container-mobile {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-container-mobile-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 30rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .desktop\:grid-container-mobile-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-container-tablet {
    margin-left: auto;
    margin-right: auto;
    max-width: 40rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .desktop\:grid-container-tablet {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-container-tablet-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 55rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .desktop\:grid-container-tablet-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-container-desktop {
    margin-left: auto;
    margin-right: auto;
    max-width: 64rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .desktop\:grid-container-desktop {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-container-desktop-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 75rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .desktop\:grid-container-desktop-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-container-widescreen {
    margin-left: auto;
    margin-right: auto;
    max-width: 87.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .desktop\:grid-container-widescreen {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.grid-row {
  display: flex;
  flex-wrap: wrap;
}
.grid-row.grid-gap {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.grid-row.grid-gap > * {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
@media all and (min-width: 64em) {
  .grid-row.grid-gap {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .grid-row.grid-gap > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.grid-row.grid-gap-0 {
  margin-left: 0;
  margin-right: 0;
}
.grid-row.grid-gap-0 > * {
  padding-left: 0;
  padding-right: 0;
}
.grid-row.grid-gap-2px {
  margin-left: -1px;
  margin-right: -1px;
}
.grid-row.grid-gap-2px > * {
  padding-left: 1px;
  padding-right: 1px;
}
.grid-row.grid-gap-05 {
  margin-left: -2px;
  margin-right: -2px;
}
.grid-row.grid-gap-05 > * {
  padding-left: 2px;
  padding-right: 2px;
}
.grid-row.grid-gap-1 {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}
.grid-row.grid-gap-1 > * {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.grid-row.grid-gap-2 {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.grid-row.grid-gap-2 > * {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.grid-row.grid-gap-3 {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}
.grid-row.grid-gap-3 > * {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.grid-row.grid-gap-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}
.grid-row.grid-gap-4 > * {
  padding-left: 1rem;
  padding-right: 1rem;
}
.grid-row.grid-gap-5 {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}
.grid-row.grid-gap-5 > * {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.grid-row.grid-gap-6 {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}
.grid-row.grid-gap-6 > * {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.grid-row.grid-gap-sm {
  margin-left: -1px;
  margin-right: -1px;
}
.grid-row.grid-gap-sm > * {
  padding-left: 1px;
  padding-right: 1px;
}
.grid-row.grid-gap-md {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.grid-row.grid-gap-md > * {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.grid-row.grid-gap-lg {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}
.grid-row.grid-gap-lg > * {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
@media all and (min-width: 30em) {
  .grid-row.mobile-lg\:grid-gap-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .grid-row.mobile-lg\:grid-gap-0 > * {
    padding-left: 0;
    padding-right: 0;
  }
  .grid-row.mobile-lg\:grid-gap-2px {
    margin-left: -1px;
    margin-right: -1px;
  }
  .grid-row.mobile-lg\:grid-gap-2px > * {
    padding-left: 1px;
    padding-right: 1px;
  }
  .grid-row.mobile-lg\:grid-gap-05 {
    margin-left: -2px;
    margin-right: -2px;
  }
  .grid-row.mobile-lg\:grid-gap-05 > * {
    padding-left: 2px;
    padding-right: 2px;
  }
  .grid-row.mobile-lg\:grid-gap-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .grid-row.mobile-lg\:grid-gap-1 > * {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .grid-row.mobile-lg\:grid-gap-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .grid-row.mobile-lg\:grid-gap-2 > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .grid-row.mobile-lg\:grid-gap-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .grid-row.mobile-lg\:grid-gap-3 > * {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .grid-row.mobile-lg\:grid-gap-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .grid-row.mobile-lg\:grid-gap-4 > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .grid-row.mobile-lg\:grid-gap-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
  .grid-row.mobile-lg\:grid-gap-5 > * {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .grid-row.mobile-lg\:grid-gap-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .grid-row.mobile-lg\:grid-gap-6 > * {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .grid-row.mobile-lg\:grid-gap-sm {
    margin-left: -1px;
    margin-right: -1px;
  }
  .grid-row.mobile-lg\:grid-gap-sm > * {
    padding-left: 1px;
    padding-right: 1px;
  }
  .grid-row.mobile-lg\:grid-gap-md {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .grid-row.mobile-lg\:grid-gap-md > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .grid-row.mobile-lg\:grid-gap-lg {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .grid-row.mobile-lg\:grid-gap-lg > * {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}
@media all and (min-width: 40em) {
  .grid-row.tablet\:grid-gap-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .grid-row.tablet\:grid-gap-0 > * {
    padding-left: 0;
    padding-right: 0;
  }
  .grid-row.tablet\:grid-gap-2px {
    margin-left: -1px;
    margin-right: -1px;
  }
  .grid-row.tablet\:grid-gap-2px > * {
    padding-left: 1px;
    padding-right: 1px;
  }
  .grid-row.tablet\:grid-gap-05 {
    margin-left: -2px;
    margin-right: -2px;
  }
  .grid-row.tablet\:grid-gap-05 > * {
    padding-left: 2px;
    padding-right: 2px;
  }
  .grid-row.tablet\:grid-gap-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .grid-row.tablet\:grid-gap-1 > * {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .grid-row.tablet\:grid-gap-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .grid-row.tablet\:grid-gap-2 > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .grid-row.tablet\:grid-gap-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .grid-row.tablet\:grid-gap-3 > * {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .grid-row.tablet\:grid-gap-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .grid-row.tablet\:grid-gap-4 > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .grid-row.tablet\:grid-gap-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
  .grid-row.tablet\:grid-gap-5 > * {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .grid-row.tablet\:grid-gap-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .grid-row.tablet\:grid-gap-6 > * {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .grid-row.tablet\:grid-gap-sm {
    margin-left: -1px;
    margin-right: -1px;
  }
  .grid-row.tablet\:grid-gap-sm > * {
    padding-left: 1px;
    padding-right: 1px;
  }
  .grid-row.tablet\:grid-gap-md {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .grid-row.tablet\:grid-gap-md > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .grid-row.tablet\:grid-gap-lg {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .grid-row.tablet\:grid-gap-lg > * {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}
@media all and (min-width: 55em) {
  .grid-row.tablet-lg\:grid-gap-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .grid-row.tablet-lg\:grid-gap-0 > * {
    padding-left: 0;
    padding-right: 0;
  }
  .grid-row.tablet-lg\:grid-gap-2px {
    margin-left: -1px;
    margin-right: -1px;
  }
  .grid-row.tablet-lg\:grid-gap-2px > * {
    padding-left: 1px;
    padding-right: 1px;
  }
  .grid-row.tablet-lg\:grid-gap-05 {
    margin-left: -2px;
    margin-right: -2px;
  }
  .grid-row.tablet-lg\:grid-gap-05 > * {
    padding-left: 2px;
    padding-right: 2px;
  }
  .grid-row.tablet-lg\:grid-gap-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .grid-row.tablet-lg\:grid-gap-1 > * {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .grid-row.tablet-lg\:grid-gap-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .grid-row.tablet-lg\:grid-gap-2 > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .grid-row.tablet-lg\:grid-gap-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .grid-row.tablet-lg\:grid-gap-3 > * {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .grid-row.tablet-lg\:grid-gap-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .grid-row.tablet-lg\:grid-gap-4 > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .grid-row.tablet-lg\:grid-gap-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
  .grid-row.tablet-lg\:grid-gap-5 > * {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .grid-row.tablet-lg\:grid-gap-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .grid-row.tablet-lg\:grid-gap-6 > * {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .grid-row.tablet-lg\:grid-gap-sm {
    margin-left: -1px;
    margin-right: -1px;
  }
  .grid-row.tablet-lg\:grid-gap-sm > * {
    padding-left: 1px;
    padding-right: 1px;
  }
  .grid-row.tablet-lg\:grid-gap-md {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .grid-row.tablet-lg\:grid-gap-md > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .grid-row.tablet-lg\:grid-gap-lg {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .grid-row.tablet-lg\:grid-gap-lg > * {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}
@media all and (min-width: 64em) {
  .grid-row.desktop\:grid-gap-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .grid-row.desktop\:grid-gap-0 > * {
    padding-left: 0;
    padding-right: 0;
  }
  .grid-row.desktop\:grid-gap-2px {
    margin-left: -1px;
    margin-right: -1px;
  }
  .grid-row.desktop\:grid-gap-2px > * {
    padding-left: 1px;
    padding-right: 1px;
  }
  .grid-row.desktop\:grid-gap-05 {
    margin-left: -2px;
    margin-right: -2px;
  }
  .grid-row.desktop\:grid-gap-05 > * {
    padding-left: 2px;
    padding-right: 2px;
  }
  .grid-row.desktop\:grid-gap-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .grid-row.desktop\:grid-gap-1 > * {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .grid-row.desktop\:grid-gap-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .grid-row.desktop\:grid-gap-2 > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .grid-row.desktop\:grid-gap-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .grid-row.desktop\:grid-gap-3 > * {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .grid-row.desktop\:grid-gap-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .grid-row.desktop\:grid-gap-4 > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .grid-row.desktop\:grid-gap-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
  .grid-row.desktop\:grid-gap-5 > * {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .grid-row.desktop\:grid-gap-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .grid-row.desktop\:grid-gap-6 > * {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .grid-row.desktop\:grid-gap-sm {
    margin-left: -1px;
    margin-right: -1px;
  }
  .grid-row.desktop\:grid-gap-sm > * {
    padding-left: 1px;
    padding-right: 1px;
  }
  .grid-row.desktop\:grid-gap-md {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .grid-row.desktop\:grid-gap-md > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .grid-row.desktop\:grid-gap-lg {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .grid-row.desktop\:grid-gap-lg > * {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}
[class*=grid-col] {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.grid-col {
  flex: 1 1 0%;
  width: auto;
  max-width: 100%;
  min-width: 1px;
}
.grid-col-auto {
  flex: 0 1 auto;
  width: auto;
  max-width: 100%;
}
.grid-col-fill {
  flex: 1 1 0%;
  width: auto;
  max-width: 100%;
  min-width: 1px;
}
.grid-col-1 {
  flex: 0 1 auto;
  width: 8.3333333333%;
}
.grid-col-2 {
  flex: 0 1 auto;
  width: 16.6666666667%;
}
.grid-col-3 {
  flex: 0 1 auto;
  width: 25%;
}
.grid-col-4 {
  flex: 0 1 auto;
  width: 33.3333333333%;
}
.grid-col-5 {
  flex: 0 1 auto;
  width: 41.6666666667%;
}
.grid-col-6 {
  flex: 0 1 auto;
  width: 50%;
}
.grid-col-7 {
  flex: 0 1 auto;
  width: 58.3333333333%;
}
.grid-col-8 {
  flex: 0 1 auto;
  width: 66.6666666667%;
}
.grid-col-9 {
  flex: 0 1 auto;
  width: 75%;
}
.grid-col-10 {
  flex: 0 1 auto;
  width: 83.3333333333%;
}
.grid-col-11 {
  flex: 0 1 auto;
  width: 91.6666666667%;
}
.grid-col-12 {
  flex: 0 1 auto;
  width: 100%;
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-col {
    flex: 1 1 0%;
    width: auto;
    max-width: 100%;
    min-width: 1px;
  }
  .mobile-lg\:grid-col-fill {
    flex: 1 1 0%;
    width: auto;
    max-width: 100%;
    min-width: 1px;
  }
  .mobile-lg\:grid-col-auto {
    flex: 0 1 auto;
    width: auto;
    max-width: 100%;
  }
  .mobile-lg\:grid-col-1 {
    flex: 0 1 auto;
    width: 8.3333333333%;
  }
  .mobile-lg\:grid-col-2 {
    flex: 0 1 auto;
    width: 16.6666666667%;
  }
  .mobile-lg\:grid-col-3 {
    flex: 0 1 auto;
    width: 25%;
  }
  .mobile-lg\:grid-col-4 {
    flex: 0 1 auto;
    width: 33.3333333333%;
  }
  .mobile-lg\:grid-col-5 {
    flex: 0 1 auto;
    width: 41.6666666667%;
  }
  .mobile-lg\:grid-col-6 {
    flex: 0 1 auto;
    width: 50%;
  }
  .mobile-lg\:grid-col-7 {
    flex: 0 1 auto;
    width: 58.3333333333%;
  }
  .mobile-lg\:grid-col-8 {
    flex: 0 1 auto;
    width: 66.6666666667%;
  }
  .mobile-lg\:grid-col-9 {
    flex: 0 1 auto;
    width: 75%;
  }
  .mobile-lg\:grid-col-10 {
    flex: 0 1 auto;
    width: 83.3333333333%;
  }
  .mobile-lg\:grid-col-11 {
    flex: 0 1 auto;
    width: 91.6666666667%;
  }
  .mobile-lg\:grid-col-12 {
    flex: 0 1 auto;
    width: 100%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-col {
    flex: 1 1 0%;
    width: auto;
    max-width: 100%;
    min-width: 1px;
  }
  .tablet\:grid-col-fill {
    flex: 1 1 0%;
    width: auto;
    max-width: 100%;
    min-width: 1px;
  }
  .tablet\:grid-col-auto {
    flex: 0 1 auto;
    width: auto;
    max-width: 100%;
  }
  .tablet\:grid-col-1 {
    flex: 0 1 auto;
    width: 8.3333333333%;
  }
  .tablet\:grid-col-2 {
    flex: 0 1 auto;
    width: 16.6666666667%;
  }
  .tablet\:grid-col-3 {
    flex: 0 1 auto;
    width: 25%;
  }
  .tablet\:grid-col-4 {
    flex: 0 1 auto;
    width: 33.3333333333%;
  }
  .tablet\:grid-col-5 {
    flex: 0 1 auto;
    width: 41.6666666667%;
  }
  .tablet\:grid-col-6 {
    flex: 0 1 auto;
    width: 50%;
  }
  .tablet\:grid-col-7 {
    flex: 0 1 auto;
    width: 58.3333333333%;
  }
  .tablet\:grid-col-8 {
    flex: 0 1 auto;
    width: 66.6666666667%;
  }
  .tablet\:grid-col-9 {
    flex: 0 1 auto;
    width: 75%;
  }
  .tablet\:grid-col-10 {
    flex: 0 1 auto;
    width: 83.3333333333%;
  }
  .tablet\:grid-col-11 {
    flex: 0 1 auto;
    width: 91.6666666667%;
  }
  .tablet\:grid-col-12 {
    flex: 0 1 auto;
    width: 100%;
  }
}
@media all and (min-width: 55em) {
  .tablet-lg\:grid-col {
    flex: 1 1 0%;
    width: auto;
    max-width: 100%;
    min-width: 1px;
  }
  .tablet-lg\:grid-col-fill {
    flex: 1 1 0%;
    width: auto;
    max-width: 100%;
    min-width: 1px;
  }
  .tablet-lg\:grid-col-auto {
    flex: 0 1 auto;
    width: auto;
    max-width: 100%;
  }
  .tablet-lg\:grid-col-1 {
    flex: 0 1 auto;
    width: 8.3333333333%;
  }
  .tablet-lg\:grid-col-2 {
    flex: 0 1 auto;
    width: 16.6666666667%;
  }
  .tablet-lg\:grid-col-3 {
    flex: 0 1 auto;
    width: 25%;
  }
  .tablet-lg\:grid-col-4 {
    flex: 0 1 auto;
    width: 33.3333333333%;
  }
  .tablet-lg\:grid-col-5 {
    flex: 0 1 auto;
    width: 41.6666666667%;
  }
  .tablet-lg\:grid-col-6 {
    flex: 0 1 auto;
    width: 50%;
  }
  .tablet-lg\:grid-col-7 {
    flex: 0 1 auto;
    width: 58.3333333333%;
  }
  .tablet-lg\:grid-col-8 {
    flex: 0 1 auto;
    width: 66.6666666667%;
  }
  .tablet-lg\:grid-col-9 {
    flex: 0 1 auto;
    width: 75%;
  }
  .tablet-lg\:grid-col-10 {
    flex: 0 1 auto;
    width: 83.3333333333%;
  }
  .tablet-lg\:grid-col-11 {
    flex: 0 1 auto;
    width: 91.6666666667%;
  }
  .tablet-lg\:grid-col-12 {
    flex: 0 1 auto;
    width: 100%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-col {
    flex: 1 1 0%;
    width: auto;
    max-width: 100%;
    min-width: 1px;
  }
  .desktop\:grid-col-fill {
    flex: 1 1 0%;
    width: auto;
    max-width: 100%;
    min-width: 1px;
  }
  .desktop\:grid-col-auto {
    flex: 0 1 auto;
    width: auto;
    max-width: 100%;
  }
  .desktop\:grid-col-1 {
    flex: 0 1 auto;
    width: 8.3333333333%;
  }
  .desktop\:grid-col-2 {
    flex: 0 1 auto;
    width: 16.6666666667%;
  }
  .desktop\:grid-col-3 {
    flex: 0 1 auto;
    width: 25%;
  }
  .desktop\:grid-col-4 {
    flex: 0 1 auto;
    width: 33.3333333333%;
  }
  .desktop\:grid-col-5 {
    flex: 0 1 auto;
    width: 41.6666666667%;
  }
  .desktop\:grid-col-6 {
    flex: 0 1 auto;
    width: 50%;
  }
  .desktop\:grid-col-7 {
    flex: 0 1 auto;
    width: 58.3333333333%;
  }
  .desktop\:grid-col-8 {
    flex: 0 1 auto;
    width: 66.6666666667%;
  }
  .desktop\:grid-col-9 {
    flex: 0 1 auto;
    width: 75%;
  }
  .desktop\:grid-col-10 {
    flex: 0 1 auto;
    width: 83.3333333333%;
  }
  .desktop\:grid-col-11 {
    flex: 0 1 auto;
    width: 91.6666666667%;
  }
  .desktop\:grid-col-12 {
    flex: 0 1 auto;
    width: 100%;
  }
}
.grid-offset-1 {
  margin-left: 8.3333333333%;
}
.grid-offset-2 {
  margin-left: 16.6666666667%;
}
.grid-offset-3 {
  margin-left: 25%;
}
.grid-offset-4 {
  margin-left: 33.3333333333%;
}
.grid-offset-5 {
  margin-left: 41.6666666667%;
}
.grid-offset-6 {
  margin-left: 50%;
}
.grid-offset-7 {
  margin-left: 58.3333333333%;
}
.grid-offset-8 {
  margin-left: 66.6666666667%;
}
.grid-offset-9 {
  margin-left: 75%;
}
.grid-offset-10 {
  margin-left: 83.3333333333%;
}
.grid-offset-11 {
  margin-left: 91.6666666667%;
}
.grid-offset-12 {
  margin-left: 100%;
}
.grid-offset-none {
  margin-left: 0;
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-offset-1 {
    margin-left: 8.3333333333%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-offset-2 {
    margin-left: 16.6666666667%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-offset-3 {
    margin-left: 25%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-offset-4 {
    margin-left: 33.3333333333%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-offset-5 {
    margin-left: 41.6666666667%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-offset-6 {
    margin-left: 50%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-offset-7 {
    margin-left: 58.3333333333%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-offset-8 {
    margin-left: 66.6666666667%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-offset-9 {
    margin-left: 75%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-offset-10 {
    margin-left: 83.3333333333%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-offset-11 {
    margin-left: 91.6666666667%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-offset-12 {
    margin-left: 100%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-offset-none {
    margin-left: 0;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-offset-1 {
    margin-left: 8.3333333333%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-offset-2 {
    margin-left: 16.6666666667%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-offset-3 {
    margin-left: 25%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-offset-4 {
    margin-left: 33.3333333333%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-offset-5 {
    margin-left: 41.6666666667%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-offset-6 {
    margin-left: 50%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-offset-7 {
    margin-left: 58.3333333333%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-offset-8 {
    margin-left: 66.6666666667%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-offset-9 {
    margin-left: 75%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-offset-10 {
    margin-left: 83.3333333333%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-offset-11 {
    margin-left: 91.6666666667%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-offset-12 {
    margin-left: 100%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-offset-none {
    margin-left: 0;
  }
}
@media all and (min-width: 55em) {
  .tablet-lg\:grid-offset-1 {
    margin-left: 8.3333333333%;
  }
}
@media all and (min-width: 55em) {
  .tablet-lg\:grid-offset-2 {
    margin-left: 16.6666666667%;
  }
}
@media all and (min-width: 55em) {
  .tablet-lg\:grid-offset-3 {
    margin-left: 25%;
  }
}
@media all and (min-width: 55em) {
  .tablet-lg\:grid-offset-4 {
    margin-left: 33.3333333333%;
  }
}
@media all and (min-width: 55em) {
  .tablet-lg\:grid-offset-5 {
    margin-left: 41.6666666667%;
  }
}
@media all and (min-width: 55em) {
  .tablet-lg\:grid-offset-6 {
    margin-left: 50%;
  }
}
@media all and (min-width: 55em) {
  .tablet-lg\:grid-offset-7 {
    margin-left: 58.3333333333%;
  }
}
@media all and (min-width: 55em) {
  .tablet-lg\:grid-offset-8 {
    margin-left: 66.6666666667%;
  }
}
@media all and (min-width: 55em) {
  .tablet-lg\:grid-offset-9 {
    margin-left: 75%;
  }
}
@media all and (min-width: 55em) {
  .tablet-lg\:grid-offset-10 {
    margin-left: 83.3333333333%;
  }
}
@media all and (min-width: 55em) {
  .tablet-lg\:grid-offset-11 {
    margin-left: 91.6666666667%;
  }
}
@media all and (min-width: 55em) {
  .tablet-lg\:grid-offset-12 {
    margin-left: 100%;
  }
}
@media all and (min-width: 55em) {
  .tablet-lg\:grid-offset-none {
    margin-left: 0;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-offset-1 {
    margin-left: 8.3333333333%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-offset-2 {
    margin-left: 16.6666666667%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-offset-3 {
    margin-left: 25%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-offset-4 {
    margin-left: 33.3333333333%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-offset-5 {
    margin-left: 41.6666666667%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-offset-6 {
    margin-left: 50%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-offset-7 {
    margin-left: 58.3333333333%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-offset-8 {
    margin-left: 66.6666666667%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-offset-9 {
    margin-left: 75%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-offset-10 {
    margin-left: 83.3333333333%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-offset-11 {
    margin-left: 91.6666666667%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-offset-12 {
    margin-left: 100%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-offset-none {
    margin-left: 0;
  }
}
.usa-media-block {
  align-items: flex-start;
  display: flex;
}
.usa-media-block__img {
  flex-shrink: 0;
  margin-right: 0.5rem;
}
.usa-media-block__body {
  flex: 1 1 0%;
}
.usa-js-no-click {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
}
.usa-js-no-click .usa-modal,
.usa-js-no-click .usa-modal * {
  pointer-events: auto;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
[data-open-modal] * {
  pointer-events: none;
}
.usa-modal-wrapper {
  text-align: center;
  transition: opacity 0.15s ease-in-out;
}
.usa-modal-wrapper.is-hidden {
  visibility: hidden;
  opacity: 0;
  position: fixed;
}
.usa-modal-wrapper.is-visible {
  visibility: visible;
  opacity: 1;
  position: fixed;
  z-index: 99999;
}
.usa-modal-overlay {
  background: rgba(0, 0, 0, 0.7);
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: scroll;
  overflow-x: hidden;
  padding: 1.5rem;
  position: fixed;
  scroll-behavior: smooth;
  top: 0;
  width: 100%;
}
.usa-modal-overlay:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.usa-modal-overlay[data-force-action=true] {
  pointer-events: none;
}
.usa-modal-overlay[data-force-action=true] * {
  pointer-events: auto;
}
.usa-js-loading .usa-modal-wrapper {
  position: absolute;
  left: -999em;
  right: auto;
}
.usa-js-loading .usa-modal-wrapper:target {
  position: static;
}
.usa-modal {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.08rem;
  line-height: 1.6;
  border-radius: 0.5rem;
  background: white;
  color: #11181d;
  display: inline-block;
  margin: 1.25rem auto;
  max-width: 30rem;
  position: relative;
  text-align: left;
  vertical-align: middle;
  width: 100%;
}
.usa-modal:focus {
  outline: none;
}
.usa-modal__content {
  display: flex;
  flex-direction: column-reverse;
  padding-top: 2rem;
  width: 100%;
}
.usa-modal__main {
  margin: 0 auto;
  padding: 0.5rem 2rem 2rem;
}
.usa-modal-wrapper [data-close-modal] > .usa-icon,
.usa-modal-wrapper [data-close-modal] > .usa-icon use {
  pointer-events: none !important;
}
.usa-modal__close {
  align-items: center;
  align-self: flex-end;
  background-color: transparent;
  color: #71767a;
  display: flex;
  flex-shrink: 0;
  font-size: 0.89rem;
  margin: -2rem 0 0 auto;
  padding: 0.25rem 0.25rem;
  width: auto;
}
.usa-modal__close:hover,
.usa-modal__close:active {
  background-color: transparent;
  color: #11181d;
}
.usa-modal__close:focus {
  outline-offset: 0;
}
.usa-modal__close .usa-icon {
  height: 2rem;
  margin: 2px 2px 0 0;
  width: 2rem;
}
.usa-modal__heading {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.39rem;
  line-height: 1.3;
  margin-top: 0;
}
.usa-modal__footer {
  margin-top: 1.5rem;
}
.usa-modal--lg {
  max-width: 55rem;
  width: 100%;
}
.usa-modal--lg .usa-modal__main {
  padding-bottom: 4rem;
  padding-top: 1.25rem;
  width: 100%;
  max-width: 40rem;
}
@media all and (min-width: 40em) {
  .usa-modal--lg .usa-modal__heading {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 2.03rem;
  }
}
.usa-js-modal--active {
  overflow: hidden;
}
@keyframes slidein-left {
  from {
    transform: translateX(15rem);
  }
  to {
    transform: translateX(0);
  }
}
.usa-nav {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.08rem;
  line-height: 1;
}
@media all and (max-width: 63.99em) {
  .usa-nav {
    position: absolute;
    right: 0;
    position: absolute;
    bottom: 0;
    top: 0;
    position: fixed;
    background: white;
    border-right: 0;
    display: none;
    flex-direction: column;
    overflow-y: auto;
    padding: 1rem;
    width: 15rem;
    z-index: 500;
  }
  .usa-nav.is-visible {
    animation: slidein-left 0.3s ease-in-out;
    display: flex;
  }
}
@media all and (min-width: 64em) {
  .usa-nav {
    float: right;
    position: relative;
  }
}
.usa-nav .usa-search {
  margin-top: 1rem;
}
@media all and (min-width: 64em) {
  .usa-nav .usa-search {
    margin-left: 1rem;
    margin-top: 0;
  }
}
.usa-nav .usa-accordion {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.08rem;
  line-height: 1;
}
@media all and (max-width: 63.99em) {
  .usa-nav__primary {
    margin-bottom: 0;
    margin-top: 0;
    list-style-type: none;
    padding-left: 0;
  }
  .usa-nav__primary > li {
    margin-bottom: 0;
    max-width: unset;
  }
  .usa-nav__primary-item {
    border-top: 1px solid #dfe1e2;
  }
  .usa-nav__primary a:not(.usa-button) {
    display: block;
    padding: 0.5rem 1rem;
    text-decoration: none;
  }
  .usa-nav__primary a:not(.usa-button):hover {
    background-color: #f0f0f0;
    text-decoration: none;
  }
  .usa-nav__primary a:not(.usa-button):not(.usa-current) {
    color: #565c65;
  }
  .usa-nav__primary a:not(.usa-button):not(.usa-current):hover {
    color: #046B99;
  }
  .usa-nav__primary a:not(.usa-button):not(.usa-current):focus {
    outline-offset: 0;
  }
  .usa-nav__primary .usa-current {
    position: relative;
    color: #046B99;
    font-weight: 700;
  }
  .usa-nav__primary .usa-current::after {
    background-color: #046B99;
    border-radius: 99rem;
    content: "";
    display: block;
    position: absolute;
    bottom: 0.25rem;
    top: 0.25rem;
    width: 0.25rem;
    left: 0.25rem;
  }
}
@media all and (max-width: 63.99em) and (min-width: 40em) {
  .usa-nav__primary .usa-current {
    position: relative;
  }
  .usa-nav__primary .usa-current::after {
    background-color: #046B99;
    border-radius: 99rem;
    content: "";
    display: block;
    position: absolute;
    bottom: 0.25rem;
    top: 0.25rem;
    width: 0.25rem;
    left: 0rem;
  }
}
@media all and (max-width: 63.99em) {
  .usa-nav__primary a {
    padding-bottom: 0.75rem;
    padding-top: 0.75rem;
  }
}
@media all and (min-width: 64em) {
  .usa-nav__primary {
    display: flex;
    align-items: stretch;
  }
}
.usa-nav__primary .usa-nav__primary-item a {
  text-decoration: none;
}
.usa-nav__primary > .usa-nav__primary-item {
  line-height: 1.2;
}
@media all and (min-width: 64em) {
  .usa-nav__primary > .usa-nav__primary-item {
    font-size: 0.89rem;
    line-height: 1;
  }
}
@media all and (min-width: 64em) {
  .usa-nav__primary > .usa-nav__primary-item > a {
    line-height: 1;
    padding: 1rem;
    align-items: center;
    color: #565c65;
    display: flex;
    font-weight: 700;
  }
  .usa-nav__primary > .usa-nav__primary-item > a:hover {
    color: #046B99;
  }
}
@media all and (min-width: 64em) {
  .usa-nav__primary > .usa-nav__primary-item > button,
  .usa-nav__primary > .usa-nav__primary-item > a {
    height: 100%;
  }
}
@media all and (min-width: 64em) {
  .usa-nav__primary a {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
  }
}
.usa-nav__primary button {
  color: #046B99;
  text-decoration: underline;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  font-weight: normal;
  justify-content: normal;
  text-align: left;
  margin: 0;
  padding: 0;
  width: auto;
  position: relative;
  color: #565c65;
  font-weight: normal;
  line-height: 1.2;
  padding: 0.75rem 1rem;
  text-decoration: none;
  width: 100%;
}
.usa-nav__primary button:visited {
  color: #54278f;
}
.usa-nav__primary button:hover {
  color: #0b4778;
}
.usa-nav__primary button:active {
  color: #1C304A;
}
.usa-nav__primary button:focus {
  outline: 0.25rem solid #2491ff;
  outline-offset: 0rem;
}
.usa-nav__primary button:hover,
.usa-nav__primary button.usa-button--hover,
.usa-nav__primary button:disabled:hover,
.usa-nav__primary button[aria-disabled=true]:hover,
.usa-nav__primary button:disabled.usa-button--hover,
.usa-nav__primary button[aria-disabled=true].usa-button--hover,
.usa-nav__primary button:active,
.usa-nav__primary button.usa-button--active,
.usa-nav__primary button:disabled:active,
.usa-nav__primary button[aria-disabled=true]:active,
.usa-nav__primary button:disabled.usa-button--active,
.usa-nav__primary button[aria-disabled=true].usa-button--active,
.usa-nav__primary button:disabled:focus,
.usa-nav__primary button[aria-disabled=true]:focus,
.usa-nav__primary button:disabled.usa-focus,
.usa-nav__primary button[aria-disabled=true].usa-focus,
.usa-nav__primary button:disabled,
.usa-nav__primary button[aria-disabled=true],
.usa-nav__primary button.usa-button--disabled {
  background-color: transparent;
  box-shadow: none;
  text-decoration: underline;
}
.usa-nav__primary button.usa-button--hover {
  color: #0b4778;
}
.usa-nav__primary button.usa-button--active {
  color: #1C304A;
}
.usa-nav__primary button:disabled,
.usa-nav__primary button[aria-disabled=true],
.usa-nav__primary button:disabled:hover,
.usa-nav__primary button[aria-disabled=true]:hover,
.usa-nav__primary button[aria-disabled=true]:focus {
  color: #757575;
}
@media (forced-colors: active) {
  .usa-nav__primary button:disabled,
  .usa-nav__primary button[aria-disabled=true],
  .usa-nav__primary button:disabled:hover,
  .usa-nav__primary button[aria-disabled=true]:hover,
  .usa-nav__primary button[aria-disabled=true]:focus {
    color: GrayText;
  }
}
@media all and (min-width: 64em) {
  .usa-nav__primary button {
    line-height: 1;
    padding: 1rem;
    font-size: 0.89rem;
    font-weight: 700;
  }
}
@media (forced-colors: active) {
  .usa-nav__primary button {
    forced-color-adjust: auto;
  }
}
.usa-nav__primary button:hover {
  color: #046B99;
  background-color: #f0f0f0;
  text-decoration: none;
}
@media all and (min-width: 64em) {
  .usa-nav__primary button:hover {
    background-color: transparent;
  }
}
.usa-nav__primary button[aria-expanded],
.usa-nav__primary button[aria-expanded]:hover {
  background-image: none;
}
@media (forced-colors: active) {
  .usa-nav__primary button[aria-expanded]::before,
  .usa-nav__primary button[aria-expanded]:hover::before {
    content: none;
  }
}
.usa-nav__primary button[aria-expanded] span::after {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.usa-nav__primary button[aria-expanded=false] span::after {
  background-image: url("../add-XJXD7HSN.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 1.25rem 1.25rem;
  display: inline-block;
  height: 1.25rem;
  width: 1.25rem;
  content: "";
  vertical-align: middle;
  margin-left: auto;
}
@supports (mask: url()) {
  .usa-nav__primary button[aria-expanded=false] span::after {
    background: none;
    background-color: ButtonText;
    -webkit-mask-image: url("../add-XJXD7HSN.svg"), linear-gradient(transparent, transparent);
    mask-image: url("../add-XJXD7HSN.svg"), linear-gradient(transparent, transparent);
    -webkit-mask-position: center center;
    mask-position: center center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: 1.25rem 1.25rem;
    mask-size: 1.25rem 1.25rem;
  }
}
.usa-nav__primary button[aria-expanded=false] span::after:hover {
  background-color: buttonText;
}
@media all and (min-width: 64em) {
  .usa-nav__primary button[aria-expanded=false] span::after {
    background-image: url("../expand_more-S37WEEPD.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 1rem 1rem;
    display: inline-block;
    height: 1rem;
    width: 1rem;
    content: "";
    vertical-align: middle;
    margin-left: auto;
  }
  @supports (mask: url()) {
    .usa-nav__primary button[aria-expanded=false] span::after {
      background: none;
      background-color: ButtonText;
      -webkit-mask-image: url("../expand_more-S37WEEPD.svg"), linear-gradient(transparent, transparent);
      mask-image: url("../expand_more-S37WEEPD.svg"), linear-gradient(transparent, transparent);
      -webkit-mask-position: center center;
      mask-position: center center;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-size: 1rem 1rem;
      mask-size: 1rem 1rem;
    }
  }
  .usa-nav__primary button[aria-expanded=false] span::after {
    right: 0.75rem;
  }
}
@media all and (min-width: 64em) {
  .usa-nav__primary button[aria-expanded=false]:hover span::after {
    background-color: #1a4480;
  }
}
@media (min-width: 64em) and (forced-colors: active) {
  .usa-nav__primary button[aria-expanded=false]:hover span::after {
    background-color: ButtonText;
  }
}
.usa-nav__primary button[aria-expanded=true] span::after {
  background-image: url("../remove-6IAU2W4S.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 1.25rem 1.25rem;
  display: inline-block;
  height: 1.25rem;
  width: 1.25rem;
  content: "";
  vertical-align: middle;
  margin-left: auto;
}
@supports (mask: url()) {
  .usa-nav__primary button[aria-expanded=true] span::after {
    background: none;
    background-color: ButtonText;
    -webkit-mask-image: url("../remove-6IAU2W4S.svg"), linear-gradient(transparent, transparent);
    mask-image: url("../remove-6IAU2W4S.svg"), linear-gradient(transparent, transparent);
    -webkit-mask-position: center center;
    mask-position: center center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: 1.25rem 1.25rem;
    mask-size: 1.25rem 1.25rem;
  }
}
.usa-nav__primary button[aria-expanded=true] span::after {
  position: absolute;
  right: 0;
}
@media (forced-colors: active) {
  .usa-nav__primary button[aria-expanded=true] span::after {
    background-color: ButtonText;
  }
}
@media all and (min-width: 64em) {
  .usa-nav__primary button[aria-expanded=true] {
    background-image: none;
    background-color: #1C304A;
    color: white;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .usa-nav__primary button[aria-expanded=true] span::after {
    background-image: url("../expand_less-RBECZ4GA.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 1rem 1rem;
    display: inline-block;
    height: 1rem;
    width: 1rem;
    content: "";
    vertical-align: middle;
    margin-left: auto;
  }
  @supports (mask: url()) {
    .usa-nav__primary button[aria-expanded=true] span::after {
      background: none;
      background-color: ButtonText;
      -webkit-mask-image: url("../expand_less-RBECZ4GA.svg"), linear-gradient(transparent, transparent);
      mask-image: url("../expand_less-RBECZ4GA.svg"), linear-gradient(transparent, transparent);
      -webkit-mask-position: center center;
      mask-position: center center;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-size: 1rem 1rem;
      mask-size: 1rem 1rem;
    }
  }
  .usa-nav__primary button[aria-expanded=true] span::after {
    right: 0.75rem;
    background-color: white;
  }
}
@media (min-width: 64em) and (min-width: 64em) and (forced-colors: active) {
  .usa-nav__primary button[aria-expanded=true] span::after {
    background-color: ButtonText;
  }
}
@media all and (min-width: 64em) {
  .usa-nav__primary .usa-accordion__button span {
    display: inline-block;
    margin-right: 0;
    padding-right: 1rem;
  }
}
.usa-nav__secondary {
  margin-top: 1rem;
}
@media all and (min-width: 64em) {
  .usa-nav__secondary {
    flex-direction: column;
    align-items: flex-end;
    bottom: 4rem;
    display: flex;
    font-size: 0.89rem;
    margin-top: 0.5rem;
    min-width: calc(27ch + 3rem);
    position: absolute;
    right: 2rem;
  }
}
.usa-nav__secondary .usa-search {
  width: 100%;
}
@media all and (min-width: 64em) {
  .usa-nav__secondary .usa-search {
    margin-left: 0;
    margin-top: 0.5rem;
  }
}
.usa-nav__secondary-links {
  margin-bottom: 0;
  margin-top: 0;
  list-style-type: none;
  padding-left: 0;
  line-height: 1.3;
  margin-top: 1.5rem;
}
@media all and (min-width: 64em) {
  .usa-nav__secondary-links {
    column-gap: 0.5rem;
    display: flex;
    flex-flow: row nowrap;
    line-height: 1;
    margin-bottom: 0.25rem;
    margin-top: 0;
  }
}
@media all and (min-width: 64em) {
  .usa-nav__secondary-links .usa-nav__secondary-item {
    padding-left: 0.25rem;
  }
  .usa-nav__secondary-links .usa-nav__secondary-item + .usa-nav__secondary-item {
    border-left: 1px solid #dfe1e2;
    padding-left: 0.5rem;
  }
}
.usa-nav__secondary-links a {
  color: #71767a;
  display: inline-block;
  font-size: 0.89rem;
  text-decoration: none;
}
.usa-nav__secondary-links a:hover {
  color: #046B99;
  text-decoration: underline;
}
@media all and (max-width: 63.99em) {
  .usa-nav__submenu {
    margin-bottom: 0;
    margin-top: 0;
    list-style-type: none;
    padding-left: 0;
    margin: 0;
  }
  .usa-nav__submenu > li {
    margin-bottom: 0;
    max-width: unset;
  }
  .usa-nav__submenu-item {
    border-top: 1px solid #dfe1e2;
    font-size: 0.89rem;
  }
  .usa-nav__submenu .usa-current::after {
    display: none;
  }
}
@media all and (max-width: 63.99em) and (min-width: 40em) {
  .usa-nav__submenu .usa-current::after {
    display: none;
  }
}
@media all and (max-width: 63.99em) {
  .usa-nav__submenu a:not(.usa-button) {
    padding-left: 2rem;
  }
  .usa-nav__submenu .usa-nav__submenu a:not(.usa-button) {
    padding-left: 3rem;
  }
  .usa-nav__submenu .usa-nav__submenu .usa-nav__submenu a:not(.usa-button) {
    padding-left: 4rem;
  }
}
@media all and (min-width: 64em) {
  .usa-nav__submenu {
    margin-bottom: 0;
    margin-top: 0;
    list-style-type: none;
    padding-left: 0;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    background-color: #1C304A;
    width: 15rem;
    position: absolute;
    z-index: 400;
  }
}
.usa-nav__submenu[aria-hidden=true] {
  display: none;
}
@media all and (min-width: 64em) {
  .usa-nav__submenu .usa-nav__submenu-item a {
    padding-left: 1rem;
    padding-right: 1rem;
    color: white;
    line-height: 1.3;
    display: block;
  }
  .usa-nav__submenu .usa-nav__submenu-item a:focus {
    outline-offset: -0.25rem;
  }
  .usa-nav__submenu .usa-nav__submenu-item a:hover {
    color: white;
    text-decoration: underline;
  }
}
.usa-nav__submenu-list {
  margin-bottom: 0;
  margin-top: 0;
  list-style-type: none;
  padding-left: 0;
}
.usa-nav__submenu-list > li {
  margin-bottom: 0;
  max-width: unset;
}
.usa-nav__submenu-list .usa-nav__submenu-list-item {
  margin: 0;
  font-size: 0.89rem;
}
.usa-nav__submenu-list .usa-nav__submenu-list-item a {
  line-height: 1.3;
}
.usa-nav__close {
  color: #046B99;
  text-decoration: underline;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  font-weight: normal;
  justify-content: normal;
  text-align: left;
  margin: 0;
  padding: 0;
  width: auto;
  height: 3rem;
  width: 3rem;
  background-image: none;
  color: currentColor;
  flex: none;
  float: right;
  margin: -0.75rem -1rem 1rem auto;
  text-align: center;
}
.usa-nav__close:visited {
  color: #54278f;
}
.usa-nav__close:hover {
  color: #0b4778;
}
.usa-nav__close:active {
  color: #1C304A;
}
.usa-nav__close:focus {
  outline: 0.25rem solid #2491ff;
  outline-offset: 0rem;
}
.usa-nav__close:hover,
.usa-nav__close.usa-button--hover,
.usa-nav__close:disabled:hover,
.usa-nav__close[aria-disabled=true]:hover,
.usa-nav__close:disabled.usa-button--hover,
.usa-nav__close[aria-disabled=true].usa-button--hover,
.usa-nav__close:active,
.usa-nav__close.usa-button--active,
.usa-nav__close:disabled:active,
.usa-nav__close[aria-disabled=true]:active,
.usa-nav__close:disabled.usa-button--active,
.usa-nav__close[aria-disabled=true].usa-button--active,
.usa-nav__close:disabled:focus,
.usa-nav__close[aria-disabled=true]:focus,
.usa-nav__close:disabled.usa-focus,
.usa-nav__close[aria-disabled=true].usa-focus,
.usa-nav__close:disabled,
.usa-nav__close[aria-disabled=true],
.usa-nav__close.usa-button--disabled {
  background-color: transparent;
  box-shadow: none;
  text-decoration: underline;
}
.usa-nav__close.usa-button--hover {
  color: #0b4778;
}
.usa-nav__close.usa-button--active {
  color: #1C304A;
}
.usa-nav__close:disabled,
.usa-nav__close[aria-disabled=true],
.usa-nav__close:disabled:hover,
.usa-nav__close[aria-disabled=true]:hover,
.usa-nav__close[aria-disabled=true]:focus {
  color: #757575;
}
@media (forced-colors: active) {
  .usa-nav__close:disabled,
  .usa-nav__close[aria-disabled=true],
  .usa-nav__close:disabled:hover,
  .usa-nav__close[aria-disabled=true]:hover,
  .usa-nav__close[aria-disabled=true]:focus {
    color: GrayText;
  }
}
@media (forced-colors: active) {
  .usa-nav__close::before {
    background-image: url("../close-D6X4FKTZ.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 1.25rem 1.25rem;
    display: inline-block;
    height: 1.25rem;
    width: 1.25rem;
    content: "";
    vertical-align: middle;
    margin-right: auto;
  }
  @supports (mask: url()) {
    .usa-nav__close::before {
      background: none;
      background-color: ButtonText;
      -webkit-mask-image: url("../close-D6X4FKTZ.svg"), linear-gradient(transparent, transparent);
      mask-image: url("../close-D6X4FKTZ.svg"), linear-gradient(transparent, transparent);
      -webkit-mask-position: center center;
      mask-position: center center;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-size: 1.25rem 1.25rem;
      mask-size: 1.25rem 1.25rem;
    }
  }
  .usa-nav__close::before {
    background-color: buttonText;
  }
}
.usa-nav__close:hover {
  color: currentColor;
  text-decoration: none;
}
@media all and (min-width: 64em) {
  .usa-nav__close {
    display: none;
  }
}
.usa-nav__close img {
  width: 1.5rem;
}
@media (forced-colors: active) {
  .usa-nav__close img {
    display: none;
  }
}
.usa-nav__close + * {
  clear: both;
}
.usa-js-mobile-nav--active {
  overflow: hidden;
}
@media (min-width: 63.06rem) {
  .usa-js-mobile-nav--active.is-safari {
    overflow-y: scroll;
    position: fixed;
    top: var(--scrolltop, 0);
  }
}
.usa-pagination {
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.08rem;
  line-height: 1.6;
  color: #11181d;
  background-color: white;
  display: flex;
  justify-content: center;
}
.usa-pagination .usa-icon {
  height: 1.13rem;
  width: 1.13rem;
}
.usa-pagination__list {
  margin-bottom: 0;
  margin-top: 0;
  list-style-type: none;
  padding-left: 0;
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  width: auto;
}
.usa-pagination__item {
  display: inline-flex;
  height: 2.5rem;
  justify-content: center;
  line-height: 1;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  min-width: 2.5rem;
}
@media all and (min-width: 40em) {
  .usa-pagination__item {
    flex: 1 0 auto;
  }
}
.usa-pagination__arrow {
  display: none;
}
@media all and (min-width: 40em) {
  .usa-pagination__arrow {
    display: inherit;
  }
}
@media all and (min-width: 40em) {
  .usa-pagination__previous-page {
    margin-right: 1.25rem;
  }
}
@media all and (min-width: 40em) {
  .usa-pagination__next-page {
    margin-left: 1.25rem;
  }
}
.usa-pagination__link {
  align-items: center;
  color: #046B99;
  display: inline-flex;
}
.usa-pagination__link[disabled] {
  opacity: 0.4 !important;
  pointer-events: none;
}
.usa-pagination__link:hover,
.usa-pagination__link:focus,
.usa-pagination__link:active {
  color: #0b4778;
}
.usa-pagination__link:visited {
  color: #046B99;
}
.usa-pagination__button {
  align-items: center;
  border-color: rgba(17, 24, 29, 0.2);
  border-radius: 0.25rem;
  border-style: solid;
  border-width: 1px;
  color: #046B99;
  display: inline-flex;
  justify-content: center;
  padding: 0.5rem;
  width: 100%;
}
.usa-pagination__button:hover,
.usa-pagination__button:focus,
.usa-pagination__button:active {
  color: #0b4778;
  border-color: #0b4778;
}
@media (forced-colors: active) {
  .usa-pagination__button:hover,
  .usa-pagination__button:focus,
  .usa-pagination__button:active {
    border: 2px solid transparent;
  }
}
.usa-pagination .usa-current {
  background-color: #11181d;
  border-color: transparent;
  color: white;
}
@media (forced-colors: active) {
  .usa-pagination .usa-current {
    border: 2px solid transparent;
    color: buttonText;
  }
}
.usa-pagination .usa-current:hover,
.usa-pagination .usa-current:focus,
.usa-pagination .usa-current:active {
  background-color: #11181d;
  color: white;
}
@media (forced-colors: active) {
  .usa-pagination .usa-current:hover,
  .usa-pagination .usa-current:focus,
  .usa-pagination .usa-current:active {
    color: buttontext;
  }
}
.usa-pagination__overflow {
  align-items: center;
  align-self: stretch;
  display: inherit;
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
  padding: 0.5rem;
}
.usa-process-list {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.08rem;
  line-height: 1.6;
  margin-bottom: 0;
  margin-top: 0;
  list-style-type: none;
  padding-left: 0;
  counter-reset: usa-numbered-list;
  padding: 20px 0 0 calc(2.5rem / 2 - 0.5rem / 2);
  position: relative;
}
.usa-process-list > li {
  margin-bottom: 0;
  max-width: unset;
}
.usa-process-list > .usa-process-list__item {
  max-width: 72ex;
}
.usa-process-list__item {
  border-left: 0.5rem solid #97d4ea;
  font-size: 1.01rem;
  margin-bottom: 0;
  padding-bottom: 2rem;
  padding-left: calc(calc(2.5rem / 2 - 0.25rem) * 2);
}
.usa-prose .usa-process-list {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.08rem;
  line-height: 1.6;
  margin-bottom: 0;
  margin-top: 0;
  list-style-type: none;
  padding-left: 0;
  counter-reset: usa-numbered-list;
  padding: 20px 0 0 calc(2.5rem / 2 - 0.5rem / 2);
  position: relative;
}
.usa-prose .usa-process-list > li {
  margin-bottom: 0;
  max-width: unset;
}
.usa-prose .usa-process-list > .usa-process-list__item {
  max-width: 72ex;
}
.usa-prose .usa-process-list__item {
  border-left: 0.5rem solid #97d4ea;
  font-size: 1.01rem;
  margin-bottom: 0;
  padding-bottom: 2rem;
  padding-left: calc(calc(2.5rem / 2 - 0.25rem) * 2);
}
.usa-process-list__item:last-child {
  border-left: 0.5rem solid transparent;
}
.usa-process-list__item::before {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  border-radius: 99rem;
  width: 2.5rem;
  background-color: white;
  border: 0.25rem solid #11181d;
  box-shadow: 0 0 0 0.25rem white;
  color: #11181d;
  content: counter(usa-numbered-list, decimal);
  counter-increment: usa-numbered-list;
  display: flex;
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.39rem;
  font-weight: 700;
  height: 2.5rem;
  left: 0;
  line-height: 1;
  margin-top: -0.4rem;
  position: absolute;
  width: 2.5rem;
}
.usa-process-list__item ul {
  list-style-type: disc;
}
.usa-process-list__item ul li {
  margin-bottom: 0.25rem;
}
.usa-process-list__item ul li:last-child {
  margin-bottom: 0;
}
.usa-process-list__item > *:first-child {
  margin-top: 0;
}
.usa-process-list__item > *:last-child {
  margin-bottom: 0;
}
.usa-process-list__heading {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.39rem;
  line-height: 1.2;
  color: #11181d;
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-weight: 700;
  margin: 0;
}
.usa-process-list__heading + * {
  margin-top: 0.25rem;
}
.usa-search {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.08rem;
  line-height: 1.6;
  position: relative;
}
.usa-search::after {
  clear: both;
  content: "";
  display: block;
}
.usa-search[role=search],
.usa-search[role=search] > div,
.usa-search [role=search] {
  display: flex;
}
.usa-search [type=submit] {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  height: 2rem;
  margin: 0;
  padding: 0;
  width: 3rem;
}
@media all and (min-width: 30em) {
  .usa-search [type=submit] {
    padding-left: 1rem;
    padding-right: 1rem;
    width: auto;
  }
}
@media (forced-colors: active) {
  .usa-search [type=submit]::before {
    background-image: url("../search-2D3FZK3P.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 1.5rem 1.5rem;
    display: inline-block;
    height: 1.5rem;
    width: 1.5rem;
    content: "";
    vertical-align: middle;
    margin-right: auto;
  }
  @supports (mask: url()) {
    .usa-search [type=submit]::before {
      background: none;
      background-color: ButtonText;
      -webkit-mask-image: url("../search-2D3FZK3P.svg"), linear-gradient(transparent, transparent);
      mask-image: url("../search-2D3FZK3P.svg"), linear-gradient(transparent, transparent);
      -webkit-mask-position: center center;
      mask-position: center center;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-size: 1.5rem 1.5rem;
      mask-size: 1.5rem 1.5rem;
    }
  }
  .usa-search [type=submit]:focus {
    outline-offset: 0;
  }
}
@media (forced-colors: active) and (min-width: 30em) {
  .usa-search [type=submit]::before {
    content: none;
  }
}
@media all and (min-width: 30em) {
  .usa-search__submit-icon {
    display: none;
  }
}
@media (forced-colors: active) {
  .usa-search__submit-icon {
    display: none;
  }
}
@media all and (min-width: 30em) {
  .usa-search--big [type=search],
  .usa-search--big .usa-search__input {
    font-size: 1.01rem;
    height: 3rem;
  }
}
@media all and (min-width: 30em) {
  .usa-search--big [type=submit],
  .usa-search--big .usa-search__submit {
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 1.39rem;
    height: 3rem;
    width: auto;
  }
}
.usa-search--small [type=submit],
.usa-search--small .usa-search__submit {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  min-width: 3rem;
}
@media (forced-colors: active) and (min-width: 30em) {
  .usa-search--small [type=submit]::before {
    content: "";
  }
}
.usa-search--small .usa-search__submit-icon {
  height: 1.5rem;
  width: 1.5rem;
  display: block;
}
@media (forced-colors: active) {
  .usa-search--small .usa-search__submit-icon {
    display: none;
  }
}
input[type=search] {
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
[type=search],
.usa-search__input {
  padding-bottom: 0;
  padding-top: 0;
  border-bottom-right-radius: 0;
  border-right: none;
  border-top-right-radius: 0;
  box-sizing: border-box;
  float: left;
  font-size: 0.95rem;
  height: 2rem;
  margin: 0;
}
.usa-search__submit-text {
  display: none;
}
@media all and (min-width: 30em) {
  .usa-search__submit-text {
    display: block;
  }
}
.usa-section {
  padding-bottom: 2rem;
  padding-top: 2rem;
}
@media all and (min-width: 40em) {
  .usa-section {
    padding-bottom: 4rem;
    padding-top: 4rem;
  }
}
.usa-section--light {
  background-color: #f0f0f0;
}
.usa-section--dark {
  background-color: #1C304A;
  color: white;
}
.usa-section--dark h1,
.usa-section--dark h2,
.usa-section--dark h3,
.usa-section--dark h4,
.usa-section--dark h5,
.usa-section--dark h6 {
  color: #00CFFF;
}
.usa-section--dark p {
  color: white;
}
.usa-section--dark a {
  color: #dfe1e2;
}
.usa-section--dark a:visited {
  color: #dfe1e2;
}
.usa-section--dark a:hover,
.usa-section--dark a:active {
  color: #f0f0f0;
}
.usa-sidenav {
  margin-bottom: 0;
  margin-top: 0;
  list-style-type: none;
  padding-left: 0;
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.01rem;
  line-height: 1.3;
  border-bottom: 1px solid #dfe1e2;
}
.usa-sidenav > li {
  margin-bottom: 0;
  max-width: unset;
}
.usa-sidenav__item {
  border-top: 1px solid #dfe1e2;
}
.usa-sidenav a:not(.usa-button) {
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
}
.usa-sidenav a:not(.usa-button):hover {
  background-color: #f0f0f0;
  text-decoration: none;
}
.usa-sidenav a:not(.usa-button):not(.usa-current) {
  color: #565c65;
}
.usa-sidenav a:not(.usa-button):not(.usa-current):hover {
  color: #046B99;
}
.usa-sidenav a:not(.usa-button):not(.usa-current):focus {
  outline-offset: 0;
}
.usa-sidenav .usa-current {
  position: relative;
  color: #046B99;
  font-weight: 700;
}
.usa-sidenav .usa-current::after {
  background-color: #046B99;
  border-radius: 99rem;
  content: "";
  display: block;
  position: absolute;
  bottom: 0.25rem;
  top: 0.25rem;
  width: 0.25rem;
  left: 0.25rem;
}
@media all and (min-width: 40em) {
  .usa-sidenav .usa-current {
    position: relative;
  }
  .usa-sidenav .usa-current::after {
    background-color: #046B99;
    border-radius: 99rem;
    content: "";
    display: block;
    position: absolute;
    bottom: 0.25rem;
    top: 0.25rem;
    width: 0.25rem;
    left: 0rem;
  }
}
.grid-container .usa-sidenav {
  margin-left: -1rem;
  margin-right: -1rem;
}
@media all and (min-width: 40em) {
  .grid-container .usa-sidenav {
    margin-left: 0;
    margin-right: 0;
  }
}
.usa-sidenav__sublist {
  margin-bottom: 0;
  margin-top: 0;
  list-style-type: none;
  padding-left: 0;
  margin: 0;
  font-size: 0.95rem;
}
.usa-sidenav__sublist > li {
  margin-bottom: 0;
  max-width: unset;
}
.usa-sidenav__sublist-item {
  border-top: 1px solid #dfe1e2;
  font-size: 0.89rem;
}
.usa-sidenav__sublist .usa-current::after {
  display: none;
}
@media all and (min-width: 40em) {
  .usa-sidenav__sublist .usa-current::after {
    display: none;
  }
}
.usa-sidenav__sublist a:not(.usa-button) {
  padding-left: 2rem;
}
.usa-sidenav__sublist .usa-sidenav__sublist a:not(.usa-button) {
  padding-left: 3rem;
}
.usa-sidenav__sublist .usa-sidenav__sublist .usa-sidenav__sublist a:not(.usa-button) {
  padding-left: 4rem;
}
.usa-site-alert .usa-alert {
  background-color: #f0f0f0;
  border-left: 0.5rem solid #a9aeb1;
  color: #11181d;
}
.usa-site-alert .usa-alert .usa-alert__body {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.08rem;
  line-height: 1.6;
  padding-bottom: 1rem;
  padding-top: 1rem;
  position: relative;
}
.usa-site-alert .usa-alert .usa-alert__text {
  margin-bottom: 0;
  margin-top: 0;
}
.usa-site-alert .usa-alert .usa-alert__text:only-child {
  padding-bottom: 0;
  padding-top: 0;
}
.usa-site-alert .usa-alert .usa-alert__heading {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.39rem;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 0.5rem;
}
.usa-site-alert .usa-alert > .usa-list,
.usa-site-alert .usa-alert .usa-alert__body > .usa-list {
  padding-left: 2ch;
}
.usa-site-alert .usa-alert > .usa-list:last-child,
.usa-site-alert .usa-alert .usa-alert__body > .usa-list:last-child {
  margin-bottom: 0;
}
.usa-site-alert .usa-alert .usa-alert__body {
  max-width: 64rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.usa-site-alert .usa-alert .usa-alert__body::before {
  left: 1.0833333333rem;
}
@media all and (min-width: 64em) {
  .usa-site-alert .usa-alert .usa-alert__body {
    margin-left: auto;
    margin-right: auto;
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
  .usa-site-alert .usa-alert .usa-alert__body::before {
    left: 1.5833333333rem;
  }
}
.usa-site-alert--info .usa-alert {
  background-color: #e7f6f8;
  border-left-color: #00bde3;
}
.usa-site-alert--info .usa-alert .usa-alert__body {
  color: #11181d;
  background-color: #e7f6f8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 3.3333333333rem;
}
.usa-site-alert--info .usa-alert .usa-alert__body::before {
  background-image: url("../info-TBKQSD4V.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 2rem 2rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
  content: "";
  display: block;
  position: absolute;
  top: 0.6666666667rem;
}
@supports (mask: url()) {
  .usa-site-alert--info .usa-alert .usa-alert__body::before {
    background: none;
    background-color: #11181d;
    -webkit-mask-image: url("../info-TBKQSD4V.svg"), linear-gradient(transparent, transparent);
    mask-image: url("../info-TBKQSD4V.svg"), linear-gradient(transparent, transparent);
    -webkit-mask-position: center center;
    mask-position: center center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: 2rem 2rem;
    mask-size: 2rem 2rem;
  }
}
.usa-site-alert--info .usa-alert .usa-alert__body > * {
  margin-left: 2.5rem;
}
.usa-site-alert--info .usa-alert .usa-alert__body .usa-link {
  color: #046B99;
}
.usa-site-alert--info .usa-alert .usa-alert__body .usa-link:visited {
  color: #54278f;
}
.usa-site-alert--info .usa-alert .usa-alert__body .usa-link:hover,
.usa-site-alert--info .usa-alert .usa-alert__body .usa-link:active {
  color: #0b4778;
}
.usa-site-alert--emergency .usa-alert {
  background-color: #9c3d10;
  border-left-color: #9c3d10;
}
.usa-site-alert--emergency .usa-alert .usa-alert__body {
  color: white;
  background-color: #9c3d10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 3.3333333333rem;
}
.usa-site-alert--emergency .usa-alert .usa-alert__body::before {
  background-image: url("../error--white-XMM7ATFA.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 2rem 2rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
  content: "";
  display: block;
  position: absolute;
  top: 0.6666666667rem;
}
@supports (mask: url()) {
  .usa-site-alert--emergency .usa-alert .usa-alert__body::before {
    background: none;
    background-color: white;
    -webkit-mask-image: url("../error-3VQTC4AF.svg"), linear-gradient(transparent, transparent);
    mask-image: url("../error-3VQTC4AF.svg"), linear-gradient(transparent, transparent);
    -webkit-mask-position: center center;
    mask-position: center center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: 2rem 2rem;
    mask-size: 2rem 2rem;
  }
}
.usa-site-alert--emergency .usa-alert .usa-alert__body > * {
  margin-left: 2.5rem;
}
.usa-site-alert--emergency .usa-alert .usa-alert__body .usa-link {
  color: #dfe1e2;
}
.usa-site-alert--emergency .usa-alert .usa-alert__body .usa-link:visited {
  color: #dfe1e2;
}
.usa-site-alert--emergency .usa-alert .usa-alert__body .usa-link:hover,
.usa-site-alert--emergency .usa-alert .usa-alert__body .usa-link:active {
  color: #f0f0f0;
}
.usa-site-alert--slim .usa-alert .usa-alert__body {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  min-height: 0;
}
.usa-site-alert--slim .usa-alert .usa-alert__body:before {
  height: 1.5rem;
  top: 0.5rem;
  width: 1.5rem;
}
@supports (mask: url()) {
  .usa-site-alert--slim .usa-alert .usa-alert__body:before {
    -webkit-mask-size: 1.5rem;
    mask-size: 1.5rem;
  }
}
.usa-site-alert--slim .usa-alert .usa-alert__body > * {
  margin-left: 2rem;
}
.usa-site-alert--slim .usa-alert .usa-alert__body::before {
  left: 1.125rem;
}
@media all and (min-width: 64em) {
  .usa-site-alert--slim .usa-alert .usa-alert__body::before {
    left: 1.625rem;
  }
}
.usa-site-alert--no-icon .usa-alert .usa-alert__body {
  min-height: 0;
}
.usa-site-alert--no-icon .usa-alert .usa-alert__body:before {
  display: none;
}
.usa-site-alert--no-icon .usa-alert .usa-alert__body > * {
  margin-left: 0;
}
.usa-skipnav {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.08rem;
  line-height: 1.6;
  color: #046B99;
  text-decoration: underline;
  background: transparent;
  left: 0;
  padding: 0.5rem 1rem;
  position: absolute;
  top: -3.8rem;
  transition: 0.15s ease-in-out;
  z-index: 100;
}
.usa-skipnav:visited {
  color: #54278f;
}
.usa-skipnav:hover {
  color: #0b4778;
}
.usa-skipnav:active {
  color: #1C304A;
}
.usa-skipnav:focus {
  outline: 0.25rem solid #2491ff;
  outline-offset: 0rem;
}
.usa-skipnav:focus,
.usa-skipnav.usa-focus {
  background: white;
  left: 0;
  position: absolute;
  top: 0;
  transition: 0.15s ease-in-out;
}
.usa-skipnav__container {
  position: relative;
}
.usa-step-indicator {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.01rem;
  line-height: 1.2;
  background-color: white;
  margin-bottom: 2rem;
  margin-left: -1px;
  margin-right: -1px;
}
@media all and (min-width: 40em) {
  .usa-step-indicator {
    margin-left: 0;
    margin-right: 0;
  }
}
.usa-step-indicator__segments {
  counter-reset: usa-step-indicator;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}
.usa-step-indicator__segment {
  flex: 1 1 0%;
  counter-increment: usa-step-indicator;
  margin-left: 1px;
  margin-right: 1px;
  max-width: 15rem;
  min-height: 0.5rem;
  position: relative;
}
.usa-step-indicator__segment:after {
  background-color: #919191;
  content: "";
  display: block;
  height: 0.5rem;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
@media all and (min-width: 40em) {
  .usa-step-indicator__segment:after {
    height: 0.5rem;
  }
}
.usa-step-indicator__segment--complete::after {
  background-color: #1C304A;
}
.usa-step-indicator__segment--complete .usa-step-indicator__segment-label {
  color: #1C304A;
}
.usa-step-indicator__segment--current::after {
  background-color: #046B99;
}
.usa-step-indicator__segment--current .usa-step-indicator__segment-label {
  color: #046B99;
  font-weight: 700;
}
.usa-step-indicator__segment-label {
  display: none;
}
@media all and (min-width: 40em) {
  .usa-step-indicator__segment-label {
    color: #565c65;
    display: block;
    font-size: 1.01rem;
    margin-top: calc(0.5rem + 0.5rem);
    padding-right: 2rem;
    text-align: left;
  }
}
.usa-step-indicator__header {
  align-items: baseline;
  display: flex;
}
.usa-step-indicator__heading {
  color: #11181d;
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.08rem;
  font-weight: 700;
  margin: 1rem 0 0;
}
@media all and (min-width: 40em) {
  .usa-step-indicator__heading {
    font-size: 1.39rem;
    margin-top: 2rem;
  }
}
.usa-step-indicator__current-step {
  height: 2.5rem;
  border-radius: 99rem;
  width: 2.5rem;
  font-weight: normal;
  font-feature-settings: "tnum" 1, "kern" 1;
  background-color: #046B99;
  color: white;
  display: inline-block;
  padding: calc((2.5rem - 2ex * 1.2) * 0.5);
  text-align: center;
}
.usa-step-indicator__total-steps {
  font-weight: normal;
  font-feature-settings: "tnum" 1, "kern" 1;
  color: #046B99;
  margin-right: 0.5rem;
}
@media all and (min-width: 40em) {
  .usa-step-indicator--counters .usa-step-indicator__segment,
  .usa-step-indicator--counters-sm .usa-step-indicator__segment {
    margin-left: 0;
    margin-right: 0;
    margin-top: calc((2.5rem - 0.5rem) / 2 + 0.25rem);
  }
  .usa-step-indicator--counters .usa-step-indicator__segment:before,
  .usa-step-indicator--counters-sm .usa-step-indicator__segment:before {
    height: 2.5rem;
    border-radius: 99rem;
    width: 2.5rem;
    font-feature-settings: "tnum" 1, "kern" 1;
    background-color: white;
    box-shadow: inset 0 0 0 0.25rem #919191, 0 0 0 0.25rem white;
    color: #565c65;
    content: counter(usa-step-indicator);
    display: block;
    font-weight: 700;
    left: 0;
    line-height: 1;
    padding: calc((2.5rem - 2ex * 1) * 0.5);
    position: absolute;
    text-align: center;
    top: calc((2.5rem - 0.5rem) / -2);
    z-index: 100;
  }
  .usa-step-indicator--counters .usa-step-indicator__segment:last-child:after,
  .usa-step-indicator--counters-sm .usa-step-indicator__segment:last-child:after {
    display: none;
  }
}
.usa-step-indicator--counters .usa-step-indicator__segment--complete::before,
.usa-step-indicator--counters-sm .usa-step-indicator__segment--complete::before {
  background-color: #1C304A;
  box-shadow: 0 0 0 0.25rem white;
  color: white;
}
.usa-step-indicator--counters .usa-step-indicator__segment--current::before,
.usa-step-indicator--counters-sm .usa-step-indicator__segment--current::before {
  background-color: #046B99;
  box-shadow: 0 0 0 0.25rem white;
  color: white;
}
@media all and (min-width: 40em) {
  .usa-step-indicator--counters .usa-step-indicator__segment-label,
  .usa-step-indicator--counters-sm .usa-step-indicator__segment-label {
    margin-top: calc((2.5rem + 0.5rem) / 2 + 0.5rem);
  }
}
@media all and (min-width: 40em) {
  .usa-step-indicator--counters.usa-step-indicator--center .usa-step-indicator__segment:first-child:after,
  .usa-step-indicator--counters-sm.usa-step-indicator--center .usa-step-indicator__segment:first-child:after {
    left: 50%;
    right: 0;
    width: auto;
  }
  .usa-step-indicator--counters.usa-step-indicator--center .usa-step-indicator__segment:last-child:after,
  .usa-step-indicator--counters-sm.usa-step-indicator--center .usa-step-indicator__segment:last-child:after {
    display: block;
    left: 0;
    right: 50%;
    width: auto;
  }
}
@media all and (min-width: 40em) {
  .usa-step-indicator--counters-sm .usa-step-indicator__segment {
    margin-top: calc((1.5rem - 0.5rem) / 2 + 0.25rem);
  }
  .usa-step-indicator--counters-sm .usa-step-indicator__segment:before {
    height: 1.5rem;
    border-radius: 99rem;
    width: 1.5rem;
    font-size: 0.89rem;
    padding: calc(0.25rem + 1px);
    top: calc((1.5rem - 0.5rem) / -2);
  }
  .usa-step-indicator--counters-sm .usa-step-indicator__segment:last-child:after {
    display: none;
  }
}
@media all and (min-width: 40em) {
  .usa-step-indicator--counters-sm .usa-step-indicator__segment-label {
    margin-top: calc((1.5rem + 0.5rem) / 2 + 0.5rem);
  }
}
.usa-step-indicator--no-labels {
  margin-left: -1px;
  margin-right: -1px;
}
.usa-step-indicator--no-labels .usa-step-indicator__segment {
  margin-top: 0;
  margin-left: 1px;
  margin-right: 1px;
}
.usa-step-indicator--no-labels .usa-step-indicator__segment:before {
  display: none;
}
.usa-step-indicator--no-labels .usa-step-indicator__segment:last-child:after {
  display: block;
}
.usa-step-indicator--no-labels .usa-step-indicator__heading {
  margin-top: 1rem;
}
.usa-step-indicator--no-labels .usa-step-indicator__segment-label {
  display: none;
}
.usa-step-indicator--center {
  margin-left: -1px;
  margin-right: -1px;
}
.usa-step-indicator--center .usa-step-indicator__segment {
  margin-left: 1px;
  margin-right: 1px;
}
.usa-step-indicator--center .usa-step-indicator__segment:before {
  left: calc(50% - (2.5rem + 0.25rem) / 2);
}
.usa-step-indicator--center .usa-step-indicator__segment-label {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-align: center;
}
.usa-step-indicator--center.usa-step-indicator--no-labels .usa-step-indicator__segment:first-child:after {
  left: 0;
}
.usa-step-indicator--center.usa-step-indicator--no-labels .usa-step-indicator__segment:last-child:after {
  right: 0;
}
.usa-step-indicator--center.usa-step-indicator--counters-sm .usa-step-indicator__segment:before {
  left: calc(50% - (1.5rem + 0.25rem) / 2);
}
.usa-summary-box {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.08rem;
  line-height: 1.6;
  color: #11181d;
  background-color: #e7f6f8;
  border-radius: 0.25rem;
  border: 1px #99deea solid;
  padding: 1.5rem;
  position: relative;
}
.usa-summary-box .usa-list:last-child {
  margin-bottom: 0;
}
* + .usa-summary-box {
  margin-top: 1rem;
}
.usa-summary-box__heading {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.39rem;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.5rem;
}
.usa-summary-box__text {
  margin-bottom: 0;
  margin-top: 0;
}
.usa-summary-box__text:only-child {
  margin-bottom: 1px;
  padding-top: 0.25rem;
}
.usa-summary-box__link {
  color: #046B99;
}
.usa-summary-box__link:visited {
  color: #54278f;
}
.usa-summary-box__link:hover,
.usa-summary-box__link:active {
  color: #0b4778;
}
.usa-summary-box__link[href^="#"]:visited {
  color: #046B99;
}
.usa-summary-box__link[href^="#"]:hover {
  color: #0b4778;
}
.usa-table {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.08rem;
  line-height: 1.6;
  border-collapse: collapse;
  border-spacing: 0;
  color: #11181d;
  margin: 1.25rem 0;
  text-align: left;
}
.usa-table thead td,
.usa-table tfoot td,
.usa-table th {
  background-clip: padding-box;
  line-height: 1.3;
  background-color: #f0f0f0;
  color: #11181d;
}
.usa-table th,
.usa-table td {
  border: 1px solid #11181d;
  padding: 0.5rem 1rem;
}
.usa-table td {
  background-color: white;
  font-weight: normal;
}
.usa-table caption {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 0.95rem;
  font-weight: 700;
  margin-bottom: 0.75rem;
  text-align: left;
}
.usa-table th[data-sortable] {
  padding-right: 2.5rem;
  position: relative;
}
.usa-table th[data-sortable]::after {
  border-bottom-color: transparent;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  bottom: 0;
  content: "";
  height: 0;
  left: 0;
  position: absolute;
  width: 100%;
}
.usa-table th[data-sortable]:not([aria-sort]) .usa-table__header__button,
.usa-table th[data-sortable][aria-sort=none] .usa-table__header__button {
  color: #046B99;
  text-decoration: underline;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  font-weight: normal;
  justify-content: normal;
  text-align: left;
  margin: 0;
  padding: 0;
  width: auto;
  height: 2rem;
  width: 2rem;
  background-position: center center;
  background-size: 1.5rem;
  color: #71767a;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  position: absolute;
  right: 0.25rem;
  text-align: center;
  text-decoration: none;
  top: 50%;
  transform: translate(0, -50%);
}
.usa-table th[data-sortable]:not([aria-sort]) .usa-table__header__button:visited,
.usa-table th[data-sortable][aria-sort=none] .usa-table__header__button:visited {
  color: #54278f;
}
.usa-table th[data-sortable]:not([aria-sort]) .usa-table__header__button:hover,
.usa-table th[data-sortable][aria-sort=none] .usa-table__header__button:hover {
  color: #0b4778;
}
.usa-table th[data-sortable]:not([aria-sort]) .usa-table__header__button:active,
.usa-table th[data-sortable][aria-sort=none] .usa-table__header__button:active {
  color: #1C304A;
}
.usa-table th[data-sortable]:not([aria-sort]) .usa-table__header__button:focus,
.usa-table th[data-sortable][aria-sort=none] .usa-table__header__button:focus {
  outline: 0.25rem solid #2491ff;
  outline-offset: 0rem;
}
.usa-table th[data-sortable]:not([aria-sort]) .usa-table__header__button:hover,
.usa-table th[data-sortable]:not([aria-sort]) .usa-table__header__button.usa-button--hover,
.usa-table th[data-sortable]:not([aria-sort]) .usa-table__header__button:disabled:hover,
.usa-table th[data-sortable]:not([aria-sort]) .usa-table__header__button[aria-disabled=true]:hover,
.usa-table th[data-sortable]:not([aria-sort]) .usa-table__header__button:disabled.usa-button--hover,
.usa-table th[data-sortable]:not([aria-sort]) .usa-table__header__button[aria-disabled=true].usa-button--hover,
.usa-table th[data-sortable]:not([aria-sort]) .usa-table__header__button:active,
.usa-table th[data-sortable]:not([aria-sort]) .usa-table__header__button.usa-button--active,
.usa-table th[data-sortable]:not([aria-sort]) .usa-table__header__button:disabled:active,
.usa-table th[data-sortable]:not([aria-sort]) .usa-table__header__button[aria-disabled=true]:active,
.usa-table th[data-sortable]:not([aria-sort]) .usa-table__header__button:disabled.usa-button--active,
.usa-table th[data-sortable]:not([aria-sort]) .usa-table__header__button[aria-disabled=true].usa-button--active,
.usa-table th[data-sortable]:not([aria-sort]) .usa-table__header__button:disabled:focus,
.usa-table th[data-sortable]:not([aria-sort]) .usa-table__header__button[aria-disabled=true]:focus,
.usa-table th[data-sortable]:not([aria-sort]) .usa-table__header__button:disabled.usa-focus,
.usa-table th[data-sortable]:not([aria-sort]) .usa-table__header__button[aria-disabled=true].usa-focus,
.usa-table th[data-sortable]:not([aria-sort]) .usa-table__header__button:disabled,
.usa-table th[data-sortable]:not([aria-sort]) .usa-table__header__button[aria-disabled=true],
.usa-table th[data-sortable]:not([aria-sort]) .usa-table__header__button.usa-button--disabled,
.usa-table th[data-sortable][aria-sort=none] .usa-table__header__button:hover,
.usa-table th[data-sortable][aria-sort=none] .usa-table__header__button.usa-button--hover,
.usa-table th[data-sortable][aria-sort=none] .usa-table__header__button:disabled:hover,
.usa-table th[data-sortable][aria-sort=none] .usa-table__header__button[aria-disabled=true]:hover,
.usa-table th[data-sortable][aria-sort=none] .usa-table__header__button:disabled.usa-button--hover,
.usa-table th[data-sortable][aria-sort=none] .usa-table__header__button[aria-disabled=true].usa-button--hover,
.usa-table th[data-sortable][aria-sort=none] .usa-table__header__button:active,
.usa-table th[data-sortable][aria-sort=none] .usa-table__header__button.usa-button--active,
.usa-table th[data-sortable][aria-sort=none] .usa-table__header__button:disabled:active,
.usa-table th[data-sortable][aria-sort=none] .usa-table__header__button[aria-disabled=true]:active,
.usa-table th[data-sortable][aria-sort=none] .usa-table__header__button:disabled.usa-button--active,
.usa-table th[data-sortable][aria-sort=none] .usa-table__header__button[aria-disabled=true].usa-button--active,
.usa-table th[data-sortable][aria-sort=none] .usa-table__header__button:disabled:focus,
.usa-table th[data-sortable][aria-sort=none] .usa-table__header__button[aria-disabled=true]:focus,
.usa-table th[data-sortable][aria-sort=none] .usa-table__header__button:disabled.usa-focus,
.usa-table th[data-sortable][aria-sort=none] .usa-table__header__button[aria-disabled=true].usa-focus,
.usa-table th[data-sortable][aria-sort=none] .usa-table__header__button:disabled,
.usa-table th[data-sortable][aria-sort=none] .usa-table__header__button[aria-disabled=true],
.usa-table th[data-sortable][aria-sort=none] .usa-table__header__button.usa-button--disabled {
  background-color: transparent;
  box-shadow: none;
  text-decoration: underline;
}
.usa-table th[data-sortable]:not([aria-sort]) .usa-table__header__button.usa-button--hover,
.usa-table th[data-sortable][aria-sort=none] .usa-table__header__button.usa-button--hover {
  color: #0b4778;
}
.usa-table th[data-sortable]:not([aria-sort]) .usa-table__header__button.usa-button--active,
.usa-table th[data-sortable][aria-sort=none] .usa-table__header__button.usa-button--active {
  color: #1C304A;
}
.usa-table th[data-sortable]:not([aria-sort]) .usa-table__header__button:disabled,
.usa-table th[data-sortable]:not([aria-sort]) .usa-table__header__button[aria-disabled=true],
.usa-table th[data-sortable]:not([aria-sort]) .usa-table__header__button:disabled:hover,
.usa-table th[data-sortable]:not([aria-sort]) .usa-table__header__button[aria-disabled=true]:hover,
.usa-table th[data-sortable]:not([aria-sort]) .usa-table__header__button[aria-disabled=true]:focus,
.usa-table th[data-sortable][aria-sort=none] .usa-table__header__button:disabled,
.usa-table th[data-sortable][aria-sort=none] .usa-table__header__button[aria-disabled=true],
.usa-table th[data-sortable][aria-sort=none] .usa-table__header__button:disabled:hover,
.usa-table th[data-sortable][aria-sort=none] .usa-table__header__button[aria-disabled=true]:hover,
.usa-table th[data-sortable][aria-sort=none] .usa-table__header__button[aria-disabled=true]:focus {
  color: #757575;
}
@media (forced-colors: active) {
  .usa-table th[data-sortable]:not([aria-sort]) .usa-table__header__button:disabled,
  .usa-table th[data-sortable]:not([aria-sort]) .usa-table__header__button[aria-disabled=true],
  .usa-table th[data-sortable]:not([aria-sort]) .usa-table__header__button:disabled:hover,
  .usa-table th[data-sortable]:not([aria-sort]) .usa-table__header__button[aria-disabled=true]:hover,
  .usa-table th[data-sortable]:not([aria-sort]) .usa-table__header__button[aria-disabled=true]:focus,
  .usa-table th[data-sortable][aria-sort=none] .usa-table__header__button:disabled,
  .usa-table th[data-sortable][aria-sort=none] .usa-table__header__button[aria-disabled=true],
  .usa-table th[data-sortable][aria-sort=none] .usa-table__header__button:disabled:hover,
  .usa-table th[data-sortable][aria-sort=none] .usa-table__header__button[aria-disabled=true]:hover,
  .usa-table th[data-sortable][aria-sort=none] .usa-table__header__button[aria-disabled=true]:focus {
    color: GrayText;
  }
}
.usa-table th[data-sortable]:not([aria-sort]) .usa-table__header__button .usa-icon,
.usa-table th[data-sortable][aria-sort=none] .usa-table__header__button .usa-icon {
  height: 1.5rem;
  width: 1.5rem;
  vertical-align: middle;
}
.usa-table th[data-sortable]:not([aria-sort]) .usa-table__header__button .usa-icon > g,
.usa-table th[data-sortable][aria-sort=none] .usa-table__header__button .usa-icon > g {
  fill: transparent;
}
.usa-table th[data-sortable]:not([aria-sort]) .usa-table__header__button .usa-icon > g.unsorted,
.usa-table th[data-sortable][aria-sort=none] .usa-table__header__button .usa-icon > g.unsorted {
  fill: #11181d;
}
.usa-table th[data-sortable]:not([aria-sort]) .usa-table__header__button:hover .usa-icon > g.unsorted,
.usa-table th[data-sortable][aria-sort=none] .usa-table__header__button:hover .usa-icon > g.unsorted {
  fill: black;
}
.usa-table th[data-sortable][aria-sort=descending],
.usa-table th[data-sortable][aria-sort=ascending] {
  background-color: #52daf2;
}
.usa-table th[data-sortable][aria-sort=descending] .usa-table__header__button {
  color: #046B99;
  text-decoration: underline;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  font-weight: normal;
  justify-content: normal;
  text-align: left;
  margin: 0;
  padding: 0;
  width: auto;
  height: 2rem;
  width: 2rem;
  background-position: center center;
  background-size: 1.5rem;
  color: #71767a;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  position: absolute;
  right: 0.25rem;
  text-align: center;
  text-decoration: none;
  top: 50%;
  transform: translate(0, -50%);
}
.usa-table th[data-sortable][aria-sort=descending] .usa-table__header__button:visited {
  color: #54278f;
}
.usa-table th[data-sortable][aria-sort=descending] .usa-table__header__button:hover {
  color: #0b4778;
}
.usa-table th[data-sortable][aria-sort=descending] .usa-table__header__button:active {
  color: #1C304A;
}
.usa-table th[data-sortable][aria-sort=descending] .usa-table__header__button:focus {
  outline: 0.25rem solid #2491ff;
  outline-offset: 0rem;
}
.usa-table th[data-sortable][aria-sort=descending] .usa-table__header__button:hover,
.usa-table th[data-sortable][aria-sort=descending] .usa-table__header__button.usa-button--hover,
.usa-table th[data-sortable][aria-sort=descending] .usa-table__header__button:disabled:hover,
.usa-table th[data-sortable][aria-sort=descending] .usa-table__header__button[aria-disabled=true]:hover,
.usa-table th[data-sortable][aria-sort=descending] .usa-table__header__button:disabled.usa-button--hover,
.usa-table th[data-sortable][aria-sort=descending] .usa-table__header__button[aria-disabled=true].usa-button--hover,
.usa-table th[data-sortable][aria-sort=descending] .usa-table__header__button:active,
.usa-table th[data-sortable][aria-sort=descending] .usa-table__header__button.usa-button--active,
.usa-table th[data-sortable][aria-sort=descending] .usa-table__header__button:disabled:active,
.usa-table th[data-sortable][aria-sort=descending] .usa-table__header__button[aria-disabled=true]:active,
.usa-table th[data-sortable][aria-sort=descending] .usa-table__header__button:disabled.usa-button--active,
.usa-table th[data-sortable][aria-sort=descending] .usa-table__header__button[aria-disabled=true].usa-button--active,
.usa-table th[data-sortable][aria-sort=descending] .usa-table__header__button:disabled:focus,
.usa-table th[data-sortable][aria-sort=descending] .usa-table__header__button[aria-disabled=true]:focus,
.usa-table th[data-sortable][aria-sort=descending] .usa-table__header__button:disabled.usa-focus,
.usa-table th[data-sortable][aria-sort=descending] .usa-table__header__button[aria-disabled=true].usa-focus,
.usa-table th[data-sortable][aria-sort=descending] .usa-table__header__button:disabled,
.usa-table th[data-sortable][aria-sort=descending] .usa-table__header__button[aria-disabled=true],
.usa-table th[data-sortable][aria-sort=descending] .usa-table__header__button.usa-button--disabled {
  background-color: transparent;
  box-shadow: none;
  text-decoration: underline;
}
.usa-table th[data-sortable][aria-sort=descending] .usa-table__header__button.usa-button--hover {
  color: #0b4778;
}
.usa-table th[data-sortable][aria-sort=descending] .usa-table__header__button.usa-button--active {
  color: #1C304A;
}
.usa-table th[data-sortable][aria-sort=descending] .usa-table__header__button:disabled,
.usa-table th[data-sortable][aria-sort=descending] .usa-table__header__button[aria-disabled=true],
.usa-table th[data-sortable][aria-sort=descending] .usa-table__header__button:disabled:hover,
.usa-table th[data-sortable][aria-sort=descending] .usa-table__header__button[aria-disabled=true]:hover,
.usa-table th[data-sortable][aria-sort=descending] .usa-table__header__button[aria-disabled=true]:focus {
  color: #757575;
}
@media (forced-colors: active) {
  .usa-table th[data-sortable][aria-sort=descending] .usa-table__header__button:disabled,
  .usa-table th[data-sortable][aria-sort=descending] .usa-table__header__button[aria-disabled=true],
  .usa-table th[data-sortable][aria-sort=descending] .usa-table__header__button:disabled:hover,
  .usa-table th[data-sortable][aria-sort=descending] .usa-table__header__button[aria-disabled=true]:hover,
  .usa-table th[data-sortable][aria-sort=descending] .usa-table__header__button[aria-disabled=true]:focus {
    color: GrayText;
  }
}
.usa-table th[data-sortable][aria-sort=descending] .usa-table__header__button .usa-icon {
  height: 1.5rem;
  width: 1.5rem;
  vertical-align: middle;
}
.usa-table th[data-sortable][aria-sort=descending] .usa-table__header__button .usa-icon > g {
  fill: transparent;
}
.usa-table th[data-sortable][aria-sort=descending] .usa-table__header__button .usa-icon > g.descending {
  fill: #11181d;
}
.usa-table th[data-sortable][aria-sort=ascending] .usa-table__header__button {
  color: #046B99;
  text-decoration: underline;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  font-weight: normal;
  justify-content: normal;
  text-align: left;
  margin: 0;
  padding: 0;
  width: auto;
  height: 2rem;
  width: 2rem;
  background-position: center center;
  background-size: 1.5rem;
  color: #71767a;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  position: absolute;
  right: 0.25rem;
  text-align: center;
  text-decoration: none;
  top: 50%;
  transform: translate(0, -50%);
}
.usa-table th[data-sortable][aria-sort=ascending] .usa-table__header__button:visited {
  color: #54278f;
}
.usa-table th[data-sortable][aria-sort=ascending] .usa-table__header__button:hover {
  color: #0b4778;
}
.usa-table th[data-sortable][aria-sort=ascending] .usa-table__header__button:active {
  color: #1C304A;
}
.usa-table th[data-sortable][aria-sort=ascending] .usa-table__header__button:focus {
  outline: 0.25rem solid #2491ff;
  outline-offset: 0rem;
}
.usa-table th[data-sortable][aria-sort=ascending] .usa-table__header__button:hover,
.usa-table th[data-sortable][aria-sort=ascending] .usa-table__header__button.usa-button--hover,
.usa-table th[data-sortable][aria-sort=ascending] .usa-table__header__button:disabled:hover,
.usa-table th[data-sortable][aria-sort=ascending] .usa-table__header__button[aria-disabled=true]:hover,
.usa-table th[data-sortable][aria-sort=ascending] .usa-table__header__button:disabled.usa-button--hover,
.usa-table th[data-sortable][aria-sort=ascending] .usa-table__header__button[aria-disabled=true].usa-button--hover,
.usa-table th[data-sortable][aria-sort=ascending] .usa-table__header__button:active,
.usa-table th[data-sortable][aria-sort=ascending] .usa-table__header__button.usa-button--active,
.usa-table th[data-sortable][aria-sort=ascending] .usa-table__header__button:disabled:active,
.usa-table th[data-sortable][aria-sort=ascending] .usa-table__header__button[aria-disabled=true]:active,
.usa-table th[data-sortable][aria-sort=ascending] .usa-table__header__button:disabled.usa-button--active,
.usa-table th[data-sortable][aria-sort=ascending] .usa-table__header__button[aria-disabled=true].usa-button--active,
.usa-table th[data-sortable][aria-sort=ascending] .usa-table__header__button:disabled:focus,
.usa-table th[data-sortable][aria-sort=ascending] .usa-table__header__button[aria-disabled=true]:focus,
.usa-table th[data-sortable][aria-sort=ascending] .usa-table__header__button:disabled.usa-focus,
.usa-table th[data-sortable][aria-sort=ascending] .usa-table__header__button[aria-disabled=true].usa-focus,
.usa-table th[data-sortable][aria-sort=ascending] .usa-table__header__button:disabled,
.usa-table th[data-sortable][aria-sort=ascending] .usa-table__header__button[aria-disabled=true],
.usa-table th[data-sortable][aria-sort=ascending] .usa-table__header__button.usa-button--disabled {
  background-color: transparent;
  box-shadow: none;
  text-decoration: underline;
}
.usa-table th[data-sortable][aria-sort=ascending] .usa-table__header__button.usa-button--hover {
  color: #0b4778;
}
.usa-table th[data-sortable][aria-sort=ascending] .usa-table__header__button.usa-button--active {
  color: #1C304A;
}
.usa-table th[data-sortable][aria-sort=ascending] .usa-table__header__button:disabled,
.usa-table th[data-sortable][aria-sort=ascending] .usa-table__header__button[aria-disabled=true],
.usa-table th[data-sortable][aria-sort=ascending] .usa-table__header__button:disabled:hover,
.usa-table th[data-sortable][aria-sort=ascending] .usa-table__header__button[aria-disabled=true]:hover,
.usa-table th[data-sortable][aria-sort=ascending] .usa-table__header__button[aria-disabled=true]:focus {
  color: #757575;
}
@media (forced-colors: active) {
  .usa-table th[data-sortable][aria-sort=ascending] .usa-table__header__button:disabled,
  .usa-table th[data-sortable][aria-sort=ascending] .usa-table__header__button[aria-disabled=true],
  .usa-table th[data-sortable][aria-sort=ascending] .usa-table__header__button:disabled:hover,
  .usa-table th[data-sortable][aria-sort=ascending] .usa-table__header__button[aria-disabled=true]:hover,
  .usa-table th[data-sortable][aria-sort=ascending] .usa-table__header__button[aria-disabled=true]:focus {
    color: GrayText;
  }
}
.usa-table th[data-sortable][aria-sort=ascending] .usa-table__header__button .usa-icon {
  height: 1.5rem;
  width: 1.5rem;
  vertical-align: middle;
}
.usa-table th[data-sortable][aria-sort=ascending] .usa-table__header__button .usa-icon > g {
  fill: transparent;
}
.usa-table th[data-sortable][aria-sort=ascending] .usa-table__header__button .usa-icon > g.ascending {
  fill: #11181d;
}
.usa-table thead th[aria-sort] {
  background-color: #52daf2;
  color: #11181d;
}
.usa-table td[data-sort-active],
.usa-table th[data-sort-active] {
  background-color: #a8f2ff;
  color: #11181d;
}
.usa-table--borderless {
}
.usa-table--borderless th,
.usa-table--borderless thead td,
.usa-table--borderless tfoot td {
  background-color: white;
}
.usa-table--borderless th {
  border-top: 0;
  color: #11181d;
}
.usa-table--borderless th[aria-sort] {
  color: #11181d;
}
.usa-table--borderless th[data-sortable]:not([aria-sort]) .usa-table__header__button .usa-icon > g.unsorted {
  fill: #11181d;
}
.usa-table--borderless th[data-sortable]:not([aria-sort]) .usa-table__header__button:hover .usa-icon > g.unsorted {
  fill: black;
}
.usa-table--borderless th,
.usa-table--borderless td {
  border-left: 0;
  border-right: 0;
}
.usa-table--compact th,
.usa-table--compact td {
  padding: 0.25rem 0.75rem;
}
.usa-table--striped tbody tr:nth-child(odd) td {
  background-color: #f0f0f0;
  color: #11181d;
}
.usa-table--striped tbody tr:nth-child(odd) td[data-sort-active] {
  background-color: #c3ebfa;
  color: #11181d;
}
@media all and (max-width: 29.99em) {
  .usa-table--stacked thead {
    display: none;
  }
  .usa-table--stacked th {
    background-color: white;
  }
  .usa-table--stacked th,
  .usa-table--stacked td {
    border-bottom-width: 0;
    display: block;
    width: 100%;
  }
  .usa-table--stacked tr {
    border-bottom: 0.25rem solid #11181d;
    border-top-width: 0;
    width: 100%;
  }
  .usa-table--stacked tr th:first-child,
  .usa-table--stacked tr td:first-child {
    border-top-width: 0;
  }
  .usa-table--stacked tr:nth-child(odd) td,
  .usa-table--stacked tr:nth-child(odd) th {
    background-color: inherit;
  }
  .usa-table--stacked tr:first-child th:first-child,
  .usa-table--stacked tr:first-child td:first-child {
    border-top: 0.25rem solid #11181d;
  }
  .usa-table--stacked th[data-label],
  .usa-table--stacked td[data-label] {
    padding-bottom: 0.75rem;
  }
  .usa-table--stacked th[data-label]:before,
  .usa-table--stacked td[data-label]:before {
    content: attr(data-label);
    display: block;
    font-weight: 700;
    margin: -0.5rem -1rem 0rem;
    padding: 0.75rem 1rem 0.25rem;
  }
}
@media all and (max-width: 29.99em) {
  .usa-table--stacked-header thead {
    display: none;
  }
  .usa-table--stacked-header th {
    background-color: white;
  }
  .usa-table--stacked-header th,
  .usa-table--stacked-header td {
    border-bottom-width: 0;
    display: block;
    width: 100%;
  }
  .usa-table--stacked-header tr {
    border-bottom: 0.25rem solid #11181d;
    border-top-width: 0;
    width: 100%;
  }
  .usa-table--stacked-header tr th:first-child,
  .usa-table--stacked-header tr td:first-child {
    border-top-width: 0;
  }
  .usa-table--stacked-header tr:nth-child(odd) td,
  .usa-table--stacked-header tr:nth-child(odd) th {
    background-color: inherit;
  }
  .usa-table--stacked-header tr:first-child th:first-child,
  .usa-table--stacked-header tr:first-child td:first-child {
    border-top: 0.25rem solid #11181d;
  }
  .usa-table--stacked-header th[data-label],
  .usa-table--stacked-header td[data-label] {
    padding-bottom: 0.75rem;
  }
  .usa-table--stacked-header th[data-label]:before,
  .usa-table--stacked-header td[data-label]:before {
    content: attr(data-label);
    display: block;
    font-weight: 700;
    margin: -0.5rem -1rem 0rem;
    padding: 0.75rem 1rem 0.25rem;
  }
  .usa-table--stacked-header tr td:first-child,
  .usa-table--stacked-header tr th:first-child {
    font-family:
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
    font-size: 1.01rem;
    line-height: 1.2;
    background-color: #f0f0f0;
    color: #11181d;
    font-weight: 700;
    padding: 0.75rem 1rem;
  }
  .usa-table--stacked-header tr td:first-child:before,
  .usa-table--stacked-header tr th:first-child:before {
    display: none;
  }
}
.width-mobile .usa-table--stacked thead {
  display: none;
}
.width-mobile .usa-table--stacked th {
  background-color: white;
}
.width-mobile .usa-table--stacked th,
.width-mobile .usa-table--stacked td {
  border-bottom-width: 0;
  display: block;
  width: 100%;
}
.width-mobile .usa-table--stacked tr {
  border-bottom: 0.25rem solid #11181d;
  border-top-width: 0;
  width: 100%;
}
.width-mobile .usa-table--stacked tr th:first-child,
.width-mobile .usa-table--stacked tr td:first-child {
  border-top-width: 0;
}
.width-mobile .usa-table--stacked tr:nth-child(odd) td,
.width-mobile .usa-table--stacked tr:nth-child(odd) th {
  background-color: inherit;
}
.width-mobile .usa-table--stacked tr:first-child th:first-child,
.width-mobile .usa-table--stacked tr:first-child td:first-child {
  border-top: 0.25rem solid #11181d;
}
.width-mobile .usa-table--stacked th[data-label],
.width-mobile .usa-table--stacked td[data-label] {
  padding-bottom: 0.75rem;
}
.width-mobile .usa-table--stacked th[data-label]:before,
.width-mobile .usa-table--stacked td[data-label]:before {
  content: attr(data-label);
  display: block;
  font-weight: 700;
  margin: -0.5rem -1rem 0rem;
  padding: 0.75rem 1rem 0.25rem;
}
.width-mobile .usa-table--stacked-header thead {
  display: none;
}
.width-mobile .usa-table--stacked-header th {
  background-color: white;
}
.width-mobile .usa-table--stacked-header th,
.width-mobile .usa-table--stacked-header td {
  border-bottom-width: 0;
  display: block;
  width: 100%;
}
.width-mobile .usa-table--stacked-header tr {
  border-bottom: 0.25rem solid #11181d;
  border-top-width: 0;
  width: 100%;
}
.width-mobile .usa-table--stacked-header tr th:first-child,
.width-mobile .usa-table--stacked-header tr td:first-child {
  border-top-width: 0;
}
.width-mobile .usa-table--stacked-header tr:nth-child(odd) td,
.width-mobile .usa-table--stacked-header tr:nth-child(odd) th {
  background-color: inherit;
}
.width-mobile .usa-table--stacked-header tr:first-child th:first-child,
.width-mobile .usa-table--stacked-header tr:first-child td:first-child {
  border-top: 0.25rem solid #11181d;
}
.width-mobile .usa-table--stacked-header th[data-label],
.width-mobile .usa-table--stacked-header td[data-label] {
  padding-bottom: 0.75rem;
}
.width-mobile .usa-table--stacked-header th[data-label]:before,
.width-mobile .usa-table--stacked-header td[data-label]:before {
  content: attr(data-label);
  display: block;
  font-weight: 700;
  margin: -0.5rem -1rem 0rem;
  padding: 0.75rem 1rem 0.25rem;
}
.width-mobile .usa-table--stacked-header tr td:first-child,
.width-mobile .usa-table--stacked-header tr th:first-child {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.01rem;
  line-height: 1.2;
  background-color: #f0f0f0;
  color: #11181d;
  font-weight: 700;
  padding: 0.75rem 1rem;
}
.width-mobile .usa-table--stacked-header tr td:first-child:before,
.width-mobile .usa-table--stacked-header tr th:first-child:before {
  display: none;
}
.usa-table--sticky-header {
  border: 1px solid #11181d;
  border-collapse: separate;
}
.usa-table--sticky-header td,
.usa-table--sticky-header th {
  border-left: none;
  border-top: none;
}
.usa-table--sticky-header td:last-child,
.usa-table--sticky-header th:last-child {
  border-right: none;
}
.usa-table--sticky-header tfoot td,
.usa-table--sticky-header tfoot th {
  border-top: 1px solid #11181d;
  border-bottom: none;
}
.usa-table--sticky-header tbody tr:last-child td,
.usa-table--sticky-header tbody tr:last-child th {
  border-bottom: none;
}
.usa-table--sticky-header thead {
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
}
.usa-table--sticky-header.usa-table--borderless {
  border: none;
}
.usa-table--sticky-header.usa-table--borderless th {
  background-color: white;
}
.usa-table--sticky-header.usa-table--borderless td,
.usa-table--sticky-header.usa-table--borderless th {
  border-right: none;
}
.usa-table-container--scrollable {
  margin: 1.25rem 0;
  overflow-y: hidden;
}
.usa-table-container--scrollable .usa-table {
  margin: 0;
}
.usa-table-container--scrollable td {
  white-space: nowrap;
}
.usa-tag {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 0.89rem;
  color: white;
  text-transform: uppercase;
  background-color: #565c65;
  border-radius: 2px;
  margin-right: 0.25rem;
  padding: 1px 0.5rem;
}
.usa-tag:only-of-type {
  margin-right: 0;
}
.usa-tag--big {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.08rem;
}
.usa-tooltip {
  display: inline-block;
  position: relative;
}
.usa-tooltip__trigger {
  cursor: pointer;
}
.usa-tooltip__trigger > svg {
  display: block;
  pointer-events: none;
}
.usa-tooltip__body,
.usa-tooltip__body--top {
  transition: opacity 0.08s ease-in-out;
  background-color: #11181d;
  border-radius: 0.25rem;
  color: #f0f0f0;
  display: none;
  font-size: 0.95rem;
  opacity: 0;
  padding: 0.5rem;
  width: auto;
  white-space: pre;
  z-index: 100000;
  position: absolute;
}
.usa-tooltip__body::after,
.usa-tooltip__body--top::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #11181d;
  position: absolute;
  bottom: -5px;
  left: 50%;
  margin-left: -5px;
}
.usa-tooltip__body::before,
.usa-tooltip__body--top::before {
  content: "";
  display: block;
  position: absolute;
}
.usa-tooltip__body--wrap {
  width: 100%;
  white-space: normal;
  text-align: center;
  min-width: 50vw;
}
.usa-tooltip__body.is-set {
  display: block;
}
.usa-tooltip__body.is-visible {
  opacity: 1;
}
.usa-tooltip__body--top::before {
  top: 100%;
  height: 5px;
  left: 0;
  right: 0;
}
.usa-tooltip__body--bottom::before {
  bottom: 100%;
  height: 5px;
  left: 0;
  right: 0;
}
.usa-tooltip__body--bottom::after {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #11181d;
  border-top: 0;
  bottom: auto;
  top: -5px;
}
.usa-tooltip__body--right::before {
  right: 100%;
  bottom: 0;
  top: 0;
  width: 5px;
}
.usa-tooltip__body--right::after {
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 5px solid #11181d;
  border-left: 0;
  right: auto;
  top: 50%;
  bottom: 0;
  left: -5px;
  margin: -5px 0 0 0;
}
.usa-tooltip__body--left::before {
  left: 100%;
  bottom: 0;
  top: 0;
  width: 5px;
}
.usa-tooltip__body--left::after {
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #11181d;
  border-right: 0;
  right: -5px;
  top: 50%;
  bottom: 0;
  left: auto;
  margin: -5px 0 0 0;
}
.usa-character-count__status {
  display: inline-block;
  padding-top: 0.25rem;
}
.usa-character-count__status.usa-character-count__status--invalid {
  color: #b50909;
  font-weight: 700;
}
.usa-input:disabled,
.usa-input[aria-disabled=true] {
  color: #454545;
  background-color: #c9c9c9;
  cursor: not-allowed;
  opacity: 1;
  -webkit-text-fill-color: #454545;
}
.usa-input:disabled:hover,
.usa-input:disabled:active,
.usa-input:disabled:focus,
.usa-input:disabled.usa-focus,
.usa-input[aria-disabled=true]:hover,
.usa-input[aria-disabled=true]:active,
.usa-input[aria-disabled=true]:focus,
.usa-input[aria-disabled=true].usa-focus {
  color: #454545;
  background-color: #c9c9c9;
}
@media (forced-colors: active) {
  .usa-input:disabled,
  .usa-input[aria-disabled=true] {
    border: 0;
    color: GrayText;
  }
  .usa-input:disabled:hover,
  .usa-input:disabled:active,
  .usa-input:disabled:focus,
  .usa-input:disabled.usa-focus,
  .usa-input[aria-disabled=true]:hover,
  .usa-input[aria-disabled=true]:active,
  .usa-input[aria-disabled=true]:focus,
  .usa-input[aria-disabled=true].usa-focus {
    color: GrayText;
  }
}
@media (forced-colors: active) {
  .usa-input:disabled,
  .usa-input[aria-disabled=true] {
    border: 2px solid GrayText;
  }
}
.usa-input--2xs,
.usa-form .usa-input--2xs {
  max-width: 5ex;
}
.usa-input--xs,
.usa-form .usa-input--xs {
  max-width: 9ex;
}
.usa-input--sm,
.usa-form .usa-input--sm {
  max-width: 13ex;
}
.usa-input--small,
.usa-form .usa-input--small {
  max-width: 13ex;
}
.usa-input--md,
.usa-form .usa-input--md {
  max-width: 20ex;
}
.usa-input--medium,
.usa-form .usa-input--medium {
  max-width: 20ex;
}
.usa-input--lg,
.usa-form .usa-input--lg {
  max-width: 30ex;
}
.usa-input--xl,
.usa-form .usa-input--xl {
  max-width: 40ex;
}
.usa-input--2xl,
.usa-form .usa-input--2xl {
  max-width: 50ex;
}
.usa-input--error {
  border-width: 0.25rem;
  border-color: #b50909;
  border-style: solid;
  padding-top: calc(0.5rem - 0.25rem);
  padding-bottom: calc(0.5rem - 0.25rem);
}
.usa-input--success {
  border-width: 0.25rem;
  border-color: #00a91c;
  border-style: solid;
  padding-top: calc(0.5rem - 0.25rem);
  padding-bottom: calc(0.5rem - 0.25rem);
}
.usa-fieldset {
  border: none;
  margin: 0;
  padding: 0;
}
.usa-legend {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.08rem;
  line-height: 1.3;
  display: block;
  font-weight: normal;
  margin-top: 1.5rem;
  max-width: 30rem;
}
.usa-legend--large {
  font-size: 2.03rem;
  font-weight: 700;
  margin-top: 1rem;
}
.usa-input-list {
  margin-bottom: 0;
  margin-top: 0;
  list-style-type: none;
  padding-left: 0;
}
.usa-input-list li {
  line-height: 1.3;
}
.usa-prose .usa-input-list {
  margin-bottom: 0;
  margin-top: 0;
  list-style-type: none;
  padding-left: 0;
}
.usa-prose .usa-input-list li {
  line-height: 1.3;
}
.usa-checkbox {
  background: white;
}
.usa-checkbox__label {
  color: #11181d;
}
.usa-checkbox__label::before {
  background: white;
  box-shadow: 0 0 0 2px #11181d;
}
@media (forced-colors: active) {
  .usa-checkbox__label::before {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
}
.usa-checkbox__input:checked + [class*=__label]::before {
  background-color: #046B99;
  box-shadow: 0 0 0 2px #046B99;
}
.usa-checkbox__input:disabled + [class*=__label],
.usa-checkbox__input[aria-disabled=true] + [class*=__label] {
  color: #757575;
  cursor: not-allowed;
}
@media (forced-colors: active) {
  .usa-checkbox__input:disabled + [class*=__label],
  .usa-checkbox__input[aria-disabled=true] + [class*=__label] {
    color: GrayText;
  }
}
.usa-checkbox__input:disabled + [class*=__label]::before,
.usa-checkbox__input[aria-disabled=true] + [class*=__label]::before {
  background-color: white;
  box-shadow: 0 0 0 2px #757575;
}
.usa-checkbox__input--tile + [class*=__label] {
  background-color: white;
  border: 2px solid #c9c9c9;
  color: #11181d;
}
.usa-checkbox__input--tile:checked + [class*=__label] {
  background-color: rgba(4, 107, 153, 0.1);
  border-color: #046B99;
}
@media (forced-colors: active) {
  .usa-checkbox__input--tile:checked + [class*=__label] {
    border: ButtonText solid 0.25rem;
  }
}
.usa-checkbox__input--tile:disabled + [class*=__label],
.usa-checkbox__input--tile[aria-disabled=true] + [class*=__label] {
  border-color: #e6e6e6;
}
.usa-checkbox__input--tile:disabled:checked + [class*=__label],
.usa-checkbox__input--tile:disabled:indeterminate + [class*=__label],
.usa-checkbox__input--tile:disabled[data-indeterminate] + [class*=__label],
.usa-checkbox__input--tile[aria-disabled=true]:checked + [class*=__label],
.usa-checkbox__input--tile[aria-disabled=true]:indeterminate + [class*=__label],
.usa-checkbox__input--tile[aria-disabled=true][data-indeterminate] + [class*=__label] {
  background-color: white;
}
.usa-checkbox__input:indeterminate + [class*=__label]::before,
.usa-checkbox__input[data-indeterminate] + [class*=__label]::before {
  background-image: url("../checkbox-indeterminate-Y35KS6LF.svg"), linear-gradient(transparent, transparent);
  background-repeat: no-repeat;
  background-color: #046B99;
  box-shadow: 0 0 0 2px #046B99;
  background-position: center center;
  background-size: 0.75rem auto;
}
@media (forced-colors: active) {
  .usa-checkbox__input:indeterminate + [class*=__label]::before,
  .usa-checkbox__input[data-indeterminate] + [class*=__label]::before {
    background-image: url("../checkbox-indeterminate-alt-4GYPE6XN.svg"), linear-gradient(transparent, transparent);
    background-repeat: no-repeat;
    background-color: SelectedItem;
  }
}
.usa-checkbox__input:indeterminate:disabled + [class*=__label]::before,
.usa-checkbox__input:indeterminate[aria-disabled=true] + [class*=__label]::before,
.usa-checkbox__input[data-indeterminate]:disabled + [class*=__label]::before,
.usa-checkbox__input[data-indeterminate][aria-disabled=true] + [class*=__label]::before {
  box-shadow: 0 0 0 2px #757575;
}
.usa-checkbox__input:indeterminate:disabled + [class*=__label],
.usa-checkbox__input:indeterminate[aria-disabled=true] + [class*=__label],
.usa-checkbox__input[data-indeterminate]:disabled + [class*=__label],
.usa-checkbox__input[data-indeterminate][aria-disabled=true] + [class*=__label] {
  border-color: #e6e6e6;
}
.usa-checkbox__input--tile:indeterminate + [class*=__label],
.usa-checkbox__input--tile[data-indeterminate] + [class*=__label] {
  background-color: rgba(4, 107, 153, 0.1);
  border-color: #046B99;
}
@media (forced-colors: active) {
  .usa-checkbox__input--tile:indeterminate + [class*=__label],
  .usa-checkbox__input--tile[data-indeterminate] + [class*=__label] {
    border: ButtonText solid 0.25rem;
  }
}
.usa-checkbox__input:checked + [class*=__label]::before,
.usa-checkbox__input:checked:disabled + [class*=__label]::before,
.usa-checkbox__input:checked[aria-disabled=true] + [class*=__label]::before {
  background-image: url("../correct8-BW6WT7U2.svg"), linear-gradient(transparent, transparent);
  background-repeat: no-repeat;
}
@media (forced-colors: active) {
  .usa-checkbox__input:checked + [class*=__label]::before,
  .usa-checkbox__input:checked:disabled + [class*=__label]::before,
  .usa-checkbox__input:checked[aria-disabled=true] + [class*=__label]::before {
    background-image: url("../correct8-alt-M4H3GT6Q.svg"), linear-gradient(transparent, transparent);
    background-repeat: no-repeat;
  }
}
.usa-checkbox__input:checked:disabled + [class*=__label]::before,
.usa-checkbox__input:checked[aria-disabled=true] + [class*=__label]::before,
.usa-checkbox__input:indeterminate:disabled + [class*=__label]::before,
.usa-checkbox__input:indeterminate[aria-disabled=true] + [class*=__label]::before,
.usa-checkbox__input[data-indeterminate]:disabled + [class*=__label]::before,
.usa-checkbox__input[data-indeterminate][aria-disabled=true] + [class*=__label]::before {
  background-color: #757575;
}
@media (forced-colors: active) {
  .usa-checkbox__input:checked:disabled + [class*=__label]::before,
  .usa-checkbox__input:checked[aria-disabled=true] + [class*=__label]::before,
  .usa-checkbox__input:indeterminate:disabled + [class*=__label]::before,
  .usa-checkbox__input:indeterminate[aria-disabled=true] + [class*=__label]::before,
  .usa-checkbox__input[data-indeterminate]:disabled + [class*=__label]::before,
  .usa-checkbox__input[data-indeterminate][aria-disabled=true] + [class*=__label]::before {
    background-color: GrayText;
  }
}
.usa-checkbox__input {
  position: absolute;
  left: -999em;
  right: auto;
}
.usa-checkbox__input:focus + [class*=__label]::before {
  outline: 0.25rem solid #2491ff;
  outline-offset: 0.25rem;
}
.usa-checkbox__input--tile + [class*=__label] {
  border-radius: 0.25rem;
  margin-top: 0.5rem;
  padding: 0.75rem 1rem 0.75rem 2.5rem;
  display: inherit;
}
.usa-checkbox__input--tile + [class*=__label]::before {
  left: 0.5rem;
}
.usa-checkbox__input:checked + [class*=__label]::before {
  background-position: center center;
  background-size: 0.75rem auto;
}
@media print {
  .usa-checkbox__input:checked + [class*=__label]::before {
    background-image: none;
    background-color: white;
    content: "\2714";
    text-align: center;
  }
}
@media (forced-colors: active) {
  .usa-checkbox__input:checked + [class*=__label]::before {
    background-color: SelectedItem;
  }
}
.usa-checkbox__label {
  cursor: pointer;
  display: inline-block;
  font-weight: normal;
  margin-top: 0.75rem;
  padding-left: 2rem;
  position: relative;
}
.usa-checkbox__label::before {
  content: " ";
  display: block;
  left: 0;
  margin-left: 2px;
  margin-top: 0.077rem;
  position: absolute;
}
.usa-checkbox__label::before {
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 2px;
}
.usa-checkbox__label-description {
  display: block;
  font-size: 0.89rem;
  margin-top: 0.5rem;
}
.usa-select {
  background-image: url("../unfold_more-WL443JWS.svg"), linear-gradient(transparent, transparent);
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: white;
  background-position: right 0.5rem center;
  background-size: 1.25rem;
  padding-right: 2rem;
}
.usa-select::-ms-expand {
  display: none;
}
.usa-select:-webkit-autofill {
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  appearance: menulist;
}
.usa-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 black;
}
.usa-select[multiple] {
  height: auto;
  background-image: none;
  padding-right: 0;
}
.usa-select option {
  overflow: hidden;
  text-overflow: ellipsis;
}
.usa-select:disabled,
.usa-select[aria-disabled=true] {
  color: #454545;
  background-color: #c9c9c9;
  cursor: not-allowed;
  opacity: 1;
}
.usa-select:disabled:hover,
.usa-select:disabled:active,
.usa-select:disabled:focus,
.usa-select:disabled.usa-focus,
.usa-select[aria-disabled=true]:hover,
.usa-select[aria-disabled=true]:active,
.usa-select[aria-disabled=true]:focus,
.usa-select[aria-disabled=true].usa-focus {
  color: #454545;
  background-color: #c9c9c9;
}
@media (forced-colors: active) {
  .usa-select:disabled,
  .usa-select[aria-disabled=true] {
    border: 0;
    color: GrayText;
  }
  .usa-select:disabled:hover,
  .usa-select:disabled:active,
  .usa-select:disabled:focus,
  .usa-select:disabled.usa-focus,
  .usa-select[aria-disabled=true]:hover,
  .usa-select[aria-disabled=true]:active,
  .usa-select[aria-disabled=true]:focus,
  .usa-select[aria-disabled=true].usa-focus {
    color: GrayText;
  }
}
@media (forced-colors: active) {
  .usa-select:disabled,
  .usa-select[aria-disabled=true] {
    border: 2px solid GrayText;
  }
}
@media (forced-colors: active) {
  .usa-select {
    -webkit-appearance: listbox;
    -moz-appearance: listbox;
    appearance: listbox;
    background-image: none;
    padding-right: 0;
  }
}
.usa-combo-box {
  max-width: 30rem;
  position: relative;
}
.usa-combo-box--pristine .usa-combo-box__input {
  padding-right: calc(5em + 4px);
}
.usa-combo-box--pristine .usa-combo-box__input::-ms-clear {
  display: none;
}
.usa-combo-box--pristine .usa-combo-box__clear-input {
  display: block;
}
@media (forced-colors: active) {
  .usa-combo-box--pristine .usa-combo-box__clear-input {
    background-image: url("../close-D6X4FKTZ.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 1rem 1rem;
    display: inline-block;
    height: 1rem;
    width: 1rem;
    height: 1.5rem;
    width: auto;
    top: 0.5rem;
  }
  @supports (mask: url()) {
    .usa-combo-box--pristine .usa-combo-box__clear-input {
      background: none;
      background-color: ButtonText;
      -webkit-mask-image: url("../close-D6X4FKTZ.svg"), linear-gradient(transparent, transparent);
      mask-image: url("../close-D6X4FKTZ.svg"), linear-gradient(transparent, transparent);
      -webkit-mask-position: center center;
      mask-position: center center;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-size: 1rem 1rem;
      mask-size: 1rem 1rem;
    }
  }
}
.usa-combo-box__input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-bottom: 0;
  max-width: none;
  padding-right: calc(2.5em + 3px);
}
.usa-combo-box__input:disabled,
.usa-combo-box__input[aria-disabled=true] {
  color: #454545;
  background-color: #c9c9c9;
  cursor: not-allowed;
  opacity: 1;
  -webkit-text-fill-color: #454545;
}
.usa-combo-box__input:disabled:hover,
.usa-combo-box__input:disabled:active,
.usa-combo-box__input:disabled:focus,
.usa-combo-box__input:disabled.usa-focus,
.usa-combo-box__input[aria-disabled=true]:hover,
.usa-combo-box__input[aria-disabled=true]:active,
.usa-combo-box__input[aria-disabled=true]:focus,
.usa-combo-box__input[aria-disabled=true].usa-focus {
  color: #454545;
  background-color: #c9c9c9;
}
@media (forced-colors: active) {
  .usa-combo-box__input:disabled,
  .usa-combo-box__input[aria-disabled=true] {
    border: 0;
    color: GrayText;
  }
  .usa-combo-box__input:disabled:hover,
  .usa-combo-box__input:disabled:active,
  .usa-combo-box__input:disabled:focus,
  .usa-combo-box__input:disabled.usa-focus,
  .usa-combo-box__input[aria-disabled=true]:hover,
  .usa-combo-box__input[aria-disabled=true]:active,
  .usa-combo-box__input[aria-disabled=true]:focus,
  .usa-combo-box__input[aria-disabled=true].usa-focus {
    color: GrayText;
  }
}
@media (forced-colors: active) {
  .usa-combo-box__input:disabled,
  .usa-combo-box__input[aria-disabled=true] {
    border: 2px solid GrayText;
  }
}
.usa-combo-box__input:disabled::placeholder,
.usa-combo-box__input[aria-disabled=true]::placeholder {
  opacity: 1;
}
.usa-combo-box__input:disabled ~ .usa-combo-box__input-button-separator,
.usa-combo-box__input[aria-disabled=true] ~ .usa-combo-box__input-button-separator {
  background-color: #454545;
  cursor: not-allowed;
}
button.usa-combo-box__toggle-list:focus,
button.usa-combo-box__clear-input:focus {
  outline-offset: -4px;
}
button.usa-combo-box__toggle-list:disabled,
button.usa-combo-box__toggle-list[aria-disabled=true],
button.usa-combo-box__clear-input:disabled,
button.usa-combo-box__clear-input[aria-disabled=true] {
  cursor: not-allowed;
}
.usa-combo-box__toggle-list__wrapper:focus,
.usa-combo-box__clear-input__wrapper:focus {
  outline: 0;
}
.usa-combo-box__toggle-list,
.usa-combo-box__clear-input {
  background-color: transparent;
  background-position: center;
  background-size: auto 1.5rem;
  border: 0;
  bottom: 1px;
  cursor: pointer;
  margin-bottom: 0;
  opacity: 0.6;
  padding-right: 2rem;
  position: absolute;
  top: 1px;
  z-index: 100;
}
.usa-combo-box__clear-input {
  background-image: url("../close-D6X4FKTZ.svg"), linear-gradient(transparent, transparent);
  background-repeat: no-repeat;
  display: none;
  right: calc(2.5em + 3px);
}
.usa-combo-box__toggle-list {
  background-image: url("../expand_more-S37WEEPD.svg"), linear-gradient(transparent, transparent);
  background-repeat: no-repeat;
  background-size: auto 2rem;
  right: 1px;
}
@media (forced-colors: active) {
  .usa-combo-box__toggle-list {
    background-image: url("../expand_more-S37WEEPD.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 1rem 1rem;
    display: inline-block;
    height: 1rem;
    width: 1rem;
    height: auto;
    width: auto;
  }
  @supports (mask: url()) {
    .usa-combo-box__toggle-list {
      background: none;
      background-color: ButtonText;
      -webkit-mask-image: url("../expand_more-S37WEEPD.svg"), linear-gradient(transparent, transparent);
      mask-image: url("../expand_more-S37WEEPD.svg"), linear-gradient(transparent, transparent);
      -webkit-mask-position: center center;
      mask-position: center center;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-size: 1rem 1rem;
      mask-size: 1rem 1rem;
    }
  }
  .usa-combo-box__toggle-list:disabled,
  .usa-combo-box__toggle-list[aria-disabled=true] {
    background-color: GrayText;
  }
}
.usa-combo-box__input-button-separator {
  background-color: #c6cace;
  position: absolute;
  top: 1px;
  height: calc(100% - 1rem);
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  width: 1px;
  right: calc(2.5em + 2px);
  box-sizing: border-box;
  z-index: 200;
}
@media (forced-colors: active) {
  .usa-combo-box__input-button-separator {
    background-color: ButtonText;
  }
}
.usa-combo-box__list {
  border-width: 1px;
  border-color: #565c65;
  border-style: solid;
  background-color: white;
  border-radius: 0;
  border-top: 0;
  margin: 0;
  max-height: 12.1em;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0;
  position: absolute;
  width: 100%;
  z-index: 300;
}
.usa-combo-box__list:focus {
  outline: 0;
}
.usa-combo-box__list-option {
  border-bottom: 1px solid #dfe1e2;
  cursor: pointer;
  display: block;
  padding: 0.5rem;
}
.usa-combo-box__list-option--focused {
  outline: 2px solid #162e51;
  outline-offset: -2px;
  position: relative;
  z-index: 100;
}
.usa-combo-box__list-option--focused:focus {
  outline-offset: -4px;
}
.usa-combo-box__list-option--selected {
  background-color: #046B99;
  border-color: #046B99;
  color: white;
}
.usa-combo-box__list-option--no-results {
  cursor: not-allowed;
  display: block;
  padding: 0.5rem;
}
.usa-date-picker__wrapper {
  display: none;
  position: relative;
  max-width: 30rem;
}
.usa-date-picker__wrapper:focus {
  outline: 0;
}
.usa-date-picker__external-input[aria-disabled=true] + .usa-date-picker__button,
.usa-date-picker__calendar__year:disabled,
.usa-date-picker__calendar__previous-year-chunk:disabled,
.usa-date-picker__calendar__next-year-chunk:disabled,
.usa-date-picker__calendar__month:disabled,
.usa-date-picker__calendar__year-selection:disabled,
.usa-date-picker__calendar__month-selection:disabled,
.usa-date-picker__calendar__date:disabled,
.usa-date-picker__calendar__previous-year:disabled,
.usa-date-picker__calendar__previous-month:disabled,
.usa-date-picker__calendar__next-year:disabled,
.usa-date-picker__calendar__next-month:disabled,
.usa-date-picker__button:disabled,
[aria-disabled=true].usa-date-picker__calendar__year,
[aria-disabled=true].usa-date-picker__calendar__previous-year-chunk,
[aria-disabled=true].usa-date-picker__calendar__next-year-chunk,
[aria-disabled=true].usa-date-picker__calendar__month,
[aria-disabled=true].usa-date-picker__calendar__year-selection,
[aria-disabled=true].usa-date-picker__calendar__month-selection,
[aria-disabled=true].usa-date-picker__calendar__date,
[aria-disabled=true].usa-date-picker__calendar__previous-year,
[aria-disabled=true].usa-date-picker__calendar__previous-month,
[aria-disabled=true].usa-date-picker__calendar__next-year,
[aria-disabled=true].usa-date-picker__calendar__next-month,
[aria-disabled=true].usa-date-picker__button {
  cursor: not-allowed;
  opacity: 0.6;
}
.usa-date-picker__external-input[aria-disabled=true] + .usa-date-picker__button:hover,
.usa-date-picker__calendar__year:hover:disabled,
.usa-date-picker__calendar__previous-year-chunk:hover:disabled,
.usa-date-picker__calendar__next-year-chunk:hover:disabled,
.usa-date-picker__calendar__month:hover:disabled,
.usa-date-picker__calendar__year-selection:hover:disabled,
.usa-date-picker__calendar__month-selection:hover:disabled,
.usa-date-picker__calendar__date:hover:disabled,
.usa-date-picker__calendar__previous-year:hover:disabled,
.usa-date-picker__calendar__previous-month:hover:disabled,
.usa-date-picker__calendar__next-year:hover:disabled,
.usa-date-picker__calendar__next-month:hover:disabled,
.usa-date-picker__button:hover:disabled,
[aria-disabled=true].usa-date-picker__calendar__year:hover,
[aria-disabled=true].usa-date-picker__calendar__previous-year-chunk:hover,
[aria-disabled=true].usa-date-picker__calendar__next-year-chunk:hover,
[aria-disabled=true].usa-date-picker__calendar__month:hover,
[aria-disabled=true].usa-date-picker__calendar__year-selection:hover,
[aria-disabled=true].usa-date-picker__calendar__month-selection:hover,
[aria-disabled=true].usa-date-picker__calendar__date:hover,
[aria-disabled=true].usa-date-picker__calendar__previous-year:hover,
[aria-disabled=true].usa-date-picker__calendar__previous-month:hover,
[aria-disabled=true].usa-date-picker__calendar__next-year:hover,
[aria-disabled=true].usa-date-picker__calendar__next-month:hover,
[aria-disabled=true].usa-date-picker__button:hover {
  background-color: initial;
}
@media (forced-colors: active) {
  .usa-date-picker__external-input[aria-disabled=true] + .usa-date-picker__button,
  .usa-date-picker__calendar__year:disabled,
  .usa-date-picker__calendar__previous-year-chunk:disabled,
  .usa-date-picker__calendar__next-year-chunk:disabled,
  .usa-date-picker__calendar__month:disabled,
  .usa-date-picker__calendar__year-selection:disabled,
  .usa-date-picker__calendar__month-selection:disabled,
  .usa-date-picker__calendar__date:disabled,
  .usa-date-picker__calendar__previous-year:disabled,
  .usa-date-picker__calendar__previous-month:disabled,
  .usa-date-picker__calendar__next-year:disabled,
  .usa-date-picker__calendar__next-month:disabled,
  .usa-date-picker__button:disabled,
  [aria-disabled=true].usa-date-picker__calendar__year,
  [aria-disabled=true].usa-date-picker__calendar__previous-year-chunk,
  [aria-disabled=true].usa-date-picker__calendar__next-year-chunk,
  [aria-disabled=true].usa-date-picker__calendar__month,
  [aria-disabled=true].usa-date-picker__calendar__year-selection,
  [aria-disabled=true].usa-date-picker__calendar__month-selection,
  [aria-disabled=true].usa-date-picker__calendar__date,
  [aria-disabled=true].usa-date-picker__calendar__previous-year,
  [aria-disabled=true].usa-date-picker__calendar__previous-month,
  [aria-disabled=true].usa-date-picker__calendar__next-year,
  [aria-disabled=true].usa-date-picker__calendar__next-month,
  [aria-disabled=true].usa-date-picker__button {
    background-color: GrayText;
  }
  .usa-date-picker__external-input[aria-disabled=true] + .usa-date-picker__button:hover,
  .usa-date-picker__calendar__year:hover:disabled,
  .usa-date-picker__calendar__previous-year-chunk:hover:disabled,
  .usa-date-picker__calendar__next-year-chunk:hover:disabled,
  .usa-date-picker__calendar__month:hover:disabled,
  .usa-date-picker__calendar__year-selection:hover:disabled,
  .usa-date-picker__calendar__month-selection:hover:disabled,
  .usa-date-picker__calendar__date:hover:disabled,
  .usa-date-picker__calendar__previous-year:hover:disabled,
  .usa-date-picker__calendar__previous-month:hover:disabled,
  .usa-date-picker__calendar__next-year:hover:disabled,
  .usa-date-picker__calendar__next-month:hover:disabled,
  .usa-date-picker__button:hover:disabled,
  [aria-disabled=true].usa-date-picker__calendar__year:hover,
  [aria-disabled=true].usa-date-picker__calendar__previous-year-chunk:hover,
  [aria-disabled=true].usa-date-picker__calendar__next-year-chunk:hover,
  [aria-disabled=true].usa-date-picker__calendar__month:hover,
  [aria-disabled=true].usa-date-picker__calendar__year-selection:hover,
  [aria-disabled=true].usa-date-picker__calendar__month-selection:hover,
  [aria-disabled=true].usa-date-picker__calendar__date:hover,
  [aria-disabled=true].usa-date-picker__calendar__previous-year:hover,
  [aria-disabled=true].usa-date-picker__calendar__previous-month:hover,
  [aria-disabled=true].usa-date-picker__calendar__next-year:hover,
  [aria-disabled=true].usa-date-picker__calendar__next-month:hover,
  [aria-disabled=true].usa-date-picker__button:hover {
    background-color: GrayText;
  }
}
.usa-date-picker__calendar__year,
.usa-date-picker__calendar__previous-year-chunk,
.usa-date-picker__calendar__next-year-chunk,
.usa-date-picker__calendar__month,
.usa-date-picker__calendar__year-selection,
.usa-date-picker__calendar__month-selection,
.usa-date-picker__calendar__date,
.usa-date-picker__calendar__previous-year,
.usa-date-picker__calendar__previous-month,
.usa-date-picker__calendar__next-year,
.usa-date-picker__calendar__next-month,
.usa-date-picker__button {
  background-color: #f0f0f0;
  border: 0;
  width: 100%;
}
.usa-date-picker__calendar__year:not([disabled]),
.usa-date-picker__calendar__previous-year-chunk:not([disabled]),
.usa-date-picker__calendar__next-year-chunk:not([disabled]),
.usa-date-picker__calendar__month:not([disabled]),
.usa-date-picker__calendar__year-selection:not([disabled]),
.usa-date-picker__calendar__month-selection:not([disabled]),
.usa-date-picker__calendar__date:not([disabled]),
.usa-date-picker__calendar__previous-year:not([disabled]),
.usa-date-picker__calendar__previous-month:not([disabled]),
.usa-date-picker__calendar__next-year:not([disabled]),
.usa-date-picker__calendar__next-month:not([disabled]),
.usa-date-picker__button:not([disabled]) {
  cursor: pointer;
}
.usa-date-picker__calendar__year:not([disabled]):focus,
.usa-date-picker__calendar__previous-year-chunk:not([disabled]):focus,
.usa-date-picker__calendar__next-year-chunk:not([disabled]):focus,
.usa-date-picker__calendar__month:not([disabled]):focus,
.usa-date-picker__calendar__year-selection:not([disabled]):focus,
.usa-date-picker__calendar__month-selection:not([disabled]):focus,
.usa-date-picker__calendar__date:not([disabled]):focus,
.usa-date-picker__calendar__previous-year:not([disabled]):focus,
.usa-date-picker__calendar__previous-month:not([disabled]):focus,
.usa-date-picker__calendar__next-year:not([disabled]):focus,
.usa-date-picker__calendar__next-month:not([disabled]):focus,
.usa-date-picker__button:not([disabled]):focus {
  outline-offset: -4px;
}
.usa-date-picker__calendar__year:not([disabled]):hover,
.usa-date-picker__calendar__previous-year-chunk:not([disabled]):hover,
.usa-date-picker__calendar__next-year-chunk:not([disabled]):hover,
.usa-date-picker__calendar__month:not([disabled]):hover,
.usa-date-picker__calendar__year-selection:not([disabled]):hover,
.usa-date-picker__calendar__month-selection:not([disabled]):hover,
.usa-date-picker__calendar__date:not([disabled]):hover,
.usa-date-picker__calendar__previous-year:not([disabled]):hover,
.usa-date-picker__calendar__previous-month:not([disabled]):hover,
.usa-date-picker__calendar__next-year:not([disabled]):hover,
.usa-date-picker__calendar__next-month:not([disabled]):hover,
.usa-date-picker__button:not([disabled]):hover {
  background-color: #dfe1e2;
}
@media (forced-colors: active) {
  .usa-date-picker__calendar__year:not([disabled]):hover,
  .usa-date-picker__calendar__previous-year-chunk:not([disabled]):hover,
  .usa-date-picker__calendar__next-year-chunk:not([disabled]):hover,
  .usa-date-picker__calendar__month:not([disabled]):hover,
  .usa-date-picker__calendar__year-selection:not([disabled]):hover,
  .usa-date-picker__calendar__month-selection:not([disabled]):hover,
  .usa-date-picker__calendar__date:not([disabled]):hover,
  .usa-date-picker__calendar__previous-year:not([disabled]):hover,
  .usa-date-picker__calendar__previous-month:not([disabled]):hover,
  .usa-date-picker__calendar__next-year:not([disabled]):hover,
  .usa-date-picker__calendar__next-month:not([disabled]):hover,
  .usa-date-picker__button:not([disabled]):hover {
    background-color: buttontext;
  }
}
.usa-date-picker__calendar__year:not([disabled]):active,
.usa-date-picker__calendar__previous-year-chunk:not([disabled]):active,
.usa-date-picker__calendar__next-year-chunk:not([disabled]):active,
.usa-date-picker__calendar__month:not([disabled]):active,
.usa-date-picker__calendar__year-selection:not([disabled]):active,
.usa-date-picker__calendar__month-selection:not([disabled]):active,
.usa-date-picker__calendar__date:not([disabled]):active,
.usa-date-picker__calendar__previous-year:not([disabled]):active,
.usa-date-picker__calendar__previous-month:not([disabled]):active,
.usa-date-picker__calendar__next-year:not([disabled]):active,
.usa-date-picker__calendar__next-month:not([disabled]):active,
.usa-date-picker__button:not([disabled]):active {
  background-color: #a9aeb1;
}
@media (forced-colors: active) {
  .usa-date-picker__calendar__year:not([disabled]):active,
  .usa-date-picker__calendar__previous-year-chunk:not([disabled]):active,
  .usa-date-picker__calendar__next-year-chunk:not([disabled]):active,
  .usa-date-picker__calendar__month:not([disabled]):active,
  .usa-date-picker__calendar__year-selection:not([disabled]):active,
  .usa-date-picker__calendar__month-selection:not([disabled]):active,
  .usa-date-picker__calendar__date:not([disabled]):active,
  .usa-date-picker__calendar__previous-year:not([disabled]):active,
  .usa-date-picker__calendar__previous-month:not([disabled]):active,
  .usa-date-picker__calendar__next-year:not([disabled]):active,
  .usa-date-picker__calendar__next-month:not([disabled]):active,
  .usa-date-picker__button:not([disabled]):active {
    background-color: buttontext;
  }
}
.usa-date-picker--active .usa-date-picker__button {
  background-color: #f0f0f0;
}
@media (forced-colors: active) {
  .usa-date-picker--active .usa-date-picker__button {
    background-color: buttontext;
  }
}
.usa-date-picker--active .usa-date-picker__calendar {
  z-index: 400;
}
.usa-date-picker__button {
  background-image: url("../calendar_today-3TYM6CKQ.svg"), linear-gradient(transparent, transparent);
  background-repeat: no-repeat;
  align-self: stretch;
  background-color: transparent;
  background-position: center;
  background-size: 1.5rem;
  margin-top: 0.5em;
  width: 3em;
}
@media (forced-colors: active) {
  .usa-date-picker__button {
    background-image: url("../calendar_today-3TYM6CKQ.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 2.5rem 2.5rem;
    display: inline-block;
    height: 2.5rem;
    width: 3rem;
    -webkit-mask-size: 1.5rem !important;
    mask-size: 1.5rem !important;
    position: relative;
  }
  @supports (mask: url()) {
    .usa-date-picker__button {
      background: none;
      background-color: ButtonText;
      -webkit-mask-image: url("../calendar_today-3TYM6CKQ.svg"), linear-gradient(transparent, transparent);
      mask-image: url("../calendar_today-3TYM6CKQ.svg"), linear-gradient(transparent, transparent);
      -webkit-mask-position: center center;
      mask-position: center center;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-size: 2.5rem 2.5rem;
      mask-size: 2.5rem 2.5rem;
    }
  }
  .usa-date-picker__button:not([disabled]):focus,
  .usa-date-picker__button:not([disabled]):hover {
    background-color: Highlight;
  }
}
.usa-date-picker--initialized .usa-date-picker__wrapper {
  display: flex;
}
.usa-date-picker__calendar {
  background-color: #f0f0f0;
  left: auto;
  max-width: 20rem;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 100;
}
.usa-date-picker__calendar__table {
  border-spacing: 0;
  border-collapse: collapse;
  table-layout: fixed;
  text-align: center;
  width: 100%;
}
.usa-date-picker__calendar__table th {
  font-weight: normal;
}
.usa-date-picker__calendar__table td {
  padding: 0;
}
.usa-date-picker__calendar__row {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  width: 100%;
}
.usa-date-picker__calendar__cell {
  background-color: #f0f0f0;
  flex: 1;
}
.usa-date-picker__calendar__cell--center-items {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (forced-colors: active) {
  .usa-date-picker__calendar__cell--center-items:not([disabled]):hover {
    outline: 2px solid transparent;
    outline-offset: -2px;
  }
}
.usa-date-picker__calendar__previous-year,
.usa-date-picker__calendar__previous-month,
.usa-date-picker__calendar__next-year,
.usa-date-picker__calendar__next-month {
  background-position: center;
  background-size: auto 1.5rem;
  height: 1.5rem;
  padding: 20px 10px;
}
@media (forced-colors: active) {
  .usa-date-picker__calendar__previous-year,
  .usa-date-picker__calendar__previous-month,
  .usa-date-picker__calendar__next-year,
  .usa-date-picker__calendar__next-month {
    -webkit-mask-size: 1.5rem !important;
    mask-size: 1.5rem !important;
  }
}
.usa-date-picker__calendar__previous-year:not([disabled]) {
  background-image: url("../navigate_far_before-GSWMTOHQ.svg"), linear-gradient(transparent, transparent);
  background-repeat: no-repeat;
}
@media (forced-colors: active) {
  .usa-date-picker__calendar__previous-year:not([disabled]) {
    background-image: url("../navigate_far_before-GSWMTOHQ.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 2.5rem 2.5rem;
    display: inline-block;
    height: 2.5rem;
    width: 3rem;
    background-color: buttonText;
  }
  @supports (mask: url()) {
    .usa-date-picker__calendar__previous-year:not([disabled]) {
      background: none;
      background-color: ButtonText;
      -webkit-mask-image: url("../navigate_far_before-GSWMTOHQ.svg"), linear-gradient(transparent, transparent);
      mask-image: url("../navigate_far_before-GSWMTOHQ.svg"), linear-gradient(transparent, transparent);
      -webkit-mask-position: center center;
      mask-position: center center;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-size: 2.5rem 2.5rem;
      mask-size: 2.5rem 2.5rem;
    }
  }
}
.usa-date-picker__calendar__previous-month:not([disabled]) {
  background-image: url("../navigate_before-T3EVEN7P.svg"), linear-gradient(transparent, transparent);
  background-repeat: no-repeat;
}
@media (forced-colors: active) {
  .usa-date-picker__calendar__previous-month:not([disabled]) {
    background-image: url("../navigate_before-T3EVEN7P.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 2.5rem 2.5rem;
    display: inline-block;
    height: 2.5rem;
    width: 3rem;
    background-color: buttonText;
  }
  @supports (mask: url()) {
    .usa-date-picker__calendar__previous-month:not([disabled]) {
      background: none;
      background-color: ButtonText;
      -webkit-mask-image: url("../navigate_before-T3EVEN7P.svg"), linear-gradient(transparent, transparent);
      mask-image: url("../navigate_before-T3EVEN7P.svg"), linear-gradient(transparent, transparent);
      -webkit-mask-position: center center;
      mask-position: center center;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-size: 2.5rem 2.5rem;
      mask-size: 2.5rem 2.5rem;
    }
  }
}
.usa-date-picker__calendar__next-year:not([disabled]) {
  background-image: url("../navigate_far_next-EC44VNMY.svg"), linear-gradient(transparent, transparent);
  background-repeat: no-repeat;
}
@media (forced-colors: active) {
  .usa-date-picker__calendar__next-year:not([disabled]) {
    background-image: url("../navigate_far_next-EC44VNMY.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 2.5rem 2.5rem;
    display: inline-block;
    height: 2.5rem;
    width: 3rem;
    background-color: buttonText;
  }
  @supports (mask: url()) {
    .usa-date-picker__calendar__next-year:not([disabled]) {
      background: none;
      background-color: ButtonText;
      -webkit-mask-image: url("../navigate_far_next-EC44VNMY.svg"), linear-gradient(transparent, transparent);
      mask-image: url("../navigate_far_next-EC44VNMY.svg"), linear-gradient(transparent, transparent);
      -webkit-mask-position: center center;
      mask-position: center center;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-size: 2.5rem 2.5rem;
      mask-size: 2.5rem 2.5rem;
    }
  }
}
.usa-date-picker__calendar__next-month:not([disabled]) {
  background-image: url("../navigate_next-EH2RHTK7.svg"), linear-gradient(transparent, transparent);
  background-repeat: no-repeat;
}
@media (forced-colors: active) {
  .usa-date-picker__calendar__next-month:not([disabled]) {
    background-image: url("../navigate_next-EH2RHTK7.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 2.5rem 2.5rem;
    display: inline-block;
    height: 2.5rem;
    width: 3rem;
    background-color: buttonText;
  }
  @supports (mask: url()) {
    .usa-date-picker__calendar__next-month:not([disabled]) {
      background: none;
      background-color: ButtonText;
      -webkit-mask-image: url("../navigate_next-EH2RHTK7.svg"), linear-gradient(transparent, transparent);
      mask-image: url("../navigate_next-EH2RHTK7.svg"), linear-gradient(transparent, transparent);
      -webkit-mask-position: center center;
      mask-position: center center;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-size: 2.5rem 2.5rem;
      mask-size: 2.5rem 2.5rem;
    }
  }
}
.usa-date-picker__calendar__day-of-week {
  padding: 6px 0px;
}
.usa-date-picker__calendar__date {
  padding: 10px 0px;
}
.usa-date-picker__calendar__date--focused {
  outline: 2px solid #162e51;
  outline-offset: -2px;
  position: relative;
  z-index: 100;
}
.usa-date-picker__calendar__date--next-month:not([disabled]),
.usa-date-picker__calendar__date--previous-month:not([disabled]) {
  color: #5d5d52;
}
.usa-date-picker__calendar__date--selected,
.usa-date-picker__calendar__date--range-date {
  background-color: #0050d8;
  color: #f9f9f9;
}
.usa-date-picker__calendar__date--selected:not([disabled]),
.usa-date-picker__calendar__date--range-date:not([disabled]) {
  background-color: #0050d8;
  color: #f9f9f9;
}
@media (forced-colors: active) {
  .usa-date-picker__calendar__date--selected:not([disabled]),
  .usa-date-picker__calendar__date--range-date:not([disabled]) {
    border: ActiveText 2px solid;
  }
}
.usa-date-picker__calendar__date--selected:not([disabled]):hover,
.usa-date-picker__calendar__date--range-date:not([disabled]):hover {
  background-color: #0050d8;
  color: #e6e6e6;
}
.usa-date-picker__calendar__date--selected:not([disabled]):focus,
.usa-date-picker__calendar__date--range-date:not([disabled]):focus {
  background-color: #0050d8;
  color: #f9f9f9;
}
@media (forced-colors: active) {
  .usa-date-picker__calendar__date--selected:not([disabled]):focus,
  .usa-date-picker__calendar__date--range-date:not([disabled]):focus {
    border: ActiveText 2px solid;
  }
}
.usa-date-picker__calendar__date--selected:not([disabled]):active,
.usa-date-picker__calendar__date--range-date:not([disabled]):active {
  background-color: #0b4778;
}
@media (forced-colors: active) {
  .usa-date-picker__calendar__date--selected:not([disabled]):active,
  .usa-date-picker__calendar__date--range-date:not([disabled]):active {
    background-color: Highlight;
  }
}
.usa-date-picker__calendar__date--range-date-start {
  border-top-left-radius: 10%;
  border-bottom-left-radius: 10%;
}
.usa-date-picker__calendar__date--range-date-end {
  border-top-right-radius: 10%;
  border-bottom-right-radius: 10%;
}
.usa-date-picker__calendar__date--within-range {
  background-color: #cfe8ff;
}
.usa-date-picker__calendar__date--within-range:not([disabled]) {
  background-color: #cfe8ff;
}
@media (forced-colors: active) {
  .usa-date-picker__calendar__date--within-range:not([disabled]) {
    border: Highlight 2px solid;
  }
}
.usa-date-picker__calendar__date--within-range:not([disabled]):hover {
  background-color: #cfe8ff;
}
@media (forced-colors: active) {
  .usa-date-picker__calendar__date--within-range:not([disabled]):hover {
    border: Highlight 2px solid;
  }
}
.usa-date-picker__calendar__date--within-range:not([disabled]):focus {
  background-color: #cfe8ff;
}
@media (forced-colors: active) {
  .usa-date-picker__calendar__date--within-range:not([disabled]):focus {
    border: Highlight 2px solid;
  }
}
.usa-date-picker__calendar__date--within-range:not([disabled]):active {
  background-color: #cfe8ff;
}
@media (forced-colors: active) {
  .usa-date-picker__calendar__date--within-range:not([disabled]):active {
    background-color: Highlight;
  }
}
@media all and (max-width: 19.99em) {
  .usa-date-picker__calendar__month-label {
    min-width: 100%;
    order: -1;
  }
}
@media all and (min-width: 20em) {
  .usa-date-picker__calendar__month-label {
    flex: 4;
    text-align: center;
  }
}
.usa-date-picker__calendar__year-selection,
.usa-date-picker__calendar__month-selection {
  display: inline-block;
  height: 100%;
  padding: 8px 4px;
  width: auto;
}
@media all and (max-width: 19.99em) {
  .usa-date-picker__calendar__year-selection,
  .usa-date-picker__calendar__month-selection {
    padding-bottom: 0;
    padding-top: 12px;
  }
}
.usa-date-picker__calendar__month-picker {
  padding: 20px 5px;
}
@media all and (max-width: 19.99em) {
  .usa-date-picker__calendar__month-picker {
    padding-bottom: 12px;
    padding-top: 12px;
  }
  .usa-date-picker__calendar__month-picker tr {
    display: flex;
    flex-direction: column;
  }
}
.usa-date-picker__calendar__month {
  padding: 10px 0;
}
.usa-date-picker__calendar__month--focused {
  outline: 2px solid #162e51;
  outline-offset: -2px;
  position: relative;
  z-index: 100;
}
.usa-date-picker__calendar__month--selected {
  background-color: #0050d8;
  color: #f9f9f9;
}
.usa-date-picker__calendar__month--selected:not([disabled]) {
  background-color: #0050d8;
  color: #f9f9f9;
}
.usa-date-picker__calendar__month--selected:not([disabled]):hover {
  background-color: #0050d8;
  color: #e6e6e6;
}
.usa-date-picker__calendar__month--selected:not([disabled]):focus {
  background-color: #0050d8;
  color: #f9f9f9;
}
.usa-date-picker__calendar__month--selected:not([disabled]):active {
  background-color: #0b4778;
}
.usa-date-picker__calendar__year-picker {
  padding: 20px 5px;
}
.usa-date-picker__calendar__previous-year-chunk,
.usa-date-picker__calendar__next-year-chunk {
  background-position: center;
  background-size: auto 2rem;
  margin: auto;
  padding: 40px 0;
}
@media (forced-colors: active) {
  .usa-date-picker__calendar__previous-year-chunk,
  .usa-date-picker__calendar__next-year-chunk {
    -webkit-mask-size: 1.5rem !important;
    mask-size: 1.5rem !important;
  }
}
.usa-date-picker__calendar__previous-year-chunk:not([disabled]) {
  background-image: url("../navigate_before-T3EVEN7P.svg"), linear-gradient(transparent, transparent);
  background-repeat: no-repeat;
}
@media (forced-colors: active) {
  .usa-date-picker__calendar__previous-year-chunk:not([disabled]) {
    background-image: none;
  }
  .usa-date-picker__calendar__previous-year-chunk:not([disabled])::after {
    background-image: url("../navigate_before-T3EVEN7P.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 2.5rem 2.5rem;
    display: inline-block;
    height: 2.5rem;
    width: 3rem;
    content: "";
    vertical-align: middle;
    margin-left: auto;
  }
  @supports (mask: url()) {
    .usa-date-picker__calendar__previous-year-chunk:not([disabled])::after {
      background: none;
      background-color: ButtonText;
      -webkit-mask-image: url("../navigate_before-T3EVEN7P.svg"), linear-gradient(transparent, transparent);
      mask-image: url("../navigate_before-T3EVEN7P.svg"), linear-gradient(transparent, transparent);
      -webkit-mask-position: center center;
      mask-position: center center;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-size: 2.5rem 2.5rem;
      mask-size: 2.5rem 2.5rem;
    }
  }
  .usa-date-picker__calendar__previous-year-chunk:not([disabled]):hover {
    border: 2px solid transparent;
    background-color: transparent;
  }
}
.usa-date-picker__calendar__next-year-chunk:not([disabled]) {
  background-image: url("../navigate_next-EH2RHTK7.svg"), linear-gradient(transparent, transparent);
  background-repeat: no-repeat;
}
@media (forced-colors: active) {
  .usa-date-picker__calendar__next-year-chunk:not([disabled]) {
    background-image: none;
  }
  .usa-date-picker__calendar__next-year-chunk:not([disabled])::after {
    background-image: url("../navigate_next-EH2RHTK7.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 2.5rem 2.5rem;
    display: inline-block;
    height: 2.5rem;
    width: 3rem;
    content: "";
    vertical-align: middle;
    margin-left: auto;
  }
  @supports (mask: url()) {
    .usa-date-picker__calendar__next-year-chunk:not([disabled])::after {
      background: none;
      background-color: ButtonText;
      -webkit-mask-image: url("../navigate_next-EH2RHTK7.svg"), linear-gradient(transparent, transparent);
      mask-image: url("../navigate_next-EH2RHTK7.svg"), linear-gradient(transparent, transparent);
      -webkit-mask-position: center center;
      mask-position: center center;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-size: 2.5rem 2.5rem;
      mask-size: 2.5rem 2.5rem;
    }
  }
  .usa-date-picker__calendar__next-year-chunk:not([disabled]):hover {
    border: 2px solid transparent;
    background-color: transparent;
  }
}
.usa-date-picker__calendar__year {
  padding: 10px 0;
}
.usa-date-picker__calendar__year--focused {
  outline: 2px solid #162e51;
  outline-offset: -2px;
  position: relative;
  z-index: 100;
}
.usa-date-picker__calendar__year--selected {
  background-color: #0050d8;
  color: #f9f9f9;
}
.usa-date-picker__calendar__year--selected:not([disabled]) {
  background-color: #0050d8;
  color: #f9f9f9;
}
.usa-date-picker__calendar__year--selected:not([disabled]):hover {
  background-color: #0050d8;
  color: #e6e6e6;
}
.usa-date-picker__calendar__year--selected:not([disabled]):focus {
  background-color: #0050d8;
  color: #f9f9f9;
}
.usa-date-picker__calendar__year--selected:not([disabled]):active {
  background-color: #0b4778;
}
.usa-error-message {
  padding-bottom: 0.25rem;
  padding-top: 0.25rem;
  color: #b50909;
  display: block;
  font-weight: 700;
}
[type=file] {
  border: none;
  margin-top: 0.5rem;
  padding-left: 0;
  padding-top: 0.2rem;
}
.usa-file-input {
  display: block;
  max-width: 30rem;
  width: 100%;
}
.usa-file-input__target {
  border: 1px dashed #a9aeb1;
  display: block;
  font-size: 0.89rem;
  margin-top: 0.5rem;
  position: relative;
  text-align: center;
  width: 100%;
}
.usa-file-input__target:hover {
  border-color: #71767a;
}
.usa-file-input__target.has-invalid-file {
  border-color: #fa9441;
}
.usa-file-input__accepted-files-message {
  font-weight: bold;
  margin: -1.5rem 0 1.5rem;
  pointer-events: none;
  position: relative;
  z-index: 3;
}
.has-invalid-file .usa-file-input__accepted-files-message {
  color: #b50909;
}
.usa-file-input__choose {
  color: #046B99;
  text-decoration: underline;
  font-weight: normal;
}
.usa-file-input__choose:visited {
  color: #54278f;
}
.usa-file-input__choose:hover {
  color: #0b4778;
}
.usa-file-input__choose:active {
  color: #1C304A;
}
.usa-file-input__choose:focus {
  outline: 0.25rem solid #2491ff;
  outline-offset: 0rem;
}
.usa-file-input__instructions {
  padding: 2rem 1rem;
  pointer-events: none;
  position: relative;
  z-index: 3;
}
.usa-file-input__box {
  background: white;
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}
.usa-file-input .usa-file-input__input[type] {
  cursor: pointer;
  height: 100%;
  left: 0;
  margin: 0;
  max-width: none;
  position: absolute;
  padding: 0.5rem;
  text-indent: -999em;
  top: 0;
  width: 100%;
  z-index: 1;
}
.usa-file-input .usa-file-input__input[type]::-webkit-file-upload-button {
  display: none;
}
.usa-file-input--drag .usa-file-input__target {
  border-color: #046B99;
}
.usa-file-input--drag .usa-file-input__box {
  background-color: #97d4ea;
}
.usa-file-input--drag .usa-file-input__preview {
  opacity: 0.1;
}
.usa-file-input__preview-heading {
  align-items: center;
  background: #97d4ea;
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  padding: 0.5rem;
  pointer-events: none;
  position: relative;
  z-index: 3;
}
.usa-file-input__preview {
  align-items: center;
  background: #97d4ea;
  word-wrap: anywhere;
  display: flex;
  font-size: 0.82rem;
  margin-top: 1px;
  padding: 0.25rem 0.5rem;
  pointer-events: none;
  position: relative;
  text-align: left;
  z-index: 3;
}
.usa-file-input__preview:last-child {
  margin-bottom: -1.5rem;
}
.usa-file-input__preview-image {
  border: none;
  display: block;
  height: 2.5rem;
  margin-right: 0.5rem;
  object-fit: contain;
  width: 2.5rem;
}
.usa-file-input__preview-image.is-loading {
  background-image: url("../loader-K6EHQUKL.svg"), linear-gradient(transparent, transparent);
  background-repeat: no-repeat;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 2rem;
}
.usa-file-input__preview-image--generic,
.usa-file-input__preview-image--pdf,
.usa-file-input__preview-image--word,
.usa-file-input__preview-image--excel,
.usa-file-input__preview-image--video {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 1.5rem;
}
.usa-file-input__preview-image--pdf {
  background-image: url("../file-pdf-JIOM4YQ2.svg"), linear-gradient(transparent, transparent);
  background-repeat: no-repeat;
}
.usa-file-input__preview-image--generic {
  background-image: url("../file-HHRN234J.svg"), linear-gradient(transparent, transparent);
  background-repeat: no-repeat;
}
.usa-file-input__preview-image--word {
  background-image: url("../file-word-LAS3SDGR.svg"), linear-gradient(transparent, transparent);
  background-repeat: no-repeat;
}
.usa-file-input__preview-image--excel {
  background-image: url("../file-excel-OKJUW4HL.svg"), linear-gradient(transparent, transparent);
  background-repeat: no-repeat;
}
.usa-file-input__preview-image--video {
  background-image: url("../file-video-EG2XXN3X.svg"), linear-gradient(transparent, transparent);
  background-repeat: no-repeat;
}
.usa-form-group--error .usa-file-input__target {
  border-color: #b50909;
  border-width: 2px;
}
.usa-file-input--disabled .usa-file-input__instructions,
.usa-file-input--disabled .usa-file-input__choose {
  color: #454545;
}
.usa-file-input--disabled .usa-file-input__box {
  background-color: #c9c9c9;
}
.usa-file-input--disabled .usa-file-input__input[type] {
  cursor: not-allowed;
}
.usa-file-input--disabled .usa-file-input__target:hover {
  border-color: #a9aeb1;
}
.usa-file-input--disabled .usa-file-input--drag .usa-file-input__box {
  background-color: #c9c9c9;
}
@media (forced-colors: active) {
  .usa-file-input--disabled .usa-file-input__instructions,
  .usa-file-input--disabled .usa-file-input__choose {
    color: GrayText;
  }
  .usa-file-input--disabled .usa-file-input__target,
  .usa-file-input--disabled .usa-file-input__target:hover {
    border-color: GrayText;
  }
}
.usa-form-group {
  margin-top: 1.5rem;
}
.usa-form-group .usa-label:first-child {
  margin-top: 0;
}
.usa-form-group--error {
  border-left-width: 0.25rem;
  border-left-color: #b50909;
  border-left-style: solid;
  padding-left: 1rem;
  position: relative;
}
@media all and (min-width: 64em) {
  .usa-form-group--error {
    margin-left: -1.25rem;
  }
}
.usa-hint {
  color: #71767a;
}
.usa-hint--required {
  color: #b50909;
}
.usa-input-group {
  align-items: center;
  display: flex;
  padding: 0;
  position: relative;
}
.usa-input-group--error input:focus,
.usa-input-group--success input:focus {
  outline-offset: 0.25rem;
}
.usa-input-group--error {
  border-width: 0.25rem;
  border-color: #b50909;
  border-style: solid;
}
.usa-input-group--success {
  border-width: 0.25rem;
  border-color: #00a91c;
  border-style: solid;
}
.usa-input-group input {
  padding-right: 2.5rem;
  border: 0;
  height: 100%;
  margin-top: 0;
  min-width: 0;
  width: 100%;
}
.usa-input-group input:disabled + .usa-input-suffix,
.usa-input-group input[aria-disabled=true] + .usa-input-suffix {
  color: #454545;
  background-color: #c9c9c9;
  cursor: not-allowed;
  opacity: 1;
}
.usa-input-group input:disabled + .usa-input-suffix:hover,
.usa-input-group input:disabled + .usa-input-suffix:active,
.usa-input-group input:disabled + .usa-input-suffix:focus,
.usa-input-group input:disabled + .usa-input-suffix.usa-focus,
.usa-input-group input[aria-disabled=true] + .usa-input-suffix:hover,
.usa-input-group input[aria-disabled=true] + .usa-input-suffix:active,
.usa-input-group input[aria-disabled=true] + .usa-input-suffix:focus,
.usa-input-group input[aria-disabled=true] + .usa-input-suffix.usa-focus {
  color: #454545;
  background-color: #c9c9c9;
}
@media (forced-colors: active) {
  .usa-input-group input:disabled + .usa-input-suffix,
  .usa-input-group input[aria-disabled=true] + .usa-input-suffix {
    border: 0;
    color: GrayText;
  }
  .usa-input-group input:disabled + .usa-input-suffix:hover,
  .usa-input-group input:disabled + .usa-input-suffix:active,
  .usa-input-group input:disabled + .usa-input-suffix:focus,
  .usa-input-group input:disabled + .usa-input-suffix.usa-focus,
  .usa-input-group input[aria-disabled=true] + .usa-input-suffix:hover,
  .usa-input-group input[aria-disabled=true] + .usa-input-suffix:active,
  .usa-input-group input[aria-disabled=true] + .usa-input-suffix:focus,
  .usa-input-group input[aria-disabled=true] + .usa-input-suffix.usa-focus {
    color: GrayText;
  }
}
@media (forced-colors: active) {
  .usa-input-group input:disabled,
  .usa-input-group input[aria-disabled=true] {
    border: 0;
  }
}
@media (forced-colors: active) {
  .usa-input-group:has(input:disabled),
  .usa-input-group:has(input[aria-disabled=true]) {
    border: 2px solid GrayText;
  }
}
.usa-input-group--2xs,
.usa-form .usa-input-group--2xs {
  max-width: 5ex;
}
.usa-input-group--xs,
.usa-form .usa-input-group--xs {
  max-width: 9ex;
}
.usa-input-group--sm,
.usa-form .usa-input-group--sm {
  max-width: 13ex;
}
.usa-input-group--small,
.usa-form .usa-input-group--small {
  max-width: 13ex;
}
.usa-input-group--md,
.usa-form .usa-input-group--md {
  max-width: 20ex;
}
.usa-input-group--medium,
.usa-form .usa-input-group--medium {
  max-width: 20ex;
}
.usa-input-group--lg,
.usa-form .usa-input-group--lg {
  max-width: 30ex;
}
.usa-input-group--xl,
.usa-form .usa-input-group--xl {
  max-width: 40ex;
}
.usa-input-group--2xl,
.usa-form .usa-input-group--2xl {
  max-width: 50ex;
}
.usa-input-prefix:has(+ input:disabled),
.usa-input-prefix:has(+ input[aria-disabled=true]) {
  color: #454545;
  background-color: #c9c9c9;
  cursor: not-allowed;
  opacity: 1;
}
.usa-input-prefix:has(+ input:disabled):hover,
.usa-input-prefix:has(+ input:disabled):active,
.usa-input-prefix:has(+ input:disabled):focus,
.usa-input-prefix:has(+ input:disabled).usa-focus,
.usa-input-prefix:has(+ input[aria-disabled=true]):hover,
.usa-input-prefix:has(+ input[aria-disabled=true]):active,
.usa-input-prefix:has(+ input[aria-disabled=true]):focus,
.usa-input-prefix:has(+ input[aria-disabled=true]).usa-focus {
  color: #454545;
  background-color: #c9c9c9;
}
@media (forced-colors: active) {
  .usa-input-prefix:has(+ input:disabled),
  .usa-input-prefix:has(+ input[aria-disabled=true]) {
    border: 0;
    color: GrayText;
  }
  .usa-input-prefix:has(+ input:disabled):hover,
  .usa-input-prefix:has(+ input:disabled):active,
  .usa-input-prefix:has(+ input:disabled):focus,
  .usa-input-prefix:has(+ input:disabled).usa-focus,
  .usa-input-prefix:has(+ input[aria-disabled=true]):hover,
  .usa-input-prefix:has(+ input[aria-disabled=true]):active,
  .usa-input-prefix:has(+ input[aria-disabled=true]):focus,
  .usa-input-prefix:has(+ input[aria-disabled=true]).usa-focus {
    color: GrayText;
  }
}
.usa-input-prefix,
.usa-input-suffix {
  position: absolute;
  color: #71767a;
  line-height: 0;
  padding: 0 0.5rem;
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
}
.usa-input-prefix .usa-icon,
.usa-input-suffix .usa-icon {
  height: 1.5rem;
  width: 1.5rem;
}
.usa-input-prefix + input {
  padding-left: 2.5rem;
  padding-right: 0.5rem;
}
.usa-input-suffix {
  right: 0;
}
.usa-input-mask {
  display: block;
  position: relative;
}
.usa-input-mask--content {
  border: 1px solid transparent;
  color: #757575;
  display: block;
  left: 2px;
  pointer-events: none;
  position: absolute;
  top: 0;
}
.usa-input-mask--content i {
  visibility: hidden;
}
@media (forced-colors: active) {
  .usa-input-mask--content i {
    color: ButtonText;
    font-style: normal;
    visibility: visible;
  }
}
@media (forced-colors: active) {
  .usa-input-mask--content {
    border: none;
  }
}
.usa-masked,
.usa-input-mask--content {
  background-color: transparent;
  padding: 0.5rem;
}
.usa-label {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.08rem;
  line-height: 1.3;
  display: block;
  font-weight: normal;
  margin-top: 1.5rem;
  max-width: 30rem;
}
.usa-label--error {
  font-weight: 700;
  margin-top: 0;
}
.usa-label--required {
  color: #b50909;
}
.usa-memorable-date {
  display: flex;
  flex-wrap: wrap;
}
.usa-memorable-date [type=number] {
  -moz-appearance: textfield;
}
.usa-memorable-date [type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.usa-memorable-date [type=number]::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  height: 0;
  width: 0;
  margin: 0;
}
.usa-memorable-date .usa-form-group {
  margin-top: 1rem;
}
.usa-form-group--day,
.usa-form-group--month,
.usa-form-group--year {
  flex: 0 1 auto;
  margin-right: 1rem;
  width: fit-content;
}
.usa-form-group--day input,
.usa-form-group--month input {
  width: 3rem;
}
.usa-form-group--month select {
  width: 15rem;
}
.usa-form-group--year input {
  width: 4.5rem;
}
.usa-radio {
  background: white;
}
.usa-radio__label {
  color: #11181d;
}
.usa-radio__label::before {
  background: white;
  box-shadow: 0 0 0 2px #11181d;
}
@media (forced-colors: active) {
  .usa-radio__label::before {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
}
.usa-radio__input:checked + [class*=__label]::before {
  background-color: #046B99;
  box-shadow: 0 0 0 2px #046B99;
}
.usa-radio__input:disabled + [class*=__label],
.usa-radio__input[aria-disabled=true] + [class*=__label] {
  color: #757575;
  cursor: not-allowed;
}
@media (forced-colors: active) {
  .usa-radio__input:disabled + [class*=__label],
  .usa-radio__input[aria-disabled=true] + [class*=__label] {
    color: GrayText;
  }
}
.usa-radio__input:disabled + [class*=__label]::before,
.usa-radio__input[aria-disabled=true] + [class*=__label]::before {
  background-color: white;
  box-shadow: 0 0 0 2px #757575;
}
.usa-radio__input--tile + [class*=__label] {
  background-color: white;
  border: 2px solid #c9c9c9;
  color: #11181d;
}
.usa-radio__input--tile:checked + [class*=__label] {
  background-color: rgba(4, 107, 153, 0.1);
  border-color: #046B99;
}
@media (forced-colors: active) {
  .usa-radio__input--tile:checked + [class*=__label] {
    border: ButtonText solid 0.25rem;
  }
}
.usa-radio__input--tile:disabled + [class*=__label],
.usa-radio__input--tile[aria-disabled=true] + [class*=__label] {
  border-color: #e6e6e6;
}
.usa-radio__input--tile:disabled:checked + [class*=__label],
.usa-radio__input--tile:disabled:indeterminate + [class*=__label],
.usa-radio__input--tile:disabled[data-indeterminate] + [class*=__label],
.usa-radio__input--tile[aria-disabled=true]:checked + [class*=__label],
.usa-radio__input--tile[aria-disabled=true]:indeterminate + [class*=__label],
.usa-radio__input--tile[aria-disabled=true][data-indeterminate] + [class*=__label] {
  background-color: white;
}
.usa-radio__input:checked + [class*=__label]::before {
  box-shadow: 0 0 0 2px #046B99, inset 0 0 0 2px white;
}
@media (forced-colors: active) {
  .usa-radio__input:checked + [class*=__label]::before {
    background-color: ButtonText;
  }
}
.usa-radio__input:checked:disabled + [class*=__label]::before,
.usa-radio__input:checked[aria-disabled=true] + [class*=__label]::before {
  background-color: #757575;
  box-shadow: 0 0 0 2px #757575, inset 0 0 0 2px white;
}
@media (forced-colors: active) {
  .usa-radio__input:checked:disabled + [class*=__label]::before,
  .usa-radio__input:checked[aria-disabled=true] + [class*=__label]::before {
    background-color: GrayText;
  }
}
.usa-radio__input {
  position: absolute;
  left: -999em;
  right: auto;
}
.usa-radio__input:focus + [class*=__label]::before {
  outline: 0.25rem solid #2491ff;
  outline-offset: 0.25rem;
}
.usa-radio__input--tile + [class*=__label] {
  border-radius: 0.25rem;
  margin-top: 0.5rem;
  padding: 0.75rem 1rem 0.75rem 2.5rem;
  display: inherit;
}
.usa-radio__input--tile + [class*=__label]::before {
  left: 0.5rem;
}
@media print {
  .usa-radio__input:checked + [class*=__label]::before {
    box-shadow:
      inset 0 0 0 2px white,
      inset 0 0 0 1rem #046B99,
      0 0 0 2px #046B99;
  }
}
.usa-radio__label {
  cursor: pointer;
  display: inline-block;
  font-weight: normal;
  margin-top: 0.75rem;
  padding-left: 2rem;
  position: relative;
}
.usa-radio__label::before {
  content: " ";
  display: block;
  left: 0;
  margin-left: 2px;
  margin-top: 0.077rem;
  position: absolute;
}
.usa-radio__label::before {
  height: 1.25rem;
  border-radius: 99rem;
  width: 1.25rem;
}
.usa-radio__label-description {
  display: block;
  font-size: 0.89rem;
  margin-top: 0.5rem;
}
.usa-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  padding-left: 1px;
  width: 100%;
}
.usa-range:focus {
  outline: none;
}
.usa-range:focus::-webkit-slider-thumb {
  background-color: white;
  box-shadow: 0 0 0 2px #2491ff;
}
.usa-range:focus::-moz-range-thumb {
  background-color: white;
  box-shadow: 0 0 0 2px #2491ff;
}
.usa-range:focus::-ms-thumb {
  background-color: white;
  box-shadow: 0 0 0 2px #2491ff;
}
.usa-range::-webkit-slider-runnable-track {
  background-color: #f0f0f0;
  border-radius: 99rem;
  border: 1px solid #71767a;
  cursor: pointer;
  height: 1rem;
  width: 100%;
}
.usa-range::-moz-range-track {
  background-color: #f0f0f0;
  border-radius: 99rem;
  border: 1px solid #71767a;
  cursor: pointer;
  height: 1rem;
  width: 100%;
}
.usa-range::-ms-track {
  background-color: #f0f0f0;
  border-radius: 99rem;
  border: 1px solid #71767a;
  cursor: pointer;
  height: 1rem;
  width: 100%;
}
.usa-range::-webkit-slider-thumb {
  height: 1.25rem;
  border-radius: 99rem;
  width: 1.25rem;
  background: #f0f0f0;
  border: none;
  box-shadow: 0 0 0 2px #71767a;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-top: -0.19rem;
}
@media (forced-colors: active) {
  .usa-range::-webkit-slider-thumb {
    outline: 2px solid transparent;
  }
}
.usa-range::-moz-range-thumb {
  height: 1.25rem;
  border-radius: 99rem;
  width: 1.25rem;
  background: #f0f0f0;
  border: none;
  box-shadow: 0 0 0 2px #71767a;
  cursor: pointer;
}
@media (forced-colors: active) {
  .usa-range::-moz-range-thumb {
    outline: 2px solid transparent;
  }
}
.usa-range::-ms-thumb {
  height: 1.25rem;
  border-radius: 99rem;
  width: 1.25rem;
  background: #f0f0f0;
  border: none;
  box-shadow: 0 0 0 2px #71767a;
  cursor: pointer;
}
@media (forced-colors: active) {
  .usa-range::-ms-thumb {
    outline: 2px solid transparent;
  }
}
.usa-range::-ms-fill-lower {
  background-color: #f0f0f0;
  border-radius: 99rem;
  border: 1px solid #71767a;
}
.usa-range::-ms-fill-upper {
  background-color: #f0f0f0;
  border-radius: 99rem;
  border: 1px solid #71767a;
}
.usa-range:disabled,
.usa-range[aria-disabled=true] {
  opacity: 1;
}
.usa-range:disabled::-webkit-slider-runnable-track,
.usa-range[aria-disabled=true]::-webkit-slider-runnable-track {
  color: #454545;
  background-color: #c9c9c9;
  cursor: not-allowed;
  opacity: 1;
}
.usa-range:disabled::-webkit-slider-runnable-track:hover,
.usa-range:disabled::-webkit-slider-runnable-track:active,
.usa-range:disabled::-webkit-slider-runnable-track:focus,
.usa-range:disabled::-webkit-slider-runnable-track .usa-focus,
.usa-range[aria-disabled=true]::-webkit-slider-runnable-track:hover,
.usa-range[aria-disabled=true]::-webkit-slider-runnable-track:active,
.usa-range[aria-disabled=true]::-webkit-slider-runnable-track:focus,
.usa-range[aria-disabled=true]::-webkit-slider-runnable-track .usa-focus {
  color: #454545;
  background-color: #c9c9c9;
}
@media (forced-colors: active) {
  .usa-range:disabled::-webkit-slider-runnable-track,
  .usa-range[aria-disabled=true]::-webkit-slider-runnable-track {
    border: 0;
    color: GrayText;
  }
  .usa-range:disabled::-webkit-slider-runnable-track:hover,
  .usa-range:disabled::-webkit-slider-runnable-track:active,
  .usa-range:disabled::-webkit-slider-runnable-track:focus,
  .usa-range:disabled::-webkit-slider-runnable-track .usa-focus,
  .usa-range[aria-disabled=true]::-webkit-slider-runnable-track:hover,
  .usa-range[aria-disabled=true]::-webkit-slider-runnable-track:active,
  .usa-range[aria-disabled=true]::-webkit-slider-runnable-track:focus,
  .usa-range[aria-disabled=true]::-webkit-slider-runnable-track .usa-focus {
    color: GrayText;
  }
}
@media (forced-colors: active) {
  .usa-range:disabled::-webkit-slider-runnable-track,
  .usa-range[aria-disabled=true]::-webkit-slider-runnable-track {
    border: 2px solid GrayText;
  }
}
.usa-range:disabled::-moz-range-track,
.usa-range[aria-disabled=true]::-moz-range-track {
  color: #454545;
  background-color: #c9c9c9;
  cursor: not-allowed;
  opacity: 1;
}
.usa-range:disabled::-moz-range-track:hover,
.usa-range:disabled::-moz-range-track:active,
.usa-range:disabled::-moz-range-track:focus,
.usa-range:disabled::-moz-range-track .usa-focus,
.usa-range[aria-disabled=true]::-moz-range-track:hover,
.usa-range[aria-disabled=true]::-moz-range-track:active,
.usa-range[aria-disabled=true]::-moz-range-track:focus,
.usa-range[aria-disabled=true]::-moz-range-track .usa-focus {
  color: #454545;
  background-color: #c9c9c9;
}
@media (forced-colors: active) {
  .usa-range:disabled::-moz-range-track,
  .usa-range[aria-disabled=true]::-moz-range-track {
    border: 0;
    color: GrayText;
  }
  .usa-range:disabled::-moz-range-track:hover,
  .usa-range:disabled::-moz-range-track:active,
  .usa-range:disabled::-moz-range-track:focus,
  .usa-range:disabled::-moz-range-track .usa-focus,
  .usa-range[aria-disabled=true]::-moz-range-track:hover,
  .usa-range[aria-disabled=true]::-moz-range-track:active,
  .usa-range[aria-disabled=true]::-moz-range-track:focus,
  .usa-range[aria-disabled=true]::-moz-range-track .usa-focus {
    color: GrayText;
  }
}
@media (forced-colors: active) {
  .usa-range:disabled::-moz-range-track,
  .usa-range[aria-disabled=true]::-moz-range-track {
    border: 2px solid GrayText;
  }
}
.usa-range:disabled::-ms-track,
.usa-range[aria-disabled=true]::-ms-track {
  color: #454545;
  background-color: #c9c9c9;
  cursor: not-allowed;
  opacity: 1;
}
.usa-range:disabled::-ms-track:hover,
.usa-range:disabled::-ms-track:active,
.usa-range:disabled::-ms-track:focus,
.usa-range:disabled::-ms-track .usa-focus,
.usa-range[aria-disabled=true]::-ms-track:hover,
.usa-range[aria-disabled=true]::-ms-track:active,
.usa-range[aria-disabled=true]::-ms-track:focus,
.usa-range[aria-disabled=true]::-ms-track .usa-focus {
  color: #454545;
  background-color: #c9c9c9;
}
@media (forced-colors: active) {
  .usa-range:disabled::-ms-track,
  .usa-range[aria-disabled=true]::-ms-track {
    border: 0;
    color: GrayText;
  }
  .usa-range:disabled::-ms-track:hover,
  .usa-range:disabled::-ms-track:active,
  .usa-range:disabled::-ms-track:focus,
  .usa-range:disabled::-ms-track .usa-focus,
  .usa-range[aria-disabled=true]::-ms-track:hover,
  .usa-range[aria-disabled=true]::-ms-track:active,
  .usa-range[aria-disabled=true]::-ms-track:focus,
  .usa-range[aria-disabled=true]::-ms-track .usa-focus {
    color: GrayText;
  }
}
.usa-range:disabled::-webkit-slider-thumb,
.usa-range[aria-disabled=true]::-webkit-slider-thumb {
  color: #454545;
  background-color: #c9c9c9;
  cursor: not-allowed;
  opacity: 1;
}
.usa-range:disabled::-webkit-slider-thumb:hover,
.usa-range:disabled::-webkit-slider-thumb:active,
.usa-range:disabled::-webkit-slider-thumb:focus,
.usa-range:disabled::-webkit-slider-thumb .usa-focus,
.usa-range[aria-disabled=true]::-webkit-slider-thumb:hover,
.usa-range[aria-disabled=true]::-webkit-slider-thumb:active,
.usa-range[aria-disabled=true]::-webkit-slider-thumb:focus,
.usa-range[aria-disabled=true]::-webkit-slider-thumb .usa-focus {
  color: #454545;
  background-color: #c9c9c9;
}
@media (forced-colors: active) {
  .usa-range:disabled::-webkit-slider-thumb,
  .usa-range[aria-disabled=true]::-webkit-slider-thumb {
    border: 0;
    color: GrayText;
  }
  .usa-range:disabled::-webkit-slider-thumb:hover,
  .usa-range:disabled::-webkit-slider-thumb:active,
  .usa-range:disabled::-webkit-slider-thumb:focus,
  .usa-range:disabled::-webkit-slider-thumb .usa-focus,
  .usa-range[aria-disabled=true]::-webkit-slider-thumb:hover,
  .usa-range[aria-disabled=true]::-webkit-slider-thumb:active,
  .usa-range[aria-disabled=true]::-webkit-slider-thumb:focus,
  .usa-range[aria-disabled=true]::-webkit-slider-thumb .usa-focus {
    color: GrayText;
  }
}
.usa-range:disabled::-moz-range-thumb,
.usa-range[aria-disabled=true]::-moz-range-thumb {
  color: #454545;
  background-color: #c9c9c9;
  cursor: not-allowed;
  opacity: 1;
}
.usa-range:disabled::-moz-range-thumb:hover,
.usa-range:disabled::-moz-range-thumb:active,
.usa-range:disabled::-moz-range-thumb:focus,
.usa-range:disabled::-moz-range-thumb .usa-focus,
.usa-range[aria-disabled=true]::-moz-range-thumb:hover,
.usa-range[aria-disabled=true]::-moz-range-thumb:active,
.usa-range[aria-disabled=true]::-moz-range-thumb:focus,
.usa-range[aria-disabled=true]::-moz-range-thumb .usa-focus {
  color: #454545;
  background-color: #c9c9c9;
}
@media (forced-colors: active) {
  .usa-range:disabled::-moz-range-thumb,
  .usa-range[aria-disabled=true]::-moz-range-thumb {
    border: 0;
    color: GrayText;
  }
  .usa-range:disabled::-moz-range-thumb:hover,
  .usa-range:disabled::-moz-range-thumb:active,
  .usa-range:disabled::-moz-range-thumb:focus,
  .usa-range:disabled::-moz-range-thumb .usa-focus,
  .usa-range[aria-disabled=true]::-moz-range-thumb:hover,
  .usa-range[aria-disabled=true]::-moz-range-thumb:active,
  .usa-range[aria-disabled=true]::-moz-range-thumb:focus,
  .usa-range[aria-disabled=true]::-moz-range-thumb .usa-focus {
    color: GrayText;
  }
}
.usa-range:disabled::-ms-thumb,
.usa-range[aria-disabled=true]::-ms-thumb {
  color: #454545;
  background-color: #c9c9c9;
  cursor: not-allowed;
  opacity: 1;
}
.usa-range:disabled::-ms-thumb:hover,
.usa-range:disabled::-ms-thumb:active,
.usa-range:disabled::-ms-thumb:focus,
.usa-range:disabled::-ms-thumb .usa-focus,
.usa-range[aria-disabled=true]::-ms-thumb:hover,
.usa-range[aria-disabled=true]::-ms-thumb:active,
.usa-range[aria-disabled=true]::-ms-thumb:focus,
.usa-range[aria-disabled=true]::-ms-thumb .usa-focus {
  color: #454545;
  background-color: #c9c9c9;
}
@media (forced-colors: active) {
  .usa-range:disabled::-ms-thumb,
  .usa-range[aria-disabled=true]::-ms-thumb {
    border: 0;
    color: GrayText;
  }
  .usa-range:disabled::-ms-thumb:hover,
  .usa-range:disabled::-ms-thumb:active,
  .usa-range:disabled::-ms-thumb:focus,
  .usa-range:disabled::-ms-thumb .usa-focus,
  .usa-range[aria-disabled=true]::-ms-thumb:hover,
  .usa-range[aria-disabled=true]::-ms-thumb:active,
  .usa-range[aria-disabled=true]::-ms-thumb:focus,
  .usa-range[aria-disabled=true]::-ms-thumb .usa-focus {
    color: GrayText;
  }
}
.usa-range:disabled::-ms-fill-lower,
.usa-range[aria-disabled=true]::-ms-fill-lower {
  color: #454545;
  background-color: #c9c9c9;
  cursor: not-allowed;
  opacity: 1;
}
.usa-range:disabled::-ms-fill-lower:hover,
.usa-range:disabled::-ms-fill-lower:active,
.usa-range:disabled::-ms-fill-lower:focus,
.usa-range:disabled::-ms-fill-lower .usa-focus,
.usa-range[aria-disabled=true]::-ms-fill-lower:hover,
.usa-range[aria-disabled=true]::-ms-fill-lower:active,
.usa-range[aria-disabled=true]::-ms-fill-lower:focus,
.usa-range[aria-disabled=true]::-ms-fill-lower .usa-focus {
  color: #454545;
  background-color: #c9c9c9;
}
@media (forced-colors: active) {
  .usa-range:disabled::-ms-fill-lower,
  .usa-range[aria-disabled=true]::-ms-fill-lower {
    border: 0;
    color: GrayText;
  }
  .usa-range:disabled::-ms-fill-lower:hover,
  .usa-range:disabled::-ms-fill-lower:active,
  .usa-range:disabled::-ms-fill-lower:focus,
  .usa-range:disabled::-ms-fill-lower .usa-focus,
  .usa-range[aria-disabled=true]::-ms-fill-lower:hover,
  .usa-range[aria-disabled=true]::-ms-fill-lower:active,
  .usa-range[aria-disabled=true]::-ms-fill-lower:focus,
  .usa-range[aria-disabled=true]::-ms-fill-lower .usa-focus {
    color: GrayText;
  }
}
.usa-range:disabled::-ms-fill-upper,
.usa-range[aria-disabled=true]::-ms-fill-upper {
  color: #454545;
  background-color: #c9c9c9;
  cursor: not-allowed;
  opacity: 1;
}
.usa-range:disabled::-ms-fill-upper:hover,
.usa-range:disabled::-ms-fill-upper:active,
.usa-range:disabled::-ms-fill-upper:focus,
.usa-range:disabled::-ms-fill-upper .usa-focus,
.usa-range[aria-disabled=true]::-ms-fill-upper:hover,
.usa-range[aria-disabled=true]::-ms-fill-upper:active,
.usa-range[aria-disabled=true]::-ms-fill-upper:focus,
.usa-range[aria-disabled=true]::-ms-fill-upper .usa-focus {
  color: #454545;
  background-color: #c9c9c9;
}
@media (forced-colors: active) {
  .usa-range:disabled::-ms-fill-upper,
  .usa-range[aria-disabled=true]::-ms-fill-upper {
    border: 0;
    color: GrayText;
  }
  .usa-range:disabled::-ms-fill-upper:hover,
  .usa-range:disabled::-ms-fill-upper:active,
  .usa-range:disabled::-ms-fill-upper:focus,
  .usa-range:disabled::-ms-fill-upper .usa-focus,
  .usa-range[aria-disabled=true]::-ms-fill-upper:hover,
  .usa-range[aria-disabled=true]::-ms-fill-upper:active,
  .usa-range[aria-disabled=true]::-ms-fill-upper:focus,
  .usa-range[aria-disabled=true]::-ms-fill-upper .usa-focus {
    color: GrayText;
  }
}
.usa-textarea:disabled,
.usa-textarea[aria-disabled=true] {
  color: #454545;
  background-color: #c9c9c9;
  cursor: not-allowed;
  opacity: 1;
}
.usa-textarea:disabled:hover,
.usa-textarea:disabled:active,
.usa-textarea:disabled:focus,
.usa-textarea:disabled.usa-focus,
.usa-textarea[aria-disabled=true]:hover,
.usa-textarea[aria-disabled=true]:active,
.usa-textarea[aria-disabled=true]:focus,
.usa-textarea[aria-disabled=true].usa-focus {
  color: #454545;
  background-color: #c9c9c9;
}
@media (forced-colors: active) {
  .usa-textarea:disabled,
  .usa-textarea[aria-disabled=true] {
    border: 0;
    color: GrayText;
  }
  .usa-textarea:disabled:hover,
  .usa-textarea:disabled:active,
  .usa-textarea:disabled:focus,
  .usa-textarea:disabled.usa-focus,
  .usa-textarea[aria-disabled=true]:hover,
  .usa-textarea[aria-disabled=true]:active,
  .usa-textarea[aria-disabled=true]:focus,
  .usa-textarea[aria-disabled=true].usa-focus {
    color: GrayText;
  }
}
@media (forced-colors: active) {
  .usa-textarea:disabled,
  .usa-textarea[aria-disabled=true] {
    border: 2px solid GrayText;
  }
}
.usa-textarea {
  height: 10rem;
}
.usa-time-picker {
  width: 10em;
}
[class*=font-mono-] {
  font-family:
    Roboto Mono Web,
    Bitstream Vera Sans Mono,
    Consolas,
    Courier,
    monospace;
}
[class*=font-sans-] {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
}
[class*=font-serif-] {
  font-family:
    Merriweather Web,
    Georgia,
    Cambria,
    Times New Roman,
    Times,
    serif;
}
[class*=font-ui-] {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
}
[class*=font-heading-] {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
}
[class*=font-body-] {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
}
[class*=font-code-] {
  font-family:
    Roboto Mono Web,
    Bitstream Vera Sans Mono,
    Consolas,
    Courier,
    monospace;
}
[class*=font-alt-] {
  font-family:
    Merriweather Web,
    Georgia,
    Cambria,
    Times New Roman,
    Times,
    serif;
}
.add-aspect-9x16 {
  box-sizing: border-box;
  height: 0;
  overflow: hidden;
  padding-bottom: 177.77778%;
  position: relative;
}
@supports (aspect-ratio: 1) {
  .add-aspect-9x16 {
    height: inherit;
    padding: inherit;
    aspect-ratio: 0.5625;
    max-width: 100%;
  }
  .add-aspect-9x16 > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  img.add-aspect-9x16,
  .add-aspect-9x16 > img {
    object-fit: cover;
  }
}
.add-aspect-16x9 {
  box-sizing: border-box;
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
}
@supports (aspect-ratio: 1) {
  .add-aspect-16x9 {
    height: inherit;
    padding: inherit;
    aspect-ratio: 1.7777777778;
    max-width: 100%;
  }
  .add-aspect-16x9 > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  img.add-aspect-16x9,
  .add-aspect-16x9 > img {
    object-fit: cover;
  }
}
.add-aspect-1x1 {
  box-sizing: border-box;
  height: 0;
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;
}
@supports (aspect-ratio: 1) {
  .add-aspect-1x1 {
    height: inherit;
    padding: inherit;
    aspect-ratio: 1;
    max-width: 100%;
  }
  .add-aspect-1x1 > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  img.add-aspect-1x1,
  .add-aspect-1x1 > img {
    object-fit: cover;
  }
}
.add-aspect-4x3 {
  box-sizing: border-box;
  height: 0;
  overflow: hidden;
  padding-bottom: 75%;
  position: relative;
}
@supports (aspect-ratio: 1) {
  .add-aspect-4x3 {
    height: inherit;
    padding: inherit;
    aspect-ratio: 1.3333333333;
    max-width: 100%;
  }
  .add-aspect-4x3 > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  img.add-aspect-4x3,
  .add-aspect-4x3 > img {
    object-fit: cover;
  }
}
.add-aspect-2x1 {
  box-sizing: border-box;
  height: 0;
  overflow: hidden;
  padding-bottom: 50%;
  position: relative;
}
@supports (aspect-ratio: 1) {
  .add-aspect-2x1 {
    height: inherit;
    padding: inherit;
    aspect-ratio: 2;
    max-width: 100%;
  }
  .add-aspect-2x1 > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  img.add-aspect-2x1,
  .add-aspect-2x1 > img {
    object-fit: cover;
  }
}
@supports (aspect-ratio: 1) {
  :where(img[class*=add-aspect-]) {
    width: min-content;
  }
}
.add-list-reset {
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
  list-style: none;
}
.flex-align-start {
  align-items: flex-start;
}
.flex-align-end {
  align-items: flex-end;
}
.flex-align-center {
  align-items: center;
}
.flex-align-stretch {
  align-items: stretch;
}
.flex-align-baseline {
  align-items: baseline;
}
.flex-align-self-start {
  align-self: flex-start;
}
.flex-align-self-end {
  align-self: flex-end;
}
.flex-align-self-center {
  align-self: center;
}
.flex-align-self-stretch {
  align-self: stretch;
}
.flex-align-self-baseline {
  align-self: baseline;
}
.bg-transparent {
  background-color: transparent;
}
.hover\:bg-transparent:hover {
  background-color: transparent;
}
.bg-black {
  background-color: black;
}
.hover\:bg-black:hover {
  background-color: black;
}
.bg-white {
  background-color: white;
}
.hover\:bg-white:hover {
  background-color: white;
}
.bg-red {
  background-color: #e52207;
}
.hover\:bg-red:hover {
  background-color: #e52207;
}
.bg-orange {
  background-color: #e66f0e;
}
.hover\:bg-orange:hover {
  background-color: #e66f0e;
}
.bg-gold {
  background-color: #ffbe2e;
}
.hover\:bg-gold:hover {
  background-color: #ffbe2e;
}
.bg-yellow {
  background-color: #fee685;
}
.hover\:bg-yellow:hover {
  background-color: #fee685;
}
.bg-green {
  background-color: #538200;
}
.hover\:bg-green:hover {
  background-color: #538200;
}
.bg-mint {
  background-color: #04c585;
}
.hover\:bg-mint:hover {
  background-color: #04c585;
}
.bg-cyan {
  background-color: #009ec1;
}
.hover\:bg-cyan:hover {
  background-color: #009ec1;
}
.bg-blue {
  background-color: #0076d6;
}
.hover\:bg-blue:hover {
  background-color: #0076d6;
}
.bg-indigo {
  background-color: #676cc8;
}
.hover\:bg-indigo:hover {
  background-color: #676cc8;
}
.bg-violet {
  background-color: #8168b3;
}
.hover\:bg-violet:hover {
  background-color: #8168b3;
}
.bg-magenta {
  background-color: #d72d79;
}
.hover\:bg-magenta:hover {
  background-color: #d72d79;
}
.bg-gray-5 {
  background-color: #f0f0f0;
}
.hover\:bg-gray-5:hover {
  background-color: #f0f0f0;
}
.bg-gray-10 {
  background-color: #e6e6e6;
}
.hover\:bg-gray-10:hover {
  background-color: #e6e6e6;
}
.bg-gray-30 {
  background-color: #adadad;
}
.hover\:bg-gray-30:hover {
  background-color: #adadad;
}
.bg-gray-50 {
  background-color: #757575;
}
.hover\:bg-gray-50:hover {
  background-color: #757575;
}
.bg-gray-70 {
  background-color: #454545;
}
.hover\:bg-gray-70:hover {
  background-color: #454545;
}
.bg-gray-90 {
  background-color: #1b1b1b;
}
.hover\:bg-gray-90:hover {
  background-color: #1b1b1b;
}
.bg-base-lightest {
  background-color: #f0f0f0;
}
.hover\:bg-base-lightest:hover {
  background-color: #f0f0f0;
}
.bg-base-lighter {
  background-color: #dfe1e2;
}
.hover\:bg-base-lighter:hover {
  background-color: #dfe1e2;
}
.bg-base-light {
  background-color: #a9aeb1;
}
.hover\:bg-base-light:hover {
  background-color: #a9aeb1;
}
.bg-base {
  background-color: #71767a;
}
.hover\:bg-base:hover {
  background-color: #71767a;
}
.bg-base-dark {
  background-color: #565c65;
}
.hover\:bg-base-dark:hover {
  background-color: #565c65;
}
.bg-base-darker {
  background-color: #3d4551;
}
.hover\:bg-base-darker:hover {
  background-color: #3d4551;
}
.bg-base-darkest {
  background-color: #1b1b1b;
}
.hover\:bg-base-darkest:hover {
  background-color: #1b1b1b;
}
.bg-ink {
  background-color: #11181d;
}
.hover\:bg-ink:hover {
  background-color: #11181d;
}
.bg-primary-lightest {
  background-color: #B3EFFF;
}
.hover\:bg-primary-lightest:hover {
  background-color: #B3EFFF;
}
.bg-primary-lighter {
  background-color: #97d4ea;
}
.hover\:bg-primary-lighter:hover {
  background-color: #97d4ea;
}
.bg-primary-light {
  background-color: #28a0cb;
}
.hover\:bg-primary-light:hover {
  background-color: #28a0cb;
}
.bg-primary {
  background-color: #046B99;
}
.hover\:bg-primary:hover {
  background-color: #046B99;
}
.bg-primary-vivid {
  background-color: #0050d8;
}
.hover\:bg-primary-vivid:hover {
  background-color: #0050d8;
}
.bg-primary-dark {
  background-color: #0b4778;
}
.hover\:bg-primary-dark:hover {
  background-color: #0b4778;
}
.bg-primary-darker {
  background-color: #1C304A;
}
.hover\:bg-primary-darker:hover {
  background-color: #1C304A;
}
.bg-primary-darkest {
  background-color: #11181d;
}
.hover\:bg-primary-darkest:hover {
  background-color: #11181d;
}
.bg-secondary-lighter {
  background-color: #f3e1e4;
}
.hover\:bg-secondary-lighter:hover {
  background-color: #f3e1e4;
}
.bg-secondary-light {
  background-color: #f2938c;
}
.hover\:bg-secondary-light:hover {
  background-color: #f2938c;
}
.bg-secondary {
  background-color: #d83933;
}
.hover\:bg-secondary:hover {
  background-color: #d83933;
}
.bg-secondary-vivid {
  background-color: #e41d3d;
}
.hover\:bg-secondary-vivid:hover {
  background-color: #e41d3d;
}
.bg-secondary-dark {
  background-color: #b50909;
}
.hover\:bg-secondary-dark:hover {
  background-color: #b50909;
}
.bg-secondary-darker {
  background-color: #8b0a03;
}
.hover\:bg-secondary-darker:hover {
  background-color: #8b0a03;
}
.bg-accent-warm-darker {
  background-color: #775540;
}
.hover\:bg-accent-warm-darker:hover {
  background-color: #775540;
}
.bg-accent-warm-dark {
  background-color: #c05600;
}
.hover\:bg-accent-warm-dark:hover {
  background-color: #c05600;
}
.bg-accent-warm {
  background-color: #fa9441;
}
.hover\:bg-accent-warm:hover {
  background-color: #fa9441;
}
.bg-accent-warm-light {
  background-color: #ffbc78;
}
.hover\:bg-accent-warm-light:hover {
  background-color: #ffbc78;
}
.bg-accent-warm-lighter {
  background-color: #f2e4d4;
}
.hover\:bg-accent-warm-lighter:hover {
  background-color: #f2e4d4;
}
.bg-accent-cool-darkest {
  background-color: #093b44;
}
.hover\:bg-accent-cool-darkest:hover {
  background-color: #093b44;
}
.bg-accent-cool-darker {
  background-color: #0e4f5c;
}
.hover\:bg-accent-cool-darker:hover {
  background-color: #0e4f5c;
}
.bg-accent-cool-dark {
  background-color: #0081a1;
}
.hover\:bg-accent-cool-dark:hover {
  background-color: #0081a1;
}
.bg-accent-cool {
  background-color: #00CFFF;
}
.hover\:bg-accent-cool:hover {
  background-color: #00CFFF;
}
.bg-accent-cool-light {
  background-color: #52daf2;
}
.hover\:bg-accent-cool-light:hover {
  background-color: #52daf2;
}
.bg-accent-cool-lighter {
  background-color: #a8f2ff;
}
.hover\:bg-accent-cool-lighter:hover {
  background-color: #a8f2ff;
}
.bg-accent-cool-lightest {
  background-color: #eff6fb;
}
.hover\:bg-accent-cool-lightest:hover {
  background-color: #eff6fb;
}
.bg-error-lighter {
  background-color: #fff5ee;
}
.hover\:bg-error-lighter:hover {
  background-color: #fff5ee;
}
.bg-error-light {
  background-color: #f39268;
}
.hover\:bg-error-light:hover {
  background-color: #f39268;
}
.bg-error {
  background-color: #d54309;
}
.hover\:bg-error:hover {
  background-color: #d54309;
}
.bg-error-dark {
  background-color: #b50909;
}
.hover\:bg-error-dark:hover {
  background-color: #b50909;
}
.bg-error-darker {
  background-color: #6f3331;
}
.hover\:bg-error-darker:hover {
  background-color: #6f3331;
}
.bg-warning-lighter {
  background-color: #faf3d1;
}
.hover\:bg-warning-lighter:hover {
  background-color: #faf3d1;
}
.bg-warning-light {
  background-color: #fee685;
}
.hover\:bg-warning-light:hover {
  background-color: #fee685;
}
.bg-warning {
  background-color: #ffbe2e;
}
.hover\:bg-warning:hover {
  background-color: #ffbe2e;
}
.bg-warning-dark {
  background-color: #e5a000;
}
.hover\:bg-warning-dark:hover {
  background-color: #e5a000;
}
.bg-warning-darker {
  background-color: #936f38;
}
.hover\:bg-warning-darker:hover {
  background-color: #936f38;
}
.bg-success-lighter {
  background-color: #ecf3ec;
}
.hover\:bg-success-lighter:hover {
  background-color: #ecf3ec;
}
.bg-success-light {
  background-color: #70e17b;
}
.hover\:bg-success-light:hover {
  background-color: #70e17b;
}
.bg-success {
  background-color: #00a91c;
}
.hover\:bg-success:hover {
  background-color: #00a91c;
}
.bg-success-dark {
  background-color: #008817;
}
.hover\:bg-success-dark:hover {
  background-color: #008817;
}
.bg-success-darker {
  background-color: #216e1f;
}
.hover\:bg-success-darker:hover {
  background-color: #216e1f;
}
.bg-info-lighter {
  background-color: #e7f6f8;
}
.hover\:bg-info-lighter:hover {
  background-color: #e7f6f8;
}
.bg-info-light {
  background-color: #99deea;
}
.hover\:bg-info-light:hover {
  background-color: #99deea;
}
.bg-info {
  background-color: #00bde3;
}
.hover\:bg-info:hover {
  background-color: #00bde3;
}
.bg-info-dark {
  background-color: #009ec1;
}
.hover\:bg-info-dark:hover {
  background-color: #009ec1;
}
.bg-info-darker {
  background-color: #2e6276;
}
.hover\:bg-info-darker:hover {
  background-color: #2e6276;
}
.bg-disabled-lighter {
  background-color: #c9c9c9;
}
.hover\:bg-disabled-lighter:hover {
  background-color: #c9c9c9;
}
.bg-disabled-light {
  background-color: #919191;
}
.hover\:bg-disabled-light:hover {
  background-color: #919191;
}
.bg-disabled {
  background-color: #757575;
}
.hover\:bg-disabled:hover {
  background-color: #757575;
}
.bg-disabled-dark {
  background-color: #454545;
}
.hover\:bg-disabled-dark:hover {
  background-color: #454545;
}
.bg-disabled-darker {
  background-color: #1b1b1b;
}
.hover\:bg-disabled-darker:hover {
  background-color: #1b1b1b;
}
.bg-emergency {
  background-color: #9c3d10;
}
.hover\:bg-emergency:hover {
  background-color: #9c3d10;
}
.bg-emergency-dark {
  background-color: #332d29;
}
.hover\:bg-emergency-dark:hover {
  background-color: #332d29;
}
.border-1px {
  border: 1px solid;
}
.hover\:border-1px:hover {
  border: 1px solid;
}
.border-y-1px {
  border-top: 1px solid;
  border-bottom: 1px solid;
}
.hover\:border-y-1px:hover {
  border-top: 1px solid;
  border-bottom: 1px solid;
}
.border-x-1px {
  border-left: 1px solid;
  border-right: 1px solid;
}
.hover\:border-x-1px:hover {
  border-left: 1px solid;
  border-right: 1px solid;
}
.border-top-1px {
  border-top: 1px solid;
}
.hover\:border-top-1px:hover {
  border-top: 1px solid;
}
.border-right-1px {
  border-right: 1px solid;
}
.hover\:border-right-1px:hover {
  border-right: 1px solid;
}
.border-bottom-1px {
  border-bottom: 1px solid;
}
.hover\:border-bottom-1px:hover {
  border-bottom: 1px solid;
}
.border-left-1px {
  border-left: 1px solid;
}
.hover\:border-left-1px:hover {
  border-left: 1px solid;
}
.border-2px {
  border: 2px solid;
}
.hover\:border-2px:hover {
  border: 2px solid;
}
.border-y-2px {
  border-top: 2px solid;
  border-bottom: 2px solid;
}
.hover\:border-y-2px:hover {
  border-top: 2px solid;
  border-bottom: 2px solid;
}
.border-x-2px {
  border-left: 2px solid;
  border-right: 2px solid;
}
.hover\:border-x-2px:hover {
  border-left: 2px solid;
  border-right: 2px solid;
}
.border-top-2px {
  border-top: 2px solid;
}
.hover\:border-top-2px:hover {
  border-top: 2px solid;
}
.border-right-2px {
  border-right: 2px solid;
}
.hover\:border-right-2px:hover {
  border-right: 2px solid;
}
.border-bottom-2px {
  border-bottom: 2px solid;
}
.hover\:border-bottom-2px:hover {
  border-bottom: 2px solid;
}
.border-left-2px {
  border-left: 2px solid;
}
.hover\:border-left-2px:hover {
  border-left: 2px solid;
}
.border-05 {
  border: 0.25rem solid;
}
.hover\:border-05:hover {
  border: 0.25rem solid;
}
.border-y-05 {
  border-top: 0.25rem solid;
  border-bottom: 0.25rem solid;
}
.hover\:border-y-05:hover {
  border-top: 0.25rem solid;
  border-bottom: 0.25rem solid;
}
.border-x-05 {
  border-left: 0.25rem solid;
  border-right: 0.25rem solid;
}
.hover\:border-x-05:hover {
  border-left: 0.25rem solid;
  border-right: 0.25rem solid;
}
.border-top-05 {
  border-top: 0.25rem solid;
}
.hover\:border-top-05:hover {
  border-top: 0.25rem solid;
}
.border-right-05 {
  border-right: 0.25rem solid;
}
.hover\:border-right-05:hover {
  border-right: 0.25rem solid;
}
.border-bottom-05 {
  border-bottom: 0.25rem solid;
}
.hover\:border-bottom-05:hover {
  border-bottom: 0.25rem solid;
}
.border-left-05 {
  border-left: 0.25rem solid;
}
.hover\:border-left-05:hover {
  border-left: 0.25rem solid;
}
.border-1 {
  border: 0.5rem solid;
}
.hover\:border-1:hover {
  border: 0.5rem solid;
}
.border-y-1 {
  border-top: 0.5rem solid;
  border-bottom: 0.5rem solid;
}
.hover\:border-y-1:hover {
  border-top: 0.5rem solid;
  border-bottom: 0.5rem solid;
}
.border-x-1 {
  border-left: 0.5rem solid;
  border-right: 0.5rem solid;
}
.hover\:border-x-1:hover {
  border-left: 0.5rem solid;
  border-right: 0.5rem solid;
}
.border-top-1 {
  border-top: 0.5rem solid;
}
.hover\:border-top-1:hover {
  border-top: 0.5rem solid;
}
.border-right-1 {
  border-right: 0.5rem solid;
}
.hover\:border-right-1:hover {
  border-right: 0.5rem solid;
}
.border-bottom-1 {
  border-bottom: 0.5rem solid;
}
.hover\:border-bottom-1:hover {
  border-bottom: 0.5rem solid;
}
.border-left-1 {
  border-left: 0.5rem solid;
}
.hover\:border-left-1:hover {
  border-left: 0.5rem solid;
}
.border-105 {
  border: 0.75rem solid;
}
.hover\:border-105:hover {
  border: 0.75rem solid;
}
.border-y-105 {
  border-top: 0.75rem solid;
  border-bottom: 0.75rem solid;
}
.hover\:border-y-105:hover {
  border-top: 0.75rem solid;
  border-bottom: 0.75rem solid;
}
.border-x-105 {
  border-left: 0.75rem solid;
  border-right: 0.75rem solid;
}
.hover\:border-x-105:hover {
  border-left: 0.75rem solid;
  border-right: 0.75rem solid;
}
.border-top-105 {
  border-top: 0.75rem solid;
}
.hover\:border-top-105:hover {
  border-top: 0.75rem solid;
}
.border-right-105 {
  border-right: 0.75rem solid;
}
.hover\:border-right-105:hover {
  border-right: 0.75rem solid;
}
.border-bottom-105 {
  border-bottom: 0.75rem solid;
}
.hover\:border-bottom-105:hover {
  border-bottom: 0.75rem solid;
}
.border-left-105 {
  border-left: 0.75rem solid;
}
.hover\:border-left-105:hover {
  border-left: 0.75rem solid;
}
.border-2 {
  border: 1rem solid;
}
.hover\:border-2:hover {
  border: 1rem solid;
}
.border-y-2 {
  border-top: 1rem solid;
  border-bottom: 1rem solid;
}
.hover\:border-y-2:hover {
  border-top: 1rem solid;
  border-bottom: 1rem solid;
}
.border-x-2 {
  border-left: 1rem solid;
  border-right: 1rem solid;
}
.hover\:border-x-2:hover {
  border-left: 1rem solid;
  border-right: 1rem solid;
}
.border-top-2 {
  border-top: 1rem solid;
}
.hover\:border-top-2:hover {
  border-top: 1rem solid;
}
.border-right-2 {
  border-right: 1rem solid;
}
.hover\:border-right-2:hover {
  border-right: 1rem solid;
}
.border-bottom-2 {
  border-bottom: 1rem solid;
}
.hover\:border-bottom-2:hover {
  border-bottom: 1rem solid;
}
.border-left-2 {
  border-left: 1rem solid;
}
.hover\:border-left-2:hover {
  border-left: 1rem solid;
}
.border-205 {
  border: 1.25rem solid;
}
.hover\:border-205:hover {
  border: 1.25rem solid;
}
.border-y-205 {
  border-top: 1.25rem solid;
  border-bottom: 1.25rem solid;
}
.hover\:border-y-205:hover {
  border-top: 1.25rem solid;
  border-bottom: 1.25rem solid;
}
.border-x-205 {
  border-left: 1.25rem solid;
  border-right: 1.25rem solid;
}
.hover\:border-x-205:hover {
  border-left: 1.25rem solid;
  border-right: 1.25rem solid;
}
.border-top-205 {
  border-top: 1.25rem solid;
}
.hover\:border-top-205:hover {
  border-top: 1.25rem solid;
}
.border-right-205 {
  border-right: 1.25rem solid;
}
.hover\:border-right-205:hover {
  border-right: 1.25rem solid;
}
.border-bottom-205 {
  border-bottom: 1.25rem solid;
}
.hover\:border-bottom-205:hover {
  border-bottom: 1.25rem solid;
}
.border-left-205 {
  border-left: 1.25rem solid;
}
.hover\:border-left-205:hover {
  border-left: 1.25rem solid;
}
.border-3 {
  border: 1.5rem solid;
}
.hover\:border-3:hover {
  border: 1.5rem solid;
}
.border-y-3 {
  border-top: 1.5rem solid;
  border-bottom: 1.5rem solid;
}
.hover\:border-y-3:hover {
  border-top: 1.5rem solid;
  border-bottom: 1.5rem solid;
}
.border-x-3 {
  border-left: 1.5rem solid;
  border-right: 1.5rem solid;
}
.hover\:border-x-3:hover {
  border-left: 1.5rem solid;
  border-right: 1.5rem solid;
}
.border-top-3 {
  border-top: 1.5rem solid;
}
.hover\:border-top-3:hover {
  border-top: 1.5rem solid;
}
.border-right-3 {
  border-right: 1.5rem solid;
}
.hover\:border-right-3:hover {
  border-right: 1.5rem solid;
}
.border-bottom-3 {
  border-bottom: 1.5rem solid;
}
.hover\:border-bottom-3:hover {
  border-bottom: 1.5rem solid;
}
.border-left-3 {
  border-left: 1.5rem solid;
}
.hover\:border-left-3:hover {
  border-left: 1.5rem solid;
}
.border-0 {
  border: 0 solid;
}
.hover\:border-0:hover {
  border: 0 solid;
}
.border-y-0 {
  border-top: 0 solid;
  border-bottom: 0 solid;
}
.hover\:border-y-0:hover {
  border-top: 0 solid;
  border-bottom: 0 solid;
}
.border-x-0 {
  border-left: 0 solid;
  border-right: 0 solid;
}
.hover\:border-x-0:hover {
  border-left: 0 solid;
  border-right: 0 solid;
}
.border-top-0 {
  border-top: 0 solid;
}
.hover\:border-top-0:hover {
  border-top: 0 solid;
}
.border-right-0 {
  border-right: 0 solid;
}
.hover\:border-right-0:hover {
  border-right: 0 solid;
}
.border-bottom-0 {
  border-bottom: 0 solid;
}
.hover\:border-bottom-0:hover {
  border-bottom: 0 solid;
}
.border-left-0 {
  border-left: 0 solid;
}
.hover\:border-left-0:hover {
  border-left: 0 solid;
}
.border {
  border: 1px solid;
}
.hover\:border:hover {
  border: 1px solid;
}
.border-y {
  border-top: 1px solid;
  border-bottom: 1px solid;
}
.hover\:border-y:hover {
  border-top: 1px solid;
  border-bottom: 1px solid;
}
.border-x {
  border-left: 1px solid;
  border-right: 1px solid;
}
.hover\:border-x:hover {
  border-left: 1px solid;
  border-right: 1px solid;
}
.border-top {
  border-top: 1px solid;
}
.hover\:border-top:hover {
  border-top: 1px solid;
}
.border-right {
  border-right: 1px solid;
}
.hover\:border-right:hover {
  border-right: 1px solid;
}
.border-bottom {
  border-bottom: 1px solid;
}
.hover\:border-bottom:hover {
  border-bottom: 1px solid;
}
.border-left {
  border-left: 1px solid;
}
.hover\:border-left:hover {
  border-left: 1px solid;
}
.border-transparent {
  border-color: transparent;
}
.hover\:border-transparent:hover {
  border-color: transparent;
}
.border-black {
  border-color: black;
}
.hover\:border-black:hover {
  border-color: black;
}
.border-white {
  border-color: white;
}
.hover\:border-white:hover {
  border-color: white;
}
.border-red {
  border-color: #e52207;
}
.hover\:border-red:hover {
  border-color: #e52207;
}
.border-orange {
  border-color: #e66f0e;
}
.hover\:border-orange:hover {
  border-color: #e66f0e;
}
.border-gold {
  border-color: #ffbe2e;
}
.hover\:border-gold:hover {
  border-color: #ffbe2e;
}
.border-yellow {
  border-color: #fee685;
}
.hover\:border-yellow:hover {
  border-color: #fee685;
}
.border-green {
  border-color: #538200;
}
.hover\:border-green:hover {
  border-color: #538200;
}
.border-mint {
  border-color: #04c585;
}
.hover\:border-mint:hover {
  border-color: #04c585;
}
.border-cyan {
  border-color: #009ec1;
}
.hover\:border-cyan:hover {
  border-color: #009ec1;
}
.border-blue {
  border-color: #0076d6;
}
.hover\:border-blue:hover {
  border-color: #0076d6;
}
.border-indigo {
  border-color: #676cc8;
}
.hover\:border-indigo:hover {
  border-color: #676cc8;
}
.border-violet {
  border-color: #8168b3;
}
.hover\:border-violet:hover {
  border-color: #8168b3;
}
.border-magenta {
  border-color: #d72d79;
}
.hover\:border-magenta:hover {
  border-color: #d72d79;
}
.border-gray-5 {
  border-color: #f0f0f0;
}
.hover\:border-gray-5:hover {
  border-color: #f0f0f0;
}
.border-gray-10 {
  border-color: #e6e6e6;
}
.hover\:border-gray-10:hover {
  border-color: #e6e6e6;
}
.border-gray-30 {
  border-color: #adadad;
}
.hover\:border-gray-30:hover {
  border-color: #adadad;
}
.border-gray-50 {
  border-color: #757575;
}
.hover\:border-gray-50:hover {
  border-color: #757575;
}
.border-gray-70 {
  border-color: #454545;
}
.hover\:border-gray-70:hover {
  border-color: #454545;
}
.border-gray-90 {
  border-color: #1b1b1b;
}
.hover\:border-gray-90:hover {
  border-color: #1b1b1b;
}
.border-base-lightest {
  border-color: #f0f0f0;
}
.hover\:border-base-lightest:hover {
  border-color: #f0f0f0;
}
.border-base-lighter {
  border-color: #dfe1e2;
}
.hover\:border-base-lighter:hover {
  border-color: #dfe1e2;
}
.border-base-light {
  border-color: #a9aeb1;
}
.hover\:border-base-light:hover {
  border-color: #a9aeb1;
}
.border-base {
  border-color: #71767a;
}
.hover\:border-base:hover {
  border-color: #71767a;
}
.border-base-dark {
  border-color: #565c65;
}
.hover\:border-base-dark:hover {
  border-color: #565c65;
}
.border-base-darker {
  border-color: #3d4551;
}
.hover\:border-base-darker:hover {
  border-color: #3d4551;
}
.border-base-darkest {
  border-color: #1b1b1b;
}
.hover\:border-base-darkest:hover {
  border-color: #1b1b1b;
}
.border-ink {
  border-color: #11181d;
}
.hover\:border-ink:hover {
  border-color: #11181d;
}
.border-primary-lightest {
  border-color: #B3EFFF;
}
.hover\:border-primary-lightest:hover {
  border-color: #B3EFFF;
}
.border-primary-lighter {
  border-color: #97d4ea;
}
.hover\:border-primary-lighter:hover {
  border-color: #97d4ea;
}
.border-primary-light {
  border-color: #28a0cb;
}
.hover\:border-primary-light:hover {
  border-color: #28a0cb;
}
.border-primary {
  border-color: #046B99;
}
.hover\:border-primary:hover {
  border-color: #046B99;
}
.border-primary-vivid {
  border-color: #0050d8;
}
.hover\:border-primary-vivid:hover {
  border-color: #0050d8;
}
.border-primary-dark {
  border-color: #0b4778;
}
.hover\:border-primary-dark:hover {
  border-color: #0b4778;
}
.border-primary-darker {
  border-color: #1C304A;
}
.hover\:border-primary-darker:hover {
  border-color: #1C304A;
}
.border-primary-darkest {
  border-color: #11181d;
}
.hover\:border-primary-darkest:hover {
  border-color: #11181d;
}
.border-secondary-lighter {
  border-color: #f3e1e4;
}
.hover\:border-secondary-lighter:hover {
  border-color: #f3e1e4;
}
.border-secondary-light {
  border-color: #f2938c;
}
.hover\:border-secondary-light:hover {
  border-color: #f2938c;
}
.border-secondary {
  border-color: #d83933;
}
.hover\:border-secondary:hover {
  border-color: #d83933;
}
.border-secondary-vivid {
  border-color: #e41d3d;
}
.hover\:border-secondary-vivid:hover {
  border-color: #e41d3d;
}
.border-secondary-dark {
  border-color: #b50909;
}
.hover\:border-secondary-dark:hover {
  border-color: #b50909;
}
.border-secondary-darker {
  border-color: #8b0a03;
}
.hover\:border-secondary-darker:hover {
  border-color: #8b0a03;
}
.border-accent-warm-darker {
  border-color: #775540;
}
.hover\:border-accent-warm-darker:hover {
  border-color: #775540;
}
.border-accent-warm-dark {
  border-color: #c05600;
}
.hover\:border-accent-warm-dark:hover {
  border-color: #c05600;
}
.border-accent-warm {
  border-color: #fa9441;
}
.hover\:border-accent-warm:hover {
  border-color: #fa9441;
}
.border-accent-warm-light {
  border-color: #ffbc78;
}
.hover\:border-accent-warm-light:hover {
  border-color: #ffbc78;
}
.border-accent-warm-lighter {
  border-color: #f2e4d4;
}
.hover\:border-accent-warm-lighter:hover {
  border-color: #f2e4d4;
}
.border-accent-cool-darkest {
  border-color: #093b44;
}
.hover\:border-accent-cool-darkest:hover {
  border-color: #093b44;
}
.border-accent-cool-darker {
  border-color: #0e4f5c;
}
.hover\:border-accent-cool-darker:hover {
  border-color: #0e4f5c;
}
.border-accent-cool-dark {
  border-color: #0081a1;
}
.hover\:border-accent-cool-dark:hover {
  border-color: #0081a1;
}
.border-accent-cool {
  border-color: #00CFFF;
}
.hover\:border-accent-cool:hover {
  border-color: #00CFFF;
}
.border-accent-cool-light {
  border-color: #52daf2;
}
.hover\:border-accent-cool-light:hover {
  border-color: #52daf2;
}
.border-accent-cool-lighter {
  border-color: #a8f2ff;
}
.hover\:border-accent-cool-lighter:hover {
  border-color: #a8f2ff;
}
.border-accent-cool-lightest {
  border-color: #eff6fb;
}
.hover\:border-accent-cool-lightest:hover {
  border-color: #eff6fb;
}
.border-error-lighter {
  border-color: #fff5ee;
}
.hover\:border-error-lighter:hover {
  border-color: #fff5ee;
}
.border-error-light {
  border-color: #f39268;
}
.hover\:border-error-light:hover {
  border-color: #f39268;
}
.border-error {
  border-color: #d54309;
}
.hover\:border-error:hover {
  border-color: #d54309;
}
.border-error-dark {
  border-color: #b50909;
}
.hover\:border-error-dark:hover {
  border-color: #b50909;
}
.border-error-darker {
  border-color: #6f3331;
}
.hover\:border-error-darker:hover {
  border-color: #6f3331;
}
.border-warning-lighter {
  border-color: #faf3d1;
}
.hover\:border-warning-lighter:hover {
  border-color: #faf3d1;
}
.border-warning-light {
  border-color: #fee685;
}
.hover\:border-warning-light:hover {
  border-color: #fee685;
}
.border-warning {
  border-color: #ffbe2e;
}
.hover\:border-warning:hover {
  border-color: #ffbe2e;
}
.border-warning-dark {
  border-color: #e5a000;
}
.hover\:border-warning-dark:hover {
  border-color: #e5a000;
}
.border-warning-darker {
  border-color: #936f38;
}
.hover\:border-warning-darker:hover {
  border-color: #936f38;
}
.border-success-lighter {
  border-color: #ecf3ec;
}
.hover\:border-success-lighter:hover {
  border-color: #ecf3ec;
}
.border-success-light {
  border-color: #70e17b;
}
.hover\:border-success-light:hover {
  border-color: #70e17b;
}
.border-success {
  border-color: #00a91c;
}
.hover\:border-success:hover {
  border-color: #00a91c;
}
.border-success-dark {
  border-color: #008817;
}
.hover\:border-success-dark:hover {
  border-color: #008817;
}
.border-success-darker {
  border-color: #216e1f;
}
.hover\:border-success-darker:hover {
  border-color: #216e1f;
}
.border-info-lighter {
  border-color: #e7f6f8;
}
.hover\:border-info-lighter:hover {
  border-color: #e7f6f8;
}
.border-info-light {
  border-color: #99deea;
}
.hover\:border-info-light:hover {
  border-color: #99deea;
}
.border-info {
  border-color: #00bde3;
}
.hover\:border-info:hover {
  border-color: #00bde3;
}
.border-info-dark {
  border-color: #009ec1;
}
.hover\:border-info-dark:hover {
  border-color: #009ec1;
}
.border-info-darker {
  border-color: #2e6276;
}
.hover\:border-info-darker:hover {
  border-color: #2e6276;
}
.border-disabled-lighter {
  border-color: #c9c9c9;
}
.hover\:border-disabled-lighter:hover {
  border-color: #c9c9c9;
}
.border-disabled-light {
  border-color: #919191;
}
.hover\:border-disabled-light:hover {
  border-color: #919191;
}
.border-disabled {
  border-color: #757575;
}
.hover\:border-disabled:hover {
  border-color: #757575;
}
.border-disabled-dark {
  border-color: #454545;
}
.hover\:border-disabled-dark:hover {
  border-color: #454545;
}
.border-disabled-darker {
  border-color: #1b1b1b;
}
.hover\:border-disabled-darker:hover {
  border-color: #1b1b1b;
}
.border-emergency {
  border-color: #9c3d10;
}
.hover\:border-emergency:hover {
  border-color: #9c3d10;
}
.border-emergency-dark {
  border-color: #332d29;
}
.hover\:border-emergency-dark:hover {
  border-color: #332d29;
}
.radius-0 {
  border-radius: 0;
}
.radius-top-0 {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.radius-right-0 {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.radius-bottom-0 {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.radius-left-0 {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.radius-sm {
  border-radius: 2px;
}
.radius-top-sm {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.radius-right-sm {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.radius-bottom-sm {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}
.radius-left-sm {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.radius-md {
  border-radius: 0.25rem;
}
.radius-top-md {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.radius-right-md {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.radius-bottom-md {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.radius-left-md {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.radius-lg {
  border-radius: 0.5rem;
}
.radius-top-lg {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.radius-right-lg {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.radius-bottom-lg {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.radius-left-lg {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.radius-pill {
  border-radius: 99rem;
}
.radius-top-pill {
  border-top-left-radius: 99rem;
  border-top-right-radius: 99rem;
}
.radius-right-pill {
  border-top-right-radius: 99rem;
  border-bottom-right-radius: 99rem;
}
.radius-bottom-pill {
  border-bottom-left-radius: 99rem;
  border-bottom-right-radius: 99rem;
}
.radius-left-pill {
  border-top-left-radius: 99rem;
  border-bottom-left-radius: 99rem;
}
.border-dashed {
  border-style: dashed;
}
.border-dotted {
  border-style: dotted;
}
.border-solid {
  border-style: solid;
}
.border-width-1px {
  border-width: 1px;
}
.border-y-width-1px {
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.border-x-width-1px {
  border-left-width: 1px;
  border-right-width: 1px;
}
.border-top-width-1px {
  border-top-width: 1px;
}
.border-right-width-1px {
  border-right-width: 1px;
}
.border-bottom-width-1px {
  border-bottom-width: 1px;
}
.border-left-width-1px {
  border-left-width: 1px;
}
.border-width-2px {
  border-width: 2px;
}
.border-y-width-2px {
  border-top-width: 2px;
  border-bottom-width: 2px;
}
.border-x-width-2px {
  border-left-width: 2px;
  border-right-width: 2px;
}
.border-top-width-2px {
  border-top-width: 2px;
}
.border-right-width-2px {
  border-right-width: 2px;
}
.border-bottom-width-2px {
  border-bottom-width: 2px;
}
.border-left-width-2px {
  border-left-width: 2px;
}
.border-width-05 {
  border-width: 0.25rem;
}
.border-y-width-05 {
  border-top-width: 0.25rem;
  border-bottom-width: 0.25rem;
}
.border-x-width-05 {
  border-left-width: 0.25rem;
  border-right-width: 0.25rem;
}
.border-top-width-05 {
  border-top-width: 0.25rem;
}
.border-right-width-05 {
  border-right-width: 0.25rem;
}
.border-bottom-width-05 {
  border-bottom-width: 0.25rem;
}
.border-left-width-05 {
  border-left-width: 0.25rem;
}
.border-width-1 {
  border-width: 0.5rem;
}
.border-y-width-1 {
  border-top-width: 0.5rem;
  border-bottom-width: 0.5rem;
}
.border-x-width-1 {
  border-left-width: 0.5rem;
  border-right-width: 0.5rem;
}
.border-top-width-1 {
  border-top-width: 0.5rem;
}
.border-right-width-1 {
  border-right-width: 0.5rem;
}
.border-bottom-width-1 {
  border-bottom-width: 0.5rem;
}
.border-left-width-1 {
  border-left-width: 0.5rem;
}
.border-width-105 {
  border-width: 0.75rem;
}
.border-y-width-105 {
  border-top-width: 0.75rem;
  border-bottom-width: 0.75rem;
}
.border-x-width-105 {
  border-left-width: 0.75rem;
  border-right-width: 0.75rem;
}
.border-top-width-105 {
  border-top-width: 0.75rem;
}
.border-right-width-105 {
  border-right-width: 0.75rem;
}
.border-bottom-width-105 {
  border-bottom-width: 0.75rem;
}
.border-left-width-105 {
  border-left-width: 0.75rem;
}
.border-width-2 {
  border-width: 1rem;
}
.border-y-width-2 {
  border-top-width: 1rem;
  border-bottom-width: 1rem;
}
.border-x-width-2 {
  border-left-width: 1rem;
  border-right-width: 1rem;
}
.border-top-width-2 {
  border-top-width: 1rem;
}
.border-right-width-2 {
  border-right-width: 1rem;
}
.border-bottom-width-2 {
  border-bottom-width: 1rem;
}
.border-left-width-2 {
  border-left-width: 1rem;
}
.border-width-205 {
  border-width: 1.25rem;
}
.border-y-width-205 {
  border-top-width: 1.25rem;
  border-bottom-width: 1.25rem;
}
.border-x-width-205 {
  border-left-width: 1.25rem;
  border-right-width: 1.25rem;
}
.border-top-width-205 {
  border-top-width: 1.25rem;
}
.border-right-width-205 {
  border-right-width: 1.25rem;
}
.border-bottom-width-205 {
  border-bottom-width: 1.25rem;
}
.border-left-width-205 {
  border-left-width: 1.25rem;
}
.border-width-3 {
  border-width: 1.5rem;
}
.border-y-width-3 {
  border-top-width: 1.5rem;
  border-bottom-width: 1.5rem;
}
.border-x-width-3 {
  border-left-width: 1.5rem;
  border-right-width: 1.5rem;
}
.border-top-width-3 {
  border-top-width: 1.5rem;
}
.border-right-width-3 {
  border-right-width: 1.5rem;
}
.border-bottom-width-3 {
  border-bottom-width: 1.5rem;
}
.border-left-width-3 {
  border-left-width: 1.5rem;
}
.border-width-0 {
  border-width: 0;
}
.border-y-width-0 {
  border-top-width: 0;
  border-bottom-width: 0;
}
.border-x-width-0 {
  border-left-width: 0;
  border-right-width: 0;
}
.border-top-width-0 {
  border-top-width: 0;
}
.border-right-width-0 {
  border-right-width: 0;
}
.border-bottom-width-0 {
  border-bottom-width: 0;
}
.border-left-width-0 {
  border-left-width: 0;
}
.bottom-1px {
  bottom: 1px;
}
.bottom-2px {
  bottom: 2px;
}
.bottom-05 {
  bottom: 0.25rem;
}
.bottom-1 {
  bottom: 0.5rem;
}
.bottom-105 {
  bottom: 0.75rem;
}
.bottom-2 {
  bottom: 1rem;
}
.bottom-205 {
  bottom: 1.25rem;
}
.bottom-3 {
  bottom: 1.5rem;
}
.bottom-neg-1px {
  bottom: -1px;
}
.bottom-neg-2px {
  bottom: -2px;
}
.bottom-neg-05 {
  bottom: -0.25rem;
}
.bottom-neg-1 {
  bottom: -0.5rem;
}
.bottom-neg-105 {
  bottom: -0.75rem;
}
.bottom-neg-2 {
  bottom: -1rem;
}
.bottom-neg-205 {
  bottom: -1.25rem;
}
.bottom-neg-3 {
  bottom: -1.5rem;
}
.bottom-0 {
  bottom: 0;
}
.bottom-auto {
  bottom: auto;
}
.bottom-full {
  bottom: 100%;
}
.shadow-none {
  box-shadow: none;
}
.hover\:shadow-none:hover {
  box-shadow: none;
}
.shadow-1 {
  box-shadow: 0 1px 0.25rem 0 rgba(0, 0, 0, 0.1);
}
.hover\:shadow-1:hover {
  box-shadow: 0 1px 0.25rem 0 rgba(0, 0, 0, 0.1);
}
.shadow-2 {
  box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.1);
}
.hover\:shadow-2:hover {
  box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.1);
}
.shadow-3 {
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}
.hover\:shadow-3:hover {
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}
.shadow-4 {
  box-shadow: 0 0.75rem 1.5rem 0 rgba(0, 0, 0, 0.1);
}
.hover\:shadow-4:hover {
  box-shadow: 0 0.75rem 1.5rem 0 rgba(0, 0, 0, 0.1);
}
.shadow-5 {
  box-shadow: 0 1rem 2rem 0 rgba(0, 0, 0, 0.1);
}
.hover\:shadow-5:hover {
  box-shadow: 0 1rem 2rem 0 rgba(0, 0, 0, 0.1);
}
.circle-1px {
  height: 1px;
  width: 1px;
  border-radius: 50%;
}
.circle-2px {
  height: 2px;
  width: 2px;
  border-radius: 50%;
}
.circle-05 {
  height: 0.25rem;
  width: 0.25rem;
  border-radius: 50%;
}
.circle-1 {
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50%;
}
.circle-105 {
  height: 0.75rem;
  width: 0.75rem;
  border-radius: 50%;
}
.circle-2 {
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
}
.circle-205 {
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 50%;
}
.circle-3 {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
}
.circle-4 {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
}
.circle-5 {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
}
.circle-6 {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
}
.circle-7 {
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 50%;
}
.circle-8 {
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
}
.circle-9 {
  height: 4.5rem;
  width: 4.5rem;
  border-radius: 50%;
}
.circle-10 {
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
}
.circle-15 {
  height: 7.5rem;
  width: 7.5rem;
  border-radius: 50%;
}
.circle-card {
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
}
.circle-card-lg {
  height: 15rem;
  width: 15rem;
  border-radius: 50%;
}
.circle-mobile {
  height: 20rem;
  width: 20rem;
  border-radius: 50%;
}
.clearfix::after {
  content: "";
  display: block;
  clear: both;
}
.text-transparent {
  color: transparent;
}
.hover\:text-transparent:hover {
  color: transparent;
}
.text-black {
  color: black;
}
.hover\:text-black:hover {
  color: black;
}
.text-white {
  color: white;
}
.hover\:text-white:hover {
  color: white;
}
.text-red {
  color: #e52207;
}
.hover\:text-red:hover {
  color: #e52207;
}
.text-orange {
  color: #e66f0e;
}
.hover\:text-orange:hover {
  color: #e66f0e;
}
.text-gold {
  color: #ffbe2e;
}
.hover\:text-gold:hover {
  color: #ffbe2e;
}
.text-yellow {
  color: #fee685;
}
.hover\:text-yellow:hover {
  color: #fee685;
}
.text-green {
  color: #538200;
}
.hover\:text-green:hover {
  color: #538200;
}
.text-mint {
  color: #04c585;
}
.hover\:text-mint:hover {
  color: #04c585;
}
.text-cyan {
  color: #009ec1;
}
.hover\:text-cyan:hover {
  color: #009ec1;
}
.text-blue {
  color: #0076d6;
}
.hover\:text-blue:hover {
  color: #0076d6;
}
.text-indigo {
  color: #676cc8;
}
.hover\:text-indigo:hover {
  color: #676cc8;
}
.text-violet {
  color: #8168b3;
}
.hover\:text-violet:hover {
  color: #8168b3;
}
.text-magenta {
  color: #d72d79;
}
.hover\:text-magenta:hover {
  color: #d72d79;
}
.text-gray-5 {
  color: #f0f0f0;
}
.hover\:text-gray-5:hover {
  color: #f0f0f0;
}
.text-gray-10 {
  color: #e6e6e6;
}
.hover\:text-gray-10:hover {
  color: #e6e6e6;
}
.text-gray-30 {
  color: #adadad;
}
.hover\:text-gray-30:hover {
  color: #adadad;
}
.text-gray-50 {
  color: #757575;
}
.hover\:text-gray-50:hover {
  color: #757575;
}
.text-gray-70 {
  color: #454545;
}
.hover\:text-gray-70:hover {
  color: #454545;
}
.text-gray-90 {
  color: #1b1b1b;
}
.hover\:text-gray-90:hover {
  color: #1b1b1b;
}
.text-base-lightest {
  color: #f0f0f0;
}
.hover\:text-base-lightest:hover {
  color: #f0f0f0;
}
.text-base-lighter {
  color: #dfe1e2;
}
.hover\:text-base-lighter:hover {
  color: #dfe1e2;
}
.text-base-light {
  color: #a9aeb1;
}
.hover\:text-base-light:hover {
  color: #a9aeb1;
}
.text-base {
  color: #71767a;
}
.hover\:text-base:hover {
  color: #71767a;
}
.text-base-dark {
  color: #565c65;
}
.hover\:text-base-dark:hover {
  color: #565c65;
}
.text-base-darker {
  color: #3d4551;
}
.hover\:text-base-darker:hover {
  color: #3d4551;
}
.text-base-darkest {
  color: #1b1b1b;
}
.hover\:text-base-darkest:hover {
  color: #1b1b1b;
}
.text-ink {
  color: #11181d;
}
.hover\:text-ink:hover {
  color: #11181d;
}
.text-primary-lightest {
  color: #B3EFFF;
}
.hover\:text-primary-lightest:hover {
  color: #B3EFFF;
}
.text-primary-lighter {
  color: #97d4ea;
}
.hover\:text-primary-lighter:hover {
  color: #97d4ea;
}
.text-primary-light {
  color: #28a0cb;
}
.hover\:text-primary-light:hover {
  color: #28a0cb;
}
.text-primary {
  color: #046B99;
}
.hover\:text-primary:hover {
  color: #046B99;
}
.text-primary-vivid {
  color: #0050d8;
}
.hover\:text-primary-vivid:hover {
  color: #0050d8;
}
.text-primary-dark {
  color: #0b4778;
}
.hover\:text-primary-dark:hover {
  color: #0b4778;
}
.text-primary-darker {
  color: #1C304A;
}
.hover\:text-primary-darker:hover {
  color: #1C304A;
}
.text-primary-darkest {
  color: #11181d;
}
.hover\:text-primary-darkest:hover {
  color: #11181d;
}
.text-secondary-lighter {
  color: #f3e1e4;
}
.hover\:text-secondary-lighter:hover {
  color: #f3e1e4;
}
.text-secondary-light {
  color: #f2938c;
}
.hover\:text-secondary-light:hover {
  color: #f2938c;
}
.text-secondary {
  color: #d83933;
}
.hover\:text-secondary:hover {
  color: #d83933;
}
.text-secondary-vivid {
  color: #e41d3d;
}
.hover\:text-secondary-vivid:hover {
  color: #e41d3d;
}
.text-secondary-dark {
  color: #b50909;
}
.hover\:text-secondary-dark:hover {
  color: #b50909;
}
.text-secondary-darker {
  color: #8b0a03;
}
.hover\:text-secondary-darker:hover {
  color: #8b0a03;
}
.text-accent-warm-darker {
  color: #775540;
}
.hover\:text-accent-warm-darker:hover {
  color: #775540;
}
.text-accent-warm-dark {
  color: #c05600;
}
.hover\:text-accent-warm-dark:hover {
  color: #c05600;
}
.text-accent-warm {
  color: #fa9441;
}
.hover\:text-accent-warm:hover {
  color: #fa9441;
}
.text-accent-warm-light {
  color: #ffbc78;
}
.hover\:text-accent-warm-light:hover {
  color: #ffbc78;
}
.text-accent-warm-lighter {
  color: #f2e4d4;
}
.hover\:text-accent-warm-lighter:hover {
  color: #f2e4d4;
}
.text-accent-cool-darkest {
  color: #093b44;
}
.hover\:text-accent-cool-darkest:hover {
  color: #093b44;
}
.text-accent-cool-darker {
  color: #0e4f5c;
}
.hover\:text-accent-cool-darker:hover {
  color: #0e4f5c;
}
.text-accent-cool-dark {
  color: #0081a1;
}
.hover\:text-accent-cool-dark:hover {
  color: #0081a1;
}
.text-accent-cool {
  color: #00CFFF;
}
.hover\:text-accent-cool:hover {
  color: #00CFFF;
}
.text-accent-cool-light {
  color: #52daf2;
}
.hover\:text-accent-cool-light:hover {
  color: #52daf2;
}
.text-accent-cool-lighter {
  color: #a8f2ff;
}
.hover\:text-accent-cool-lighter:hover {
  color: #a8f2ff;
}
.text-accent-cool-lightest {
  color: #eff6fb;
}
.hover\:text-accent-cool-lightest:hover {
  color: #eff6fb;
}
.text-error-lighter {
  color: #fff5ee;
}
.hover\:text-error-lighter:hover {
  color: #fff5ee;
}
.text-error-light {
  color: #f39268;
}
.hover\:text-error-light:hover {
  color: #f39268;
}
.text-error {
  color: #d54309;
}
.hover\:text-error:hover {
  color: #d54309;
}
.text-error-dark {
  color: #b50909;
}
.hover\:text-error-dark:hover {
  color: #b50909;
}
.text-error-darker {
  color: #6f3331;
}
.hover\:text-error-darker:hover {
  color: #6f3331;
}
.text-warning-lighter {
  color: #faf3d1;
}
.hover\:text-warning-lighter:hover {
  color: #faf3d1;
}
.text-warning-light {
  color: #fee685;
}
.hover\:text-warning-light:hover {
  color: #fee685;
}
.text-warning {
  color: #ffbe2e;
}
.hover\:text-warning:hover {
  color: #ffbe2e;
}
.text-warning-dark {
  color: #e5a000;
}
.hover\:text-warning-dark:hover {
  color: #e5a000;
}
.text-warning-darker {
  color: #936f38;
}
.hover\:text-warning-darker:hover {
  color: #936f38;
}
.text-success-lighter {
  color: #ecf3ec;
}
.hover\:text-success-lighter:hover {
  color: #ecf3ec;
}
.text-success-light {
  color: #70e17b;
}
.hover\:text-success-light:hover {
  color: #70e17b;
}
.text-success {
  color: #00a91c;
}
.hover\:text-success:hover {
  color: #00a91c;
}
.text-success-dark {
  color: #008817;
}
.hover\:text-success-dark:hover {
  color: #008817;
}
.text-success-darker {
  color: #216e1f;
}
.hover\:text-success-darker:hover {
  color: #216e1f;
}
.text-info-lighter {
  color: #e7f6f8;
}
.hover\:text-info-lighter:hover {
  color: #e7f6f8;
}
.text-info-light {
  color: #99deea;
}
.hover\:text-info-light:hover {
  color: #99deea;
}
.text-info {
  color: #00bde3;
}
.hover\:text-info:hover {
  color: #00bde3;
}
.text-info-dark {
  color: #009ec1;
}
.hover\:text-info-dark:hover {
  color: #009ec1;
}
.text-info-darker {
  color: #2e6276;
}
.hover\:text-info-darker:hover {
  color: #2e6276;
}
.text-disabled-lighter {
  color: #c9c9c9;
}
.hover\:text-disabled-lighter:hover {
  color: #c9c9c9;
}
.text-disabled-light {
  color: #919191;
}
.hover\:text-disabled-light:hover {
  color: #919191;
}
.text-disabled {
  color: #757575;
}
.hover\:text-disabled:hover {
  color: #757575;
}
.text-disabled-dark {
  color: #454545;
}
.hover\:text-disabled-dark:hover {
  color: #454545;
}
.text-disabled-darker {
  color: #1b1b1b;
}
.hover\:text-disabled-darker:hover {
  color: #1b1b1b;
}
.text-emergency {
  color: #9c3d10;
}
.hover\:text-emergency:hover {
  color: #9c3d10;
}
.text-emergency-dark {
  color: #332d29;
}
.hover\:text-emergency-dark:hover {
  color: #332d29;
}
.cursor-auto {
  cursor: auto;
}
.cursor-default {
  cursor: default;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-wait {
  cursor: wait;
}
.cursor-move {
  cursor: move;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.display-block {
  display: block;
}
.display-flex {
  display: flex;
}
.display-none {
  display: none;
}
.display-inline {
  display: inline;
}
.display-inline-block {
  display: inline-block;
}
.display-inline-flex {
  display: inline-flex;
}
.display-table {
  display: table;
}
.display-table-cell {
  display: table-cell;
}
.display-table-row {
  display: table-row;
}
.flex-1 {
  flex: 1 1 0%;
}
.flex-2 {
  flex: 2 1 0%;
}
.flex-3 {
  flex: 3 1 0%;
}
.flex-4 {
  flex: 4 1 0%;
}
.flex-5 {
  flex: 5 1 0%;
}
.flex-6 {
  flex: 6 1 0%;
}
.flex-7 {
  flex: 7 1 0%;
}
.flex-8 {
  flex: 8 1 0%;
}
.flex-9 {
  flex: 9 1 0%;
}
.flex-10 {
  flex: 10 1 0%;
}
.flex-11 {
  flex: 11 1 0%;
}
.flex-12 {
  flex: 12 1 0%;
}
.flex-fill {
  flex: 1 1 0%;
}
.flex-auto {
  flex: 0 1 auto;
}
.flex-row {
  flex-direction: row;
}
.flex-column {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-no-wrap {
  flex-wrap: nowrap;
}
.float-left {
  float: left;
}
.float-none {
  float: none;
}
.float-right {
  float: right;
}
.font-mono-3xs {
  font-size: 0.77rem;
}
.font-mono-2xs {
  font-size: 0.83rem;
}
.font-mono-xs {
  font-size: 0.89rem;
}
.font-mono-sm {
  font-size: 0.95rem;
}
.font-mono-md {
  font-size: 1.01rem;
}
.font-mono-lg {
  font-size: 1.31rem;
}
.font-mono-xl {
  font-size: 1.91rem;
}
.font-mono-2xl {
  font-size: 2.38rem;
}
.font-mono-3xl {
  font-size: 2.86rem;
}
.font-sans-3xs {
  font-size: 0.82rem;
}
.font-sans-2xs {
  font-size: 0.89rem;
}
.font-sans-xs {
  font-size: 0.95rem;
}
.font-sans-sm {
  font-size: 1.01rem;
}
.font-sans-md {
  font-size: 1.08rem;
}
.font-sans-lg {
  font-size: 1.39rem;
}
.font-sans-xl {
  font-size: 2.03rem;
}
.font-sans-2xl {
  font-size: 2.54rem;
}
.font-sans-3xl {
  font-size: 3.04rem;
}
.font-serif-3xs {
  font-size: 0.79rem;
}
.font-serif-2xs {
  font-size: 0.85rem;
}
.font-serif-xs {
  font-size: 0.91rem;
}
.font-serif-sm {
  font-size: 0.98rem;
}
.font-serif-md {
  font-size: 1.04rem;
}
.font-serif-lg {
  font-size: 1.34rem;
}
.font-serif-xl {
  font-size: 1.95rem;
}
.font-serif-2xl {
  font-size: 2.44rem;
}
.font-serif-3xl {
  font-size: 2.93rem;
}
.font-heading-3xs {
  font-size: 0.82rem;
}
.font-heading-2xs {
  font-size: 0.89rem;
}
.font-heading-xs {
  font-size: 0.95rem;
}
.font-heading-sm {
  font-size: 1.01rem;
}
.font-heading-md {
  font-size: 1.08rem;
}
.font-heading-lg {
  font-size: 1.39rem;
}
.font-heading-xl {
  font-size: 2.03rem;
}
.font-heading-2xl {
  font-size: 2.54rem;
}
.font-heading-3xl {
  font-size: 3.04rem;
}
.font-body-3xs {
  font-size: 0.82rem;
}
.font-body-2xs {
  font-size: 0.89rem;
}
.font-body-xs {
  font-size: 0.95rem;
}
.font-body-sm {
  font-size: 1.01rem;
}
.font-body-md {
  font-size: 1.08rem;
}
.font-body-lg {
  font-size: 1.39rem;
}
.font-body-xl {
  font-size: 2.03rem;
}
.font-body-2xl {
  font-size: 2.54rem;
}
.font-body-3xl {
  font-size: 3.04rem;
}
.font-code-3xs {
  font-size: 0.77rem;
}
.font-code-2xs {
  font-size: 0.83rem;
}
.font-code-xs {
  font-size: 0.89rem;
}
.font-code-sm {
  font-size: 0.95rem;
}
.font-code-md {
  font-size: 1.01rem;
}
.font-code-lg {
  font-size: 1.31rem;
}
.font-code-xl {
  font-size: 1.91rem;
}
.font-code-2xl {
  font-size: 2.38rem;
}
.font-code-3xl {
  font-size: 2.86rem;
}
.font-alt-3xs {
  font-size: 0.79rem;
}
.font-alt-2xs {
  font-size: 0.85rem;
}
.font-alt-xs {
  font-size: 0.91rem;
}
.font-alt-sm {
  font-size: 0.98rem;
}
.font-alt-md {
  font-size: 1.04rem;
}
.font-alt-lg {
  font-size: 1.34rem;
}
.font-alt-xl {
  font-size: 1.95rem;
}
.font-alt-2xl {
  font-size: 2.44rem;
}
.font-alt-3xl {
  font-size: 2.93rem;
}
.font-ui-3xs {
  font-size: 0.82rem;
}
.font-ui-2xs {
  font-size: 0.89rem;
}
.font-ui-xs {
  font-size: 0.95rem;
}
.font-ui-sm {
  font-size: 1.01rem;
}
.font-ui-md {
  font-size: 1.08rem;
}
.font-ui-lg {
  font-size: 1.39rem;
}
.font-ui-xl {
  font-size: 2.03rem;
}
.font-ui-2xl {
  font-size: 2.54rem;
}
.font-ui-3xl {
  font-size: 3.04rem;
}
.font-family-mono {
  font-family:
    Roboto Mono Web,
    Bitstream Vera Sans Mono,
    Consolas,
    Courier,
    monospace;
}
.font-family-sans {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
}
.font-family-serif {
  font-family:
    Merriweather Web,
    Georgia,
    Cambria,
    Times New Roman,
    Times,
    serif;
}
.font-family-ui {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
}
.font-family-heading {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
}
.font-family-body {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
}
.font-family-code {
  font-family:
    Roboto Mono Web,
    Bitstream Vera Sans Mono,
    Consolas,
    Courier,
    monospace;
}
.font-family-alt {
  font-family:
    Merriweather Web,
    Georgia,
    Cambria,
    Times New Roman,
    Times,
    serif;
}
.text-tabular {
  font-feature-settings: "tnum" 1, "kern" 1;
}
.text-no-tabular {
  font-feature-settings: "kern" 1;
}
.text-italic {
  font-style: italic;
}
.text-no-italic {
  font-style: normal;
}
.text-light {
  font-weight: 300;
}
.text-normal {
  font-weight: normal;
}
.text-semibold {
  font-weight: 700;
}
.text-bold {
  font-weight: 700;
}
.height-1px {
  height: 1px;
}
.height-2px {
  height: 2px;
}
.height-05 {
  height: 0.25rem;
}
.height-1 {
  height: 0.5rem;
}
.height-105 {
  height: 0.75rem;
}
.height-2 {
  height: 1rem;
}
.height-205 {
  height: 1.25rem;
}
.height-3 {
  height: 1.5rem;
}
.height-4 {
  height: 2rem;
}
.height-5 {
  height: 2.5rem;
}
.height-6 {
  height: 3rem;
}
.height-7 {
  height: 3.5rem;
}
.height-8 {
  height: 4rem;
}
.height-9 {
  height: 4.5rem;
}
.height-10 {
  height: 5rem;
}
.height-15 {
  height: 7.5rem;
}
.height-card {
  height: 10rem;
}
.height-card-lg {
  height: 15rem;
}
.height-mobile {
  height: 20rem;
}
.height-0 {
  height: 0;
}
.height-auto {
  height: auto;
}
.height-full {
  height: 100%;
}
.height-viewport {
  height: 100vh;
}
.flex-justify-center {
  justify-content: center;
}
.flex-justify-start {
  justify-content: flex-start;
}
.flex-justify-end {
  justify-content: flex-end;
}
.flex-justify {
  justify-content: space-between;
}
.left-1px {
  left: 1px;
}
.left-2px {
  left: 2px;
}
.left-05 {
  left: 0.25rem;
}
.left-1 {
  left: 0.5rem;
}
.left-105 {
  left: 0.75rem;
}
.left-2 {
  left: 1rem;
}
.left-205 {
  left: 1.25rem;
}
.left-3 {
  left: 1.5rem;
}
.left-neg-1px {
  left: -1px;
}
.left-neg-2px {
  left: -2px;
}
.left-neg-05 {
  left: -0.25rem;
}
.left-neg-1 {
  left: -0.5rem;
}
.left-neg-105 {
  left: -0.75rem;
}
.left-neg-2 {
  left: -1rem;
}
.left-neg-205 {
  left: -1.25rem;
}
.left-neg-3 {
  left: -1.5rem;
}
.left-0 {
  left: 0;
}
.left-auto {
  left: auto;
}
.left-full {
  left: 100%;
}
.text-ls-auto {
  letter-spacing: initial;
}
.text-ls-neg-3 {
  letter-spacing: -0.03em;
}
.text-ls-neg-2 {
  letter-spacing: -0.02em;
}
.text-ls-neg-1 {
  letter-spacing: -0.01em;
}
.text-ls-1 {
  letter-spacing: 0.025em;
}
.text-ls-2 {
  letter-spacing: 0.1em;
}
.text-ls-3 {
  letter-spacing: 0.15em;
}
.line-height-sans-1 {
  line-height: 1;
}
.line-height-sans-2 {
  line-height: 1.2;
}
.line-height-sans-3 {
  line-height: 1.3;
}
.line-height-sans-4 {
  line-height: 1.5;
}
.line-height-sans-5 {
  line-height: 1.6;
}
.line-height-sans-6 {
  line-height: 1.7;
}
.line-height-serif-1 {
  line-height: 1;
}
.line-height-serif-2 {
  line-height: 1.2;
}
.line-height-serif-3 {
  line-height: 1.4;
}
.line-height-serif-4 {
  line-height: 1.5;
}
.line-height-serif-5 {
  line-height: 1.7;
}
.line-height-serif-6 {
  line-height: 1.8;
}
.line-height-mono-1 {
  line-height: 1;
}
.line-height-mono-2 {
  line-height: 1.3;
}
.line-height-mono-3 {
  line-height: 1.4;
}
.line-height-mono-4 {
  line-height: 1.6;
}
.line-height-mono-5 {
  line-height: 1.7;
}
.line-height-mono-6 {
  line-height: 1.8;
}
.line-height-heading-1 {
  line-height: 1;
}
.line-height-heading-2 {
  line-height: 1.2;
}
.line-height-heading-3 {
  line-height: 1.3;
}
.line-height-heading-4 {
  line-height: 1.5;
}
.line-height-heading-5 {
  line-height: 1.6;
}
.line-height-heading-6 {
  line-height: 1.7;
}
.line-height-ui-1 {
  line-height: 1;
}
.line-height-ui-2 {
  line-height: 1.2;
}
.line-height-ui-3 {
  line-height: 1.3;
}
.line-height-ui-4 {
  line-height: 1.5;
}
.line-height-ui-5 {
  line-height: 1.6;
}
.line-height-ui-6 {
  line-height: 1.7;
}
.line-height-body-1 {
  line-height: 1;
}
.line-height-body-2 {
  line-height: 1.2;
}
.line-height-body-3 {
  line-height: 1.3;
}
.line-height-body-4 {
  line-height: 1.5;
}
.line-height-body-5 {
  line-height: 1.6;
}
.line-height-body-6 {
  line-height: 1.7;
}
.line-height-code-1 {
  line-height: 1;
}
.line-height-code-2 {
  line-height: 1.3;
}
.line-height-code-3 {
  line-height: 1.4;
}
.line-height-code-4 {
  line-height: 1.6;
}
.line-height-code-5 {
  line-height: 1.7;
}
.line-height-code-6 {
  line-height: 1.8;
}
.line-height-alt-1 {
  line-height: 1;
}
.line-height-alt-2 {
  line-height: 1.2;
}
.line-height-alt-3 {
  line-height: 1.4;
}
.line-height-alt-4 {
  line-height: 1.5;
}
.line-height-alt-5 {
  line-height: 1.7;
}
.line-height-alt-6 {
  line-height: 1.8;
}
.margin-neg-1px {
  margin: -1px;
}
.margin-neg-2px {
  margin: -2px;
}
.margin-neg-05 {
  margin: -0.25rem;
}
.margin-neg-1 {
  margin: -0.5rem;
}
.margin-neg-105 {
  margin: -0.75rem;
}
.margin-neg-2 {
  margin: -1rem;
}
.margin-neg-205 {
  margin: -1.25rem;
}
.margin-neg-3 {
  margin: -1.5rem;
}
.margin-1px {
  margin: 1px;
}
.margin-2px {
  margin: 2px;
}
.margin-05 {
  margin: 0.25rem;
}
.margin-1 {
  margin: 0.5rem;
}
.margin-105 {
  margin: 0.75rem;
}
.margin-2 {
  margin: 1rem;
}
.margin-205 {
  margin: 1.25rem;
}
.margin-3 {
  margin: 1.5rem;
}
.margin-neg-4 {
  margin: -2rem;
}
.margin-neg-5 {
  margin: -2.5rem;
}
.margin-neg-6 {
  margin: -3rem;
}
.margin-neg-7 {
  margin: -3.5rem;
}
.margin-neg-8 {
  margin: -4rem;
}
.margin-neg-9 {
  margin: -4.5rem;
}
.margin-neg-10 {
  margin: -5rem;
}
.margin-neg-15 {
  margin: -7.5rem;
}
.margin-4 {
  margin: 2rem;
}
.margin-5 {
  margin: 2.5rem;
}
.margin-6 {
  margin: 3rem;
}
.margin-7 {
  margin: 3.5rem;
}
.margin-8 {
  margin: 4rem;
}
.margin-9 {
  margin: 4.5rem;
}
.margin-10 {
  margin: 5rem;
}
.margin-15 {
  margin: 7.5rem;
}
.margin-05em {
  margin: 0.5em;
}
.margin-1em {
  margin: 1em;
}
.margin-105em {
  margin: 1.5em;
}
.margin-2em {
  margin: 2em;
}
.margin-0 {
  margin: 0;
}
.margin-y-1px {
  margin-top: 1px;
  margin-bottom: 1px;
}
.margin-top-1px {
  margin-top: 1px;
}
.margin-bottom-1px {
  margin-bottom: 1px;
}
.margin-y-2px {
  margin-top: 2px;
  margin-bottom: 2px;
}
.margin-top-2px {
  margin-top: 2px;
}
.margin-bottom-2px {
  margin-bottom: 2px;
}
.margin-y-05 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.margin-top-05 {
  margin-top: 0.25rem;
}
.margin-bottom-05 {
  margin-bottom: 0.25rem;
}
.margin-y-1 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.margin-top-1 {
  margin-top: 0.5rem;
}
.margin-bottom-1 {
  margin-bottom: 0.5rem;
}
.margin-y-105 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
.margin-top-105 {
  margin-top: 0.75rem;
}
.margin-bottom-105 {
  margin-bottom: 0.75rem;
}
.margin-y-2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.margin-top-2 {
  margin-top: 1rem;
}
.margin-bottom-2 {
  margin-bottom: 1rem;
}
.margin-y-205 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
.margin-top-205 {
  margin-top: 1.25rem;
}
.margin-bottom-205 {
  margin-bottom: 1.25rem;
}
.margin-y-3 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.margin-top-3 {
  margin-top: 1.5rem;
}
.margin-bottom-3 {
  margin-bottom: 1.5rem;
}
.margin-y-neg-1px {
  margin-top: -1px;
  margin-bottom: -1px;
}
.margin-top-neg-1px {
  margin-top: -1px;
}
.margin-bottom-neg-1px {
  margin-bottom: -1px;
}
.margin-y-neg-2px {
  margin-top: -2px;
  margin-bottom: -2px;
}
.margin-top-neg-2px {
  margin-top: -2px;
}
.margin-bottom-neg-2px {
  margin-bottom: -2px;
}
.margin-y-neg-05 {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
}
.margin-top-neg-05 {
  margin-top: -0.25rem;
}
.margin-bottom-neg-05 {
  margin-bottom: -0.25rem;
}
.margin-y-neg-1 {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}
.margin-top-neg-1 {
  margin-top: -0.5rem;
}
.margin-bottom-neg-1 {
  margin-bottom: -0.5rem;
}
.margin-y-neg-105 {
  margin-top: -0.75rem;
  margin-bottom: -0.75rem;
}
.margin-top-neg-105 {
  margin-top: -0.75rem;
}
.margin-bottom-neg-105 {
  margin-bottom: -0.75rem;
}
.margin-y-neg-2 {
  margin-top: -1rem;
  margin-bottom: -1rem;
}
.margin-top-neg-2 {
  margin-top: -1rem;
}
.margin-bottom-neg-2 {
  margin-bottom: -1rem;
}
.margin-y-neg-205 {
  margin-top: -1.25rem;
  margin-bottom: -1.25rem;
}
.margin-top-neg-205 {
  margin-top: -1.25rem;
}
.margin-bottom-neg-205 {
  margin-bottom: -1.25rem;
}
.margin-y-neg-3 {
  margin-top: -1.5rem;
  margin-bottom: -1.5rem;
}
.margin-top-neg-3 {
  margin-top: -1.5rem;
}
.margin-bottom-neg-3 {
  margin-bottom: -1.5rem;
}
.margin-y-neg-4 {
  margin-top: -2rem;
  margin-bottom: -2rem;
}
.margin-top-neg-4 {
  margin-top: -2rem;
}
.margin-bottom-neg-4 {
  margin-bottom: -2rem;
}
.margin-y-neg-5 {
  margin-top: -2.5rem;
  margin-bottom: -2.5rem;
}
.margin-top-neg-5 {
  margin-top: -2.5rem;
}
.margin-bottom-neg-5 {
  margin-bottom: -2.5rem;
}
.margin-y-neg-6 {
  margin-top: -3rem;
  margin-bottom: -3rem;
}
.margin-top-neg-6 {
  margin-top: -3rem;
}
.margin-bottom-neg-6 {
  margin-bottom: -3rem;
}
.margin-y-neg-7 {
  margin-top: -3.5rem;
  margin-bottom: -3.5rem;
}
.margin-top-neg-7 {
  margin-top: -3.5rem;
}
.margin-bottom-neg-7 {
  margin-bottom: -3.5rem;
}
.margin-y-neg-8 {
  margin-top: -4rem;
  margin-bottom: -4rem;
}
.margin-top-neg-8 {
  margin-top: -4rem;
}
.margin-bottom-neg-8 {
  margin-bottom: -4rem;
}
.margin-y-neg-9 {
  margin-top: -4.5rem;
  margin-bottom: -4.5rem;
}
.margin-top-neg-9 {
  margin-top: -4.5rem;
}
.margin-bottom-neg-9 {
  margin-bottom: -4.5rem;
}
.margin-y-neg-10 {
  margin-top: -5rem;
  margin-bottom: -5rem;
}
.margin-top-neg-10 {
  margin-top: -5rem;
}
.margin-bottom-neg-10 {
  margin-bottom: -5rem;
}
.margin-y-neg-15 {
  margin-top: -7.5rem;
  margin-bottom: -7.5rem;
}
.margin-top-neg-15 {
  margin-top: -7.5rem;
}
.margin-bottom-neg-15 {
  margin-bottom: -7.5rem;
}
.margin-y-4 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.margin-top-4 {
  margin-top: 2rem;
}
.margin-bottom-4 {
  margin-bottom: 2rem;
}
.margin-y-5 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.margin-top-5 {
  margin-top: 2.5rem;
}
.margin-bottom-5 {
  margin-bottom: 2.5rem;
}
.margin-y-6 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.margin-top-6 {
  margin-top: 3rem;
}
.margin-bottom-6 {
  margin-bottom: 3rem;
}
.margin-y-7 {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}
.margin-top-7 {
  margin-top: 3.5rem;
}
.margin-bottom-7 {
  margin-bottom: 3.5rem;
}
.margin-y-8 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.margin-top-8 {
  margin-top: 4rem;
}
.margin-bottom-8 {
  margin-bottom: 4rem;
}
.margin-y-9 {
  margin-top: 4.5rem;
  margin-bottom: 4.5rem;
}
.margin-top-9 {
  margin-top: 4.5rem;
}
.margin-bottom-9 {
  margin-bottom: 4.5rem;
}
.margin-y-10 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.margin-top-10 {
  margin-top: 5rem;
}
.margin-bottom-10 {
  margin-bottom: 5rem;
}
.margin-y-15 {
  margin-top: 7.5rem;
  margin-bottom: 7.5rem;
}
.margin-top-15 {
  margin-top: 7.5rem;
}
.margin-bottom-15 {
  margin-bottom: 7.5rem;
}
.margin-y-05em {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.margin-top-05em {
  margin-top: 0.5em;
}
.margin-bottom-05em {
  margin-bottom: 0.5em;
}
.margin-y-1em {
  margin-top: 1em;
  margin-bottom: 1em;
}
.margin-top-1em {
  margin-top: 1em;
}
.margin-bottom-1em {
  margin-bottom: 1em;
}
.margin-y-105em {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}
.margin-top-105em {
  margin-top: 1.5em;
}
.margin-bottom-105em {
  margin-bottom: 1.5em;
}
.margin-y-2em {
  margin-top: 2em;
  margin-bottom: 2em;
}
.margin-top-2em {
  margin-top: 2em;
}
.margin-bottom-2em {
  margin-bottom: 2em;
}
.margin-y-0 {
  margin-top: 0;
  margin-bottom: 0;
}
.margin-top-0 {
  margin-top: 0;
}
.margin-bottom-0 {
  margin-bottom: 0;
}
.margin-y-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.margin-top-auto {
  margin-top: auto;
}
.margin-bottom-auto {
  margin-bottom: auto;
}
.margin-x-1px {
  margin-left: 1px;
  margin-right: 1px;
}
.margin-right-1px {
  margin-right: 1px;
}
.margin-left-1px {
  margin-left: 1px;
}
.margin-x-2px {
  margin-left: 2px;
  margin-right: 2px;
}
.margin-right-2px {
  margin-right: 2px;
}
.margin-left-2px {
  margin-left: 2px;
}
.margin-x-05 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.margin-right-05 {
  margin-right: 0.25rem;
}
.margin-left-05 {
  margin-left: 0.25rem;
}
.margin-x-1 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.margin-right-1 {
  margin-right: 0.5rem;
}
.margin-left-1 {
  margin-left: 0.5rem;
}
.margin-x-105 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}
.margin-right-105 {
  margin-right: 0.75rem;
}
.margin-left-105 {
  margin-left: 0.75rem;
}
.margin-x-2 {
  margin-left: 1rem;
  margin-right: 1rem;
}
.margin-right-2 {
  margin-right: 1rem;
}
.margin-left-2 {
  margin-left: 1rem;
}
.margin-x-205 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}
.margin-right-205 {
  margin-right: 1.25rem;
}
.margin-left-205 {
  margin-left: 1.25rem;
}
.margin-x-3 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.margin-right-3 {
  margin-right: 1.5rem;
}
.margin-left-3 {
  margin-left: 1.5rem;
}
.margin-x-neg-1px {
  margin-left: -1px;
  margin-right: -1px;
}
.margin-right-neg-1px {
  margin-right: -1px;
}
.margin-left-neg-1px {
  margin-left: -1px;
}
.margin-x-neg-2px {
  margin-left: -2px;
  margin-right: -2px;
}
.margin-right-neg-2px {
  margin-right: -2px;
}
.margin-left-neg-2px {
  margin-left: -2px;
}
.margin-x-neg-05 {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}
.margin-right-neg-05 {
  margin-right: -0.25rem;
}
.margin-left-neg-05 {
  margin-left: -0.25rem;
}
.margin-x-neg-1 {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.margin-right-neg-1 {
  margin-right: -0.5rem;
}
.margin-left-neg-1 {
  margin-left: -0.5rem;
}
.margin-x-neg-105 {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}
.margin-right-neg-105 {
  margin-right: -0.75rem;
}
.margin-left-neg-105 {
  margin-left: -0.75rem;
}
.margin-x-neg-2 {
  margin-left: -1rem;
  margin-right: -1rem;
}
.margin-right-neg-2 {
  margin-right: -1rem;
}
.margin-left-neg-2 {
  margin-left: -1rem;
}
.margin-x-neg-205 {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}
.margin-right-neg-205 {
  margin-right: -1.25rem;
}
.margin-left-neg-205 {
  margin-left: -1.25rem;
}
.margin-x-neg-3 {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}
.margin-right-neg-3 {
  margin-right: -1.5rem;
}
.margin-left-neg-3 {
  margin-left: -1.5rem;
}
.margin-x-neg-4 {
  margin-left: -2rem;
  margin-right: -2rem;
}
.margin-right-neg-4 {
  margin-right: -2rem;
}
.margin-left-neg-4 {
  margin-left: -2rem;
}
.margin-x-neg-5 {
  margin-left: -2.5rem;
  margin-right: -2.5rem;
}
.margin-right-neg-5 {
  margin-right: -2.5rem;
}
.margin-left-neg-5 {
  margin-left: -2.5rem;
}
.margin-x-neg-6 {
  margin-left: -3rem;
  margin-right: -3rem;
}
.margin-right-neg-6 {
  margin-right: -3rem;
}
.margin-left-neg-6 {
  margin-left: -3rem;
}
.margin-x-neg-7 {
  margin-left: -3.5rem;
  margin-right: -3.5rem;
}
.margin-right-neg-7 {
  margin-right: -3.5rem;
}
.margin-left-neg-7 {
  margin-left: -3.5rem;
}
.margin-x-neg-8 {
  margin-left: -4rem;
  margin-right: -4rem;
}
.margin-right-neg-8 {
  margin-right: -4rem;
}
.margin-left-neg-8 {
  margin-left: -4rem;
}
.margin-x-neg-9 {
  margin-left: -4.5rem;
  margin-right: -4.5rem;
}
.margin-right-neg-9 {
  margin-right: -4.5rem;
}
.margin-left-neg-9 {
  margin-left: -4.5rem;
}
.margin-x-neg-10 {
  margin-left: -5rem;
  margin-right: -5rem;
}
.margin-right-neg-10 {
  margin-right: -5rem;
}
.margin-left-neg-10 {
  margin-left: -5rem;
}
.margin-x-neg-15 {
  margin-left: -7.5rem;
  margin-right: -7.5rem;
}
.margin-right-neg-15 {
  margin-right: -7.5rem;
}
.margin-left-neg-15 {
  margin-left: -7.5rem;
}
.margin-x-4 {
  margin-left: 2rem;
  margin-right: 2rem;
}
.margin-right-4 {
  margin-right: 2rem;
}
.margin-left-4 {
  margin-left: 2rem;
}
.margin-x-5 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}
.margin-right-5 {
  margin-right: 2.5rem;
}
.margin-left-5 {
  margin-left: 2.5rem;
}
.margin-x-6 {
  margin-left: 3rem;
  margin-right: 3rem;
}
.margin-right-6 {
  margin-right: 3rem;
}
.margin-left-6 {
  margin-left: 3rem;
}
.margin-x-7 {
  margin-left: 3.5rem;
  margin-right: 3.5rem;
}
.margin-right-7 {
  margin-right: 3.5rem;
}
.margin-left-7 {
  margin-left: 3.5rem;
}
.margin-x-8 {
  margin-left: 4rem;
  margin-right: 4rem;
}
.margin-right-8 {
  margin-right: 4rem;
}
.margin-left-8 {
  margin-left: 4rem;
}
.margin-x-9 {
  margin-left: 4.5rem;
  margin-right: 4.5rem;
}
.margin-right-9 {
  margin-right: 4.5rem;
}
.margin-left-9 {
  margin-left: 4.5rem;
}
.margin-x-10 {
  margin-left: 5rem;
  margin-right: 5rem;
}
.margin-right-10 {
  margin-right: 5rem;
}
.margin-left-10 {
  margin-left: 5rem;
}
.margin-x-15 {
  margin-left: 7.5rem;
  margin-right: 7.5rem;
}
.margin-right-15 {
  margin-right: 7.5rem;
}
.margin-left-15 {
  margin-left: 7.5rem;
}
.margin-x-card {
  margin-left: 10rem;
  margin-right: 10rem;
}
.margin-right-card {
  margin-right: 10rem;
}
.margin-left-card {
  margin-left: 10rem;
}
.margin-x-card-lg {
  margin-left: 15rem;
  margin-right: 15rem;
}
.margin-right-card-lg {
  margin-right: 15rem;
}
.margin-left-card-lg {
  margin-left: 15rem;
}
.margin-x-mobile {
  margin-left: 20rem;
  margin-right: 20rem;
}
.margin-right-mobile {
  margin-right: 20rem;
}
.margin-left-mobile {
  margin-left: 20rem;
}
.margin-x-05em {
  margin-left: 0.5em;
  margin-right: 0.5em;
}
.margin-right-05em {
  margin-right: 0.5em;
}
.margin-left-05em {
  margin-left: 0.5em;
}
.margin-x-1em {
  margin-left: 1em;
  margin-right: 1em;
}
.margin-right-1em {
  margin-right: 1em;
}
.margin-left-1em {
  margin-left: 1em;
}
.margin-x-105em {
  margin-left: 1.5em;
  margin-right: 1.5em;
}
.margin-right-105em {
  margin-right: 1.5em;
}
.margin-left-105em {
  margin-left: 1.5em;
}
.margin-x-2em {
  margin-left: 2em;
  margin-right: 2em;
}
.margin-right-2em {
  margin-right: 2em;
}
.margin-left-2em {
  margin-left: 2em;
}
.margin-x-0 {
  margin-left: 0;
  margin-right: 0;
}
.margin-right-0 {
  margin-right: 0;
}
.margin-left-0 {
  margin-left: 0;
}
.margin-x-auto {
  margin-left: auto;
  margin-right: auto;
}
.margin-right-auto {
  margin-right: auto;
}
.margin-left-auto {
  margin-left: auto;
}
.maxh-05 {
  max-height: 0.25rem;
}
.maxh-1 {
  max-height: 0.5rem;
}
.maxh-105 {
  max-height: 0.75rem;
}
.maxh-2 {
  max-height: 1rem;
}
.maxh-205 {
  max-height: 1.25rem;
}
.maxh-3 {
  max-height: 1.5rem;
}
.maxh-4 {
  max-height: 2rem;
}
.maxh-5 {
  max-height: 2.5rem;
}
.maxh-6 {
  max-height: 3rem;
}
.maxh-7 {
  max-height: 3.5rem;
}
.maxh-8 {
  max-height: 4rem;
}
.maxh-9 {
  max-height: 4.5rem;
}
.maxh-10 {
  max-height: 5rem;
}
.maxh-15 {
  max-height: 7.5rem;
}
.maxh-card {
  max-height: 10rem;
}
.maxh-card-lg {
  max-height: 15rem;
}
.maxh-mobile {
  max-height: 20rem;
}
.maxh-mobile-lg {
  max-height: 30rem;
}
.maxh-tablet {
  max-height: 40rem;
}
.maxh-tablet-lg {
  max-height: 55rem;
}
.maxh-none {
  max-height: none;
}
.maxh-viewport {
  max-height: 100vh;
}
.maxw-05 {
  max-width: 0.25rem;
}
.maxw-1 {
  max-width: 0.5rem;
}
.maxw-105 {
  max-width: 0.75rem;
}
.maxw-2 {
  max-width: 1rem;
}
.maxw-205 {
  max-width: 1.25rem;
}
.maxw-3 {
  max-width: 1.5rem;
}
.maxw-4 {
  max-width: 2rem;
}
.maxw-5 {
  max-width: 2.5rem;
}
.maxw-6 {
  max-width: 3rem;
}
.maxw-7 {
  max-width: 3.5rem;
}
.maxw-8 {
  max-width: 4rem;
}
.maxw-9 {
  max-width: 4.5rem;
}
.maxw-10 {
  max-width: 5rem;
}
.maxw-15 {
  max-width: 7.5rem;
}
.maxw-card {
  max-width: 10rem;
}
.maxw-card-lg {
  max-width: 15rem;
}
.maxw-mobile {
  max-width: 20rem;
}
.maxw-mobile-lg {
  max-width: 30rem;
}
.maxw-tablet {
  max-width: 40rem;
}
.maxw-tablet-lg {
  max-width: 55rem;
}
.maxw-desktop {
  max-width: 64rem;
}
.maxw-desktop-lg {
  max-width: 75rem;
}
.maxw-widescreen {
  max-width: 87.5rem;
}
.maxw-none {
  max-width: none;
}
.maxw-full {
  max-width: 100%;
}
.measure-1 {
  max-width: 44ex;
}
.measure-2 {
  max-width: 60ex;
}
.measure-3 {
  max-width: 64ex;
}
.measure-4 {
  max-width: 68ex;
}
.measure-5 {
  max-width: 72ex;
}
.measure-6 {
  max-width: 88ex;
}
.measure-none {
  max-width: none;
}
.minh-1px {
  min-height: 1px;
}
.minh-2px {
  min-height: 2px;
}
.minh-05 {
  min-height: 0.25rem;
}
.minh-1 {
  min-height: 0.5rem;
}
.minh-105 {
  min-height: 0.75rem;
}
.minh-2 {
  min-height: 1rem;
}
.minh-205 {
  min-height: 1.25rem;
}
.minh-3 {
  min-height: 1.5rem;
}
.minh-4 {
  min-height: 2rem;
}
.minh-5 {
  min-height: 2.5rem;
}
.minh-6 {
  min-height: 3rem;
}
.minh-7 {
  min-height: 3.5rem;
}
.minh-8 {
  min-height: 4rem;
}
.minh-9 {
  min-height: 4.5rem;
}
.minh-10 {
  min-height: 5rem;
}
.minh-15 {
  min-height: 7.5rem;
}
.minh-card {
  min-height: 10rem;
}
.minh-card-lg {
  min-height: 15rem;
}
.minh-mobile {
  min-height: 20rem;
}
.minh-mobile-lg {
  min-height: 30rem;
}
.minh-tablet {
  min-height: 40rem;
}
.minh-tablet-lg {
  min-height: 55rem;
}
.minh-0 {
  min-height: 0;
}
.minh-full {
  min-height: 100%;
}
.minh-viewport {
  min-height: 100vh;
}
.minw-05 {
  min-width: 0.25rem;
}
.minw-1 {
  min-width: 0.5rem;
}
.minw-105 {
  min-width: 0.75rem;
}
.minw-2 {
  min-width: 1rem;
}
.minw-205 {
  min-width: 1.25rem;
}
.minw-3 {
  min-width: 1.5rem;
}
.minw-4 {
  min-width: 2rem;
}
.minw-5 {
  min-width: 2.5rem;
}
.minw-6 {
  min-width: 3rem;
}
.minw-7 {
  min-width: 3.5rem;
}
.minw-8 {
  min-width: 4rem;
}
.minw-9 {
  min-width: 4.5rem;
}
.minw-10 {
  min-width: 5rem;
}
.minw-15 {
  min-width: 7.5rem;
}
.minw-0 {
  min-width: 0;
}
.opacity-0 {
  opacity: 0;
}
.opacity-10 {
  opacity: 0.1;
}
.opacity-20 {
  opacity: 0.2;
}
.opacity-30 {
  opacity: 0.3;
}
.opacity-40 {
  opacity: 0.4;
}
.opacity-50 {
  opacity: 0.5;
}
.opacity-60 {
  opacity: 0.6;
}
.opacity-70 {
  opacity: 0.7;
}
.opacity-80 {
  opacity: 0.8;
}
.opacity-90 {
  opacity: 0.9;
}
.opacity-100 {
  opacity: 1;
}
.order-first {
  order: -1;
}
.order-last {
  order: 999;
}
.order-initial {
  order: initial;
}
.order-0 {
  order: 0;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
.order-4 {
  order: 4;
}
.order-5 {
  order: 5;
}
.order-6 {
  order: 6;
}
.order-7 {
  order: 7;
}
.order-8 {
  order: 8;
}
.order-9 {
  order: 9;
}
.order-10 {
  order: 10;
}
.order-11 {
  order: 11;
}
.outline-1px {
  outline: 1px solid;
}
.outline-2px {
  outline: 2px solid;
}
.outline-0 {
  outline: 0 solid;
}
.outline-05 {
  outline: 0.25rem solid;
}
.outline-transparent {
  outline-color: transparent;
}
.outline-black {
  outline-color: black;
}
.outline-white {
  outline-color: white;
}
.outline-red {
  outline-color: #e52207;
}
.outline-orange {
  outline-color: #e66f0e;
}
.outline-gold {
  outline-color: #ffbe2e;
}
.outline-yellow {
  outline-color: #fee685;
}
.outline-green {
  outline-color: #538200;
}
.outline-mint {
  outline-color: #04c585;
}
.outline-cyan {
  outline-color: #009ec1;
}
.outline-blue {
  outline-color: #0076d6;
}
.outline-indigo {
  outline-color: #676cc8;
}
.outline-violet {
  outline-color: #8168b3;
}
.outline-magenta {
  outline-color: #d72d79;
}
.outline-gray-5 {
  outline-color: #f0f0f0;
}
.outline-gray-10 {
  outline-color: #e6e6e6;
}
.outline-gray-30 {
  outline-color: #adadad;
}
.outline-gray-50 {
  outline-color: #757575;
}
.outline-gray-70 {
  outline-color: #454545;
}
.outline-gray-90 {
  outline-color: #1b1b1b;
}
.outline-base-lightest {
  outline-color: #f0f0f0;
}
.outline-base-lighter {
  outline-color: #dfe1e2;
}
.outline-base-light {
  outline-color: #a9aeb1;
}
.outline-base {
  outline-color: #71767a;
}
.outline-base-dark {
  outline-color: #565c65;
}
.outline-base-darker {
  outline-color: #3d4551;
}
.outline-base-darkest {
  outline-color: #1b1b1b;
}
.outline-ink {
  outline-color: #11181d;
}
.outline-primary-lightest {
  outline-color: #B3EFFF;
}
.outline-primary-lighter {
  outline-color: #97d4ea;
}
.outline-primary-light {
  outline-color: #28a0cb;
}
.outline-primary {
  outline-color: #046B99;
}
.outline-primary-vivid {
  outline-color: #0050d8;
}
.outline-primary-dark {
  outline-color: #0b4778;
}
.outline-primary-darker {
  outline-color: #1C304A;
}
.outline-primary-darkest {
  outline-color: #11181d;
}
.outline-secondary-lighter {
  outline-color: #f3e1e4;
}
.outline-secondary-light {
  outline-color: #f2938c;
}
.outline-secondary {
  outline-color: #d83933;
}
.outline-secondary-vivid {
  outline-color: #e41d3d;
}
.outline-secondary-dark {
  outline-color: #b50909;
}
.outline-secondary-darker {
  outline-color: #8b0a03;
}
.outline-accent-warm-darker {
  outline-color: #775540;
}
.outline-accent-warm-dark {
  outline-color: #c05600;
}
.outline-accent-warm {
  outline-color: #fa9441;
}
.outline-accent-warm-light {
  outline-color: #ffbc78;
}
.outline-accent-warm-lighter {
  outline-color: #f2e4d4;
}
.outline-accent-cool-darkest {
  outline-color: #093b44;
}
.outline-accent-cool-darker {
  outline-color: #0e4f5c;
}
.outline-accent-cool-dark {
  outline-color: #0081a1;
}
.outline-accent-cool {
  outline-color: #00CFFF;
}
.outline-accent-cool-light {
  outline-color: #52daf2;
}
.outline-accent-cool-lighter {
  outline-color: #a8f2ff;
}
.outline-accent-cool-lightest {
  outline-color: #eff6fb;
}
.outline-error-lighter {
  outline-color: #fff5ee;
}
.outline-error-light {
  outline-color: #f39268;
}
.outline-error {
  outline-color: #d54309;
}
.outline-error-dark {
  outline-color: #b50909;
}
.outline-error-darker {
  outline-color: #6f3331;
}
.outline-warning-lighter {
  outline-color: #faf3d1;
}
.outline-warning-light {
  outline-color: #fee685;
}
.outline-warning {
  outline-color: #ffbe2e;
}
.outline-warning-dark {
  outline-color: #e5a000;
}
.outline-warning-darker {
  outline-color: #936f38;
}
.outline-success-lighter {
  outline-color: #ecf3ec;
}
.outline-success-light {
  outline-color: #70e17b;
}
.outline-success {
  outline-color: #00a91c;
}
.outline-success-dark {
  outline-color: #008817;
}
.outline-success-darker {
  outline-color: #216e1f;
}
.outline-info-lighter {
  outline-color: #e7f6f8;
}
.outline-info-light {
  outline-color: #99deea;
}
.outline-info {
  outline-color: #00bde3;
}
.outline-info-dark {
  outline-color: #009ec1;
}
.outline-info-darker {
  outline-color: #2e6276;
}
.outline-disabled-lighter {
  outline-color: #c9c9c9;
}
.outline-disabled-light {
  outline-color: #919191;
}
.outline-disabled {
  outline-color: #757575;
}
.outline-disabled-dark {
  outline-color: #454545;
}
.outline-disabled-darker {
  outline-color: #1b1b1b;
}
.outline-emergency {
  outline-color: #9c3d10;
}
.outline-emergency-dark {
  outline-color: #332d29;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-y-hidden {
  overflow-y: hidden;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.overflow-scroll {
  overflow: scroll;
}
.overflow-y-scroll {
  overflow-y: scroll;
}
.overflow-x-scroll {
  overflow-x: scroll;
}
.overflow-auto {
  overflow: auto;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-x-auto {
  overflow-x: auto;
}
.overflow-visible {
  overflow: visible;
}
.overflow-y-visible {
  overflow-y: visible;
}
.overflow-x-visible {
  overflow-x: visible;
}
.padding-1px {
  padding: 1px;
}
.padding-y-1px {
  padding-top: 1px;
  padding-bottom: 1px;
}
.padding-x-1px {
  padding-left: 1px;
  padding-right: 1px;
}
.padding-top-1px {
  padding-top: 1px;
}
.padding-right-1px {
  padding-right: 1px;
}
.padding-bottom-1px {
  padding-bottom: 1px;
}
.padding-left-1px {
  padding-left: 1px;
}
.padding-2px {
  padding: 2px;
}
.padding-y-2px {
  padding-top: 2px;
  padding-bottom: 2px;
}
.padding-x-2px {
  padding-left: 2px;
  padding-right: 2px;
}
.padding-top-2px {
  padding-top: 2px;
}
.padding-right-2px {
  padding-right: 2px;
}
.padding-bottom-2px {
  padding-bottom: 2px;
}
.padding-left-2px {
  padding-left: 2px;
}
.padding-05 {
  padding: 0.25rem;
}
.padding-y-05 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.padding-x-05 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.padding-top-05 {
  padding-top: 0.25rem;
}
.padding-right-05 {
  padding-right: 0.25rem;
}
.padding-bottom-05 {
  padding-bottom: 0.25rem;
}
.padding-left-05 {
  padding-left: 0.25rem;
}
.padding-1 {
  padding: 0.5rem;
}
.padding-y-1 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.padding-x-1 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.padding-top-1 {
  padding-top: 0.5rem;
}
.padding-right-1 {
  padding-right: 0.5rem;
}
.padding-bottom-1 {
  padding-bottom: 0.5rem;
}
.padding-left-1 {
  padding-left: 0.5rem;
}
.padding-105 {
  padding: 0.75rem;
}
.padding-y-105 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.padding-x-105 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.padding-top-105 {
  padding-top: 0.75rem;
}
.padding-right-105 {
  padding-right: 0.75rem;
}
.padding-bottom-105 {
  padding-bottom: 0.75rem;
}
.padding-left-105 {
  padding-left: 0.75rem;
}
.padding-2 {
  padding: 1rem;
}
.padding-y-2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.padding-x-2 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.padding-top-2 {
  padding-top: 1rem;
}
.padding-right-2 {
  padding-right: 1rem;
}
.padding-bottom-2 {
  padding-bottom: 1rem;
}
.padding-left-2 {
  padding-left: 1rem;
}
.padding-205 {
  padding: 1.25rem;
}
.padding-y-205 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.padding-x-205 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.padding-top-205 {
  padding-top: 1.25rem;
}
.padding-right-205 {
  padding-right: 1.25rem;
}
.padding-bottom-205 {
  padding-bottom: 1.25rem;
}
.padding-left-205 {
  padding-left: 1.25rem;
}
.padding-3 {
  padding: 1.5rem;
}
.padding-y-3 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.padding-x-3 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.padding-top-3 {
  padding-top: 1.5rem;
}
.padding-right-3 {
  padding-right: 1.5rem;
}
.padding-bottom-3 {
  padding-bottom: 1.5rem;
}
.padding-left-3 {
  padding-left: 1.5rem;
}
.padding-4 {
  padding: 2rem;
}
.padding-y-4 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.padding-x-4 {
  padding-left: 2rem;
  padding-right: 2rem;
}
.padding-top-4 {
  padding-top: 2rem;
}
.padding-right-4 {
  padding-right: 2rem;
}
.padding-bottom-4 {
  padding-bottom: 2rem;
}
.padding-left-4 {
  padding-left: 2rem;
}
.padding-5 {
  padding: 2.5rem;
}
.padding-y-5 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.padding-x-5 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.padding-top-5 {
  padding-top: 2.5rem;
}
.padding-right-5 {
  padding-right: 2.5rem;
}
.padding-bottom-5 {
  padding-bottom: 2.5rem;
}
.padding-left-5 {
  padding-left: 2.5rem;
}
.padding-6 {
  padding: 3rem;
}
.padding-y-6 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.padding-x-6 {
  padding-left: 3rem;
  padding-right: 3rem;
}
.padding-top-6 {
  padding-top: 3rem;
}
.padding-right-6 {
  padding-right: 3rem;
}
.padding-bottom-6 {
  padding-bottom: 3rem;
}
.padding-left-6 {
  padding-left: 3rem;
}
.padding-7 {
  padding: 3.5rem;
}
.padding-y-7 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}
.padding-x-7 {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}
.padding-top-7 {
  padding-top: 3.5rem;
}
.padding-right-7 {
  padding-right: 3.5rem;
}
.padding-bottom-7 {
  padding-bottom: 3.5rem;
}
.padding-left-7 {
  padding-left: 3.5rem;
}
.padding-8 {
  padding: 4rem;
}
.padding-y-8 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.padding-x-8 {
  padding-left: 4rem;
  padding-right: 4rem;
}
.padding-top-8 {
  padding-top: 4rem;
}
.padding-right-8 {
  padding-right: 4rem;
}
.padding-bottom-8 {
  padding-bottom: 4rem;
}
.padding-left-8 {
  padding-left: 4rem;
}
.padding-9 {
  padding: 4.5rem;
}
.padding-y-9 {
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
}
.padding-x-9 {
  padding-left: 4.5rem;
  padding-right: 4.5rem;
}
.padding-top-9 {
  padding-top: 4.5rem;
}
.padding-right-9 {
  padding-right: 4.5rem;
}
.padding-bottom-9 {
  padding-bottom: 4.5rem;
}
.padding-left-9 {
  padding-left: 4.5rem;
}
.padding-10 {
  padding: 5rem;
}
.padding-y-10 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.padding-x-10 {
  padding-left: 5rem;
  padding-right: 5rem;
}
.padding-top-10 {
  padding-top: 5rem;
}
.padding-right-10 {
  padding-right: 5rem;
}
.padding-bottom-10 {
  padding-bottom: 5rem;
}
.padding-left-10 {
  padding-left: 5rem;
}
.padding-15 {
  padding: 7.5rem;
}
.padding-y-15 {
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
}
.padding-x-15 {
  padding-left: 7.5rem;
  padding-right: 7.5rem;
}
.padding-top-15 {
  padding-top: 7.5rem;
}
.padding-right-15 {
  padding-right: 7.5rem;
}
.padding-bottom-15 {
  padding-bottom: 7.5rem;
}
.padding-left-15 {
  padding-left: 7.5rem;
}
.padding-0 {
  padding: 0;
}
.padding-y-0 {
  padding-top: 0;
  padding-bottom: 0;
}
.padding-x-0 {
  padding-left: 0;
  padding-right: 0;
}
.padding-top-0 {
  padding-top: 0;
}
.padding-right-0 {
  padding-right: 0;
}
.padding-bottom-0 {
  padding-bottom: 0;
}
.padding-left-0 {
  padding-left: 0;
}
.pin-all {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  position: absolute;
}
.pin-x {
  left: 0;
  right: 0;
  position: absolute;
}
.pin-y {
  bottom: 0;
  top: 0;
  position: absolute;
}
.pin-bottom {
  bottom: 0;
  position: absolute;
}
.pin-left {
  left: 0;
  position: absolute;
}
.pin-right {
  right: 0;
  position: absolute;
}
.pin-top {
  top: 0;
  position: absolute;
}
.pin-none {
  bottom: auto;
  left: auto;
  right: auto;
  top: auto;
  position: static;
}
.position-absolute {
  position: absolute;
}
.position-fixed {
  position: fixed;
}
.position-relative {
  position: relative;
}
.position-static {
  position: static;
}
.position-sticky {
  position: -webkit-sticky;
  position: sticky;
}
.right-1px {
  right: 1px;
}
.right-2px {
  right: 2px;
}
.right-05 {
  right: 0.25rem;
}
.right-1 {
  right: 0.5rem;
}
.right-105 {
  right: 0.75rem;
}
.right-2 {
  right: 1rem;
}
.right-205 {
  right: 1.25rem;
}
.right-3 {
  right: 1.5rem;
}
.right-neg-1px {
  right: -1px;
}
.right-neg-2px {
  right: -2px;
}
.right-neg-05 {
  right: -0.25rem;
}
.right-neg-1 {
  right: -0.5rem;
}
.right-neg-105 {
  right: -0.75rem;
}
.right-neg-2 {
  right: -1rem;
}
.right-neg-205 {
  right: -1.25rem;
}
.right-neg-3 {
  right: -1.5rem;
}
.right-0 {
  right: 0;
}
.right-auto {
  right: auto;
}
.right-full {
  right: 100%;
}
.square-1px {
  height: 1px;
  width: 1px;
}
.square-2px {
  height: 2px;
  width: 2px;
}
.square-05 {
  height: 0.25rem;
  width: 0.25rem;
}
.square-1 {
  height: 0.5rem;
  width: 0.5rem;
}
.square-105 {
  height: 0.75rem;
  width: 0.75rem;
}
.square-2 {
  height: 1rem;
  width: 1rem;
}
.square-205 {
  height: 1.25rem;
  width: 1.25rem;
}
.square-3 {
  height: 1.5rem;
  width: 1.5rem;
}
.square-4 {
  height: 2rem;
  width: 2rem;
}
.square-5 {
  height: 2.5rem;
  width: 2.5rem;
}
.square-6 {
  height: 3rem;
  width: 3rem;
}
.square-7 {
  height: 3.5rem;
  width: 3.5rem;
}
.square-8 {
  height: 4rem;
  width: 4rem;
}
.square-9 {
  height: 4.5rem;
  width: 4.5rem;
}
.square-10 {
  height: 5rem;
  width: 5rem;
}
.square-15 {
  height: 7.5rem;
  width: 7.5rem;
}
.square-card {
  height: 10rem;
  width: 10rem;
}
.square-card-lg {
  height: 15rem;
  width: 15rem;
}
.square-mobile {
  height: 20rem;
  width: 20rem;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-justify {
  text-align: justify;
}
.text-right {
  text-align: right;
}
.text-strike {
  text-decoration: line-through;
}
.hover\:text-strike:hover {
  text-decoration: line-through;
}
.text-underline {
  text-decoration: underline;
}
.hover\:text-underline:hover {
  text-decoration: underline;
}
.text-no-underline {
  text-decoration: none;
}
.hover\:text-no-underline:hover {
  text-decoration: none;
}
.text-no-strike {
  text-decoration: none;
}
.hover\:text-no-strike:hover {
  text-decoration: none;
}
.underline-red {
  -webkit-text-decoration-color: #e52207;
  text-decoration-color: #e52207;
}
.hover\:underline-red:hover {
  -webkit-text-decoration-color: #e52207;
  text-decoration-color: #e52207;
}
.underline-orange {
  -webkit-text-decoration-color: #e66f0e;
  text-decoration-color: #e66f0e;
}
.hover\:underline-orange:hover {
  -webkit-text-decoration-color: #e66f0e;
  text-decoration-color: #e66f0e;
}
.underline-gold {
  -webkit-text-decoration-color: #ffbe2e;
  text-decoration-color: #ffbe2e;
}
.hover\:underline-gold:hover {
  -webkit-text-decoration-color: #ffbe2e;
  text-decoration-color: #ffbe2e;
}
.underline-yellow {
  -webkit-text-decoration-color: #fee685;
  text-decoration-color: #fee685;
}
.hover\:underline-yellow:hover {
  -webkit-text-decoration-color: #fee685;
  text-decoration-color: #fee685;
}
.underline-green {
  -webkit-text-decoration-color: #538200;
  text-decoration-color: #538200;
}
.hover\:underline-green:hover {
  -webkit-text-decoration-color: #538200;
  text-decoration-color: #538200;
}
.underline-mint {
  -webkit-text-decoration-color: #04c585;
  text-decoration-color: #04c585;
}
.hover\:underline-mint:hover {
  -webkit-text-decoration-color: #04c585;
  text-decoration-color: #04c585;
}
.underline-cyan {
  -webkit-text-decoration-color: #009ec1;
  text-decoration-color: #009ec1;
}
.hover\:underline-cyan:hover {
  -webkit-text-decoration-color: #009ec1;
  text-decoration-color: #009ec1;
}
.underline-blue {
  -webkit-text-decoration-color: #0076d6;
  text-decoration-color: #0076d6;
}
.hover\:underline-blue:hover {
  -webkit-text-decoration-color: #0076d6;
  text-decoration-color: #0076d6;
}
.underline-indigo {
  -webkit-text-decoration-color: #676cc8;
  text-decoration-color: #676cc8;
}
.hover\:underline-indigo:hover {
  -webkit-text-decoration-color: #676cc8;
  text-decoration-color: #676cc8;
}
.underline-violet {
  -webkit-text-decoration-color: #8168b3;
  text-decoration-color: #8168b3;
}
.hover\:underline-violet:hover {
  -webkit-text-decoration-color: #8168b3;
  text-decoration-color: #8168b3;
}
.underline-magenta {
  -webkit-text-decoration-color: #d72d79;
  text-decoration-color: #d72d79;
}
.hover\:underline-magenta:hover {
  -webkit-text-decoration-color: #d72d79;
  text-decoration-color: #d72d79;
}
.underline-gray-5 {
  -webkit-text-decoration-color: #f0f0f0;
  text-decoration-color: #f0f0f0;
}
.hover\:underline-gray-5:hover {
  -webkit-text-decoration-color: #f0f0f0;
  text-decoration-color: #f0f0f0;
}
.underline-gray-10 {
  -webkit-text-decoration-color: #e6e6e6;
  text-decoration-color: #e6e6e6;
}
.hover\:underline-gray-10:hover {
  -webkit-text-decoration-color: #e6e6e6;
  text-decoration-color: #e6e6e6;
}
.underline-gray-30 {
  -webkit-text-decoration-color: #adadad;
  text-decoration-color: #adadad;
}
.hover\:underline-gray-30:hover {
  -webkit-text-decoration-color: #adadad;
  text-decoration-color: #adadad;
}
.underline-gray-50 {
  -webkit-text-decoration-color: #757575;
  text-decoration-color: #757575;
}
.hover\:underline-gray-50:hover {
  -webkit-text-decoration-color: #757575;
  text-decoration-color: #757575;
}
.underline-gray-70 {
  -webkit-text-decoration-color: #454545;
  text-decoration-color: #454545;
}
.hover\:underline-gray-70:hover {
  -webkit-text-decoration-color: #454545;
  text-decoration-color: #454545;
}
.underline-gray-90 {
  -webkit-text-decoration-color: #1b1b1b;
  text-decoration-color: #1b1b1b;
}
.hover\:underline-gray-90:hover {
  -webkit-text-decoration-color: #1b1b1b;
  text-decoration-color: #1b1b1b;
}
.underline-base-lightest {
  -webkit-text-decoration-color: #f0f0f0;
  text-decoration-color: #f0f0f0;
}
.hover\:underline-base-lightest:hover {
  -webkit-text-decoration-color: #f0f0f0;
  text-decoration-color: #f0f0f0;
}
.underline-base-lighter {
  -webkit-text-decoration-color: #dfe1e2;
  text-decoration-color: #dfe1e2;
}
.hover\:underline-base-lighter:hover {
  -webkit-text-decoration-color: #dfe1e2;
  text-decoration-color: #dfe1e2;
}
.underline-base-light {
  -webkit-text-decoration-color: #a9aeb1;
  text-decoration-color: #a9aeb1;
}
.hover\:underline-base-light:hover {
  -webkit-text-decoration-color: #a9aeb1;
  text-decoration-color: #a9aeb1;
}
.underline-base {
  -webkit-text-decoration-color: #71767a;
  text-decoration-color: #71767a;
}
.hover\:underline-base:hover {
  -webkit-text-decoration-color: #71767a;
  text-decoration-color: #71767a;
}
.underline-base-dark {
  -webkit-text-decoration-color: #565c65;
  text-decoration-color: #565c65;
}
.hover\:underline-base-dark:hover {
  -webkit-text-decoration-color: #565c65;
  text-decoration-color: #565c65;
}
.underline-base-darker {
  -webkit-text-decoration-color: #3d4551;
  text-decoration-color: #3d4551;
}
.hover\:underline-base-darker:hover {
  -webkit-text-decoration-color: #3d4551;
  text-decoration-color: #3d4551;
}
.underline-base-darkest {
  -webkit-text-decoration-color: #1b1b1b;
  text-decoration-color: #1b1b1b;
}
.hover\:underline-base-darkest:hover {
  -webkit-text-decoration-color: #1b1b1b;
  text-decoration-color: #1b1b1b;
}
.underline-ink {
  -webkit-text-decoration-color: #11181d;
  text-decoration-color: #11181d;
}
.hover\:underline-ink:hover {
  -webkit-text-decoration-color: #11181d;
  text-decoration-color: #11181d;
}
.underline-primary-lightest {
  -webkit-text-decoration-color: #B3EFFF;
  text-decoration-color: #B3EFFF;
}
.hover\:underline-primary-lightest:hover {
  -webkit-text-decoration-color: #B3EFFF;
  text-decoration-color: #B3EFFF;
}
.underline-primary-lighter {
  -webkit-text-decoration-color: #97d4ea;
  text-decoration-color: #97d4ea;
}
.hover\:underline-primary-lighter:hover {
  -webkit-text-decoration-color: #97d4ea;
  text-decoration-color: #97d4ea;
}
.underline-primary-light {
  -webkit-text-decoration-color: #28a0cb;
  text-decoration-color: #28a0cb;
}
.hover\:underline-primary-light:hover {
  -webkit-text-decoration-color: #28a0cb;
  text-decoration-color: #28a0cb;
}
.underline-primary {
  -webkit-text-decoration-color: #046B99;
  text-decoration-color: #046B99;
}
.hover\:underline-primary:hover {
  -webkit-text-decoration-color: #046B99;
  text-decoration-color: #046B99;
}
.underline-primary-vivid {
  -webkit-text-decoration-color: #0050d8;
  text-decoration-color: #0050d8;
}
.hover\:underline-primary-vivid:hover {
  -webkit-text-decoration-color: #0050d8;
  text-decoration-color: #0050d8;
}
.underline-primary-dark {
  -webkit-text-decoration-color: #0b4778;
  text-decoration-color: #0b4778;
}
.hover\:underline-primary-dark:hover {
  -webkit-text-decoration-color: #0b4778;
  text-decoration-color: #0b4778;
}
.underline-primary-darker {
  -webkit-text-decoration-color: #1C304A;
  text-decoration-color: #1C304A;
}
.hover\:underline-primary-darker:hover {
  -webkit-text-decoration-color: #1C304A;
  text-decoration-color: #1C304A;
}
.underline-primary-darkest {
  -webkit-text-decoration-color: #11181d;
  text-decoration-color: #11181d;
}
.hover\:underline-primary-darkest:hover {
  -webkit-text-decoration-color: #11181d;
  text-decoration-color: #11181d;
}
.underline-secondary-lighter {
  -webkit-text-decoration-color: #f3e1e4;
  text-decoration-color: #f3e1e4;
}
.hover\:underline-secondary-lighter:hover {
  -webkit-text-decoration-color: #f3e1e4;
  text-decoration-color: #f3e1e4;
}
.underline-secondary-light {
  -webkit-text-decoration-color: #f2938c;
  text-decoration-color: #f2938c;
}
.hover\:underline-secondary-light:hover {
  -webkit-text-decoration-color: #f2938c;
  text-decoration-color: #f2938c;
}
.underline-secondary {
  -webkit-text-decoration-color: #d83933;
  text-decoration-color: #d83933;
}
.hover\:underline-secondary:hover {
  -webkit-text-decoration-color: #d83933;
  text-decoration-color: #d83933;
}
.underline-secondary-vivid {
  -webkit-text-decoration-color: #e41d3d;
  text-decoration-color: #e41d3d;
}
.hover\:underline-secondary-vivid:hover {
  -webkit-text-decoration-color: #e41d3d;
  text-decoration-color: #e41d3d;
}
.underline-secondary-dark {
  -webkit-text-decoration-color: #b50909;
  text-decoration-color: #b50909;
}
.hover\:underline-secondary-dark:hover {
  -webkit-text-decoration-color: #b50909;
  text-decoration-color: #b50909;
}
.underline-secondary-darker {
  -webkit-text-decoration-color: #8b0a03;
  text-decoration-color: #8b0a03;
}
.hover\:underline-secondary-darker:hover {
  -webkit-text-decoration-color: #8b0a03;
  text-decoration-color: #8b0a03;
}
.underline-accent-warm-darker {
  -webkit-text-decoration-color: #775540;
  text-decoration-color: #775540;
}
.hover\:underline-accent-warm-darker:hover {
  -webkit-text-decoration-color: #775540;
  text-decoration-color: #775540;
}
.underline-accent-warm-dark {
  -webkit-text-decoration-color: #c05600;
  text-decoration-color: #c05600;
}
.hover\:underline-accent-warm-dark:hover {
  -webkit-text-decoration-color: #c05600;
  text-decoration-color: #c05600;
}
.underline-accent-warm {
  -webkit-text-decoration-color: #fa9441;
  text-decoration-color: #fa9441;
}
.hover\:underline-accent-warm:hover {
  -webkit-text-decoration-color: #fa9441;
  text-decoration-color: #fa9441;
}
.underline-accent-warm-light {
  -webkit-text-decoration-color: #ffbc78;
  text-decoration-color: #ffbc78;
}
.hover\:underline-accent-warm-light:hover {
  -webkit-text-decoration-color: #ffbc78;
  text-decoration-color: #ffbc78;
}
.underline-accent-warm-lighter {
  -webkit-text-decoration-color: #f2e4d4;
  text-decoration-color: #f2e4d4;
}
.hover\:underline-accent-warm-lighter:hover {
  -webkit-text-decoration-color: #f2e4d4;
  text-decoration-color: #f2e4d4;
}
.underline-accent-cool-darkest {
  -webkit-text-decoration-color: #093b44;
  text-decoration-color: #093b44;
}
.hover\:underline-accent-cool-darkest:hover {
  -webkit-text-decoration-color: #093b44;
  text-decoration-color: #093b44;
}
.underline-accent-cool-darker {
  -webkit-text-decoration-color: #0e4f5c;
  text-decoration-color: #0e4f5c;
}
.hover\:underline-accent-cool-darker:hover {
  -webkit-text-decoration-color: #0e4f5c;
  text-decoration-color: #0e4f5c;
}
.underline-accent-cool-dark {
  -webkit-text-decoration-color: #0081a1;
  text-decoration-color: #0081a1;
}
.hover\:underline-accent-cool-dark:hover {
  -webkit-text-decoration-color: #0081a1;
  text-decoration-color: #0081a1;
}
.underline-accent-cool {
  -webkit-text-decoration-color: #00CFFF;
  text-decoration-color: #00CFFF;
}
.hover\:underline-accent-cool:hover {
  -webkit-text-decoration-color: #00CFFF;
  text-decoration-color: #00CFFF;
}
.underline-accent-cool-light {
  -webkit-text-decoration-color: #52daf2;
  text-decoration-color: #52daf2;
}
.hover\:underline-accent-cool-light:hover {
  -webkit-text-decoration-color: #52daf2;
  text-decoration-color: #52daf2;
}
.underline-accent-cool-lighter {
  -webkit-text-decoration-color: #a8f2ff;
  text-decoration-color: #a8f2ff;
}
.hover\:underline-accent-cool-lighter:hover {
  -webkit-text-decoration-color: #a8f2ff;
  text-decoration-color: #a8f2ff;
}
.underline-accent-cool-lightest {
  -webkit-text-decoration-color: #eff6fb;
  text-decoration-color: #eff6fb;
}
.hover\:underline-accent-cool-lightest:hover {
  -webkit-text-decoration-color: #eff6fb;
  text-decoration-color: #eff6fb;
}
.underline-error-lighter {
  -webkit-text-decoration-color: #fff5ee;
  text-decoration-color: #fff5ee;
}
.hover\:underline-error-lighter:hover {
  -webkit-text-decoration-color: #fff5ee;
  text-decoration-color: #fff5ee;
}
.underline-error-light {
  -webkit-text-decoration-color: #f39268;
  text-decoration-color: #f39268;
}
.hover\:underline-error-light:hover {
  -webkit-text-decoration-color: #f39268;
  text-decoration-color: #f39268;
}
.underline-error {
  -webkit-text-decoration-color: #d54309;
  text-decoration-color: #d54309;
}
.hover\:underline-error:hover {
  -webkit-text-decoration-color: #d54309;
  text-decoration-color: #d54309;
}
.underline-error-dark {
  -webkit-text-decoration-color: #b50909;
  text-decoration-color: #b50909;
}
.hover\:underline-error-dark:hover {
  -webkit-text-decoration-color: #b50909;
  text-decoration-color: #b50909;
}
.underline-error-darker {
  -webkit-text-decoration-color: #6f3331;
  text-decoration-color: #6f3331;
}
.hover\:underline-error-darker:hover {
  -webkit-text-decoration-color: #6f3331;
  text-decoration-color: #6f3331;
}
.underline-warning-lighter {
  -webkit-text-decoration-color: #faf3d1;
  text-decoration-color: #faf3d1;
}
.hover\:underline-warning-lighter:hover {
  -webkit-text-decoration-color: #faf3d1;
  text-decoration-color: #faf3d1;
}
.underline-warning-light {
  -webkit-text-decoration-color: #fee685;
  text-decoration-color: #fee685;
}
.hover\:underline-warning-light:hover {
  -webkit-text-decoration-color: #fee685;
  text-decoration-color: #fee685;
}
.underline-warning {
  -webkit-text-decoration-color: #ffbe2e;
  text-decoration-color: #ffbe2e;
}
.hover\:underline-warning:hover {
  -webkit-text-decoration-color: #ffbe2e;
  text-decoration-color: #ffbe2e;
}
.underline-warning-dark {
  -webkit-text-decoration-color: #e5a000;
  text-decoration-color: #e5a000;
}
.hover\:underline-warning-dark:hover {
  -webkit-text-decoration-color: #e5a000;
  text-decoration-color: #e5a000;
}
.underline-warning-darker {
  -webkit-text-decoration-color: #936f38;
  text-decoration-color: #936f38;
}
.hover\:underline-warning-darker:hover {
  -webkit-text-decoration-color: #936f38;
  text-decoration-color: #936f38;
}
.underline-success-lighter {
  -webkit-text-decoration-color: #ecf3ec;
  text-decoration-color: #ecf3ec;
}
.hover\:underline-success-lighter:hover {
  -webkit-text-decoration-color: #ecf3ec;
  text-decoration-color: #ecf3ec;
}
.underline-success-light {
  -webkit-text-decoration-color: #70e17b;
  text-decoration-color: #70e17b;
}
.hover\:underline-success-light:hover {
  -webkit-text-decoration-color: #70e17b;
  text-decoration-color: #70e17b;
}
.underline-success {
  -webkit-text-decoration-color: #00a91c;
  text-decoration-color: #00a91c;
}
.hover\:underline-success:hover {
  -webkit-text-decoration-color: #00a91c;
  text-decoration-color: #00a91c;
}
.underline-success-dark {
  -webkit-text-decoration-color: #008817;
  text-decoration-color: #008817;
}
.hover\:underline-success-dark:hover {
  -webkit-text-decoration-color: #008817;
  text-decoration-color: #008817;
}
.underline-success-darker {
  -webkit-text-decoration-color: #216e1f;
  text-decoration-color: #216e1f;
}
.hover\:underline-success-darker:hover {
  -webkit-text-decoration-color: #216e1f;
  text-decoration-color: #216e1f;
}
.underline-info-lighter {
  -webkit-text-decoration-color: #e7f6f8;
  text-decoration-color: #e7f6f8;
}
.hover\:underline-info-lighter:hover {
  -webkit-text-decoration-color: #e7f6f8;
  text-decoration-color: #e7f6f8;
}
.underline-info-light {
  -webkit-text-decoration-color: #99deea;
  text-decoration-color: #99deea;
}
.hover\:underline-info-light:hover {
  -webkit-text-decoration-color: #99deea;
  text-decoration-color: #99deea;
}
.underline-info {
  -webkit-text-decoration-color: #00bde3;
  text-decoration-color: #00bde3;
}
.hover\:underline-info:hover {
  -webkit-text-decoration-color: #00bde3;
  text-decoration-color: #00bde3;
}
.underline-info-dark {
  -webkit-text-decoration-color: #009ec1;
  text-decoration-color: #009ec1;
}
.hover\:underline-info-dark:hover {
  -webkit-text-decoration-color: #009ec1;
  text-decoration-color: #009ec1;
}
.underline-info-darker {
  -webkit-text-decoration-color: #2e6276;
  text-decoration-color: #2e6276;
}
.hover\:underline-info-darker:hover {
  -webkit-text-decoration-color: #2e6276;
  text-decoration-color: #2e6276;
}
.underline-disabled-lighter {
  -webkit-text-decoration-color: #c9c9c9;
  text-decoration-color: #c9c9c9;
}
.hover\:underline-disabled-lighter:hover {
  -webkit-text-decoration-color: #c9c9c9;
  text-decoration-color: #c9c9c9;
}
.underline-disabled-light {
  -webkit-text-decoration-color: #919191;
  text-decoration-color: #919191;
}
.hover\:underline-disabled-light:hover {
  -webkit-text-decoration-color: #919191;
  text-decoration-color: #919191;
}
.underline-disabled {
  -webkit-text-decoration-color: #757575;
  text-decoration-color: #757575;
}
.hover\:underline-disabled:hover {
  -webkit-text-decoration-color: #757575;
  text-decoration-color: #757575;
}
.underline-disabled-dark {
  -webkit-text-decoration-color: #454545;
  text-decoration-color: #454545;
}
.hover\:underline-disabled-dark:hover {
  -webkit-text-decoration-color: #454545;
  text-decoration-color: #454545;
}
.underline-disabled-darker {
  -webkit-text-decoration-color: #1b1b1b;
  text-decoration-color: #1b1b1b;
}
.hover\:underline-disabled-darker:hover {
  -webkit-text-decoration-color: #1b1b1b;
  text-decoration-color: #1b1b1b;
}
.underline-emergency {
  -webkit-text-decoration-color: #9c3d10;
  text-decoration-color: #9c3d10;
}
.hover\:underline-emergency:hover {
  -webkit-text-decoration-color: #9c3d10;
  text-decoration-color: #9c3d10;
}
.underline-emergency-dark {
  -webkit-text-decoration-color: #332d29;
  text-decoration-color: #332d29;
}
.hover\:underline-emergency-dark:hover {
  -webkit-text-decoration-color: #332d29;
  text-decoration-color: #332d29;
}
.text-indent-0 {
  text-indent: 0;
}
.text-indent-05 {
  text-indent: 0.25rem;
}
.text-indent-1 {
  text-indent: 0.5rem;
}
.text-indent-105 {
  text-indent: 0.75rem;
}
.text-indent-2 {
  text-indent: 1rem;
}
.text-indent-205 {
  text-indent: 1.25rem;
}
.text-indent-3 {
  text-indent: 1.5rem;
}
.text-indent-neg-05 {
  text-indent: -0.25rem;
}
.text-indent-neg-1 {
  text-indent: -0.5rem;
}
.text-indent-neg-105 {
  text-indent: -0.75rem;
}
.text-indent-neg-2 {
  text-indent: -1rem;
}
.text-indent-neg-205 {
  text-indent: -1.25rem;
}
.text-indent-neg-3 {
  text-indent: -1.5rem;
}
.text-indent-4 {
  text-indent: 2rem;
}
.text-indent-5 {
  text-indent: 2.5rem;
}
.text-indent-6 {
  text-indent: 3rem;
}
.text-indent-7 {
  text-indent: 3.5rem;
}
.text-indent-8 {
  text-indent: 4rem;
}
.text-indent-9 {
  text-indent: 4.5rem;
}
.text-indent-10 {
  text-indent: 5rem;
}
.text-indent-15 {
  text-indent: 7.5rem;
}
.text-indent-neg-4 {
  text-indent: -2rem;
}
.text-indent-neg-5 {
  text-indent: -2.5rem;
}
.text-indent-neg-6 {
  text-indent: -3rem;
}
.text-indent-neg-7 {
  text-indent: -3.5rem;
}
.text-indent-neg-8 {
  text-indent: -4rem;
}
.text-indent-neg-9 {
  text-indent: -4.5rem;
}
.text-indent-neg-10 {
  text-indent: -5rem;
}
.text-indent-neg-15 {
  text-indent: -7.5rem;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-no-uppercase {
  text-transform: none;
}
.text-lowercase {
  text-transform: lowercase;
}
.text-no-lowercase {
  text-transform: none;
}
.top-1px {
  top: 1px;
}
.top-2px {
  top: 2px;
}
.top-05 {
  top: 0.25rem;
}
.top-1 {
  top: 0.5rem;
}
.top-105 {
  top: 0.75rem;
}
.top-2 {
  top: 1rem;
}
.top-205 {
  top: 1.25rem;
}
.top-3 {
  top: 1.5rem;
}
.top-neg-1px {
  top: -1px;
}
.top-neg-2px {
  top: -2px;
}
.top-neg-05 {
  top: -0.25rem;
}
.top-neg-1 {
  top: -0.5rem;
}
.top-neg-105 {
  top: -0.75rem;
}
.top-neg-2 {
  top: -1rem;
}
.top-neg-205 {
  top: -1.25rem;
}
.top-neg-3 {
  top: -1.5rem;
}
.top-0 {
  top: 0;
}
.top-auto {
  top: auto;
}
.top-full {
  top: 100%;
}
.text-baseline {
  vertical-align: baseline;
}
.text-bottom {
  vertical-align: bottom;
}
.text-middle {
  vertical-align: middle;
}
.text-sub {
  vertical-align: sub;
}
.text-super {
  vertical-align: super;
}
.text-tbottom {
  vertical-align: text-bottom;
}
.text-ttop {
  vertical-align: text-top;
}
.text-top {
  vertical-align: top;
}
.text-pre {
  white-space: pre;
}
.text-pre-line {
  white-space: pre-line;
}
.text-pre-wrap {
  white-space: pre-wrap;
}
.text-wrap {
  white-space: normal;
}
.text-no-wrap {
  white-space: nowrap;
}
.width-1px {
  width: 1px;
}
.width-2px {
  width: 2px;
}
.width-05 {
  width: 0.25rem;
}
.width-1 {
  width: 0.5rem;
}
.width-105 {
  width: 0.75rem;
}
.width-2 {
  width: 1rem;
}
.width-205 {
  width: 1.25rem;
}
.width-3 {
  width: 1.5rem;
}
.width-4 {
  width: 2rem;
}
.width-5 {
  width: 2.5rem;
}
.width-6 {
  width: 3rem;
}
.width-7 {
  width: 3.5rem;
}
.width-8 {
  width: 4rem;
}
.width-9 {
  width: 4.5rem;
}
.width-10 {
  width: 5rem;
}
.width-15 {
  width: 7.5rem;
}
.width-card {
  width: 10rem;
}
.width-card-lg {
  width: 15rem;
}
.width-mobile {
  width: 20rem;
}
.width-mobile-lg {
  width: 30rem;
}
.width-tablet {
  width: 40rem;
}
.width-tablet-lg {
  width: 55rem;
}
.width-desktop {
  width: 64rem;
}
.width-desktop-lg {
  width: 75rem;
}
.width-widescreen {
  width: 87.5rem;
}
.width-0 {
  width: 0;
}
.width-full {
  width: 100%;
}
.width-auto {
  width: auto;
}
.z-auto {
  z-index: auto;
}
.z-bottom {
  z-index: -100;
}
.z-top {
  z-index: 99999;
}
.z-0 {
  z-index: 0;
}
.z-100 {
  z-index: 100;
}
.z-200 {
  z-index: 200;
}
.z-300 {
  z-index: 300;
}
.z-400 {
  z-index: 400;
}
.z-500 {
  z-index: 500;
}
@media all and (min-width: 30em) {
  .mobile-lg\:border-1px {
    border: 1px solid;
  }
  .mobile-lg\:hover\:border-1px:hover {
    border: 1px solid;
  }
  .mobile-lg\:border-y-1px {
    border-top: 1px solid;
    border-bottom: 1px solid;
  }
  .mobile-lg\:hover\:border-y-1px:hover {
    border-top: 1px solid;
    border-bottom: 1px solid;
  }
  .mobile-lg\:border-x-1px {
    border-left: 1px solid;
    border-right: 1px solid;
  }
  .mobile-lg\:hover\:border-x-1px:hover {
    border-left: 1px solid;
    border-right: 1px solid;
  }
  .mobile-lg\:border-top-1px {
    border-top: 1px solid;
  }
  .mobile-lg\:hover\:border-top-1px:hover {
    border-top: 1px solid;
  }
  .mobile-lg\:border-right-1px {
    border-right: 1px solid;
  }
  .mobile-lg\:hover\:border-right-1px:hover {
    border-right: 1px solid;
  }
  .mobile-lg\:border-bottom-1px {
    border-bottom: 1px solid;
  }
  .mobile-lg\:hover\:border-bottom-1px:hover {
    border-bottom: 1px solid;
  }
  .mobile-lg\:border-left-1px {
    border-left: 1px solid;
  }
  .mobile-lg\:hover\:border-left-1px:hover {
    border-left: 1px solid;
  }
  .mobile-lg\:border-2px {
    border: 2px solid;
  }
  .mobile-lg\:hover\:border-2px:hover {
    border: 2px solid;
  }
  .mobile-lg\:border-y-2px {
    border-top: 2px solid;
    border-bottom: 2px solid;
  }
  .mobile-lg\:hover\:border-y-2px:hover {
    border-top: 2px solid;
    border-bottom: 2px solid;
  }
  .mobile-lg\:border-x-2px {
    border-left: 2px solid;
    border-right: 2px solid;
  }
  .mobile-lg\:hover\:border-x-2px:hover {
    border-left: 2px solid;
    border-right: 2px solid;
  }
  .mobile-lg\:border-top-2px {
    border-top: 2px solid;
  }
  .mobile-lg\:hover\:border-top-2px:hover {
    border-top: 2px solid;
  }
  .mobile-lg\:border-right-2px {
    border-right: 2px solid;
  }
  .mobile-lg\:hover\:border-right-2px:hover {
    border-right: 2px solid;
  }
  .mobile-lg\:border-bottom-2px {
    border-bottom: 2px solid;
  }
  .mobile-lg\:hover\:border-bottom-2px:hover {
    border-bottom: 2px solid;
  }
  .mobile-lg\:border-left-2px {
    border-left: 2px solid;
  }
  .mobile-lg\:hover\:border-left-2px:hover {
    border-left: 2px solid;
  }
  .mobile-lg\:border-05 {
    border: 0.25rem solid;
  }
  .mobile-lg\:hover\:border-05:hover {
    border: 0.25rem solid;
  }
  .mobile-lg\:border-y-05 {
    border-top: 0.25rem solid;
    border-bottom: 0.25rem solid;
  }
  .mobile-lg\:hover\:border-y-05:hover {
    border-top: 0.25rem solid;
    border-bottom: 0.25rem solid;
  }
  .mobile-lg\:border-x-05 {
    border-left: 0.25rem solid;
    border-right: 0.25rem solid;
  }
  .mobile-lg\:hover\:border-x-05:hover {
    border-left: 0.25rem solid;
    border-right: 0.25rem solid;
  }
  .mobile-lg\:border-top-05 {
    border-top: 0.25rem solid;
  }
  .mobile-lg\:hover\:border-top-05:hover {
    border-top: 0.25rem solid;
  }
  .mobile-lg\:border-right-05 {
    border-right: 0.25rem solid;
  }
  .mobile-lg\:hover\:border-right-05:hover {
    border-right: 0.25rem solid;
  }
  .mobile-lg\:border-bottom-05 {
    border-bottom: 0.25rem solid;
  }
  .mobile-lg\:hover\:border-bottom-05:hover {
    border-bottom: 0.25rem solid;
  }
  .mobile-lg\:border-left-05 {
    border-left: 0.25rem solid;
  }
  .mobile-lg\:hover\:border-left-05:hover {
    border-left: 0.25rem solid;
  }
  .mobile-lg\:border-1 {
    border: 0.5rem solid;
  }
  .mobile-lg\:hover\:border-1:hover {
    border: 0.5rem solid;
  }
  .mobile-lg\:border-y-1 {
    border-top: 0.5rem solid;
    border-bottom: 0.5rem solid;
  }
  .mobile-lg\:hover\:border-y-1:hover {
    border-top: 0.5rem solid;
    border-bottom: 0.5rem solid;
  }
  .mobile-lg\:border-x-1 {
    border-left: 0.5rem solid;
    border-right: 0.5rem solid;
  }
  .mobile-lg\:hover\:border-x-1:hover {
    border-left: 0.5rem solid;
    border-right: 0.5rem solid;
  }
  .mobile-lg\:border-top-1 {
    border-top: 0.5rem solid;
  }
  .mobile-lg\:hover\:border-top-1:hover {
    border-top: 0.5rem solid;
  }
  .mobile-lg\:border-right-1 {
    border-right: 0.5rem solid;
  }
  .mobile-lg\:hover\:border-right-1:hover {
    border-right: 0.5rem solid;
  }
  .mobile-lg\:border-bottom-1 {
    border-bottom: 0.5rem solid;
  }
  .mobile-lg\:hover\:border-bottom-1:hover {
    border-bottom: 0.5rem solid;
  }
  .mobile-lg\:border-left-1 {
    border-left: 0.5rem solid;
  }
  .mobile-lg\:hover\:border-left-1:hover {
    border-left: 0.5rem solid;
  }
  .mobile-lg\:border-105 {
    border: 0.75rem solid;
  }
  .mobile-lg\:hover\:border-105:hover {
    border: 0.75rem solid;
  }
  .mobile-lg\:border-y-105 {
    border-top: 0.75rem solid;
    border-bottom: 0.75rem solid;
  }
  .mobile-lg\:hover\:border-y-105:hover {
    border-top: 0.75rem solid;
    border-bottom: 0.75rem solid;
  }
  .mobile-lg\:border-x-105 {
    border-left: 0.75rem solid;
    border-right: 0.75rem solid;
  }
  .mobile-lg\:hover\:border-x-105:hover {
    border-left: 0.75rem solid;
    border-right: 0.75rem solid;
  }
  .mobile-lg\:border-top-105 {
    border-top: 0.75rem solid;
  }
  .mobile-lg\:hover\:border-top-105:hover {
    border-top: 0.75rem solid;
  }
  .mobile-lg\:border-right-105 {
    border-right: 0.75rem solid;
  }
  .mobile-lg\:hover\:border-right-105:hover {
    border-right: 0.75rem solid;
  }
  .mobile-lg\:border-bottom-105 {
    border-bottom: 0.75rem solid;
  }
  .mobile-lg\:hover\:border-bottom-105:hover {
    border-bottom: 0.75rem solid;
  }
  .mobile-lg\:border-left-105 {
    border-left: 0.75rem solid;
  }
  .mobile-lg\:hover\:border-left-105:hover {
    border-left: 0.75rem solid;
  }
  .mobile-lg\:border-2 {
    border: 1rem solid;
  }
  .mobile-lg\:hover\:border-2:hover {
    border: 1rem solid;
  }
  .mobile-lg\:border-y-2 {
    border-top: 1rem solid;
    border-bottom: 1rem solid;
  }
  .mobile-lg\:hover\:border-y-2:hover {
    border-top: 1rem solid;
    border-bottom: 1rem solid;
  }
  .mobile-lg\:border-x-2 {
    border-left: 1rem solid;
    border-right: 1rem solid;
  }
  .mobile-lg\:hover\:border-x-2:hover {
    border-left: 1rem solid;
    border-right: 1rem solid;
  }
  .mobile-lg\:border-top-2 {
    border-top: 1rem solid;
  }
  .mobile-lg\:hover\:border-top-2:hover {
    border-top: 1rem solid;
  }
  .mobile-lg\:border-right-2 {
    border-right: 1rem solid;
  }
  .mobile-lg\:hover\:border-right-2:hover {
    border-right: 1rem solid;
  }
  .mobile-lg\:border-bottom-2 {
    border-bottom: 1rem solid;
  }
  .mobile-lg\:hover\:border-bottom-2:hover {
    border-bottom: 1rem solid;
  }
  .mobile-lg\:border-left-2 {
    border-left: 1rem solid;
  }
  .mobile-lg\:hover\:border-left-2:hover {
    border-left: 1rem solid;
  }
  .mobile-lg\:border-205 {
    border: 1.25rem solid;
  }
  .mobile-lg\:hover\:border-205:hover {
    border: 1.25rem solid;
  }
  .mobile-lg\:border-y-205 {
    border-top: 1.25rem solid;
    border-bottom: 1.25rem solid;
  }
  .mobile-lg\:hover\:border-y-205:hover {
    border-top: 1.25rem solid;
    border-bottom: 1.25rem solid;
  }
  .mobile-lg\:border-x-205 {
    border-left: 1.25rem solid;
    border-right: 1.25rem solid;
  }
  .mobile-lg\:hover\:border-x-205:hover {
    border-left: 1.25rem solid;
    border-right: 1.25rem solid;
  }
  .mobile-lg\:border-top-205 {
    border-top: 1.25rem solid;
  }
  .mobile-lg\:hover\:border-top-205:hover {
    border-top: 1.25rem solid;
  }
  .mobile-lg\:border-right-205 {
    border-right: 1.25rem solid;
  }
  .mobile-lg\:hover\:border-right-205:hover {
    border-right: 1.25rem solid;
  }
  .mobile-lg\:border-bottom-205 {
    border-bottom: 1.25rem solid;
  }
  .mobile-lg\:hover\:border-bottom-205:hover {
    border-bottom: 1.25rem solid;
  }
  .mobile-lg\:border-left-205 {
    border-left: 1.25rem solid;
  }
  .mobile-lg\:hover\:border-left-205:hover {
    border-left: 1.25rem solid;
  }
  .mobile-lg\:border-3 {
    border: 1.5rem solid;
  }
  .mobile-lg\:hover\:border-3:hover {
    border: 1.5rem solid;
  }
  .mobile-lg\:border-y-3 {
    border-top: 1.5rem solid;
    border-bottom: 1.5rem solid;
  }
  .mobile-lg\:hover\:border-y-3:hover {
    border-top: 1.5rem solid;
    border-bottom: 1.5rem solid;
  }
  .mobile-lg\:border-x-3 {
    border-left: 1.5rem solid;
    border-right: 1.5rem solid;
  }
  .mobile-lg\:hover\:border-x-3:hover {
    border-left: 1.5rem solid;
    border-right: 1.5rem solid;
  }
  .mobile-lg\:border-top-3 {
    border-top: 1.5rem solid;
  }
  .mobile-lg\:hover\:border-top-3:hover {
    border-top: 1.5rem solid;
  }
  .mobile-lg\:border-right-3 {
    border-right: 1.5rem solid;
  }
  .mobile-lg\:hover\:border-right-3:hover {
    border-right: 1.5rem solid;
  }
  .mobile-lg\:border-bottom-3 {
    border-bottom: 1.5rem solid;
  }
  .mobile-lg\:hover\:border-bottom-3:hover {
    border-bottom: 1.5rem solid;
  }
  .mobile-lg\:border-left-3 {
    border-left: 1.5rem solid;
  }
  .mobile-lg\:hover\:border-left-3:hover {
    border-left: 1.5rem solid;
  }
  .mobile-lg\:border-0 {
    border: 0 solid;
  }
  .mobile-lg\:hover\:border-0:hover {
    border: 0 solid;
  }
  .mobile-lg\:border-y-0 {
    border-top: 0 solid;
    border-bottom: 0 solid;
  }
  .mobile-lg\:hover\:border-y-0:hover {
    border-top: 0 solid;
    border-bottom: 0 solid;
  }
  .mobile-lg\:border-x-0 {
    border-left: 0 solid;
    border-right: 0 solid;
  }
  .mobile-lg\:hover\:border-x-0:hover {
    border-left: 0 solid;
    border-right: 0 solid;
  }
  .mobile-lg\:border-top-0 {
    border-top: 0 solid;
  }
  .mobile-lg\:hover\:border-top-0:hover {
    border-top: 0 solid;
  }
  .mobile-lg\:border-right-0 {
    border-right: 0 solid;
  }
  .mobile-lg\:hover\:border-right-0:hover {
    border-right: 0 solid;
  }
  .mobile-lg\:border-bottom-0 {
    border-bottom: 0 solid;
  }
  .mobile-lg\:hover\:border-bottom-0:hover {
    border-bottom: 0 solid;
  }
  .mobile-lg\:border-left-0 {
    border-left: 0 solid;
  }
  .mobile-lg\:hover\:border-left-0:hover {
    border-left: 0 solid;
  }
  .mobile-lg\:border {
    border: 1px solid;
  }
  .mobile-lg\:hover\:border:hover {
    border: 1px solid;
  }
  .mobile-lg\:border-y {
    border-top: 1px solid;
    border-bottom: 1px solid;
  }
  .mobile-lg\:hover\:border-y:hover {
    border-top: 1px solid;
    border-bottom: 1px solid;
  }
  .mobile-lg\:border-x {
    border-left: 1px solid;
    border-right: 1px solid;
  }
  .mobile-lg\:hover\:border-x:hover {
    border-left: 1px solid;
    border-right: 1px solid;
  }
  .mobile-lg\:border-top {
    border-top: 1px solid;
  }
  .mobile-lg\:hover\:border-top:hover {
    border-top: 1px solid;
  }
  .mobile-lg\:border-right {
    border-right: 1px solid;
  }
  .mobile-lg\:hover\:border-right:hover {
    border-right: 1px solid;
  }
  .mobile-lg\:border-bottom {
    border-bottom: 1px solid;
  }
  .mobile-lg\:hover\:border-bottom:hover {
    border-bottom: 1px solid;
  }
  .mobile-lg\:border-left {
    border-left: 1px solid;
  }
  .mobile-lg\:hover\:border-left:hover {
    border-left: 1px solid;
  }
  .mobile-lg\:border-transparent {
    border-color: transparent;
  }
  .mobile-lg\:hover\:border-transparent:hover {
    border-color: transparent;
  }
  .mobile-lg\:border-black {
    border-color: black;
  }
  .mobile-lg\:hover\:border-black:hover {
    border-color: black;
  }
  .mobile-lg\:border-white {
    border-color: white;
  }
  .mobile-lg\:hover\:border-white:hover {
    border-color: white;
  }
  .mobile-lg\:border-red {
    border-color: #e52207;
  }
  .mobile-lg\:hover\:border-red:hover {
    border-color: #e52207;
  }
  .mobile-lg\:border-orange {
    border-color: #e66f0e;
  }
  .mobile-lg\:hover\:border-orange:hover {
    border-color: #e66f0e;
  }
  .mobile-lg\:border-gold {
    border-color: #ffbe2e;
  }
  .mobile-lg\:hover\:border-gold:hover {
    border-color: #ffbe2e;
  }
  .mobile-lg\:border-yellow {
    border-color: #fee685;
  }
  .mobile-lg\:hover\:border-yellow:hover {
    border-color: #fee685;
  }
  .mobile-lg\:border-green {
    border-color: #538200;
  }
  .mobile-lg\:hover\:border-green:hover {
    border-color: #538200;
  }
  .mobile-lg\:border-mint {
    border-color: #04c585;
  }
  .mobile-lg\:hover\:border-mint:hover {
    border-color: #04c585;
  }
  .mobile-lg\:border-cyan {
    border-color: #009ec1;
  }
  .mobile-lg\:hover\:border-cyan:hover {
    border-color: #009ec1;
  }
  .mobile-lg\:border-blue {
    border-color: #0076d6;
  }
  .mobile-lg\:hover\:border-blue:hover {
    border-color: #0076d6;
  }
  .mobile-lg\:border-indigo {
    border-color: #676cc8;
  }
  .mobile-lg\:hover\:border-indigo:hover {
    border-color: #676cc8;
  }
  .mobile-lg\:border-violet {
    border-color: #8168b3;
  }
  .mobile-lg\:hover\:border-violet:hover {
    border-color: #8168b3;
  }
  .mobile-lg\:border-magenta {
    border-color: #d72d79;
  }
  .mobile-lg\:hover\:border-magenta:hover {
    border-color: #d72d79;
  }
  .mobile-lg\:border-gray-5 {
    border-color: #f0f0f0;
  }
  .mobile-lg\:hover\:border-gray-5:hover {
    border-color: #f0f0f0;
  }
  .mobile-lg\:border-gray-10 {
    border-color: #e6e6e6;
  }
  .mobile-lg\:hover\:border-gray-10:hover {
    border-color: #e6e6e6;
  }
  .mobile-lg\:border-gray-30 {
    border-color: #adadad;
  }
  .mobile-lg\:hover\:border-gray-30:hover {
    border-color: #adadad;
  }
  .mobile-lg\:border-gray-50 {
    border-color: #757575;
  }
  .mobile-lg\:hover\:border-gray-50:hover {
    border-color: #757575;
  }
  .mobile-lg\:border-gray-70 {
    border-color: #454545;
  }
  .mobile-lg\:hover\:border-gray-70:hover {
    border-color: #454545;
  }
  .mobile-lg\:border-gray-90 {
    border-color: #1b1b1b;
  }
  .mobile-lg\:hover\:border-gray-90:hover {
    border-color: #1b1b1b;
  }
  .mobile-lg\:border-base-lightest {
    border-color: #f0f0f0;
  }
  .mobile-lg\:hover\:border-base-lightest:hover {
    border-color: #f0f0f0;
  }
  .mobile-lg\:border-base-lighter {
    border-color: #dfe1e2;
  }
  .mobile-lg\:hover\:border-base-lighter:hover {
    border-color: #dfe1e2;
  }
  .mobile-lg\:border-base-light {
    border-color: #a9aeb1;
  }
  .mobile-lg\:hover\:border-base-light:hover {
    border-color: #a9aeb1;
  }
  .mobile-lg\:border-base {
    border-color: #71767a;
  }
  .mobile-lg\:hover\:border-base:hover {
    border-color: #71767a;
  }
  .mobile-lg\:border-base-dark {
    border-color: #565c65;
  }
  .mobile-lg\:hover\:border-base-dark:hover {
    border-color: #565c65;
  }
  .mobile-lg\:border-base-darker {
    border-color: #3d4551;
  }
  .mobile-lg\:hover\:border-base-darker:hover {
    border-color: #3d4551;
  }
  .mobile-lg\:border-base-darkest {
    border-color: #1b1b1b;
  }
  .mobile-lg\:hover\:border-base-darkest:hover {
    border-color: #1b1b1b;
  }
  .mobile-lg\:border-ink {
    border-color: #11181d;
  }
  .mobile-lg\:hover\:border-ink:hover {
    border-color: #11181d;
  }
  .mobile-lg\:border-primary-lightest {
    border-color: #B3EFFF;
  }
  .mobile-lg\:hover\:border-primary-lightest:hover {
    border-color: #B3EFFF;
  }
  .mobile-lg\:border-primary-lighter {
    border-color: #97d4ea;
  }
  .mobile-lg\:hover\:border-primary-lighter:hover {
    border-color: #97d4ea;
  }
  .mobile-lg\:border-primary-light {
    border-color: #28a0cb;
  }
  .mobile-lg\:hover\:border-primary-light:hover {
    border-color: #28a0cb;
  }
  .mobile-lg\:border-primary {
    border-color: #046B99;
  }
  .mobile-lg\:hover\:border-primary:hover {
    border-color: #046B99;
  }
  .mobile-lg\:border-primary-vivid {
    border-color: #0050d8;
  }
  .mobile-lg\:hover\:border-primary-vivid:hover {
    border-color: #0050d8;
  }
  .mobile-lg\:border-primary-dark {
    border-color: #0b4778;
  }
  .mobile-lg\:hover\:border-primary-dark:hover {
    border-color: #0b4778;
  }
  .mobile-lg\:border-primary-darker {
    border-color: #1C304A;
  }
  .mobile-lg\:hover\:border-primary-darker:hover {
    border-color: #1C304A;
  }
  .mobile-lg\:border-primary-darkest {
    border-color: #11181d;
  }
  .mobile-lg\:hover\:border-primary-darkest:hover {
    border-color: #11181d;
  }
  .mobile-lg\:border-secondary-lighter {
    border-color: #f3e1e4;
  }
  .mobile-lg\:hover\:border-secondary-lighter:hover {
    border-color: #f3e1e4;
  }
  .mobile-lg\:border-secondary-light {
    border-color: #f2938c;
  }
  .mobile-lg\:hover\:border-secondary-light:hover {
    border-color: #f2938c;
  }
  .mobile-lg\:border-secondary {
    border-color: #d83933;
  }
  .mobile-lg\:hover\:border-secondary:hover {
    border-color: #d83933;
  }
  .mobile-lg\:border-secondary-vivid {
    border-color: #e41d3d;
  }
  .mobile-lg\:hover\:border-secondary-vivid:hover {
    border-color: #e41d3d;
  }
  .mobile-lg\:border-secondary-dark {
    border-color: #b50909;
  }
  .mobile-lg\:hover\:border-secondary-dark:hover {
    border-color: #b50909;
  }
  .mobile-lg\:border-secondary-darker {
    border-color: #8b0a03;
  }
  .mobile-lg\:hover\:border-secondary-darker:hover {
    border-color: #8b0a03;
  }
  .mobile-lg\:border-accent-warm-darker {
    border-color: #775540;
  }
  .mobile-lg\:hover\:border-accent-warm-darker:hover {
    border-color: #775540;
  }
  .mobile-lg\:border-accent-warm-dark {
    border-color: #c05600;
  }
  .mobile-lg\:hover\:border-accent-warm-dark:hover {
    border-color: #c05600;
  }
  .mobile-lg\:border-accent-warm {
    border-color: #fa9441;
  }
  .mobile-lg\:hover\:border-accent-warm:hover {
    border-color: #fa9441;
  }
  .mobile-lg\:border-accent-warm-light {
    border-color: #ffbc78;
  }
  .mobile-lg\:hover\:border-accent-warm-light:hover {
    border-color: #ffbc78;
  }
  .mobile-lg\:border-accent-warm-lighter {
    border-color: #f2e4d4;
  }
  .mobile-lg\:hover\:border-accent-warm-lighter:hover {
    border-color: #f2e4d4;
  }
  .mobile-lg\:border-accent-cool-darkest {
    border-color: #093b44;
  }
  .mobile-lg\:hover\:border-accent-cool-darkest:hover {
    border-color: #093b44;
  }
  .mobile-lg\:border-accent-cool-darker {
    border-color: #0e4f5c;
  }
  .mobile-lg\:hover\:border-accent-cool-darker:hover {
    border-color: #0e4f5c;
  }
  .mobile-lg\:border-accent-cool-dark {
    border-color: #0081a1;
  }
  .mobile-lg\:hover\:border-accent-cool-dark:hover {
    border-color: #0081a1;
  }
  .mobile-lg\:border-accent-cool {
    border-color: #00CFFF;
  }
  .mobile-lg\:hover\:border-accent-cool:hover {
    border-color: #00CFFF;
  }
  .mobile-lg\:border-accent-cool-light {
    border-color: #52daf2;
  }
  .mobile-lg\:hover\:border-accent-cool-light:hover {
    border-color: #52daf2;
  }
  .mobile-lg\:border-accent-cool-lighter {
    border-color: #a8f2ff;
  }
  .mobile-lg\:hover\:border-accent-cool-lighter:hover {
    border-color: #a8f2ff;
  }
  .mobile-lg\:border-accent-cool-lightest {
    border-color: #eff6fb;
  }
  .mobile-lg\:hover\:border-accent-cool-lightest:hover {
    border-color: #eff6fb;
  }
  .mobile-lg\:border-error-lighter {
    border-color: #fff5ee;
  }
  .mobile-lg\:hover\:border-error-lighter:hover {
    border-color: #fff5ee;
  }
  .mobile-lg\:border-error-light {
    border-color: #f39268;
  }
  .mobile-lg\:hover\:border-error-light:hover {
    border-color: #f39268;
  }
  .mobile-lg\:border-error {
    border-color: #d54309;
  }
  .mobile-lg\:hover\:border-error:hover {
    border-color: #d54309;
  }
  .mobile-lg\:border-error-dark {
    border-color: #b50909;
  }
  .mobile-lg\:hover\:border-error-dark:hover {
    border-color: #b50909;
  }
  .mobile-lg\:border-error-darker {
    border-color: #6f3331;
  }
  .mobile-lg\:hover\:border-error-darker:hover {
    border-color: #6f3331;
  }
  .mobile-lg\:border-warning-lighter {
    border-color: #faf3d1;
  }
  .mobile-lg\:hover\:border-warning-lighter:hover {
    border-color: #faf3d1;
  }
  .mobile-lg\:border-warning-light {
    border-color: #fee685;
  }
  .mobile-lg\:hover\:border-warning-light:hover {
    border-color: #fee685;
  }
  .mobile-lg\:border-warning {
    border-color: #ffbe2e;
  }
  .mobile-lg\:hover\:border-warning:hover {
    border-color: #ffbe2e;
  }
  .mobile-lg\:border-warning-dark {
    border-color: #e5a000;
  }
  .mobile-lg\:hover\:border-warning-dark:hover {
    border-color: #e5a000;
  }
  .mobile-lg\:border-warning-darker {
    border-color: #936f38;
  }
  .mobile-lg\:hover\:border-warning-darker:hover {
    border-color: #936f38;
  }
  .mobile-lg\:border-success-lighter {
    border-color: #ecf3ec;
  }
  .mobile-lg\:hover\:border-success-lighter:hover {
    border-color: #ecf3ec;
  }
  .mobile-lg\:border-success-light {
    border-color: #70e17b;
  }
  .mobile-lg\:hover\:border-success-light:hover {
    border-color: #70e17b;
  }
  .mobile-lg\:border-success {
    border-color: #00a91c;
  }
  .mobile-lg\:hover\:border-success:hover {
    border-color: #00a91c;
  }
  .mobile-lg\:border-success-dark {
    border-color: #008817;
  }
  .mobile-lg\:hover\:border-success-dark:hover {
    border-color: #008817;
  }
  .mobile-lg\:border-success-darker {
    border-color: #216e1f;
  }
  .mobile-lg\:hover\:border-success-darker:hover {
    border-color: #216e1f;
  }
  .mobile-lg\:border-info-lighter {
    border-color: #e7f6f8;
  }
  .mobile-lg\:hover\:border-info-lighter:hover {
    border-color: #e7f6f8;
  }
  .mobile-lg\:border-info-light {
    border-color: #99deea;
  }
  .mobile-lg\:hover\:border-info-light:hover {
    border-color: #99deea;
  }
  .mobile-lg\:border-info {
    border-color: #00bde3;
  }
  .mobile-lg\:hover\:border-info:hover {
    border-color: #00bde3;
  }
  .mobile-lg\:border-info-dark {
    border-color: #009ec1;
  }
  .mobile-lg\:hover\:border-info-dark:hover {
    border-color: #009ec1;
  }
  .mobile-lg\:border-info-darker {
    border-color: #2e6276;
  }
  .mobile-lg\:hover\:border-info-darker:hover {
    border-color: #2e6276;
  }
  .mobile-lg\:border-disabled-lighter {
    border-color: #c9c9c9;
  }
  .mobile-lg\:hover\:border-disabled-lighter:hover {
    border-color: #c9c9c9;
  }
  .mobile-lg\:border-disabled-light {
    border-color: #919191;
  }
  .mobile-lg\:hover\:border-disabled-light:hover {
    border-color: #919191;
  }
  .mobile-lg\:border-disabled {
    border-color: #757575;
  }
  .mobile-lg\:hover\:border-disabled:hover {
    border-color: #757575;
  }
  .mobile-lg\:border-disabled-dark {
    border-color: #454545;
  }
  .mobile-lg\:hover\:border-disabled-dark:hover {
    border-color: #454545;
  }
  .mobile-lg\:border-disabled-darker {
    border-color: #1b1b1b;
  }
  .mobile-lg\:hover\:border-disabled-darker:hover {
    border-color: #1b1b1b;
  }
  .mobile-lg\:border-emergency {
    border-color: #9c3d10;
  }
  .mobile-lg\:hover\:border-emergency:hover {
    border-color: #9c3d10;
  }
  .mobile-lg\:border-emergency-dark {
    border-color: #332d29;
  }
  .mobile-lg\:hover\:border-emergency-dark:hover {
    border-color: #332d29;
  }
  .mobile-lg\:radius-0 {
    border-radius: 0;
  }
  .mobile-lg\:radius-top-0 {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .mobile-lg\:radius-right-0 {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .mobile-lg\:radius-bottom-0 {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .mobile-lg\:radius-left-0 {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .mobile-lg\:radius-sm {
    border-radius: 2px;
  }
  .mobile-lg\:radius-top-sm {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }
  .mobile-lg\:radius-right-sm {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  .mobile-lg\:radius-bottom-sm {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  .mobile-lg\:radius-left-sm {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  .mobile-lg\:radius-md {
    border-radius: 0.25rem;
  }
  .mobile-lg\:radius-top-md {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .mobile-lg\:radius-right-md {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  .mobile-lg\:radius-bottom-md {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  .mobile-lg\:radius-left-md {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .mobile-lg\:radius-lg {
    border-radius: 0.5rem;
  }
  .mobile-lg\:radius-top-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  .mobile-lg\:radius-right-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .mobile-lg\:radius-bottom-lg {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .mobile-lg\:radius-left-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .mobile-lg\:radius-pill {
    border-radius: 99rem;
  }
  .mobile-lg\:radius-top-pill {
    border-top-left-radius: 99rem;
    border-top-right-radius: 99rem;
  }
  .mobile-lg\:radius-right-pill {
    border-top-right-radius: 99rem;
    border-bottom-right-radius: 99rem;
  }
  .mobile-lg\:radius-bottom-pill {
    border-bottom-left-radius: 99rem;
    border-bottom-right-radius: 99rem;
  }
  .mobile-lg\:radius-left-pill {
    border-top-left-radius: 99rem;
    border-bottom-left-radius: 99rem;
  }
  .mobile-lg\:display-block {
    display: block;
  }
  .mobile-lg\:display-flex {
    display: flex;
  }
  .mobile-lg\:display-none {
    display: none;
  }
  .mobile-lg\:display-inline {
    display: inline;
  }
  .mobile-lg\:display-inline-block {
    display: inline-block;
  }
  .mobile-lg\:display-inline-flex {
    display: inline-flex;
  }
  .mobile-lg\:display-table {
    display: table;
  }
  .mobile-lg\:display-table-cell {
    display: table-cell;
  }
  .mobile-lg\:display-table-row {
    display: table-row;
  }
  .mobile-lg\:font-mono-3xs {
    font-size: 0.77rem;
  }
  .mobile-lg\:font-mono-2xs {
    font-size: 0.83rem;
  }
  .mobile-lg\:font-mono-xs {
    font-size: 0.89rem;
  }
  .mobile-lg\:font-mono-sm {
    font-size: 0.95rem;
  }
  .mobile-lg\:font-mono-md {
    font-size: 1.01rem;
  }
  .mobile-lg\:font-mono-lg {
    font-size: 1.31rem;
  }
  .mobile-lg\:font-mono-xl {
    font-size: 1.91rem;
  }
  .mobile-lg\:font-mono-2xl {
    font-size: 2.38rem;
  }
  .mobile-lg\:font-mono-3xl {
    font-size: 2.86rem;
  }
  .mobile-lg\:font-sans-3xs {
    font-size: 0.82rem;
  }
  .mobile-lg\:font-sans-2xs {
    font-size: 0.89rem;
  }
  .mobile-lg\:font-sans-xs {
    font-size: 0.95rem;
  }
  .mobile-lg\:font-sans-sm {
    font-size: 1.01rem;
  }
  .mobile-lg\:font-sans-md {
    font-size: 1.08rem;
  }
  .mobile-lg\:font-sans-lg {
    font-size: 1.39rem;
  }
  .mobile-lg\:font-sans-xl {
    font-size: 2.03rem;
  }
  .mobile-lg\:font-sans-2xl {
    font-size: 2.54rem;
  }
  .mobile-lg\:font-sans-3xl {
    font-size: 3.04rem;
  }
  .mobile-lg\:font-serif-3xs {
    font-size: 0.79rem;
  }
  .mobile-lg\:font-serif-2xs {
    font-size: 0.85rem;
  }
  .mobile-lg\:font-serif-xs {
    font-size: 0.91rem;
  }
  .mobile-lg\:font-serif-sm {
    font-size: 0.98rem;
  }
  .mobile-lg\:font-serif-md {
    font-size: 1.04rem;
  }
  .mobile-lg\:font-serif-lg {
    font-size: 1.34rem;
  }
  .mobile-lg\:font-serif-xl {
    font-size: 1.95rem;
  }
  .mobile-lg\:font-serif-2xl {
    font-size: 2.44rem;
  }
  .mobile-lg\:font-serif-3xl {
    font-size: 2.93rem;
  }
  .mobile-lg\:font-heading-3xs {
    font-size: 0.82rem;
  }
  .mobile-lg\:font-heading-2xs {
    font-size: 0.89rem;
  }
  .mobile-lg\:font-heading-xs {
    font-size: 0.95rem;
  }
  .mobile-lg\:font-heading-sm {
    font-size: 1.01rem;
  }
  .mobile-lg\:font-heading-md {
    font-size: 1.08rem;
  }
  .mobile-lg\:font-heading-lg {
    font-size: 1.39rem;
  }
  .mobile-lg\:font-heading-xl {
    font-size: 2.03rem;
  }
  .mobile-lg\:font-heading-2xl {
    font-size: 2.54rem;
  }
  .mobile-lg\:font-heading-3xl {
    font-size: 3.04rem;
  }
  .mobile-lg\:font-body-3xs {
    font-size: 0.82rem;
  }
  .mobile-lg\:font-body-2xs {
    font-size: 0.89rem;
  }
  .mobile-lg\:font-body-xs {
    font-size: 0.95rem;
  }
  .mobile-lg\:font-body-sm {
    font-size: 1.01rem;
  }
  .mobile-lg\:font-body-md {
    font-size: 1.08rem;
  }
  .mobile-lg\:font-body-lg {
    font-size: 1.39rem;
  }
  .mobile-lg\:font-body-xl {
    font-size: 2.03rem;
  }
  .mobile-lg\:font-body-2xl {
    font-size: 2.54rem;
  }
  .mobile-lg\:font-body-3xl {
    font-size: 3.04rem;
  }
  .mobile-lg\:font-code-3xs {
    font-size: 0.77rem;
  }
  .mobile-lg\:font-code-2xs {
    font-size: 0.83rem;
  }
  .mobile-lg\:font-code-xs {
    font-size: 0.89rem;
  }
  .mobile-lg\:font-code-sm {
    font-size: 0.95rem;
  }
  .mobile-lg\:font-code-md {
    font-size: 1.01rem;
  }
  .mobile-lg\:font-code-lg {
    font-size: 1.31rem;
  }
  .mobile-lg\:font-code-xl {
    font-size: 1.91rem;
  }
  .mobile-lg\:font-code-2xl {
    font-size: 2.38rem;
  }
  .mobile-lg\:font-code-3xl {
    font-size: 2.86rem;
  }
  .mobile-lg\:font-alt-3xs {
    font-size: 0.79rem;
  }
  .mobile-lg\:font-alt-2xs {
    font-size: 0.85rem;
  }
  .mobile-lg\:font-alt-xs {
    font-size: 0.91rem;
  }
  .mobile-lg\:font-alt-sm {
    font-size: 0.98rem;
  }
  .mobile-lg\:font-alt-md {
    font-size: 1.04rem;
  }
  .mobile-lg\:font-alt-lg {
    font-size: 1.34rem;
  }
  .mobile-lg\:font-alt-xl {
    font-size: 1.95rem;
  }
  .mobile-lg\:font-alt-2xl {
    font-size: 2.44rem;
  }
  .mobile-lg\:font-alt-3xl {
    font-size: 2.93rem;
  }
  .mobile-lg\:font-ui-3xs {
    font-size: 0.82rem;
  }
  .mobile-lg\:font-ui-2xs {
    font-size: 0.89rem;
  }
  .mobile-lg\:font-ui-xs {
    font-size: 0.95rem;
  }
  .mobile-lg\:font-ui-sm {
    font-size: 1.01rem;
  }
  .mobile-lg\:font-ui-md {
    font-size: 1.08rem;
  }
  .mobile-lg\:font-ui-lg {
    font-size: 1.39rem;
  }
  .mobile-lg\:font-ui-xl {
    font-size: 2.03rem;
  }
  .mobile-lg\:font-ui-2xl {
    font-size: 2.54rem;
  }
  .mobile-lg\:font-ui-3xl {
    font-size: 3.04rem;
  }
  .mobile-lg\:text-light {
    font-weight: 300;
  }
  .mobile-lg\:text-normal {
    font-weight: normal;
  }
  .mobile-lg\:text-semibold {
    font-weight: 700;
  }
  .mobile-lg\:text-bold {
    font-weight: 700;
  }
  .mobile-lg\:flex-justify-center {
    justify-content: center;
  }
  .mobile-lg\:flex-justify-start {
    justify-content: flex-start;
  }
  .mobile-lg\:flex-justify-end {
    justify-content: flex-end;
  }
  .mobile-lg\:flex-justify {
    justify-content: space-between;
  }
  .mobile-lg\:line-height-sans-1 {
    line-height: 1;
  }
  .mobile-lg\:line-height-sans-2 {
    line-height: 1.2;
  }
  .mobile-lg\:line-height-sans-3 {
    line-height: 1.3;
  }
  .mobile-lg\:line-height-sans-4 {
    line-height: 1.5;
  }
  .mobile-lg\:line-height-sans-5 {
    line-height: 1.6;
  }
  .mobile-lg\:line-height-sans-6 {
    line-height: 1.7;
  }
  .mobile-lg\:line-height-serif-1 {
    line-height: 1;
  }
  .mobile-lg\:line-height-serif-2 {
    line-height: 1.2;
  }
  .mobile-lg\:line-height-serif-3 {
    line-height: 1.4;
  }
  .mobile-lg\:line-height-serif-4 {
    line-height: 1.5;
  }
  .mobile-lg\:line-height-serif-5 {
    line-height: 1.7;
  }
  .mobile-lg\:line-height-serif-6 {
    line-height: 1.8;
  }
  .mobile-lg\:line-height-mono-1 {
    line-height: 1;
  }
  .mobile-lg\:line-height-mono-2 {
    line-height: 1.3;
  }
  .mobile-lg\:line-height-mono-3 {
    line-height: 1.4;
  }
  .mobile-lg\:line-height-mono-4 {
    line-height: 1.6;
  }
  .mobile-lg\:line-height-mono-5 {
    line-height: 1.7;
  }
  .mobile-lg\:line-height-mono-6 {
    line-height: 1.8;
  }
  .mobile-lg\:line-height-heading-1 {
    line-height: 1;
  }
  .mobile-lg\:line-height-heading-2 {
    line-height: 1.2;
  }
  .mobile-lg\:line-height-heading-3 {
    line-height: 1.3;
  }
  .mobile-lg\:line-height-heading-4 {
    line-height: 1.5;
  }
  .mobile-lg\:line-height-heading-5 {
    line-height: 1.6;
  }
  .mobile-lg\:line-height-heading-6 {
    line-height: 1.7;
  }
  .mobile-lg\:line-height-ui-1 {
    line-height: 1;
  }
  .mobile-lg\:line-height-ui-2 {
    line-height: 1.2;
  }
  .mobile-lg\:line-height-ui-3 {
    line-height: 1.3;
  }
  .mobile-lg\:line-height-ui-4 {
    line-height: 1.5;
  }
  .mobile-lg\:line-height-ui-5 {
    line-height: 1.6;
  }
  .mobile-lg\:line-height-ui-6 {
    line-height: 1.7;
  }
  .mobile-lg\:line-height-body-1 {
    line-height: 1;
  }
  .mobile-lg\:line-height-body-2 {
    line-height: 1.2;
  }
  .mobile-lg\:line-height-body-3 {
    line-height: 1.3;
  }
  .mobile-lg\:line-height-body-4 {
    line-height: 1.5;
  }
  .mobile-lg\:line-height-body-5 {
    line-height: 1.6;
  }
  .mobile-lg\:line-height-body-6 {
    line-height: 1.7;
  }
  .mobile-lg\:line-height-code-1 {
    line-height: 1;
  }
  .mobile-lg\:line-height-code-2 {
    line-height: 1.3;
  }
  .mobile-lg\:line-height-code-3 {
    line-height: 1.4;
  }
  .mobile-lg\:line-height-code-4 {
    line-height: 1.6;
  }
  .mobile-lg\:line-height-code-5 {
    line-height: 1.7;
  }
  .mobile-lg\:line-height-code-6 {
    line-height: 1.8;
  }
  .mobile-lg\:line-height-alt-1 {
    line-height: 1;
  }
  .mobile-lg\:line-height-alt-2 {
    line-height: 1.2;
  }
  .mobile-lg\:line-height-alt-3 {
    line-height: 1.4;
  }
  .mobile-lg\:line-height-alt-4 {
    line-height: 1.5;
  }
  .mobile-lg\:line-height-alt-5 {
    line-height: 1.7;
  }
  .mobile-lg\:line-height-alt-6 {
    line-height: 1.8;
  }
  .mobile-lg\:margin-neg-1px {
    margin: -1px;
  }
  .mobile-lg\:margin-neg-2px {
    margin: -2px;
  }
  .mobile-lg\:margin-neg-05 {
    margin: -0.25rem;
  }
  .mobile-lg\:margin-neg-1 {
    margin: -0.5rem;
  }
  .mobile-lg\:margin-neg-105 {
    margin: -0.75rem;
  }
  .mobile-lg\:margin-neg-2 {
    margin: -1rem;
  }
  .mobile-lg\:margin-neg-205 {
    margin: -1.25rem;
  }
  .mobile-lg\:margin-neg-3 {
    margin: -1.5rem;
  }
  .mobile-lg\:margin-1px {
    margin: 1px;
  }
  .mobile-lg\:margin-2px {
    margin: 2px;
  }
  .mobile-lg\:margin-05 {
    margin: 0.25rem;
  }
  .mobile-lg\:margin-1 {
    margin: 0.5rem;
  }
  .mobile-lg\:margin-105 {
    margin: 0.75rem;
  }
  .mobile-lg\:margin-2 {
    margin: 1rem;
  }
  .mobile-lg\:margin-205 {
    margin: 1.25rem;
  }
  .mobile-lg\:margin-3 {
    margin: 1.5rem;
  }
  .mobile-lg\:margin-neg-4 {
    margin: -2rem;
  }
  .mobile-lg\:margin-neg-5 {
    margin: -2.5rem;
  }
  .mobile-lg\:margin-neg-6 {
    margin: -3rem;
  }
  .mobile-lg\:margin-neg-7 {
    margin: -3.5rem;
  }
  .mobile-lg\:margin-neg-8 {
    margin: -4rem;
  }
  .mobile-lg\:margin-neg-9 {
    margin: -4.5rem;
  }
  .mobile-lg\:margin-neg-10 {
    margin: -5rem;
  }
  .mobile-lg\:margin-neg-15 {
    margin: -7.5rem;
  }
  .mobile-lg\:margin-4 {
    margin: 2rem;
  }
  .mobile-lg\:margin-5 {
    margin: 2.5rem;
  }
  .mobile-lg\:margin-6 {
    margin: 3rem;
  }
  .mobile-lg\:margin-7 {
    margin: 3.5rem;
  }
  .mobile-lg\:margin-8 {
    margin: 4rem;
  }
  .mobile-lg\:margin-9 {
    margin: 4.5rem;
  }
  .mobile-lg\:margin-10 {
    margin: 5rem;
  }
  .mobile-lg\:margin-15 {
    margin: 7.5rem;
  }
  .mobile-lg\:margin-05em {
    margin: 0.5em;
  }
  .mobile-lg\:margin-1em {
    margin: 1em;
  }
  .mobile-lg\:margin-105em {
    margin: 1.5em;
  }
  .mobile-lg\:margin-2em {
    margin: 2em;
  }
  .mobile-lg\:margin-0 {
    margin: 0;
  }
  .mobile-lg\:margin-y-1px {
    margin-top: 1px;
    margin-bottom: 1px;
  }
  .mobile-lg\:margin-top-1px {
    margin-top: 1px;
  }
  .mobile-lg\:margin-bottom-1px {
    margin-bottom: 1px;
  }
  .mobile-lg\:margin-y-2px {
    margin-top: 2px;
    margin-bottom: 2px;
  }
  .mobile-lg\:margin-top-2px {
    margin-top: 2px;
  }
  .mobile-lg\:margin-bottom-2px {
    margin-bottom: 2px;
  }
  .mobile-lg\:margin-y-05 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .mobile-lg\:margin-top-05 {
    margin-top: 0.25rem;
  }
  .mobile-lg\:margin-bottom-05 {
    margin-bottom: 0.25rem;
  }
  .mobile-lg\:margin-y-1 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .mobile-lg\:margin-top-1 {
    margin-top: 0.5rem;
  }
  .mobile-lg\:margin-bottom-1 {
    margin-bottom: 0.5rem;
  }
  .mobile-lg\:margin-y-105 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  .mobile-lg\:margin-top-105 {
    margin-top: 0.75rem;
  }
  .mobile-lg\:margin-bottom-105 {
    margin-bottom: 0.75rem;
  }
  .mobile-lg\:margin-y-2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .mobile-lg\:margin-top-2 {
    margin-top: 1rem;
  }
  .mobile-lg\:margin-bottom-2 {
    margin-bottom: 1rem;
  }
  .mobile-lg\:margin-y-205 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .mobile-lg\:margin-top-205 {
    margin-top: 1.25rem;
  }
  .mobile-lg\:margin-bottom-205 {
    margin-bottom: 1.25rem;
  }
  .mobile-lg\:margin-y-3 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .mobile-lg\:margin-top-3 {
    margin-top: 1.5rem;
  }
  .mobile-lg\:margin-bottom-3 {
    margin-bottom: 1.5rem;
  }
  .mobile-lg\:margin-y-neg-1px {
    margin-top: -1px;
    margin-bottom: -1px;
  }
  .mobile-lg\:margin-top-neg-1px {
    margin-top: -1px;
  }
  .mobile-lg\:margin-bottom-neg-1px {
    margin-bottom: -1px;
  }
  .mobile-lg\:margin-y-neg-2px {
    margin-top: -2px;
    margin-bottom: -2px;
  }
  .mobile-lg\:margin-top-neg-2px {
    margin-top: -2px;
  }
  .mobile-lg\:margin-bottom-neg-2px {
    margin-bottom: -2px;
  }
  .mobile-lg\:margin-y-neg-05 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .mobile-lg\:margin-top-neg-05 {
    margin-top: -0.25rem;
  }
  .mobile-lg\:margin-bottom-neg-05 {
    margin-bottom: -0.25rem;
  }
  .mobile-lg\:margin-y-neg-1 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .mobile-lg\:margin-top-neg-1 {
    margin-top: -0.5rem;
  }
  .mobile-lg\:margin-bottom-neg-1 {
    margin-bottom: -0.5rem;
  }
  .mobile-lg\:margin-y-neg-105 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }
  .mobile-lg\:margin-top-neg-105 {
    margin-top: -0.75rem;
  }
  .mobile-lg\:margin-bottom-neg-105 {
    margin-bottom: -0.75rem;
  }
  .mobile-lg\:margin-y-neg-2 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .mobile-lg\:margin-top-neg-2 {
    margin-top: -1rem;
  }
  .mobile-lg\:margin-bottom-neg-2 {
    margin-bottom: -1rem;
  }
  .mobile-lg\:margin-y-neg-205 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }
  .mobile-lg\:margin-top-neg-205 {
    margin-top: -1.25rem;
  }
  .mobile-lg\:margin-bottom-neg-205 {
    margin-bottom: -1.25rem;
  }
  .mobile-lg\:margin-y-neg-3 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .mobile-lg\:margin-top-neg-3 {
    margin-top: -1.5rem;
  }
  .mobile-lg\:margin-bottom-neg-3 {
    margin-bottom: -1.5rem;
  }
  .mobile-lg\:margin-y-neg-4 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }
  .mobile-lg\:margin-top-neg-4 {
    margin-top: -2rem;
  }
  .mobile-lg\:margin-bottom-neg-4 {
    margin-bottom: -2rem;
  }
  .mobile-lg\:margin-y-neg-5 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }
  .mobile-lg\:margin-top-neg-5 {
    margin-top: -2.5rem;
  }
  .mobile-lg\:margin-bottom-neg-5 {
    margin-bottom: -2.5rem;
  }
  .mobile-lg\:margin-y-neg-6 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .mobile-lg\:margin-top-neg-6 {
    margin-top: -3rem;
  }
  .mobile-lg\:margin-bottom-neg-6 {
    margin-bottom: -3rem;
  }
  .mobile-lg\:margin-y-neg-7 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }
  .mobile-lg\:margin-top-neg-7 {
    margin-top: -3.5rem;
  }
  .mobile-lg\:margin-bottom-neg-7 {
    margin-bottom: -3.5rem;
  }
  .mobile-lg\:margin-y-neg-8 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }
  .mobile-lg\:margin-top-neg-8 {
    margin-top: -4rem;
  }
  .mobile-lg\:margin-bottom-neg-8 {
    margin-bottom: -4rem;
  }
  .mobile-lg\:margin-y-neg-9 {
    margin-top: -4.5rem;
    margin-bottom: -4.5rem;
  }
  .mobile-lg\:margin-top-neg-9 {
    margin-top: -4.5rem;
  }
  .mobile-lg\:margin-bottom-neg-9 {
    margin-bottom: -4.5rem;
  }
  .mobile-lg\:margin-y-neg-10 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }
  .mobile-lg\:margin-top-neg-10 {
    margin-top: -5rem;
  }
  .mobile-lg\:margin-bottom-neg-10 {
    margin-bottom: -5rem;
  }
  .mobile-lg\:margin-y-neg-15 {
    margin-top: -7.5rem;
    margin-bottom: -7.5rem;
  }
  .mobile-lg\:margin-top-neg-15 {
    margin-top: -7.5rem;
  }
  .mobile-lg\:margin-bottom-neg-15 {
    margin-bottom: -7.5rem;
  }
  .mobile-lg\:margin-y-4 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .mobile-lg\:margin-top-4 {
    margin-top: 2rem;
  }
  .mobile-lg\:margin-bottom-4 {
    margin-bottom: 2rem;
  }
  .mobile-lg\:margin-y-5 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .mobile-lg\:margin-top-5 {
    margin-top: 2.5rem;
  }
  .mobile-lg\:margin-bottom-5 {
    margin-bottom: 2.5rem;
  }
  .mobile-lg\:margin-y-6 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .mobile-lg\:margin-top-6 {
    margin-top: 3rem;
  }
  .mobile-lg\:margin-bottom-6 {
    margin-bottom: 3rem;
  }
  .mobile-lg\:margin-y-7 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
  .mobile-lg\:margin-top-7 {
    margin-top: 3.5rem;
  }
  .mobile-lg\:margin-bottom-7 {
    margin-bottom: 3.5rem;
  }
  .mobile-lg\:margin-y-8 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .mobile-lg\:margin-top-8 {
    margin-top: 4rem;
  }
  .mobile-lg\:margin-bottom-8 {
    margin-bottom: 4rem;
  }
  .mobile-lg\:margin-y-9 {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }
  .mobile-lg\:margin-top-9 {
    margin-top: 4.5rem;
  }
  .mobile-lg\:margin-bottom-9 {
    margin-bottom: 4.5rem;
  }
  .mobile-lg\:margin-y-10 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .mobile-lg\:margin-top-10 {
    margin-top: 5rem;
  }
  .mobile-lg\:margin-bottom-10 {
    margin-bottom: 5rem;
  }
  .mobile-lg\:margin-y-15 {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem;
  }
  .mobile-lg\:margin-top-15 {
    margin-top: 7.5rem;
  }
  .mobile-lg\:margin-bottom-15 {
    margin-bottom: 7.5rem;
  }
  .mobile-lg\:margin-y-05em {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  .mobile-lg\:margin-top-05em {
    margin-top: 0.5em;
  }
  .mobile-lg\:margin-bottom-05em {
    margin-bottom: 0.5em;
  }
  .mobile-lg\:margin-y-1em {
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .mobile-lg\:margin-top-1em {
    margin-top: 1em;
  }
  .mobile-lg\:margin-bottom-1em {
    margin-bottom: 1em;
  }
  .mobile-lg\:margin-y-105em {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }
  .mobile-lg\:margin-top-105em {
    margin-top: 1.5em;
  }
  .mobile-lg\:margin-bottom-105em {
    margin-bottom: 1.5em;
  }
  .mobile-lg\:margin-y-2em {
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .mobile-lg\:margin-top-2em {
    margin-top: 2em;
  }
  .mobile-lg\:margin-bottom-2em {
    margin-bottom: 2em;
  }
  .mobile-lg\:margin-y-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .mobile-lg\:margin-top-0 {
    margin-top: 0;
  }
  .mobile-lg\:margin-bottom-0 {
    margin-bottom: 0;
  }
  .mobile-lg\:margin-y-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mobile-lg\:margin-top-auto {
    margin-top: auto;
  }
  .mobile-lg\:margin-bottom-auto {
    margin-bottom: auto;
  }
  .mobile-lg\:margin-x-1px {
    margin-left: 1px;
    margin-right: 1px;
  }
  .mobile-lg\:margin-right-1px {
    margin-right: 1px;
  }
  .mobile-lg\:margin-left-1px {
    margin-left: 1px;
  }
  .mobile-lg\:margin-x-2px {
    margin-left: 2px;
    margin-right: 2px;
  }
  .mobile-lg\:margin-right-2px {
    margin-right: 2px;
  }
  .mobile-lg\:margin-left-2px {
    margin-left: 2px;
  }
  .mobile-lg\:margin-x-05 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .mobile-lg\:margin-right-05 {
    margin-right: 0.25rem;
  }
  .mobile-lg\:margin-left-05 {
    margin-left: 0.25rem;
  }
  .mobile-lg\:margin-x-1 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .mobile-lg\:margin-right-1 {
    margin-right: 0.5rem;
  }
  .mobile-lg\:margin-left-1 {
    margin-left: 0.5rem;
  }
  .mobile-lg\:margin-x-105 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
  .mobile-lg\:margin-right-105 {
    margin-right: 0.75rem;
  }
  .mobile-lg\:margin-left-105 {
    margin-left: 0.75rem;
  }
  .mobile-lg\:margin-x-2 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .mobile-lg\:margin-right-2 {
    margin-right: 1rem;
  }
  .mobile-lg\:margin-left-2 {
    margin-left: 1rem;
  }
  .mobile-lg\:margin-x-205 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
  .mobile-lg\:margin-right-205 {
    margin-right: 1.25rem;
  }
  .mobile-lg\:margin-left-205 {
    margin-left: 1.25rem;
  }
  .mobile-lg\:margin-x-3 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .mobile-lg\:margin-right-3 {
    margin-right: 1.5rem;
  }
  .mobile-lg\:margin-left-3 {
    margin-left: 1.5rem;
  }
  .mobile-lg\:margin-x-neg-1px {
    margin-left: -1px;
    margin-right: -1px;
  }
  .mobile-lg\:margin-right-neg-1px {
    margin-right: -1px;
  }
  .mobile-lg\:margin-left-neg-1px {
    margin-left: -1px;
  }
  .mobile-lg\:margin-x-neg-2px {
    margin-left: -2px;
    margin-right: -2px;
  }
  .mobile-lg\:margin-right-neg-2px {
    margin-right: -2px;
  }
  .mobile-lg\:margin-left-neg-2px {
    margin-left: -2px;
  }
  .mobile-lg\:margin-x-neg-05 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .mobile-lg\:margin-right-neg-05 {
    margin-right: -0.25rem;
  }
  .mobile-lg\:margin-left-neg-05 {
    margin-left: -0.25rem;
  }
  .mobile-lg\:margin-x-neg-1 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .mobile-lg\:margin-right-neg-1 {
    margin-right: -0.5rem;
  }
  .mobile-lg\:margin-left-neg-1 {
    margin-left: -0.5rem;
  }
  .mobile-lg\:margin-x-neg-105 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .mobile-lg\:margin-right-neg-105 {
    margin-right: -0.75rem;
  }
  .mobile-lg\:margin-left-neg-105 {
    margin-left: -0.75rem;
  }
  .mobile-lg\:margin-x-neg-2 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .mobile-lg\:margin-right-neg-2 {
    margin-right: -1rem;
  }
  .mobile-lg\:margin-left-neg-2 {
    margin-left: -1rem;
  }
  .mobile-lg\:margin-x-neg-205 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
  .mobile-lg\:margin-right-neg-205 {
    margin-right: -1.25rem;
  }
  .mobile-lg\:margin-left-neg-205 {
    margin-left: -1.25rem;
  }
  .mobile-lg\:margin-x-neg-3 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .mobile-lg\:margin-right-neg-3 {
    margin-right: -1.5rem;
  }
  .mobile-lg\:margin-left-neg-3 {
    margin-left: -1.5rem;
  }
  .mobile-lg\:margin-x-neg-4 {
    margin-left: -2rem;
    margin-right: -2rem;
  }
  .mobile-lg\:margin-right-neg-4 {
    margin-right: -2rem;
  }
  .mobile-lg\:margin-left-neg-4 {
    margin-left: -2rem;
  }
  .mobile-lg\:margin-x-neg-5 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }
  .mobile-lg\:margin-right-neg-5 {
    margin-right: -2.5rem;
  }
  .mobile-lg\:margin-left-neg-5 {
    margin-left: -2.5rem;
  }
  .mobile-lg\:margin-x-neg-6 {
    margin-left: -3rem;
    margin-right: -3rem;
  }
  .mobile-lg\:margin-right-neg-6 {
    margin-right: -3rem;
  }
  .mobile-lg\:margin-left-neg-6 {
    margin-left: -3rem;
  }
  .mobile-lg\:margin-x-neg-7 {
    margin-left: -3.5rem;
    margin-right: -3.5rem;
  }
  .mobile-lg\:margin-right-neg-7 {
    margin-right: -3.5rem;
  }
  .mobile-lg\:margin-left-neg-7 {
    margin-left: -3.5rem;
  }
  .mobile-lg\:margin-x-neg-8 {
    margin-left: -4rem;
    margin-right: -4rem;
  }
  .mobile-lg\:margin-right-neg-8 {
    margin-right: -4rem;
  }
  .mobile-lg\:margin-left-neg-8 {
    margin-left: -4rem;
  }
  .mobile-lg\:margin-x-neg-9 {
    margin-left: -4.5rem;
    margin-right: -4.5rem;
  }
  .mobile-lg\:margin-right-neg-9 {
    margin-right: -4.5rem;
  }
  .mobile-lg\:margin-left-neg-9 {
    margin-left: -4.5rem;
  }
  .mobile-lg\:margin-x-neg-10 {
    margin-left: -5rem;
    margin-right: -5rem;
  }
  .mobile-lg\:margin-right-neg-10 {
    margin-right: -5rem;
  }
  .mobile-lg\:margin-left-neg-10 {
    margin-left: -5rem;
  }
  .mobile-lg\:margin-x-neg-15 {
    margin-left: -7.5rem;
    margin-right: -7.5rem;
  }
  .mobile-lg\:margin-right-neg-15 {
    margin-right: -7.5rem;
  }
  .mobile-lg\:margin-left-neg-15 {
    margin-left: -7.5rem;
  }
  .mobile-lg\:margin-x-4 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .mobile-lg\:margin-right-4 {
    margin-right: 2rem;
  }
  .mobile-lg\:margin-left-4 {
    margin-left: 2rem;
  }
  .mobile-lg\:margin-x-5 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
  .mobile-lg\:margin-right-5 {
    margin-right: 2.5rem;
  }
  .mobile-lg\:margin-left-5 {
    margin-left: 2.5rem;
  }
  .mobile-lg\:margin-x-6 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .mobile-lg\:margin-right-6 {
    margin-right: 3rem;
  }
  .mobile-lg\:margin-left-6 {
    margin-left: 3rem;
  }
  .mobile-lg\:margin-x-7 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }
  .mobile-lg\:margin-right-7 {
    margin-right: 3.5rem;
  }
  .mobile-lg\:margin-left-7 {
    margin-left: 3.5rem;
  }
  .mobile-lg\:margin-x-8 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .mobile-lg\:margin-right-8 {
    margin-right: 4rem;
  }
  .mobile-lg\:margin-left-8 {
    margin-left: 4rem;
  }
  .mobile-lg\:margin-x-9 {
    margin-left: 4.5rem;
    margin-right: 4.5rem;
  }
  .mobile-lg\:margin-right-9 {
    margin-right: 4.5rem;
  }
  .mobile-lg\:margin-left-9 {
    margin-left: 4.5rem;
  }
  .mobile-lg\:margin-x-10 {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .mobile-lg\:margin-right-10 {
    margin-right: 5rem;
  }
  .mobile-lg\:margin-left-10 {
    margin-left: 5rem;
  }
  .mobile-lg\:margin-x-15 {
    margin-left: 7.5rem;
    margin-right: 7.5rem;
  }
  .mobile-lg\:margin-right-15 {
    margin-right: 7.5rem;
  }
  .mobile-lg\:margin-left-15 {
    margin-left: 7.5rem;
  }
  .mobile-lg\:margin-x-card {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  .mobile-lg\:margin-right-card {
    margin-right: 10rem;
  }
  .mobile-lg\:margin-left-card {
    margin-left: 10rem;
  }
  .mobile-lg\:margin-x-card-lg {
    margin-left: 15rem;
    margin-right: 15rem;
  }
  .mobile-lg\:margin-right-card-lg {
    margin-right: 15rem;
  }
  .mobile-lg\:margin-left-card-lg {
    margin-left: 15rem;
  }
  .mobile-lg\:margin-x-mobile {
    margin-left: 20rem;
    margin-right: 20rem;
  }
  .mobile-lg\:margin-right-mobile {
    margin-right: 20rem;
  }
  .mobile-lg\:margin-left-mobile {
    margin-left: 20rem;
  }
  .mobile-lg\:margin-x-05em {
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
  .mobile-lg\:margin-right-05em {
    margin-right: 0.5em;
  }
  .mobile-lg\:margin-left-05em {
    margin-left: 0.5em;
  }
  .mobile-lg\:margin-x-1em {
    margin-left: 1em;
    margin-right: 1em;
  }
  .mobile-lg\:margin-right-1em {
    margin-right: 1em;
  }
  .mobile-lg\:margin-left-1em {
    margin-left: 1em;
  }
  .mobile-lg\:margin-x-105em {
    margin-left: 1.5em;
    margin-right: 1.5em;
  }
  .mobile-lg\:margin-right-105em {
    margin-right: 1.5em;
  }
  .mobile-lg\:margin-left-105em {
    margin-left: 1.5em;
  }
  .mobile-lg\:margin-x-2em {
    margin-left: 2em;
    margin-right: 2em;
  }
  .mobile-lg\:margin-right-2em {
    margin-right: 2em;
  }
  .mobile-lg\:margin-left-2em {
    margin-left: 2em;
  }
  .mobile-lg\:margin-x-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .mobile-lg\:margin-right-0 {
    margin-right: 0;
  }
  .mobile-lg\:margin-left-0 {
    margin-left: 0;
  }
  .mobile-lg\:margin-x-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .mobile-lg\:margin-right-auto {
    margin-right: auto;
  }
  .mobile-lg\:margin-left-auto {
    margin-left: auto;
  }
  .mobile-lg\:measure-1 {
    max-width: 44ex;
  }
  .mobile-lg\:measure-2 {
    max-width: 60ex;
  }
  .mobile-lg\:measure-3 {
    max-width: 64ex;
  }
  .mobile-lg\:measure-4 {
    max-width: 68ex;
  }
  .mobile-lg\:measure-5 {
    max-width: 72ex;
  }
  .mobile-lg\:measure-6 {
    max-width: 88ex;
  }
  .mobile-lg\:measure-none {
    max-width: none;
  }
  .mobile-lg\:order-first {
    order: -1;
  }
  .mobile-lg\:order-last {
    order: 999;
  }
  .mobile-lg\:order-initial {
    order: initial;
  }
  .mobile-lg\:order-0 {
    order: 0;
  }
  .mobile-lg\:order-1 {
    order: 1;
  }
  .mobile-lg\:order-2 {
    order: 2;
  }
  .mobile-lg\:order-3 {
    order: 3;
  }
  .mobile-lg\:order-4 {
    order: 4;
  }
  .mobile-lg\:order-5 {
    order: 5;
  }
  .mobile-lg\:order-6 {
    order: 6;
  }
  .mobile-lg\:order-7 {
    order: 7;
  }
  .mobile-lg\:order-8 {
    order: 8;
  }
  .mobile-lg\:order-9 {
    order: 9;
  }
  .mobile-lg\:order-10 {
    order: 10;
  }
  .mobile-lg\:order-11 {
    order: 11;
  }
  .mobile-lg\:padding-1px {
    padding: 1px;
  }
  .mobile-lg\:padding-y-1px {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .mobile-lg\:padding-x-1px {
    padding-left: 1px;
    padding-right: 1px;
  }
  .mobile-lg\:padding-top-1px {
    padding-top: 1px;
  }
  .mobile-lg\:padding-right-1px {
    padding-right: 1px;
  }
  .mobile-lg\:padding-bottom-1px {
    padding-bottom: 1px;
  }
  .mobile-lg\:padding-left-1px {
    padding-left: 1px;
  }
  .mobile-lg\:padding-2px {
    padding: 2px;
  }
  .mobile-lg\:padding-y-2px {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .mobile-lg\:padding-x-2px {
    padding-left: 2px;
    padding-right: 2px;
  }
  .mobile-lg\:padding-top-2px {
    padding-top: 2px;
  }
  .mobile-lg\:padding-right-2px {
    padding-right: 2px;
  }
  .mobile-lg\:padding-bottom-2px {
    padding-bottom: 2px;
  }
  .mobile-lg\:padding-left-2px {
    padding-left: 2px;
  }
  .mobile-lg\:padding-05 {
    padding: 0.25rem;
  }
  .mobile-lg\:padding-y-05 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .mobile-lg\:padding-x-05 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .mobile-lg\:padding-top-05 {
    padding-top: 0.25rem;
  }
  .mobile-lg\:padding-right-05 {
    padding-right: 0.25rem;
  }
  .mobile-lg\:padding-bottom-05 {
    padding-bottom: 0.25rem;
  }
  .mobile-lg\:padding-left-05 {
    padding-left: 0.25rem;
  }
  .mobile-lg\:padding-1 {
    padding: 0.5rem;
  }
  .mobile-lg\:padding-y-1 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .mobile-lg\:padding-x-1 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .mobile-lg\:padding-top-1 {
    padding-top: 0.5rem;
  }
  .mobile-lg\:padding-right-1 {
    padding-right: 0.5rem;
  }
  .mobile-lg\:padding-bottom-1 {
    padding-bottom: 0.5rem;
  }
  .mobile-lg\:padding-left-1 {
    padding-left: 0.5rem;
  }
  .mobile-lg\:padding-105 {
    padding: 0.75rem;
  }
  .mobile-lg\:padding-y-105 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .mobile-lg\:padding-x-105 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .mobile-lg\:padding-top-105 {
    padding-top: 0.75rem;
  }
  .mobile-lg\:padding-right-105 {
    padding-right: 0.75rem;
  }
  .mobile-lg\:padding-bottom-105 {
    padding-bottom: 0.75rem;
  }
  .mobile-lg\:padding-left-105 {
    padding-left: 0.75rem;
  }
  .mobile-lg\:padding-2 {
    padding: 1rem;
  }
  .mobile-lg\:padding-y-2 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .mobile-lg\:padding-x-2 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .mobile-lg\:padding-top-2 {
    padding-top: 1rem;
  }
  .mobile-lg\:padding-right-2 {
    padding-right: 1rem;
  }
  .mobile-lg\:padding-bottom-2 {
    padding-bottom: 1rem;
  }
  .mobile-lg\:padding-left-2 {
    padding-left: 1rem;
  }
  .mobile-lg\:padding-205 {
    padding: 1.25rem;
  }
  .mobile-lg\:padding-y-205 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .mobile-lg\:padding-x-205 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .mobile-lg\:padding-top-205 {
    padding-top: 1.25rem;
  }
  .mobile-lg\:padding-right-205 {
    padding-right: 1.25rem;
  }
  .mobile-lg\:padding-bottom-205 {
    padding-bottom: 1.25rem;
  }
  .mobile-lg\:padding-left-205 {
    padding-left: 1.25rem;
  }
  .mobile-lg\:padding-3 {
    padding: 1.5rem;
  }
  .mobile-lg\:padding-y-3 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .mobile-lg\:padding-x-3 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .mobile-lg\:padding-top-3 {
    padding-top: 1.5rem;
  }
  .mobile-lg\:padding-right-3 {
    padding-right: 1.5rem;
  }
  .mobile-lg\:padding-bottom-3 {
    padding-bottom: 1.5rem;
  }
  .mobile-lg\:padding-left-3 {
    padding-left: 1.5rem;
  }
  .mobile-lg\:padding-4 {
    padding: 2rem;
  }
  .mobile-lg\:padding-y-4 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .mobile-lg\:padding-x-4 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .mobile-lg\:padding-top-4 {
    padding-top: 2rem;
  }
  .mobile-lg\:padding-right-4 {
    padding-right: 2rem;
  }
  .mobile-lg\:padding-bottom-4 {
    padding-bottom: 2rem;
  }
  .mobile-lg\:padding-left-4 {
    padding-left: 2rem;
  }
  .mobile-lg\:padding-5 {
    padding: 2.5rem;
  }
  .mobile-lg\:padding-y-5 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .mobile-lg\:padding-x-5 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .mobile-lg\:padding-top-5 {
    padding-top: 2.5rem;
  }
  .mobile-lg\:padding-right-5 {
    padding-right: 2.5rem;
  }
  .mobile-lg\:padding-bottom-5 {
    padding-bottom: 2.5rem;
  }
  .mobile-lg\:padding-left-5 {
    padding-left: 2.5rem;
  }
  .mobile-lg\:padding-6 {
    padding: 3rem;
  }
  .mobile-lg\:padding-y-6 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .mobile-lg\:padding-x-6 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .mobile-lg\:padding-top-6 {
    padding-top: 3rem;
  }
  .mobile-lg\:padding-right-6 {
    padding-right: 3rem;
  }
  .mobile-lg\:padding-bottom-6 {
    padding-bottom: 3rem;
  }
  .mobile-lg\:padding-left-6 {
    padding-left: 3rem;
  }
  .mobile-lg\:padding-7 {
    padding: 3.5rem;
  }
  .mobile-lg\:padding-y-7 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .mobile-lg\:padding-x-7 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
  .mobile-lg\:padding-top-7 {
    padding-top: 3.5rem;
  }
  .mobile-lg\:padding-right-7 {
    padding-right: 3.5rem;
  }
  .mobile-lg\:padding-bottom-7 {
    padding-bottom: 3.5rem;
  }
  .mobile-lg\:padding-left-7 {
    padding-left: 3.5rem;
  }
  .mobile-lg\:padding-8 {
    padding: 4rem;
  }
  .mobile-lg\:padding-y-8 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .mobile-lg\:padding-x-8 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .mobile-lg\:padding-top-8 {
    padding-top: 4rem;
  }
  .mobile-lg\:padding-right-8 {
    padding-right: 4rem;
  }
  .mobile-lg\:padding-bottom-8 {
    padding-bottom: 4rem;
  }
  .mobile-lg\:padding-left-8 {
    padding-left: 4rem;
  }
  .mobile-lg\:padding-9 {
    padding: 4.5rem;
  }
  .mobile-lg\:padding-y-9 {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }
  .mobile-lg\:padding-x-9 {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }
  .mobile-lg\:padding-top-9 {
    padding-top: 4.5rem;
  }
  .mobile-lg\:padding-right-9 {
    padding-right: 4.5rem;
  }
  .mobile-lg\:padding-bottom-9 {
    padding-bottom: 4.5rem;
  }
  .mobile-lg\:padding-left-9 {
    padding-left: 4.5rem;
  }
  .mobile-lg\:padding-10 {
    padding: 5rem;
  }
  .mobile-lg\:padding-y-10 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .mobile-lg\:padding-x-10 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .mobile-lg\:padding-top-10 {
    padding-top: 5rem;
  }
  .mobile-lg\:padding-right-10 {
    padding-right: 5rem;
  }
  .mobile-lg\:padding-bottom-10 {
    padding-bottom: 5rem;
  }
  .mobile-lg\:padding-left-10 {
    padding-left: 5rem;
  }
  .mobile-lg\:padding-15 {
    padding: 7.5rem;
  }
  .mobile-lg\:padding-y-15 {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
  .mobile-lg\:padding-x-15 {
    padding-left: 7.5rem;
    padding-right: 7.5rem;
  }
  .mobile-lg\:padding-top-15 {
    padding-top: 7.5rem;
  }
  .mobile-lg\:padding-right-15 {
    padding-right: 7.5rem;
  }
  .mobile-lg\:padding-bottom-15 {
    padding-bottom: 7.5rem;
  }
  .mobile-lg\:padding-left-15 {
    padding-left: 7.5rem;
  }
  .mobile-lg\:padding-0 {
    padding: 0;
  }
  .mobile-lg\:padding-y-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .mobile-lg\:padding-x-0 {
    padding-left: 0;
    padding-right: 0;
  }
  .mobile-lg\:padding-top-0 {
    padding-top: 0;
  }
  .mobile-lg\:padding-right-0 {
    padding-right: 0;
  }
  .mobile-lg\:padding-bottom-0 {
    padding-bottom: 0;
  }
  .mobile-lg\:padding-left-0 {
    padding-left: 0;
  }
  .mobile-lg\:width-1px {
    width: 1px;
  }
  .mobile-lg\:width-2px {
    width: 2px;
  }
  .mobile-lg\:width-05 {
    width: 0.25rem;
  }
  .mobile-lg\:width-1 {
    width: 0.5rem;
  }
  .mobile-lg\:width-105 {
    width: 0.75rem;
  }
  .mobile-lg\:width-2 {
    width: 1rem;
  }
  .mobile-lg\:width-205 {
    width: 1.25rem;
  }
  .mobile-lg\:width-3 {
    width: 1.5rem;
  }
  .mobile-lg\:width-4 {
    width: 2rem;
  }
  .mobile-lg\:width-5 {
    width: 2.5rem;
  }
  .mobile-lg\:width-6 {
    width: 3rem;
  }
  .mobile-lg\:width-7 {
    width: 3.5rem;
  }
  .mobile-lg\:width-8 {
    width: 4rem;
  }
  .mobile-lg\:width-9 {
    width: 4.5rem;
  }
  .mobile-lg\:width-10 {
    width: 5rem;
  }
  .mobile-lg\:width-15 {
    width: 7.5rem;
  }
  .mobile-lg\:width-card {
    width: 10rem;
  }
  .mobile-lg\:width-card-lg {
    width: 15rem;
  }
  .mobile-lg\:width-mobile {
    width: 20rem;
  }
  .mobile-lg\:width-mobile-lg {
    width: 30rem;
  }
  .mobile-lg\:width-tablet {
    width: 40rem;
  }
  .mobile-lg\:width-tablet-lg {
    width: 55rem;
  }
  .mobile-lg\:width-desktop {
    width: 64rem;
  }
  .mobile-lg\:width-desktop-lg {
    width: 75rem;
  }
  .mobile-lg\:width-widescreen {
    width: 87.5rem;
  }
  .mobile-lg\:width-0 {
    width: 0;
  }
  .mobile-lg\:width-full {
    width: 100%;
  }
  .mobile-lg\:width-auto {
    width: auto;
  }
}
@media all and (min-width: 40em) {
  .tablet\:border-1px {
    border: 1px solid;
  }
  .tablet\:hover\:border-1px:hover {
    border: 1px solid;
  }
  .tablet\:border-y-1px {
    border-top: 1px solid;
    border-bottom: 1px solid;
  }
  .tablet\:hover\:border-y-1px:hover {
    border-top: 1px solid;
    border-bottom: 1px solid;
  }
  .tablet\:border-x-1px {
    border-left: 1px solid;
    border-right: 1px solid;
  }
  .tablet\:hover\:border-x-1px:hover {
    border-left: 1px solid;
    border-right: 1px solid;
  }
  .tablet\:border-top-1px {
    border-top: 1px solid;
  }
  .tablet\:hover\:border-top-1px:hover {
    border-top: 1px solid;
  }
  .tablet\:border-right-1px {
    border-right: 1px solid;
  }
  .tablet\:hover\:border-right-1px:hover {
    border-right: 1px solid;
  }
  .tablet\:border-bottom-1px {
    border-bottom: 1px solid;
  }
  .tablet\:hover\:border-bottom-1px:hover {
    border-bottom: 1px solid;
  }
  .tablet\:border-left-1px {
    border-left: 1px solid;
  }
  .tablet\:hover\:border-left-1px:hover {
    border-left: 1px solid;
  }
  .tablet\:border-2px {
    border: 2px solid;
  }
  .tablet\:hover\:border-2px:hover {
    border: 2px solid;
  }
  .tablet\:border-y-2px {
    border-top: 2px solid;
    border-bottom: 2px solid;
  }
  .tablet\:hover\:border-y-2px:hover {
    border-top: 2px solid;
    border-bottom: 2px solid;
  }
  .tablet\:border-x-2px {
    border-left: 2px solid;
    border-right: 2px solid;
  }
  .tablet\:hover\:border-x-2px:hover {
    border-left: 2px solid;
    border-right: 2px solid;
  }
  .tablet\:border-top-2px {
    border-top: 2px solid;
  }
  .tablet\:hover\:border-top-2px:hover {
    border-top: 2px solid;
  }
  .tablet\:border-right-2px {
    border-right: 2px solid;
  }
  .tablet\:hover\:border-right-2px:hover {
    border-right: 2px solid;
  }
  .tablet\:border-bottom-2px {
    border-bottom: 2px solid;
  }
  .tablet\:hover\:border-bottom-2px:hover {
    border-bottom: 2px solid;
  }
  .tablet\:border-left-2px {
    border-left: 2px solid;
  }
  .tablet\:hover\:border-left-2px:hover {
    border-left: 2px solid;
  }
  .tablet\:border-05 {
    border: 0.25rem solid;
  }
  .tablet\:hover\:border-05:hover {
    border: 0.25rem solid;
  }
  .tablet\:border-y-05 {
    border-top: 0.25rem solid;
    border-bottom: 0.25rem solid;
  }
  .tablet\:hover\:border-y-05:hover {
    border-top: 0.25rem solid;
    border-bottom: 0.25rem solid;
  }
  .tablet\:border-x-05 {
    border-left: 0.25rem solid;
    border-right: 0.25rem solid;
  }
  .tablet\:hover\:border-x-05:hover {
    border-left: 0.25rem solid;
    border-right: 0.25rem solid;
  }
  .tablet\:border-top-05 {
    border-top: 0.25rem solid;
  }
  .tablet\:hover\:border-top-05:hover {
    border-top: 0.25rem solid;
  }
  .tablet\:border-right-05 {
    border-right: 0.25rem solid;
  }
  .tablet\:hover\:border-right-05:hover {
    border-right: 0.25rem solid;
  }
  .tablet\:border-bottom-05 {
    border-bottom: 0.25rem solid;
  }
  .tablet\:hover\:border-bottom-05:hover {
    border-bottom: 0.25rem solid;
  }
  .tablet\:border-left-05 {
    border-left: 0.25rem solid;
  }
  .tablet\:hover\:border-left-05:hover {
    border-left: 0.25rem solid;
  }
  .tablet\:border-1 {
    border: 0.5rem solid;
  }
  .tablet\:hover\:border-1:hover {
    border: 0.5rem solid;
  }
  .tablet\:border-y-1 {
    border-top: 0.5rem solid;
    border-bottom: 0.5rem solid;
  }
  .tablet\:hover\:border-y-1:hover {
    border-top: 0.5rem solid;
    border-bottom: 0.5rem solid;
  }
  .tablet\:border-x-1 {
    border-left: 0.5rem solid;
    border-right: 0.5rem solid;
  }
  .tablet\:hover\:border-x-1:hover {
    border-left: 0.5rem solid;
    border-right: 0.5rem solid;
  }
  .tablet\:border-top-1 {
    border-top: 0.5rem solid;
  }
  .tablet\:hover\:border-top-1:hover {
    border-top: 0.5rem solid;
  }
  .tablet\:border-right-1 {
    border-right: 0.5rem solid;
  }
  .tablet\:hover\:border-right-1:hover {
    border-right: 0.5rem solid;
  }
  .tablet\:border-bottom-1 {
    border-bottom: 0.5rem solid;
  }
  .tablet\:hover\:border-bottom-1:hover {
    border-bottom: 0.5rem solid;
  }
  .tablet\:border-left-1 {
    border-left: 0.5rem solid;
  }
  .tablet\:hover\:border-left-1:hover {
    border-left: 0.5rem solid;
  }
  .tablet\:border-105 {
    border: 0.75rem solid;
  }
  .tablet\:hover\:border-105:hover {
    border: 0.75rem solid;
  }
  .tablet\:border-y-105 {
    border-top: 0.75rem solid;
    border-bottom: 0.75rem solid;
  }
  .tablet\:hover\:border-y-105:hover {
    border-top: 0.75rem solid;
    border-bottom: 0.75rem solid;
  }
  .tablet\:border-x-105 {
    border-left: 0.75rem solid;
    border-right: 0.75rem solid;
  }
  .tablet\:hover\:border-x-105:hover {
    border-left: 0.75rem solid;
    border-right: 0.75rem solid;
  }
  .tablet\:border-top-105 {
    border-top: 0.75rem solid;
  }
  .tablet\:hover\:border-top-105:hover {
    border-top: 0.75rem solid;
  }
  .tablet\:border-right-105 {
    border-right: 0.75rem solid;
  }
  .tablet\:hover\:border-right-105:hover {
    border-right: 0.75rem solid;
  }
  .tablet\:border-bottom-105 {
    border-bottom: 0.75rem solid;
  }
  .tablet\:hover\:border-bottom-105:hover {
    border-bottom: 0.75rem solid;
  }
  .tablet\:border-left-105 {
    border-left: 0.75rem solid;
  }
  .tablet\:hover\:border-left-105:hover {
    border-left: 0.75rem solid;
  }
  .tablet\:border-2 {
    border: 1rem solid;
  }
  .tablet\:hover\:border-2:hover {
    border: 1rem solid;
  }
  .tablet\:border-y-2 {
    border-top: 1rem solid;
    border-bottom: 1rem solid;
  }
  .tablet\:hover\:border-y-2:hover {
    border-top: 1rem solid;
    border-bottom: 1rem solid;
  }
  .tablet\:border-x-2 {
    border-left: 1rem solid;
    border-right: 1rem solid;
  }
  .tablet\:hover\:border-x-2:hover {
    border-left: 1rem solid;
    border-right: 1rem solid;
  }
  .tablet\:border-top-2 {
    border-top: 1rem solid;
  }
  .tablet\:hover\:border-top-2:hover {
    border-top: 1rem solid;
  }
  .tablet\:border-right-2 {
    border-right: 1rem solid;
  }
  .tablet\:hover\:border-right-2:hover {
    border-right: 1rem solid;
  }
  .tablet\:border-bottom-2 {
    border-bottom: 1rem solid;
  }
  .tablet\:hover\:border-bottom-2:hover {
    border-bottom: 1rem solid;
  }
  .tablet\:border-left-2 {
    border-left: 1rem solid;
  }
  .tablet\:hover\:border-left-2:hover {
    border-left: 1rem solid;
  }
  .tablet\:border-205 {
    border: 1.25rem solid;
  }
  .tablet\:hover\:border-205:hover {
    border: 1.25rem solid;
  }
  .tablet\:border-y-205 {
    border-top: 1.25rem solid;
    border-bottom: 1.25rem solid;
  }
  .tablet\:hover\:border-y-205:hover {
    border-top: 1.25rem solid;
    border-bottom: 1.25rem solid;
  }
  .tablet\:border-x-205 {
    border-left: 1.25rem solid;
    border-right: 1.25rem solid;
  }
  .tablet\:hover\:border-x-205:hover {
    border-left: 1.25rem solid;
    border-right: 1.25rem solid;
  }
  .tablet\:border-top-205 {
    border-top: 1.25rem solid;
  }
  .tablet\:hover\:border-top-205:hover {
    border-top: 1.25rem solid;
  }
  .tablet\:border-right-205 {
    border-right: 1.25rem solid;
  }
  .tablet\:hover\:border-right-205:hover {
    border-right: 1.25rem solid;
  }
  .tablet\:border-bottom-205 {
    border-bottom: 1.25rem solid;
  }
  .tablet\:hover\:border-bottom-205:hover {
    border-bottom: 1.25rem solid;
  }
  .tablet\:border-left-205 {
    border-left: 1.25rem solid;
  }
  .tablet\:hover\:border-left-205:hover {
    border-left: 1.25rem solid;
  }
  .tablet\:border-3 {
    border: 1.5rem solid;
  }
  .tablet\:hover\:border-3:hover {
    border: 1.5rem solid;
  }
  .tablet\:border-y-3 {
    border-top: 1.5rem solid;
    border-bottom: 1.5rem solid;
  }
  .tablet\:hover\:border-y-3:hover {
    border-top: 1.5rem solid;
    border-bottom: 1.5rem solid;
  }
  .tablet\:border-x-3 {
    border-left: 1.5rem solid;
    border-right: 1.5rem solid;
  }
  .tablet\:hover\:border-x-3:hover {
    border-left: 1.5rem solid;
    border-right: 1.5rem solid;
  }
  .tablet\:border-top-3 {
    border-top: 1.5rem solid;
  }
  .tablet\:hover\:border-top-3:hover {
    border-top: 1.5rem solid;
  }
  .tablet\:border-right-3 {
    border-right: 1.5rem solid;
  }
  .tablet\:hover\:border-right-3:hover {
    border-right: 1.5rem solid;
  }
  .tablet\:border-bottom-3 {
    border-bottom: 1.5rem solid;
  }
  .tablet\:hover\:border-bottom-3:hover {
    border-bottom: 1.5rem solid;
  }
  .tablet\:border-left-3 {
    border-left: 1.5rem solid;
  }
  .tablet\:hover\:border-left-3:hover {
    border-left: 1.5rem solid;
  }
  .tablet\:border-0 {
    border: 0 solid;
  }
  .tablet\:hover\:border-0:hover {
    border: 0 solid;
  }
  .tablet\:border-y-0 {
    border-top: 0 solid;
    border-bottom: 0 solid;
  }
  .tablet\:hover\:border-y-0:hover {
    border-top: 0 solid;
    border-bottom: 0 solid;
  }
  .tablet\:border-x-0 {
    border-left: 0 solid;
    border-right: 0 solid;
  }
  .tablet\:hover\:border-x-0:hover {
    border-left: 0 solid;
    border-right: 0 solid;
  }
  .tablet\:border-top-0 {
    border-top: 0 solid;
  }
  .tablet\:hover\:border-top-0:hover {
    border-top: 0 solid;
  }
  .tablet\:border-right-0 {
    border-right: 0 solid;
  }
  .tablet\:hover\:border-right-0:hover {
    border-right: 0 solid;
  }
  .tablet\:border-bottom-0 {
    border-bottom: 0 solid;
  }
  .tablet\:hover\:border-bottom-0:hover {
    border-bottom: 0 solid;
  }
  .tablet\:border-left-0 {
    border-left: 0 solid;
  }
  .tablet\:hover\:border-left-0:hover {
    border-left: 0 solid;
  }
  .tablet\:border {
    border: 1px solid;
  }
  .tablet\:hover\:border:hover {
    border: 1px solid;
  }
  .tablet\:border-y {
    border-top: 1px solid;
    border-bottom: 1px solid;
  }
  .tablet\:hover\:border-y:hover {
    border-top: 1px solid;
    border-bottom: 1px solid;
  }
  .tablet\:border-x {
    border-left: 1px solid;
    border-right: 1px solid;
  }
  .tablet\:hover\:border-x:hover {
    border-left: 1px solid;
    border-right: 1px solid;
  }
  .tablet\:border-top {
    border-top: 1px solid;
  }
  .tablet\:hover\:border-top:hover {
    border-top: 1px solid;
  }
  .tablet\:border-right {
    border-right: 1px solid;
  }
  .tablet\:hover\:border-right:hover {
    border-right: 1px solid;
  }
  .tablet\:border-bottom {
    border-bottom: 1px solid;
  }
  .tablet\:hover\:border-bottom:hover {
    border-bottom: 1px solid;
  }
  .tablet\:border-left {
    border-left: 1px solid;
  }
  .tablet\:hover\:border-left:hover {
    border-left: 1px solid;
  }
  .tablet\:border-transparent {
    border-color: transparent;
  }
  .tablet\:hover\:border-transparent:hover {
    border-color: transparent;
  }
  .tablet\:border-black {
    border-color: black;
  }
  .tablet\:hover\:border-black:hover {
    border-color: black;
  }
  .tablet\:border-white {
    border-color: white;
  }
  .tablet\:hover\:border-white:hover {
    border-color: white;
  }
  .tablet\:border-red {
    border-color: #e52207;
  }
  .tablet\:hover\:border-red:hover {
    border-color: #e52207;
  }
  .tablet\:border-orange {
    border-color: #e66f0e;
  }
  .tablet\:hover\:border-orange:hover {
    border-color: #e66f0e;
  }
  .tablet\:border-gold {
    border-color: #ffbe2e;
  }
  .tablet\:hover\:border-gold:hover {
    border-color: #ffbe2e;
  }
  .tablet\:border-yellow {
    border-color: #fee685;
  }
  .tablet\:hover\:border-yellow:hover {
    border-color: #fee685;
  }
  .tablet\:border-green {
    border-color: #538200;
  }
  .tablet\:hover\:border-green:hover {
    border-color: #538200;
  }
  .tablet\:border-mint {
    border-color: #04c585;
  }
  .tablet\:hover\:border-mint:hover {
    border-color: #04c585;
  }
  .tablet\:border-cyan {
    border-color: #009ec1;
  }
  .tablet\:hover\:border-cyan:hover {
    border-color: #009ec1;
  }
  .tablet\:border-blue {
    border-color: #0076d6;
  }
  .tablet\:hover\:border-blue:hover {
    border-color: #0076d6;
  }
  .tablet\:border-indigo {
    border-color: #676cc8;
  }
  .tablet\:hover\:border-indigo:hover {
    border-color: #676cc8;
  }
  .tablet\:border-violet {
    border-color: #8168b3;
  }
  .tablet\:hover\:border-violet:hover {
    border-color: #8168b3;
  }
  .tablet\:border-magenta {
    border-color: #d72d79;
  }
  .tablet\:hover\:border-magenta:hover {
    border-color: #d72d79;
  }
  .tablet\:border-gray-5 {
    border-color: #f0f0f0;
  }
  .tablet\:hover\:border-gray-5:hover {
    border-color: #f0f0f0;
  }
  .tablet\:border-gray-10 {
    border-color: #e6e6e6;
  }
  .tablet\:hover\:border-gray-10:hover {
    border-color: #e6e6e6;
  }
  .tablet\:border-gray-30 {
    border-color: #adadad;
  }
  .tablet\:hover\:border-gray-30:hover {
    border-color: #adadad;
  }
  .tablet\:border-gray-50 {
    border-color: #757575;
  }
  .tablet\:hover\:border-gray-50:hover {
    border-color: #757575;
  }
  .tablet\:border-gray-70 {
    border-color: #454545;
  }
  .tablet\:hover\:border-gray-70:hover {
    border-color: #454545;
  }
  .tablet\:border-gray-90 {
    border-color: #1b1b1b;
  }
  .tablet\:hover\:border-gray-90:hover {
    border-color: #1b1b1b;
  }
  .tablet\:border-base-lightest {
    border-color: #f0f0f0;
  }
  .tablet\:hover\:border-base-lightest:hover {
    border-color: #f0f0f0;
  }
  .tablet\:border-base-lighter {
    border-color: #dfe1e2;
  }
  .tablet\:hover\:border-base-lighter:hover {
    border-color: #dfe1e2;
  }
  .tablet\:border-base-light {
    border-color: #a9aeb1;
  }
  .tablet\:hover\:border-base-light:hover {
    border-color: #a9aeb1;
  }
  .tablet\:border-base {
    border-color: #71767a;
  }
  .tablet\:hover\:border-base:hover {
    border-color: #71767a;
  }
  .tablet\:border-base-dark {
    border-color: #565c65;
  }
  .tablet\:hover\:border-base-dark:hover {
    border-color: #565c65;
  }
  .tablet\:border-base-darker {
    border-color: #3d4551;
  }
  .tablet\:hover\:border-base-darker:hover {
    border-color: #3d4551;
  }
  .tablet\:border-base-darkest {
    border-color: #1b1b1b;
  }
  .tablet\:hover\:border-base-darkest:hover {
    border-color: #1b1b1b;
  }
  .tablet\:border-ink {
    border-color: #11181d;
  }
  .tablet\:hover\:border-ink:hover {
    border-color: #11181d;
  }
  .tablet\:border-primary-lightest {
    border-color: #B3EFFF;
  }
  .tablet\:hover\:border-primary-lightest:hover {
    border-color: #B3EFFF;
  }
  .tablet\:border-primary-lighter {
    border-color: #97d4ea;
  }
  .tablet\:hover\:border-primary-lighter:hover {
    border-color: #97d4ea;
  }
  .tablet\:border-primary-light {
    border-color: #28a0cb;
  }
  .tablet\:hover\:border-primary-light:hover {
    border-color: #28a0cb;
  }
  .tablet\:border-primary {
    border-color: #046B99;
  }
  .tablet\:hover\:border-primary:hover {
    border-color: #046B99;
  }
  .tablet\:border-primary-vivid {
    border-color: #0050d8;
  }
  .tablet\:hover\:border-primary-vivid:hover {
    border-color: #0050d8;
  }
  .tablet\:border-primary-dark {
    border-color: #0b4778;
  }
  .tablet\:hover\:border-primary-dark:hover {
    border-color: #0b4778;
  }
  .tablet\:border-primary-darker {
    border-color: #1C304A;
  }
  .tablet\:hover\:border-primary-darker:hover {
    border-color: #1C304A;
  }
  .tablet\:border-primary-darkest {
    border-color: #11181d;
  }
  .tablet\:hover\:border-primary-darkest:hover {
    border-color: #11181d;
  }
  .tablet\:border-secondary-lighter {
    border-color: #f3e1e4;
  }
  .tablet\:hover\:border-secondary-lighter:hover {
    border-color: #f3e1e4;
  }
  .tablet\:border-secondary-light {
    border-color: #f2938c;
  }
  .tablet\:hover\:border-secondary-light:hover {
    border-color: #f2938c;
  }
  .tablet\:border-secondary {
    border-color: #d83933;
  }
  .tablet\:hover\:border-secondary:hover {
    border-color: #d83933;
  }
  .tablet\:border-secondary-vivid {
    border-color: #e41d3d;
  }
  .tablet\:hover\:border-secondary-vivid:hover {
    border-color: #e41d3d;
  }
  .tablet\:border-secondary-dark {
    border-color: #b50909;
  }
  .tablet\:hover\:border-secondary-dark:hover {
    border-color: #b50909;
  }
  .tablet\:border-secondary-darker {
    border-color: #8b0a03;
  }
  .tablet\:hover\:border-secondary-darker:hover {
    border-color: #8b0a03;
  }
  .tablet\:border-accent-warm-darker {
    border-color: #775540;
  }
  .tablet\:hover\:border-accent-warm-darker:hover {
    border-color: #775540;
  }
  .tablet\:border-accent-warm-dark {
    border-color: #c05600;
  }
  .tablet\:hover\:border-accent-warm-dark:hover {
    border-color: #c05600;
  }
  .tablet\:border-accent-warm {
    border-color: #fa9441;
  }
  .tablet\:hover\:border-accent-warm:hover {
    border-color: #fa9441;
  }
  .tablet\:border-accent-warm-light {
    border-color: #ffbc78;
  }
  .tablet\:hover\:border-accent-warm-light:hover {
    border-color: #ffbc78;
  }
  .tablet\:border-accent-warm-lighter {
    border-color: #f2e4d4;
  }
  .tablet\:hover\:border-accent-warm-lighter:hover {
    border-color: #f2e4d4;
  }
  .tablet\:border-accent-cool-darkest {
    border-color: #093b44;
  }
  .tablet\:hover\:border-accent-cool-darkest:hover {
    border-color: #093b44;
  }
  .tablet\:border-accent-cool-darker {
    border-color: #0e4f5c;
  }
  .tablet\:hover\:border-accent-cool-darker:hover {
    border-color: #0e4f5c;
  }
  .tablet\:border-accent-cool-dark {
    border-color: #0081a1;
  }
  .tablet\:hover\:border-accent-cool-dark:hover {
    border-color: #0081a1;
  }
  .tablet\:border-accent-cool {
    border-color: #00CFFF;
  }
  .tablet\:hover\:border-accent-cool:hover {
    border-color: #00CFFF;
  }
  .tablet\:border-accent-cool-light {
    border-color: #52daf2;
  }
  .tablet\:hover\:border-accent-cool-light:hover {
    border-color: #52daf2;
  }
  .tablet\:border-accent-cool-lighter {
    border-color: #a8f2ff;
  }
  .tablet\:hover\:border-accent-cool-lighter:hover {
    border-color: #a8f2ff;
  }
  .tablet\:border-accent-cool-lightest {
    border-color: #eff6fb;
  }
  .tablet\:hover\:border-accent-cool-lightest:hover {
    border-color: #eff6fb;
  }
  .tablet\:border-error-lighter {
    border-color: #fff5ee;
  }
  .tablet\:hover\:border-error-lighter:hover {
    border-color: #fff5ee;
  }
  .tablet\:border-error-light {
    border-color: #f39268;
  }
  .tablet\:hover\:border-error-light:hover {
    border-color: #f39268;
  }
  .tablet\:border-error {
    border-color: #d54309;
  }
  .tablet\:hover\:border-error:hover {
    border-color: #d54309;
  }
  .tablet\:border-error-dark {
    border-color: #b50909;
  }
  .tablet\:hover\:border-error-dark:hover {
    border-color: #b50909;
  }
  .tablet\:border-error-darker {
    border-color: #6f3331;
  }
  .tablet\:hover\:border-error-darker:hover {
    border-color: #6f3331;
  }
  .tablet\:border-warning-lighter {
    border-color: #faf3d1;
  }
  .tablet\:hover\:border-warning-lighter:hover {
    border-color: #faf3d1;
  }
  .tablet\:border-warning-light {
    border-color: #fee685;
  }
  .tablet\:hover\:border-warning-light:hover {
    border-color: #fee685;
  }
  .tablet\:border-warning {
    border-color: #ffbe2e;
  }
  .tablet\:hover\:border-warning:hover {
    border-color: #ffbe2e;
  }
  .tablet\:border-warning-dark {
    border-color: #e5a000;
  }
  .tablet\:hover\:border-warning-dark:hover {
    border-color: #e5a000;
  }
  .tablet\:border-warning-darker {
    border-color: #936f38;
  }
  .tablet\:hover\:border-warning-darker:hover {
    border-color: #936f38;
  }
  .tablet\:border-success-lighter {
    border-color: #ecf3ec;
  }
  .tablet\:hover\:border-success-lighter:hover {
    border-color: #ecf3ec;
  }
  .tablet\:border-success-light {
    border-color: #70e17b;
  }
  .tablet\:hover\:border-success-light:hover {
    border-color: #70e17b;
  }
  .tablet\:border-success {
    border-color: #00a91c;
  }
  .tablet\:hover\:border-success:hover {
    border-color: #00a91c;
  }
  .tablet\:border-success-dark {
    border-color: #008817;
  }
  .tablet\:hover\:border-success-dark:hover {
    border-color: #008817;
  }
  .tablet\:border-success-darker {
    border-color: #216e1f;
  }
  .tablet\:hover\:border-success-darker:hover {
    border-color: #216e1f;
  }
  .tablet\:border-info-lighter {
    border-color: #e7f6f8;
  }
  .tablet\:hover\:border-info-lighter:hover {
    border-color: #e7f6f8;
  }
  .tablet\:border-info-light {
    border-color: #99deea;
  }
  .tablet\:hover\:border-info-light:hover {
    border-color: #99deea;
  }
  .tablet\:border-info {
    border-color: #00bde3;
  }
  .tablet\:hover\:border-info:hover {
    border-color: #00bde3;
  }
  .tablet\:border-info-dark {
    border-color: #009ec1;
  }
  .tablet\:hover\:border-info-dark:hover {
    border-color: #009ec1;
  }
  .tablet\:border-info-darker {
    border-color: #2e6276;
  }
  .tablet\:hover\:border-info-darker:hover {
    border-color: #2e6276;
  }
  .tablet\:border-disabled-lighter {
    border-color: #c9c9c9;
  }
  .tablet\:hover\:border-disabled-lighter:hover {
    border-color: #c9c9c9;
  }
  .tablet\:border-disabled-light {
    border-color: #919191;
  }
  .tablet\:hover\:border-disabled-light:hover {
    border-color: #919191;
  }
  .tablet\:border-disabled {
    border-color: #757575;
  }
  .tablet\:hover\:border-disabled:hover {
    border-color: #757575;
  }
  .tablet\:border-disabled-dark {
    border-color: #454545;
  }
  .tablet\:hover\:border-disabled-dark:hover {
    border-color: #454545;
  }
  .tablet\:border-disabled-darker {
    border-color: #1b1b1b;
  }
  .tablet\:hover\:border-disabled-darker:hover {
    border-color: #1b1b1b;
  }
  .tablet\:border-emergency {
    border-color: #9c3d10;
  }
  .tablet\:hover\:border-emergency:hover {
    border-color: #9c3d10;
  }
  .tablet\:border-emergency-dark {
    border-color: #332d29;
  }
  .tablet\:hover\:border-emergency-dark:hover {
    border-color: #332d29;
  }
  .tablet\:radius-0 {
    border-radius: 0;
  }
  .tablet\:radius-top-0 {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .tablet\:radius-right-0 {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .tablet\:radius-bottom-0 {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .tablet\:radius-left-0 {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .tablet\:radius-sm {
    border-radius: 2px;
  }
  .tablet\:radius-top-sm {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }
  .tablet\:radius-right-sm {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  .tablet\:radius-bottom-sm {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  .tablet\:radius-left-sm {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  .tablet\:radius-md {
    border-radius: 0.25rem;
  }
  .tablet\:radius-top-md {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .tablet\:radius-right-md {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  .tablet\:radius-bottom-md {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  .tablet\:radius-left-md {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .tablet\:radius-lg {
    border-radius: 0.5rem;
  }
  .tablet\:radius-top-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  .tablet\:radius-right-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .tablet\:radius-bottom-lg {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .tablet\:radius-left-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .tablet\:radius-pill {
    border-radius: 99rem;
  }
  .tablet\:radius-top-pill {
    border-top-left-radius: 99rem;
    border-top-right-radius: 99rem;
  }
  .tablet\:radius-right-pill {
    border-top-right-radius: 99rem;
    border-bottom-right-radius: 99rem;
  }
  .tablet\:radius-bottom-pill {
    border-bottom-left-radius: 99rem;
    border-bottom-right-radius: 99rem;
  }
  .tablet\:radius-left-pill {
    border-top-left-radius: 99rem;
    border-bottom-left-radius: 99rem;
  }
  .tablet\:display-block {
    display: block;
  }
  .tablet\:display-flex {
    display: flex;
  }
  .tablet\:display-none {
    display: none;
  }
  .tablet\:display-inline {
    display: inline;
  }
  .tablet\:display-inline-block {
    display: inline-block;
  }
  .tablet\:display-inline-flex {
    display: inline-flex;
  }
  .tablet\:display-table {
    display: table;
  }
  .tablet\:display-table-cell {
    display: table-cell;
  }
  .tablet\:display-table-row {
    display: table-row;
  }
  .tablet\:font-mono-3xs {
    font-size: 0.77rem;
  }
  .tablet\:font-mono-2xs {
    font-size: 0.83rem;
  }
  .tablet\:font-mono-xs {
    font-size: 0.89rem;
  }
  .tablet\:font-mono-sm {
    font-size: 0.95rem;
  }
  .tablet\:font-mono-md {
    font-size: 1.01rem;
  }
  .tablet\:font-mono-lg {
    font-size: 1.31rem;
  }
  .tablet\:font-mono-xl {
    font-size: 1.91rem;
  }
  .tablet\:font-mono-2xl {
    font-size: 2.38rem;
  }
  .tablet\:font-mono-3xl {
    font-size: 2.86rem;
  }
  .tablet\:font-sans-3xs {
    font-size: 0.82rem;
  }
  .tablet\:font-sans-2xs {
    font-size: 0.89rem;
  }
  .tablet\:font-sans-xs {
    font-size: 0.95rem;
  }
  .tablet\:font-sans-sm {
    font-size: 1.01rem;
  }
  .tablet\:font-sans-md {
    font-size: 1.08rem;
  }
  .tablet\:font-sans-lg {
    font-size: 1.39rem;
  }
  .tablet\:font-sans-xl {
    font-size: 2.03rem;
  }
  .tablet\:font-sans-2xl {
    font-size: 2.54rem;
  }
  .tablet\:font-sans-3xl {
    font-size: 3.04rem;
  }
  .tablet\:font-serif-3xs {
    font-size: 0.79rem;
  }
  .tablet\:font-serif-2xs {
    font-size: 0.85rem;
  }
  .tablet\:font-serif-xs {
    font-size: 0.91rem;
  }
  .tablet\:font-serif-sm {
    font-size: 0.98rem;
  }
  .tablet\:font-serif-md {
    font-size: 1.04rem;
  }
  .tablet\:font-serif-lg {
    font-size: 1.34rem;
  }
  .tablet\:font-serif-xl {
    font-size: 1.95rem;
  }
  .tablet\:font-serif-2xl {
    font-size: 2.44rem;
  }
  .tablet\:font-serif-3xl {
    font-size: 2.93rem;
  }
  .tablet\:font-heading-3xs {
    font-size: 0.82rem;
  }
  .tablet\:font-heading-2xs {
    font-size: 0.89rem;
  }
  .tablet\:font-heading-xs {
    font-size: 0.95rem;
  }
  .tablet\:font-heading-sm {
    font-size: 1.01rem;
  }
  .tablet\:font-heading-md {
    font-size: 1.08rem;
  }
  .tablet\:font-heading-lg {
    font-size: 1.39rem;
  }
  .tablet\:font-heading-xl {
    font-size: 2.03rem;
  }
  .tablet\:font-heading-2xl {
    font-size: 2.54rem;
  }
  .tablet\:font-heading-3xl {
    font-size: 3.04rem;
  }
  .tablet\:font-body-3xs {
    font-size: 0.82rem;
  }
  .tablet\:font-body-2xs {
    font-size: 0.89rem;
  }
  .tablet\:font-body-xs {
    font-size: 0.95rem;
  }
  .tablet\:font-body-sm {
    font-size: 1.01rem;
  }
  .tablet\:font-body-md {
    font-size: 1.08rem;
  }
  .tablet\:font-body-lg {
    font-size: 1.39rem;
  }
  .tablet\:font-body-xl {
    font-size: 2.03rem;
  }
  .tablet\:font-body-2xl {
    font-size: 2.54rem;
  }
  .tablet\:font-body-3xl {
    font-size: 3.04rem;
  }
  .tablet\:font-code-3xs {
    font-size: 0.77rem;
  }
  .tablet\:font-code-2xs {
    font-size: 0.83rem;
  }
  .tablet\:font-code-xs {
    font-size: 0.89rem;
  }
  .tablet\:font-code-sm {
    font-size: 0.95rem;
  }
  .tablet\:font-code-md {
    font-size: 1.01rem;
  }
  .tablet\:font-code-lg {
    font-size: 1.31rem;
  }
  .tablet\:font-code-xl {
    font-size: 1.91rem;
  }
  .tablet\:font-code-2xl {
    font-size: 2.38rem;
  }
  .tablet\:font-code-3xl {
    font-size: 2.86rem;
  }
  .tablet\:font-alt-3xs {
    font-size: 0.79rem;
  }
  .tablet\:font-alt-2xs {
    font-size: 0.85rem;
  }
  .tablet\:font-alt-xs {
    font-size: 0.91rem;
  }
  .tablet\:font-alt-sm {
    font-size: 0.98rem;
  }
  .tablet\:font-alt-md {
    font-size: 1.04rem;
  }
  .tablet\:font-alt-lg {
    font-size: 1.34rem;
  }
  .tablet\:font-alt-xl {
    font-size: 1.95rem;
  }
  .tablet\:font-alt-2xl {
    font-size: 2.44rem;
  }
  .tablet\:font-alt-3xl {
    font-size: 2.93rem;
  }
  .tablet\:font-ui-3xs {
    font-size: 0.82rem;
  }
  .tablet\:font-ui-2xs {
    font-size: 0.89rem;
  }
  .tablet\:font-ui-xs {
    font-size: 0.95rem;
  }
  .tablet\:font-ui-sm {
    font-size: 1.01rem;
  }
  .tablet\:font-ui-md {
    font-size: 1.08rem;
  }
  .tablet\:font-ui-lg {
    font-size: 1.39rem;
  }
  .tablet\:font-ui-xl {
    font-size: 2.03rem;
  }
  .tablet\:font-ui-2xl {
    font-size: 2.54rem;
  }
  .tablet\:font-ui-3xl {
    font-size: 3.04rem;
  }
  .tablet\:text-light {
    font-weight: 300;
  }
  .tablet\:text-normal {
    font-weight: normal;
  }
  .tablet\:text-semibold {
    font-weight: 700;
  }
  .tablet\:text-bold {
    font-weight: 700;
  }
  .tablet\:flex-justify-center {
    justify-content: center;
  }
  .tablet\:flex-justify-start {
    justify-content: flex-start;
  }
  .tablet\:flex-justify-end {
    justify-content: flex-end;
  }
  .tablet\:flex-justify {
    justify-content: space-between;
  }
  .tablet\:line-height-sans-1 {
    line-height: 1;
  }
  .tablet\:line-height-sans-2 {
    line-height: 1.2;
  }
  .tablet\:line-height-sans-3 {
    line-height: 1.3;
  }
  .tablet\:line-height-sans-4 {
    line-height: 1.5;
  }
  .tablet\:line-height-sans-5 {
    line-height: 1.6;
  }
  .tablet\:line-height-sans-6 {
    line-height: 1.7;
  }
  .tablet\:line-height-serif-1 {
    line-height: 1;
  }
  .tablet\:line-height-serif-2 {
    line-height: 1.2;
  }
  .tablet\:line-height-serif-3 {
    line-height: 1.4;
  }
  .tablet\:line-height-serif-4 {
    line-height: 1.5;
  }
  .tablet\:line-height-serif-5 {
    line-height: 1.7;
  }
  .tablet\:line-height-serif-6 {
    line-height: 1.8;
  }
  .tablet\:line-height-mono-1 {
    line-height: 1;
  }
  .tablet\:line-height-mono-2 {
    line-height: 1.3;
  }
  .tablet\:line-height-mono-3 {
    line-height: 1.4;
  }
  .tablet\:line-height-mono-4 {
    line-height: 1.6;
  }
  .tablet\:line-height-mono-5 {
    line-height: 1.7;
  }
  .tablet\:line-height-mono-6 {
    line-height: 1.8;
  }
  .tablet\:line-height-heading-1 {
    line-height: 1;
  }
  .tablet\:line-height-heading-2 {
    line-height: 1.2;
  }
  .tablet\:line-height-heading-3 {
    line-height: 1.3;
  }
  .tablet\:line-height-heading-4 {
    line-height: 1.5;
  }
  .tablet\:line-height-heading-5 {
    line-height: 1.6;
  }
  .tablet\:line-height-heading-6 {
    line-height: 1.7;
  }
  .tablet\:line-height-ui-1 {
    line-height: 1;
  }
  .tablet\:line-height-ui-2 {
    line-height: 1.2;
  }
  .tablet\:line-height-ui-3 {
    line-height: 1.3;
  }
  .tablet\:line-height-ui-4 {
    line-height: 1.5;
  }
  .tablet\:line-height-ui-5 {
    line-height: 1.6;
  }
  .tablet\:line-height-ui-6 {
    line-height: 1.7;
  }
  .tablet\:line-height-body-1 {
    line-height: 1;
  }
  .tablet\:line-height-body-2 {
    line-height: 1.2;
  }
  .tablet\:line-height-body-3 {
    line-height: 1.3;
  }
  .tablet\:line-height-body-4 {
    line-height: 1.5;
  }
  .tablet\:line-height-body-5 {
    line-height: 1.6;
  }
  .tablet\:line-height-body-6 {
    line-height: 1.7;
  }
  .tablet\:line-height-code-1 {
    line-height: 1;
  }
  .tablet\:line-height-code-2 {
    line-height: 1.3;
  }
  .tablet\:line-height-code-3 {
    line-height: 1.4;
  }
  .tablet\:line-height-code-4 {
    line-height: 1.6;
  }
  .tablet\:line-height-code-5 {
    line-height: 1.7;
  }
  .tablet\:line-height-code-6 {
    line-height: 1.8;
  }
  .tablet\:line-height-alt-1 {
    line-height: 1;
  }
  .tablet\:line-height-alt-2 {
    line-height: 1.2;
  }
  .tablet\:line-height-alt-3 {
    line-height: 1.4;
  }
  .tablet\:line-height-alt-4 {
    line-height: 1.5;
  }
  .tablet\:line-height-alt-5 {
    line-height: 1.7;
  }
  .tablet\:line-height-alt-6 {
    line-height: 1.8;
  }
  .tablet\:margin-neg-1px {
    margin: -1px;
  }
  .tablet\:margin-neg-2px {
    margin: -2px;
  }
  .tablet\:margin-neg-05 {
    margin: -0.25rem;
  }
  .tablet\:margin-neg-1 {
    margin: -0.5rem;
  }
  .tablet\:margin-neg-105 {
    margin: -0.75rem;
  }
  .tablet\:margin-neg-2 {
    margin: -1rem;
  }
  .tablet\:margin-neg-205 {
    margin: -1.25rem;
  }
  .tablet\:margin-neg-3 {
    margin: -1.5rem;
  }
  .tablet\:margin-1px {
    margin: 1px;
  }
  .tablet\:margin-2px {
    margin: 2px;
  }
  .tablet\:margin-05 {
    margin: 0.25rem;
  }
  .tablet\:margin-1 {
    margin: 0.5rem;
  }
  .tablet\:margin-105 {
    margin: 0.75rem;
  }
  .tablet\:margin-2 {
    margin: 1rem;
  }
  .tablet\:margin-205 {
    margin: 1.25rem;
  }
  .tablet\:margin-3 {
    margin: 1.5rem;
  }
  .tablet\:margin-neg-4 {
    margin: -2rem;
  }
  .tablet\:margin-neg-5 {
    margin: -2.5rem;
  }
  .tablet\:margin-neg-6 {
    margin: -3rem;
  }
  .tablet\:margin-neg-7 {
    margin: -3.5rem;
  }
  .tablet\:margin-neg-8 {
    margin: -4rem;
  }
  .tablet\:margin-neg-9 {
    margin: -4.5rem;
  }
  .tablet\:margin-neg-10 {
    margin: -5rem;
  }
  .tablet\:margin-neg-15 {
    margin: -7.5rem;
  }
  .tablet\:margin-4 {
    margin: 2rem;
  }
  .tablet\:margin-5 {
    margin: 2.5rem;
  }
  .tablet\:margin-6 {
    margin: 3rem;
  }
  .tablet\:margin-7 {
    margin: 3.5rem;
  }
  .tablet\:margin-8 {
    margin: 4rem;
  }
  .tablet\:margin-9 {
    margin: 4.5rem;
  }
  .tablet\:margin-10 {
    margin: 5rem;
  }
  .tablet\:margin-15 {
    margin: 7.5rem;
  }
  .tablet\:margin-05em {
    margin: 0.5em;
  }
  .tablet\:margin-1em {
    margin: 1em;
  }
  .tablet\:margin-105em {
    margin: 1.5em;
  }
  .tablet\:margin-2em {
    margin: 2em;
  }
  .tablet\:margin-0 {
    margin: 0;
  }
  .tablet\:margin-y-1px {
    margin-top: 1px;
    margin-bottom: 1px;
  }
  .tablet\:margin-top-1px {
    margin-top: 1px;
  }
  .tablet\:margin-bottom-1px {
    margin-bottom: 1px;
  }
  .tablet\:margin-y-2px {
    margin-top: 2px;
    margin-bottom: 2px;
  }
  .tablet\:margin-top-2px {
    margin-top: 2px;
  }
  .tablet\:margin-bottom-2px {
    margin-bottom: 2px;
  }
  .tablet\:margin-y-05 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .tablet\:margin-top-05 {
    margin-top: 0.25rem;
  }
  .tablet\:margin-bottom-05 {
    margin-bottom: 0.25rem;
  }
  .tablet\:margin-y-1 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .tablet\:margin-top-1 {
    margin-top: 0.5rem;
  }
  .tablet\:margin-bottom-1 {
    margin-bottom: 0.5rem;
  }
  .tablet\:margin-y-105 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  .tablet\:margin-top-105 {
    margin-top: 0.75rem;
  }
  .tablet\:margin-bottom-105 {
    margin-bottom: 0.75rem;
  }
  .tablet\:margin-y-2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .tablet\:margin-top-2 {
    margin-top: 1rem;
  }
  .tablet\:margin-bottom-2 {
    margin-bottom: 1rem;
  }
  .tablet\:margin-y-205 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .tablet\:margin-top-205 {
    margin-top: 1.25rem;
  }
  .tablet\:margin-bottom-205 {
    margin-bottom: 1.25rem;
  }
  .tablet\:margin-y-3 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .tablet\:margin-top-3 {
    margin-top: 1.5rem;
  }
  .tablet\:margin-bottom-3 {
    margin-bottom: 1.5rem;
  }
  .tablet\:margin-y-neg-1px {
    margin-top: -1px;
    margin-bottom: -1px;
  }
  .tablet\:margin-top-neg-1px {
    margin-top: -1px;
  }
  .tablet\:margin-bottom-neg-1px {
    margin-bottom: -1px;
  }
  .tablet\:margin-y-neg-2px {
    margin-top: -2px;
    margin-bottom: -2px;
  }
  .tablet\:margin-top-neg-2px {
    margin-top: -2px;
  }
  .tablet\:margin-bottom-neg-2px {
    margin-bottom: -2px;
  }
  .tablet\:margin-y-neg-05 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .tablet\:margin-top-neg-05 {
    margin-top: -0.25rem;
  }
  .tablet\:margin-bottom-neg-05 {
    margin-bottom: -0.25rem;
  }
  .tablet\:margin-y-neg-1 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .tablet\:margin-top-neg-1 {
    margin-top: -0.5rem;
  }
  .tablet\:margin-bottom-neg-1 {
    margin-bottom: -0.5rem;
  }
  .tablet\:margin-y-neg-105 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }
  .tablet\:margin-top-neg-105 {
    margin-top: -0.75rem;
  }
  .tablet\:margin-bottom-neg-105 {
    margin-bottom: -0.75rem;
  }
  .tablet\:margin-y-neg-2 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .tablet\:margin-top-neg-2 {
    margin-top: -1rem;
  }
  .tablet\:margin-bottom-neg-2 {
    margin-bottom: -1rem;
  }
  .tablet\:margin-y-neg-205 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }
  .tablet\:margin-top-neg-205 {
    margin-top: -1.25rem;
  }
  .tablet\:margin-bottom-neg-205 {
    margin-bottom: -1.25rem;
  }
  .tablet\:margin-y-neg-3 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .tablet\:margin-top-neg-3 {
    margin-top: -1.5rem;
  }
  .tablet\:margin-bottom-neg-3 {
    margin-bottom: -1.5rem;
  }
  .tablet\:margin-y-neg-4 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }
  .tablet\:margin-top-neg-4 {
    margin-top: -2rem;
  }
  .tablet\:margin-bottom-neg-4 {
    margin-bottom: -2rem;
  }
  .tablet\:margin-y-neg-5 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }
  .tablet\:margin-top-neg-5 {
    margin-top: -2.5rem;
  }
  .tablet\:margin-bottom-neg-5 {
    margin-bottom: -2.5rem;
  }
  .tablet\:margin-y-neg-6 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .tablet\:margin-top-neg-6 {
    margin-top: -3rem;
  }
  .tablet\:margin-bottom-neg-6 {
    margin-bottom: -3rem;
  }
  .tablet\:margin-y-neg-7 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }
  .tablet\:margin-top-neg-7 {
    margin-top: -3.5rem;
  }
  .tablet\:margin-bottom-neg-7 {
    margin-bottom: -3.5rem;
  }
  .tablet\:margin-y-neg-8 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }
  .tablet\:margin-top-neg-8 {
    margin-top: -4rem;
  }
  .tablet\:margin-bottom-neg-8 {
    margin-bottom: -4rem;
  }
  .tablet\:margin-y-neg-9 {
    margin-top: -4.5rem;
    margin-bottom: -4.5rem;
  }
  .tablet\:margin-top-neg-9 {
    margin-top: -4.5rem;
  }
  .tablet\:margin-bottom-neg-9 {
    margin-bottom: -4.5rem;
  }
  .tablet\:margin-y-neg-10 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }
  .tablet\:margin-top-neg-10 {
    margin-top: -5rem;
  }
  .tablet\:margin-bottom-neg-10 {
    margin-bottom: -5rem;
  }
  .tablet\:margin-y-neg-15 {
    margin-top: -7.5rem;
    margin-bottom: -7.5rem;
  }
  .tablet\:margin-top-neg-15 {
    margin-top: -7.5rem;
  }
  .tablet\:margin-bottom-neg-15 {
    margin-bottom: -7.5rem;
  }
  .tablet\:margin-y-4 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .tablet\:margin-top-4 {
    margin-top: 2rem;
  }
  .tablet\:margin-bottom-4 {
    margin-bottom: 2rem;
  }
  .tablet\:margin-y-5 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .tablet\:margin-top-5 {
    margin-top: 2.5rem;
  }
  .tablet\:margin-bottom-5 {
    margin-bottom: 2.5rem;
  }
  .tablet\:margin-y-6 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .tablet\:margin-top-6 {
    margin-top: 3rem;
  }
  .tablet\:margin-bottom-6 {
    margin-bottom: 3rem;
  }
  .tablet\:margin-y-7 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
  .tablet\:margin-top-7 {
    margin-top: 3.5rem;
  }
  .tablet\:margin-bottom-7 {
    margin-bottom: 3.5rem;
  }
  .tablet\:margin-y-8 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .tablet\:margin-top-8 {
    margin-top: 4rem;
  }
  .tablet\:margin-bottom-8 {
    margin-bottom: 4rem;
  }
  .tablet\:margin-y-9 {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }
  .tablet\:margin-top-9 {
    margin-top: 4.5rem;
  }
  .tablet\:margin-bottom-9 {
    margin-bottom: 4.5rem;
  }
  .tablet\:margin-y-10 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .tablet\:margin-top-10 {
    margin-top: 5rem;
  }
  .tablet\:margin-bottom-10 {
    margin-bottom: 5rem;
  }
  .tablet\:margin-y-15 {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem;
  }
  .tablet\:margin-top-15 {
    margin-top: 7.5rem;
  }
  .tablet\:margin-bottom-15 {
    margin-bottom: 7.5rem;
  }
  .tablet\:margin-y-05em {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  .tablet\:margin-top-05em {
    margin-top: 0.5em;
  }
  .tablet\:margin-bottom-05em {
    margin-bottom: 0.5em;
  }
  .tablet\:margin-y-1em {
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .tablet\:margin-top-1em {
    margin-top: 1em;
  }
  .tablet\:margin-bottom-1em {
    margin-bottom: 1em;
  }
  .tablet\:margin-y-105em {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }
  .tablet\:margin-top-105em {
    margin-top: 1.5em;
  }
  .tablet\:margin-bottom-105em {
    margin-bottom: 1.5em;
  }
  .tablet\:margin-y-2em {
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .tablet\:margin-top-2em {
    margin-top: 2em;
  }
  .tablet\:margin-bottom-2em {
    margin-bottom: 2em;
  }
  .tablet\:margin-y-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .tablet\:margin-top-0 {
    margin-top: 0;
  }
  .tablet\:margin-bottom-0 {
    margin-bottom: 0;
  }
  .tablet\:margin-y-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .tablet\:margin-top-auto {
    margin-top: auto;
  }
  .tablet\:margin-bottom-auto {
    margin-bottom: auto;
  }
  .tablet\:margin-x-1px {
    margin-left: 1px;
    margin-right: 1px;
  }
  .tablet\:margin-right-1px {
    margin-right: 1px;
  }
  .tablet\:margin-left-1px {
    margin-left: 1px;
  }
  .tablet\:margin-x-2px {
    margin-left: 2px;
    margin-right: 2px;
  }
  .tablet\:margin-right-2px {
    margin-right: 2px;
  }
  .tablet\:margin-left-2px {
    margin-left: 2px;
  }
  .tablet\:margin-x-05 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .tablet\:margin-right-05 {
    margin-right: 0.25rem;
  }
  .tablet\:margin-left-05 {
    margin-left: 0.25rem;
  }
  .tablet\:margin-x-1 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .tablet\:margin-right-1 {
    margin-right: 0.5rem;
  }
  .tablet\:margin-left-1 {
    margin-left: 0.5rem;
  }
  .tablet\:margin-x-105 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
  .tablet\:margin-right-105 {
    margin-right: 0.75rem;
  }
  .tablet\:margin-left-105 {
    margin-left: 0.75rem;
  }
  .tablet\:margin-x-2 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .tablet\:margin-right-2 {
    margin-right: 1rem;
  }
  .tablet\:margin-left-2 {
    margin-left: 1rem;
  }
  .tablet\:margin-x-205 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
  .tablet\:margin-right-205 {
    margin-right: 1.25rem;
  }
  .tablet\:margin-left-205 {
    margin-left: 1.25rem;
  }
  .tablet\:margin-x-3 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .tablet\:margin-right-3 {
    margin-right: 1.5rem;
  }
  .tablet\:margin-left-3 {
    margin-left: 1.5rem;
  }
  .tablet\:margin-x-neg-1px {
    margin-left: -1px;
    margin-right: -1px;
  }
  .tablet\:margin-right-neg-1px {
    margin-right: -1px;
  }
  .tablet\:margin-left-neg-1px {
    margin-left: -1px;
  }
  .tablet\:margin-x-neg-2px {
    margin-left: -2px;
    margin-right: -2px;
  }
  .tablet\:margin-right-neg-2px {
    margin-right: -2px;
  }
  .tablet\:margin-left-neg-2px {
    margin-left: -2px;
  }
  .tablet\:margin-x-neg-05 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .tablet\:margin-right-neg-05 {
    margin-right: -0.25rem;
  }
  .tablet\:margin-left-neg-05 {
    margin-left: -0.25rem;
  }
  .tablet\:margin-x-neg-1 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .tablet\:margin-right-neg-1 {
    margin-right: -0.5rem;
  }
  .tablet\:margin-left-neg-1 {
    margin-left: -0.5rem;
  }
  .tablet\:margin-x-neg-105 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .tablet\:margin-right-neg-105 {
    margin-right: -0.75rem;
  }
  .tablet\:margin-left-neg-105 {
    margin-left: -0.75rem;
  }
  .tablet\:margin-x-neg-2 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .tablet\:margin-right-neg-2 {
    margin-right: -1rem;
  }
  .tablet\:margin-left-neg-2 {
    margin-left: -1rem;
  }
  .tablet\:margin-x-neg-205 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
  .tablet\:margin-right-neg-205 {
    margin-right: -1.25rem;
  }
  .tablet\:margin-left-neg-205 {
    margin-left: -1.25rem;
  }
  .tablet\:margin-x-neg-3 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .tablet\:margin-right-neg-3 {
    margin-right: -1.5rem;
  }
  .tablet\:margin-left-neg-3 {
    margin-left: -1.5rem;
  }
  .tablet\:margin-x-neg-4 {
    margin-left: -2rem;
    margin-right: -2rem;
  }
  .tablet\:margin-right-neg-4 {
    margin-right: -2rem;
  }
  .tablet\:margin-left-neg-4 {
    margin-left: -2rem;
  }
  .tablet\:margin-x-neg-5 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }
  .tablet\:margin-right-neg-5 {
    margin-right: -2.5rem;
  }
  .tablet\:margin-left-neg-5 {
    margin-left: -2.5rem;
  }
  .tablet\:margin-x-neg-6 {
    margin-left: -3rem;
    margin-right: -3rem;
  }
  .tablet\:margin-right-neg-6 {
    margin-right: -3rem;
  }
  .tablet\:margin-left-neg-6 {
    margin-left: -3rem;
  }
  .tablet\:margin-x-neg-7 {
    margin-left: -3.5rem;
    margin-right: -3.5rem;
  }
  .tablet\:margin-right-neg-7 {
    margin-right: -3.5rem;
  }
  .tablet\:margin-left-neg-7 {
    margin-left: -3.5rem;
  }
  .tablet\:margin-x-neg-8 {
    margin-left: -4rem;
    margin-right: -4rem;
  }
  .tablet\:margin-right-neg-8 {
    margin-right: -4rem;
  }
  .tablet\:margin-left-neg-8 {
    margin-left: -4rem;
  }
  .tablet\:margin-x-neg-9 {
    margin-left: -4.5rem;
    margin-right: -4.5rem;
  }
  .tablet\:margin-right-neg-9 {
    margin-right: -4.5rem;
  }
  .tablet\:margin-left-neg-9 {
    margin-left: -4.5rem;
  }
  .tablet\:margin-x-neg-10 {
    margin-left: -5rem;
    margin-right: -5rem;
  }
  .tablet\:margin-right-neg-10 {
    margin-right: -5rem;
  }
  .tablet\:margin-left-neg-10 {
    margin-left: -5rem;
  }
  .tablet\:margin-x-neg-15 {
    margin-left: -7.5rem;
    margin-right: -7.5rem;
  }
  .tablet\:margin-right-neg-15 {
    margin-right: -7.5rem;
  }
  .tablet\:margin-left-neg-15 {
    margin-left: -7.5rem;
  }
  .tablet\:margin-x-4 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .tablet\:margin-right-4 {
    margin-right: 2rem;
  }
  .tablet\:margin-left-4 {
    margin-left: 2rem;
  }
  .tablet\:margin-x-5 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
  .tablet\:margin-right-5 {
    margin-right: 2.5rem;
  }
  .tablet\:margin-left-5 {
    margin-left: 2.5rem;
  }
  .tablet\:margin-x-6 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .tablet\:margin-right-6 {
    margin-right: 3rem;
  }
  .tablet\:margin-left-6 {
    margin-left: 3rem;
  }
  .tablet\:margin-x-7 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }
  .tablet\:margin-right-7 {
    margin-right: 3.5rem;
  }
  .tablet\:margin-left-7 {
    margin-left: 3.5rem;
  }
  .tablet\:margin-x-8 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .tablet\:margin-right-8 {
    margin-right: 4rem;
  }
  .tablet\:margin-left-8 {
    margin-left: 4rem;
  }
  .tablet\:margin-x-9 {
    margin-left: 4.5rem;
    margin-right: 4.5rem;
  }
  .tablet\:margin-right-9 {
    margin-right: 4.5rem;
  }
  .tablet\:margin-left-9 {
    margin-left: 4.5rem;
  }
  .tablet\:margin-x-10 {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .tablet\:margin-right-10 {
    margin-right: 5rem;
  }
  .tablet\:margin-left-10 {
    margin-left: 5rem;
  }
  .tablet\:margin-x-15 {
    margin-left: 7.5rem;
    margin-right: 7.5rem;
  }
  .tablet\:margin-right-15 {
    margin-right: 7.5rem;
  }
  .tablet\:margin-left-15 {
    margin-left: 7.5rem;
  }
  .tablet\:margin-x-card {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  .tablet\:margin-right-card {
    margin-right: 10rem;
  }
  .tablet\:margin-left-card {
    margin-left: 10rem;
  }
  .tablet\:margin-x-card-lg {
    margin-left: 15rem;
    margin-right: 15rem;
  }
  .tablet\:margin-right-card-lg {
    margin-right: 15rem;
  }
  .tablet\:margin-left-card-lg {
    margin-left: 15rem;
  }
  .tablet\:margin-x-mobile {
    margin-left: 20rem;
    margin-right: 20rem;
  }
  .tablet\:margin-right-mobile {
    margin-right: 20rem;
  }
  .tablet\:margin-left-mobile {
    margin-left: 20rem;
  }
  .tablet\:margin-x-05em {
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
  .tablet\:margin-right-05em {
    margin-right: 0.5em;
  }
  .tablet\:margin-left-05em {
    margin-left: 0.5em;
  }
  .tablet\:margin-x-1em {
    margin-left: 1em;
    margin-right: 1em;
  }
  .tablet\:margin-right-1em {
    margin-right: 1em;
  }
  .tablet\:margin-left-1em {
    margin-left: 1em;
  }
  .tablet\:margin-x-105em {
    margin-left: 1.5em;
    margin-right: 1.5em;
  }
  .tablet\:margin-right-105em {
    margin-right: 1.5em;
  }
  .tablet\:margin-left-105em {
    margin-left: 1.5em;
  }
  .tablet\:margin-x-2em {
    margin-left: 2em;
    margin-right: 2em;
  }
  .tablet\:margin-right-2em {
    margin-right: 2em;
  }
  .tablet\:margin-left-2em {
    margin-left: 2em;
  }
  .tablet\:margin-x-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .tablet\:margin-right-0 {
    margin-right: 0;
  }
  .tablet\:margin-left-0 {
    margin-left: 0;
  }
  .tablet\:margin-x-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .tablet\:margin-right-auto {
    margin-right: auto;
  }
  .tablet\:margin-left-auto {
    margin-left: auto;
  }
  .tablet\:measure-1 {
    max-width: 44ex;
  }
  .tablet\:measure-2 {
    max-width: 60ex;
  }
  .tablet\:measure-3 {
    max-width: 64ex;
  }
  .tablet\:measure-4 {
    max-width: 68ex;
  }
  .tablet\:measure-5 {
    max-width: 72ex;
  }
  .tablet\:measure-6 {
    max-width: 88ex;
  }
  .tablet\:measure-none {
    max-width: none;
  }
  .tablet\:order-first {
    order: -1;
  }
  .tablet\:order-last {
    order: 999;
  }
  .tablet\:order-initial {
    order: initial;
  }
  .tablet\:order-0 {
    order: 0;
  }
  .tablet\:order-1 {
    order: 1;
  }
  .tablet\:order-2 {
    order: 2;
  }
  .tablet\:order-3 {
    order: 3;
  }
  .tablet\:order-4 {
    order: 4;
  }
  .tablet\:order-5 {
    order: 5;
  }
  .tablet\:order-6 {
    order: 6;
  }
  .tablet\:order-7 {
    order: 7;
  }
  .tablet\:order-8 {
    order: 8;
  }
  .tablet\:order-9 {
    order: 9;
  }
  .tablet\:order-10 {
    order: 10;
  }
  .tablet\:order-11 {
    order: 11;
  }
  .tablet\:padding-1px {
    padding: 1px;
  }
  .tablet\:padding-y-1px {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .tablet\:padding-x-1px {
    padding-left: 1px;
    padding-right: 1px;
  }
  .tablet\:padding-top-1px {
    padding-top: 1px;
  }
  .tablet\:padding-right-1px {
    padding-right: 1px;
  }
  .tablet\:padding-bottom-1px {
    padding-bottom: 1px;
  }
  .tablet\:padding-left-1px {
    padding-left: 1px;
  }
  .tablet\:padding-2px {
    padding: 2px;
  }
  .tablet\:padding-y-2px {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .tablet\:padding-x-2px {
    padding-left: 2px;
    padding-right: 2px;
  }
  .tablet\:padding-top-2px {
    padding-top: 2px;
  }
  .tablet\:padding-right-2px {
    padding-right: 2px;
  }
  .tablet\:padding-bottom-2px {
    padding-bottom: 2px;
  }
  .tablet\:padding-left-2px {
    padding-left: 2px;
  }
  .tablet\:padding-05 {
    padding: 0.25rem;
  }
  .tablet\:padding-y-05 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .tablet\:padding-x-05 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .tablet\:padding-top-05 {
    padding-top: 0.25rem;
  }
  .tablet\:padding-right-05 {
    padding-right: 0.25rem;
  }
  .tablet\:padding-bottom-05 {
    padding-bottom: 0.25rem;
  }
  .tablet\:padding-left-05 {
    padding-left: 0.25rem;
  }
  .tablet\:padding-1 {
    padding: 0.5rem;
  }
  .tablet\:padding-y-1 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .tablet\:padding-x-1 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .tablet\:padding-top-1 {
    padding-top: 0.5rem;
  }
  .tablet\:padding-right-1 {
    padding-right: 0.5rem;
  }
  .tablet\:padding-bottom-1 {
    padding-bottom: 0.5rem;
  }
  .tablet\:padding-left-1 {
    padding-left: 0.5rem;
  }
  .tablet\:padding-105 {
    padding: 0.75rem;
  }
  .tablet\:padding-y-105 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .tablet\:padding-x-105 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .tablet\:padding-top-105 {
    padding-top: 0.75rem;
  }
  .tablet\:padding-right-105 {
    padding-right: 0.75rem;
  }
  .tablet\:padding-bottom-105 {
    padding-bottom: 0.75rem;
  }
  .tablet\:padding-left-105 {
    padding-left: 0.75rem;
  }
  .tablet\:padding-2 {
    padding: 1rem;
  }
  .tablet\:padding-y-2 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .tablet\:padding-x-2 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .tablet\:padding-top-2 {
    padding-top: 1rem;
  }
  .tablet\:padding-right-2 {
    padding-right: 1rem;
  }
  .tablet\:padding-bottom-2 {
    padding-bottom: 1rem;
  }
  .tablet\:padding-left-2 {
    padding-left: 1rem;
  }
  .tablet\:padding-205 {
    padding: 1.25rem;
  }
  .tablet\:padding-y-205 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .tablet\:padding-x-205 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .tablet\:padding-top-205 {
    padding-top: 1.25rem;
  }
  .tablet\:padding-right-205 {
    padding-right: 1.25rem;
  }
  .tablet\:padding-bottom-205 {
    padding-bottom: 1.25rem;
  }
  .tablet\:padding-left-205 {
    padding-left: 1.25rem;
  }
  .tablet\:padding-3 {
    padding: 1.5rem;
  }
  .tablet\:padding-y-3 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .tablet\:padding-x-3 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .tablet\:padding-top-3 {
    padding-top: 1.5rem;
  }
  .tablet\:padding-right-3 {
    padding-right: 1.5rem;
  }
  .tablet\:padding-bottom-3 {
    padding-bottom: 1.5rem;
  }
  .tablet\:padding-left-3 {
    padding-left: 1.5rem;
  }
  .tablet\:padding-4 {
    padding: 2rem;
  }
  .tablet\:padding-y-4 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .tablet\:padding-x-4 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .tablet\:padding-top-4 {
    padding-top: 2rem;
  }
  .tablet\:padding-right-4 {
    padding-right: 2rem;
  }
  .tablet\:padding-bottom-4 {
    padding-bottom: 2rem;
  }
  .tablet\:padding-left-4 {
    padding-left: 2rem;
  }
  .tablet\:padding-5 {
    padding: 2.5rem;
  }
  .tablet\:padding-y-5 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .tablet\:padding-x-5 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .tablet\:padding-top-5 {
    padding-top: 2.5rem;
  }
  .tablet\:padding-right-5 {
    padding-right: 2.5rem;
  }
  .tablet\:padding-bottom-5 {
    padding-bottom: 2.5rem;
  }
  .tablet\:padding-left-5 {
    padding-left: 2.5rem;
  }
  .tablet\:padding-6 {
    padding: 3rem;
  }
  .tablet\:padding-y-6 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .tablet\:padding-x-6 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .tablet\:padding-top-6 {
    padding-top: 3rem;
  }
  .tablet\:padding-right-6 {
    padding-right: 3rem;
  }
  .tablet\:padding-bottom-6 {
    padding-bottom: 3rem;
  }
  .tablet\:padding-left-6 {
    padding-left: 3rem;
  }
  .tablet\:padding-7 {
    padding: 3.5rem;
  }
  .tablet\:padding-y-7 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .tablet\:padding-x-7 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
  .tablet\:padding-top-7 {
    padding-top: 3.5rem;
  }
  .tablet\:padding-right-7 {
    padding-right: 3.5rem;
  }
  .tablet\:padding-bottom-7 {
    padding-bottom: 3.5rem;
  }
  .tablet\:padding-left-7 {
    padding-left: 3.5rem;
  }
  .tablet\:padding-8 {
    padding: 4rem;
  }
  .tablet\:padding-y-8 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .tablet\:padding-x-8 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .tablet\:padding-top-8 {
    padding-top: 4rem;
  }
  .tablet\:padding-right-8 {
    padding-right: 4rem;
  }
  .tablet\:padding-bottom-8 {
    padding-bottom: 4rem;
  }
  .tablet\:padding-left-8 {
    padding-left: 4rem;
  }
  .tablet\:padding-9 {
    padding: 4.5rem;
  }
  .tablet\:padding-y-9 {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }
  .tablet\:padding-x-9 {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }
  .tablet\:padding-top-9 {
    padding-top: 4.5rem;
  }
  .tablet\:padding-right-9 {
    padding-right: 4.5rem;
  }
  .tablet\:padding-bottom-9 {
    padding-bottom: 4.5rem;
  }
  .tablet\:padding-left-9 {
    padding-left: 4.5rem;
  }
  .tablet\:padding-10 {
    padding: 5rem;
  }
  .tablet\:padding-y-10 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .tablet\:padding-x-10 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .tablet\:padding-top-10 {
    padding-top: 5rem;
  }
  .tablet\:padding-right-10 {
    padding-right: 5rem;
  }
  .tablet\:padding-bottom-10 {
    padding-bottom: 5rem;
  }
  .tablet\:padding-left-10 {
    padding-left: 5rem;
  }
  .tablet\:padding-15 {
    padding: 7.5rem;
  }
  .tablet\:padding-y-15 {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
  .tablet\:padding-x-15 {
    padding-left: 7.5rem;
    padding-right: 7.5rem;
  }
  .tablet\:padding-top-15 {
    padding-top: 7.5rem;
  }
  .tablet\:padding-right-15 {
    padding-right: 7.5rem;
  }
  .tablet\:padding-bottom-15 {
    padding-bottom: 7.5rem;
  }
  .tablet\:padding-left-15 {
    padding-left: 7.5rem;
  }
  .tablet\:padding-0 {
    padding: 0;
  }
  .tablet\:padding-y-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .tablet\:padding-x-0 {
    padding-left: 0;
    padding-right: 0;
  }
  .tablet\:padding-top-0 {
    padding-top: 0;
  }
  .tablet\:padding-right-0 {
    padding-right: 0;
  }
  .tablet\:padding-bottom-0 {
    padding-bottom: 0;
  }
  .tablet\:padding-left-0 {
    padding-left: 0;
  }
  .tablet\:width-1px {
    width: 1px;
  }
  .tablet\:width-2px {
    width: 2px;
  }
  .tablet\:width-05 {
    width: 0.25rem;
  }
  .tablet\:width-1 {
    width: 0.5rem;
  }
  .tablet\:width-105 {
    width: 0.75rem;
  }
  .tablet\:width-2 {
    width: 1rem;
  }
  .tablet\:width-205 {
    width: 1.25rem;
  }
  .tablet\:width-3 {
    width: 1.5rem;
  }
  .tablet\:width-4 {
    width: 2rem;
  }
  .tablet\:width-5 {
    width: 2.5rem;
  }
  .tablet\:width-6 {
    width: 3rem;
  }
  .tablet\:width-7 {
    width: 3.5rem;
  }
  .tablet\:width-8 {
    width: 4rem;
  }
  .tablet\:width-9 {
    width: 4.5rem;
  }
  .tablet\:width-10 {
    width: 5rem;
  }
  .tablet\:width-15 {
    width: 7.5rem;
  }
  .tablet\:width-card {
    width: 10rem;
  }
  .tablet\:width-card-lg {
    width: 15rem;
  }
  .tablet\:width-mobile {
    width: 20rem;
  }
  .tablet\:width-mobile-lg {
    width: 30rem;
  }
  .tablet\:width-tablet {
    width: 40rem;
  }
  .tablet\:width-tablet-lg {
    width: 55rem;
  }
  .tablet\:width-desktop {
    width: 64rem;
  }
  .tablet\:width-desktop-lg {
    width: 75rem;
  }
  .tablet\:width-widescreen {
    width: 87.5rem;
  }
  .tablet\:width-0 {
    width: 0;
  }
  .tablet\:width-full {
    width: 100%;
  }
  .tablet\:width-auto {
    width: auto;
  }
}
@media all and (min-width: 55em) {
  .tablet-lg\:border-1px {
    border: 1px solid;
  }
  .tablet-lg\:hover\:border-1px:hover {
    border: 1px solid;
  }
  .tablet-lg\:border-y-1px {
    border-top: 1px solid;
    border-bottom: 1px solid;
  }
  .tablet-lg\:hover\:border-y-1px:hover {
    border-top: 1px solid;
    border-bottom: 1px solid;
  }
  .tablet-lg\:border-x-1px {
    border-left: 1px solid;
    border-right: 1px solid;
  }
  .tablet-lg\:hover\:border-x-1px:hover {
    border-left: 1px solid;
    border-right: 1px solid;
  }
  .tablet-lg\:border-top-1px {
    border-top: 1px solid;
  }
  .tablet-lg\:hover\:border-top-1px:hover {
    border-top: 1px solid;
  }
  .tablet-lg\:border-right-1px {
    border-right: 1px solid;
  }
  .tablet-lg\:hover\:border-right-1px:hover {
    border-right: 1px solid;
  }
  .tablet-lg\:border-bottom-1px {
    border-bottom: 1px solid;
  }
  .tablet-lg\:hover\:border-bottom-1px:hover {
    border-bottom: 1px solid;
  }
  .tablet-lg\:border-left-1px {
    border-left: 1px solid;
  }
  .tablet-lg\:hover\:border-left-1px:hover {
    border-left: 1px solid;
  }
  .tablet-lg\:border-2px {
    border: 2px solid;
  }
  .tablet-lg\:hover\:border-2px:hover {
    border: 2px solid;
  }
  .tablet-lg\:border-y-2px {
    border-top: 2px solid;
    border-bottom: 2px solid;
  }
  .tablet-lg\:hover\:border-y-2px:hover {
    border-top: 2px solid;
    border-bottom: 2px solid;
  }
  .tablet-lg\:border-x-2px {
    border-left: 2px solid;
    border-right: 2px solid;
  }
  .tablet-lg\:hover\:border-x-2px:hover {
    border-left: 2px solid;
    border-right: 2px solid;
  }
  .tablet-lg\:border-top-2px {
    border-top: 2px solid;
  }
  .tablet-lg\:hover\:border-top-2px:hover {
    border-top: 2px solid;
  }
  .tablet-lg\:border-right-2px {
    border-right: 2px solid;
  }
  .tablet-lg\:hover\:border-right-2px:hover {
    border-right: 2px solid;
  }
  .tablet-lg\:border-bottom-2px {
    border-bottom: 2px solid;
  }
  .tablet-lg\:hover\:border-bottom-2px:hover {
    border-bottom: 2px solid;
  }
  .tablet-lg\:border-left-2px {
    border-left: 2px solid;
  }
  .tablet-lg\:hover\:border-left-2px:hover {
    border-left: 2px solid;
  }
  .tablet-lg\:border-05 {
    border: 0.25rem solid;
  }
  .tablet-lg\:hover\:border-05:hover {
    border: 0.25rem solid;
  }
  .tablet-lg\:border-y-05 {
    border-top: 0.25rem solid;
    border-bottom: 0.25rem solid;
  }
  .tablet-lg\:hover\:border-y-05:hover {
    border-top: 0.25rem solid;
    border-bottom: 0.25rem solid;
  }
  .tablet-lg\:border-x-05 {
    border-left: 0.25rem solid;
    border-right: 0.25rem solid;
  }
  .tablet-lg\:hover\:border-x-05:hover {
    border-left: 0.25rem solid;
    border-right: 0.25rem solid;
  }
  .tablet-lg\:border-top-05 {
    border-top: 0.25rem solid;
  }
  .tablet-lg\:hover\:border-top-05:hover {
    border-top: 0.25rem solid;
  }
  .tablet-lg\:border-right-05 {
    border-right: 0.25rem solid;
  }
  .tablet-lg\:hover\:border-right-05:hover {
    border-right: 0.25rem solid;
  }
  .tablet-lg\:border-bottom-05 {
    border-bottom: 0.25rem solid;
  }
  .tablet-lg\:hover\:border-bottom-05:hover {
    border-bottom: 0.25rem solid;
  }
  .tablet-lg\:border-left-05 {
    border-left: 0.25rem solid;
  }
  .tablet-lg\:hover\:border-left-05:hover {
    border-left: 0.25rem solid;
  }
  .tablet-lg\:border-1 {
    border: 0.5rem solid;
  }
  .tablet-lg\:hover\:border-1:hover {
    border: 0.5rem solid;
  }
  .tablet-lg\:border-y-1 {
    border-top: 0.5rem solid;
    border-bottom: 0.5rem solid;
  }
  .tablet-lg\:hover\:border-y-1:hover {
    border-top: 0.5rem solid;
    border-bottom: 0.5rem solid;
  }
  .tablet-lg\:border-x-1 {
    border-left: 0.5rem solid;
    border-right: 0.5rem solid;
  }
  .tablet-lg\:hover\:border-x-1:hover {
    border-left: 0.5rem solid;
    border-right: 0.5rem solid;
  }
  .tablet-lg\:border-top-1 {
    border-top: 0.5rem solid;
  }
  .tablet-lg\:hover\:border-top-1:hover {
    border-top: 0.5rem solid;
  }
  .tablet-lg\:border-right-1 {
    border-right: 0.5rem solid;
  }
  .tablet-lg\:hover\:border-right-1:hover {
    border-right: 0.5rem solid;
  }
  .tablet-lg\:border-bottom-1 {
    border-bottom: 0.5rem solid;
  }
  .tablet-lg\:hover\:border-bottom-1:hover {
    border-bottom: 0.5rem solid;
  }
  .tablet-lg\:border-left-1 {
    border-left: 0.5rem solid;
  }
  .tablet-lg\:hover\:border-left-1:hover {
    border-left: 0.5rem solid;
  }
  .tablet-lg\:border-105 {
    border: 0.75rem solid;
  }
  .tablet-lg\:hover\:border-105:hover {
    border: 0.75rem solid;
  }
  .tablet-lg\:border-y-105 {
    border-top: 0.75rem solid;
    border-bottom: 0.75rem solid;
  }
  .tablet-lg\:hover\:border-y-105:hover {
    border-top: 0.75rem solid;
    border-bottom: 0.75rem solid;
  }
  .tablet-lg\:border-x-105 {
    border-left: 0.75rem solid;
    border-right: 0.75rem solid;
  }
  .tablet-lg\:hover\:border-x-105:hover {
    border-left: 0.75rem solid;
    border-right: 0.75rem solid;
  }
  .tablet-lg\:border-top-105 {
    border-top: 0.75rem solid;
  }
  .tablet-lg\:hover\:border-top-105:hover {
    border-top: 0.75rem solid;
  }
  .tablet-lg\:border-right-105 {
    border-right: 0.75rem solid;
  }
  .tablet-lg\:hover\:border-right-105:hover {
    border-right: 0.75rem solid;
  }
  .tablet-lg\:border-bottom-105 {
    border-bottom: 0.75rem solid;
  }
  .tablet-lg\:hover\:border-bottom-105:hover {
    border-bottom: 0.75rem solid;
  }
  .tablet-lg\:border-left-105 {
    border-left: 0.75rem solid;
  }
  .tablet-lg\:hover\:border-left-105:hover {
    border-left: 0.75rem solid;
  }
  .tablet-lg\:border-2 {
    border: 1rem solid;
  }
  .tablet-lg\:hover\:border-2:hover {
    border: 1rem solid;
  }
  .tablet-lg\:border-y-2 {
    border-top: 1rem solid;
    border-bottom: 1rem solid;
  }
  .tablet-lg\:hover\:border-y-2:hover {
    border-top: 1rem solid;
    border-bottom: 1rem solid;
  }
  .tablet-lg\:border-x-2 {
    border-left: 1rem solid;
    border-right: 1rem solid;
  }
  .tablet-lg\:hover\:border-x-2:hover {
    border-left: 1rem solid;
    border-right: 1rem solid;
  }
  .tablet-lg\:border-top-2 {
    border-top: 1rem solid;
  }
  .tablet-lg\:hover\:border-top-2:hover {
    border-top: 1rem solid;
  }
  .tablet-lg\:border-right-2 {
    border-right: 1rem solid;
  }
  .tablet-lg\:hover\:border-right-2:hover {
    border-right: 1rem solid;
  }
  .tablet-lg\:border-bottom-2 {
    border-bottom: 1rem solid;
  }
  .tablet-lg\:hover\:border-bottom-2:hover {
    border-bottom: 1rem solid;
  }
  .tablet-lg\:border-left-2 {
    border-left: 1rem solid;
  }
  .tablet-lg\:hover\:border-left-2:hover {
    border-left: 1rem solid;
  }
  .tablet-lg\:border-205 {
    border: 1.25rem solid;
  }
  .tablet-lg\:hover\:border-205:hover {
    border: 1.25rem solid;
  }
  .tablet-lg\:border-y-205 {
    border-top: 1.25rem solid;
    border-bottom: 1.25rem solid;
  }
  .tablet-lg\:hover\:border-y-205:hover {
    border-top: 1.25rem solid;
    border-bottom: 1.25rem solid;
  }
  .tablet-lg\:border-x-205 {
    border-left: 1.25rem solid;
    border-right: 1.25rem solid;
  }
  .tablet-lg\:hover\:border-x-205:hover {
    border-left: 1.25rem solid;
    border-right: 1.25rem solid;
  }
  .tablet-lg\:border-top-205 {
    border-top: 1.25rem solid;
  }
  .tablet-lg\:hover\:border-top-205:hover {
    border-top: 1.25rem solid;
  }
  .tablet-lg\:border-right-205 {
    border-right: 1.25rem solid;
  }
  .tablet-lg\:hover\:border-right-205:hover {
    border-right: 1.25rem solid;
  }
  .tablet-lg\:border-bottom-205 {
    border-bottom: 1.25rem solid;
  }
  .tablet-lg\:hover\:border-bottom-205:hover {
    border-bottom: 1.25rem solid;
  }
  .tablet-lg\:border-left-205 {
    border-left: 1.25rem solid;
  }
  .tablet-lg\:hover\:border-left-205:hover {
    border-left: 1.25rem solid;
  }
  .tablet-lg\:border-3 {
    border: 1.5rem solid;
  }
  .tablet-lg\:hover\:border-3:hover {
    border: 1.5rem solid;
  }
  .tablet-lg\:border-y-3 {
    border-top: 1.5rem solid;
    border-bottom: 1.5rem solid;
  }
  .tablet-lg\:hover\:border-y-3:hover {
    border-top: 1.5rem solid;
    border-bottom: 1.5rem solid;
  }
  .tablet-lg\:border-x-3 {
    border-left: 1.5rem solid;
    border-right: 1.5rem solid;
  }
  .tablet-lg\:hover\:border-x-3:hover {
    border-left: 1.5rem solid;
    border-right: 1.5rem solid;
  }
  .tablet-lg\:border-top-3 {
    border-top: 1.5rem solid;
  }
  .tablet-lg\:hover\:border-top-3:hover {
    border-top: 1.5rem solid;
  }
  .tablet-lg\:border-right-3 {
    border-right: 1.5rem solid;
  }
  .tablet-lg\:hover\:border-right-3:hover {
    border-right: 1.5rem solid;
  }
  .tablet-lg\:border-bottom-3 {
    border-bottom: 1.5rem solid;
  }
  .tablet-lg\:hover\:border-bottom-3:hover {
    border-bottom: 1.5rem solid;
  }
  .tablet-lg\:border-left-3 {
    border-left: 1.5rem solid;
  }
  .tablet-lg\:hover\:border-left-3:hover {
    border-left: 1.5rem solid;
  }
  .tablet-lg\:border-0 {
    border: 0 solid;
  }
  .tablet-lg\:hover\:border-0:hover {
    border: 0 solid;
  }
  .tablet-lg\:border-y-0 {
    border-top: 0 solid;
    border-bottom: 0 solid;
  }
  .tablet-lg\:hover\:border-y-0:hover {
    border-top: 0 solid;
    border-bottom: 0 solid;
  }
  .tablet-lg\:border-x-0 {
    border-left: 0 solid;
    border-right: 0 solid;
  }
  .tablet-lg\:hover\:border-x-0:hover {
    border-left: 0 solid;
    border-right: 0 solid;
  }
  .tablet-lg\:border-top-0 {
    border-top: 0 solid;
  }
  .tablet-lg\:hover\:border-top-0:hover {
    border-top: 0 solid;
  }
  .tablet-lg\:border-right-0 {
    border-right: 0 solid;
  }
  .tablet-lg\:hover\:border-right-0:hover {
    border-right: 0 solid;
  }
  .tablet-lg\:border-bottom-0 {
    border-bottom: 0 solid;
  }
  .tablet-lg\:hover\:border-bottom-0:hover {
    border-bottom: 0 solid;
  }
  .tablet-lg\:border-left-0 {
    border-left: 0 solid;
  }
  .tablet-lg\:hover\:border-left-0:hover {
    border-left: 0 solid;
  }
  .tablet-lg\:border {
    border: 1px solid;
  }
  .tablet-lg\:hover\:border:hover {
    border: 1px solid;
  }
  .tablet-lg\:border-y {
    border-top: 1px solid;
    border-bottom: 1px solid;
  }
  .tablet-lg\:hover\:border-y:hover {
    border-top: 1px solid;
    border-bottom: 1px solid;
  }
  .tablet-lg\:border-x {
    border-left: 1px solid;
    border-right: 1px solid;
  }
  .tablet-lg\:hover\:border-x:hover {
    border-left: 1px solid;
    border-right: 1px solid;
  }
  .tablet-lg\:border-top {
    border-top: 1px solid;
  }
  .tablet-lg\:hover\:border-top:hover {
    border-top: 1px solid;
  }
  .tablet-lg\:border-right {
    border-right: 1px solid;
  }
  .tablet-lg\:hover\:border-right:hover {
    border-right: 1px solid;
  }
  .tablet-lg\:border-bottom {
    border-bottom: 1px solid;
  }
  .tablet-lg\:hover\:border-bottom:hover {
    border-bottom: 1px solid;
  }
  .tablet-lg\:border-left {
    border-left: 1px solid;
  }
  .tablet-lg\:hover\:border-left:hover {
    border-left: 1px solid;
  }
  .tablet-lg\:border-transparent {
    border-color: transparent;
  }
  .tablet-lg\:hover\:border-transparent:hover {
    border-color: transparent;
  }
  .tablet-lg\:border-black {
    border-color: black;
  }
  .tablet-lg\:hover\:border-black:hover {
    border-color: black;
  }
  .tablet-lg\:border-white {
    border-color: white;
  }
  .tablet-lg\:hover\:border-white:hover {
    border-color: white;
  }
  .tablet-lg\:border-red {
    border-color: #e52207;
  }
  .tablet-lg\:hover\:border-red:hover {
    border-color: #e52207;
  }
  .tablet-lg\:border-orange {
    border-color: #e66f0e;
  }
  .tablet-lg\:hover\:border-orange:hover {
    border-color: #e66f0e;
  }
  .tablet-lg\:border-gold {
    border-color: #ffbe2e;
  }
  .tablet-lg\:hover\:border-gold:hover {
    border-color: #ffbe2e;
  }
  .tablet-lg\:border-yellow {
    border-color: #fee685;
  }
  .tablet-lg\:hover\:border-yellow:hover {
    border-color: #fee685;
  }
  .tablet-lg\:border-green {
    border-color: #538200;
  }
  .tablet-lg\:hover\:border-green:hover {
    border-color: #538200;
  }
  .tablet-lg\:border-mint {
    border-color: #04c585;
  }
  .tablet-lg\:hover\:border-mint:hover {
    border-color: #04c585;
  }
  .tablet-lg\:border-cyan {
    border-color: #009ec1;
  }
  .tablet-lg\:hover\:border-cyan:hover {
    border-color: #009ec1;
  }
  .tablet-lg\:border-blue {
    border-color: #0076d6;
  }
  .tablet-lg\:hover\:border-blue:hover {
    border-color: #0076d6;
  }
  .tablet-lg\:border-indigo {
    border-color: #676cc8;
  }
  .tablet-lg\:hover\:border-indigo:hover {
    border-color: #676cc8;
  }
  .tablet-lg\:border-violet {
    border-color: #8168b3;
  }
  .tablet-lg\:hover\:border-violet:hover {
    border-color: #8168b3;
  }
  .tablet-lg\:border-magenta {
    border-color: #d72d79;
  }
  .tablet-lg\:hover\:border-magenta:hover {
    border-color: #d72d79;
  }
  .tablet-lg\:border-gray-5 {
    border-color: #f0f0f0;
  }
  .tablet-lg\:hover\:border-gray-5:hover {
    border-color: #f0f0f0;
  }
  .tablet-lg\:border-gray-10 {
    border-color: #e6e6e6;
  }
  .tablet-lg\:hover\:border-gray-10:hover {
    border-color: #e6e6e6;
  }
  .tablet-lg\:border-gray-30 {
    border-color: #adadad;
  }
  .tablet-lg\:hover\:border-gray-30:hover {
    border-color: #adadad;
  }
  .tablet-lg\:border-gray-50 {
    border-color: #757575;
  }
  .tablet-lg\:hover\:border-gray-50:hover {
    border-color: #757575;
  }
  .tablet-lg\:border-gray-70 {
    border-color: #454545;
  }
  .tablet-lg\:hover\:border-gray-70:hover {
    border-color: #454545;
  }
  .tablet-lg\:border-gray-90 {
    border-color: #1b1b1b;
  }
  .tablet-lg\:hover\:border-gray-90:hover {
    border-color: #1b1b1b;
  }
  .tablet-lg\:border-base-lightest {
    border-color: #f0f0f0;
  }
  .tablet-lg\:hover\:border-base-lightest:hover {
    border-color: #f0f0f0;
  }
  .tablet-lg\:border-base-lighter {
    border-color: #dfe1e2;
  }
  .tablet-lg\:hover\:border-base-lighter:hover {
    border-color: #dfe1e2;
  }
  .tablet-lg\:border-base-light {
    border-color: #a9aeb1;
  }
  .tablet-lg\:hover\:border-base-light:hover {
    border-color: #a9aeb1;
  }
  .tablet-lg\:border-base {
    border-color: #71767a;
  }
  .tablet-lg\:hover\:border-base:hover {
    border-color: #71767a;
  }
  .tablet-lg\:border-base-dark {
    border-color: #565c65;
  }
  .tablet-lg\:hover\:border-base-dark:hover {
    border-color: #565c65;
  }
  .tablet-lg\:border-base-darker {
    border-color: #3d4551;
  }
  .tablet-lg\:hover\:border-base-darker:hover {
    border-color: #3d4551;
  }
  .tablet-lg\:border-base-darkest {
    border-color: #1b1b1b;
  }
  .tablet-lg\:hover\:border-base-darkest:hover {
    border-color: #1b1b1b;
  }
  .tablet-lg\:border-ink {
    border-color: #11181d;
  }
  .tablet-lg\:hover\:border-ink:hover {
    border-color: #11181d;
  }
  .tablet-lg\:border-primary-lightest {
    border-color: #B3EFFF;
  }
  .tablet-lg\:hover\:border-primary-lightest:hover {
    border-color: #B3EFFF;
  }
  .tablet-lg\:border-primary-lighter {
    border-color: #97d4ea;
  }
  .tablet-lg\:hover\:border-primary-lighter:hover {
    border-color: #97d4ea;
  }
  .tablet-lg\:border-primary-light {
    border-color: #28a0cb;
  }
  .tablet-lg\:hover\:border-primary-light:hover {
    border-color: #28a0cb;
  }
  .tablet-lg\:border-primary {
    border-color: #046B99;
  }
  .tablet-lg\:hover\:border-primary:hover {
    border-color: #046B99;
  }
  .tablet-lg\:border-primary-vivid {
    border-color: #0050d8;
  }
  .tablet-lg\:hover\:border-primary-vivid:hover {
    border-color: #0050d8;
  }
  .tablet-lg\:border-primary-dark {
    border-color: #0b4778;
  }
  .tablet-lg\:hover\:border-primary-dark:hover {
    border-color: #0b4778;
  }
  .tablet-lg\:border-primary-darker {
    border-color: #1C304A;
  }
  .tablet-lg\:hover\:border-primary-darker:hover {
    border-color: #1C304A;
  }
  .tablet-lg\:border-primary-darkest {
    border-color: #11181d;
  }
  .tablet-lg\:hover\:border-primary-darkest:hover {
    border-color: #11181d;
  }
  .tablet-lg\:border-secondary-lighter {
    border-color: #f3e1e4;
  }
  .tablet-lg\:hover\:border-secondary-lighter:hover {
    border-color: #f3e1e4;
  }
  .tablet-lg\:border-secondary-light {
    border-color: #f2938c;
  }
  .tablet-lg\:hover\:border-secondary-light:hover {
    border-color: #f2938c;
  }
  .tablet-lg\:border-secondary {
    border-color: #d83933;
  }
  .tablet-lg\:hover\:border-secondary:hover {
    border-color: #d83933;
  }
  .tablet-lg\:border-secondary-vivid {
    border-color: #e41d3d;
  }
  .tablet-lg\:hover\:border-secondary-vivid:hover {
    border-color: #e41d3d;
  }
  .tablet-lg\:border-secondary-dark {
    border-color: #b50909;
  }
  .tablet-lg\:hover\:border-secondary-dark:hover {
    border-color: #b50909;
  }
  .tablet-lg\:border-secondary-darker {
    border-color: #8b0a03;
  }
  .tablet-lg\:hover\:border-secondary-darker:hover {
    border-color: #8b0a03;
  }
  .tablet-lg\:border-accent-warm-darker {
    border-color: #775540;
  }
  .tablet-lg\:hover\:border-accent-warm-darker:hover {
    border-color: #775540;
  }
  .tablet-lg\:border-accent-warm-dark {
    border-color: #c05600;
  }
  .tablet-lg\:hover\:border-accent-warm-dark:hover {
    border-color: #c05600;
  }
  .tablet-lg\:border-accent-warm {
    border-color: #fa9441;
  }
  .tablet-lg\:hover\:border-accent-warm:hover {
    border-color: #fa9441;
  }
  .tablet-lg\:border-accent-warm-light {
    border-color: #ffbc78;
  }
  .tablet-lg\:hover\:border-accent-warm-light:hover {
    border-color: #ffbc78;
  }
  .tablet-lg\:border-accent-warm-lighter {
    border-color: #f2e4d4;
  }
  .tablet-lg\:hover\:border-accent-warm-lighter:hover {
    border-color: #f2e4d4;
  }
  .tablet-lg\:border-accent-cool-darkest {
    border-color: #093b44;
  }
  .tablet-lg\:hover\:border-accent-cool-darkest:hover {
    border-color: #093b44;
  }
  .tablet-lg\:border-accent-cool-darker {
    border-color: #0e4f5c;
  }
  .tablet-lg\:hover\:border-accent-cool-darker:hover {
    border-color: #0e4f5c;
  }
  .tablet-lg\:border-accent-cool-dark {
    border-color: #0081a1;
  }
  .tablet-lg\:hover\:border-accent-cool-dark:hover {
    border-color: #0081a1;
  }
  .tablet-lg\:border-accent-cool {
    border-color: #00CFFF;
  }
  .tablet-lg\:hover\:border-accent-cool:hover {
    border-color: #00CFFF;
  }
  .tablet-lg\:border-accent-cool-light {
    border-color: #52daf2;
  }
  .tablet-lg\:hover\:border-accent-cool-light:hover {
    border-color: #52daf2;
  }
  .tablet-lg\:border-accent-cool-lighter {
    border-color: #a8f2ff;
  }
  .tablet-lg\:hover\:border-accent-cool-lighter:hover {
    border-color: #a8f2ff;
  }
  .tablet-lg\:border-accent-cool-lightest {
    border-color: #eff6fb;
  }
  .tablet-lg\:hover\:border-accent-cool-lightest:hover {
    border-color: #eff6fb;
  }
  .tablet-lg\:border-error-lighter {
    border-color: #fff5ee;
  }
  .tablet-lg\:hover\:border-error-lighter:hover {
    border-color: #fff5ee;
  }
  .tablet-lg\:border-error-light {
    border-color: #f39268;
  }
  .tablet-lg\:hover\:border-error-light:hover {
    border-color: #f39268;
  }
  .tablet-lg\:border-error {
    border-color: #d54309;
  }
  .tablet-lg\:hover\:border-error:hover {
    border-color: #d54309;
  }
  .tablet-lg\:border-error-dark {
    border-color: #b50909;
  }
  .tablet-lg\:hover\:border-error-dark:hover {
    border-color: #b50909;
  }
  .tablet-lg\:border-error-darker {
    border-color: #6f3331;
  }
  .tablet-lg\:hover\:border-error-darker:hover {
    border-color: #6f3331;
  }
  .tablet-lg\:border-warning-lighter {
    border-color: #faf3d1;
  }
  .tablet-lg\:hover\:border-warning-lighter:hover {
    border-color: #faf3d1;
  }
  .tablet-lg\:border-warning-light {
    border-color: #fee685;
  }
  .tablet-lg\:hover\:border-warning-light:hover {
    border-color: #fee685;
  }
  .tablet-lg\:border-warning {
    border-color: #ffbe2e;
  }
  .tablet-lg\:hover\:border-warning:hover {
    border-color: #ffbe2e;
  }
  .tablet-lg\:border-warning-dark {
    border-color: #e5a000;
  }
  .tablet-lg\:hover\:border-warning-dark:hover {
    border-color: #e5a000;
  }
  .tablet-lg\:border-warning-darker {
    border-color: #936f38;
  }
  .tablet-lg\:hover\:border-warning-darker:hover {
    border-color: #936f38;
  }
  .tablet-lg\:border-success-lighter {
    border-color: #ecf3ec;
  }
  .tablet-lg\:hover\:border-success-lighter:hover {
    border-color: #ecf3ec;
  }
  .tablet-lg\:border-success-light {
    border-color: #70e17b;
  }
  .tablet-lg\:hover\:border-success-light:hover {
    border-color: #70e17b;
  }
  .tablet-lg\:border-success {
    border-color: #00a91c;
  }
  .tablet-lg\:hover\:border-success:hover {
    border-color: #00a91c;
  }
  .tablet-lg\:border-success-dark {
    border-color: #008817;
  }
  .tablet-lg\:hover\:border-success-dark:hover {
    border-color: #008817;
  }
  .tablet-lg\:border-success-darker {
    border-color: #216e1f;
  }
  .tablet-lg\:hover\:border-success-darker:hover {
    border-color: #216e1f;
  }
  .tablet-lg\:border-info-lighter {
    border-color: #e7f6f8;
  }
  .tablet-lg\:hover\:border-info-lighter:hover {
    border-color: #e7f6f8;
  }
  .tablet-lg\:border-info-light {
    border-color: #99deea;
  }
  .tablet-lg\:hover\:border-info-light:hover {
    border-color: #99deea;
  }
  .tablet-lg\:border-info {
    border-color: #00bde3;
  }
  .tablet-lg\:hover\:border-info:hover {
    border-color: #00bde3;
  }
  .tablet-lg\:border-info-dark {
    border-color: #009ec1;
  }
  .tablet-lg\:hover\:border-info-dark:hover {
    border-color: #009ec1;
  }
  .tablet-lg\:border-info-darker {
    border-color: #2e6276;
  }
  .tablet-lg\:hover\:border-info-darker:hover {
    border-color: #2e6276;
  }
  .tablet-lg\:border-disabled-lighter {
    border-color: #c9c9c9;
  }
  .tablet-lg\:hover\:border-disabled-lighter:hover {
    border-color: #c9c9c9;
  }
  .tablet-lg\:border-disabled-light {
    border-color: #919191;
  }
  .tablet-lg\:hover\:border-disabled-light:hover {
    border-color: #919191;
  }
  .tablet-lg\:border-disabled {
    border-color: #757575;
  }
  .tablet-lg\:hover\:border-disabled:hover {
    border-color: #757575;
  }
  .tablet-lg\:border-disabled-dark {
    border-color: #454545;
  }
  .tablet-lg\:hover\:border-disabled-dark:hover {
    border-color: #454545;
  }
  .tablet-lg\:border-disabled-darker {
    border-color: #1b1b1b;
  }
  .tablet-lg\:hover\:border-disabled-darker:hover {
    border-color: #1b1b1b;
  }
  .tablet-lg\:border-emergency {
    border-color: #9c3d10;
  }
  .tablet-lg\:hover\:border-emergency:hover {
    border-color: #9c3d10;
  }
  .tablet-lg\:border-emergency-dark {
    border-color: #332d29;
  }
  .tablet-lg\:hover\:border-emergency-dark:hover {
    border-color: #332d29;
  }
  .tablet-lg\:radius-0 {
    border-radius: 0;
  }
  .tablet-lg\:radius-top-0 {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .tablet-lg\:radius-right-0 {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .tablet-lg\:radius-bottom-0 {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .tablet-lg\:radius-left-0 {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .tablet-lg\:radius-sm {
    border-radius: 2px;
  }
  .tablet-lg\:radius-top-sm {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }
  .tablet-lg\:radius-right-sm {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  .tablet-lg\:radius-bottom-sm {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  .tablet-lg\:radius-left-sm {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  .tablet-lg\:radius-md {
    border-radius: 0.25rem;
  }
  .tablet-lg\:radius-top-md {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .tablet-lg\:radius-right-md {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  .tablet-lg\:radius-bottom-md {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  .tablet-lg\:radius-left-md {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .tablet-lg\:radius-lg {
    border-radius: 0.5rem;
  }
  .tablet-lg\:radius-top-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  .tablet-lg\:radius-right-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .tablet-lg\:radius-bottom-lg {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .tablet-lg\:radius-left-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .tablet-lg\:radius-pill {
    border-radius: 99rem;
  }
  .tablet-lg\:radius-top-pill {
    border-top-left-radius: 99rem;
    border-top-right-radius: 99rem;
  }
  .tablet-lg\:radius-right-pill {
    border-top-right-radius: 99rem;
    border-bottom-right-radius: 99rem;
  }
  .tablet-lg\:radius-bottom-pill {
    border-bottom-left-radius: 99rem;
    border-bottom-right-radius: 99rem;
  }
  .tablet-lg\:radius-left-pill {
    border-top-left-radius: 99rem;
    border-bottom-left-radius: 99rem;
  }
  .tablet-lg\:display-block {
    display: block;
  }
  .tablet-lg\:display-flex {
    display: flex;
  }
  .tablet-lg\:display-none {
    display: none;
  }
  .tablet-lg\:display-inline {
    display: inline;
  }
  .tablet-lg\:display-inline-block {
    display: inline-block;
  }
  .tablet-lg\:display-inline-flex {
    display: inline-flex;
  }
  .tablet-lg\:display-table {
    display: table;
  }
  .tablet-lg\:display-table-cell {
    display: table-cell;
  }
  .tablet-lg\:display-table-row {
    display: table-row;
  }
  .tablet-lg\:font-mono-3xs {
    font-size: 0.77rem;
  }
  .tablet-lg\:font-mono-2xs {
    font-size: 0.83rem;
  }
  .tablet-lg\:font-mono-xs {
    font-size: 0.89rem;
  }
  .tablet-lg\:font-mono-sm {
    font-size: 0.95rem;
  }
  .tablet-lg\:font-mono-md {
    font-size: 1.01rem;
  }
  .tablet-lg\:font-mono-lg {
    font-size: 1.31rem;
  }
  .tablet-lg\:font-mono-xl {
    font-size: 1.91rem;
  }
  .tablet-lg\:font-mono-2xl {
    font-size: 2.38rem;
  }
  .tablet-lg\:font-mono-3xl {
    font-size: 2.86rem;
  }
  .tablet-lg\:font-sans-3xs {
    font-size: 0.82rem;
  }
  .tablet-lg\:font-sans-2xs {
    font-size: 0.89rem;
  }
  .tablet-lg\:font-sans-xs {
    font-size: 0.95rem;
  }
  .tablet-lg\:font-sans-sm {
    font-size: 1.01rem;
  }
  .tablet-lg\:font-sans-md {
    font-size: 1.08rem;
  }
  .tablet-lg\:font-sans-lg {
    font-size: 1.39rem;
  }
  .tablet-lg\:font-sans-xl {
    font-size: 2.03rem;
  }
  .tablet-lg\:font-sans-2xl {
    font-size: 2.54rem;
  }
  .tablet-lg\:font-sans-3xl {
    font-size: 3.04rem;
  }
  .tablet-lg\:font-serif-3xs {
    font-size: 0.79rem;
  }
  .tablet-lg\:font-serif-2xs {
    font-size: 0.85rem;
  }
  .tablet-lg\:font-serif-xs {
    font-size: 0.91rem;
  }
  .tablet-lg\:font-serif-sm {
    font-size: 0.98rem;
  }
  .tablet-lg\:font-serif-md {
    font-size: 1.04rem;
  }
  .tablet-lg\:font-serif-lg {
    font-size: 1.34rem;
  }
  .tablet-lg\:font-serif-xl {
    font-size: 1.95rem;
  }
  .tablet-lg\:font-serif-2xl {
    font-size: 2.44rem;
  }
  .tablet-lg\:font-serif-3xl {
    font-size: 2.93rem;
  }
  .tablet-lg\:font-heading-3xs {
    font-size: 0.82rem;
  }
  .tablet-lg\:font-heading-2xs {
    font-size: 0.89rem;
  }
  .tablet-lg\:font-heading-xs {
    font-size: 0.95rem;
  }
  .tablet-lg\:font-heading-sm {
    font-size: 1.01rem;
  }
  .tablet-lg\:font-heading-md {
    font-size: 1.08rem;
  }
  .tablet-lg\:font-heading-lg {
    font-size: 1.39rem;
  }
  .tablet-lg\:font-heading-xl {
    font-size: 2.03rem;
  }
  .tablet-lg\:font-heading-2xl {
    font-size: 2.54rem;
  }
  .tablet-lg\:font-heading-3xl {
    font-size: 3.04rem;
  }
  .tablet-lg\:font-body-3xs {
    font-size: 0.82rem;
  }
  .tablet-lg\:font-body-2xs {
    font-size: 0.89rem;
  }
  .tablet-lg\:font-body-xs {
    font-size: 0.95rem;
  }
  .tablet-lg\:font-body-sm {
    font-size: 1.01rem;
  }
  .tablet-lg\:font-body-md {
    font-size: 1.08rem;
  }
  .tablet-lg\:font-body-lg {
    font-size: 1.39rem;
  }
  .tablet-lg\:font-body-xl {
    font-size: 2.03rem;
  }
  .tablet-lg\:font-body-2xl {
    font-size: 2.54rem;
  }
  .tablet-lg\:font-body-3xl {
    font-size: 3.04rem;
  }
  .tablet-lg\:font-code-3xs {
    font-size: 0.77rem;
  }
  .tablet-lg\:font-code-2xs {
    font-size: 0.83rem;
  }
  .tablet-lg\:font-code-xs {
    font-size: 0.89rem;
  }
  .tablet-lg\:font-code-sm {
    font-size: 0.95rem;
  }
  .tablet-lg\:font-code-md {
    font-size: 1.01rem;
  }
  .tablet-lg\:font-code-lg {
    font-size: 1.31rem;
  }
  .tablet-lg\:font-code-xl {
    font-size: 1.91rem;
  }
  .tablet-lg\:font-code-2xl {
    font-size: 2.38rem;
  }
  .tablet-lg\:font-code-3xl {
    font-size: 2.86rem;
  }
  .tablet-lg\:font-alt-3xs {
    font-size: 0.79rem;
  }
  .tablet-lg\:font-alt-2xs {
    font-size: 0.85rem;
  }
  .tablet-lg\:font-alt-xs {
    font-size: 0.91rem;
  }
  .tablet-lg\:font-alt-sm {
    font-size: 0.98rem;
  }
  .tablet-lg\:font-alt-md {
    font-size: 1.04rem;
  }
  .tablet-lg\:font-alt-lg {
    font-size: 1.34rem;
  }
  .tablet-lg\:font-alt-xl {
    font-size: 1.95rem;
  }
  .tablet-lg\:font-alt-2xl {
    font-size: 2.44rem;
  }
  .tablet-lg\:font-alt-3xl {
    font-size: 2.93rem;
  }
  .tablet-lg\:font-ui-3xs {
    font-size: 0.82rem;
  }
  .tablet-lg\:font-ui-2xs {
    font-size: 0.89rem;
  }
  .tablet-lg\:font-ui-xs {
    font-size: 0.95rem;
  }
  .tablet-lg\:font-ui-sm {
    font-size: 1.01rem;
  }
  .tablet-lg\:font-ui-md {
    font-size: 1.08rem;
  }
  .tablet-lg\:font-ui-lg {
    font-size: 1.39rem;
  }
  .tablet-lg\:font-ui-xl {
    font-size: 2.03rem;
  }
  .tablet-lg\:font-ui-2xl {
    font-size: 2.54rem;
  }
  .tablet-lg\:font-ui-3xl {
    font-size: 3.04rem;
  }
  .tablet-lg\:text-light {
    font-weight: 300;
  }
  .tablet-lg\:text-normal {
    font-weight: normal;
  }
  .tablet-lg\:text-semibold {
    font-weight: 700;
  }
  .tablet-lg\:text-bold {
    font-weight: 700;
  }
  .tablet-lg\:flex-justify-center {
    justify-content: center;
  }
  .tablet-lg\:flex-justify-start {
    justify-content: flex-start;
  }
  .tablet-lg\:flex-justify-end {
    justify-content: flex-end;
  }
  .tablet-lg\:flex-justify {
    justify-content: space-between;
  }
  .tablet-lg\:line-height-sans-1 {
    line-height: 1;
  }
  .tablet-lg\:line-height-sans-2 {
    line-height: 1.2;
  }
  .tablet-lg\:line-height-sans-3 {
    line-height: 1.3;
  }
  .tablet-lg\:line-height-sans-4 {
    line-height: 1.5;
  }
  .tablet-lg\:line-height-sans-5 {
    line-height: 1.6;
  }
  .tablet-lg\:line-height-sans-6 {
    line-height: 1.7;
  }
  .tablet-lg\:line-height-serif-1 {
    line-height: 1;
  }
  .tablet-lg\:line-height-serif-2 {
    line-height: 1.2;
  }
  .tablet-lg\:line-height-serif-3 {
    line-height: 1.4;
  }
  .tablet-lg\:line-height-serif-4 {
    line-height: 1.5;
  }
  .tablet-lg\:line-height-serif-5 {
    line-height: 1.7;
  }
  .tablet-lg\:line-height-serif-6 {
    line-height: 1.8;
  }
  .tablet-lg\:line-height-mono-1 {
    line-height: 1;
  }
  .tablet-lg\:line-height-mono-2 {
    line-height: 1.3;
  }
  .tablet-lg\:line-height-mono-3 {
    line-height: 1.4;
  }
  .tablet-lg\:line-height-mono-4 {
    line-height: 1.6;
  }
  .tablet-lg\:line-height-mono-5 {
    line-height: 1.7;
  }
  .tablet-lg\:line-height-mono-6 {
    line-height: 1.8;
  }
  .tablet-lg\:line-height-heading-1 {
    line-height: 1;
  }
  .tablet-lg\:line-height-heading-2 {
    line-height: 1.2;
  }
  .tablet-lg\:line-height-heading-3 {
    line-height: 1.3;
  }
  .tablet-lg\:line-height-heading-4 {
    line-height: 1.5;
  }
  .tablet-lg\:line-height-heading-5 {
    line-height: 1.6;
  }
  .tablet-lg\:line-height-heading-6 {
    line-height: 1.7;
  }
  .tablet-lg\:line-height-ui-1 {
    line-height: 1;
  }
  .tablet-lg\:line-height-ui-2 {
    line-height: 1.2;
  }
  .tablet-lg\:line-height-ui-3 {
    line-height: 1.3;
  }
  .tablet-lg\:line-height-ui-4 {
    line-height: 1.5;
  }
  .tablet-lg\:line-height-ui-5 {
    line-height: 1.6;
  }
  .tablet-lg\:line-height-ui-6 {
    line-height: 1.7;
  }
  .tablet-lg\:line-height-body-1 {
    line-height: 1;
  }
  .tablet-lg\:line-height-body-2 {
    line-height: 1.2;
  }
  .tablet-lg\:line-height-body-3 {
    line-height: 1.3;
  }
  .tablet-lg\:line-height-body-4 {
    line-height: 1.5;
  }
  .tablet-lg\:line-height-body-5 {
    line-height: 1.6;
  }
  .tablet-lg\:line-height-body-6 {
    line-height: 1.7;
  }
  .tablet-lg\:line-height-code-1 {
    line-height: 1;
  }
  .tablet-lg\:line-height-code-2 {
    line-height: 1.3;
  }
  .tablet-lg\:line-height-code-3 {
    line-height: 1.4;
  }
  .tablet-lg\:line-height-code-4 {
    line-height: 1.6;
  }
  .tablet-lg\:line-height-code-5 {
    line-height: 1.7;
  }
  .tablet-lg\:line-height-code-6 {
    line-height: 1.8;
  }
  .tablet-lg\:line-height-alt-1 {
    line-height: 1;
  }
  .tablet-lg\:line-height-alt-2 {
    line-height: 1.2;
  }
  .tablet-lg\:line-height-alt-3 {
    line-height: 1.4;
  }
  .tablet-lg\:line-height-alt-4 {
    line-height: 1.5;
  }
  .tablet-lg\:line-height-alt-5 {
    line-height: 1.7;
  }
  .tablet-lg\:line-height-alt-6 {
    line-height: 1.8;
  }
  .tablet-lg\:margin-neg-1px {
    margin: -1px;
  }
  .tablet-lg\:margin-neg-2px {
    margin: -2px;
  }
  .tablet-lg\:margin-neg-05 {
    margin: -0.25rem;
  }
  .tablet-lg\:margin-neg-1 {
    margin: -0.5rem;
  }
  .tablet-lg\:margin-neg-105 {
    margin: -0.75rem;
  }
  .tablet-lg\:margin-neg-2 {
    margin: -1rem;
  }
  .tablet-lg\:margin-neg-205 {
    margin: -1.25rem;
  }
  .tablet-lg\:margin-neg-3 {
    margin: -1.5rem;
  }
  .tablet-lg\:margin-1px {
    margin: 1px;
  }
  .tablet-lg\:margin-2px {
    margin: 2px;
  }
  .tablet-lg\:margin-05 {
    margin: 0.25rem;
  }
  .tablet-lg\:margin-1 {
    margin: 0.5rem;
  }
  .tablet-lg\:margin-105 {
    margin: 0.75rem;
  }
  .tablet-lg\:margin-2 {
    margin: 1rem;
  }
  .tablet-lg\:margin-205 {
    margin: 1.25rem;
  }
  .tablet-lg\:margin-3 {
    margin: 1.5rem;
  }
  .tablet-lg\:margin-neg-4 {
    margin: -2rem;
  }
  .tablet-lg\:margin-neg-5 {
    margin: -2.5rem;
  }
  .tablet-lg\:margin-neg-6 {
    margin: -3rem;
  }
  .tablet-lg\:margin-neg-7 {
    margin: -3.5rem;
  }
  .tablet-lg\:margin-neg-8 {
    margin: -4rem;
  }
  .tablet-lg\:margin-neg-9 {
    margin: -4.5rem;
  }
  .tablet-lg\:margin-neg-10 {
    margin: -5rem;
  }
  .tablet-lg\:margin-neg-15 {
    margin: -7.5rem;
  }
  .tablet-lg\:margin-4 {
    margin: 2rem;
  }
  .tablet-lg\:margin-5 {
    margin: 2.5rem;
  }
  .tablet-lg\:margin-6 {
    margin: 3rem;
  }
  .tablet-lg\:margin-7 {
    margin: 3.5rem;
  }
  .tablet-lg\:margin-8 {
    margin: 4rem;
  }
  .tablet-lg\:margin-9 {
    margin: 4.5rem;
  }
  .tablet-lg\:margin-10 {
    margin: 5rem;
  }
  .tablet-lg\:margin-15 {
    margin: 7.5rem;
  }
  .tablet-lg\:margin-05em {
    margin: 0.5em;
  }
  .tablet-lg\:margin-1em {
    margin: 1em;
  }
  .tablet-lg\:margin-105em {
    margin: 1.5em;
  }
  .tablet-lg\:margin-2em {
    margin: 2em;
  }
  .tablet-lg\:margin-0 {
    margin: 0;
  }
  .tablet-lg\:margin-y-1px {
    margin-top: 1px;
    margin-bottom: 1px;
  }
  .tablet-lg\:margin-top-1px {
    margin-top: 1px;
  }
  .tablet-lg\:margin-bottom-1px {
    margin-bottom: 1px;
  }
  .tablet-lg\:margin-y-2px {
    margin-top: 2px;
    margin-bottom: 2px;
  }
  .tablet-lg\:margin-top-2px {
    margin-top: 2px;
  }
  .tablet-lg\:margin-bottom-2px {
    margin-bottom: 2px;
  }
  .tablet-lg\:margin-y-05 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .tablet-lg\:margin-top-05 {
    margin-top: 0.25rem;
  }
  .tablet-lg\:margin-bottom-05 {
    margin-bottom: 0.25rem;
  }
  .tablet-lg\:margin-y-1 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .tablet-lg\:margin-top-1 {
    margin-top: 0.5rem;
  }
  .tablet-lg\:margin-bottom-1 {
    margin-bottom: 0.5rem;
  }
  .tablet-lg\:margin-y-105 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  .tablet-lg\:margin-top-105 {
    margin-top: 0.75rem;
  }
  .tablet-lg\:margin-bottom-105 {
    margin-bottom: 0.75rem;
  }
  .tablet-lg\:margin-y-2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .tablet-lg\:margin-top-2 {
    margin-top: 1rem;
  }
  .tablet-lg\:margin-bottom-2 {
    margin-bottom: 1rem;
  }
  .tablet-lg\:margin-y-205 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .tablet-lg\:margin-top-205 {
    margin-top: 1.25rem;
  }
  .tablet-lg\:margin-bottom-205 {
    margin-bottom: 1.25rem;
  }
  .tablet-lg\:margin-y-3 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .tablet-lg\:margin-top-3 {
    margin-top: 1.5rem;
  }
  .tablet-lg\:margin-bottom-3 {
    margin-bottom: 1.5rem;
  }
  .tablet-lg\:margin-y-neg-1px {
    margin-top: -1px;
    margin-bottom: -1px;
  }
  .tablet-lg\:margin-top-neg-1px {
    margin-top: -1px;
  }
  .tablet-lg\:margin-bottom-neg-1px {
    margin-bottom: -1px;
  }
  .tablet-lg\:margin-y-neg-2px {
    margin-top: -2px;
    margin-bottom: -2px;
  }
  .tablet-lg\:margin-top-neg-2px {
    margin-top: -2px;
  }
  .tablet-lg\:margin-bottom-neg-2px {
    margin-bottom: -2px;
  }
  .tablet-lg\:margin-y-neg-05 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .tablet-lg\:margin-top-neg-05 {
    margin-top: -0.25rem;
  }
  .tablet-lg\:margin-bottom-neg-05 {
    margin-bottom: -0.25rem;
  }
  .tablet-lg\:margin-y-neg-1 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .tablet-lg\:margin-top-neg-1 {
    margin-top: -0.5rem;
  }
  .tablet-lg\:margin-bottom-neg-1 {
    margin-bottom: -0.5rem;
  }
  .tablet-lg\:margin-y-neg-105 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }
  .tablet-lg\:margin-top-neg-105 {
    margin-top: -0.75rem;
  }
  .tablet-lg\:margin-bottom-neg-105 {
    margin-bottom: -0.75rem;
  }
  .tablet-lg\:margin-y-neg-2 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .tablet-lg\:margin-top-neg-2 {
    margin-top: -1rem;
  }
  .tablet-lg\:margin-bottom-neg-2 {
    margin-bottom: -1rem;
  }
  .tablet-lg\:margin-y-neg-205 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }
  .tablet-lg\:margin-top-neg-205 {
    margin-top: -1.25rem;
  }
  .tablet-lg\:margin-bottom-neg-205 {
    margin-bottom: -1.25rem;
  }
  .tablet-lg\:margin-y-neg-3 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .tablet-lg\:margin-top-neg-3 {
    margin-top: -1.5rem;
  }
  .tablet-lg\:margin-bottom-neg-3 {
    margin-bottom: -1.5rem;
  }
  .tablet-lg\:margin-y-neg-4 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }
  .tablet-lg\:margin-top-neg-4 {
    margin-top: -2rem;
  }
  .tablet-lg\:margin-bottom-neg-4 {
    margin-bottom: -2rem;
  }
  .tablet-lg\:margin-y-neg-5 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }
  .tablet-lg\:margin-top-neg-5 {
    margin-top: -2.5rem;
  }
  .tablet-lg\:margin-bottom-neg-5 {
    margin-bottom: -2.5rem;
  }
  .tablet-lg\:margin-y-neg-6 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .tablet-lg\:margin-top-neg-6 {
    margin-top: -3rem;
  }
  .tablet-lg\:margin-bottom-neg-6 {
    margin-bottom: -3rem;
  }
  .tablet-lg\:margin-y-neg-7 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }
  .tablet-lg\:margin-top-neg-7 {
    margin-top: -3.5rem;
  }
  .tablet-lg\:margin-bottom-neg-7 {
    margin-bottom: -3.5rem;
  }
  .tablet-lg\:margin-y-neg-8 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }
  .tablet-lg\:margin-top-neg-8 {
    margin-top: -4rem;
  }
  .tablet-lg\:margin-bottom-neg-8 {
    margin-bottom: -4rem;
  }
  .tablet-lg\:margin-y-neg-9 {
    margin-top: -4.5rem;
    margin-bottom: -4.5rem;
  }
  .tablet-lg\:margin-top-neg-9 {
    margin-top: -4.5rem;
  }
  .tablet-lg\:margin-bottom-neg-9 {
    margin-bottom: -4.5rem;
  }
  .tablet-lg\:margin-y-neg-10 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }
  .tablet-lg\:margin-top-neg-10 {
    margin-top: -5rem;
  }
  .tablet-lg\:margin-bottom-neg-10 {
    margin-bottom: -5rem;
  }
  .tablet-lg\:margin-y-neg-15 {
    margin-top: -7.5rem;
    margin-bottom: -7.5rem;
  }
  .tablet-lg\:margin-top-neg-15 {
    margin-top: -7.5rem;
  }
  .tablet-lg\:margin-bottom-neg-15 {
    margin-bottom: -7.5rem;
  }
  .tablet-lg\:margin-y-4 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .tablet-lg\:margin-top-4 {
    margin-top: 2rem;
  }
  .tablet-lg\:margin-bottom-4 {
    margin-bottom: 2rem;
  }
  .tablet-lg\:margin-y-5 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .tablet-lg\:margin-top-5 {
    margin-top: 2.5rem;
  }
  .tablet-lg\:margin-bottom-5 {
    margin-bottom: 2.5rem;
  }
  .tablet-lg\:margin-y-6 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .tablet-lg\:margin-top-6 {
    margin-top: 3rem;
  }
  .tablet-lg\:margin-bottom-6 {
    margin-bottom: 3rem;
  }
  .tablet-lg\:margin-y-7 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
  .tablet-lg\:margin-top-7 {
    margin-top: 3.5rem;
  }
  .tablet-lg\:margin-bottom-7 {
    margin-bottom: 3.5rem;
  }
  .tablet-lg\:margin-y-8 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .tablet-lg\:margin-top-8 {
    margin-top: 4rem;
  }
  .tablet-lg\:margin-bottom-8 {
    margin-bottom: 4rem;
  }
  .tablet-lg\:margin-y-9 {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }
  .tablet-lg\:margin-top-9 {
    margin-top: 4.5rem;
  }
  .tablet-lg\:margin-bottom-9 {
    margin-bottom: 4.5rem;
  }
  .tablet-lg\:margin-y-10 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .tablet-lg\:margin-top-10 {
    margin-top: 5rem;
  }
  .tablet-lg\:margin-bottom-10 {
    margin-bottom: 5rem;
  }
  .tablet-lg\:margin-y-15 {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem;
  }
  .tablet-lg\:margin-top-15 {
    margin-top: 7.5rem;
  }
  .tablet-lg\:margin-bottom-15 {
    margin-bottom: 7.5rem;
  }
  .tablet-lg\:margin-y-05em {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  .tablet-lg\:margin-top-05em {
    margin-top: 0.5em;
  }
  .tablet-lg\:margin-bottom-05em {
    margin-bottom: 0.5em;
  }
  .tablet-lg\:margin-y-1em {
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .tablet-lg\:margin-top-1em {
    margin-top: 1em;
  }
  .tablet-lg\:margin-bottom-1em {
    margin-bottom: 1em;
  }
  .tablet-lg\:margin-y-105em {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }
  .tablet-lg\:margin-top-105em {
    margin-top: 1.5em;
  }
  .tablet-lg\:margin-bottom-105em {
    margin-bottom: 1.5em;
  }
  .tablet-lg\:margin-y-2em {
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .tablet-lg\:margin-top-2em {
    margin-top: 2em;
  }
  .tablet-lg\:margin-bottom-2em {
    margin-bottom: 2em;
  }
  .tablet-lg\:margin-y-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .tablet-lg\:margin-top-0 {
    margin-top: 0;
  }
  .tablet-lg\:margin-bottom-0 {
    margin-bottom: 0;
  }
  .tablet-lg\:margin-y-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .tablet-lg\:margin-top-auto {
    margin-top: auto;
  }
  .tablet-lg\:margin-bottom-auto {
    margin-bottom: auto;
  }
  .tablet-lg\:margin-x-1px {
    margin-left: 1px;
    margin-right: 1px;
  }
  .tablet-lg\:margin-right-1px {
    margin-right: 1px;
  }
  .tablet-lg\:margin-left-1px {
    margin-left: 1px;
  }
  .tablet-lg\:margin-x-2px {
    margin-left: 2px;
    margin-right: 2px;
  }
  .tablet-lg\:margin-right-2px {
    margin-right: 2px;
  }
  .tablet-lg\:margin-left-2px {
    margin-left: 2px;
  }
  .tablet-lg\:margin-x-05 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .tablet-lg\:margin-right-05 {
    margin-right: 0.25rem;
  }
  .tablet-lg\:margin-left-05 {
    margin-left: 0.25rem;
  }
  .tablet-lg\:margin-x-1 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .tablet-lg\:margin-right-1 {
    margin-right: 0.5rem;
  }
  .tablet-lg\:margin-left-1 {
    margin-left: 0.5rem;
  }
  .tablet-lg\:margin-x-105 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
  .tablet-lg\:margin-right-105 {
    margin-right: 0.75rem;
  }
  .tablet-lg\:margin-left-105 {
    margin-left: 0.75rem;
  }
  .tablet-lg\:margin-x-2 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .tablet-lg\:margin-right-2 {
    margin-right: 1rem;
  }
  .tablet-lg\:margin-left-2 {
    margin-left: 1rem;
  }
  .tablet-lg\:margin-x-205 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
  .tablet-lg\:margin-right-205 {
    margin-right: 1.25rem;
  }
  .tablet-lg\:margin-left-205 {
    margin-left: 1.25rem;
  }
  .tablet-lg\:margin-x-3 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .tablet-lg\:margin-right-3 {
    margin-right: 1.5rem;
  }
  .tablet-lg\:margin-left-3 {
    margin-left: 1.5rem;
  }
  .tablet-lg\:margin-x-neg-1px {
    margin-left: -1px;
    margin-right: -1px;
  }
  .tablet-lg\:margin-right-neg-1px {
    margin-right: -1px;
  }
  .tablet-lg\:margin-left-neg-1px {
    margin-left: -1px;
  }
  .tablet-lg\:margin-x-neg-2px {
    margin-left: -2px;
    margin-right: -2px;
  }
  .tablet-lg\:margin-right-neg-2px {
    margin-right: -2px;
  }
  .tablet-lg\:margin-left-neg-2px {
    margin-left: -2px;
  }
  .tablet-lg\:margin-x-neg-05 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .tablet-lg\:margin-right-neg-05 {
    margin-right: -0.25rem;
  }
  .tablet-lg\:margin-left-neg-05 {
    margin-left: -0.25rem;
  }
  .tablet-lg\:margin-x-neg-1 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .tablet-lg\:margin-right-neg-1 {
    margin-right: -0.5rem;
  }
  .tablet-lg\:margin-left-neg-1 {
    margin-left: -0.5rem;
  }
  .tablet-lg\:margin-x-neg-105 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .tablet-lg\:margin-right-neg-105 {
    margin-right: -0.75rem;
  }
  .tablet-lg\:margin-left-neg-105 {
    margin-left: -0.75rem;
  }
  .tablet-lg\:margin-x-neg-2 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .tablet-lg\:margin-right-neg-2 {
    margin-right: -1rem;
  }
  .tablet-lg\:margin-left-neg-2 {
    margin-left: -1rem;
  }
  .tablet-lg\:margin-x-neg-205 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
  .tablet-lg\:margin-right-neg-205 {
    margin-right: -1.25rem;
  }
  .tablet-lg\:margin-left-neg-205 {
    margin-left: -1.25rem;
  }
  .tablet-lg\:margin-x-neg-3 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .tablet-lg\:margin-right-neg-3 {
    margin-right: -1.5rem;
  }
  .tablet-lg\:margin-left-neg-3 {
    margin-left: -1.5rem;
  }
  .tablet-lg\:margin-x-neg-4 {
    margin-left: -2rem;
    margin-right: -2rem;
  }
  .tablet-lg\:margin-right-neg-4 {
    margin-right: -2rem;
  }
  .tablet-lg\:margin-left-neg-4 {
    margin-left: -2rem;
  }
  .tablet-lg\:margin-x-neg-5 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }
  .tablet-lg\:margin-right-neg-5 {
    margin-right: -2.5rem;
  }
  .tablet-lg\:margin-left-neg-5 {
    margin-left: -2.5rem;
  }
  .tablet-lg\:margin-x-neg-6 {
    margin-left: -3rem;
    margin-right: -3rem;
  }
  .tablet-lg\:margin-right-neg-6 {
    margin-right: -3rem;
  }
  .tablet-lg\:margin-left-neg-6 {
    margin-left: -3rem;
  }
  .tablet-lg\:margin-x-neg-7 {
    margin-left: -3.5rem;
    margin-right: -3.5rem;
  }
  .tablet-lg\:margin-right-neg-7 {
    margin-right: -3.5rem;
  }
  .tablet-lg\:margin-left-neg-7 {
    margin-left: -3.5rem;
  }
  .tablet-lg\:margin-x-neg-8 {
    margin-left: -4rem;
    margin-right: -4rem;
  }
  .tablet-lg\:margin-right-neg-8 {
    margin-right: -4rem;
  }
  .tablet-lg\:margin-left-neg-8 {
    margin-left: -4rem;
  }
  .tablet-lg\:margin-x-neg-9 {
    margin-left: -4.5rem;
    margin-right: -4.5rem;
  }
  .tablet-lg\:margin-right-neg-9 {
    margin-right: -4.5rem;
  }
  .tablet-lg\:margin-left-neg-9 {
    margin-left: -4.5rem;
  }
  .tablet-lg\:margin-x-neg-10 {
    margin-left: -5rem;
    margin-right: -5rem;
  }
  .tablet-lg\:margin-right-neg-10 {
    margin-right: -5rem;
  }
  .tablet-lg\:margin-left-neg-10 {
    margin-left: -5rem;
  }
  .tablet-lg\:margin-x-neg-15 {
    margin-left: -7.5rem;
    margin-right: -7.5rem;
  }
  .tablet-lg\:margin-right-neg-15 {
    margin-right: -7.5rem;
  }
  .tablet-lg\:margin-left-neg-15 {
    margin-left: -7.5rem;
  }
  .tablet-lg\:margin-x-4 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .tablet-lg\:margin-right-4 {
    margin-right: 2rem;
  }
  .tablet-lg\:margin-left-4 {
    margin-left: 2rem;
  }
  .tablet-lg\:margin-x-5 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
  .tablet-lg\:margin-right-5 {
    margin-right: 2.5rem;
  }
  .tablet-lg\:margin-left-5 {
    margin-left: 2.5rem;
  }
  .tablet-lg\:margin-x-6 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .tablet-lg\:margin-right-6 {
    margin-right: 3rem;
  }
  .tablet-lg\:margin-left-6 {
    margin-left: 3rem;
  }
  .tablet-lg\:margin-x-7 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }
  .tablet-lg\:margin-right-7 {
    margin-right: 3.5rem;
  }
  .tablet-lg\:margin-left-7 {
    margin-left: 3.5rem;
  }
  .tablet-lg\:margin-x-8 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .tablet-lg\:margin-right-8 {
    margin-right: 4rem;
  }
  .tablet-lg\:margin-left-8 {
    margin-left: 4rem;
  }
  .tablet-lg\:margin-x-9 {
    margin-left: 4.5rem;
    margin-right: 4.5rem;
  }
  .tablet-lg\:margin-right-9 {
    margin-right: 4.5rem;
  }
  .tablet-lg\:margin-left-9 {
    margin-left: 4.5rem;
  }
  .tablet-lg\:margin-x-10 {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .tablet-lg\:margin-right-10 {
    margin-right: 5rem;
  }
  .tablet-lg\:margin-left-10 {
    margin-left: 5rem;
  }
  .tablet-lg\:margin-x-15 {
    margin-left: 7.5rem;
    margin-right: 7.5rem;
  }
  .tablet-lg\:margin-right-15 {
    margin-right: 7.5rem;
  }
  .tablet-lg\:margin-left-15 {
    margin-left: 7.5rem;
  }
  .tablet-lg\:margin-x-card {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  .tablet-lg\:margin-right-card {
    margin-right: 10rem;
  }
  .tablet-lg\:margin-left-card {
    margin-left: 10rem;
  }
  .tablet-lg\:margin-x-card-lg {
    margin-left: 15rem;
    margin-right: 15rem;
  }
  .tablet-lg\:margin-right-card-lg {
    margin-right: 15rem;
  }
  .tablet-lg\:margin-left-card-lg {
    margin-left: 15rem;
  }
  .tablet-lg\:margin-x-mobile {
    margin-left: 20rem;
    margin-right: 20rem;
  }
  .tablet-lg\:margin-right-mobile {
    margin-right: 20rem;
  }
  .tablet-lg\:margin-left-mobile {
    margin-left: 20rem;
  }
  .tablet-lg\:margin-x-05em {
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
  .tablet-lg\:margin-right-05em {
    margin-right: 0.5em;
  }
  .tablet-lg\:margin-left-05em {
    margin-left: 0.5em;
  }
  .tablet-lg\:margin-x-1em {
    margin-left: 1em;
    margin-right: 1em;
  }
  .tablet-lg\:margin-right-1em {
    margin-right: 1em;
  }
  .tablet-lg\:margin-left-1em {
    margin-left: 1em;
  }
  .tablet-lg\:margin-x-105em {
    margin-left: 1.5em;
    margin-right: 1.5em;
  }
  .tablet-lg\:margin-right-105em {
    margin-right: 1.5em;
  }
  .tablet-lg\:margin-left-105em {
    margin-left: 1.5em;
  }
  .tablet-lg\:margin-x-2em {
    margin-left: 2em;
    margin-right: 2em;
  }
  .tablet-lg\:margin-right-2em {
    margin-right: 2em;
  }
  .tablet-lg\:margin-left-2em {
    margin-left: 2em;
  }
  .tablet-lg\:margin-x-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .tablet-lg\:margin-right-0 {
    margin-right: 0;
  }
  .tablet-lg\:margin-left-0 {
    margin-left: 0;
  }
  .tablet-lg\:margin-x-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .tablet-lg\:margin-right-auto {
    margin-right: auto;
  }
  .tablet-lg\:margin-left-auto {
    margin-left: auto;
  }
  .tablet-lg\:measure-1 {
    max-width: 44ex;
  }
  .tablet-lg\:measure-2 {
    max-width: 60ex;
  }
  .tablet-lg\:measure-3 {
    max-width: 64ex;
  }
  .tablet-lg\:measure-4 {
    max-width: 68ex;
  }
  .tablet-lg\:measure-5 {
    max-width: 72ex;
  }
  .tablet-lg\:measure-6 {
    max-width: 88ex;
  }
  .tablet-lg\:measure-none {
    max-width: none;
  }
  .tablet-lg\:order-first {
    order: -1;
  }
  .tablet-lg\:order-last {
    order: 999;
  }
  .tablet-lg\:order-initial {
    order: initial;
  }
  .tablet-lg\:order-0 {
    order: 0;
  }
  .tablet-lg\:order-1 {
    order: 1;
  }
  .tablet-lg\:order-2 {
    order: 2;
  }
  .tablet-lg\:order-3 {
    order: 3;
  }
  .tablet-lg\:order-4 {
    order: 4;
  }
  .tablet-lg\:order-5 {
    order: 5;
  }
  .tablet-lg\:order-6 {
    order: 6;
  }
  .tablet-lg\:order-7 {
    order: 7;
  }
  .tablet-lg\:order-8 {
    order: 8;
  }
  .tablet-lg\:order-9 {
    order: 9;
  }
  .tablet-lg\:order-10 {
    order: 10;
  }
  .tablet-lg\:order-11 {
    order: 11;
  }
  .tablet-lg\:padding-1px {
    padding: 1px;
  }
  .tablet-lg\:padding-y-1px {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .tablet-lg\:padding-x-1px {
    padding-left: 1px;
    padding-right: 1px;
  }
  .tablet-lg\:padding-top-1px {
    padding-top: 1px;
  }
  .tablet-lg\:padding-right-1px {
    padding-right: 1px;
  }
  .tablet-lg\:padding-bottom-1px {
    padding-bottom: 1px;
  }
  .tablet-lg\:padding-left-1px {
    padding-left: 1px;
  }
  .tablet-lg\:padding-2px {
    padding: 2px;
  }
  .tablet-lg\:padding-y-2px {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .tablet-lg\:padding-x-2px {
    padding-left: 2px;
    padding-right: 2px;
  }
  .tablet-lg\:padding-top-2px {
    padding-top: 2px;
  }
  .tablet-lg\:padding-right-2px {
    padding-right: 2px;
  }
  .tablet-lg\:padding-bottom-2px {
    padding-bottom: 2px;
  }
  .tablet-lg\:padding-left-2px {
    padding-left: 2px;
  }
  .tablet-lg\:padding-05 {
    padding: 0.25rem;
  }
  .tablet-lg\:padding-y-05 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .tablet-lg\:padding-x-05 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .tablet-lg\:padding-top-05 {
    padding-top: 0.25rem;
  }
  .tablet-lg\:padding-right-05 {
    padding-right: 0.25rem;
  }
  .tablet-lg\:padding-bottom-05 {
    padding-bottom: 0.25rem;
  }
  .tablet-lg\:padding-left-05 {
    padding-left: 0.25rem;
  }
  .tablet-lg\:padding-1 {
    padding: 0.5rem;
  }
  .tablet-lg\:padding-y-1 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .tablet-lg\:padding-x-1 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .tablet-lg\:padding-top-1 {
    padding-top: 0.5rem;
  }
  .tablet-lg\:padding-right-1 {
    padding-right: 0.5rem;
  }
  .tablet-lg\:padding-bottom-1 {
    padding-bottom: 0.5rem;
  }
  .tablet-lg\:padding-left-1 {
    padding-left: 0.5rem;
  }
  .tablet-lg\:padding-105 {
    padding: 0.75rem;
  }
  .tablet-lg\:padding-y-105 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .tablet-lg\:padding-x-105 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .tablet-lg\:padding-top-105 {
    padding-top: 0.75rem;
  }
  .tablet-lg\:padding-right-105 {
    padding-right: 0.75rem;
  }
  .tablet-lg\:padding-bottom-105 {
    padding-bottom: 0.75rem;
  }
  .tablet-lg\:padding-left-105 {
    padding-left: 0.75rem;
  }
  .tablet-lg\:padding-2 {
    padding: 1rem;
  }
  .tablet-lg\:padding-y-2 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .tablet-lg\:padding-x-2 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .tablet-lg\:padding-top-2 {
    padding-top: 1rem;
  }
  .tablet-lg\:padding-right-2 {
    padding-right: 1rem;
  }
  .tablet-lg\:padding-bottom-2 {
    padding-bottom: 1rem;
  }
  .tablet-lg\:padding-left-2 {
    padding-left: 1rem;
  }
  .tablet-lg\:padding-205 {
    padding: 1.25rem;
  }
  .tablet-lg\:padding-y-205 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .tablet-lg\:padding-x-205 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .tablet-lg\:padding-top-205 {
    padding-top: 1.25rem;
  }
  .tablet-lg\:padding-right-205 {
    padding-right: 1.25rem;
  }
  .tablet-lg\:padding-bottom-205 {
    padding-bottom: 1.25rem;
  }
  .tablet-lg\:padding-left-205 {
    padding-left: 1.25rem;
  }
  .tablet-lg\:padding-3 {
    padding: 1.5rem;
  }
  .tablet-lg\:padding-y-3 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .tablet-lg\:padding-x-3 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .tablet-lg\:padding-top-3 {
    padding-top: 1.5rem;
  }
  .tablet-lg\:padding-right-3 {
    padding-right: 1.5rem;
  }
  .tablet-lg\:padding-bottom-3 {
    padding-bottom: 1.5rem;
  }
  .tablet-lg\:padding-left-3 {
    padding-left: 1.5rem;
  }
  .tablet-lg\:padding-4 {
    padding: 2rem;
  }
  .tablet-lg\:padding-y-4 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .tablet-lg\:padding-x-4 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .tablet-lg\:padding-top-4 {
    padding-top: 2rem;
  }
  .tablet-lg\:padding-right-4 {
    padding-right: 2rem;
  }
  .tablet-lg\:padding-bottom-4 {
    padding-bottom: 2rem;
  }
  .tablet-lg\:padding-left-4 {
    padding-left: 2rem;
  }
  .tablet-lg\:padding-5 {
    padding: 2.5rem;
  }
  .tablet-lg\:padding-y-5 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .tablet-lg\:padding-x-5 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .tablet-lg\:padding-top-5 {
    padding-top: 2.5rem;
  }
  .tablet-lg\:padding-right-5 {
    padding-right: 2.5rem;
  }
  .tablet-lg\:padding-bottom-5 {
    padding-bottom: 2.5rem;
  }
  .tablet-lg\:padding-left-5 {
    padding-left: 2.5rem;
  }
  .tablet-lg\:padding-6 {
    padding: 3rem;
  }
  .tablet-lg\:padding-y-6 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .tablet-lg\:padding-x-6 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .tablet-lg\:padding-top-6 {
    padding-top: 3rem;
  }
  .tablet-lg\:padding-right-6 {
    padding-right: 3rem;
  }
  .tablet-lg\:padding-bottom-6 {
    padding-bottom: 3rem;
  }
  .tablet-lg\:padding-left-6 {
    padding-left: 3rem;
  }
  .tablet-lg\:padding-7 {
    padding: 3.5rem;
  }
  .tablet-lg\:padding-y-7 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .tablet-lg\:padding-x-7 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
  .tablet-lg\:padding-top-7 {
    padding-top: 3.5rem;
  }
  .tablet-lg\:padding-right-7 {
    padding-right: 3.5rem;
  }
  .tablet-lg\:padding-bottom-7 {
    padding-bottom: 3.5rem;
  }
  .tablet-lg\:padding-left-7 {
    padding-left: 3.5rem;
  }
  .tablet-lg\:padding-8 {
    padding: 4rem;
  }
  .tablet-lg\:padding-y-8 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .tablet-lg\:padding-x-8 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .tablet-lg\:padding-top-8 {
    padding-top: 4rem;
  }
  .tablet-lg\:padding-right-8 {
    padding-right: 4rem;
  }
  .tablet-lg\:padding-bottom-8 {
    padding-bottom: 4rem;
  }
  .tablet-lg\:padding-left-8 {
    padding-left: 4rem;
  }
  .tablet-lg\:padding-9 {
    padding: 4.5rem;
  }
  .tablet-lg\:padding-y-9 {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }
  .tablet-lg\:padding-x-9 {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }
  .tablet-lg\:padding-top-9 {
    padding-top: 4.5rem;
  }
  .tablet-lg\:padding-right-9 {
    padding-right: 4.5rem;
  }
  .tablet-lg\:padding-bottom-9 {
    padding-bottom: 4.5rem;
  }
  .tablet-lg\:padding-left-9 {
    padding-left: 4.5rem;
  }
  .tablet-lg\:padding-10 {
    padding: 5rem;
  }
  .tablet-lg\:padding-y-10 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .tablet-lg\:padding-x-10 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .tablet-lg\:padding-top-10 {
    padding-top: 5rem;
  }
  .tablet-lg\:padding-right-10 {
    padding-right: 5rem;
  }
  .tablet-lg\:padding-bottom-10 {
    padding-bottom: 5rem;
  }
  .tablet-lg\:padding-left-10 {
    padding-left: 5rem;
  }
  .tablet-lg\:padding-15 {
    padding: 7.5rem;
  }
  .tablet-lg\:padding-y-15 {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
  .tablet-lg\:padding-x-15 {
    padding-left: 7.5rem;
    padding-right: 7.5rem;
  }
  .tablet-lg\:padding-top-15 {
    padding-top: 7.5rem;
  }
  .tablet-lg\:padding-right-15 {
    padding-right: 7.5rem;
  }
  .tablet-lg\:padding-bottom-15 {
    padding-bottom: 7.5rem;
  }
  .tablet-lg\:padding-left-15 {
    padding-left: 7.5rem;
  }
  .tablet-lg\:padding-0 {
    padding: 0;
  }
  .tablet-lg\:padding-y-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .tablet-lg\:padding-x-0 {
    padding-left: 0;
    padding-right: 0;
  }
  .tablet-lg\:padding-top-0 {
    padding-top: 0;
  }
  .tablet-lg\:padding-right-0 {
    padding-right: 0;
  }
  .tablet-lg\:padding-bottom-0 {
    padding-bottom: 0;
  }
  .tablet-lg\:padding-left-0 {
    padding-left: 0;
  }
  .tablet-lg\:width-1px {
    width: 1px;
  }
  .tablet-lg\:width-2px {
    width: 2px;
  }
  .tablet-lg\:width-05 {
    width: 0.25rem;
  }
  .tablet-lg\:width-1 {
    width: 0.5rem;
  }
  .tablet-lg\:width-105 {
    width: 0.75rem;
  }
  .tablet-lg\:width-2 {
    width: 1rem;
  }
  .tablet-lg\:width-205 {
    width: 1.25rem;
  }
  .tablet-lg\:width-3 {
    width: 1.5rem;
  }
  .tablet-lg\:width-4 {
    width: 2rem;
  }
  .tablet-lg\:width-5 {
    width: 2.5rem;
  }
  .tablet-lg\:width-6 {
    width: 3rem;
  }
  .tablet-lg\:width-7 {
    width: 3.5rem;
  }
  .tablet-lg\:width-8 {
    width: 4rem;
  }
  .tablet-lg\:width-9 {
    width: 4.5rem;
  }
  .tablet-lg\:width-10 {
    width: 5rem;
  }
  .tablet-lg\:width-15 {
    width: 7.5rem;
  }
  .tablet-lg\:width-card {
    width: 10rem;
  }
  .tablet-lg\:width-card-lg {
    width: 15rem;
  }
  .tablet-lg\:width-mobile {
    width: 20rem;
  }
  .tablet-lg\:width-mobile-lg {
    width: 30rem;
  }
  .tablet-lg\:width-tablet {
    width: 40rem;
  }
  .tablet-lg\:width-tablet-lg {
    width: 55rem;
  }
  .tablet-lg\:width-desktop {
    width: 64rem;
  }
  .tablet-lg\:width-desktop-lg {
    width: 75rem;
  }
  .tablet-lg\:width-widescreen {
    width: 87.5rem;
  }
  .tablet-lg\:width-0 {
    width: 0;
  }
  .tablet-lg\:width-full {
    width: 100%;
  }
  .tablet-lg\:width-auto {
    width: auto;
  }
}
@media all and (min-width: 64em) {
  .desktop\:border-1px {
    border: 1px solid;
  }
  .desktop\:hover\:border-1px:hover {
    border: 1px solid;
  }
  .desktop\:border-y-1px {
    border-top: 1px solid;
    border-bottom: 1px solid;
  }
  .desktop\:hover\:border-y-1px:hover {
    border-top: 1px solid;
    border-bottom: 1px solid;
  }
  .desktop\:border-x-1px {
    border-left: 1px solid;
    border-right: 1px solid;
  }
  .desktop\:hover\:border-x-1px:hover {
    border-left: 1px solid;
    border-right: 1px solid;
  }
  .desktop\:border-top-1px {
    border-top: 1px solid;
  }
  .desktop\:hover\:border-top-1px:hover {
    border-top: 1px solid;
  }
  .desktop\:border-right-1px {
    border-right: 1px solid;
  }
  .desktop\:hover\:border-right-1px:hover {
    border-right: 1px solid;
  }
  .desktop\:border-bottom-1px {
    border-bottom: 1px solid;
  }
  .desktop\:hover\:border-bottom-1px:hover {
    border-bottom: 1px solid;
  }
  .desktop\:border-left-1px {
    border-left: 1px solid;
  }
  .desktop\:hover\:border-left-1px:hover {
    border-left: 1px solid;
  }
  .desktop\:border-2px {
    border: 2px solid;
  }
  .desktop\:hover\:border-2px:hover {
    border: 2px solid;
  }
  .desktop\:border-y-2px {
    border-top: 2px solid;
    border-bottom: 2px solid;
  }
  .desktop\:hover\:border-y-2px:hover {
    border-top: 2px solid;
    border-bottom: 2px solid;
  }
  .desktop\:border-x-2px {
    border-left: 2px solid;
    border-right: 2px solid;
  }
  .desktop\:hover\:border-x-2px:hover {
    border-left: 2px solid;
    border-right: 2px solid;
  }
  .desktop\:border-top-2px {
    border-top: 2px solid;
  }
  .desktop\:hover\:border-top-2px:hover {
    border-top: 2px solid;
  }
  .desktop\:border-right-2px {
    border-right: 2px solid;
  }
  .desktop\:hover\:border-right-2px:hover {
    border-right: 2px solid;
  }
  .desktop\:border-bottom-2px {
    border-bottom: 2px solid;
  }
  .desktop\:hover\:border-bottom-2px:hover {
    border-bottom: 2px solid;
  }
  .desktop\:border-left-2px {
    border-left: 2px solid;
  }
  .desktop\:hover\:border-left-2px:hover {
    border-left: 2px solid;
  }
  .desktop\:border-05 {
    border: 0.25rem solid;
  }
  .desktop\:hover\:border-05:hover {
    border: 0.25rem solid;
  }
  .desktop\:border-y-05 {
    border-top: 0.25rem solid;
    border-bottom: 0.25rem solid;
  }
  .desktop\:hover\:border-y-05:hover {
    border-top: 0.25rem solid;
    border-bottom: 0.25rem solid;
  }
  .desktop\:border-x-05 {
    border-left: 0.25rem solid;
    border-right: 0.25rem solid;
  }
  .desktop\:hover\:border-x-05:hover {
    border-left: 0.25rem solid;
    border-right: 0.25rem solid;
  }
  .desktop\:border-top-05 {
    border-top: 0.25rem solid;
  }
  .desktop\:hover\:border-top-05:hover {
    border-top: 0.25rem solid;
  }
  .desktop\:border-right-05 {
    border-right: 0.25rem solid;
  }
  .desktop\:hover\:border-right-05:hover {
    border-right: 0.25rem solid;
  }
  .desktop\:border-bottom-05 {
    border-bottom: 0.25rem solid;
  }
  .desktop\:hover\:border-bottom-05:hover {
    border-bottom: 0.25rem solid;
  }
  .desktop\:border-left-05 {
    border-left: 0.25rem solid;
  }
  .desktop\:hover\:border-left-05:hover {
    border-left: 0.25rem solid;
  }
  .desktop\:border-1 {
    border: 0.5rem solid;
  }
  .desktop\:hover\:border-1:hover {
    border: 0.5rem solid;
  }
  .desktop\:border-y-1 {
    border-top: 0.5rem solid;
    border-bottom: 0.5rem solid;
  }
  .desktop\:hover\:border-y-1:hover {
    border-top: 0.5rem solid;
    border-bottom: 0.5rem solid;
  }
  .desktop\:border-x-1 {
    border-left: 0.5rem solid;
    border-right: 0.5rem solid;
  }
  .desktop\:hover\:border-x-1:hover {
    border-left: 0.5rem solid;
    border-right: 0.5rem solid;
  }
  .desktop\:border-top-1 {
    border-top: 0.5rem solid;
  }
  .desktop\:hover\:border-top-1:hover {
    border-top: 0.5rem solid;
  }
  .desktop\:border-right-1 {
    border-right: 0.5rem solid;
  }
  .desktop\:hover\:border-right-1:hover {
    border-right: 0.5rem solid;
  }
  .desktop\:border-bottom-1 {
    border-bottom: 0.5rem solid;
  }
  .desktop\:hover\:border-bottom-1:hover {
    border-bottom: 0.5rem solid;
  }
  .desktop\:border-left-1 {
    border-left: 0.5rem solid;
  }
  .desktop\:hover\:border-left-1:hover {
    border-left: 0.5rem solid;
  }
  .desktop\:border-105 {
    border: 0.75rem solid;
  }
  .desktop\:hover\:border-105:hover {
    border: 0.75rem solid;
  }
  .desktop\:border-y-105 {
    border-top: 0.75rem solid;
    border-bottom: 0.75rem solid;
  }
  .desktop\:hover\:border-y-105:hover {
    border-top: 0.75rem solid;
    border-bottom: 0.75rem solid;
  }
  .desktop\:border-x-105 {
    border-left: 0.75rem solid;
    border-right: 0.75rem solid;
  }
  .desktop\:hover\:border-x-105:hover {
    border-left: 0.75rem solid;
    border-right: 0.75rem solid;
  }
  .desktop\:border-top-105 {
    border-top: 0.75rem solid;
  }
  .desktop\:hover\:border-top-105:hover {
    border-top: 0.75rem solid;
  }
  .desktop\:border-right-105 {
    border-right: 0.75rem solid;
  }
  .desktop\:hover\:border-right-105:hover {
    border-right: 0.75rem solid;
  }
  .desktop\:border-bottom-105 {
    border-bottom: 0.75rem solid;
  }
  .desktop\:hover\:border-bottom-105:hover {
    border-bottom: 0.75rem solid;
  }
  .desktop\:border-left-105 {
    border-left: 0.75rem solid;
  }
  .desktop\:hover\:border-left-105:hover {
    border-left: 0.75rem solid;
  }
  .desktop\:border-2 {
    border: 1rem solid;
  }
  .desktop\:hover\:border-2:hover {
    border: 1rem solid;
  }
  .desktop\:border-y-2 {
    border-top: 1rem solid;
    border-bottom: 1rem solid;
  }
  .desktop\:hover\:border-y-2:hover {
    border-top: 1rem solid;
    border-bottom: 1rem solid;
  }
  .desktop\:border-x-2 {
    border-left: 1rem solid;
    border-right: 1rem solid;
  }
  .desktop\:hover\:border-x-2:hover {
    border-left: 1rem solid;
    border-right: 1rem solid;
  }
  .desktop\:border-top-2 {
    border-top: 1rem solid;
  }
  .desktop\:hover\:border-top-2:hover {
    border-top: 1rem solid;
  }
  .desktop\:border-right-2 {
    border-right: 1rem solid;
  }
  .desktop\:hover\:border-right-2:hover {
    border-right: 1rem solid;
  }
  .desktop\:border-bottom-2 {
    border-bottom: 1rem solid;
  }
  .desktop\:hover\:border-bottom-2:hover {
    border-bottom: 1rem solid;
  }
  .desktop\:border-left-2 {
    border-left: 1rem solid;
  }
  .desktop\:hover\:border-left-2:hover {
    border-left: 1rem solid;
  }
  .desktop\:border-205 {
    border: 1.25rem solid;
  }
  .desktop\:hover\:border-205:hover {
    border: 1.25rem solid;
  }
  .desktop\:border-y-205 {
    border-top: 1.25rem solid;
    border-bottom: 1.25rem solid;
  }
  .desktop\:hover\:border-y-205:hover {
    border-top: 1.25rem solid;
    border-bottom: 1.25rem solid;
  }
  .desktop\:border-x-205 {
    border-left: 1.25rem solid;
    border-right: 1.25rem solid;
  }
  .desktop\:hover\:border-x-205:hover {
    border-left: 1.25rem solid;
    border-right: 1.25rem solid;
  }
  .desktop\:border-top-205 {
    border-top: 1.25rem solid;
  }
  .desktop\:hover\:border-top-205:hover {
    border-top: 1.25rem solid;
  }
  .desktop\:border-right-205 {
    border-right: 1.25rem solid;
  }
  .desktop\:hover\:border-right-205:hover {
    border-right: 1.25rem solid;
  }
  .desktop\:border-bottom-205 {
    border-bottom: 1.25rem solid;
  }
  .desktop\:hover\:border-bottom-205:hover {
    border-bottom: 1.25rem solid;
  }
  .desktop\:border-left-205 {
    border-left: 1.25rem solid;
  }
  .desktop\:hover\:border-left-205:hover {
    border-left: 1.25rem solid;
  }
  .desktop\:border-3 {
    border: 1.5rem solid;
  }
  .desktop\:hover\:border-3:hover {
    border: 1.5rem solid;
  }
  .desktop\:border-y-3 {
    border-top: 1.5rem solid;
    border-bottom: 1.5rem solid;
  }
  .desktop\:hover\:border-y-3:hover {
    border-top: 1.5rem solid;
    border-bottom: 1.5rem solid;
  }
  .desktop\:border-x-3 {
    border-left: 1.5rem solid;
    border-right: 1.5rem solid;
  }
  .desktop\:hover\:border-x-3:hover {
    border-left: 1.5rem solid;
    border-right: 1.5rem solid;
  }
  .desktop\:border-top-3 {
    border-top: 1.5rem solid;
  }
  .desktop\:hover\:border-top-3:hover {
    border-top: 1.5rem solid;
  }
  .desktop\:border-right-3 {
    border-right: 1.5rem solid;
  }
  .desktop\:hover\:border-right-3:hover {
    border-right: 1.5rem solid;
  }
  .desktop\:border-bottom-3 {
    border-bottom: 1.5rem solid;
  }
  .desktop\:hover\:border-bottom-3:hover {
    border-bottom: 1.5rem solid;
  }
  .desktop\:border-left-3 {
    border-left: 1.5rem solid;
  }
  .desktop\:hover\:border-left-3:hover {
    border-left: 1.5rem solid;
  }
  .desktop\:border-0 {
    border: 0 solid;
  }
  .desktop\:hover\:border-0:hover {
    border: 0 solid;
  }
  .desktop\:border-y-0 {
    border-top: 0 solid;
    border-bottom: 0 solid;
  }
  .desktop\:hover\:border-y-0:hover {
    border-top: 0 solid;
    border-bottom: 0 solid;
  }
  .desktop\:border-x-0 {
    border-left: 0 solid;
    border-right: 0 solid;
  }
  .desktop\:hover\:border-x-0:hover {
    border-left: 0 solid;
    border-right: 0 solid;
  }
  .desktop\:border-top-0 {
    border-top: 0 solid;
  }
  .desktop\:hover\:border-top-0:hover {
    border-top: 0 solid;
  }
  .desktop\:border-right-0 {
    border-right: 0 solid;
  }
  .desktop\:hover\:border-right-0:hover {
    border-right: 0 solid;
  }
  .desktop\:border-bottom-0 {
    border-bottom: 0 solid;
  }
  .desktop\:hover\:border-bottom-0:hover {
    border-bottom: 0 solid;
  }
  .desktop\:border-left-0 {
    border-left: 0 solid;
  }
  .desktop\:hover\:border-left-0:hover {
    border-left: 0 solid;
  }
  .desktop\:border {
    border: 1px solid;
  }
  .desktop\:hover\:border:hover {
    border: 1px solid;
  }
  .desktop\:border-y {
    border-top: 1px solid;
    border-bottom: 1px solid;
  }
  .desktop\:hover\:border-y:hover {
    border-top: 1px solid;
    border-bottom: 1px solid;
  }
  .desktop\:border-x {
    border-left: 1px solid;
    border-right: 1px solid;
  }
  .desktop\:hover\:border-x:hover {
    border-left: 1px solid;
    border-right: 1px solid;
  }
  .desktop\:border-top {
    border-top: 1px solid;
  }
  .desktop\:hover\:border-top:hover {
    border-top: 1px solid;
  }
  .desktop\:border-right {
    border-right: 1px solid;
  }
  .desktop\:hover\:border-right:hover {
    border-right: 1px solid;
  }
  .desktop\:border-bottom {
    border-bottom: 1px solid;
  }
  .desktop\:hover\:border-bottom:hover {
    border-bottom: 1px solid;
  }
  .desktop\:border-left {
    border-left: 1px solid;
  }
  .desktop\:hover\:border-left:hover {
    border-left: 1px solid;
  }
  .desktop\:border-transparent {
    border-color: transparent;
  }
  .desktop\:hover\:border-transparent:hover {
    border-color: transparent;
  }
  .desktop\:border-black {
    border-color: black;
  }
  .desktop\:hover\:border-black:hover {
    border-color: black;
  }
  .desktop\:border-white {
    border-color: white;
  }
  .desktop\:hover\:border-white:hover {
    border-color: white;
  }
  .desktop\:border-red {
    border-color: #e52207;
  }
  .desktop\:hover\:border-red:hover {
    border-color: #e52207;
  }
  .desktop\:border-orange {
    border-color: #e66f0e;
  }
  .desktop\:hover\:border-orange:hover {
    border-color: #e66f0e;
  }
  .desktop\:border-gold {
    border-color: #ffbe2e;
  }
  .desktop\:hover\:border-gold:hover {
    border-color: #ffbe2e;
  }
  .desktop\:border-yellow {
    border-color: #fee685;
  }
  .desktop\:hover\:border-yellow:hover {
    border-color: #fee685;
  }
  .desktop\:border-green {
    border-color: #538200;
  }
  .desktop\:hover\:border-green:hover {
    border-color: #538200;
  }
  .desktop\:border-mint {
    border-color: #04c585;
  }
  .desktop\:hover\:border-mint:hover {
    border-color: #04c585;
  }
  .desktop\:border-cyan {
    border-color: #009ec1;
  }
  .desktop\:hover\:border-cyan:hover {
    border-color: #009ec1;
  }
  .desktop\:border-blue {
    border-color: #0076d6;
  }
  .desktop\:hover\:border-blue:hover {
    border-color: #0076d6;
  }
  .desktop\:border-indigo {
    border-color: #676cc8;
  }
  .desktop\:hover\:border-indigo:hover {
    border-color: #676cc8;
  }
  .desktop\:border-violet {
    border-color: #8168b3;
  }
  .desktop\:hover\:border-violet:hover {
    border-color: #8168b3;
  }
  .desktop\:border-magenta {
    border-color: #d72d79;
  }
  .desktop\:hover\:border-magenta:hover {
    border-color: #d72d79;
  }
  .desktop\:border-gray-5 {
    border-color: #f0f0f0;
  }
  .desktop\:hover\:border-gray-5:hover {
    border-color: #f0f0f0;
  }
  .desktop\:border-gray-10 {
    border-color: #e6e6e6;
  }
  .desktop\:hover\:border-gray-10:hover {
    border-color: #e6e6e6;
  }
  .desktop\:border-gray-30 {
    border-color: #adadad;
  }
  .desktop\:hover\:border-gray-30:hover {
    border-color: #adadad;
  }
  .desktop\:border-gray-50 {
    border-color: #757575;
  }
  .desktop\:hover\:border-gray-50:hover {
    border-color: #757575;
  }
  .desktop\:border-gray-70 {
    border-color: #454545;
  }
  .desktop\:hover\:border-gray-70:hover {
    border-color: #454545;
  }
  .desktop\:border-gray-90 {
    border-color: #1b1b1b;
  }
  .desktop\:hover\:border-gray-90:hover {
    border-color: #1b1b1b;
  }
  .desktop\:border-base-lightest {
    border-color: #f0f0f0;
  }
  .desktop\:hover\:border-base-lightest:hover {
    border-color: #f0f0f0;
  }
  .desktop\:border-base-lighter {
    border-color: #dfe1e2;
  }
  .desktop\:hover\:border-base-lighter:hover {
    border-color: #dfe1e2;
  }
  .desktop\:border-base-light {
    border-color: #a9aeb1;
  }
  .desktop\:hover\:border-base-light:hover {
    border-color: #a9aeb1;
  }
  .desktop\:border-base {
    border-color: #71767a;
  }
  .desktop\:hover\:border-base:hover {
    border-color: #71767a;
  }
  .desktop\:border-base-dark {
    border-color: #565c65;
  }
  .desktop\:hover\:border-base-dark:hover {
    border-color: #565c65;
  }
  .desktop\:border-base-darker {
    border-color: #3d4551;
  }
  .desktop\:hover\:border-base-darker:hover {
    border-color: #3d4551;
  }
  .desktop\:border-base-darkest {
    border-color: #1b1b1b;
  }
  .desktop\:hover\:border-base-darkest:hover {
    border-color: #1b1b1b;
  }
  .desktop\:border-ink {
    border-color: #11181d;
  }
  .desktop\:hover\:border-ink:hover {
    border-color: #11181d;
  }
  .desktop\:border-primary-lightest {
    border-color: #B3EFFF;
  }
  .desktop\:hover\:border-primary-lightest:hover {
    border-color: #B3EFFF;
  }
  .desktop\:border-primary-lighter {
    border-color: #97d4ea;
  }
  .desktop\:hover\:border-primary-lighter:hover {
    border-color: #97d4ea;
  }
  .desktop\:border-primary-light {
    border-color: #28a0cb;
  }
  .desktop\:hover\:border-primary-light:hover {
    border-color: #28a0cb;
  }
  .desktop\:border-primary {
    border-color: #046B99;
  }
  .desktop\:hover\:border-primary:hover {
    border-color: #046B99;
  }
  .desktop\:border-primary-vivid {
    border-color: #0050d8;
  }
  .desktop\:hover\:border-primary-vivid:hover {
    border-color: #0050d8;
  }
  .desktop\:border-primary-dark {
    border-color: #0b4778;
  }
  .desktop\:hover\:border-primary-dark:hover {
    border-color: #0b4778;
  }
  .desktop\:border-primary-darker {
    border-color: #1C304A;
  }
  .desktop\:hover\:border-primary-darker:hover {
    border-color: #1C304A;
  }
  .desktop\:border-primary-darkest {
    border-color: #11181d;
  }
  .desktop\:hover\:border-primary-darkest:hover {
    border-color: #11181d;
  }
  .desktop\:border-secondary-lighter {
    border-color: #f3e1e4;
  }
  .desktop\:hover\:border-secondary-lighter:hover {
    border-color: #f3e1e4;
  }
  .desktop\:border-secondary-light {
    border-color: #f2938c;
  }
  .desktop\:hover\:border-secondary-light:hover {
    border-color: #f2938c;
  }
  .desktop\:border-secondary {
    border-color: #d83933;
  }
  .desktop\:hover\:border-secondary:hover {
    border-color: #d83933;
  }
  .desktop\:border-secondary-vivid {
    border-color: #e41d3d;
  }
  .desktop\:hover\:border-secondary-vivid:hover {
    border-color: #e41d3d;
  }
  .desktop\:border-secondary-dark {
    border-color: #b50909;
  }
  .desktop\:hover\:border-secondary-dark:hover {
    border-color: #b50909;
  }
  .desktop\:border-secondary-darker {
    border-color: #8b0a03;
  }
  .desktop\:hover\:border-secondary-darker:hover {
    border-color: #8b0a03;
  }
  .desktop\:border-accent-warm-darker {
    border-color: #775540;
  }
  .desktop\:hover\:border-accent-warm-darker:hover {
    border-color: #775540;
  }
  .desktop\:border-accent-warm-dark {
    border-color: #c05600;
  }
  .desktop\:hover\:border-accent-warm-dark:hover {
    border-color: #c05600;
  }
  .desktop\:border-accent-warm {
    border-color: #fa9441;
  }
  .desktop\:hover\:border-accent-warm:hover {
    border-color: #fa9441;
  }
  .desktop\:border-accent-warm-light {
    border-color: #ffbc78;
  }
  .desktop\:hover\:border-accent-warm-light:hover {
    border-color: #ffbc78;
  }
  .desktop\:border-accent-warm-lighter {
    border-color: #f2e4d4;
  }
  .desktop\:hover\:border-accent-warm-lighter:hover {
    border-color: #f2e4d4;
  }
  .desktop\:border-accent-cool-darkest {
    border-color: #093b44;
  }
  .desktop\:hover\:border-accent-cool-darkest:hover {
    border-color: #093b44;
  }
  .desktop\:border-accent-cool-darker {
    border-color: #0e4f5c;
  }
  .desktop\:hover\:border-accent-cool-darker:hover {
    border-color: #0e4f5c;
  }
  .desktop\:border-accent-cool-dark {
    border-color: #0081a1;
  }
  .desktop\:hover\:border-accent-cool-dark:hover {
    border-color: #0081a1;
  }
  .desktop\:border-accent-cool {
    border-color: #00CFFF;
  }
  .desktop\:hover\:border-accent-cool:hover {
    border-color: #00CFFF;
  }
  .desktop\:border-accent-cool-light {
    border-color: #52daf2;
  }
  .desktop\:hover\:border-accent-cool-light:hover {
    border-color: #52daf2;
  }
  .desktop\:border-accent-cool-lighter {
    border-color: #a8f2ff;
  }
  .desktop\:hover\:border-accent-cool-lighter:hover {
    border-color: #a8f2ff;
  }
  .desktop\:border-accent-cool-lightest {
    border-color: #eff6fb;
  }
  .desktop\:hover\:border-accent-cool-lightest:hover {
    border-color: #eff6fb;
  }
  .desktop\:border-error-lighter {
    border-color: #fff5ee;
  }
  .desktop\:hover\:border-error-lighter:hover {
    border-color: #fff5ee;
  }
  .desktop\:border-error-light {
    border-color: #f39268;
  }
  .desktop\:hover\:border-error-light:hover {
    border-color: #f39268;
  }
  .desktop\:border-error {
    border-color: #d54309;
  }
  .desktop\:hover\:border-error:hover {
    border-color: #d54309;
  }
  .desktop\:border-error-dark {
    border-color: #b50909;
  }
  .desktop\:hover\:border-error-dark:hover {
    border-color: #b50909;
  }
  .desktop\:border-error-darker {
    border-color: #6f3331;
  }
  .desktop\:hover\:border-error-darker:hover {
    border-color: #6f3331;
  }
  .desktop\:border-warning-lighter {
    border-color: #faf3d1;
  }
  .desktop\:hover\:border-warning-lighter:hover {
    border-color: #faf3d1;
  }
  .desktop\:border-warning-light {
    border-color: #fee685;
  }
  .desktop\:hover\:border-warning-light:hover {
    border-color: #fee685;
  }
  .desktop\:border-warning {
    border-color: #ffbe2e;
  }
  .desktop\:hover\:border-warning:hover {
    border-color: #ffbe2e;
  }
  .desktop\:border-warning-dark {
    border-color: #e5a000;
  }
  .desktop\:hover\:border-warning-dark:hover {
    border-color: #e5a000;
  }
  .desktop\:border-warning-darker {
    border-color: #936f38;
  }
  .desktop\:hover\:border-warning-darker:hover {
    border-color: #936f38;
  }
  .desktop\:border-success-lighter {
    border-color: #ecf3ec;
  }
  .desktop\:hover\:border-success-lighter:hover {
    border-color: #ecf3ec;
  }
  .desktop\:border-success-light {
    border-color: #70e17b;
  }
  .desktop\:hover\:border-success-light:hover {
    border-color: #70e17b;
  }
  .desktop\:border-success {
    border-color: #00a91c;
  }
  .desktop\:hover\:border-success:hover {
    border-color: #00a91c;
  }
  .desktop\:border-success-dark {
    border-color: #008817;
  }
  .desktop\:hover\:border-success-dark:hover {
    border-color: #008817;
  }
  .desktop\:border-success-darker {
    border-color: #216e1f;
  }
  .desktop\:hover\:border-success-darker:hover {
    border-color: #216e1f;
  }
  .desktop\:border-info-lighter {
    border-color: #e7f6f8;
  }
  .desktop\:hover\:border-info-lighter:hover {
    border-color: #e7f6f8;
  }
  .desktop\:border-info-light {
    border-color: #99deea;
  }
  .desktop\:hover\:border-info-light:hover {
    border-color: #99deea;
  }
  .desktop\:border-info {
    border-color: #00bde3;
  }
  .desktop\:hover\:border-info:hover {
    border-color: #00bde3;
  }
  .desktop\:border-info-dark {
    border-color: #009ec1;
  }
  .desktop\:hover\:border-info-dark:hover {
    border-color: #009ec1;
  }
  .desktop\:border-info-darker {
    border-color: #2e6276;
  }
  .desktop\:hover\:border-info-darker:hover {
    border-color: #2e6276;
  }
  .desktop\:border-disabled-lighter {
    border-color: #c9c9c9;
  }
  .desktop\:hover\:border-disabled-lighter:hover {
    border-color: #c9c9c9;
  }
  .desktop\:border-disabled-light {
    border-color: #919191;
  }
  .desktop\:hover\:border-disabled-light:hover {
    border-color: #919191;
  }
  .desktop\:border-disabled {
    border-color: #757575;
  }
  .desktop\:hover\:border-disabled:hover {
    border-color: #757575;
  }
  .desktop\:border-disabled-dark {
    border-color: #454545;
  }
  .desktop\:hover\:border-disabled-dark:hover {
    border-color: #454545;
  }
  .desktop\:border-disabled-darker {
    border-color: #1b1b1b;
  }
  .desktop\:hover\:border-disabled-darker:hover {
    border-color: #1b1b1b;
  }
  .desktop\:border-emergency {
    border-color: #9c3d10;
  }
  .desktop\:hover\:border-emergency:hover {
    border-color: #9c3d10;
  }
  .desktop\:border-emergency-dark {
    border-color: #332d29;
  }
  .desktop\:hover\:border-emergency-dark:hover {
    border-color: #332d29;
  }
  .desktop\:radius-0 {
    border-radius: 0;
  }
  .desktop\:radius-top-0 {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .desktop\:radius-right-0 {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .desktop\:radius-bottom-0 {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .desktop\:radius-left-0 {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .desktop\:radius-sm {
    border-radius: 2px;
  }
  .desktop\:radius-top-sm {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }
  .desktop\:radius-right-sm {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  .desktop\:radius-bottom-sm {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  .desktop\:radius-left-sm {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  .desktop\:radius-md {
    border-radius: 0.25rem;
  }
  .desktop\:radius-top-md {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .desktop\:radius-right-md {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  .desktop\:radius-bottom-md {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  .desktop\:radius-left-md {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .desktop\:radius-lg {
    border-radius: 0.5rem;
  }
  .desktop\:radius-top-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  .desktop\:radius-right-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .desktop\:radius-bottom-lg {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .desktop\:radius-left-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .desktop\:radius-pill {
    border-radius: 99rem;
  }
  .desktop\:radius-top-pill {
    border-top-left-radius: 99rem;
    border-top-right-radius: 99rem;
  }
  .desktop\:radius-right-pill {
    border-top-right-radius: 99rem;
    border-bottom-right-radius: 99rem;
  }
  .desktop\:radius-bottom-pill {
    border-bottom-left-radius: 99rem;
    border-bottom-right-radius: 99rem;
  }
  .desktop\:radius-left-pill {
    border-top-left-radius: 99rem;
    border-bottom-left-radius: 99rem;
  }
  .desktop\:display-block {
    display: block;
  }
  .desktop\:display-flex {
    display: flex;
  }
  .desktop\:display-none {
    display: none;
  }
  .desktop\:display-inline {
    display: inline;
  }
  .desktop\:display-inline-block {
    display: inline-block;
  }
  .desktop\:display-inline-flex {
    display: inline-flex;
  }
  .desktop\:display-table {
    display: table;
  }
  .desktop\:display-table-cell {
    display: table-cell;
  }
  .desktop\:display-table-row {
    display: table-row;
  }
  .desktop\:font-mono-3xs {
    font-size: 0.77rem;
  }
  .desktop\:font-mono-2xs {
    font-size: 0.83rem;
  }
  .desktop\:font-mono-xs {
    font-size: 0.89rem;
  }
  .desktop\:font-mono-sm {
    font-size: 0.95rem;
  }
  .desktop\:font-mono-md {
    font-size: 1.01rem;
  }
  .desktop\:font-mono-lg {
    font-size: 1.31rem;
  }
  .desktop\:font-mono-xl {
    font-size: 1.91rem;
  }
  .desktop\:font-mono-2xl {
    font-size: 2.38rem;
  }
  .desktop\:font-mono-3xl {
    font-size: 2.86rem;
  }
  .desktop\:font-sans-3xs {
    font-size: 0.82rem;
  }
  .desktop\:font-sans-2xs {
    font-size: 0.89rem;
  }
  .desktop\:font-sans-xs {
    font-size: 0.95rem;
  }
  .desktop\:font-sans-sm {
    font-size: 1.01rem;
  }
  .desktop\:font-sans-md {
    font-size: 1.08rem;
  }
  .desktop\:font-sans-lg {
    font-size: 1.39rem;
  }
  .desktop\:font-sans-xl {
    font-size: 2.03rem;
  }
  .desktop\:font-sans-2xl {
    font-size: 2.54rem;
  }
  .desktop\:font-sans-3xl {
    font-size: 3.04rem;
  }
  .desktop\:font-serif-3xs {
    font-size: 0.79rem;
  }
  .desktop\:font-serif-2xs {
    font-size: 0.85rem;
  }
  .desktop\:font-serif-xs {
    font-size: 0.91rem;
  }
  .desktop\:font-serif-sm {
    font-size: 0.98rem;
  }
  .desktop\:font-serif-md {
    font-size: 1.04rem;
  }
  .desktop\:font-serif-lg {
    font-size: 1.34rem;
  }
  .desktop\:font-serif-xl {
    font-size: 1.95rem;
  }
  .desktop\:font-serif-2xl {
    font-size: 2.44rem;
  }
  .desktop\:font-serif-3xl {
    font-size: 2.93rem;
  }
  .desktop\:font-heading-3xs {
    font-size: 0.82rem;
  }
  .desktop\:font-heading-2xs {
    font-size: 0.89rem;
  }
  .desktop\:font-heading-xs {
    font-size: 0.95rem;
  }
  .desktop\:font-heading-sm {
    font-size: 1.01rem;
  }
  .desktop\:font-heading-md {
    font-size: 1.08rem;
  }
  .desktop\:font-heading-lg {
    font-size: 1.39rem;
  }
  .desktop\:font-heading-xl {
    font-size: 2.03rem;
  }
  .desktop\:font-heading-2xl {
    font-size: 2.54rem;
  }
  .desktop\:font-heading-3xl {
    font-size: 3.04rem;
  }
  .desktop\:font-body-3xs {
    font-size: 0.82rem;
  }
  .desktop\:font-body-2xs {
    font-size: 0.89rem;
  }
  .desktop\:font-body-xs {
    font-size: 0.95rem;
  }
  .desktop\:font-body-sm {
    font-size: 1.01rem;
  }
  .desktop\:font-body-md {
    font-size: 1.08rem;
  }
  .desktop\:font-body-lg {
    font-size: 1.39rem;
  }
  .desktop\:font-body-xl {
    font-size: 2.03rem;
  }
  .desktop\:font-body-2xl {
    font-size: 2.54rem;
  }
  .desktop\:font-body-3xl {
    font-size: 3.04rem;
  }
  .desktop\:font-code-3xs {
    font-size: 0.77rem;
  }
  .desktop\:font-code-2xs {
    font-size: 0.83rem;
  }
  .desktop\:font-code-xs {
    font-size: 0.89rem;
  }
  .desktop\:font-code-sm {
    font-size: 0.95rem;
  }
  .desktop\:font-code-md {
    font-size: 1.01rem;
  }
  .desktop\:font-code-lg {
    font-size: 1.31rem;
  }
  .desktop\:font-code-xl {
    font-size: 1.91rem;
  }
  .desktop\:font-code-2xl {
    font-size: 2.38rem;
  }
  .desktop\:font-code-3xl {
    font-size: 2.86rem;
  }
  .desktop\:font-alt-3xs {
    font-size: 0.79rem;
  }
  .desktop\:font-alt-2xs {
    font-size: 0.85rem;
  }
  .desktop\:font-alt-xs {
    font-size: 0.91rem;
  }
  .desktop\:font-alt-sm {
    font-size: 0.98rem;
  }
  .desktop\:font-alt-md {
    font-size: 1.04rem;
  }
  .desktop\:font-alt-lg {
    font-size: 1.34rem;
  }
  .desktop\:font-alt-xl {
    font-size: 1.95rem;
  }
  .desktop\:font-alt-2xl {
    font-size: 2.44rem;
  }
  .desktop\:font-alt-3xl {
    font-size: 2.93rem;
  }
  .desktop\:font-ui-3xs {
    font-size: 0.82rem;
  }
  .desktop\:font-ui-2xs {
    font-size: 0.89rem;
  }
  .desktop\:font-ui-xs {
    font-size: 0.95rem;
  }
  .desktop\:font-ui-sm {
    font-size: 1.01rem;
  }
  .desktop\:font-ui-md {
    font-size: 1.08rem;
  }
  .desktop\:font-ui-lg {
    font-size: 1.39rem;
  }
  .desktop\:font-ui-xl {
    font-size: 2.03rem;
  }
  .desktop\:font-ui-2xl {
    font-size: 2.54rem;
  }
  .desktop\:font-ui-3xl {
    font-size: 3.04rem;
  }
  .desktop\:text-light {
    font-weight: 300;
  }
  .desktop\:text-normal {
    font-weight: normal;
  }
  .desktop\:text-semibold {
    font-weight: 700;
  }
  .desktop\:text-bold {
    font-weight: 700;
  }
  .desktop\:flex-justify-center {
    justify-content: center;
  }
  .desktop\:flex-justify-start {
    justify-content: flex-start;
  }
  .desktop\:flex-justify-end {
    justify-content: flex-end;
  }
  .desktop\:flex-justify {
    justify-content: space-between;
  }
  .desktop\:line-height-sans-1 {
    line-height: 1;
  }
  .desktop\:line-height-sans-2 {
    line-height: 1.2;
  }
  .desktop\:line-height-sans-3 {
    line-height: 1.3;
  }
  .desktop\:line-height-sans-4 {
    line-height: 1.5;
  }
  .desktop\:line-height-sans-5 {
    line-height: 1.6;
  }
  .desktop\:line-height-sans-6 {
    line-height: 1.7;
  }
  .desktop\:line-height-serif-1 {
    line-height: 1;
  }
  .desktop\:line-height-serif-2 {
    line-height: 1.2;
  }
  .desktop\:line-height-serif-3 {
    line-height: 1.4;
  }
  .desktop\:line-height-serif-4 {
    line-height: 1.5;
  }
  .desktop\:line-height-serif-5 {
    line-height: 1.7;
  }
  .desktop\:line-height-serif-6 {
    line-height: 1.8;
  }
  .desktop\:line-height-mono-1 {
    line-height: 1;
  }
  .desktop\:line-height-mono-2 {
    line-height: 1.3;
  }
  .desktop\:line-height-mono-3 {
    line-height: 1.4;
  }
  .desktop\:line-height-mono-4 {
    line-height: 1.6;
  }
  .desktop\:line-height-mono-5 {
    line-height: 1.7;
  }
  .desktop\:line-height-mono-6 {
    line-height: 1.8;
  }
  .desktop\:line-height-heading-1 {
    line-height: 1;
  }
  .desktop\:line-height-heading-2 {
    line-height: 1.2;
  }
  .desktop\:line-height-heading-3 {
    line-height: 1.3;
  }
  .desktop\:line-height-heading-4 {
    line-height: 1.5;
  }
  .desktop\:line-height-heading-5 {
    line-height: 1.6;
  }
  .desktop\:line-height-heading-6 {
    line-height: 1.7;
  }
  .desktop\:line-height-ui-1 {
    line-height: 1;
  }
  .desktop\:line-height-ui-2 {
    line-height: 1.2;
  }
  .desktop\:line-height-ui-3 {
    line-height: 1.3;
  }
  .desktop\:line-height-ui-4 {
    line-height: 1.5;
  }
  .desktop\:line-height-ui-5 {
    line-height: 1.6;
  }
  .desktop\:line-height-ui-6 {
    line-height: 1.7;
  }
  .desktop\:line-height-body-1 {
    line-height: 1;
  }
  .desktop\:line-height-body-2 {
    line-height: 1.2;
  }
  .desktop\:line-height-body-3 {
    line-height: 1.3;
  }
  .desktop\:line-height-body-4 {
    line-height: 1.5;
  }
  .desktop\:line-height-body-5 {
    line-height: 1.6;
  }
  .desktop\:line-height-body-6 {
    line-height: 1.7;
  }
  .desktop\:line-height-code-1 {
    line-height: 1;
  }
  .desktop\:line-height-code-2 {
    line-height: 1.3;
  }
  .desktop\:line-height-code-3 {
    line-height: 1.4;
  }
  .desktop\:line-height-code-4 {
    line-height: 1.6;
  }
  .desktop\:line-height-code-5 {
    line-height: 1.7;
  }
  .desktop\:line-height-code-6 {
    line-height: 1.8;
  }
  .desktop\:line-height-alt-1 {
    line-height: 1;
  }
  .desktop\:line-height-alt-2 {
    line-height: 1.2;
  }
  .desktop\:line-height-alt-3 {
    line-height: 1.4;
  }
  .desktop\:line-height-alt-4 {
    line-height: 1.5;
  }
  .desktop\:line-height-alt-5 {
    line-height: 1.7;
  }
  .desktop\:line-height-alt-6 {
    line-height: 1.8;
  }
  .desktop\:margin-neg-1px {
    margin: -1px;
  }
  .desktop\:margin-neg-2px {
    margin: -2px;
  }
  .desktop\:margin-neg-05 {
    margin: -0.25rem;
  }
  .desktop\:margin-neg-1 {
    margin: -0.5rem;
  }
  .desktop\:margin-neg-105 {
    margin: -0.75rem;
  }
  .desktop\:margin-neg-2 {
    margin: -1rem;
  }
  .desktop\:margin-neg-205 {
    margin: -1.25rem;
  }
  .desktop\:margin-neg-3 {
    margin: -1.5rem;
  }
  .desktop\:margin-1px {
    margin: 1px;
  }
  .desktop\:margin-2px {
    margin: 2px;
  }
  .desktop\:margin-05 {
    margin: 0.25rem;
  }
  .desktop\:margin-1 {
    margin: 0.5rem;
  }
  .desktop\:margin-105 {
    margin: 0.75rem;
  }
  .desktop\:margin-2 {
    margin: 1rem;
  }
  .desktop\:margin-205 {
    margin: 1.25rem;
  }
  .desktop\:margin-3 {
    margin: 1.5rem;
  }
  .desktop\:margin-neg-4 {
    margin: -2rem;
  }
  .desktop\:margin-neg-5 {
    margin: -2.5rem;
  }
  .desktop\:margin-neg-6 {
    margin: -3rem;
  }
  .desktop\:margin-neg-7 {
    margin: -3.5rem;
  }
  .desktop\:margin-neg-8 {
    margin: -4rem;
  }
  .desktop\:margin-neg-9 {
    margin: -4.5rem;
  }
  .desktop\:margin-neg-10 {
    margin: -5rem;
  }
  .desktop\:margin-neg-15 {
    margin: -7.5rem;
  }
  .desktop\:margin-4 {
    margin: 2rem;
  }
  .desktop\:margin-5 {
    margin: 2.5rem;
  }
  .desktop\:margin-6 {
    margin: 3rem;
  }
  .desktop\:margin-7 {
    margin: 3.5rem;
  }
  .desktop\:margin-8 {
    margin: 4rem;
  }
  .desktop\:margin-9 {
    margin: 4.5rem;
  }
  .desktop\:margin-10 {
    margin: 5rem;
  }
  .desktop\:margin-15 {
    margin: 7.5rem;
  }
  .desktop\:margin-05em {
    margin: 0.5em;
  }
  .desktop\:margin-1em {
    margin: 1em;
  }
  .desktop\:margin-105em {
    margin: 1.5em;
  }
  .desktop\:margin-2em {
    margin: 2em;
  }
  .desktop\:margin-0 {
    margin: 0;
  }
  .desktop\:margin-y-1px {
    margin-top: 1px;
    margin-bottom: 1px;
  }
  .desktop\:margin-top-1px {
    margin-top: 1px;
  }
  .desktop\:margin-bottom-1px {
    margin-bottom: 1px;
  }
  .desktop\:margin-y-2px {
    margin-top: 2px;
    margin-bottom: 2px;
  }
  .desktop\:margin-top-2px {
    margin-top: 2px;
  }
  .desktop\:margin-bottom-2px {
    margin-bottom: 2px;
  }
  .desktop\:margin-y-05 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .desktop\:margin-top-05 {
    margin-top: 0.25rem;
  }
  .desktop\:margin-bottom-05 {
    margin-bottom: 0.25rem;
  }
  .desktop\:margin-y-1 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .desktop\:margin-top-1 {
    margin-top: 0.5rem;
  }
  .desktop\:margin-bottom-1 {
    margin-bottom: 0.5rem;
  }
  .desktop\:margin-y-105 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  .desktop\:margin-top-105 {
    margin-top: 0.75rem;
  }
  .desktop\:margin-bottom-105 {
    margin-bottom: 0.75rem;
  }
  .desktop\:margin-y-2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .desktop\:margin-top-2 {
    margin-top: 1rem;
  }
  .desktop\:margin-bottom-2 {
    margin-bottom: 1rem;
  }
  .desktop\:margin-y-205 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .desktop\:margin-top-205 {
    margin-top: 1.25rem;
  }
  .desktop\:margin-bottom-205 {
    margin-bottom: 1.25rem;
  }
  .desktop\:margin-y-3 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .desktop\:margin-top-3 {
    margin-top: 1.5rem;
  }
  .desktop\:margin-bottom-3 {
    margin-bottom: 1.5rem;
  }
  .desktop\:margin-y-neg-1px {
    margin-top: -1px;
    margin-bottom: -1px;
  }
  .desktop\:margin-top-neg-1px {
    margin-top: -1px;
  }
  .desktop\:margin-bottom-neg-1px {
    margin-bottom: -1px;
  }
  .desktop\:margin-y-neg-2px {
    margin-top: -2px;
    margin-bottom: -2px;
  }
  .desktop\:margin-top-neg-2px {
    margin-top: -2px;
  }
  .desktop\:margin-bottom-neg-2px {
    margin-bottom: -2px;
  }
  .desktop\:margin-y-neg-05 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .desktop\:margin-top-neg-05 {
    margin-top: -0.25rem;
  }
  .desktop\:margin-bottom-neg-05 {
    margin-bottom: -0.25rem;
  }
  .desktop\:margin-y-neg-1 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .desktop\:margin-top-neg-1 {
    margin-top: -0.5rem;
  }
  .desktop\:margin-bottom-neg-1 {
    margin-bottom: -0.5rem;
  }
  .desktop\:margin-y-neg-105 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }
  .desktop\:margin-top-neg-105 {
    margin-top: -0.75rem;
  }
  .desktop\:margin-bottom-neg-105 {
    margin-bottom: -0.75rem;
  }
  .desktop\:margin-y-neg-2 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .desktop\:margin-top-neg-2 {
    margin-top: -1rem;
  }
  .desktop\:margin-bottom-neg-2 {
    margin-bottom: -1rem;
  }
  .desktop\:margin-y-neg-205 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }
  .desktop\:margin-top-neg-205 {
    margin-top: -1.25rem;
  }
  .desktop\:margin-bottom-neg-205 {
    margin-bottom: -1.25rem;
  }
  .desktop\:margin-y-neg-3 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .desktop\:margin-top-neg-3 {
    margin-top: -1.5rem;
  }
  .desktop\:margin-bottom-neg-3 {
    margin-bottom: -1.5rem;
  }
  .desktop\:margin-y-neg-4 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }
  .desktop\:margin-top-neg-4 {
    margin-top: -2rem;
  }
  .desktop\:margin-bottom-neg-4 {
    margin-bottom: -2rem;
  }
  .desktop\:margin-y-neg-5 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }
  .desktop\:margin-top-neg-5 {
    margin-top: -2.5rem;
  }
  .desktop\:margin-bottom-neg-5 {
    margin-bottom: -2.5rem;
  }
  .desktop\:margin-y-neg-6 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .desktop\:margin-top-neg-6 {
    margin-top: -3rem;
  }
  .desktop\:margin-bottom-neg-6 {
    margin-bottom: -3rem;
  }
  .desktop\:margin-y-neg-7 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }
  .desktop\:margin-top-neg-7 {
    margin-top: -3.5rem;
  }
  .desktop\:margin-bottom-neg-7 {
    margin-bottom: -3.5rem;
  }
  .desktop\:margin-y-neg-8 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }
  .desktop\:margin-top-neg-8 {
    margin-top: -4rem;
  }
  .desktop\:margin-bottom-neg-8 {
    margin-bottom: -4rem;
  }
  .desktop\:margin-y-neg-9 {
    margin-top: -4.5rem;
    margin-bottom: -4.5rem;
  }
  .desktop\:margin-top-neg-9 {
    margin-top: -4.5rem;
  }
  .desktop\:margin-bottom-neg-9 {
    margin-bottom: -4.5rem;
  }
  .desktop\:margin-y-neg-10 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }
  .desktop\:margin-top-neg-10 {
    margin-top: -5rem;
  }
  .desktop\:margin-bottom-neg-10 {
    margin-bottom: -5rem;
  }
  .desktop\:margin-y-neg-15 {
    margin-top: -7.5rem;
    margin-bottom: -7.5rem;
  }
  .desktop\:margin-top-neg-15 {
    margin-top: -7.5rem;
  }
  .desktop\:margin-bottom-neg-15 {
    margin-bottom: -7.5rem;
  }
  .desktop\:margin-y-4 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .desktop\:margin-top-4 {
    margin-top: 2rem;
  }
  .desktop\:margin-bottom-4 {
    margin-bottom: 2rem;
  }
  .desktop\:margin-y-5 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .desktop\:margin-top-5 {
    margin-top: 2.5rem;
  }
  .desktop\:margin-bottom-5 {
    margin-bottom: 2.5rem;
  }
  .desktop\:margin-y-6 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .desktop\:margin-top-6 {
    margin-top: 3rem;
  }
  .desktop\:margin-bottom-6 {
    margin-bottom: 3rem;
  }
  .desktop\:margin-y-7 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
  .desktop\:margin-top-7 {
    margin-top: 3.5rem;
  }
  .desktop\:margin-bottom-7 {
    margin-bottom: 3.5rem;
  }
  .desktop\:margin-y-8 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .desktop\:margin-top-8 {
    margin-top: 4rem;
  }
  .desktop\:margin-bottom-8 {
    margin-bottom: 4rem;
  }
  .desktop\:margin-y-9 {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }
  .desktop\:margin-top-9 {
    margin-top: 4.5rem;
  }
  .desktop\:margin-bottom-9 {
    margin-bottom: 4.5rem;
  }
  .desktop\:margin-y-10 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .desktop\:margin-top-10 {
    margin-top: 5rem;
  }
  .desktop\:margin-bottom-10 {
    margin-bottom: 5rem;
  }
  .desktop\:margin-y-15 {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem;
  }
  .desktop\:margin-top-15 {
    margin-top: 7.5rem;
  }
  .desktop\:margin-bottom-15 {
    margin-bottom: 7.5rem;
  }
  .desktop\:margin-y-05em {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  .desktop\:margin-top-05em {
    margin-top: 0.5em;
  }
  .desktop\:margin-bottom-05em {
    margin-bottom: 0.5em;
  }
  .desktop\:margin-y-1em {
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .desktop\:margin-top-1em {
    margin-top: 1em;
  }
  .desktop\:margin-bottom-1em {
    margin-bottom: 1em;
  }
  .desktop\:margin-y-105em {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }
  .desktop\:margin-top-105em {
    margin-top: 1.5em;
  }
  .desktop\:margin-bottom-105em {
    margin-bottom: 1.5em;
  }
  .desktop\:margin-y-2em {
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .desktop\:margin-top-2em {
    margin-top: 2em;
  }
  .desktop\:margin-bottom-2em {
    margin-bottom: 2em;
  }
  .desktop\:margin-y-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .desktop\:margin-top-0 {
    margin-top: 0;
  }
  .desktop\:margin-bottom-0 {
    margin-bottom: 0;
  }
  .desktop\:margin-y-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .desktop\:margin-top-auto {
    margin-top: auto;
  }
  .desktop\:margin-bottom-auto {
    margin-bottom: auto;
  }
  .desktop\:margin-x-1px {
    margin-left: 1px;
    margin-right: 1px;
  }
  .desktop\:margin-right-1px {
    margin-right: 1px;
  }
  .desktop\:margin-left-1px {
    margin-left: 1px;
  }
  .desktop\:margin-x-2px {
    margin-left: 2px;
    margin-right: 2px;
  }
  .desktop\:margin-right-2px {
    margin-right: 2px;
  }
  .desktop\:margin-left-2px {
    margin-left: 2px;
  }
  .desktop\:margin-x-05 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .desktop\:margin-right-05 {
    margin-right: 0.25rem;
  }
  .desktop\:margin-left-05 {
    margin-left: 0.25rem;
  }
  .desktop\:margin-x-1 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .desktop\:margin-right-1 {
    margin-right: 0.5rem;
  }
  .desktop\:margin-left-1 {
    margin-left: 0.5rem;
  }
  .desktop\:margin-x-105 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
  .desktop\:margin-right-105 {
    margin-right: 0.75rem;
  }
  .desktop\:margin-left-105 {
    margin-left: 0.75rem;
  }
  .desktop\:margin-x-2 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .desktop\:margin-right-2 {
    margin-right: 1rem;
  }
  .desktop\:margin-left-2 {
    margin-left: 1rem;
  }
  .desktop\:margin-x-205 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
  .desktop\:margin-right-205 {
    margin-right: 1.25rem;
  }
  .desktop\:margin-left-205 {
    margin-left: 1.25rem;
  }
  .desktop\:margin-x-3 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .desktop\:margin-right-3 {
    margin-right: 1.5rem;
  }
  .desktop\:margin-left-3 {
    margin-left: 1.5rem;
  }
  .desktop\:margin-x-neg-1px {
    margin-left: -1px;
    margin-right: -1px;
  }
  .desktop\:margin-right-neg-1px {
    margin-right: -1px;
  }
  .desktop\:margin-left-neg-1px {
    margin-left: -1px;
  }
  .desktop\:margin-x-neg-2px {
    margin-left: -2px;
    margin-right: -2px;
  }
  .desktop\:margin-right-neg-2px {
    margin-right: -2px;
  }
  .desktop\:margin-left-neg-2px {
    margin-left: -2px;
  }
  .desktop\:margin-x-neg-05 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .desktop\:margin-right-neg-05 {
    margin-right: -0.25rem;
  }
  .desktop\:margin-left-neg-05 {
    margin-left: -0.25rem;
  }
  .desktop\:margin-x-neg-1 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .desktop\:margin-right-neg-1 {
    margin-right: -0.5rem;
  }
  .desktop\:margin-left-neg-1 {
    margin-left: -0.5rem;
  }
  .desktop\:margin-x-neg-105 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .desktop\:margin-right-neg-105 {
    margin-right: -0.75rem;
  }
  .desktop\:margin-left-neg-105 {
    margin-left: -0.75rem;
  }
  .desktop\:margin-x-neg-2 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .desktop\:margin-right-neg-2 {
    margin-right: -1rem;
  }
  .desktop\:margin-left-neg-2 {
    margin-left: -1rem;
  }
  .desktop\:margin-x-neg-205 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
  .desktop\:margin-right-neg-205 {
    margin-right: -1.25rem;
  }
  .desktop\:margin-left-neg-205 {
    margin-left: -1.25rem;
  }
  .desktop\:margin-x-neg-3 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .desktop\:margin-right-neg-3 {
    margin-right: -1.5rem;
  }
  .desktop\:margin-left-neg-3 {
    margin-left: -1.5rem;
  }
  .desktop\:margin-x-neg-4 {
    margin-left: -2rem;
    margin-right: -2rem;
  }
  .desktop\:margin-right-neg-4 {
    margin-right: -2rem;
  }
  .desktop\:margin-left-neg-4 {
    margin-left: -2rem;
  }
  .desktop\:margin-x-neg-5 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }
  .desktop\:margin-right-neg-5 {
    margin-right: -2.5rem;
  }
  .desktop\:margin-left-neg-5 {
    margin-left: -2.5rem;
  }
  .desktop\:margin-x-neg-6 {
    margin-left: -3rem;
    margin-right: -3rem;
  }
  .desktop\:margin-right-neg-6 {
    margin-right: -3rem;
  }
  .desktop\:margin-left-neg-6 {
    margin-left: -3rem;
  }
  .desktop\:margin-x-neg-7 {
    margin-left: -3.5rem;
    margin-right: -3.5rem;
  }
  .desktop\:margin-right-neg-7 {
    margin-right: -3.5rem;
  }
  .desktop\:margin-left-neg-7 {
    margin-left: -3.5rem;
  }
  .desktop\:margin-x-neg-8 {
    margin-left: -4rem;
    margin-right: -4rem;
  }
  .desktop\:margin-right-neg-8 {
    margin-right: -4rem;
  }
  .desktop\:margin-left-neg-8 {
    margin-left: -4rem;
  }
  .desktop\:margin-x-neg-9 {
    margin-left: -4.5rem;
    margin-right: -4.5rem;
  }
  .desktop\:margin-right-neg-9 {
    margin-right: -4.5rem;
  }
  .desktop\:margin-left-neg-9 {
    margin-left: -4.5rem;
  }
  .desktop\:margin-x-neg-10 {
    margin-left: -5rem;
    margin-right: -5rem;
  }
  .desktop\:margin-right-neg-10 {
    margin-right: -5rem;
  }
  .desktop\:margin-left-neg-10 {
    margin-left: -5rem;
  }
  .desktop\:margin-x-neg-15 {
    margin-left: -7.5rem;
    margin-right: -7.5rem;
  }
  .desktop\:margin-right-neg-15 {
    margin-right: -7.5rem;
  }
  .desktop\:margin-left-neg-15 {
    margin-left: -7.5rem;
  }
  .desktop\:margin-x-4 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .desktop\:margin-right-4 {
    margin-right: 2rem;
  }
  .desktop\:margin-left-4 {
    margin-left: 2rem;
  }
  .desktop\:margin-x-5 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
  .desktop\:margin-right-5 {
    margin-right: 2.5rem;
  }
  .desktop\:margin-left-5 {
    margin-left: 2.5rem;
  }
  .desktop\:margin-x-6 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .desktop\:margin-right-6 {
    margin-right: 3rem;
  }
  .desktop\:margin-left-6 {
    margin-left: 3rem;
  }
  .desktop\:margin-x-7 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }
  .desktop\:margin-right-7 {
    margin-right: 3.5rem;
  }
  .desktop\:margin-left-7 {
    margin-left: 3.5rem;
  }
  .desktop\:margin-x-8 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .desktop\:margin-right-8 {
    margin-right: 4rem;
  }
  .desktop\:margin-left-8 {
    margin-left: 4rem;
  }
  .desktop\:margin-x-9 {
    margin-left: 4.5rem;
    margin-right: 4.5rem;
  }
  .desktop\:margin-right-9 {
    margin-right: 4.5rem;
  }
  .desktop\:margin-left-9 {
    margin-left: 4.5rem;
  }
  .desktop\:margin-x-10 {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .desktop\:margin-right-10 {
    margin-right: 5rem;
  }
  .desktop\:margin-left-10 {
    margin-left: 5rem;
  }
  .desktop\:margin-x-15 {
    margin-left: 7.5rem;
    margin-right: 7.5rem;
  }
  .desktop\:margin-right-15 {
    margin-right: 7.5rem;
  }
  .desktop\:margin-left-15 {
    margin-left: 7.5rem;
  }
  .desktop\:margin-x-card {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  .desktop\:margin-right-card {
    margin-right: 10rem;
  }
  .desktop\:margin-left-card {
    margin-left: 10rem;
  }
  .desktop\:margin-x-card-lg {
    margin-left: 15rem;
    margin-right: 15rem;
  }
  .desktop\:margin-right-card-lg {
    margin-right: 15rem;
  }
  .desktop\:margin-left-card-lg {
    margin-left: 15rem;
  }
  .desktop\:margin-x-mobile {
    margin-left: 20rem;
    margin-right: 20rem;
  }
  .desktop\:margin-right-mobile {
    margin-right: 20rem;
  }
  .desktop\:margin-left-mobile {
    margin-left: 20rem;
  }
  .desktop\:margin-x-05em {
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
  .desktop\:margin-right-05em {
    margin-right: 0.5em;
  }
  .desktop\:margin-left-05em {
    margin-left: 0.5em;
  }
  .desktop\:margin-x-1em {
    margin-left: 1em;
    margin-right: 1em;
  }
  .desktop\:margin-right-1em {
    margin-right: 1em;
  }
  .desktop\:margin-left-1em {
    margin-left: 1em;
  }
  .desktop\:margin-x-105em {
    margin-left: 1.5em;
    margin-right: 1.5em;
  }
  .desktop\:margin-right-105em {
    margin-right: 1.5em;
  }
  .desktop\:margin-left-105em {
    margin-left: 1.5em;
  }
  .desktop\:margin-x-2em {
    margin-left: 2em;
    margin-right: 2em;
  }
  .desktop\:margin-right-2em {
    margin-right: 2em;
  }
  .desktop\:margin-left-2em {
    margin-left: 2em;
  }
  .desktop\:margin-x-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .desktop\:margin-right-0 {
    margin-right: 0;
  }
  .desktop\:margin-left-0 {
    margin-left: 0;
  }
  .desktop\:margin-x-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .desktop\:margin-right-auto {
    margin-right: auto;
  }
  .desktop\:margin-left-auto {
    margin-left: auto;
  }
  .desktop\:measure-1 {
    max-width: 44ex;
  }
  .desktop\:measure-2 {
    max-width: 60ex;
  }
  .desktop\:measure-3 {
    max-width: 64ex;
  }
  .desktop\:measure-4 {
    max-width: 68ex;
  }
  .desktop\:measure-5 {
    max-width: 72ex;
  }
  .desktop\:measure-6 {
    max-width: 88ex;
  }
  .desktop\:measure-none {
    max-width: none;
  }
  .desktop\:order-first {
    order: -1;
  }
  .desktop\:order-last {
    order: 999;
  }
  .desktop\:order-initial {
    order: initial;
  }
  .desktop\:order-0 {
    order: 0;
  }
  .desktop\:order-1 {
    order: 1;
  }
  .desktop\:order-2 {
    order: 2;
  }
  .desktop\:order-3 {
    order: 3;
  }
  .desktop\:order-4 {
    order: 4;
  }
  .desktop\:order-5 {
    order: 5;
  }
  .desktop\:order-6 {
    order: 6;
  }
  .desktop\:order-7 {
    order: 7;
  }
  .desktop\:order-8 {
    order: 8;
  }
  .desktop\:order-9 {
    order: 9;
  }
  .desktop\:order-10 {
    order: 10;
  }
  .desktop\:order-11 {
    order: 11;
  }
  .desktop\:padding-1px {
    padding: 1px;
  }
  .desktop\:padding-y-1px {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .desktop\:padding-x-1px {
    padding-left: 1px;
    padding-right: 1px;
  }
  .desktop\:padding-top-1px {
    padding-top: 1px;
  }
  .desktop\:padding-right-1px {
    padding-right: 1px;
  }
  .desktop\:padding-bottom-1px {
    padding-bottom: 1px;
  }
  .desktop\:padding-left-1px {
    padding-left: 1px;
  }
  .desktop\:padding-2px {
    padding: 2px;
  }
  .desktop\:padding-y-2px {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .desktop\:padding-x-2px {
    padding-left: 2px;
    padding-right: 2px;
  }
  .desktop\:padding-top-2px {
    padding-top: 2px;
  }
  .desktop\:padding-right-2px {
    padding-right: 2px;
  }
  .desktop\:padding-bottom-2px {
    padding-bottom: 2px;
  }
  .desktop\:padding-left-2px {
    padding-left: 2px;
  }
  .desktop\:padding-05 {
    padding: 0.25rem;
  }
  .desktop\:padding-y-05 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .desktop\:padding-x-05 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .desktop\:padding-top-05 {
    padding-top: 0.25rem;
  }
  .desktop\:padding-right-05 {
    padding-right: 0.25rem;
  }
  .desktop\:padding-bottom-05 {
    padding-bottom: 0.25rem;
  }
  .desktop\:padding-left-05 {
    padding-left: 0.25rem;
  }
  .desktop\:padding-1 {
    padding: 0.5rem;
  }
  .desktop\:padding-y-1 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .desktop\:padding-x-1 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .desktop\:padding-top-1 {
    padding-top: 0.5rem;
  }
  .desktop\:padding-right-1 {
    padding-right: 0.5rem;
  }
  .desktop\:padding-bottom-1 {
    padding-bottom: 0.5rem;
  }
  .desktop\:padding-left-1 {
    padding-left: 0.5rem;
  }
  .desktop\:padding-105 {
    padding: 0.75rem;
  }
  .desktop\:padding-y-105 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .desktop\:padding-x-105 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .desktop\:padding-top-105 {
    padding-top: 0.75rem;
  }
  .desktop\:padding-right-105 {
    padding-right: 0.75rem;
  }
  .desktop\:padding-bottom-105 {
    padding-bottom: 0.75rem;
  }
  .desktop\:padding-left-105 {
    padding-left: 0.75rem;
  }
  .desktop\:padding-2 {
    padding: 1rem;
  }
  .desktop\:padding-y-2 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .desktop\:padding-x-2 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .desktop\:padding-top-2 {
    padding-top: 1rem;
  }
  .desktop\:padding-right-2 {
    padding-right: 1rem;
  }
  .desktop\:padding-bottom-2 {
    padding-bottom: 1rem;
  }
  .desktop\:padding-left-2 {
    padding-left: 1rem;
  }
  .desktop\:padding-205 {
    padding: 1.25rem;
  }
  .desktop\:padding-y-205 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .desktop\:padding-x-205 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .desktop\:padding-top-205 {
    padding-top: 1.25rem;
  }
  .desktop\:padding-right-205 {
    padding-right: 1.25rem;
  }
  .desktop\:padding-bottom-205 {
    padding-bottom: 1.25rem;
  }
  .desktop\:padding-left-205 {
    padding-left: 1.25rem;
  }
  .desktop\:padding-3 {
    padding: 1.5rem;
  }
  .desktop\:padding-y-3 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .desktop\:padding-x-3 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .desktop\:padding-top-3 {
    padding-top: 1.5rem;
  }
  .desktop\:padding-right-3 {
    padding-right: 1.5rem;
  }
  .desktop\:padding-bottom-3 {
    padding-bottom: 1.5rem;
  }
  .desktop\:padding-left-3 {
    padding-left: 1.5rem;
  }
  .desktop\:padding-4 {
    padding: 2rem;
  }
  .desktop\:padding-y-4 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .desktop\:padding-x-4 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .desktop\:padding-top-4 {
    padding-top: 2rem;
  }
  .desktop\:padding-right-4 {
    padding-right: 2rem;
  }
  .desktop\:padding-bottom-4 {
    padding-bottom: 2rem;
  }
  .desktop\:padding-left-4 {
    padding-left: 2rem;
  }
  .desktop\:padding-5 {
    padding: 2.5rem;
  }
  .desktop\:padding-y-5 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .desktop\:padding-x-5 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .desktop\:padding-top-5 {
    padding-top: 2.5rem;
  }
  .desktop\:padding-right-5 {
    padding-right: 2.5rem;
  }
  .desktop\:padding-bottom-5 {
    padding-bottom: 2.5rem;
  }
  .desktop\:padding-left-5 {
    padding-left: 2.5rem;
  }
  .desktop\:padding-6 {
    padding: 3rem;
  }
  .desktop\:padding-y-6 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .desktop\:padding-x-6 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .desktop\:padding-top-6 {
    padding-top: 3rem;
  }
  .desktop\:padding-right-6 {
    padding-right: 3rem;
  }
  .desktop\:padding-bottom-6 {
    padding-bottom: 3rem;
  }
  .desktop\:padding-left-6 {
    padding-left: 3rem;
  }
  .desktop\:padding-7 {
    padding: 3.5rem;
  }
  .desktop\:padding-y-7 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .desktop\:padding-x-7 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
  .desktop\:padding-top-7 {
    padding-top: 3.5rem;
  }
  .desktop\:padding-right-7 {
    padding-right: 3.5rem;
  }
  .desktop\:padding-bottom-7 {
    padding-bottom: 3.5rem;
  }
  .desktop\:padding-left-7 {
    padding-left: 3.5rem;
  }
  .desktop\:padding-8 {
    padding: 4rem;
  }
  .desktop\:padding-y-8 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .desktop\:padding-x-8 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .desktop\:padding-top-8 {
    padding-top: 4rem;
  }
  .desktop\:padding-right-8 {
    padding-right: 4rem;
  }
  .desktop\:padding-bottom-8 {
    padding-bottom: 4rem;
  }
  .desktop\:padding-left-8 {
    padding-left: 4rem;
  }
  .desktop\:padding-9 {
    padding: 4.5rem;
  }
  .desktop\:padding-y-9 {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }
  .desktop\:padding-x-9 {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }
  .desktop\:padding-top-9 {
    padding-top: 4.5rem;
  }
  .desktop\:padding-right-9 {
    padding-right: 4.5rem;
  }
  .desktop\:padding-bottom-9 {
    padding-bottom: 4.5rem;
  }
  .desktop\:padding-left-9 {
    padding-left: 4.5rem;
  }
  .desktop\:padding-10 {
    padding: 5rem;
  }
  .desktop\:padding-y-10 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .desktop\:padding-x-10 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .desktop\:padding-top-10 {
    padding-top: 5rem;
  }
  .desktop\:padding-right-10 {
    padding-right: 5rem;
  }
  .desktop\:padding-bottom-10 {
    padding-bottom: 5rem;
  }
  .desktop\:padding-left-10 {
    padding-left: 5rem;
  }
  .desktop\:padding-15 {
    padding: 7.5rem;
  }
  .desktop\:padding-y-15 {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
  .desktop\:padding-x-15 {
    padding-left: 7.5rem;
    padding-right: 7.5rem;
  }
  .desktop\:padding-top-15 {
    padding-top: 7.5rem;
  }
  .desktop\:padding-right-15 {
    padding-right: 7.5rem;
  }
  .desktop\:padding-bottom-15 {
    padding-bottom: 7.5rem;
  }
  .desktop\:padding-left-15 {
    padding-left: 7.5rem;
  }
  .desktop\:padding-0 {
    padding: 0;
  }
  .desktop\:padding-y-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .desktop\:padding-x-0 {
    padding-left: 0;
    padding-right: 0;
  }
  .desktop\:padding-top-0 {
    padding-top: 0;
  }
  .desktop\:padding-right-0 {
    padding-right: 0;
  }
  .desktop\:padding-bottom-0 {
    padding-bottom: 0;
  }
  .desktop\:padding-left-0 {
    padding-left: 0;
  }
  .desktop\:width-1px {
    width: 1px;
  }
  .desktop\:width-2px {
    width: 2px;
  }
  .desktop\:width-05 {
    width: 0.25rem;
  }
  .desktop\:width-1 {
    width: 0.5rem;
  }
  .desktop\:width-105 {
    width: 0.75rem;
  }
  .desktop\:width-2 {
    width: 1rem;
  }
  .desktop\:width-205 {
    width: 1.25rem;
  }
  .desktop\:width-3 {
    width: 1.5rem;
  }
  .desktop\:width-4 {
    width: 2rem;
  }
  .desktop\:width-5 {
    width: 2.5rem;
  }
  .desktop\:width-6 {
    width: 3rem;
  }
  .desktop\:width-7 {
    width: 3.5rem;
  }
  .desktop\:width-8 {
    width: 4rem;
  }
  .desktop\:width-9 {
    width: 4.5rem;
  }
  .desktop\:width-10 {
    width: 5rem;
  }
  .desktop\:width-15 {
    width: 7.5rem;
  }
  .desktop\:width-card {
    width: 10rem;
  }
  .desktop\:width-card-lg {
    width: 15rem;
  }
  .desktop\:width-mobile {
    width: 20rem;
  }
  .desktop\:width-mobile-lg {
    width: 30rem;
  }
  .desktop\:width-tablet {
    width: 40rem;
  }
  .desktop\:width-tablet-lg {
    width: 55rem;
  }
  .desktop\:width-desktop {
    width: 64rem;
  }
  .desktop\:width-desktop-lg {
    width: 75rem;
  }
  .desktop\:width-widescreen {
    width: 87.5rem;
  }
  .desktop\:width-0 {
    width: 0;
  }
  .desktop\:width-full {
    width: 100%;
  }
  .desktop\:width-auto {
    width: auto;
  }
}
.todo-link,
a[href*="/TODO/"] {
  color: #112f4e !important;
  background-color: #ffe396 !important;
  padding: 0 0.25rem;
}
.todo-link:after,
a[href*="/TODO/"]:after {
  content: " [link TBD]";
}
.todo-link:visited,
a[href*="/TODO/"]:visited {
  color: #112f4e !important;
}
.todo-link.usa-link--external:after,
a[href*="/TODO/"].usa-link--external:after {
  background-color: inherit;
  background-image: none;
  margin-left: 0;
  padding-left: 0.25rem;
  vertical-align: baseline;
  -webkit-mask-image: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #1C304A;
}
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.3;
}
.heading-permalink {
  opacity: 0.4;
}
.heading-permalink:visited {
  color: #046B99;
}
.heading-permalink:focus,
.heading-permalink:hover {
  opacity: 1;
}
.heading-permalink .usa-icon {
  vertical-align: sub;
  max-width: 1.5rem;
}
.landing-header a {
  text-decoration: none;
  color: inherit;
}
.landing-header a:hover {
  text-decoration: none;
}
.landing-header a:active {
  text-decoration: none;
}
.landing-header a:visited {
  text-decoration: none;
}
.usa-prose blockquote {
  max-width: 60ex;
}
.usa-prose code {
  word-wrap: break-word;
}
.usa-prose h2,
.usa-prose h3,
.usa-prose h4,
.usa-prose h5,
.usa-prose h6 {
  text-wrap: balance;
  text-wrap: pretty;
}
.usa-prose h2 {
  margin-top: 2.5rem;
}
.usa-prose h2.page-title {
  margin-top: 0rem;
}
.usa-prose h2.page-title.derisking {
  padding-top: 0.5rem;
  border-top: 1px solid #1C304A;
}
.usa-prose li p {
  margin-bottom: 0;
}
.hero .section__heading--white {
  color: white;
}
a.usa-button--accent {
  color: #1C304A;
  background-color: #00CFFF;
}
a.usa-button--accent:visited {
  color: #1C304A;
  background-color: inherit;
}
a.usa-button--accent:focus,
a.usa-button--accent:hover {
  color: #1C304A;
  background-color: #a8f2ff;
}
a.usa-button--accent:active {
  background-color: #eff6fb;
}
main {
  padding: 3rem 0 4.5rem;
}
.width-200 {
  width: 200px;
}
.first_page > h1 {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 3.04rem;
}
.first_page > h2 {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 2.54rem;
  border-top: 1px solid #0b4778;
  padding-top: 0.75rem;
}
#footnotes {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.08rem;
  border-top: 1px solid #0b4778;
  padding-top: 0.75rem;
}
.footnotes-list {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 0.95rem;
  max-width: 72ex;
  padding-left: 1rem;
}
figcaption {
  font-style: italic;
}
figcaption details {
  font-style: normal;
}
.heading-md {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.08rem;
}
h2.heading-md {
  margin-top: 1rem;
}
.guide-checklist,
.key-questions {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 1.08rem;
  color: white;
  padding: 0.5rem;
}
.guide-checklist {
  background-color: #046B99;
}
.key-questions {
  background-color: #1C304A;
}
.guide-checklist + ul,
.key-questions + ul {
  background-color: #eff6fb;
  margin-top: 0;
  padding-top: 0.5rem;
  padding-right: 1rem;
  padding-bottom: 0.5rem;
}
.guide-checklist + ul li,
.key-questions + ul li {
  margin-bottom: 0.5rem;
}
.guide-checklist + ul,
.guide-checklist + ul ul,
.guide-checklist--single + ul {
  list-style: none;
  margin-left: 0;
  padding-left: 0.5rem;
}
.guide-checklist + ul li,
.guide-checklist--single + ul li {
  padding-left: 1.25rem;
  text-indent: -1.25rem;
}
.guide-checklist + ul li:before,
.guide-checklist--single + ul li:before {
  content: "\25a1";
  padding-right: 0.5rem;
}
.example-user-interview-script code {
  background: yellow;
}
#touchpoints-form {
  font-family:
    Helvetica Neue,
    Helvetica,
    Roboto,
    Arial,
    sans-serif;
  font-size: 0.82rem;
  background-color: #046B99;
  border-radius: 0.25rem 0.25rem 0 0;
  bottom: 0;
  position: fixed;
  right: 0.75rem;
  z-index: 9999;
}
#touchpoints-form span .usa-icon {
  font-size: 1.01rem;
  vertical-align: middle;
}
.usa-nav__primary-item .usa-nav__link .download-icon {
  padding-left: 2px;
}
.usa-nav__primary-item .usa-nav__link .download-icon .usa-icon {
  min-height: 1rem;
  min-width: 1rem;
  vertical-align: middle;
}
.footer-section-bottom {
  color: white;
  padding: 1.5rem 0 3rem;
}
.usa-footer__primary-link {
  padding: 0;
}
.social-link {
  align-items: center;
  display: inline-flex;
}
.social-link:before {
  content: "";
  display: inline-block;
  height: 24px;
  margin-right: 0.5rem;
  width: 24px;
}
.social-link.social-link-bsky:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='600' height='530'%3E%3Cpath fill='%23ffffff' d='M135.72 44.03C202.216 93.951 273.74 195.17 300 249.49c26.262-54.316 97.782-155.54 164.28-205.46C512.26 8.009 590-19.862 590 68.825c0 17.712-10.155 148.79-16.111 170.07-20.703 73.984-96.144 92.854-163.25 81.433 117.3 19.964 147.14 86.092 82.697 152.22-122.39 125.59-175.91-31.511-189.63-71.766-2.514-7.38-3.69-10.832-3.708-7.896-.017-2.936-1.193.516-3.707 7.896-13.714 40.255-67.233 197.36-189.63 71.766-64.444-66.128-34.605-132.26 82.697-152.22-67.108 11.421-142.55-7.45-163.25-81.433C20.15 217.613 9.997 86.535 9.997 68.825c0-88.687 77.742-60.816 125.72-24.795z'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.media-links-list {
  list-style-type: none;
  padding: 0;
}
.media-links-list h2,
.media-links-list .citation {
  margin-bottom: 0.5rem;
}
.media-links-list li {
  margin-bottom: 2rem;
}
.citation,
.article-date {
  font-size: 0.95rem;
  color: #1C304A;
}
pre[class*=language-] {
  border-radius: 0.25rem;
}
.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  color: #216e1f;
}
.token.atrule,
.token.attr-value,
.token.keyword {
  color: #046B99;
}
.token.punctuation {
  color: #565c65;
}
.token.function,
.token.class-name {
  color: #5942d2;
}
.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: #3d4551;
}
.token.regex,
.token.important,
.token.variable {
  color: #8c471c;
}
@media (min-width: 64em) {
  .usa-nav__submenu .usa-nav__submenu-item a {
    padding-left: 1rem;
  }
  .usa-nav__submenu {
    border-width: 1px;
    border-color: white;
    border-style: solid;
    border-top: 0;
  }
  .usa-nav__primary-item .usa-nav__link span.download-icon .usa-icon {
    display: inline-block;
    margin-top: 1px;
  }
}
.usa-banner {
  padding-bottom: 0.2rem;
}
.usa-banner .usa-accordion {
  line-height: 1.2;
}
.brand-background-white {
  background-color: white;
  box-shadow: inset 0 0 0 1px #a9aeb1;
}
.brand-foreground-white {
  color: white;
}
.brand-background-light {
  background-color: #B3EFFF;
}
.brand-foreground-light {
  color: #B3EFFF;
}
.brand-background-bright {
  background-color: #00CFFF;
}
.brand-foreground-bright {
  color: #00CFFF;
}
.brand-background-medium {
  background-color: #046B99;
}
.brand-foreground-medium {
  color: #046B99;
}
.brand-background-dark {
  background-color: #1C304A;
}
.brand-foreground-dark {
  color: #1C304A;
}
.brand-background-black {
  background-color: black;
}
.brand-foreground-black {
  color: black;
}
.color-matrix-square {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 100%;
  height: 3.5rem;
  width: 3.5rem;
  max-width: 3.5rem;
}
.palette-square {
  width: 100%;
  padding-bottom: 100%;
}
.color-matrix-legend.legend-bad-contrast {
  display: none;
}
table.color-matrix {
  display: block;
}
table.color-matrix thead {
  display: none;
}
table.color-matrix tbody {
  display: block;
}
table.color-matrix tbody tr {
  display: block;
}
table.color-matrix tbody tr td.color-combo {
  display: flex;
  align-items: center;
  padding-left: 0;
}
table.color-matrix tbody tr td.color-combo .color-combo-description {
  padding-left: 1em;
}
table.color-matrix tbody tr td.color-combo--invalid {
  display: none;
}
table.color-matrix tbody tr td[scope=row] {
  display: none;
}
@media all and (min-width: 55em) {
  .usa-prose .color-matrix-legend {
    align-items: center;
  }
  .color-matrix-legend.legend-bad-contrast {
    display: flex;
  }
  table.color-matrix {
    display: table;
  }
  table.color-matrix thead {
    display: table-header-group;
  }
  table.color-matrix thead td[scope=col] {
    padding-left: 0.5rem;
  }
  table.color-matrix thead .brand-foreground-white,
  table.color-matrix thead .brand-foreground-light,
  table.color-matrix thead .brand-foreground-bright {
    text-shadow:
      -1px -1px 0 #3d4551,
      1px -1px 0 #3d4551,
      -1px 1px 0 #3d4551,
      1px 1px 0 #3d4551;
  }
  table.color-matrix tbody {
    display: table-row-group;
  }
  table.color-matrix tbody tr {
    display: table-row;
  }
  table.color-matrix tbody tr td.color-combo {
    display: table-cell;
  }
  table.color-matrix tbody tr td.color-combo .color-combo-description {
    flex: 1;
    padding-left: 0;
  }
  table.color-matrix tbody tr td[scope=row] {
    display: table-cell;
    padding-left: 0;
  }
}
.text-huge {
  font-size: 140px;
}
header {
  text-align: center;
}
.usa-logo-img {
  border-radius: 5px;
  margin-right: 1rem;
  max-width: 120px;
  text-align: center;
}
p,
li {
  line-height: 1.5;
  font-size: 1.25rem;
}
li {
  margin-bottom: 0.5rem;
}
h2 {
  margin-top: 1.25rem;
  padding-top: 1.25rem;
}
.card-with-image-guides {
  box-sizing: border-box;
  border: 2px solid transparent;
  background-color: white;
  border-radius: radius-lg;
  flex-grow: 0;
  min-height: 260px;
}
.card-with-image-guides h2 {
  font-size: 1.58rem;
  line-height: 1.3;
  letter-spacing: 0.0125em;
}
.card-content {
  flex-grow: 1;
}
.card-with-image-guides p {
  text-wrap: pretty;
  flex-grow: 1;
}
.card-with-image-guides a {
  text-wrap: pretty;
}
.usa-list--unstyled li {
  font-size: 1rem;
  margin-bottom: 0.3rem;
}
.header-logo {
  display: block;
  max-width: 96px;
  border-radius: none;
}
@media all and (min-width: 64em) {
  .header-logo {
    display: block;
    max-width: 128px;
    border-radius: none;
  }
}
/*! uswds @version */
/*# sourceMappingURL=styles-RVRKUF7W.css.map */
